import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProductSetupRoutingModule} from './product-setup-routing.module';
import {ProductSetupComponent} from './product-setup.component';
import {SharedModule} from '../_shared/shared.module';
import {ProductSeasonFormComponent} from './product-season/product-season-form/product-season-form.component';
import {ProductSeasonComponent} from './product-season/product-season.component';
import {GfpFormBulkComponent} from './gfp/gfp-form-bulk/gfp-form-bulk.component';
import {GfpFormSingleComponent} from './gfp/gfp-form/gfp-form-single/gfp-form-single.component';
import {HotelProductsComponent} from './hotel-products/hotel-products.component';
import {HotelProductsFormBulkComponent} from './hotel-products/hotel-products-form-bulk/hotel-products-form-bulk.component';
import {HotelProductsFormSingleComponent} from './hotel-products/hotel-products-form-single/hotel-products-form-single.component';
import {PriceListComponent} from './price-list/price-list.component';
import {PriceListFormComponent} from './price-list/price-list-form/price-list-form.component';
import {AddPriceListComponent} from './price-list/add-price-list/add-price-list.component';
import {GolfHolidayComponent} from './golf-holiday/golf-holiday.component';
import {GfpFormComponent} from './gfp/gfp-form/gfp-form.component';
import {GfpPackageComponent} from './gfp/gfp-form/gfp-package/gfp-package.component';
import {GolfHolidayFormComponent} from './golf-holiday/golf-holiday-form/golf-holiday-form.component';
import {HotelProductsTreeComponent} from './hotel-products/hotel-products-tree/hotel-products-tree.component';
import {HotelProductsTableComponent} from './hotel-products/hotel-products-table/hotel-products-table.component';
import {GolfHolidayTableComponent} from './golf-holiday/golf-holiday-table/golf-holiday-table.component';
import {GolfHolidayTreeComponent} from './golf-holiday/golf-holiday-tree/golf-holiday-tree.component';
import {GfpComponent} from './gfp/gfp.component';
import {GfpTreeComponent} from './gfp/gfp-tree/gfp-tree.component';
import {GfpTableComponent} from './gfp/gfp-table/gfp-table.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        ProductSetupComponent,
        ProductSeasonFormComponent,
        ProductSeasonComponent,
        GfpFormBulkComponent,
        GfpFormSingleComponent,
        HotelProductsComponent,
        HotelProductsFormBulkComponent,
        HotelProductsFormSingleComponent,
        PriceListComponent,
        PriceListFormComponent,
        AddPriceListComponent,
        GolfHolidayComponent,
        GfpFormComponent,
        GfpPackageComponent,
        GolfHolidayFormComponent,
        HotelProductsTreeComponent,
        HotelProductsTableComponent,
        GolfHolidayTableComponent,
        GolfHolidayTreeComponent,
        GfpComponent,
        GfpTreeComponent,
        GfpTableComponent
    ],
    imports: [
        CommonModule,
        ProductSetupRoutingModule,
        SharedModule,
        TranslateModule
    ],
    exports: [
        ProductSeasonComponent,
        GfpComponent,
        GfpTreeComponent,
        GfpTableComponent
    ]
})
export class ProductSetupModule {
}
