<div class="p-grid">
    <div class="p-col-6 p-md-4 p-xl-3 p-field">
        <p-dropdown [options]="golfCourses$ | async"
                    (onChange)="updateGolfCourse(golfCourse)"
                    [filter]="true"
                    [(ngModel)]="golfCourse"
                    filterBy="name"
                    optionLabel="name"
                    placeholder="Select a Golf Course"
        ></p-dropdown>
    </div>
</div>
<br />
<app-g-calendar
        [showAddBtns]="golfCourse"
        (eventAdded)="addEvent($event)"
        [events]="events$ | async"
        [timezone]="golfCourse?.timezone || 'Africa/Abidjan'"
></app-g-calendar>
