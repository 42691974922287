<div class="full-width">
    <h5 class="float-left">{{'reports' | translate}}</h5>

</div>
<div class="search-div mt-3 mb-3">
    <form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
        <p class="group-text">{{'selectReport' | translate}}</p>
        <div class="float-left ml-3 mr-2 mb-3">
            <p-dropdown [options]="filterTypes" [formControl]="searchForm.controls['groupBy']"
                        [optionLabel]="'value'" [optionValue]="'code'" placeholder="Group by"></p-dropdown>
        </div>
        <div class="float-left">
            <div class="buttons">
            </div>
        </div>
    </form>
</div>
<form [formGroup]="filterForm" (ngSubmit)="getUserActivities()" class="mt-3 mb-3">
    <div class="row p-3">
        <div class="col-md-3">
            <app-text-input label="{{'search' | translate}}" [formControl]="filterForm.get('search')"></app-text-input>
        </div>
        <div class="col-md-3">
            <p class="mb-1">{{'dateFrom' | translate}}</p>
            <p-calendar [formControl]="filterForm.get('date_from')" dateFormat="dd.mm.yy"
                        [showIcon]="true" styleClass="date-input"
                        showButtonBar="true" [maxDate]="today"></p-calendar>
        </div>
        <div class="col-md-3">
            <p class="mb-1">{{'dateTo' | translate}}</p>
            <p-calendar [formControl]="filterForm.get('date_to')" dateFormat="dd.mm.yy"
                        [showIcon]="true" styleClass="date-input"
                        showButtonBar="true" [maxDate]="today"></p-calendar>
        </div>
        <div class="col-md-3">
            <button pButton pRipple type="submit" label="{{'Submit' | translate}}"
                    class="mt-4 float-right"></button>
        </div>
    </div>
</form>
<div *ngIf="items?.data?.length === 0" class="mt-3 mb-3">
    <p class="hint">No Available data!</p>
</div>
<p-table *ngIf="items?.data?.length > 0" [value]="items?.data" dataKey="id" [paginator]="true" [rows]="10"
         [showCurrentPageReport]="true" responsiveLayout="scroll" [showJumpToPageInput]="true"
         currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}" [lazyLoadOnInit]="false"
         [lazy]="true" (onLazyLoad)="pageChanged($event)" [(first)]="first" [totalRecords]="items?.meta?.total"
         [rowsPerPageOptions]="[10,25,50,100]">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="id">Id
                <p-sortIcon field="id"></p-sortIcon>
            </th>
            <th pSortableColumn="username">{{'username' | translate}}
                <p-sortIcon field="username"></p-sortIcon>
            </th>
            <th pSortableColumn="last_name">{{'lastName' | translate}}
                <p-sortIcon field="last_name"></p-sortIcon>
            </th>
            <th pSortableColumn="first_name">{{'firstName' | translate}}
                <p-sortIcon field="first_name"></p-sortIcon>
            </th>
            <th pSortableColumn="email">{{'email' | translate}}
                <p-sortIcon field="email"></p-sortIcon>
            </th>
            <!--<th pSortableColumn="ip">{{'ip' | translate}}
                <p-sortIcon field="ip"></p-sortIcon>
            </th>
            <th pSortableColumn="geoip_city_name">{{'Location' | translate}}
                <p-sortIcon field="geoip_city_name"></p-sortIcon>
            </th>-->
            <th pSortableColumn="browser_name">{{'browser' | translate}}
                <p-sortIcon field="browser_name"></p-sortIcon>
            </th>
            <th pSortableColumn="created_at">{{'date' | translate}}
                <p-sortIcon field="created_at"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{item.id}}</td>
            <td>{{item.username}}</td>
            <td>{{item.last_name}}</td>
            <td>{{item.first_name}}</td>
            <td>{{item.email}}</td>
            <!--<td>{{item.ip}}</td>
            <td>{{item.geoip_city_name}}</td>-->
            <td>{{item.browser_name}}</td>
            <td>{{item.created_at}}</td>
        </tr>
    </ng-template>
</p-table>
