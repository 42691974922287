import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Permission, Role} from '../_models/roles-permissions';
import {HttpErrrorHandler} from '../_classes/httpErrorHandler';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RolesPermissionService {

    apiURL = environment.apiUrl;
    // Error Handler
    handleError = new HttpErrrorHandler();

    roles: Role[] = [];
    permissions: Permission[] = [];

    constructor(private http: HttpClient) {
        console.log('Roles-Permissions Service is running!');
    }

    // Roles API
    /**
     * get all roles and cache in roles variable
     * @returns Observable array of roles
     */
    getRoles(): Observable<Role[]> {
        return this.http.get<{ status: string, roles: Role[] }>(`${this.apiURL}roles`)
            .pipe(catchError(this.handleError.handleError))
            .pipe(map(res => this.roles = res.roles));
    }

    /**
     * get role details
     * @param roleID role id
     * @returns Role object if already cached in roles variable | promise of Role object
     */
    getRoleDetails(roleID: number): Role | Promise<Role> {
        if (this.roles.length > 0) {
            return this.roles.filter((role: Role) => role.id === roleID)[0];
        } else {
            return this.http.get<{ status: string, role: Role }>(`${this.apiURL}roles/${roleID}`).pipe(catchError(this.handleError.handleError))
                .pipe(map(res => res.role)).toPromise();
        }
    }

    /**
     * update role
     * @param role Role object
     */
    async updateRole(role: Role): Promise<string> {
        const cloneRole: any = {...role};
        cloneRole.permissions = this.convertRolePermissionsToArray(role);
        return this.http.put<{ status: string, role: Role }>(`${this.apiURL}roles/${role.id}`, cloneRole).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.status)).toPromise();
    }

    /**
     * Create new Role
     * @param role role object data
     * @returns result status
     */
    createRole(role: Role): Promise<string> {
        return this.http.post<{ status: string, role: Role }>(`${this.apiURL}roles`, role).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.status)).toPromise();
    }

    deleteRole(roleId: number): Promise<string> {
        return this.http.delete<{ status: string }>(`${this.apiURL}roles/${roleId}`).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.status)).toPromise();
    }

    // Permissions
    /**
     * get all permissions and cache in permissions variable
     * @returns Observable array of permissions
     */
    getPermissions(): Observable<Permission[]> {
        return this.http.get<{ status: string, permissions: Permission[] }>(`${this.apiURL}permissions`).pipe(catchError(this.handleError.handleError))
            .pipe(map(res => this.permissions = res.permissions));
    }

    /**
     * get permission details
     * @param permissionID permission id
     * @returns permission object if already cached in permissions variable | promise of permission object
     */
    getPermissionDetails(permissionId: number): Permission | Promise<Permission> {
        if (this.permissions.length > 0) {
            return this.permissions.filter((permission: Permission) => permission.id === permissionId)[0];
        } else {
            return this.http.get<{ status: string, permission: Permission }>(`${this.apiURL}permissions/${permissionId}`).pipe(catchError(this.handleError.handleError))
                .pipe(map(res => res.permission)).toPromise();
        }
    }

    /**
     * update permission
     * @param permission permission object
     */
    updatePermission(permission: Permission): Promise<string> {
        return this.http.put<{ status: string, permission: Permission }>(`${this.apiURL}permissions/${permission.id}`, permission).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.status)).toPromise();
    }

    deletePermission(permissionId: number): Promise<string> {
        return this.http.delete<{ status: string }>(`${this.apiURL}permissions/${permissionId}`).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.status)).toPromise();
    }

    /**
     * Create new Permission
     * @param permisison permisison object data
     * @returns result status
     */
    createPermission(permisison: Permission): Promise<string> {
        return this.http.post<{ status: string, permission: Permission }>(`${this.apiURL}permissions`, permisison).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.status)).toPromise();
    }

    // extras
    /**
     * Get Modules Tree
     * @returns Promise array of modules structure
     */
    getModulesList(): Promise<any[]> {
        return this.http.get<{ status: string, test: string, modules: any[] }>(`${this.apiURL}modules`).pipe(catchError(this.handleError.handleError)).pipe(map(res => res.modules)).toPromise();
    }

    // helpers
    /**
     * convert permissions array of objects to an array of permissions ids [1,2,3,...]
     * @param role Role
     * @returns array of permissions ids
     */
    private convertRolePermissionsToArray(role: Role) {
        if (typeof (role.permissions[0]) === 'number') {
            return role.permissions;
        }
        return role.permissions.map((permission) => permission.id);
    }

}
