<div class="p-grid p-jc-between mt-2 mb-3">
    <div class="p-d-flex p-ai-center">
        <h5 class="p-mr-2">Hotel Rooms</h5>
        <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                (click)="addNewRoom()"></button>
    </div>
</div>

<p class="hint" *ngIf="hotelRooms?.length === 0">{{ translate.transform('noDataFound')}}</p>
<div *ngIf="hotelRooms?.length > 0" class="p-grid mt-4 frame">
    <div class="p-col-3 service-menu scroll">
        <div *ngFor="let room of hotelRooms; let i = index" class="service-menu-item"
             (click)="changeSelectedRoom(room)"
             [ngClass]="selectedRoom?.name == room?.name ? 'active-menu-item' : null">
            {{room.name}}
            <i *ngIf="room.show_website" class="pi pi-globe mr-2"></i>
        </div>
    </div>
    <div class="p-col-9 service-data scroll">
        <app-room-form *ngIf="selectedRoom" [hotelId]="hotelId"
                       [facilitiesList]="facilitiesList"
                       (closeModal)="roomUpdated($event)"
                       [roomFieldTypes]="roomFieldTypes"
                       [room]="selectedRoom"
                       (roomDeleted)="roomDeleted($event)"></app-room-form>
    </div>
</div>

<ng-container *ngIf="showRoomForm">
    <p-dialog header="New Room" [(visible)]="showRoomForm" [position]="'top'" [modal]="true"
              [style]="{width: '80vw'}"
              [draggable]="false" [resizable]="false" [maximizable]="true" (onHide)="showRoomForm = false">
        <app-room-form [hotelId]="hotelId" [facilitiesList]="facilitiesList" #roomFormComponent
                       [roomFieldTypes]="roomFieldTypes" (closeModal)="closeAddModal($event)"></app-room-form>
    </p-dialog>
</ng-container>
