import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../_services/account.service';
import {Company} from '../../_models/company';

@Component({
    selector: 'app-gfp',
    templateUrl: './gfp.component.html',
    styleUrls: ['./gfp.component.css']
})
export class GfpComponent implements OnInit {
    activeView = 'table';
    companyId: number;
    @Input() company: Company;

    constructor(private breadcrumbService: BreadcrumbService, private accountService: AccountService,
                private router: Router, private route: ActivatedRoute) {
        if (this.route.parent.snapshot.paramMap.get('id')) {
            this.companyId = +this.route.parent.snapshot.paramMap.get('id');
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Companies', routerLink: '/admin/companies'},
                {label: 'Company GFPs'}
            ]);
        } else {
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Product Setup'},
                {label: 'GFPs'},
            ]);
        }
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParamMap.has('view')) {
            if (this.route.snapshot.queryParamMap.get('view') == 'tree') {
                this.activeView = 'tree';
            } else if (this.route.snapshot.queryParamMap.get('view') == 'table') {
                this.activeView = 'table';
            }
        }
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    addNew() {
        this.router.navigate(['add'], {relativeTo: this.route});
        if (this.companyId) {
            this.router.navigate(['product-setup/gfp/add'],
                {queryParams: {company_id: this.companyId}, state: {company: this.company}});
        } else {
            this.router.navigate(['product-setup/gfp/add']);
        }
    }
    changeActiveView(view: string) {
        this.activeView = view;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                view: view
            }
        });
    }

    addNewBulk() {
        this.router.navigate(['add-bulk'], {relativeTo: this.route});
    }
}
