import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Region} from '../../../_models/destinations';
import {DestinationsService} from '../../_services/destinations.service';
import {Observable} from 'rxjs';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-region-form',
  templateUrl: './region-form.component.html',
  styleUrls: ['./region-form.component.css']
})
export class RegionFormComponent implements OnInit {

  isEditMode = false;
  regionForm: UntypedFormGroup;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private msgService: MessageService,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private destService: DestinationsService,
    private translate: TranslatePipe
  ) { }

  ngOnInit(): void {
    this.initForm();
    // chek edit mode
    const regionId = +this.activatedRoute.snapshot.paramMap.get('id');
    if (regionId > 0) {
      this.isEditMode = true;
      this.breadcrumbService.setItems([
        {label: 'Dashboard', routerLink: '/'},
        {label: this.translate.transform('continents'), routerLink: '/destinations/continents'},
        {label: this.translate.transform(this.isEditMode ? 'editContinent' : 'newContinent')},
      ]);
      try {
        this.getRegion(regionId).subscribe(region => {
          if (region) {
            this.regionForm.patchValue(region);
          } else {
            this.msgService.add({
              severity: 'error',
              summary: 'Get Continent',
              detail: 'No such Continent!'
            });
          }
        });
      } catch (e) {
        console.error('get Continent data error', e);
        this.msgService.add({
          severity: 'error',
          summary: 'Get Continent',
          detail: 'Something unexpected went wrong!'
        });
      }
    }
  }

  initForm(): void {
    this.regionForm = this.fb.group({
      id: [''],
      name_en: ['', Validators.required],
      name_de: ['', Validators.required],
      code: ['', Validators.required],
      status: [1],
      translations: this.fb.array([
        this.fb.group({
          language_id: 1,
          name: [null]
        }),
        this.fb.group({
          language_id: 2,
          name: [null]
        })
      ])
    });
  }

  getRegion(id: number): Observable<Region> {
    return this.destService.getRegion(id);
  }

  submit() {
    // check form validation
    if (!this.regionForm.valid) {
      this.regionForm.markAllAsTouched();
      return false;
    }

    // submit form
    const regionData: Region = {...this.regionForm.value};
    regionData.translations[0].name = regionData.name_en;
    regionData.translations[1].name = regionData.name_de;
    regionData.status = this.regionForm.value.status ? 1 : 0;

    // check if edit mode is active
    if (!this.isEditMode) {
      // add new region
      try {
        this.destService.addRegion(regionData).subscribe(res => {
          if (res) {
            this.msgService.add({
              severity: 'success',
              summary: 'Add Continent',
              detail: 'Continent has been added successfully!'
            });
            this.initForm();
          }
        });
      } catch (err) {
        console.error('add continent error', err);
        this.msgService.add({
          severity: 'error',
          summary: 'Add Continent',
          detail: 'Something unexpected went wrong!'
        });
      }
    } else {
      // update region
      try {
        this.destService.updateRegion(regionData).subscribe(res => {
          if (res) {
            this.msgService.add({
              severity: 'success',
              summary: 'Edit Continent',
              detail: 'Continent has been updated successfully!'
            });
          }
        });
      } catch (err) {
        console.error('edit continent error', err);
        this.msgService.add({
          severity: 'error',
          summary: 'Edit Continent',
          detail: 'Something unexpected went wrong!'
        });
      }
    }
  }

}
