import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '../_guards/auth.guard';
import {PackageFormComponent} from './package-form/package-form.component';
import {PackagesComponent} from './packages/packages.component';

const routes: Routes = [
    {path: '', redirectTo: './', pathMatch: 'full'},
    {
        path: '', component: PackagesComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1691135637212', type: 'action'}
    },
    {
        path: 'add', component: PackageFormComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1691135761682', type: 'action'}
    },
    {
        path: 'edit/:id', component: PackageFormComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1691135928282', type: 'action'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PackageCmsRoutingModule { }
