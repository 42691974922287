import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-news-slider',
    templateUrl: './news-slider.component.html',
    styleUrls: ['./news-slider.component.css']
})
export class NewsSliderComponent implements OnInit {

    news = [
        {title: 'Discover our newly added Destinations', image: '1.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'Discover our newly added Golf Courses', image: '2.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'VIP Groups Tee time reservation', image: '3.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'Best offers for this seasons', image: '5.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'New Pros GFP', image: '6.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'New Partners Portal "Fairway', image: '7.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'New GG Events', image: '8.jpg', link: 'https://staging2.golfglobe.com/'},
        {title: 'Coming Soon - Live Tee Time Booking', image: '9.jpg', link: 'https://staging2.golfglobe.com/'}
    ];
    responsiveOptions;

    constructor() {
    }

    ngOnInit(): void {
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    openLink(link) {
        window.open(link, '_blank');
    }

}
