<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="permissions$ | async" [rows]="10" [paginator]="true"
             [globalFilterFields]="['status','name','module','page', 'code', 'actions']"
             [(selection)]="selectedPermissions" [rowHover]="true" dataKey="id"
             [scrollable]="true" [scrollDirection]="'both'"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showJumpToPageInput]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10, 50, 100, 200]">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">Permisssions</h5>

                    <button pButton pRipple label="Add" icon="pi pi-plus" class="p-button-primary p-ml-2"
                            (click)="addNew()" *ngIf="checkPermission('1636371364127')"></button>
                </div>
                <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                        (click)="deleteSelectedProducts()"
                        [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->


                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload"
                        class="p-button-help float-right" *ngIf="checkPermission('1636371451977')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width"
                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="status"
                    class="p-d-flex p-jc-between p-ai-center">
                    Status
                    <app-filter fieldName="status" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="name"
                    class="p-d-flex p-jc-between p-ai-center">Name
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="module_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Module
                    <p-sortIcon field="module_name"></p-sortIcon>
                    <app-filter fieldName="module_name" [filterOptions]="filters.module_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="page_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Page
                    <p-sortIcon field="page_name"></p-sortIcon>
                    <app-filter fieldName="page_name" [filterOptions]="filters.page_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="code"
                    class="p-d-flex p-jc-between p-ai-center">Code
                    <p-sortIcon field="code"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371383923') || checkPermission('1636371399146') || checkPermission('1636371418512')"
                >Actions
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-permission>
            <tr>
                <td style="width: 3rem">
                    <p-tableCheckbox [value]="permission"></p-tableCheckbox>
                </td>
                <td style="min-width:160px; max-width: 160px;" class="status-td">
                    <span class="status-txt">{{permission.status === 1 ? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td style="min-width:160px; max-width: 160px;cursor: pointer" (click)="edit(permission)">{{permission.name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{permission.module_name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{permission.page_name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{permission.code}}</td>
                <td style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371383923') || checkPermission('1636371399146') || checkPermission('1636371418512')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                            aria-labelledby="button-basic">
                            <!-- <li role="menuitem" *ngIf="checkPermission('1636371383923')"><a class="dropdown-item" (click)="view(role)">View</a></li> -->
                            <li role="menuitem" *ngIf="checkPermission('1636371399146')"><a class="dropdown-item"
                                                                                            (click)="edit(permission)">Edit</a>
                            </li>
                            <!--<li role="menuitem" *ngIf="checkPermission('1636371418512')"><a class="dropdown-item"
                                                                                            (click)="delete(permission)">Delete</a>
                            </li>-->
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
