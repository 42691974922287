import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CityComponent } from './city/city.component';
import { CountryComponent } from './country/country.component';
import { DestinationComponent } from './destination.component';
import { RegionComponent } from './region/region.component';
import {RegionFormComponent} from './region/region-form/region-form.component';
import {CountryFormComponent} from './country/country-form/country-form.component';
import {CityFormComponent} from './city/city-form/city-form.component';
import {AuthGuard} from '../_guards/auth.guard';
import {AreaComponent} from './area/area.component';
import {AreaFormComponent} from './area/area-form/area-form.component';

const routes: Routes = [
  {
      path: '', component: DestinationComponent, canActivate: [AuthGuard],
      data: {requirePermission: true, page_id: 14, type: 'page'}
  },
  {
    path: 'continents', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
          path: '', component: RegionComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370614164', type: 'action'}
      },
      {
          path: 'add', component: RegionFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370630870', type: 'action'}
      },
      {
          path: 'edit/:id', component: RegionFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370661741', type: 'action'}
      }
    ], data: {requirePermission: true, page_id: 15, type: 'page'}
  },
  {
    path: 'countries', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
          path: '', component: CountryComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370731398', type: 'action'}
      },
      {
          path: 'continent/:id', component: CountryComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370646637', type: 'action'}
      },
      {
          path: 'add', component: CountryFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370748894', type: 'action'}
      },
      {
          path: 'edit/:id', component: CountryFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370786752', type: 'action'}
      },
    ], data: {requirePermission: true, page_id: 16, type: 'page'}
  },
  {
    path: 'regions', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
          path: '', component: CityComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370849391', type: 'action'}
      },
      {
          path: 'country/:id', component: CityComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370765293', type: 'action'}
      },
      {
          path: 'add', component: CityFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370877107', type: 'action'}
      },
      {
          path: 'edit/:id', component: CityFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1636370949146', type: 'action'}
      }
    ], data: {requirePermission: true, page_id: 17, type: 'page'}
  },
  {
    path: 'areas', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
          path: '', component: AreaComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1678311220197', type: 'action'}
      },
      {
          path: 'region/:id', component: AreaComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1678311220197', type: 'action'}
      },
      {
          path: 'add', component: AreaFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1678311247964', type: 'action'}
      },
      {
          path: 'edit/:id', component: AreaFormComponent, canActivate: [AuthGuard],
          data: {requirePermission: true, code: '1678312195331', type: 'action'}
      }
    ], data: {requirePermission: true, page_id: 26, type: 'page'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DestinationRoutingModule { }
