import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
/*
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
*/
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivityType, CalendarEventEmitter} from '../../_models/calendar';
import {CalendarService} from '../../_services/calendar';
import {Observable} from 'rxjs';
import {AccountService} from '../../_services/account.service';

@Component({
  selector: 'app-g-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class GCalendarComponent {
/*
export class GCalendarComponent implements OnInit, OnChanges {
*/

  /*@Input() showAddBtns: boolean;
  @Input() events: any[];
  @Input() timezone: string;

  @Output() eventAdded: EventEmitter<CalendarEventEmitter> = new EventEmitter();

  @ViewChild('calendar') fCalendar: FullCalendarComponent;

  isEditMode = false;
  isCalendarOpen: { state: boolean; curr: string; } = {state: false, curr: 'sHide'};
  isRecurEnable = true;
  calendarOptions: CalendarOptions = {} as CalendarOptions;
  dialog: any = {
    activity: false,
    season: false
  };

  activityForm: UntypedFormGroup;
  seasonForm: UntypedFormGroup;

  colorsPalette = [
    {code: '#eca400', name: 'Marigold'},
    {code: '#eaf8bf', name: 'Pale Spring Bud'},
    {code: '#150578', name: 'Navy Blue'},
    {code: '#78c0e0', name: 'Dark Sky Blue'},
    {code: '#f06c9b', name: 'Cyclamen'},
    {code: '#6b654b', name: 'Gold Fusion'},
    {code: '#ff331f', name: 'Red RYB'},
    {code: '#4c1c00', name: 'Seal Brown'},
    {code: '#ffd447', name: 'Mustard'},
    {code: '#320d6d', name: 'Parisian Indigo'},
    {code: '#7cdedc', name: 'Middle Blue Green'},
    {code: '#9e788f', name: 'Mountbatten Pink'},
  ];
  activityTypes$: Observable<ActivityType[]>;
  timezones: { name: string; utc_offset: string; }[];
  timezoneOffset: string;
  today: Date = new Date();
  seasonMaxDateValue: Date = new Date(new Date().setFullYear(this.today.getFullYear() + 1));

  constructor(
      private fb: UntypedFormBuilder,
      private calendarService: CalendarService,
      private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.calendarOptions = {
      timeZone: this.timezone,
      aspectRatio: window.innerWidth < 1145 ? 1.35 : 2.7,
      nowIndicator: true,
      dayMaxEvents: 3,
      dayMaxEventRows: 3,
      headerToolbar: {
        // TODO: add "season" next to activity
        end: this.showAddBtns && this.checkPermission('1636369353209') ? 'activity' : '',
        center: 'today,dayGridMonth,timeGridWeek,timeGridDay,weekWork',
        start: 'prev,next,title'
      },
      views: {
        weekWork: {
          type: 'timeGridWeek',
          buttonText: 'Week Work',
          weekends: false,
        }
      },
      customButtons: {
        activity: {
          text: 'Add Activity',
          click: this.openDialog.bind(this, 'activity')
        },
        season: {
          text: 'Add Season',
          click: this.openDialog.bind(this, 'season')
        }
      },
      windowResize: this.calendarWindowResize.bind(this),
      events: this.events,
      eventClick: this.updateActivity.bind(this)
    };
  }

  calendarWindowResize() {
    if (window.innerWidth < 1145) {
      this.calendarOptions.aspectRatio = 1.35;
    } else {
      this.calendarOptions.aspectRatio = 2.7;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calendarOptions.events = changes.events?.currentValue || this.events;
    this.calendarOptions.timeZone = changes.timezone?.currentValue || this.timezone;
    this.calendarOptions.headerToolbar = {
      // TODO: add "season" next to activity
      end: this.showAddBtns && this.checkPermission('1636369353209') ? 'activity' : '',
      center: 'today,dayGridMonth,timeGridWeek,timeGridDay,weekWork',
      start: 'prev,next,title'
    };
  }

  initForms(): void {
    this.activityTypes$ = this.calendarService.getActivityTypes();
    this.timezones = this.calendarService.timezone;
    this.timezoneOffset = this.timezones[this.timezones.findIndex(zone => zone.name === this.timezone)]['utc_offset'];
    this.activityForm = this.fb.group({
      id: '',
      title: ['', Validators.required],
      type: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      isAllDay: [0],
      changeTimeZone: [0],
      timezone: this.timezoneOffset,
      isRecurring: [''],
      recurringOptions: this.fb.group({
        repeatType: [''],
        days: [''],
        start: [''],
        end: ['']
      })
    });

    this.seasonForm = this.fb.group({
      title: ['', Validators.required],
      color: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      peak_start: ['', Validators.required],
      peak_end: ['', Validators.required]
    });
  }

  toggleRequire(formCtrl: AbstractControl | AbstractControl[], isRequired: number): void {
    if (Array.isArray(formCtrl)) {
      for (const ctrl of formCtrl) {
        if (isRequired === 1) {
          ctrl.addValidators(Validators.required);
        } else {
          ctrl.removeValidators(Validators.required);
        }
        ctrl.reset();
      }
    } else {
      if (isRequired === 1) {
        formCtrl.addValidators(Validators.required);
      } else {
        formCtrl.removeValidators(Validators.required);
      }
      formCtrl.reset();
    }
  }

  openDialog(dialogName: string): void {
    this.activityForm.reset();
    this.seasonForm.reset();
    this.dialog[dialogName] = true;
  }

  checkRecurEnable() {
    const start = new Date(this.activityForm.value.start);
    const end = new Date(this.activityForm.value.end);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    if (start.getTime() !== end.getTime()) {
      this.isRecurEnable = false;
      this.activityForm.patchValue({isRecurring: 0});
      this.toggleRequire([
            this.activityForm.get(['recurringOptions', 'repeatType']),
            this.activityForm.get(['recurringOptions', 'start']),
            this.activityForm.get(['recurringOptions', 'end'])
          ],
          0
      );
    } else {
      this.isRecurEnable = true;
    }
}

  submit(formName: string): void {
    // check form validation
    switch (formName) {
      case 'activity':
        if (!this.activityForm.valid) {
          this.activityForm.markAllAsTouched();
          break;
        }
        const activityValues = this.activityForm.value;
        this.eventAdded.emit({type: 'activity', isEditMode: this.isEditMode, data: {
            id: activityValues.id,
            title: activityValues.title,
            type_id: activityValues.type,
            is_recurring: activityValues.isRecurring,
            start_time: activityValues.isRecurring ? null : getTime(activityValues.start, activityValues.isAllDay, activityValues.timezone),
            end_time: activityValues.isRecurring ? null : getTime(activityValues.end, activityValues.isAllDay, activityValues.timezone),
            duration: activityValues.isRecurring ? calcDuration(activityValues.start, activityValues.end, activityValues.isAllDay) : null,
            // tslint:disable-next-line:max-line-length
            start_recur: activityValues.isRecurring ? getTimeRecur(activityValues.recurringOptions.start, activityValues.start, activityValues.isAllDay, activityValues.timezone) : null,
            end_recur: activityValues.isRecurring ? getTime(activityValues.recurringOptions.end, true, activityValues.timezone) : null,
            // tslint:disable-next-line:max-line-length
            days_of_week:  activityValues.isRecurring && activityValues.recurringOptions.repeatType === 'weekly' ? activityValues.recurringOptions.days : null,
            timezone: activityValues.timezone
        }});
        this.activityForm.reset();
        this.dialog.activity = false;
        this.isEditMode = false;
        break;
      case 'season':
        // check peak end time, should be greater than peak start
        if (this.seasonForm.value.peak_start.getTime() > this.seasonForm.value.peak_end.getTime()) {
          this.seasonForm.patchValue({peak_end: ''});
          this.seasonForm.markAllAsTouched();
          break;
        }
        if (!this.seasonForm.valid) {
          this.seasonForm.markAllAsTouched();
          break;
        }

        const seasonValues = this.seasonForm.value;
        this.eventAdded.emit({type: 'season', isEditMode: this.isEditMode, data: {
            title: seasonValues.title,
            start_date: `${seasonValues.start.getFullYear()}-${addZero(seasonValues.start.getMonth() + 1)}-${addZero(seasonValues.start.getDate())}`,
            // tslint:disable-next-line:max-line-length
            end_date: `${seasonValues.end.getFullYear()}-${addZero(seasonValues.end.getMonth() + 1)}-${addZero(seasonValues.end.getDate())}`,
            color: seasonValues.color.code,
            display: 'background',
            peak_time_from: `${addZero(seasonValues.peak_start.getHours())}:${addZero(seasonValues.peak_start.getMinutes())}${this.timezoneOffset}`,
            peak_time_to: `${addZero(seasonValues.peak_end.getHours())}:${addZero(seasonValues.peak_end.getMinutes())}${this.timezoneOffset}`
          }});
        this.seasonForm.reset();
        this.dialog.season = false;
        this.isEditMode = false;
        break;
      default:
        break;
    }

    function addZero(n: number ): string {
      return n < 10 ? '0' + n : '' + n;
    }

    function getTime(date: Date, isAllDay: boolean, timezone: string): string {
      if (isAllDay) {
        return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
      }
      return  `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}T${addZero(date.getHours())}:${addZero(date.getMinutes())}${timezone || this.timezoneOffset}`;
    }

    function getTimeRecur(date: Date, time: Date, isAllDay: boolean, timezone: string) {
      if (isAllDay) {
        return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
      } else {
        return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}T${addZero(time.getHours())}:${addZero(time.getMinutes())}${timezone || this.timezoneOffset}`;
      }
    }

    function calcDuration(d1: Date, d2: Date, isAllDay: boolean) {
      if (isAllDay) {
        return null;
      }
      // @ts-ignore
      let delta = Math.abs(d2 - d1) / 1000;
      // calculate (and subtract) whole days
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      const minutes = Math.floor(delta / 60) % 60;

      return `${addZero(hours)}:${addZero(minutes)}`;
    }
  }

  startDateChange(formName: string): void {
    switch (formName) {
      case 'activity':
        // check if start Date is Greater than selected End Date to re-select new valid End Date
        if (this.activityForm.value.end && this.activityForm.value.start.getTime() > this.activityForm.value.end.getTime()) {
          this.activityForm.patchValue({end: ''});
        }
        break;
      case 'season':
        // check if start Date is Greater than selected End Date to re-select new valid End Date
        if (this.seasonForm.value.end && this.seasonForm.value.start.getTime() > this.seasonForm.value.end.getTime()) {
          this.seasonForm.patchValue({end: ''});
        }
        this.seasonMaxDateValue = new Date(new Date().setFullYear(this.seasonForm.value.start.getFullYear() + 1,
            this.seasonForm.value.start.getMonth(),
            this.seasonForm.value.start.getDate()));
        break;
    }
  }

  startTimeChange(formName: string): void {
    switch (formName) {
      case 'activity':
        break;
      case 'season':
        // check if start Time is Greater than selected End Time to re-select new valid End Time
        if (this.seasonForm.value.peak_end && this.seasonForm.value.peak_start.getTime() > this.seasonForm.value.peak_end.getTime()) {
          this.seasonForm.patchValue({peak_end: ''});
        }
        break;
    }
  }

  startDateChangeRecurring(): void {
    if (this.activityForm.value.recurringOptions.end &&
        this.activityForm.value.recurringOptions.start.getTime() > this.activityForm.value.recurringOptions.end.getTime()) {
      this.activityForm.value.recurringOptions.patchValue({end: ''});
    }
  }

  addCalendarCLass(state: boolean, curr) {
    if (curr.includes('Show')) {
      this.isCalendarOpen.curr = curr;
    }
    if (curr.split('')[0] === this.isCalendarOpen.curr.split('')[0]) {
      this.isCalendarOpen.state = state;
      console.log(this.isCalendarOpen, state, curr);
    } else {
      this.isCalendarOpen.state = true;
      console.log(this.isCalendarOpen, state, curr);
    }
  }

  updateActivity(args) {
    this.isEditMode = true;
    const event = args.event;
    if (event.extendedProps.isSeason) {
      return;
    }
    const activity = this.events[this.events.findIndex(ev => ev.id === +event.id)];
    console.log(activity);
    this.openDialog('activity');
    this.activityForm.patchValue({
      id: activity.id,
      title: activity.title,
      type: activity.type_id,
      // tslint:disable-next-line:max-line-length
      start: activity.isRecurring === 1 ? convertTZ(activity.rrule.dtstart, activity.timezone || this.timezone) : convertTZ(activity.start, activity.timezone || this.timezone),
      // tslint:disable-next-line:max-line-length
      end: activity.isRecurring === 1 ?  calcEndDate(convertTZ(activity.rrule.dtstart, activity.timezone || this.timezone), activity.duration) : convertTZ(activity.end, activity.timezone || this.timezone),
      isAllDay: event.allDay ? 1 : 0,
      changeTimeZone: this.timezone === activity.timezone ? 1 : 0,
      timezone: this.timezones[this.timezones.findIndex(tz => tz.name === activity.timezone)]?.utc_offset || this.timezoneOffset,
      isRecurring: activity.isRecurring,
      recurringOptions: {
        repeatType: activity.rrule.freq,
        days: activity.rrule.byweekday,
        start: new Date(activity.rrule.dtstart),
        end: new Date(activity.rrule.until)
      }
    });

    function convertTZ(date, tzString) {
      return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {timeZone: tzString}));
    }

    function calcEndDate(start, duration) {
      const hours = +duration.split(':')[0];
      const minutes = +duration.split(':')[1];

      start.setUTCHours(start.getUTCHours() + hours);
      start.setUTCMinutes(start.getUTCMinutes() + minutes);

      return start;
    }
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }*/
}
