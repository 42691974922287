import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {RequestAPIService} from '../../_services/request-api.service';
import {SharedService} from '../../../_services/shared.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-edit-players',
    templateUrl: './edit-players.component.html',
    styleUrls: ['./edit-players.component.css', '../../stages/request-stage-one/request-stage-one.component.css']
})
export class EditPlayersComponent implements OnInit {

    @Input() displayEditPlayers = false;
    @Input() request: any;
    @Input() isPortal = false;
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    form: UntypedFormArray;
    genderOptions = ['Male', 'Female'];

    constructor(private fb: UntypedFormBuilder, private requestServices: RequestAPIService,
                private confirmationService: ConfirmationService,
                private sharedService: SharedService,
                private messageService: MessageService, private translate: TranslatePipe) {
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.form = this.fb.array([]);
        this.request.players.forEach(player => {
            this.form.push(
                this.fb.group({
                    id: player.id,
                    first_name: [player.first_name, Validators.required],
                    last_name: [player.last_name, Validators.required],
                    gender: [player.gender, Validators.required],
                    hcp: [player.hcp, Validators.required],
                    client_id: player.client_id,
                    destinations: [this.getDestinationsIds(player.destinations), Validators.required]
                })
            );
        });
        this.disableClientPlayerNames();
    }

    getDestinationsIds(destinations) {
        const ids = [];
        destinations.forEach(dest => {
            ids.push(dest.id);
        });
        return ids;
    }

    disableClientPlayerNames() {
        this.form.getRawValue().forEach((player, index) => {
            if (player.client_id !== null) {
                this.form.controls[index].get('first_name').disable();
                this.form.controls[index].get('last_name').disable();
                this.form.controls[index].get('destinations').disable();
            }
        });
    }

    updatePlayer(pIndex) {
        if (this.request.players[pIndex]?.id) {
            if (!this.form.controls[pIndex].valid) {
                this.form.controls[pIndex].markAllAsTouched();
                return false;
            }
            const reqPlayer = {...this.request.players[pIndex]};
            delete reqPlayer.request_id;
            delete reqPlayer.groups;
            reqPlayer.destinations = reqPlayer.destinations.map(destination => {
                return destination.id;
            });
            if (this.form.controls[pIndex].value.id !== null &&
                !this.sharedService.checkObjectChanges(this.form.controls[pIndex].value, this.request.players[pIndex])) {
                this.requestServices.updatePlayer(this.form.getRawValue()[pIndex]).subscribe((res: any) => {
                    this.request = res.request;
                });
            }
        }
    }

    addPlayer() {
        this.form.push(
            this.fb.group({
                id: null,
                first_name: [null, Validators.required],
                last_name: [null, Validators.required],
                gender: ['Male', Validators.required],
                hcp: [null, Validators.required],
                client_id: null,
                destinations: [this.request.destinations.length == 1 ? [this.request.destinations[0].id] : null, Validators.required]
            })
        );
    }

    removePlayer(playerIndex) {
        if (this.form.controls[playerIndex].value.id == null) {
            this.form.removeAt(playerIndex);
        } else {
            if (!this.requestServices.checkPlayerAssignee(this.request, playerIndex)) {
                this.messageService.add({
                    severity: 'error', summary: 'Delete Player',
                    detail: 'A player assigned to a tee time cannot be deleted'
                });
            } else {
                this.confirmationService.confirm({
                    message: this.translate.transform('confirmMessage'),
                    accept: () => {
                        this.requestServices.deletePlayer(this.request.players[playerIndex].id).subscribe((res: any) => {
                            this.request = res.request;
                            this.form.removeAt(playerIndex);
                        });
                    },
                    acceptLabel: this.translate.transform('yes'),
                    rejectLabel: this.translate.transform('no'),
                    header: this.translate.transform('confirmation')
                });
            }
        }
    }

    close() {
        this.closeModal.emit(this.request.players);
    }

    submit() {
        if (!this.form.valid) {
            this.form.get('players').markAllAsTouched();
            return false;
        }
        const newPlayers = this.form.value.filter(player => player.id == null);
        if (newPlayers.length > 0) {
            this.requestServices.createPlayerBulk({players: newPlayers}, this.request.id).subscribe((res: any) => {
                this.request = res.request;
                this.close();
            });
        } else {
            this.close();
        }
    }

}
