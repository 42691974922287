<p-toast></p-toast>

<form [formGroup]="regionForm" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5 class="p-mb-4">{{(isEditMode? 'editContinent': 'addContinent') | translate}} </h5>
            </div>
            <div class="p-col">
                <button pButton type="submit" class="float-right ml-3">
                    {{isEditMode? ('saveChanges' | translate) : 'Create'}}</button>
                <app-switch-input
                    [label]="regionForm.controls['status'].value ? ('active' | translate) : ('inactive' | translate)"
                    [formControl]="regionForm.controls['status']" [inline]="true" class="float-right mr-3">
                </app-switch-input>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <app-text-input
                    [formControl]="regionForm.controls['name_en']"
                    [label]="'Name EN'"
                    [type]="'text'">
                </app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-text-input
                    [formControl]="regionForm.controls['name_de']"
                    [label]="'Name DE'"
                    [type]="'text'">
                </app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-text-input
                        [formControl]="regionForm.controls['code']"
                        [label]="'Code'"
                        [type]="'text'">
                </app-text-input>
            </div>

        </div>
    </div>

    <!--<div class="buttons">
        <button pButton type="submit" class="float-right">{{isEditMode? ('saveChanges' | translate) : 'Create'}}</button>
    </div>-->
</form>
