import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Area, City, Country, Region} from '../../../_models/destinations';
import {Lookup} from '../../../_models/lookup';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {DestinationsService} from '../../_services/destinations.service';
import {AccountService} from '../../../_services/account.service';

@Component({
    selector: 'app-area-form',
    templateUrl: './area-form.component.html',
    styleUrls: ['./area-form.component.css']
})
export class AreaFormComponent implements OnInit, OnChanges {

    form: UntypedFormGroup;
    area: any;
    isEditMode = false;
    @Input() areaId: number;
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    langKey: string;
    countriesLoaded = false;
    citiesLoaded = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private msgService: MessageService, private router: Router,
        private activatedRoute: ActivatedRoute, private accountService: AccountService,
        private fb: UntypedFormBuilder, private confirmationService: ConfirmationService,
        private destService: DestinationsService, private translate: TranslatePipe,
        public translateService: TranslateService) {
    }

    ngOnInit() {
        this.langKey = this.translateService.currentLang === 'de' ? 'name_de' : 'name_en';
        // init form
        this.initForm();

        // get Regions for dropdown select
        this.getRegions();

        // check edit mode
        const areaId = this.areaId || +this.activatedRoute.snapshot.paramMap.get('id');
        if (areaId > 0) {
            this.isEditMode = true;
            if (!this.accountService.checkPermission('1697457201874')) {
                this.form.get('region_id').disable();
                this.form.get('country_id').disable();
                this.form.get('city_id').disable();
            }
            this.fetchArea(areaId);
        }
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('areas'), routerLink: '/destinations/areas'},
            {label: this.translate.transform(this.isEditMode ? 'editArea' : 'newArea')},
        ]);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initForm();
        this.fetchArea(this.areaId);
    }

    fetchArea(areaId) {
        this.getArea(areaId).subscribe((area: any) => {
            this.area = area;
            this.fillDestinations();
            this.form.patchValue(this.area);
        });
    }

    initForm(): void {
        this.form = this.fb.group({
            id: [''],
            translations: this.fb.array([
                this.fb.group({
                    language_id: 1,
                    name: [null]
                }),
                this.fb.group({
                    language_id: 2,
                    name: [null]
                })
            ]),
            code: ['', Validators.required],
            status: [1],
            region_id: [null, Validators.required],
            country_id: [null, Validators.required],
            city_id: [null, Validators.required],
            name_en: [null, Validators.required],
            name_de: [null, Validators.required],
        });
    }

    getArea(id: number): Observable<Area> {
        return this.destService.getArea(id);
    }

    get translations() {
        return this.form.get('translations') as UntypedFormArray;
    }

    submit() {
        // check form validation
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return false;
        }

        // submit form
        const areaData: Area = {...this.form.value, ...this.form.value.destinations};
        areaData.translations[0].name = areaData.name_en;
        areaData.translations[1].name = areaData.name_de;

        // check if edit mode is active
        if (!this.isEditMode) {
            // add new Area
            this.destService.addArea(areaData).subscribe(res => {
                if (res) {
                    this.msgService.add({
                        severity: 'success',
                        summary: this.translate.transform('areas'),
                        detail: this.translate.transform('addAreaMessage')
                    });
                    this.resetPage();
                }
            });
        } else {
            // update City
            this.destService.updateArea(areaData).subscribe((res: any) => {
                if (res) {
                    this.area = res.area;
                    this.msgService.add({
                        severity: 'success',
                        summary: this.translate.transform('areas'),
                        detail: this.translate.transform('updateAreaMessage')
                    });
                }
            });
        }
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

    //////////////// Destination Section ///////////////
    getRegions(id?: number) {
        if (id) {
            this.destService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    regionChanged() {
        this.form.get('country_id').setValue(null);
        this.form.get('city_id').setValue(null);

        this.countries = [];
        this.cities = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;

        this.getCountries(null, this.form.get('region_id').value);
    }

    countryChanged() {
        this.form.get('city_id').setValue(null);

        this.cities = [];

        this.citiesLoaded = false;

        this.getCities(null, this.form.get('country_id').value);
    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    fetchDestinations(item) {
        this.getCountries(item.country_id);
        this.getCities(item.city_id);
    }

    fillDestinations() {
        this.countries = [this.area.country];
        this.cities = [this.area.city];
    }

    //////////////// Destination Section ///////////////

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

}
