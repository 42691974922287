// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://gap-sapi.golfglobe.com/api/', // development and staging link
  webHookUrl: 'https://staging2.golfglobe.com/wp-json/wp-gap/v1/',
  token: 'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9eyJjb21wYW55IjoiR0FQIiwibmFtZ',
  firebaseConfig: {
    apiKey: 'AIzaSyB-tIlzMdAFPElAmpUG6b3oBEPj5waB0F8',
    authDomain: 'gap-app-6edc1.firebaseapp.com',
    projectId: 'gap-app-6edc1',
    storageBucket: 'gap-app-6edc1.appspot.com',
    messagingSenderId: '930462278538',
    appId: '1:930462278538:web:7d3ec231e1b8871b009e73',
    measurementId: 'G-SLMJRJT6QS'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
