import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

    constructor(private translate: TranslatePipe, private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit(): void {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('golfCourses'), routerLink: '/golf-courses'},
            {label: this.translate.transform('services')},
        ]);
    }

}
