import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AddressBookService {

    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getAllContacts(companyId) {
        return this.http.get(this.baseUrl + 'address-books?company_id=' + companyId);
    }

    getContactsById(id) {
        return this.http.get<Document>(this.baseUrl + 'address-books/' + id);
    }

    addContact(obj) {
        return this.http.post(this.baseUrl + 'address-books', obj);
    }

    updateContact(obj, id) {
        return this.http.put(this.baseUrl + 'address-books/' + id, obj);
    }

    deleteContact(id) {
        return this.http.delete(this.baseUrl + 'address-books/' + id);
    }
}
