<div class="p-5">
    <div class="p-grid">
        <div class="p-col-12 text-center">
            <img src="../../../../assets/layout/images/pages/golf-logo-min.png" height="100"
                 width="100">
        </div>
        <div class="p-col-12">
            <p class="text-center m-0"><b>Tee Time Voucher</b></p>
        </div>
    </div>
    <div class="p-grid mt-5">
        <div class="p-col-12">
            <p *ngIf="showExpiredVoucher" class="hint">Voucher Expired</p>
            <p *ngIf="showNotExistVoucher" class="hint">Invalid Voucher</p>

            <p-table *ngIf="!showNotExistVoucher && !showExpiredVoucher"
                     [value]="teeTime" responsiveLayout="scroll" styleClass="participants-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Number Of Players</th>
                        <th>Golf Course</th>
                        <th>Date</th>
                        <th>Time</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-teeTime>
                    <tr>
                        <td>{{request.players.length}}</td>
                        <td>{{teeTime.golf_course_name}}</td>
                        <td>{{teeTime.date | date: 'dd.MM.yyyy'}}</td>
                        <td>{{teeTime.conf_time.slice(0, -3)}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
