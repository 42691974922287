<div class="p-grid">
  <div class="p-md-3">
    <app-tree-view [object]="'Products'" [l4Data]="services" [l5Data]="this.products ? this.products : null" (selectedCity)="citySelected($event)"
                   (selectedL4Item)="productSelected($event)" (selectedL5Item)="serviceSelected($event)"></app-tree-view>
  </div>
  <div class="p-md-9 mt-1">
    <app-gfp-form-single *ngIf="selectedProduct?.is_package == 0"
                         [productId]="selectedProduct.id"></app-gfp-form-single>

    <app-gfp-package *ngIf="selectedProduct?.is_package == 1"
                         [productId]="selectedProduct.id"></app-gfp-package>
  </div>
</div>
