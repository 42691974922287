import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../../../_models/lookup';
import {GolfCourse} from '../../../../_models/golf-course';
import {GolfCoursesService} from '../../../../golf-courses/_services/golf-courses.service';
import {GfpService} from '../../../_services/gfp.service';
import {ProductAPIService} from '../../../_services/product-api.service';
import {Company} from '../../../../_models/company';
import {CompaniesService} from '../../../../_services/companies-service';
import {HotelsService} from '../../../../hotels/_services/hotels.service';
import {ProductService} from '../../../../_models/product-service';
import {SharedService} from '../../../../_services/shared.service';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../../../breadcrumb.service';

@Component({
    selector: 'app-gfp-form-single',
    templateUrl: './gfp-form-single.component.html',
    styleUrls: ['./gfp-form-single.component.css']
})
export class GfpFormSingleComponent implements OnInit, OnChanges {

    editMode = false;
    form: UntypedFormGroup;
    services: ProductService[];
    teeTimes: Lookup[];
    holes: Lookup[];
    golfCourses: GolfCourse[];
    bookingOptions = ['Hotel', 'Region'];
    serviceHandlerTypes: Lookup[] = [];
    bookings: Company[] = [];
    companies: Company[];
    serviceHandlersTypesIds = [3, 4, 6];
    serviceHandlers: Company[];
    providers: Company[] = [];
    showServiceInfoButton = true;
    selectedService: any;
    product: any;
    @Input() productId: number;

    constructor(private fb: UntypedFormBuilder, private golfCoursesService: GolfCoursesService, private sharedService: SharedService,
                private gfpServices: GfpService, private productsServices: ProductAPIService, private router: Router,
                private companiesService: CompaniesService, private hotelServices: HotelsService,
                private messageService: MessageService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
        if (this.route.snapshot.paramMap.get('id')) {
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Product Setup'},
                {label: 'Products', routerLink: '/product-setup/gfp'},
                {label: 'Edit Product'}
            ]);
        }
    }

    ngOnInit(): void {
        this.initForm();
        this.getGolfCourses();
        this.getTeeTimes();
        this.getHoles();
        this.getCompanyTypes();
        if (this.productId || this.route.snapshot.paramMap.get('id')) {
            this.fetchData();
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.form) {
            this.resetPage();
            this.initForm();
        }
        this.fetchData();
    }

    initForm(): void {
        this.form = this.fb.group({
            id: null,
            name: [null, Validators.required],
            golf_course_id: [null, Validators.required],
            service_id: [null, Validators.required],
            tee_time_id: [null, Validators.required],
            hole_id: [null, Validators.required],
            ref_code: [null],
            code: [null],
            tui_code: [null],
            additional_information: [null],
            validity_from: [new Date(), Validators.required],
            validity_to: [new Date(new Date().setFullYear(new Date().getFullYear() + 1)), Validators.required],
            junior: [0],
            buggy: [0],
            multi_players_only: [0],
            invoice_handler_id: null,
            service_handler_type_id: [null, Validators.required],
            service_handler_id: [{value: null, disabled: true}],
            booking_possible_for: [null, Validators.required],
            hotels: [{value: null, disabled: true}],
            use_service_configurations: [1],
            status: [1]
        });
        this.serviceInfoButtonClick({checked: 1});
    }

    async fetchData() {
        this.editMode = true;
        const productId = this.productId ? this.productId : +this.route.snapshot.paramMap.get('id');

        this.product = await this.gfpServices.getProduct(productId).toPromise();
        this.product.validity_to = new Date(this.product.validity_to);
        this.product.validity_from = new Date(this.product.validity_from);

        this.form.patchValue(this.product);

        await this.getServiceById(this.product.service_id);
        if (this.product.booking_possible_for == 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }
        this.form.get('service_id').setValue({
            id: this.product.service_id,
            name: this.product.service_name
        });
        this.form.controls.invoice_handler_id.setValue({
            id: this.product.invoice_handler_id,
            name: this.product.invoice_handler_name
        });
        ////////////// Fetch Hotels /////////////////
        const hotelsObj = [];
        this.product.hotels.forEach((hotel: any) => hotelsObj.push(hotel.id));
        this.form.controls.hotels.setValue(hotelsObj);
        ////////////// Fetch Hotels /////////////////
        if (this.product.use_service_configurations == 1) {
            this.form.get('use_service_configurations').setValue(1);
            await this.serviceInfoButtonClick({checked: 1});
        }
        await this.serviceHandlerTypeChanged(this.product.service_handler_type_id);
    }
    async getServiceById(id) {
        await this.productsServices.getService(id).toPromise().then((res: any) => {
            this.selectedService = res;
        });
    }

    getGolfCourses() {
        this.golfCoursesService.getAllGolfCourses().subscribe((res: any) => {
            this.golfCourses = res;
        });
    }

    getTeeTimes() {
        this.gfpServices.getTeeTimes().subscribe((res: any) => {
            this.teeTimes = res.tee_times;
            this.teeTimes.shift();
        });
    }

    getHoles() {
        this.gfpServices.getHoles().subscribe((res: any) => {
            this.holes = res.holes;
        });
    }

    async searchCompanies(event) {
        this.companiesService.searchCompanies(event.query).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    searchServices(event) {
        this.productsServices.searchService(event.query).subscribe((res: any) => {
            this.services = res;
        });
    }

    async serviceHandlerTypeChanged(value) {
        if (value === 3 || value == null) {
            // golf club type selected
            this.form.get('service_handler_id').disable();
            this.form.get('service_handler_id').setValue(null);
            this.serviceHandlers = [];
            this.form.get('service_handler_id').removeValidators(Validators.required);
        } else {
            await this.companiesService.getAllCompaniesByType(value).toPromise().then((res: any) => {
                this.serviceHandlers = res.companies;
                if (this.form.get('use_service_configurations').value == 0) {
                    this.form.get('service_handler_id').enable();
                    this.form.get('service_handler_id').setValidators(Validators.required);
                }
            });
        }
    }

    async bookingPossibleChanged(value) {
        if (value == 'Hotel') {
            if (this.selectedService.cities.length > 0) {
                const citiesIds = [];
                this.selectedService.cities.forEach(city => citiesIds.push(city.id));
                this.hotelServices.getAllHotelsByCitiesList(citiesIds).subscribe((res: any) => {
                    this.bookings = res.hotels;
                    if (this.form.get('use_service_configurations').value == 0) {
                        this.form.get('hotels').enable();
                        this.form.get('hotels').setValidators(Validators.required);
                    }
                });
            } else {
                this.hotelServices.getAllHotelsByCity(this.selectedService.country_id).subscribe((res: any) => {
                    if (this.form.get('use_service_configurations').value == 0) {
                        this.form.get('hotels').enable();
                        this.form.get('hotels').setValidators(Validators.required);
                    }
                });
            }
        } else {
            this.bookings = [];
            this.form.get('hotels').setValue(null);
            this.form.get('hotels').disable();
            this.form.get('hotels').removeValidators(Validators.required);
        }
    }

    async serviceInfoButtonClick(event) {
        if (event.checked == 1) {
            this.disabilitySwitch(true);
            if (this.form.get('service_id').value !== null) {
                await this.fillServiceInfo();
            }
        } else {
            this.disabilitySwitch(false);
        }
    }

    async fillServiceInfo() {
        await this.serviceHandlerTypeChanged(this.selectedService.service_handler_type_id);
        if (this.selectedService.booking_possible_for == 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }
        this.form.get('invoice_handler_id').setValue({
            id: this.selectedService.invoice_handler_id,
            name: this.selectedService.invoice_handler_name
        });
        this.form.get('service_handler_type_id').setValue(this.selectedService.service_handler_type_id);
        this.form.get('service_handler_id').setValue(this.selectedService.service_handler_id);
        this.form.get('booking_possible_for').setValue(this.selectedService.booking_possible_for);
        const hotelsIds = [];
        console.log(this.selectedService);
        this.selectedService.hotels.forEach(hotel => {
            hotelsIds.push(hotel.id);
        });
        this.form.get('hotels').setValue(hotelsIds);
    }

    disabilitySwitch(disable: boolean) {
        if (disable) {
            this.form.get('invoice_handler_id').disable();
            this.form.get('service_handler_type_id').disable();
            this.form.get('service_handler_id').disable();
            this.form.get('booking_possible_for').disable();
            this.form.get('hotels').disable();
        } else {
            this.form.get('invoice_handler_id').enable();

            this.form.get('service_handler_type_id').enable();

            this.form.get('service_handler_type_id').value == null ? this.form.get('service_handler_id').disable() :
                this.form.get('service_handler_id').enable();

            this.form.get('booking_possible_for').enable();

            this.form.get('booking_possible_for').value == null ? this.form.get('hotels').disable() :
                this.form.get('hotels').enable();
        }
    }

    async getCompanyTypes() {
        this.companiesService.getCompanyTypes().subscribe((res: any) => {
            this.serviceHandlersTypesIds.forEach(type => {
                this.serviceHandlerTypes.push(res.filter(x => x.id === type)[0]);
            });
        });
    }

    async serviceChanged(value) {
        this.selectedService = value;
        if (value == null && this.form.get('use_service_configurations').value == 1) {
            this.clearAlternatives();
        }
        if (value !== null) {
            // this.selectedService.hotels = +this.selectedService.hotels;
            if (this.form.get('use_service_configurations').value == 1) {
                this.fillServiceInfo();
            }
        }
    }

    clearAlternatives() {
        this.form.get('invoice_handler_id').setValue(null);
        this.form.get('service_handler_type_id').setValue(null);
        this.form.get('service_handler_id').setValue(null);
        this.form.get('booking_possible_for').setValue(null);
        this.form.get('hotels').setValue(null);
    }

    submit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        const clone = this.form.getRawValue();
        clone.invoice_handler_id = clone.invoice_handler_id.id;
        clone.service_id = clone.service_id.id;
        clone.validity_from = this.sharedService.dateToIsoString(clone.validity_from).slice(0, 10);
        clone.validity_to = this.sharedService.dateToIsoString(clone.validity_to).slice(0, 10);
        if (!clone.hotels) {
            clone.hotels = [];
        }

        if (!this.editMode) {
            this.gfpServices.addProduct(clone).subscribe(() => {
                this.initForm();
                this.messageService.add({
                    severity: 'success', summary: 'Create Product Service',
                    detail: 'Product service has been Added successfully'
                });
            });
        } else {
            this.gfpServices.updateProduct(clone).subscribe(() => {
                this.messageService.add({
                    severity: 'success', summary: 'Update Product Service',
                    detail: 'Product service has been updated successfully'
                });
            });
        }
    }
    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }
}
