<app-news-slider></app-news-slider>
<div *ngIf="user.company_type_id == 2" class="card toolbar-demo">
    <div class="p-grid p-jc-between">
        <div class="p-col-12">
            <h5>{{'LatestSuccessfulRequests' | translate}}</h5>
        </div>
    </div>
    <p-table [value]="statistics?.latest_success_request">
        <ng-template pTemplate="header">
            <tr>
                <th>{{'Id' | translate}}</th>
                <th>{{'Name' | translate}}</th>
                <th>{{'TUI Code' | translate}}</th>
                <th>{{'CreatedAt' | translate}}</th>
                <th>{{'SubmittedAt' | translate}}</th>
                <th>{{'ApprovedAt' | translate}}</th>
                <th>{{'ConfirmedAt' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-request>
            <tr>
                <td>{{request.id}}</td>
                <td>{{request.travel_agency}}</td>
                <td>{{request.tui_code}}</td>
                <td>{{request.created_at | date: 'dd.MM.yyyy'}}</td>
                <td>{{request.submited_at | date: 'dd.MM.yyyy'}}</td>
                <td>{{request.approved_at | date: 'dd.MM.yyyy'}}</td>
                <td>{{request.confirmed_at | date: 'dd.MM.yyyy'}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="card toolbar-demo">
    <div class="p-grid p-jc-between">
        <div class="p-col-12">
            <h5>{{'gapInNumbers' | translate}}</h5>
        </div>
        <div class="p-grid layout-dashboard">
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/hotel-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Hotels</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.hotels_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/handshake-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>DMC</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.dmcs_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-3">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/plane-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Travel Agencies</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.travel_agencies_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-4">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/building-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Golf Clubs</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.golf_clubs_count}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-4">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/golf-ball-tee-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Golf Courses</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.golf_courses_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/globe-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Tour Operators</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.tour_operators_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/code-pull-request-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Requests</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.requests_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-3">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/users-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Users</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.users_count}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-3">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/question-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Enquiries</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.enquiries_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-4">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/product-golf.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Golf Products</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.products_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/product-hotel.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Hotel Products</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.hotel_products_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/product-hunt-brands.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Golf Holiday Products</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.golf_holidays_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/user-tie-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Customers</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.clients_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-3">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/person-walking-with-cane-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Players</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.players_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-4">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/earth-europe-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Regions</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.regions_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/earth-oceania-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Countries</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.countries_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img class="svg-color-one" src="assets/dashboard/city-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Cities</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.cities_count}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img class="svg-color-one" src="assets/dashboard/average.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Tee times / Requests</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{(statistics?.statistics?.request_product_tee_time_count /
                    statistics?.statistics?.requests_count | number : '1.2-2')}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-3">
                    <div class="p-col-3 overview-box-icon">
                        <img class="svg-color-one" src="assets/dashboard/average.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Golf Courses / Requests</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.cities_count}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="user.company_type_id == 2" class="card toolbar-demo">
    <div class="p-grid p-jc-between">
        <div class="p-col-12">
            <h5>TA Overview</h5>
        </div>
        <div class="p-grid layout-dashboard" style="width: 100%">
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/code-pull-request-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Requests</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.travel_agency?.requests}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-3">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/users-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Users</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.travel_agency?.users}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-4">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/user-tie-solid2.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Customers</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.travel_agency?.clients}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/earth-oceania-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Destinations</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.travel_agency?.destinations}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/question-solid2.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Tee Times</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.statistics?.request_product_tee_time_count}}</div>
                </div>
            </div>

            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/person-walking-with-cane-solid2.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Players</h5></div>
                    <div class="p-col-3 overview-box-status">{{statistics?.travel_agency?.players}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="user.company_type_id == 2 && showMonthsChart" class="card toolbar-demo">
    <div class="p-grid p-jc-between">
        <div class="p-col-12">
            <h5>{{'requestsPerMonth' | translate}}</h5>
        </div>
    </div>
    <p-chart type="bar" [data]="monthsChartData" [options]="monthsChartOptions"></p-chart>
</div>
<div class="row">
    <div class="col-6">
        <div *ngIf="user.company_type_id == 2 && showCoursesChart" class="card toolbar-demo">
            <div class="p-grid p-jc-between">
                <div class="p-col-12">
                    <h5>{{'mostRequestedCourses' | translate}}</h5>
                </div>
            </div>
            <p-chart type="polarArea" [data]="coursesChartData" [options]="monthsChartOptions"></p-chart>
        </div>
    </div>
    <div class="col-6">
        <div *ngIf="user.company_type_id == 2 && showHotelsChart" class="card toolbar-demo">
            <div class="p-grid p-jc-between">
                <div class="p-col-12">
                    <h5>{{'mostRequestedHotels' | translate}}</h5>
                </div>
            </div>
            <p-chart type="pie" [data]="hotelsChartData" [options]="monthsChartOptions"></p-chart>
        </div>
    </div>
</div>
<div *ngIf="user.company_type_id == 2 && showHeatMap" class="card toolbar-demo mt-4">
    <div class="p-grid p-jc-between">
        <div class="p-col-12">
            <h5>{{'mostRequestedHours' | translate}}</h5>
        </div>
    </div>
    <div id="chart">
        <apx-chart
                [series]="heatMapOptions.series"
                [chart]="heatMapOptions.chart"
                [dataLabels]="heatMapOptions.dataLabels"
                [title]="heatMapOptions.title"
                [colors]="heatMapOptions.colors"
        ></apx-chart>
    </div>
</div>
