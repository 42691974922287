<ng-container *ngIf="route.snapshot.data">
    <app-players-section *ngIf="request?.players?.length > 0" [request]="request" [viewOnly]="true"
    ></app-players-section>
    <div class="card toolbar-demo mt-3">
        <div class="p-grid p-jc-between">
            <div class="p-col-6">
                <span><strong>Booking numbers: </strong>
                    <span *ngFor="let item of request.booking_codes; let i = index;">
                        {{item.booking_code + (i == request.booking_codes.length - 1 ? '' : ', ')}}</span>
                </span>
            </div>
            <div *ngIf="request.tui_ref_code" class="p-col-6">
                <span><strong>SPNR (TUI-CODE): </strong>{{request.tui_ref_code}}</span>
            </div>
        </div>
    </div>
</ng-container>
<br>
<form [formGroup]="destinationsForm" (ngSubmit)="submit()">
    <div class="card toolbar-demo dest-card" *ngFor="let destination of request?.destinations; let dIndex = index">
        <div class="p-grid">
            <div class="p-col-12 p-md-col"><strong>Destination:</strong> {{destination.city.name_en}}</div>
            <div *ngIf="destination.hotel_id" class="p-col-12 p-md-col"><strong>Hotel: </strong>
                <a [routerLink]="'/hotels/edit/' + destination.hotel_id" target="_blank">
                    {{destination.hotel_name}}</a>
            </div>
            <div class="p-col-12 p-md-col"><strong>Arrival date:</strong>
                {{destination.arrival_date | date: 'dd.MM.yyyy'}}</div>
            <div class="p-col-12 p-md-col"><strong>Departure date:</strong>
                {{destination.departure_date | date: 'dd.MM.yyyy'}}</div>
        </div>
        <p-divider></p-divider>
        <h6 class="mt-2">GFP Details</h6>
        <div class="card toolbar-demo" *ngFor="let product of destination.products; let pIndex = index">
            <div class="p-grid">
                <div class="p-col-12 p-md-4 half-rem-padding"><strong>Name: </strong><span
                        *ngIf="isPortal">{{product.name}}</span>
                    <a *ngIf="!isPortal" [routerLink]="'/product-setup/gfp/' + (product.is_package ? 'edit-package/' : 'edit/')
                    + product.product_id" target="_blank">{{product.name}}</a>
                </div>
                <ng-container *ngIf="user.company_id === 1">
                    <div class="p-col-12 p-md-2"><strong>GFP ID:</strong> {{product.code}}</div>
                    <div class="p-col-12 p-md-2"><strong>@ComRes Booking Code:</strong> {{product.tui_code}}</div>
                    <div class="p-col-12 p-md-2"><strong>Tee Times:</strong>
                        {{product.tee_time_name}}</div>
                    <div class="p-col-12 p-md-2"><strong>Bookable for:</strong>
                        {{product.booking_possible_for}}</div>
                </ng-container>
            </div>

            <p-divider *ngIf="product.is_package && user.company_id === 1"></p-divider>
            <div *ngIf="product.availableCourses.length > 0 && user.company_id === 1" class="p-grid">
                <div *ngIf="product.is_package" class="p-col-12 p-md-12">
                    <ul class="desc-ul float-left m-0">
                        <li *ngFor="let item of product.availableCourses">
                            {{item.type == 'Fixed' ? item.tee_time_name :
                            (item.min_tee_time_name + ' to ' + item.max_tee_time_name)}} x {{item.golf_course_name}}
                        </li>
                    </ul>
                </div>
            </div>
            <p-divider></p-divider>
            <h6 style="text-decoration: underline">Tee Times</h6>
            <p-table [value]="_players(dIndex, pIndex)?.controls" dataKey="id"
                     [scrollable]="true"
                     [scrollDirection]="'both'">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 30%">Golf course</th>
                        <th style="width: 20%">Date</th>
                        <th style="width: 15%">Preferred time slot</th>
                        <th style="width: 15%">Hours</th>
                        <th style="width: 20%">From - To</th>
                        <!--<th></th>-->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-player let-i="rowIndex">
                    <tr>
                        <td style="width: 30%">
                            <div class="p-field m-0 full-width">
                                <p-dropdown class="full-width" optionLabel="golf_course_name" optionValue="id"
                                            [options]="product.availableCourses"
                                            [formControl]="_player(dIndex, pIndex, i).get('request_product_details_id')"
                                            [showClear]="true" optionDisabled="disable"
                                            (onClick)="checkGolfCourseAvailability(dIndex, pIndex)"
                                            (onChange)="checkHCPLimit(dIndex, pIndex)" [appendTo]="'body'"
                                            placeholder="Select golf course"></p-dropdown>
                                <small *ngIf="_player(dIndex, pIndex, i).get('request_product_details_id').touched &&
                    _player(dIndex, pIndex, i).get('request_product_details_id').errors?.required"
                                       class="p-error">required</small>
                            </div>
                        </td>
                        <td style="width: 20%">
                            <div class="p-field m-0">
                                <p-calendar showIcon="true" dateFormat="dd.mm.yy" [appendTo]="'body'"
                                            (onSelect)="dateChanged($event, dIndex, pIndex, i)"
                                            [formControl]="_player(dIndex, pIndex, i).get('date')"
                                            [minDate]="calcMinDate(dIndex)" [maxDate]="calcMaxDate(dIndex)"
                                            placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                                <small *ngIf="_player(dIndex, pIndex, i).get('date').touched &&
                    _player(dIndex, pIndex, i).get('date').errors?.required"
                                       class="p-error">required</small>
                            </div>
                        </td>
                        <td style="width: 15%">
                            <div class="p-field m-0">
                                <p-inputMask mask="99:99"
                                             [formControl]="_player(dIndex, pIndex, i).get('pref_time')"
                                             placeholder="10:00" (onBlur)="calcTimeFromTo(dIndex, pIndex, i)"
                                             styleClass="request-input-mask"></p-inputMask>
                                <small *ngIf="_player(dIndex, pIndex, i).get('pref_time').touched &&
                    _player(dIndex, pIndex, i).get('pref_time').errors?.required"
                                       class="p-error">required</small>
                                <small *ngIf="!_player(dIndex, pIndex, i).get('pref_time').errors?.required &&
                                            _player(dIndex, pIndex, i).get('pref_time').errors?.pattern"
                                       class="p-error">Invalid time</small>
                            </div>
                        </td>
                        <td style="width: 15%">
                            <div class="field">
                                <p-inputNumber [formControl]="_player(dIndex, pIndex, i).get('time_margin')"
                                               [disabled]="_player(dIndex, pIndex, i).get('pref_time').value == null"
                                               [showButtons]="true" buttonLayout="horizontal" inputId="horizontal"
                                               spinnerMode="horizontal" [step]="1" [min]="1"
                                               [max]="marginMaxTime(dIndex, pIndex, i)"
                                               (onInput)="calcTimeFromTo(dIndex, pIndex, i, $event.value)"
                                               decrementButtonClass="p-button-primary"
                                               incrementButtonClass="p-button-primary"
                                               incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                               mode="decimal" styleClass="request-input-num">
                                </p-inputNumber>
                                <small *ngIf="_player(dIndex, pIndex, i).get('time_margin').touched &&
                    _player(dIndex, pIndex, i).get('time_margin').errors?.required"
                                       class="p-error">required</small>
                            </div>
                        </td>

                        <td style="width: 20%">{{_player(dIndex, pIndex, i).get('time_from').value?.slice(0, -3) + ' - ' +
                        _player(dIndex, pIndex, i).get('time_to').value?.slice(0, -3)}}</td>
                        <!--<td>
                            <img *ngIf="i == 0 && _players(dIndex, pIndex).controls.length > 1"
                                 [src]="'../../../assets/icons/' + (_player(dIndex, pIndex, i).get('link').value == 1 ?
                             'link-solid' : 'unlink-solid') + '.svg'" width="14px" height="14px" class="ml-3 link-icon"
                                 [pTooltip]="_player(dIndex, pIndex, i).get('link').value == 1 ?
                                'Changes made to this player will automatically be applied to all other players in the same product' :
                                '[Inactive] If active, changes made to this player will automatically be applied to all ' +
                                 'other players in the same product'" tooltipPosition="top"
                                 (click)="_player(dIndex, pIndex, i).get('link').setValue(!_player(dIndex, pIndex, i).get('link').value)">
                        </td>-->
                    </tr>
                </ng-template>
            </p-table>
            <p-divider *ngIf="request.destinations[dIndex].products[pIndex].request_tee_times.length == 0"></p-divider>
            <ul class="invalid-controls">
                <li *ngFor="let error of this.request.destinations[dIndex].products[pIndex].minCounterError">
                    {{error.name}} must be selected {{error.type == 'min' ? 'at least' : ''}} {{error.minValue}}
                    time{{error.minValue > 1 ? 's' : ''}}
                </li>
            </ul>
            <p *ngIf="request.destinations[dIndex].products[pIndex].showDateWarning" class="date-warning">
                <strong>ATTENTION</strong>, when booking start times on the arrival or departure day.
                There is basically no right to reimbursement of the costs if the customers
                should not be able to take advantage of start time
                (e.g. due to changes in flights times or delay in the flight or transfer)!
            </p>
            <p *ngIf="request.destinations[dIndex].products[pIndex].hcpWarning" class="date-warning">
                <strong>ATTENTION</strong>, The HCP of one or more player/s exceeds the HCP of the Golf Course/s!
            </p>
            <p *ngIf="request.destinations[dIndex].products[pIndex].showNotSavedError" class="p-error">
                These tee times records have not been saved</p>
            <div *ngIf="request?.status_id === 1" class="buttons">
                <button pButton class="float-right" type="button" (click)="CreateTeeTimes(dIndex,pIndex)">
                    {{request.destinations[dIndex].products[pIndex].request_tee_times.length == 0 ? 'Save Tee Times' :
                    'Update Tee Times'}}
                </button>
            </div>
        </div>
    </div>

    <div class="p-grid mt-3 mb-3 pl-3 pr-3">
        <div class="p-col-12">
            <div class="p-field">
                <label>Notes</label>
                <textarea pInputTextarea rows="2" class="full-width" type="text" [(ngModel)]="notes" name="notes"
                          (blur)="updateNotes($event)"
                          [ngModelOptions]="{standalone: true}"></textarea>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-checkbox">
                <p-checkbox [formControl]="confirmForm.get('confirmOne')" [binary]="true" inputId="binary"></p-checkbox>
                <label style="margin: -20px 0 0 30px">I have informed my customer about the General terms and conditions
                    of
                    business as well as about the cancellation conditions referred to below.
                    In particular it has been pointed out to the customer that the customer is exclusively
                    responsible for fulfilling golf course handicap requirements.</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-checkbox">
                <p-checkbox [formControl]="confirmForm.get('confirmTwo')" [binary]="true" inputId="binary"></p-checkbox>
                <label style="margin: -20px 0 0 30px">I confirm that this is a binding reservation/booking and that I
                    have
                    Informed my customer of this. No right exists for withdrawing from the reservation/booking free of
                    charge.
                    Items which are confirmed as requested can only be cancelled on the basis of the general terms and
                    conditions of business or the cancellation conditions. Items which are confirmed without being
                    requested can be cancelled free of charge as soon as you receive confirmation of the
                    enquiry. </label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-checkbox">
                <p-checkbox [formControl]="confirmForm.get('confirmThree')" [binary]="true"
                            inputId="binary"></p-checkbox>
                <label style="margin: -20px 0 0 30px">Cancellation fee/s & conditions per person and tee times:
                    If all data is correct, you can now send the request.
                    On successful submit, you will receive a confirmation page.
                    You should print this confirmation and let your customer sign one copy. </label>
            </div>
        </div>
    </div>

    <button *ngIf="isPortal" pButton pRipple type="button" label="Submit Changes"
            class="mt-2 full-width" (click)="submitPortal()"
            [disabled]="!confirmForm.value.confirmOne || !confirmForm.value.confirmTwo"></button>
    <div class="buttons mt-2" *ngIf="!isPortal">
        <button pButton (click)="goBack()" label="Previous Stage" type="button"></button>
        <button *ngIf="request?.sub_status_id == 1" pButton type="button" (click)="submit()" label="Submit Request"
                class="float-right ml-2"
                [disabled]="!confirmForm.value.confirmOne || !confirmForm.value.confirmTwo"></button>
        <button pButton icon="pi pi-print" type="button" (click)="print()" label="Print" class="float-right"></button>
    </div>
</form>
