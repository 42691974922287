<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="roles$ | async" [rows]="10" [paginator]="true"
             [globalFilterFields]="['status','name','no-user','no-permssions']"
             [scrollable]="true" [scrollDirection]="'both'"
             [(selection)]="selectedRoles" [rowHover]="true" dataKey="id"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showJumpToPageInput]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10, 50, 100, 200]">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">Roles</h5>

                    <button pButton pRipple label="Add" icon="pi pi-plus" class="p-button-primary p-ml-2"
                            (click)="addNew()" *ngIf="checkPermission('1636371241875')"></button>
                </div>
                <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                        (click)="deleteSelectedProducts()"
                        [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->


                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636371320559')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="min-width:160px; max-width: 160px;" class="p-d-flex p-jc-between p-ai-center">
                    Status
                    <app-filter fieldName="status" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>

                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="name">Role Name
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="no-user">Number of users
                    <p-sortIcon field="no-user"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="no-permissions">Number of permissions
                    <p-sortIcon field="no-permissions"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371256298') || checkPermission('1636371273090') || checkPermission('1636371287359')"
                >Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-role>
            <tr style="cursor: pointer" (click)="edit(role)">
                <td style="width: 3rem">
                    <p-tableCheckbox [value]="role"></p-tableCheckbox>
                </td>
                <td style="min-width:160px; max-width: 160px;" class="status-td">
                    <span class="status-txt">{{role.status === 1? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td style="min-width:160px; max-width: 160px;">{{role.name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{role.users_count}}</td>
                <td style="min-width:160px; max-width: 160px;">{{role.permissions_count}}</td>

                <td style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371256298') || checkPermission('1636371273090') || checkPermission('1636371287359')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <!-- <li role="menuitem" *ngIf="checkPermission('1636371256298')"><a class="dropdown-item" (click)="view(role)">View</a></li> -->
                            <li role="menuitem" *ngIf="checkPermission('1636371273090')"><a class="dropdown-item" (click)="edit(role)">Edit</a></li>
                            <li role="menuitem" *ngIf="checkPermission('1636371287359')"><a class="dropdown-item" (click)="delete(role)">Delete</a></li>
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="showDialog" header="role Details" [modal]="true" closeOnEscape="true" dismissableMask="true"
          styleClass="p-fluid details-dialog">
    <!--<div class="view-data">
        <div class="p-grid">
            <div class="p-col-12">
                <p-card header="Basic">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <p class="title">role name <span class="content">{{role?.name}}</span></p>
                            <p class="title">role type <span class="content">{{role?.role_type_name}}</span>
                            </p>
                            <p class="title">Website <span class="content">
                            <a [href]="role?.website" target="_blank">{{role?.website}}</a>
                        </span></p>
                            <p class="title">Assigned user <span class="content">{{role?.assigned_username}}</span>
                            </p>
                            <p class="title">Rank <span class="content">{{role?.rank}}</span></p>
                        </div>
                        <div class="p-col-6">
                            <p class="title">Delegator name <span class="content">{{role?.delegator_name}}</span>
                            </p>
                            <p class="title">Delegator email <span class="content">{{role?.delegator_email}}</span>
                            </p>
                            <p class="title">Delegator Phone <span
                                    class="content">{{role?.delegator_mobile_number}}</span></p>
                            <p class="title">Has contract <span class="content">
                                {{role?.contract == 1 ? 'Yes' : 'No'}}
                            </span></p>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12">
                <p-card header="Contact">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <p class="title">Region <span class="content">{{role?.region_name}}</span></p>
                            <p class="title">Country <span class="content">{{role?.country_name}}</span></p>
                            <p class="title">City <span class="content">{{role?.city_name}}</span></p>
                            <p class="title">Street <span class="content">{{role?.street}}</span></p>
                            <p class="title">Postal code <span class="content">{{role?.postal_code}}</span></p>
                        </div>
                        <div class="p-col-6">
                            <p class="title">Phone <span class="content">{{role?.phone}}</span></p>
                            <p class="title">Fax <span class="content">{{role?.fax}}</span></p>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="p-col-12">
                <p-card header="Social">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <p class="title">Facebook <span class="content">
                                <a [href]="role?.facebook" target="_blank">{{role?.facebook}}</a></span></p>
                            <p class="title">Linkedin <span class="content">
                            <a [href]="role?.linkedin" target="_blank">{{role?.linkedin}}</a></span></p>
                        </div>
                        <div class="p-col-6">
                            <p class="title">Twitter <span class="content">
                            <a [href]="role?.twitter" target="_blank">{{role?.twitter}}</a></span></p>
                            <p class="title">Instagram <span class="content">
                            <a [href]="role?.instagram" target="_blank">{{role?.instagram}}</a></span></p>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
    </div>-->
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

