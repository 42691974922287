import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {User} from '../_models/user';
import {ReplaySubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {GcmService} from './gcm.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    baseUrl = environment.apiUrl;
    private currentUserSource = new ReplaySubject<User>(1);
    currentUser$ = this.currentUserSource.asObservable();
    currUser: User;
    notificationsCount = 0;
    notifications: any[];

    constructor(private http: HttpClient, private router: Router, private gcmService: GcmService,
                public translate: TranslateService, private route: ActivatedRoute) {
        translate.addLangs(['en', 'de']);
    }

    login(model: any) {
        return this.http.post<{ status: boolean; user: User; token: string }>(this.baseUrl + 'login', model).pipe(
            map(response => {
                const user = {...response.user, token: response.token};
                user.rememberMe = model.rememberMe;
                if (user) {
                    localStorage.removeItem('user');
                    sessionStorage.removeItem('user');
                    this.setCurrentUser(user);
                }
                return user;
            })
        );
    }

    directLogin(userId) {
        return this.http.post<{ status: boolean; user: User; token: string }>(this.baseUrl + 'direct-login',
            {user_id: userId, device_key: localStorage.getItem('device_key')}).pipe(
            map(response => {
                const user = {...response.user, token: response.token};
                user.rememberMe = true;
                if (user) {
                    this.setCurrentUser(user);
                }
            })
        );
    }

    tokenLogin(token) {
        return this.http.post<{ status: boolean; user: User; token: string }>(this.baseUrl + 'token-login',
            {token: token}).pipe(
            map(response => {
                const user = {...response.user, token: response.token};
                user.rememberMe = false;
                if (user) {
                    this.setCurrentUser(user);
                }
            })
        );
    }


    setCurrentUser(user: User) {
        if (localStorage.getItem('user') || user.rememberMe) {
            localStorage.setItem('user', JSON.stringify({...user, rememberMe: true}));
            if (!localStorage.getItem('menuStatus')) {
                localStorage.setItem('menuStatus', 'active');
            }
        } else {
            sessionStorage.setItem('user', JSON.stringify({...user, rememberMe: false}));
            if (!sessionStorage.getItem('menuStatus')) {
                sessionStorage.setItem('menuStatus', 'active');
            }
        }
        this.currentUserSource.next(user);
        this.currUser = user;
        this.setDefaultLanguage();
    }

    setDefaultLanguage() {
        if (this.currUser?.lang) {
            this.translate.setDefaultLang(this.currUser.lang);
            this.translate.use(this.currUser.lang);
        } else {
            this.translate.setDefaultLang('en');
            this.translate.use('en');
        }
    }

    /**
     * check if the user has the permission or no
     * @param permissionCode Permission code for button or action
     * @param user user data
     * @returns boolean
     */
    checkPermission(permissionCode: string): boolean {
        const permissions = this.currUser.permissions.filter(per => per.code === permissionCode);
        return permissions.length > 0 && permissions[0].status === 1 ? true : false;
    }

    /**
     * check if the user has the permission or no
     * @param id value of permission looking for
     * @param key module_id / page_id
     */
    checkModulePagePermission(id: number, key: string): boolean {
        const permissions = this.currUser.permissions.filter(per => per[key] === id);
        return permissions.length > 0 && permissions[0].status === 1 ? true : false;
    }

    logout() {
        const deviceKey = localStorage.getItem('device_key');
        this.http.post(this.baseUrl + 'logout', {device_key: deviceKey}).subscribe(res => {
            this.gcmService.deleteToken();
            localStorage.clear();
            sessionStorage.clear();
            this.currentUserSource.next(null as any);
            this.router.navigateByUrl('login');
        }, error => {
            this.gcmService.deleteToken();
            localStorage.clear();
            sessionStorage.clear();
            this.currentUserSource.next(null as any);
            this.router.navigateByUrl('login');
        });
    }

    requestVerificationCode(obj) {
        return this.http.post(this.baseUrl + 'forget-password', obj);
    }

    resetPassword(obj) {
        return this.http.post(this.baseUrl + 'forget-password/store', obj);
    }

    changePassword(obj) {
        return this.http.post(this.baseUrl + 'user/reset-password', obj);
    }

    getLatestNotifications() {
        this.http.get(this.baseUrl + 'notifications/last?number=60').subscribe((res: any) => {
            this.notificationsCount = res.not_seen_count;
            this.notifications = res.notifications;
            this.notifications.forEach(noti => {
                noti.shortText = this.truncateChar(noti.body);
            });
        }, error => {
            console.log(error);
            this.logout();
        });
    }

    changeNotificationsToSeen() {
        const nonSeenNotifications = [];
        this.notifications.forEach(noti => {
            if (noti.seen === 0) {
                nonSeenNotifications.push(noti.id);
            }
        });
        if (nonSeenNotifications.length > 0) {
            const obj = {notifications: nonSeenNotifications};
            this.http.put(this.baseUrl + 'notifications/update/seen', obj).subscribe((res: any) => {
                this.notificationsCount = 0;
                this.notifications.forEach(notification => {
                    if (notification.seen === 0) {
                        notification.seen = 1;
                    }
                });
            });
        }
    }

    truncateChar(text: string): string {
        const charLimit = 80;
        if (!text || text.length <= charLimit) {
            return text;
        }
        const withoutHtml = text.replace(/<(?:.|\n)*?>/gm, '');
        const shortened = withoutHtml.substring(0, charLimit) + '...';
        return shortened;
    }

    switchLanguage(lang) {
        this.http.put(this.baseUrl + 'users/' + this.currUser.id + '/' + lang, {})
            .subscribe((res: any) => {
                this.currUser.lang = res.user.lang;
                this.setCurrentUser(this.currUser);
                this.translate.use(lang);
                window.location.reload();
            });
    }
    getUserIP() {
        return this.http.get('https://api.ipdata.co?api-key=5737a39cf891ba37be5a3db9f606c45fc520f21a7a50a6e4483403aa');
    }
}
