import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../../_models/lookup';
import {ConfirmationService} from 'primeng/api';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../_models/product-service';

@Component({
    selector: 'app-hotel-products-form-bulk',
    templateUrl: './hotel-products-form-bulk.component.html',
    styleUrls: ['./hotel-products-form-bulk.component.css']
})
export class HotelProductsFormBulkComponent implements OnInit {

    form: UntypedFormGroup;
    services: Lookup[] = [{id: 1, name: 'Service1'}, {id: 2, name: 'Service2'}, {id: 3, name: 'Service3'}];
    roomTypes: Lookup[] = [{id: 1, name: 'Single'}, {id: 2, name: 'Double'}, {id: 3, name: 'Double for single use'},
        {id: 4, name: 'Suite'}, {id: 5, name: 'Family'}, {id: 5, name: 'Deluxe Double'}, {id: 5, name: 'Deluxe Single'}];

    views: Lookup[] = [{id: 1, name: 'Sea view'}, {id: 2, name: 'Garden view'}, {id: 3, name: 'Sea front view'},
        {id: 4, name: 'Pool view'}];

    boards: Lookup[] = [{id: 1, name: 'Full Board'}, {id: 2, name: 'Half Board'}, {id: 3, name: 'Breakfast'},
        {id: 4, name: 'All inclusive'}, {id: 5, name: 'No Meals'}];
    generatedProducts = [];
    selectedProducts = [];
    currentProductName: string;
    productName: string;

    constructor(private fb: UntypedFormBuilder, private confirmationService: ConfirmationService,
                private breadcrumbService: BreadcrumbService,
                private route: ActivatedRoute) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Hotel Products', routerLink: '/product-setup/hotel-products'},
            {label: 'New Hotel Products Bulk'}
        ]);
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            services: [null, Validators.required],
            room_types: [null, Validators.required],
            views: [null, Validators.required],
            boards: [null, Validators.required],
        });
    }

    submit(): void {
        this.generatedProducts = [];
        this.fetch(this.form.value.services, this.form.value.room_types, this.form.value.views, this.form.value.boards);
    }

    fetch(services: Lookup[], roomTypes: Lookup[], views: Lookup[], boards: Lookup[]) {
        services.forEach(service => {
            roomTypes.forEach(type => {
                views.forEach(view => {
                    boards.forEach(board => {
                        this.generatedProducts.push({
                            name: type.name + ', ' + view.name + ', ' + board.name + ', ' + service.name,
                            service_id: service.id,
                            service_name: service.name,
                            room_type_id: type.id,
                            room_type_name: type.name,
                            view_id: view.id,
                            view_name: view.name,
                            board_id: board.id,
                            board_name: board.name,
                            validity_from: new Date(),
                            validity_to: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
                            active: 1
                        });
                    });
                });
            });
        });
    }

    saveChanges() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        const clone: ProductService = {
            ...this.form.value,
            company: this.form.get('company').value.id,
            provider: this.form.get('provider').value.id,
            country: this.form.get('country').value.id,
            city: this.form.get('city').value?.id || null,
        };
    }

    edit(product) {
    }

    delete(index) {
        this.generatedProducts.splice(index, 1);
    }

    inlineUpdate(index) {
    }

    openNameEdit(product) {
        this.productName = product.name;
        this.currentProductName = product.name;
    }

    changeStatus(event, index) {
        this.generatedProducts[index].active = event.checked;
    }
}
