<div class="card">
  <p-table #dt dataKey="id" [value]="services" [(selection)]="selectedServices" [rowHover]="true"
           [rows]="10" [paginator]="companyId ? true : false"
           currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
           [showCurrentPageReport]="true" [globalFilterFields]="['name']" [scrollable]="true" [scrollDirection]="'both'"
           dataKey="id"
           [globalFilterFields]="['name','code','company_name','provider_name', 'country_name', 'city_name', 'area_name',
           'validity_to', 'invoice_handler_name', 'service_handler_name', 'active']">
    <ng-template pTemplate="caption">
      <div class="p-ai-center">

        <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636370002853')"></button>

        <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" [(ngModel)]="searchValue"
                           (input)="companyId ? dt.filterGlobal($event.target.value, 'contains') : searchServices()"
                           placeholder="Search..." />
                </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 80px" pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="booking_code">Company/Service Provider ID <p-sortIcon field="booking_code"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="code">Contract Code <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th *ngIf="!companyId" style="width: 120px" pSortableColumn="company_name">Company
          <p-sortIcon field="company_name"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="provider_name">Provider <p-sortIcon field="provider_name"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="country_name">Country <p-sortIcon field="country_name"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="validity_to">Validity <p-sortIcon field="validity_to"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="invoice_handler_name">Invoice Handler <p-sortIcon
                field="invoice_handler"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="service_handler_name">Service Handler <p-sortIcon
                field="service_handler"></p-sortIcon>
        </th>
        <th style="width: 120px" pSortableColumn="active">Status <p-sortIcon field="active"></p-sortIcon>
        </th>
        <th style="width: 60px" alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1636369934002') || checkPermission('1636369948581') || checkPermission('1636369963287')"
        >Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-service>
      <tr>
        <td style="width: 80px">
          {{service.id}}
        </td>
        <td style="width: 120px; cursor: pointer" (click)="edit(service)">
          {{service.name}}
        </td>
        <td style="width: 120px">
          {{service.booking_code}}
        </td>
        <td style="width: 120px">
          {{service.code}}
        </td>
        <td *ngIf="!companyId" style="width: 120px">
          {{service.company_name}}
        </td>
        <td style="width: 120px">
          {{service.provider_name}}
        </td>
        <td style="width: 120px">
          {{service.country?.name_en}}
        </td>
        <td style="width: 120px">
          {{service.validity_to}}
        </td>
        <td style="width: 120px">
          {{service.invoice_handler_name}}
        </td>
        <td style="width: 120px">
          {{service.service_handler_name}}
        </td>
        <td style="width: 120px">
          {{service.active == 1 ? ('active' | translate) : ('inactive' | translate)}}
        </td>
        <td style="width:60px" alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1636369934002') || checkPermission('1636369948581') || checkPermission('1636369963287')"
        >
          <div class="btn-group" dropdown>
            <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                    class="p-button-rounded p-button-text"></button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-basic">
              <li role="menuitem" *ngIf="checkPermission('1636369948581')"><a class="dropdown-item" (click)="edit(service)">Edit</a></li>
              <li role="menuitem" *ngIf="checkPermission('1636369963287')"><a class="dropdown-item" (click)="delete(service)">Delete</a></li>
            </ul>
          </div>

        </td>

      </tr>
    </ng-template>
  </p-table>
  <p-paginator *ngIf="services?.length > 0 && !companyId" [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
               (onPageChange)="pageChanged($event)" [showJumpToPageInput]="true"
               [rowsPerPageOptions]="[10, 50, 100, 200]" #p [showCurrentPageReport]="true"
               currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
</div>
