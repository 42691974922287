import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientRequestsComponent} from './client-requests/client-requests.component';
import {ClientPortalRoutingModule} from './client-portal-routing.module';
import {SharedModule} from '../_shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        ClientRequestsComponent
    ],
    imports: [
        CommonModule,
        ClientPortalRoutingModule,
        SharedModule,
        TranslateModule
    ]
})
export class ClientPortalModule {
}
