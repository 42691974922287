import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-price-list',
    templateUrl: './price-list.component.html',
    styleUrls: ['./price-list.component.css']
})
export class PriceListComponent implements OnInit {

  lists$: Observable<any[]>;
  list: any;
  selectedLists: any[];

  // Filter vars
  tableHelper = new GgTableCustomFN();

  constructor(private breadcrumbService: BreadcrumbService,
              private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService,
              private messageService: MessageService, private translate: TranslatePipe) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: 'Product Setup'},
      {label: 'Price List'}
    ]);
  }

  ngOnInit(): void {
  }

  addNew() {
    this.router.navigate(['add'], {relativeTo: this.route});
  }
  addNewBulk() {
    this.router.navigate(['add-bulk'], {relativeTo: this.route});
  }

  edit(product) {
    this.router.navigate(['edit/' + product.id], {relativeTo: this.route});
  }
  delete(product, index) {}

  changeStatus(product, event) {

  }

  exportExcel() {
    if (!this.selectedLists || this.selectedLists.length < 1) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedLists);
  }
}
