<form [formGroup]='generalForm' (ngSubmit)="submitForm()" class="mt-4">

    <!-- BASIC SECTION -->
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col">
                <h5>{{'basicInfo' | translate}}</h5>
            </div>
            <div>
                <button pButton class="float-right ml-3" type="submit">{{'saveChanges' | translate}}</button>
                <app-switch-input [formControl]="generalForm.controls['active']" [label]="'active' | translate"
                                  [inline]="true"
                                  class="float-right"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label class="full-width">{{'companyName' | translate}} <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" delay="1000" [field]="'name'" formControlName="company"
                                    [suggestions]="companies" (onSelect)="companySelected($event)"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="generalForm.get('company').touched && generalForm.get('company').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-3">
                <app-text-input [formControl]="generalForm.controls['name']"
                                [label]='"golfCourseName" | translate'></app-text-input>
            </div>

            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label class="full-width">{{'parentHotel' | translate}}</label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="hotel"
                                    [suggestions]="hotels" delay="1000"
                                    (completeMethod)="searchHotels($event)" [forceSelection]="true">
                        <ng-template let-hotel pTemplate="item">
                            <div>{{hotel.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
            <ng-container *ngIf="currentUser.role_id !== 3">
                <div class="p-col-12 p-md-3">
                    <app-text-input [formControl]='generalForm.controls["reservation_email"]' [type]="'email'"
                                    [label]='"reservationEmail" | translate'></app-text-input>
                </div>
                <div class="p-col-12 p-md-3">
                    <app-text-input [formControl]='generalForm.controls["letter_code"]'
                                    [label]='"lettersCode" | translate'></app-text-input>
                </div>
            </ng-container>
            <div class="p-col-12 p-md-3">
                <app-select-input [formControl]="generalForm.controls['golf_course_style_id']"
                                  [label]="'style' | translate"
                                  [options]="styles" [optionLabel]="'name'"
                                  [optionValue]="'id'"></app-select-input>
            </div>
            <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-3">
                <app-select-input [formControl]="generalForm.controls['club_rating']"
                                  [label]="'clubRating' | translate"
                                  [options]="ratingOptions" [optionLabel]="'name'"
                                  [optionValue]="'id'"></app-select-input>
            </div>
            <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-3">
                <app-select-input [formControl]="generalForm.controls['course_rating']"
                                  [label]="'courseRating' | translate"
                                  [options]="ratingOptions" [optionLabel]="'name'"
                                  [optionValue]="'id'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-text-input [formControl]="generalForm.controls['designer']" [type]="'text'"
                                [label]="'designer' | translate"></app-text-input>
            </div>
            <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-2">
                <label>{{'startTime' | translate}}</label>
                <div class="p-field m-0">
                    <p-inputMask mask="99:99"
                                 [formControl]="generalForm.controls['start_time']"
                                 placeholder="10:00"
                                 styleClass="request-input-mask"></p-inputMask>
                    <small *ngIf="generalForm.controls['start_time'].touched &&
                    generalForm.controls['start_time'].errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                    <small *ngIf="!generalForm.controls['start_time'].errors?.required &&
                                            generalForm.controls['start_time'].errors?.pattern"
                           class="p-error">{{'invalidTime' | translate}}</small>
                </div>
            </div>
            <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-2">
                <label>{{'endTime' | translate}}</label>
                <div class="p-field m-0">
                    <p-inputMask mask="99:99"
                                 [formControl]="generalForm.controls['end_time']"
                                 placeholder="10:00"
                                 styleClass="request-input-mask"></p-inputMask>
                    <small *ngIf="generalForm.controls['end_time'].touched &&
                    generalForm.controls['end_time'].errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                    <small *ngIf="!generalForm.controls['end_time'].errors?.required &&
                                            generalForm.controls['end_time'].errors?.pattern"
                           class="p-error">{{'invalidTime' | translate}}</small>
                </div>
            </div>
            <div class="p-col-12 p-md-2">
                <app-switch-input [formControl]="generalForm.controls['academy']"
                                  [label]="'academy' | translate"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-switch-input [formControl]="generalForm.controls['pros']"
                                  [label]="'pros' | translate"></app-switch-input>
            </div>
        </div>
        <p-fieldset *ngIf="currentUser.role_id !== 3" legend="{{'contract' | translate}}"
                    styleClass="services-fieldset">
            <div class="p-grid">
                <div class="p-col-12 p-md-2">
                    <app-switch-input [formControl]="generalForm.controls['direct_contract']"
                                      [label]="'direct' | translate" [inline]="true"></app-switch-input>
                </div>
                <div class="p-col-12 p-md-2">
                    <app-switch-input [formControl]="generalForm.controls['via_dmc']"
                                      [label]="'DMC'" [inline]="true"></app-switch-input>
                </div>
                <div class="p-col-12 p-md-2">
                    <app-switch-input [formControl]="generalForm.controls['via_hotel']"
                                      [label]="'hotel' | translate" [inline]="true"></app-switch-input>
                </div>
            </div>
        </p-fieldset>

    </div>
    <div *ngIf="currentUser.role_id !== 3" class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-12">
                <h5>{{'referenceCodes'| translate}}</h5>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]='generalForm.controls["ref_id"]'
                                [label]="'Ref. ID'"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]='generalForm.controls["tui_ref_code"]'
                                [label]='"@ComRes ID"'></app-text-input>
            </div>
            <div class="p-col-12 p-md-3 half-rem-padding">
                <app-text-input [formControl]='generalForm.controls["booking_code"]'
                                [label]='"SPId" | translate'></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]='generalForm.controls["davinci_booking_code"]'
                                [label]='"DaVinci ID"'></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-12">
                <h5>{{'davinciBookingCodes'| translate}}</h5>
            </div>
        </div>
        <div class="p-grid">
            <div *ngFor="let code of _DaVinciBookingCodes.controls;let c = index; let cl = last"
                 class="p-col-12 p-md-3 half-rem-padding">
                <label>{{'davinciBookingCode' | translate}} </label>
                <div class="p-inputgroup">
                    <button *ngIf="_DaVinciBookingCodes.controls.length > 1" type="button" pButton pRipple
                            icon="pi pi-trash"
                            styleClass="p-button-success" (click)="removeDaVinciBookingCode(c)"></button>
                    <input type="text" pInputText placeholder="{{'davinciBookingCode' | translate}}"
                           [formControl]="code">
                    <button *ngIf="c + 1 == _DaVinciBookingCodes.controls.length" type="button" pButton pRipple
                            icon="pi pi-plus" styleClass="p-button-danger" (click)="addDaVinciBookingCode()"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>{{'companyContact' | translate}}</h5>
            </div>
            <div>
                <app-switch-input class="float-right" *ngIf="showCompanyInfoButton"
                                  [formControl]="generalForm.controls['is_company_address']"
                                  [label]="'useCompanyInfo' | translate"
                                  [inline]="true"
                                  (changed)="companyInfoButtonClick($event)"></app-switch-input>
                <a class="view-company-link"
                   *ngIf="generalForm.get('company').value && generalForm.get('is_company_address').value == 1"
                   (click)="viewCompany()">{{'viewCompany' | translate}}</a>

            </div>
        </div>
        <!--<div class="p-grid">
            <div class="p-col-12 p-md-4 p-lg-col">
                <app-text-input [type]="'text'" [label]="'Delegate name'"
                                [formControl]="generalForm.controls['delegate_name']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4 p-lg-col">
                <app-text-input [type]="'email'" [label]="'Delegate email'"
                                [formControl]="generalForm.controls['delegate_email']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4 p-lg-col">
                <div class="p-field">
                    <label>Delegate mobile</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Delegate mobile'"
                            formControlName="delegate_mobile_number"
                    ></ngx-intl-tel-input>
                    <small *ngIf="generalForm.controls.delegate_mobile_number.touched && generalForm.controls.delegate_mobile_number.errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                    <small class="p-error"
                           *ngIf="generalForm.controls.delegate_mobile_number.touched && generalForm.controls.delegate_mobile_number.invalid">
                        Invalid number
                    </small>
                </div>
            </div>
            &lt;!&ndash;<div class="p-col-12 p-md-4 p-lg-col">
                <div class="p-field">
                    <label class="full-width">Delegate GAP username <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'full_name'" formControlName="delegate_user"
                                    [suggestions]="users" delay="1000"
                                    (completeMethod)="searchUsers($event)" [forceSelection]="true">
                        <ng-template let-user pTemplate="item">
                            <div>{{user.first_name + ' ' + user.last_name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="generalForm.controls.delegate_user.touched && generalForm.controls.delegate_user.errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>&ndash;&gt;
            <div class="p-col-12 p-md-4 p-lg-col">
                <div class="p-field">
                    <label class="full-width">GG assigned user</label>
                    <p-autoComplete class="autocomplete" [field]="'full_name'" formControlName="assigned_user"
                                    [suggestions]="users" delay="1000"
                                    (completeMethod)="searchUsers($event)" [forceSelection]="true">
                        <ng-template let-user pTemplate="item">
                            <div>{{user.first_name + ' ' + user.last_name}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
        </div>-->

        <h5 class="p-mt-3">{{'golfCourseAddress' | translate}}</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'continent' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="regions" [formControl]="generalForm.get('region_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                                placeholder="{{'selectContinent' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getRegionName(generalForm.get('region_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="generalForm.get('region_id').touched && generalForm.get('region_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'country' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="countries" [formControl]="generalForm.get('country_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()"
                                placeholder="{{'selectCountry' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getCountries(null, generalForm.get('region_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCountryName(generalForm.get('country_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="generalForm.get('country_id').touched && generalForm.get('country_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'region' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="cities" [formControl]="generalForm.get('city_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="cityChanged()"
                                (onClick)="getCities(null, generalForm.get('country_id').value)"
                                placeholder="{{'selectRegion' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCityName(generalForm.get('city_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                            + ' - ' + item?.code}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="generalForm.get('city_id').touched && generalForm.get('city_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'area' | translate}}</label>
                    <p-dropdown [options]="areas" [formControl]="generalForm.get('area_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true"
                                placeholder="{{'selectArea' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getAreas(null, generalForm.get('city_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getAreaName(generalForm.get('area_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="generalForm.get('area_id').touched && generalForm.get('area_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="generalForm.controls['street']" placeholder="{{'street' | translate}}"
                                label="{{'street' | translate}}"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="generalForm.controls['postal_code']"
                                label="{{'postalCode' | translate}}" placeholder="{{'postalCode' | translate}}"
                                [type]="'number'"></app-text-input>
            </div>
        </div>
        <h5 class="p-mt-3">{{'golfCourseContact' | translate}}</h5>
        <div class="p-grid">
            <ng-container *ngIf="currentUser.role_id !== 3">
                <div class="p-col-12 p-md-3">
                    <app-text-input [type]="'text'" [label]="'website' | translate"
                                    [formControl]="generalForm.controls['website_link']"></app-text-input>
                </div>

                <div class="p-col-12 p-md-3 p-lg-col">
                    <div class="p-field">
                        <label>{{'phone' | translate}}</label>
                        <ngx-intl-tel-input
                                [cssClass]="'ngx-phone'"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [selectFirstCountry]="false"
                                [maxLength]="15"
                                [customPlaceholder]="'phone' | translate"
                                formControlName="phone"
                        ></ngx-intl-tel-input>
                        <small *ngIf="generalForm.controls.phone.touched && generalForm.controls.phone.errors?.required"
                               class="p-error">{{'required' | translate}}</small>
                        <small class="p-error"
                               *ngIf="generalForm.controls.phone.touched && generalForm.controls.phone.invalid">
                            {{'invalidNumber' | translate}}
                        </small>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-col">
                    <div class="p-field">
                        <label>{{'fax' | translate}}</label>
                        <ngx-intl-tel-input
                                [cssClass]="'ngx-phone'"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [selectFirstCountry]="false"
                                [maxLength]="15"
                                [customPlaceholder]="'fax' | translate"
                                formControlName="fax"
                        ></ngx-intl-tel-input>
                        <small *ngIf="generalForm.controls.fax.touched && generalForm.controls.fax.errors?.required"
                               class="p-error">{{'required' | translate}}</small>
                        <small class="p-error"
                               *ngIf="generalForm.controls.fax.touched && generalForm.controls.fax.invalid">
                            {{'invalidNumber' | translate}}
                        </small>
                    </div>
                </div>
            </ng-container>
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-text-input [type]="'email'" [label]="'email' | translate"
                                [formControl]="generalForm.controls['email']"></app-text-input>
            </div>
        </div>
    </div>
    <!--<div class="card toolbar-demo">
        <h5>Finance Details</h5>
        <div class="p-grid">
            <div class="p-col-4">
                <app-switch-input [formControl]="generalForm.controls['payee']"
                                  [label]="'Payee'"></app-switch-input>
            </div>
            <div class="p-col-4">
                <app-switch-input [formControl]="generalForm.controls['is_payee_only']"
                                  [label]="'Golf course is payee only'"></app-switch-input>
            </div>
            <div class="p-col-4">
                <app-switch-input [formControl]="generalForm.controls['payee_key_created']"
                                  [label]="'Payee key created'"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'number'" [label]="'Account number'"
                                [formControl]="generalForm.controls['account_number']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'text'" [label]="'Bank'"
                                [formControl]="generalForm.controls['bank']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'text'" [label]="'Bank location'"
                                [formControl]="generalForm.controls['bank_location']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'number'" [label]="'Swift-Code'"
                                [formControl]="generalForm.controls['swift_code']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'number'" [label]="'IBAN'"
                                [formControl]="generalForm.controls['iban']"></app-text-input>
            </div>
        </div>
    </div>-->

    <!--   <ul class="invalid-controls">
           <li *ngFor="let control of invalidControls">{{control}} {{'isInvalid' | translate}}</li>
       </ul>-->
    <!--<div class="buttons">
        <button pButton class="float-right" type="submit">{{'saveChanges' | translate}}</button>
    </div>-->
</form>
