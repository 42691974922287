import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getUserActivities(obj: any) {
        return this.http.get(this.baseUrl + 'users/activity?search=' + (obj.search || '') +
            '&date_from=' + obj.date_from + '&date_to=' + obj.date_to +
            '&pagination=' + obj.pagination + '&page=' + obj.page);
    }
}
