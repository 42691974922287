import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AccountService} from '../../_services/account.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.css']
})

export class CoursesComponent implements OnInit {

    activeView = 'table';

    constructor(private breadcrumbService: BreadcrumbService, private accountService: AccountService,
                private router: Router, private route: ActivatedRoute, private translate: TranslatePipe) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('golfCourses')},
        ]);
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParamMap.has('view')) {
            if (this.route.snapshot.queryParamMap.get('view') == 'tree') {
                this.activeView = 'tree';
            } else if (this.route.snapshot.queryParamMap.get('view') == 'table') {
                this.activeView = 'table';
            }
        }
    }

    changeActiveView(view: string) {
        this.activeView = view;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                view: view
            }
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    addNew() {
        this.router.navigate(['add'], {relativeTo: this.route});
    }
}
