import { Component, OnInit } from '@angular/core';
import {Company} from '../../../../_models/company';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-company-gfp',
  templateUrl: './company-gfp.component.html',
  styleUrls: ['./company-gfp.component.css']
})
export class CompanyGfpComponent implements OnInit {
  company: Company;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.company = this.route.snapshot.parent.data.company;
  }

}
