import {Component, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CompaniesService} from '../../_services/companies-service';
import {Company} from '../../_models/company';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe} from '@ngx-translate/core';
import {Paginator} from 'primeng/paginator';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
    company: Company;
    selectedCompanies: Company[];
    companies: Company[];

    // Filter vars
    tableHelper = new GgTableCustomFN();
    filters = {
        company_type_name: [],
        delegate_name: [],
        assigned_user_name: [],
        country_name: [],
        city_name: [],
    };
    booleanFilter = [{name: this.translate.transform('yes'), val: 1},
        {name: this.translate.transform('no'), val: 0}];
    pagination: any;
    searchValue = '';
    showDeletePrevent = false;
    first = 0;
    @ViewChild('paginator', {static: false}) paginator: Paginator;

    constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService,
                private confirmationService: ConfirmationService, public companiesService: CompaniesService,
                private router: Router, private activatedRoute: ActivatedRoute,
                private accountService: AccountService, public translate: TranslatePipe) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Companies', routerLink: ['/admin/companies']}
        ]);
    }

    ngOnInit(): void {
        this.getAllCompanies(1, 10, this.searchValue);
    }

    getAllCompanies(pageNumber, pagination, searchKeyword) {
        this.companiesService.getPaginatedCompanies(pageNumber, pagination, searchKeyword).subscribe((res: any) => {
            this.companies = res.companies.data;
            this.pagination = res.companies.meta;
            this.tableHelper.populateFilter(this.filters, this.companies);
        });
    }

    addNew() {
        this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    deleteSelectedProducts() {
    }

    edit(company: Company) {
        this.router.navigate(['edit', company.id], {relativeTo: this.activatedRoute});
    }

    delete(company: Company) {
        if (company.childs_count > 0) {
            this.company = company;
            this.showDeletePrevent = true;
        } else {
            this.confirmationService.confirm({
                message: this.translate.transform('confirmMessage'),
                accept: () => {
                    this.companiesService.deleteCompany(company.id).subscribe(res => {
                        this.getAllCompanies(1, 10, this.searchValue);
                        this.messageService.add({
                            severity: 'success', summary: 'Delete company',
                            detail: 'Company has been deleted successfully'
                        });
                        this.getAllCompanies(1, 10, this.searchValue);
                        setTimeout(() => this.paginator.changePage(0));
                    });
                },
                acceptLabel: this.translate.transform('yes'),
                rejectLabel: this.translate.transform('no'),
                header: this.translate.transform('confirmation')
            });
        }
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedCompanies || this.selectedCompanies.length < 1) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedCompanies);
    }

    searchCompanies() {
        this.getAllCompanies(1, this.pagination.per_page, this.searchValue);
    }

    pageChanged(event) {
        this.getAllCompanies(event.page + 1, +event.rows, this.searchValue);
    }
}



