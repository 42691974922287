import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PlayersFormComponent} from '../../stages/request-stage-one/players-form/players-form.component';
import {RequestAPIService} from '../../_services/request-api.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-players-section',
    templateUrl: './players-section.component.html',
    styleUrls: ['./players-section.component.css']
})
export class PlayersSectionComponent implements OnInit {

    @Input() request: any;
    @Input() viewOnly = false;
    displayEditPlayers = false;
    @ViewChild('playersForm', {static: false}) playersForm: PlayersFormComponent;

    constructor(public requestServices: RequestAPIService, private clipboard: Clipboard,
                private messageService: MessageService) {
    }

    ngOnInit() {
    }

    editPlayers() {
        this.displayEditPlayers = true;
    }

    onHidePlayersForm() {
        this.request.players = this.playersForm.getPlayers();
        this.displayEditPlayers = false;
    }

    saveChanges() {
        const players: any = this.playersForm.submit();
        const newPlayers = players.filter(player => player.id == null);
        if (newPlayers.length > 0) {
            this.requestServices.createPlayerBulk({players: newPlayers}, this.request.id).subscribe((res: any) => {
                this.request = res.request;
                this.displayEditPlayers = false;
            });
        } else {
            this.displayEditPlayers = false;
        }
    }

    playersUpdated(event) {
        this.request.players = event;
    }
    copyDelegationLink() {
        this.requestServices.getDelegationToken(this.request.id).subscribe((res: any) => {
            this.formDelegationLink(res.token);

        });
    }
    copyToClipboard(delegationUrl) {
        this.clipboard.copy(delegationUrl);
        this.messageService.add({
            severity: 'success', summary: 'Request Delegation',
            detail: 'Delegation link copied to clipboard.'
        });
    }
    formDelegationLink(token) {
        const delegationUrl = window.location.origin + '/login?client_token=' + token;
        this.copyToClipboard(delegationUrl);
    }
}
