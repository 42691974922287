<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="companies"
             [globalFilterFields]="['name','delegate_name','delegate_email','assigned_username', 'delegate_mobile_number',
             'phone', 'country_name', 'city_name', 'area_name', 'booking_code', 'davinci_booking_code']"
             [scrollable]="true" [scrollDirection]="'both'"
             [(selection)]="selectedCompanies" [rowHover]="true" dataKey="id"
             [columns]="['name', 'company_type_name', 'delegate_name', 'delegate_mobile_number', 'assigned_username', 'rank', 'contract',
             'phone','region', 'country', 'city', 'area']">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">Companies</h5>

                    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                            (click)="addNew()" pTooltip="Add new company" tooltipPosition="top"
                            *ngIf="checkPermission('1636371001099')"></button>
                </div>
                <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                        (click)="deleteSelectedProducts()"
                        [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->


                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload"
                        class="p-button-help float-right" *ngIf="checkPermission('1636371079951')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" [(ngModel)]="searchValue"
                           (input)="searchCompanies()" placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="name">Company Name
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="company_type_name">Company Type
                    <p-sortIcon field="company_type_name"></p-sortIcon>
                    <!--<app-filter fieldName="company_type_name" [filterOptions]="filters.company_type_name"></app-filter>-->
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="booking_code">Company/Service Provider ID
                    <p-sortIcon field="booking_code"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="davinci_booking_code">DaVinci ID
                    <p-sortIcon field="davinci_booking_code"></p-sortIcon>
                </th>
                <!--<th style="min-width:160px; max-width: 160px;" pSortableColumn="delegate_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Delegate Name
                    <p-sortIcon field="delegate_name"></p-sortIcon>
                    &lt;!&ndash;<app-filter fieldName="delegate_name" [filterOptions]="filters.delegate_name"></app-filter>&ndash;&gt;
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="delegate_mobile_number"
                    class="p-d-flex p-jc-between p-ai-center">
                    Delegate Mobile
                    <p-sortIcon field="delegate_mobile_number"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="assigned_username"
                    class="p-d-flex p-jc-between p-ai-center">
                    Assigned User
                    <p-sortIcon field="assigned_user_name"></p-sortIcon>
                    &lt;!&ndash;<app-filter fieldName="assigned_user_name"
                                [filterOptions]="filters.assigned_user_name"></app-filter>&ndash;&gt;
                </th>-->
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="rank"
                    class="p-d-flex p-jc-between p-ai-center">
                    Rank
                    <p-sortIcon field="rank"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="contract"
                    class="p-d-flex p-jc-between p-ai-center">
                    Contract
                    <p-sortIcon field="contract"></p-sortIcon>
                    <!--<app-filter fieldName="contract" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>-->
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="phone"
                    class="p-d-flex p-jc-between p-ai-center">
                    Phone
                    <p-sortIcon field="phone"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="region_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Continent
                    <p-sortIcon field="region_name"></p-sortIcon>
                    <!--<app-filter fieldName="country_name" [filterOptions]="filters.country_name"></app-filter>-->
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="country_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Country
                    <p-sortIcon field="country_name"></p-sortIcon>
                    <!--<app-filter fieldName="country_name" [filterOptions]="filters.country_name"></app-filter>-->
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="city_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Region
                    <p-sortIcon field="city_name"></p-sortIcon>
                    <!--<app-filter fieldName="city_name" [filterOptions]="filters.city_name"></app-filter>-->
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="area_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    Area
                    <p-sortIcon field="area_name"></p-sortIcon>
                    <!--<app-filter fieldName="city_name" [filterOptions]="filters.city_name"></app-filter>-->
                </th>
                <th style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371016513') || checkPermission('1636371030691') || checkPermission('1636371046272')"
                >Actions
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
            <tr>
                <td style="min-width:160px; max-width: 160px; cursor: pointer" (click)="edit(company)">{{company.name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.company_type_name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.booking_code}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.davinci_booking_code}}</td>
                <!--<td style="min-width:160px; max-width: 160px;">{{company.delegate_name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.delegate_mobile_number}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.assigned_user_name}}</td>-->
                <td style="min-width:160px; max-width: 160px;">{{company.rank}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.contract ? 'Yes' : 'No'}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.phone}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.region?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.country?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.city?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{company.area?.name_en}}</td>

                <td style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371016513') || checkPermission('1636371030691') || checkPermission('1636371046272')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <!--<li role="menuitem"><a class="dropdown-item" (click)="view(company)" *ngIf="checkPermission('1636371016513')">View</a></li>-->
                            <li role="menuitem"><a class="dropdown-item" (click)="edit(company)"
                                                   *ngIf="checkPermission('1636371030691')">Edit</a></li>
                            <li *ngIf="company.id !== 1" role="menuitem"><a class="dropdown-item" (click)="delete(company)"
                                                   *ngIf="checkPermission('1636371046272')">Delete</a></li>
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator *ngIf="companies?.length > 0" [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
                 (onPageChange)="pageChanged($event)" [showJumpToPageInput]="true" #paginator
                 [rowsPerPageOptions]="[10, 50, 100, 200]" #p [showCurrentPageReport]="true"
                 currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
</div>
<p-dialog header="Delete Company" [(visible)]="showDeletePrevent" (onHide)="showDeletePrevent = false">
    Notice! This company has {{company?.childs_count}} Children, Contact your System Administrator.
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showDeletePrevent = false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
