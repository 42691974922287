<div *ngIf="hotel.id" class="p-grid">
    <div class="p-col-4">
        <h4 class="mb-3">{{hotel.name}}</h4>
    </div>
    <div class="p-col-8">
        <div class="buttons">
            <button *ngIf="isPublishAvailable()"
                    class="p-button-danger mr-3 float-right" pButton type="button"
                    (click)="publishToWebsite()" pRipple>{{'publish' | translate}}
            </button>
        </div>
    </div>
</div>
<form autocomplete="off" [formGroup]='hotelForm' (ngSubmit)="submitForm()">
    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="{{'basic' | translate}}">
            <div class="card toolbar-demo">
                <div class="p-grid p-jc-between">
                    <div class="p-col">
                        <h5>{{'basic' | translate}}</h5>
                    </div>
                    <div>
                        <button *ngIf="!addMode" class="float-right ml-2" pButton
                                type="submit">{{(addMode ? 'create' : 'saveChanges') | translate}}</button>
                        <app-switch-input [formControl]="hotelForm.controls['active']" [label]="'active' | translate"
                                          class="float-right" [inline]="true"></app-switch-input>
                    </div>
                </div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-2" *ngIf="addMode">
                        <app-switch-input [formControl]="hotelForm.controls['is_parent']"
                                          (changed)="isParentChanged($event)"
                                          [label]="'createCompanyProfile' | translate"></app-switch-input>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <div class="p-field">
                            <label class="full-width">{{'companyName' | translate}} <span
                                    class="p-error">*</span></label>
                            <p-autoComplete class="autocomplete" [field]="'name'" formControlName="company"
                                            [suggestions]="companies" (onSelect)="companySelected($event)" delay="1000"
                                            (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                                <ng-template let-company pTemplate="item">
                                    <div>{{company.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <small *ngIf="hotelForm.get('company').touched && hotelForm.get('company').errors?.required"
                                   class="p-error">{{'required' | translate}}</small>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <app-text-input [formControl]="hotelForm.controls['name']"
                                        [label]="'hotelName' | translate"></app-text-input>
                    </div>
                    <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-2">
                        <app-text-input [formControl]='hotelForm.controls["letter_code"]'
                                        [label]="'lettersCode' | translate"></app-text-input>
                    </div>
                    <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-2">
                        <app-text-input [formControl]='hotelForm.controls["reservation_email"]' [type]="'email'"
                                        [label]='"reservationEmail" | translate'></app-text-input>
                    </div>

                    <div class="p-col-12 p-md-2" *ngIf="currentUser.role_id !== 3">
                        <app-multi-select [options]="boards" [formControl]="hotelForm.controls['boards']"
                                          [label]="'boards' | translate" [placeholder]="'selectBoards' | translate"
                                          [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
                    </div>
                    <div class="p-col-12 p-md-2" *ngIf="currentUser.role_id !== 3">
                        <app-text-input [type]="'number'" [label]="'numberOfRooms' | translate"
                                        [formControl]="hotelForm.controls['number_of_rooms']"></app-text-input>
                    </div>

                    <div class="p-col-12 p-md-col half-rem-padding">
                        <div class="p-field">
                            <label class="full-width">{{'tourOperators' | translate}}
                            <span class="p-error">*</span></label>
                            <p-autoComplete class="autocomplete" [field]="'name'" formControlName="touroperators"
                                            [suggestions]="tourOperators" [multiple]="true" delay="1000"
                                            (completeMethod)="searchTourOperators($event)" [forceSelection]="true"
                                            [unique]="true" (onSelect)="tourOperatorSelected($event)">
                                <ng-template let-tour pTemplate="item">
                                    <div>{{tour.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <small *ngIf="hotelForm.get('touroperators').touched && hotelForm.get('touroperators').errors?.required"
                                   class="p-error">{{'required' | translate}}</small>
                        </div>
                    </div>

                </div>
                <p-fieldset *ngIf="!addMode && currentUser.role_id !== 3" legend="{{'contract' | translate}}"
                            styleClass="services-fieldset">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <app-switch-input [formControl]="hotelForm.controls['direct_contract']"
                                              [label]="'direct' | translate" [inline]="true"></app-switch-input>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <app-switch-input [formControl]="hotelForm.controls['via_dmc']"
                                              [label]="'DMC'" [inline]="true"></app-switch-input>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="card toolbar-demo">
                <div class="p-grid p-jc-between p-mb-3">
                    <div class="p-col-12">
                        <h5>{{'referenceCodes' | translate}}</h5>
                    </div>
                </div>
                <div class="p-grid">
                    <div *ngIf="!addMode" class="p-col-12 p-md-2">
                        <app-text-input [formControl]='hotelForm.controls["ref_id"]'
                                        [label]='"Ref. ID"'></app-text-input>
                    </div>
                    <!--<div class="p-col-12 p-md-2">
                        <app-text-input [formControl]='hotelForm.controls["tui_ref_code"]'
                                        [label]='"TUI code (@ComRes)"'></app-text-input>
                    </div>-->
                    <div class="p-col-12 p-md-2">
                        <app-text-input [formControl]='hotelForm.controls["giata_code"]'
                                        [label]='"holidayUUID" | translate'></app-text-input>
                    </div>

                    <div *ngIf="!addMode && currentUser.role_id !== 3" class="p-col-12 p-md-3 half-rem-padding">
                        <app-text-input [formControl]='hotelForm.controls["booking_code"]'
                                        [label]="'SPId' | translate"></app-text-input>
                    </div>
                    <div *ngIf="currentUser.role_id !== 3" class="p-col-12 p-md-2">
                        <app-text-input [formControl]='hotelForm.controls["davinci_booking_code"]'
                                        [label]='"DaVinci ID"'></app-text-input>
                    </div>
                </div>
            </div>

            <div class="card toolbar-demo">
                <div class="p-grid p-jc-between p-mb-3">
                    <div class="p-col-12">
                        <h5>{{'davinciBookingCodes'| translate}}</h5>
                    </div>
                </div>
                <div class="p-grid">
                    <div *ngFor="let code of _DaVinciBookingCodes.controls;let c = index; let cl = last"
                         class="p-col-12 p-md-3 half-rem-padding">
                        <label>{{'davinciBookingCode' | translate}} </label>
                        <div class="p-inputgroup">
                            <button *ngIf="_DaVinciBookingCodes.controls.length > 1" type="button" pButton pRipple
                                    icon="pi pi-trash"
                                    styleClass="p-button-success" (click)="removeDaVinciBookingCode(c)"></button>
                            <input type="text" pInputText placeholder="{{'davinciBookingCode' | translate}}"
                                   [formControl]="code">
                            <button *ngIf="c + 1 == _DaVinciBookingCodes.controls.length" type="button" pButton pRipple
                                    icon="pi pi-plus" styleClass="p-button-danger"
                                    (click)="addDaVinciBookingCode()"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card toolbar-demo">
                <div class="p-grid p-jc-between">
                    <div class="p-col">
                        <h5>{{'companyContact' | translate}}</h5>
                    </div>
                    <div>
                        <app-switch-input class="float-right" *ngIf="showCompanyInfoButton"
                                          [formControl]="hotelForm.controls['is_company_address']"
                                          [label]="'useCompanyInfo' | translate"
                                          [inline]="true"
                                          (changed)="companyInfoButtonClick($event)"></app-switch-input>
                        <a class="view-company-link"
                           *ngIf="hotelForm.get('company').value && hotelForm.get('is_company_address').value == 1"
                           (click)="viewCompany()">{{'viewCompany' | translate}}</a>

                    </div>
                </div>
                <!--<div class="p-grid">
                    <div class="p-col-12 p-md-col">
                        <app-text-input [type]="'text'" [label]="'Delegate name'"
                                        [formControl]="hotelForm.controls['delegate_name']"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <app-text-input [type]="'email'" [label]="'Delegate email'"
                                        [formControl]="hotelForm.controls['delegate_email']"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <div class="p-field">
                            <label>Delegate mobile</label>
                            <ngx-intl-tel-input
                                    [cssClass]="'ngx-phone'"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [selectFirstCountry]="false"
                                    [maxLength]="15"
                                    [customPlaceholder]="'Delegate mobile'"
                                    formControlName="delegate_mobile_number"
                            ></ngx-intl-tel-input>
                            <small *ngIf="hotelForm.controls.delegate_mobile_number.touched && hotelForm.controls.delegate_mobile_number.errors?.required"
                                   class="p-error">{{'required' | translate}}</small>
                            <small class="p-error"
                                   *ngIf="hotelForm.controls.delegate_mobile_number.touched && hotelForm.controls.delegate_mobile_number.invalid">
                                Invalid number
                            </small>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <div class="p-field">
                            <label class="full-width">GG assigned user</label>
                            <p-autoComplete class="autocomplete" [field]="'full_name'" formControlName="assigned_user"
                                            [suggestions]="users" delay="1000"
                                            (completeMethod)="searchUsers($event)" [forceSelection]="true">
                                <ng-template let-user pTemplate="item">
                                    <div>{{user.first_name + ' ' + user.last_name}}</div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                </div>-->
                <div class="p-grid">
                    <div class="p-col-12">
                        <h5>Hotel Address</h5>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-col">
                                <div class="p-field">
                                    <label>{{'continent' | translate}} <span class="p-error">*</span></label>
                                    <p-dropdown [options]="regions" [formControl]="hotelForm.get('region_id')" [appendTo]="'body'"
                                                [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                                                placeholder="{{'selectContinent' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                                        <ng-template pTemplate="selectedItem">
                                            <div>{{getRegionName(hotelForm.get('region_id').value)}}</div>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div>{{item[langKey]}}</div>
                                        </ng-template>
                                    </p-dropdown>

                                    <small *ngIf="hotelForm.get('region_id').touched && hotelForm.get('region_id').errors?.required"
                                           class="p-error">{{'required' | translate}}</small>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-col">
                                <div class="p-field">
                                    <label>{{'country' | translate}} <span class="p-error">*</span></label>
                                    <p-dropdown [options]="countries" [formControl]="hotelForm.get('country_id')" [appendTo]="'body'"
                                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()"
                                                placeholder="{{'selectCountry' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                                (onClick)="getCountries(null, hotelForm.get('region_id').value)">
                                        <ng-template pTemplate="selectedItem">
                                            <div>{{getCountryName(hotelForm.get('country_id').value)}}</div>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                                        </ng-template>
                                    </p-dropdown>

                                    <small *ngIf="hotelForm.get('country_id').touched && hotelForm.get('country_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-col">
                                <div class="p-field">
                                    <label>{{'region' | translate}} <span class="p-error">*</span></label>
                                    <p-dropdown [options]="cities" [formControl]="hotelForm.get('city_id')" [appendTo]="'body'"
                                                [optionValue]="'id'" [showClear]="true" (onChange)="cityChanged()"
                                                (onClick)="getCities(null, hotelForm.get('country_id').value)"
                                                placeholder="{{'selectRegion' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                                        <ng-template pTemplate="selectedItem">
                                            <div>{{getCityName(hotelForm.get('city_id').value)}}</div>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                                            + ' - ' + item?.code}}</div>
                                        </ng-template>
                                    </p-dropdown>

                                    <small *ngIf="hotelForm.get('city_id').touched && hotelForm.get('city_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-col">
                                <div class="p-field">
                                    <label>{{'area' | translate}}</label>
                                    <p-dropdown [options]="areas" [formControl]="hotelForm.get('area_id')" [appendTo]="'body'"
                                                [optionValue]="'id'" [showClear]="true"
                                                placeholder="{{'selectArea' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                                (onClick)="getAreas(null, hotelForm.get('city_id').value)">
                                        <ng-template pTemplate="selectedItem">
                                            <div>{{getAreaName(hotelForm.get('area_id').value)}}</div>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div>{{item[langKey]}}</div>
                                        </ng-template>
                                    </p-dropdown>

                                    <small *ngIf="hotelForm.get('area_id').touched && hotelForm.get('area_id').errors?.required"
                                           class="p-error">{{'required' | translate}}</small>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-col">
                                <app-text-input [formControl]="hotelForm.controls['street']" placeholder="{{'street' | translate}}"
                                                label="{{'street' | translate}}"></app-text-input>
                            </div>

                            <div class="p-col-12 p-md-col">
                                <app-text-input [formControl]="hotelForm.controls['postal_code']"
                                                label="{{'postalCode' | translate}}" placeholder="{{'postalCode' | translate}}"
                                                [type]="'number'"></app-text-input>
                            </div>
                        </div>
                    </div>
                </div>
                <h5>{{'hotelContact' | translate}}</h5>
                <div class="p-grid">
                    <ng-container *ngIf="currentUser.role_id !== 3">
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <label>{{'phone' | translate}}</label>
                                <ngx-intl-tel-input
                                        [cssClass]="'ngx-phone'"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [selectFirstCountry]="false"
                                        [maxLength]="15"
                                        [customPlaceholder]="'phone' | translate"
                                        formControlName="phone"
                                ></ngx-intl-tel-input>
                                <small *ngIf="hotelForm.controls.phone.touched && hotelForm.controls.phone.errors?.required"
                                       class="p-error">{{'required' | translate}}</small>
                                <small class="p-error"
                                       *ngIf="hotelForm.controls.phone.touched && hotelForm.controls.phone.invalid">
                                    {{'invalidNumber' | translate}}
                                </small>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <label>{{'fax' | translate}}</label>
                                <ngx-intl-tel-input
                                        [cssClass]="'ngx-phone'"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [selectFirstCountry]="false"
                                        [maxLength]="15"
                                        [customPlaceholder]="'fax' | translate"
                                        formControlName="fax"
                                ></ngx-intl-tel-input>
                                <small *ngIf="hotelForm.controls.fax.touched && hotelForm.controls.fax.errors?.required"
                                       class="p-error">{{'required' | translate}}</small>
                                <small class="p-error"
                                       *ngIf="hotelForm.controls.fax.touched && hotelForm.controls.fax.invalid">
                                    {{'invalidNumber' | translate}}
                                </small>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <app-text-input [type]="'text'" [label]="'website' | translate"
                                            [formControl]="hotelForm.controls['website']"></app-text-input>
                        </div>
                    </ng-container>
                    <div class="p-col-12 p-md-3">
                        <app-text-input [type]="'email'" [label]="'email' | translate"
                                        [formControl]="hotelForm.controls['email']"></app-text-input>
                    </div>
                </div>
            </div>
            <div class="card toolbar-demo">
                <h5>{{'logoLink' | translate}}</h5>
                <div class="p-grid">
                    <div class="p-col-12 mt-3">
                        <div class="image-div" *ngFor="let image of hotel?.links_logo_images">
                            <p-image
                                    [src]="image?.link ? image.link : '../../assets/layout/images/default-image.png'"
                                    alt="Image"
                                    width="100" height="100" [preview]="true"></p-image>
                        </div>
                    </div>
                    <div class="p-col-12" formArrayName="links_logo_images">
                        <div *ngFor="let link of logoLinks.controls; let i=index">
                            <app-text-input [formControl]="link" [label]="'Logo link'"
                                            (delete)="removeLogoLink(i)"
                                            (blurEvent)="changeLogoLink($event, i)"></app-text-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card toolbar-demo" *ngIf="currentUser.role_id !== 3">
                <div class="p-grid">
                    <div class="p-col-12">
                        <h5>{{'financeDetails' | translate}}</h5>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-3">
                                <app-switch-input [formControl]="hotelForm.controls['payee']"
                                                  [label]="'payee' | translate"></app-switch-input>
                            </div>
                            <div class="p-col-12 p-md-3">
                                <app-switch-input [formControl]="hotelForm.controls['is_payee_only']"
                                                  [label]="'isPayeeOnly' | translate"></app-switch-input>
                            </div>
                            <div class="p-col-12 p-md-3">
                                <app-switch-input [formControl]="hotelForm.controls['payee_key_created']"
                                                  [label]="'payeeKeyCreated' | translate"></app-switch-input>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-3">
                                <app-text-input [type]="'number'" [label]="'accountNumber' | translate"
                                                [formControl]="hotelForm.controls['account_number']"></app-text-input>
                            </div>
                            <div class="p-col-12 p-md-3">
                                <app-text-input [type]="'text'" [label]="'bank' | translate"
                                                [formControl]="hotelForm.controls['bank']"></app-text-input>
                            </div>
                            <div class="p-col-12 p-md-6 rem-padding">
                                <app-text-input [type]="'text'" [label]="'bankLocation' | translate"
                                                [formControl]="hotelForm.controls['bank_location']"></app-text-input>
                            </div>
                            <div class="p-col-12 p-md-3">
                                <app-text-input [type]="'number'" [label]="'swiftCode' | translate"
                                                [formControl]="hotelForm.controls['swift_code']"></app-text-input>
                            </div>
                            <div class="p-col-12 p-md-3">
                                <app-text-input [type]="'number'" [label]="'IBAN'"
                                                [formControl]="hotelForm.controls['iban']"></app-text-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card toolbar-demo" *ngIf="currentUser.role_id !== 3">
                <h5>{{'reservationDetails' | translate}}</h5>
                <div class="p-grid">
                    <!--<div class="p-col-12 p-md-6">
                        <app-text-input [label]="'Reservation email'" [type]="'email'"
                                        [formControl]="hotelForm.controls['reservation_email']"></app-text-input>
                    </div>-->
                    <div class="p-col-12 p-md-6">
                        <div class="p-field">
                            <label class="full-width">{{'bookingAccounting' | translate}}</label>
                            <p-autoComplete class="autocomplete" [field]="'name'" formControlName="booking_accounting"
                                            [suggestions]="companies" delay="1000"
                                            (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                                <ng-template let-company pTemplate="item">
                                    <div>{{company.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <small *ngIf="hotelForm.controls.booking_accounting.touched && hotelForm.controls.booking_accounting.errors?.required"
                                   class="p-error">{{'required' | translate}}</small>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <app-text-area [label]="'notes' | translate"
                                       [formControl]="hotelForm.controls['notes']"></app-text-area>
                    </div>
                </div>
            </div>
            <div class="card toolbar-demo" *ngIf="currentUser.role_id !== 3">
                <div class="p-grid">
                    <div class="p-col-12">
                        <h5>{{'rebateOffers' | translate}}</h5>
                        <p-fieldset legend="{{'rebates' | translate}}" styleClass="services-fieldset">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 rem-padding">
                                    <app-switch-input [formControl]="hotelForm.controls['leader_offer']"
                                                      [label]="'groupLeaderOffer' | translate"></app-switch-input>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <app-text-input [formControl]="hotelForm.controls['leader_offer_number']"
                                                    [label]="'groupNumber' | translate"
                                                    [type]="'number'"></app-text-input>
                                </div>
                                <div class="p-col-12 p-md-7 rem-padding">
                                    <app-text-area [formControl]="hotelForm.controls['leader_offer_notes']"
                                                   [label]="'notes' | translate"></app-text-area>
                                </div>
                            </div>

                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 rem-padding">
                                    <app-switch-input [formControl]="hotelForm.controls['pro_leader_offer']"
                                                      [label]="'leaderOffer' | translate"></app-switch-input>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <app-text-input [formControl]="hotelForm.controls['pro_leader_offer_number']"
                                                    [label]="'groupNumber' | translate"
                                                    [type]="'number'"></app-text-input>
                                </div>
                                <div class="p-col-12 p-md-7 rem-padding">
                                    <app-text-area [formControl]="hotelForm.controls['pro_leader_offer_notes']"
                                                   [label]="'notes' | translate"></app-text-area>
                                </div>
                            </div>
                        </p-fieldset>
                        <p-fieldset legend="{{'discounts' | translate}}" styleClass="services-fieldset">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 rem-padding">
                                    <app-switch-input [formControl]="hotelForm.controls['junior']"
                                                      [label]="'junior' | translate"></app-switch-input>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <app-text-input [formControl]="hotelForm.controls['junior_ratio']"
                                                    [label]="'ratio' | translate" [type]="'number'"></app-text-input>
                                </div>
                                <div class="p-col-12 p-md-7 rem-padding">
                                    <app-text-area [formControl]="hotelForm.controls['junior_notes']"
                                                   [label]="'notes' | translate"></app-text-area>
                                </div>
                            </div>

                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 rem-padding">
                                    <app-switch-input [formControl]="hotelForm.controls['travel_agent']"
                                                      [label]="'travelAgentNo' | translate"></app-switch-input>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <app-text-input [formControl]="hotelForm.controls['travel_agent_ratio']"
                                                    [label]="'ratio' | translate" [type]="'number'"></app-text-input>
                                </div>
                                <div class="p-col-12 p-md-7 rem-padding">
                                    <app-text-area [formControl]="hotelForm.controls['travel_agent_notes']"
                                                   [label]="'notes' | translate"></app-text-area>
                                </div>
                            </div>

                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 rem-padding">
                                    <app-switch-input [formControl]="hotelForm.controls['president']"
                                                      [label]="'golfClubPresidentNo' | translate"></app-switch-input>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <app-text-input [formControl]="hotelForm.controls['president_ratio']"
                                                    [label]="'ratio' | translate" [type]="'number'"></app-text-input>
                                </div>
                                <div class="p-col-12 p-md-7 rem-padding">
                                    <app-text-area [formControl]="hotelForm.controls['president_notes']"
                                                   [label]="'notes' | translate"></app-text-area>
                                </div>
                            </div>

                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 rem-padding">
                                    <app-switch-input [formControl]="hotelForm.controls['pro']"
                                                      [label]="'proNo' | translate"></app-switch-input>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <app-text-input [formControl]="hotelForm.controls['pro_ratio']"
                                                    [label]="'ratio' | translate" [type]="'number'"></app-text-input>
                                </div>
                                <div class="p-col-12 p-md-7 rem-padding">
                                    <app-text-area [formControl]="hotelForm.controls['pro_notes']"
                                                   [label]="'notes' | translate"></app-text-area>
                                </div>
                            </div>
                        </p-fieldset>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="!addMode" header="{{'cms' | translate}}">
            <div class="p-grid p-jc-between">
                <div class="p-col">
                </div>
                <div>
                    <button class="float-right ml-2" pButton
                            type="submit">{{(addMode ? 'create' : 'saveChanges') | translate}}</button>
                    <app-switch-input *ngIf="!addMode" [formControl]="hotelForm.controls['show_website']"
                                      [label]="'onWebsite' | translate"
                                      [inline]="true"
                                      class="float-right mr-3"></app-switch-input>
                    <app-switch-input *ngIf="!addMode" [formControl]="hotelForm.controls['top']"
                                      [label]="'topGolfHotel' | translate"
                                      [inline]="true"
                                      class="float-right mr-3"></app-switch-input>
                </div>
            </div>

            <div class="card toolbar-demo">
                <div class="p-grid">
                    <div class="p-col-12 p-md-2 half-rem-padding">
                        <app-select-input [label]="'hotelRating' | translate" [options]="rating"
                                          [formControl]="hotelForm.controls['hotel_rating']"></app-select-input>
                    </div>
                    <div class="p-col half-rem-padding">
                        <div class="p-field">
                            <label class="full-width">{{'relatedHotels' | translate}}</label>
                            <p-autoComplete class="autocomplete" [field]="'name'" formControlName="related_hotels"
                                            [suggestions]="hotels" [multiple]="true" delay="1000" appendTo="body"
                                            (completeMethod)="searchHotels($event)" [forceSelection]="true"
                                            [unique]="true">
                                <ng-template let-value pTemplate="selectedItem">
                                    <div>
                                        <i class="pi pi-circle-fill"
                                           [ngStyle]="{'color': value.publish === 'Published' ? '#4cf94c' :
                                    (value.publish === 'Unpublished' ? '#f15454' : 'orange')}"></i>
                                        {{value.name}}
                                    </div>
                                </ng-template>
                                <ng-template let-hotel pTemplate="item">
                                    <div>{{hotel?.name + ', ' +
                                    hotel?.country?.[translateService.currentLang === 'de' ? 'name_de' : 'name_en'] + ', ' +
                                    hotel?.city?.[translateService.currentLang === 'de' ? 'name_de' : 'name_en'] +
                                    ' (' + hotel?.publish + ')'}}</div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                    <div class="p-col half-rem-padding">
                        <div class="p-field">
                            <label class="full-width">{{'relatedGolfCourses' | translate}}</label>
                            <p-autoComplete class="autocomplete" [field]="'name'" formControlName="golfcourses"
                                            [suggestions]="golfCourses" [multiple]="true" delay="1000"
                                            (completeMethod)="searchCourses($event)" [forceSelection]="true">
                                <ng-template let-value pTemplate="selectedItem">
                                    <div>
                                        <i class="pi pi-circle-fill"
                                           [ngStyle]="{'color': value.publish === 'Published' ? '#4cf94c' :
                                    (value.publish === 'Unpublished' ? '#f15454' : 'orange')}"></i>
                                        {{value.name}}
                                    </div>
                                </ng-template>
                                <ng-template let-course pTemplate="item">
                                    <div>{{course?.name + ', ' +
                                    course?.country?.[translateService.currentLang === 'de' ? 'name_de' : 'name_en'] + ', ' +
                                    course?.city?.[translateService.currentLang === 'de' ? 'name_de' : 'name_en'] +
                                    ' (' + course?.publish + ')'}}</div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card toolbar-demo">
                <h5>{{'hotelPhotos' | translate}}</h5>
                <div class="p-grid">
                    <div class="p-col-12">
                        <app-images-list [limit]="20" [count]="hotel?.images?.length"
                                         #uploader (delete)="deleteImage($event)"
                                         (mainImageChanged)="changeMainImage($event)"
                                         (onSelectImages)="uploadImages()"
                                         [images]="hotel?.images"></app-images-list>
                    </div>

                </div>
            </div>
            <div class="card toolbar-demo">
                <h5>{{'imagesLinks' | translate}}</h5>
                <div class="p-grid">
                    <div class="p-col-12" formArrayName="links_hd_images">
                        <div *ngFor="let link of hdLinks.controls; let i=index">
                            <app-text-input [formControl]="link" [label]="'Link (' + (i + 1) + ')'"
                                            (delete)="removeHdLinks(i)" (blurEvent)="changePicLink($event, i)"
                                            [showTrash]="true"></app-text-input>
                        </div>
                        <div class="buttons">
                            <button pButton type="button" (click)="addHdLinks()">{{'addLink' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card toolbar-demo">
                <h5>{{'fields' | translate}}</h5>
                <!--<div class="p-grid mt-3">
                    <div class="p-col-12 p-md-12">
                        <div class="p-field">
                            <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                            [formControl]="hotelForm.controls['internal_description_mode']"
                                            styleClass="language-switch"></p-selectButton>
                            <textarea *ngIf="hotelForm.get('internal_description_mode').value == 1"
                                      class="full-width"
                                      pInputText
                                      [formControl]="translations.controls[1].get('internal_description')"
                                      placeholder="Description (Internal) - EN" rows="2"
                                      autocomplete="false"></textarea>
                            <textarea *ngIf="hotelForm.get('internal_description_mode').value == 2"
                                      class="full-width"
                                      pInputText
                                      [formControl]="translations.controls[0].get('internal_description')"
                                      placeholder="Description (Internal) - DE" rows="2"
                                      autocomplete="false"></textarea>
                        </div>
                    </div>
                </div>-->
                <div *ngFor="let field of fields.controls; let i = index; let last = last">
                    <div class="p-3" [ngClass]="i % 2 == 0 ? 'gray-bg' : null">
                        <h6>{{getFieldName(field.get('type_id').value)}}</h6>
                        <div *ngIf="field.get('is_html')?.value == 1" class="p-col-12 p-md-12 quill">
                            <div style="display: flow-root">
                                <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                                optionValue="id" optionLabel="name"
                                                [formControl]="field.get('is_html')"></p-selectButton>
                                <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                                [formControl]="field.get('language_mode')"
                                                styleClass="language-switch"></p-selectButton>
                            </div>
                            <app-editor [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                          [placeholder]="'content' | translate"></app-editor>
                        </div>
                        <div *ngIf="field.get('is_html').value == 0" class="p-col-12 p-md-12">
                            <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                            optionValue="id" optionLabel="name"
                                            [formControl]="field.get('is_html')"></p-selectButton>
                            <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                            [formControl]="field.get('language_mode')"
                                            styleClass="language-switch"></p-selectButton>
                            <textarea class="full-width" pInputText
                                      [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                      [placeholder]="'Content'"
                                      rows="4" autocomplete="false"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card toolbar-demo">
                <h5>{{'tags' | translate}}</h5>
                <div class="p-grid">
                    <div class="p-col-12">
                        <span class="p-fluid">
                            <p-autoComplete [formControl]="hotelForm.get('tags')" [suggestions]="filteredTags"
                                            (completeMethod)="filterTags($event)" field="name" [multiple]="true"
                                            (onSelect)="tagSelected($event)" delay="1000">
                            </p-autoComplete>
                        </span>
                    </div>

                </div>
            </div>
            <div class="card toolbar-demo">
                <h5>{{'locationDetails' | translate}}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-8">
                        <app-text-input [type]="'text'" [label]="'locationLink' | translate"
                                        (blurEvent)="getLangLat($event)"
                                        [formControl]="hotelForm.controls['location_link']"></app-text-input>
                    </div>
                    <div class="p-col-6 p-md-2">
                        <app-text-input [type]="'text'" [label]="'latitude' | translate"
                                        [formControl]="hotelForm.controls['latitude']"></app-text-input>
                    </div>
                    <div class="p-col-6 p-md-2">
                        <app-text-input [type]="'text'" [label]="'longitude' | translate"
                                        [formControl]="hotelForm.controls['longitude']"></app-text-input>
                    </div>
                </div>
            </div>


        </p-tabPanel>
        <!--<p-tabPanel *ngIf="!addMode" header="Facilities">
            <div *ngIf="facilities.controls.length > 0" class="card toolbar-demo">
                <div class="p-grid p-3">
                    <div class="p-col-6 p-md-3" style="padding: 0 !important;"
                         *ngFor="let facility of  facilities.controls; let i=index">
                        &lt;!&ndash;<div class="p-grid">
                            <div class="p-col-12 p-md-5 rem-padding">
                                <app-switch-input [label]="facility.get('name').value"
                                                  [formControl]="facility.get('available')"
                                ></app-switch-input>
                            </div>
                            <div class="p-col-12 p-md-2">
                            </div>
                        </div>&ndash;&gt;
                        <div class="p-field">
                            <label class="full-width">
                                {{facility.get('name').value}}</label>

                            <p-inputSwitch class="switch-input" [formControl]="facility.get('available')"
                                           [trueValue]="1" [falseValue]="0"></p-inputSwitch>
                            <img *ngIf="facility.get('icon')?.value" [src]="'../../../../assets/services-icons/' +
                                        facility.get('icon')?.value + '.svg'"
                                 width="22px" class="filter-petrol service-icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>-->
        <p-tabPanel *ngIf="!addMode" header="Services">
            <div class="card toolbar-demo">
                <div class="p-grid">
                    <div class="p-col-6">
                        <h5>{{'services' | translate}}</h5>
                    </div>
                    <div class="p-col-6">
                        <button class="float-right" pButton
                                type="submit">{{(addMode ? 'create' : 'saveChanges') | translate}}</button>
                    </div>
                </div>
                <p-fieldset *ngFor="let type of hotelServicesTypes"
                            legend="{{type.replace('-', ' ') + ' Services'}}" styleClass="services-fieldset">
                    <div class="p-grid">
                        <ng-container *ngFor="let service of hotelServicesFormArray.controls; let i = index">
                            <div class="p-col-3" *ngIf="service.get('type').value === type">
                                <div class="p-field">
                                    <label class="full-width">
                                        {{service.get('name').value}}</label>

                                    <p-inputSwitch class="switch-input" [formControl]="service.get('selected_option')"
                                                   [trueValue]="1" [falseValue]="0"></p-inputSwitch>
                                    <img *ngIf="service.get('icon').value" [src]="'../../../../assets/services-icons/' +
                                        service.get('icon').value + '.svg'"
                                         width="22px" class="filter-petrol service-icon"/>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </p-fieldset>
            </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="!addMode && hotel && roomTypes" header="Rooms Details">
            <app-rooms-details *ngIf="hotel.id" [roomFieldTypes]="roomFieldTypes"
                               [roomTypes]="roomTypes" [hotelRooms]="hotel.rooms"
                               [facilitiesList]="roomFacilities" [hotelId]="hotelId"
                               (hotelUpdated)="hotel.is_publish_required = $event"></app-rooms-details>
        </p-tabPanel>
    </p-tabView>
    <!--<ul class="invalid-controls">
        <li *ngFor="let control of invalidControls">{{control}} is invalid</li>
    </ul> -->
    <div *ngIf="addMode" class="buttons">
        <button class="float-right" pButton type="submit">{{(addMode ? 'create' : 'saveChanges') | translate}}</button>
    </div>
</form>
