<p-toast></p-toast>

<div class="card">
    <p-table #dt dataKey="id"
             [value]="seasons$ | async"
             [(selection)]="selectedSeasons"
             [rowHover]="true"
             [rows]="10"
             [paginator]="true"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showCurrentPageReport]="true"
             [globalFilterFields]="['name']"
             [scrollable]="true"
             [scrollDirection]="'both'"
             [showJumpToPageInput]="true" [rowsPerPageOptions]="[10, 50, 100, 200]"
    >
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 *ngIf="!companyId" class="p-m-1 float-left">Seasons</h5>

                    <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-primary p-ml-2" (click)="addNew()" *ngIf="checkPermission('1636370036704')"></button>
                </div>

                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636370116621')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 120px" pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="service_name">Contract <p-sortIcon field="service_name"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="code">Season Code <p-sortIcon field="code"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="from" class="p-d-flex p-jc-between p-ai-center">
                    From
                    <p-sortIcon field="from"></p-sortIcon>
                    <p-columnFilter field="from" type="date" display="menu"></p-columnFilter>
                </th>
                <th style="width: 120px" pSortableColumn="to" class="p-d-flex p-jc-between p-ai-center">
                    To
                    <p-sortIcon field="to"></p-sortIcon>
                    <p-columnFilter field="to" type="date" display="menu"></p-columnFilter>
                </th>
                <th style="width: 120px" pSortableColumn="color" class="p-d-flex p-jc-between p-ai-center">
                    Color
                    <app-filter fieldName="color" [isColor]="true" [filterOptions]="filters.color"></app-filter>
                </th>
                <th style="width: 60px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370051381') || checkPermission('1636370066439') || checkPermission('1636370085328')"
                >Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-season>
            <tr>
                <td style="width: 120px; cursor: pointer">
                    <a (click)="edit(season)">{{season.title}}</a>
                </td>
                <td style="width: 120px">
                    {{season.service_name}}
                </td>
                <td style="width: 120px">
                    <a [routerLink]="'edit/' + season.id">{{season.code}}</a>
                </td>
                <td style="width: 120px">
                    {{season.start_date | date}}
                </td>
                <td style="width: 120px">
                    {{season.end_date | date}}
                </td>
                <td style="width: 120px">
                    <span [ngStyle]="{'display': 'inline-block', 'background-color': season.color, 'padding': '8px'}"></span>
                </td>

                <td style="width:60px"  alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370051381') || checkPermission('1636370066439') || checkPermission('1636370085328')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngIf="checkPermission('1636370066439')"><a class="dropdown-item" (click)="edit(season)">Edit</a></li>
                            <li role="menuitem" *ngIf="checkPermission('1636370085328')"><a class="dropdown-item" (click)="delete(season)">Delete</a></li>
                        </ul>
                    </div>

                </td>

            </tr>
        </ng-template>
    </p-table>
</div>
