import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GGTreeNode} from '../../_models/GGTreeModel';
import {Tree} from 'primeng/tree';
import {SharedService} from '../../_services/shared.service';
import {DestinationsService} from '../../destination/_services/destinations.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-countries-tree',
    templateUrl: './countries-tree.component.html',
    styleUrls: ['./countries-tree.component.css']
})
export class CountriesTreeComponent implements OnInit {

    destinationsTree: GGTreeNode[] = [];
    isLoadingTree = false;
    @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();
    filterValue = '';
    @ViewChild('tree', {static: true}) tree: Tree;

    constructor(private destinationsService: DestinationsService, private elem: ElementRef,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        this.getAllCountries();
    }

    getAllCountries() {
        this.destinationsService.getAllCountries().subscribe((res: any) => {
            this.buildTree(res);
        });
    }

    buildTree(countries) {
        let treeCountry: any = {};
        countries.forEach((country, countryIndex) => {
            treeCountry.id = country.id;
            treeCountry.label = country.code + ' - ' +
                country[this.translateService.currentLang === 'de' ? 'name_de' : 'name_en'];
            treeCountry.code = country.code;
            treeCountry.level = 0;
            treeCountry.children = [];
            treeCountry.indexes = [countryIndex];
            treeCountry.key = 'country-' + country.id;

            let treeCity: any = {};
            country.cities.forEach((city, cityIndex) => {
                treeCity.id = city.id;
                treeCity.label = city.code + ' - ' +
                    city[this.translateService.currentLang === 'de' ? 'name_de' : 'name_en'];
                treeCity.code = city.code;
                treeCity.level = 1;
                treeCity.indexes = [countryIndex, cityIndex];
                treeCity.children = [];
                treeCity.key = 'city-' + city.id;

                treeCountry.children.push(treeCity);
                treeCity = {};
            });
            this.destinationsTree.push(treeCountry);
            treeCountry = {};
        });
    }

    nodeSelected(event) {
        this.selectedItem.emit(event.node);
    }

    onFilter(event) {
        this.filterValue = event.filter;
    }

    refillFilterInput() {
        this.tree._filter(this.filterValue);
        const elements = this.elem.nativeElement.querySelectorAll('.p-tree-filter');
        elements[0].value = this.filterValue;
    }

}
