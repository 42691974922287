import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ClientRequestsComponent} from './client-requests/client-requests.component';
import {AuthGuard} from '../_guards/auth.guard';

const routes: Routes = [
    {path: '', redirectTo: 'client-requests', pathMatch: 'full'},
    {path: 'client-requests', component: ClientRequestsComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, page_id: 23, type: 'page'}}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientPortalRoutingModule { }
