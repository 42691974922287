<div class="p-field">
    <label>{{label}} <span class="p-error">{{checkRequired(ngControl.control) ? '*' : ''}}</span></label>
    <p-dropdown [options]="options" [formControl]="ngControl.control" [appendTo]="'body'"
                [optionLabel]="optionLabel" [optionValue]="optionValue" [showClear]="true"
                [placeholder]="label" (onChange)="valueChanged($event)" (onClick)="clicked()"
                [disabled]="disabled" [filter]="filter" [emptyMessage]="emptyMessage"
    ></p-dropdown>

    <small *ngIf="ngControl.control.touched && ngControl.control.errors?.required" class="p-error">required</small>
</div>
