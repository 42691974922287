<div class="p-fluid">
    <div class="p-grid">
        <div class="p-col-12">
            <p-fileUpload multiple="multiple"
                          accept="image/*"
                          maxFileSize="50000000"
                          customUpload="true" #uploader
                          withCredentials="true"
                          [fileLimit]="limit - count"
                          [disabled]="disabled || images?.length == 20"
                          [showUploadButton]="false"
                          [showCancelButton]="false"
                          (onSelect)="photosSelected()"
                          chooseLabel="{{'chooseLabel' | translate}}">
                <ng-template pTemplate="toolbar">
                    <div *ngIf="images?.length < 20" class="hint">{{'1MaxSize' | translate}}</div>
                    <div *ngIf="images?.length < 20" class="hint">
                        {{'YouCanUpload' | translate}} {{limit - count}} {{'more' | translate}}
                        {{limit - count > 1 ? ('photos' | translate) : ('photo' | translate)}}</div>
                    <div *ngIf="images?.length >= 20" class="hint">{{'20MaxPhotos' | translate}}
                    </div>
                </ng-template>

            </p-fileUpload>
        </div>
        <div *ngIf="showGallery" class="p-col-12 images-container" cdkDropList (cdkDropListDropped)="drop($event)"
             cdkDropListOrientation="horizontal"
        [ngStyle]="{'height': images?.length == 0 ? '0px' : '232px' }">
            <div class="image-div" *ngFor="let control of form.controls; let i = index" cdkDrag
                 style="background-color: #FFFFFF">
                <p-badge class="rank-badge" [value]="i + 1"></p-badge>
                <img [src]="control.get('src').value" alt="Image" width="100" height="100" (click)="viewImage(i)"
                     (mouseover)="itemSelected(control.value); op.toggle($event)" (mouseleave)="op.toggle($event)">
                <app-text-area [formControl]="form.controls[i].get('alt')" draggable="false"
                               placeholder="{{'imageAlt' | translate}}"></app-text-area>
                <p-checkbox *ngIf="showImageSelection" class="float-left" [binary]="true" [formControl]="form.controls[i].get('checked')"
                            (onChange)="selectionChanged($event)"></p-checkbox>

                <i [class]="'mt-1 pi ' + (control.get('isMain').value ? 'pi-check' : 'pi-heart')"
                   (click)="changeMainImage(control.value.id)"
                   [pTooltip]="control.get('isMain') ? ('featuredImage' | translate) : ('setAsFeaturedImage' | translate)"
                   tooltipPosition="bottom"></i>
                <i class="pi pi-trash trash-icon float-right" (click)="deleteImage(control.value.id)"></i>
            </div>
        </div>
    </div>
</div>
<div *ngIf="images?.length > 0 && showImageSelection" class="buttons mt-3">
    <button *ngIf="selectionsCount > 0" pButton type="button" (click)="deleteSelected()"
            class="float-right p-button-danger ml-2">{{('deleteSelected' | translate) + ' (' + selectionsCount + ')'}}</button>
    <button pButton type="button" (click)="allSelection()"
            class="float-right">{{selectionsCount === images?.length ?
        ('deselectAll' | translate) : ('selectAll' | translate)}}</button>
</div>
<p-galleria [(value)]="images" [(visible)]="displayGalley" [responsiveOptions]="responsiveOptions2"
            [containerStyle]="{'max-width': '50%'}" [numVisible]="9"
            [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000"
            [activeIndex]="activeIndex">
    <ng-template pTemplate="item" let-item>
        <img [src]="item.file_name" style="width: 100%; display: block;"/>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item.file_name" width="80" height="80" style="display: block;"/>
        </div>
    </ng-template>
</p-galleria>

<p-overlayPanel #op [style]="{width: '250px'}">
    <ng-template pTemplate>
        <p style="font-size: 12px;word-break: break-all"><b>Name: </b>{{selectedItem?.original_file_name}}</p>
        <p style="font-size: 12px"><b>size: </b>{{selectedItem?.size / (1024*1024) | number : '1.2-2'}} MB</p>
    </ng-template>
</p-overlayPanel>
