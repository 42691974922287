import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Region, Country, City} from '../../../_models/destinations';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {DestinationsService} from '../../_services/destinations.service';
import {Lookup} from '../../../_models/lookup';
import {ImagesListComponent} from '../../../_shared/images-list/images-list.component';
import {GolfCoursesService} from '../../../golf-courses/_services/golf-courses.service';
import {HotelsService} from '../../../hotels/_services/hotels.service';
import {GfpService} from '../../../product-setup/_services/gfp.service';
import {HotelProductsService} from '../../../product-setup/_services/hotel-products.service';
import {GolfHolidayService} from '../../../product-setup/_services/golf-holiday.service';
import {TestimoniesComponent} from '../../testimonies/testimonies.component';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {AccountService} from "../../../_services/account.service";

@Component({
    selector: 'app-city-form',
    templateUrl: './city-form.component.html',
    styleUrls: ['./city-form.component.css']
})
export class CityFormComponent implements OnInit, OnChanges {
    cityForm: UntypedFormGroup;
    city: any;
    isEditMode = false;
    regions: Region[];
    countries: Country[];
    @Input() cityId: number;
    fieldTypes: any[];
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];

    golfCourses = [];
    hotels = [];
    golfProducts = [];
    hotelProducts = [];
    golfHolidays = [];
    currencies = [];
    cities = [];
    @ViewChild('uploader', {static: false}) imagesUploader: ImagesListComponent;
    @ViewChild('testimony', {static: false}) testimonyComponent: TestimoniesComponent;
    showWebsiteCurrentValue: number;
    areasCount: number;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private msgService: MessageService, private router: Router,
        private activatedRoute: ActivatedRoute, private loader: NgxUiLoaderService,
        private fb: UntypedFormBuilder, private confirmationService: ConfirmationService,
        private destService: DestinationsService, private golfCoursesService: GolfCoursesService,
        private hotelsService: HotelsService, private gfpService: GfpService,
        private hotelProductsService: HotelProductsService, public translateService: TranslateService,
        private golfHolidayService: GolfHolidayService, public translate: TranslatePipe,
        private accountService: AccountService) {
    }

    ngOnInit() {
        // init form
        this.initForm();

        // get Regions for dropdown select
        this.getRegions();
        this.getAllCities();
        this.getFieldTypes();

        // check edit mode
        const cityId = this.cityId || +this.activatedRoute.snapshot.paramMap.get('id');
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('regions'), routerLink: '/destinations/regions'},
            {label: cityId > 0 ? this.translate.transform('editRegion') : this.translate.transform('addRegion')},
        ]);
        if (cityId > 0) {
            this.isEditMode = true;
            if (!this.accountService.checkPermission('1697457109424')) {
                this.cityForm.get('region_id').disable();
                this.cityForm.get('country_id').disable();
            }
            this.fetchCity(cityId);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initForm();
        this.fetchCity(this.cityId);
    }

    fetchCity(cityId) {
        this.getCity(cityId).subscribe((city: any) => {
            this.city = city;
            this.relatedAreas();
            this.city.name_en = this.city.translations[0].name;
            this.city.name_de = this.city.translations[1].name;
            this.showWebsiteCurrentValue = this.city.show_website;
            this.city.featured_golf_courses = this.city.featured_golf_courses?.map(item => {
                return item.id;
            });
            this.city.featured_hotels = this.city.featured_hotels?.map(item => {
                return item.id;
            });
            this.city.featured_products = this.city.featured_products?.map(item => {
                return item.id;
            });
            this.city.featured_hotel_products = this.city.featured_hotel_products?.map(item => {
                return item.id;
            });
            this.city.featured_golf_holidays = this.city.featured_golf_holidays?.map(item => {
                return item.id;
            });
            this.city.featured_cities = this.city.featured_cities?.map(item => {
                return item.id;
            });
            this.city.related_regions = this.city?.related_regions?.split(',').map((e) => {
                return +e;
            });
            this.cityForm.patchValue(this.city);
            this.fillFieldsData(this.city.fields);
            this.fillFaqsData(this.city.faqs);
            this.fetchCountries();
        });
        this.getGolfCoursesByCity(cityId);
        this.getHotelsByCity(cityId);
        /*this.getProductsByCity(cityId);
        this.getHotelProductsByCity(cityId);
        this.getGolfHolidaysByCity(cityId);*/
    }

    initForm(): void {
        this.cityForm = this.fb.group({
            id: [''],
            name_en: ['', Validators.required],
            name_de: ['', Validators.required],
            code: ['', Validators.required],
            status: [1],
            region_id: ['', Validators.required],
            country_id: ['', Validators.required],
            fields: this.fb.array([]),
            faqs: this.fb.array([]),
            featured_golf_courses: null,
            featured_hotels: null,
            featured_products: null,
            featured_hotel_products: null,
            featured_golf_holidays: null,
            related_regions: null,
            show_website: 0,
            is_publish_required: null,
            translations: this.fb.array([
                this.fb.group({
                    language_id: 1,
                    name: [null]
                }),
                this.fb.group({
                    language_id: 2,
                    name: [null]
                })
            ]),
            top: 0
        });
    }

    initFields() {
        this.fieldTypes?.forEach((fl, index) => {
            this.fields.push(
                this.fb.group({
                    id: null,
                    type_id: fl.id,
                    is_html: 0,
                    language_mode: 2,
                    translations: this.fb.array([])
                })
            );
            const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
            fl.translations.forEach((translation, n) => {
                fieldTranslations.push(
                    this.fb.group({
                        language_id: translation.language_id,
                        description: null
                    })
                );
            });
        });
    }

    getRegions(): void {
        this.destService.getRegionsList().subscribe(res => {
            this.regions = res;
        });
    }

    getAllCities() {
        this.destService.getCitiesList().subscribe(res => {
            this.cities = res;
        });
    }

    relatedAreas() {
        this.destService.getAllAreas(this.city.id).subscribe(res => {
            if (res) {
                this.areasCount = res.length;
            } else {
                this.areasCount = 0;
            }
        });
    }

    getGolfCoursesByCity(cityId) {
        this.golfCoursesService.getGolfCoursesByCity(cityId).subscribe((res: any) => {
            this.golfCourses = res;
        });
    }

    getHotelsByCity(cityId) {
        this.hotelsService.getAllHotelsByCity(cityId).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    getProductsByCity(cityId) {
        this.gfpService.getProductsByCity(cityId).subscribe((res: any) => {
            this.golfProducts = res;
        });
    }

    getHotelProductsByCity(cityId) {
        this.hotelProductsService.getAll(cityId).subscribe((res: any) => {
            this.hotelProducts = res.products;
        });
    }

    getGolfHolidaysByCity(cityId) {
        this.golfHolidayService.getAll(null, cityId).subscribe((res: any) => {
            this.golfHolidays = res.products;
        });
    }

    getCity(id: number): Observable<City> {
        return this.destService.getCity(id);
    }

    get fields() {
        return this.cityForm.controls.fields as UntypedFormArray;
    }

    get faqs() {
        return this.cityForm.controls.faqs as UntypedFormArray;
    }

    fillFieldsData(fields?: any[]) {
        this.fields.clear();
        this.fieldTypes?.forEach((fl, index) => {
            const field = fields.filter(x => +x.type_id === fl.id)[0];
            if (field) {
                this.fields.push(
                    this.fb.group({
                        id: field.id,
                        type_id: field.type_id,
                        is_html: field.is_html,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach((translation, n) => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: field.translations[n]?.description
                        })
                    );
                });
            } else {
                this.fields.push(
                    this.fb.group({
                        id: null,
                        type_id: fl.id,
                        is_html: 0,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach(translation => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: null
                        })
                    );
                });
            }
        });
    }

    getFieldName(fieldId) {
        const name = this.fieldTypes.filter(x => x.id === +fieldId)[0]
            .translations[this.translateService.currentLang === 'de' ? 1 : 0].name;
        return name;
    }

    fillFaqsData(faqs: any[]) {
        this.faqs.clear();
        faqs?.forEach((faq, index) => {
            this.faqs.push(
                this.fb.group({
                    id: faq.id,
                    question: [faq.question, Validators.required],
                    answer: [faq.answer, Validators.required],
                })
            );
        });
    }

    addFaq() {
        this.faqs.push(this.fb.group({
            id: null,
            question: [null, Validators.required],
            answer: [null, Validators.required],
        }));
    }

    removeFaq(i) {
        this.faqs.removeAt(i);
    }

    getFieldTypes() {
        this.destService.getCityFieldTypes().subscribe(res => {
            this.fieldTypes = res;
            if (!this.isEditMode) {
                this.initFields();
            }
        });
    }

    deleteImage(idsList) {
        this.destService.deleteCityImage(this.city.id, idsList).subscribe((res: any) => {
            this.city.images = res.city.images;
            this.msgService.add({
                severity: 'success', summary: this.translate.transform('regions'),
                detail: 'Selected image has been deleted successfully'
            });
            this.checkMainImage();
        });
    }

    submit() {
        // check form validation
        if (!this.cityForm.valid) {
            this.cityForm.markAllAsTouched();
            return false;
        }

        // submit form
        const cityData: City = {...this.cityForm.getRawValue()};
        cityData.translations[0].name = cityData.name_en;
        cityData.translations[1].name = cityData.name_de;
        cityData.status = this.cityForm.getRawValue().status ? 1 : 0;

        if (cityData.featured_golf_courses == null) {
            cityData.featured_golf_courses = [];
        }
        if (cityData.featured_hotels == null) {
            cityData.featured_hotels = [];
        }
        if (cityData.featured_products == null) {
            cityData.featured_products = [];
        }
        if (cityData.featured_hotel_products == null) {
            cityData.featured_hotel_products = [];
        }
        if (cityData.featured_golf_holidays == null) {
            cityData.featured_golf_holidays = [];
        }
        cityData.related_regions = cityData?.related_regions?.toString();

        // check if edit mode is active
        if (!this.isEditMode) {
            // add new City
            this.destService.addCity(cityData).subscribe(res => {
                if (res) {
                    this.msgService.add({
                        severity: 'success',
                        summary: this.translate.transform('regions'),
                        detail: this.translate.transform('addRegionMessage')
                    });
                    this.resetPage();
                }
            });
        } else {
            // update City
            cityData.images = this.imagesUploader.getFormValue();
            this.destService.updateCity(cityData).subscribe((res: any) => {
                if (res) {
                    this.city = res;
                    if (+this.showWebsiteCurrentValue === 1 && +this.city.show_website === 0) {
                        this.publishToWebsite();
                    } else {
                        this.showWebsiteCurrentValue = this.city.show_website;
                    }
                    this.msgService.add({
                        severity: 'success',
                        summary: this.translate.transform('regions'),
                        detail: this.translate.transform('updateRegionMessage')
                    });
                }
            });
        }
    }

    uploadImages() {
        const files = this.imagesUploader.getFiles();
        const formData: FormData = new FormData();
        if (files.length === 0) {
            return false;
        }
        this.loader.start('uploadImages');
        files.forEach(file => {
            formData.append('images[]', file);
            formData.append('size[]', file.size.toString());
            formData.append('original_file_name[]', file.name);
        });
        this.destService.uploadCityImages(this.city.id, formData).subscribe((res: any) => {
            this.city.images = res.city.images;
            this.msgService.add({
                severity: 'success', summary: this.translate.transform('regions'),
                detail: this.translate.transform('updateRegionMessage')
            });
            this.imagesUploader.clearUploader();
            this.loader.stop('uploadImages');
            this.checkMainImage();
        });
    }

    addTestimony(event) {
        this.destService.createCityTestimony(this.city.id, event).subscribe((res: any) => {
            this.city.testimonies = res.city.testimonies;
            this.testimonyComponent.displayForm = false;
        });
    }

    changeMainImage(id) {
        this.destService.changeCityMainImage(this.city.id, {image_id: id}).subscribe((res: any) => {
            this.city.images = res.city.images;
            this.msgService.add({
                severity: 'success', summary: this.translate.transform('regions'),
                detail: this.translate.transform('updateRegionMessage')
            });
        });
    }

    publishToWebsite() {
        const obj = {
            auth: 'GAP_API',
            token: environment.token,
            type: 'city',
            object_id: this.city.id
        };
        this.hotelsService.publishHotelToWebsite(obj).subscribe((res: any) => {
            if (res.update_status === 'Country is not published for this City') {
                this.confirmationService.confirm({
                    message: this.translate.transform('publishCityMessage'),
                    accept: () => {
                        this.destService.getCountry(this.city.country.id).subscribe((country: Country) => {
                            this.prepareCountryDateForUpdate(country);
                            this.destService.updateCountry(country).subscribe((updatedCountry: Country) => {
                                const countryObj = {
                                    auth: 'GAP_API',
                                    token: environment.token,
                                    type: 'country',
                                    object_id: this.city.country.id
                                };
                                this.hotelsService.publishHotelToWebsite(countryObj).subscribe((response: any) => {
                                    if (response.update_status) {
                                        this.destService.publishCountry(country.id).subscribe((resp: any) => {
                                            this.msgService.add({
                                                severity: 'success', summary: this.translate.transform('countries'),
                                                detail: this.translate.transform('publishMessage')
                                            });
                                        });
                                        this.publishToWebsite();
                                    }
                                });
                            });
                        });
                    },
                    acceptLabel: this.translate.transform('yes'),
                    rejectLabel: this.translate.transform('no'),
                    header: this.translate.transform('confirmation')
                });
            } else if (res.update_status === 'Updated Successfully') {
                this.destService.publishCity(this.city.id).subscribe((resp: any) => {
                    this.city.is_publish_required = 0;
                    this.msgService.add({
                        severity: 'success', summary: this.translate.transform('regions'),
                        detail: this.translate.transform('publishMessage')
                    });
                });
            }
        });
    }

    checkMainImage() {
        let isMain = false;
        this.city.images.forEach(image => {
            if (image.is_main) {
                isMain = true;
            }
        });
        if (!isMain && this.city.images.length > 0) {
            this.changeMainImage(this.city.images[0]?.id);
        }
    }

    fetchCountries(change = false, event?: any) {
        if (change) {
            this.cityForm.get('country_id').setValue(null);
        }
        this.destService.getCountriesList(event ? event.value : this.city?.region_id)
            .subscribe(res => {
                this.countries = res;
            });
    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0]
            [this.translateService.currentLang === 'de' ? 'name_de' : 'name_en'];
        return regionName;
    }

    getCountryName(countryId) {
        const countryName = this.countries.filter(country => country.id === countryId)[0]
            [this.translateService.currentLang === 'de' ? 'name_de' : 'name_en'];
        return countryName;
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

    prepareCountryDateForUpdate(country: Country) {
        country.show_website = 1;
        country.featured_cities = country.featured_cities.map(city => {
            return city.id;
        });
        country.featured_golf_courses = country.featured_golf_courses.map(course => {
            return course.id;
        });
        country.featured_hotels = country.featured_hotels.map(hotel => {
            return hotel.id;
        });
        /*country.featured_products = country.featured_products.map(product => {
            return product.id;
        });
        country.featured_golf_holidays = country.featured_golf_holidays.map(holiday => {
            return holiday.id;
        });
        country.featured_hotel_products = country.featured_hotel_products.map(hotelProduct => {
            return hotelProduct.id;
        });*/
        return country;
    }

}
