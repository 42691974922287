import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../../../_models/lookup';
import {ProductService} from '../../../../_models/product-service';
import {GolfCourse} from '../../../../_models/golf-course';
import {Company} from '../../../../_models/company';
import {GolfCoursesService} from '../../../../golf-courses/_services/golf-courses.service';
import {SharedService} from '../../../../_services/shared.service';
import {GfpService} from '../../../_services/gfp.service';
import {ProductAPIService} from '../../../_services/product-api.service';
import {CompaniesService} from '../../../../_services/companies-service';
import {HotelsService} from '../../../../hotels/_services/hotels.service';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {TagsService} from '../../../../_services/tags.service';
import {RoutingState} from '../../../../_services/route-state.service';
import {AccountService} from '../../../../_services/account.service';
import {User} from '../../../../_models/user';

@Component({
    selector: 'app-gfp-package',
    templateUrl: './gfp-package.component.html',
    styleUrls: ['./gfp-package.component.css']
})
export class GfpPackageComponent implements OnInit, OnChanges {

    editMode = false;
    form: UntypedFormGroup;
    detailForm: UntypedFormGroup;
    services: ProductService[];
    teeTimes: Lookup[];
    holes: Lookup[];
    golfCourses: GolfCourse[];
    bookingOptions = ['Hotel', 'Region'];
    serviceHandlerTypes: Lookup[] = [];
    bookings: Company[] = [];
    companies: Company[];
    golfClubs: Company[];
    serviceHandlersTypesIds = [3, 4, 6];
    serviceHandlers: Company[];
    providers: Company[] = [];
    showServiceInfoButton = true;
    selectedService: any;
    switchOptions = ['Fixed', 'Min-Max'];
    availableTimes: Lookup[] = [];
    availableMinTimes: Lookup[] = [];
    availableMaxValues: Lookup[] = [];
    details = [];
    product: any;
    detailForEdit: any = null;
    @Input() productId: number;
    showTeeTimeCountError = false;
    missingTeeTimesCount = 0;
    invalidControls: any[];
    filteredTags: any;
    bookingCodes: any[];
    showContract = false;
    company: Company;
    previousRoute: string;
    user: User;

    constructor(private fb: UntypedFormBuilder, private golfCoursesService: GolfCoursesService,
                private sharedService: SharedService, private router: Router,
                private gfpServices: GfpService, private productsServices: ProductAPIService,
                private companiesService: CompaniesService, private hotelServices: HotelsService,
                private messageService: MessageService, private route: ActivatedRoute,
                private breadcrumbService: BreadcrumbService, private tagsService: TagsService,
                private routingState: RoutingState, private accountService: AccountService) {
    }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe(res => {
            this.user = res;
        });
        this.previousRoute = this.routingState.getPreviousUrl();
        if (this.productId || this.route.snapshot.paramMap.get('id')) {
            this.editMode = true;
            this.fetchData();
        } else if (this.route.snapshot.queryParamMap.get('company_id')) {
            if (history.state.company) {
                this.company = history.state.company;
                // this.searchBookingCodes({query: this.company.booking_code});
                this.getCompanyAndSPBoobingCodes(this.company.id);
            } else {
                this.getCompany();
            }
        }
        this.setBreadcrumb();
        this.initForm();
        this.getTeeTimes();
        this.getCompanyTypes();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.form) {
            this.form.reset();
            this.initForm();
        }
        this.fetchData();
    }

    setBreadcrumb() {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {
                label: 'GFPs', routerLink: this.previousRoute.includes('gfp') ? this.previousRoute :
                    (this.user.company_id === 1 ? '/product-setup/gfp' : '/admin/companies/edit/' +
                        this.user.company_id + '/gfps')
            },
            {label: this.editMode ? 'Edit GFP' : 'Add GFP'}
        ]);
    }

    initForm(): void {
        this.form = this.fb.group({
            id: null,
            is_package: 1,
            name: [null, Validators.required],
            booking_code: [null, Validators.required],
            service_id: [null, Validators.required],
            tee_time_id: [null, Validators.required],
            ref_code: [null],
            code: [{value: null, disabled: true}],
            tui_code: [null],
            additional_information: [null],
            /*validity_from: [new Date(), Validators.required],
            validity_to: [new Date(new Date().setFullYear(new Date().getFullYear() + 1)), Validators.required],*/
            multi_players_only: [0],
            invoice_handler_id: null,
            service_handler_type_id: [null, Validators.required],
            service_handler_id: [{value: null, disabled: true}],
            booking_possible_for: [null, Validators.required],
            hotels: [{value: null, disabled: true}],
            use_service_configurations: [1],
            use_destination_hotel: 0,
            status: [1],
            tags: null
        });
        this.detailForm = this.fb.group({
            id: null,
            company: [null, Validators.required],
            company_id: null,
            company_name: null,

            golf_course: [null, Validators.required],
            golf_course_id: null,
            golf_course_name: null,

            type: [null, Validators.required],

            tee_time: null,
            tee_time_id: 0,
            tee_time_name: null,

            min_tee_time: null,
            min_tee_time_id: 0,
            min_tee_time_name: null,

            max_tee_time: null,
            max_tee_time_id: 0,
            max_tee_time_name: null
        });
        this.detailForm.disable();
        this.serviceInfoButtonClick({checked: 1});
    }

    async fetchData() {
        this.editMode = true;
        const productId = this.productId ? this.productId : +this.route.snapshot.paramMap.get('id');

        this.product = await this.gfpServices.getProduct(productId).toPromise();
        this.form.patchValue(this.product);

        await this.getServiceById(this.product.service_id);
        if (this.product.booking_possible_for == 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }
        this.product.booking_code.id = 1; // set default id value just to fill the autocomplete value
        this.getCompanyAndSPBoobingCodes(this.product.service_company_id);
        this.form.controls.booking_code.setValue(this.product.booking_code);
        this.form.controls.invoice_handler_id.setValue({
            id: this.product.invoice_handler_id,
            name: this.product.invoice_handler_name
        });
        ////////////// Fetch Hotels /////////////////
        const hotelsObj = [];
        this.product.hotels.forEach((hotel: any) => hotelsObj.push(hotel.id));
        this.form.controls.hotels.setValue(hotelsObj);
        ////////////// Fetch Hotels /////////////////
        if (this.product.use_service_configurations == 1) {
            this.form.get('use_service_configurations').setValue(1);
            await this.serviceInfoButtonClick({checked: 1});
        } else {
            this.disabilitySwitch(false);
        }
        await this.serviceHandlerTypeChanged(this.product.service_handler_type_id);
        this.details = this.product.details;
        this.updateAvailableTeeTimes();
        this.detailForm.enable();
    }

    async getServiceById(id) {
        await this.productsServices.getService(id).toPromise().then((res: any) => {
            this.selectedService = res;
        });
    }

    getCompanyAndSPBoobingCodes(companyId: number) {
        this.productsServices.getCompanyAndSPBoobingCodes(companyId).subscribe((res: any) => {
            this.bookingCodes = res;
            if (this.editMode) {
                const selectedCode = this.bookingCodes.filter(x => x.booking_code === this.product.booking_code.booking_code);
                this.form.get('booking_code').setValue(selectedCode[0]);
                this.bookingCodeChanged();
            }
        });
    }

    getCompany() {
        this.companiesService.getCompany(this.route.snapshot.queryParamMap.get('company_id')).subscribe(response => {
            this.company = response;
        });
    }

    async getGolfCourses(companyId) {
        await this.companiesService.getAllCompanyChildren(companyId).toPromise().then((res: any) => {
            this.golfCourses = res;
            this.golfCourses.forEach(type => {
                type.disabled = false;
            });
            this.initAvailableGolfCourses();
        });
    }

    getTeeTimes() {
        this.gfpServices.getTeeTimes().subscribe((res: any) => {
            this.teeTimes = res.tee_times;
            this.teeTimes.shift();
        });
    }

    async searchCompanies(event) {
        this.companiesService.searchCompanies(event.query).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    searchServices(event) {
        this.productsServices.searchService(event.query).subscribe((res: any) => {
            this.services = res;
        });
    }

    /*searchBookingCodes(event) {
        this.companiesService.searchBookingCodes(event.query).subscribe((res: any) => {
            this.bookingCodes = res;
            if (this.company) {
                const code = this.bookingCodes.filter(x => x.type === 'company')[0];
                if (code) {
                    this.form.get('booking_code').setValue(code);
                    this.form.get('booking_code').disable();
                    this.bookingCodeChanged(code);
                }
            }
        });
    }*/

    async serviceHandlerTypeChanged(value) {
        this.form.get('service_handler_id').setValue(null);
        if (value === 3 || value == null) {
            this.form.get('use_destination_hotel').setValue(0);
            // golf club type selected
            this.form.get('service_handler_id').disable();

            this.serviceHandlers = [];
            this.form.get('service_handler_id').removeValidators(Validators.required);
        } else if (value === 6) {
            this.form.get('use_destination_hotel').setValue(0);
            this.companiesService.getAllCompaniesByType(value).toPromise().then((res: any) => {
                this.serviceHandlers = res.companies;
                if (this.form.get('use_service_configurations').value === 0) {
                    this.form.get('service_handler_id').enable();
                    this.form.get('service_handler_id').setValidators(Validators.required);
                }
            });
        } else if (value === 4) {
            this.companiesService.getAllCompaniesByType(value).toPromise().then((res: any) => {
                this.serviceHandlers = res.companies;
                if (this.form.get('use_destination_hotel').value === 0 &&
                    this.form.get('use_service_configurations').value === 0) {
                    this.form.get('service_handler_id').enable();
                    this.form.get('service_handler_id').setValidators(Validators.required);
                }
            });
        }
    }

    async bookingPossibleChanged(value) {
        if (value == 'Hotel') {
            if (this.selectedService.cities.length > 0) {
                let citiesIds = '';
                this.selectedService.cities.forEach(city => citiesIds += 'city_id_list[]=' + city.id + '&');
                this.hotelServices.getAllHotelsByCitiesList(citiesIds).subscribe((res: any) => {
                    this.bookings = res.hotels;
                    if (this.form.get('use_service_configurations').value == 0) {
                        this.form.get('hotels').enable();
                        this.form.get('hotels').setValidators(Validators.required);
                    }
                });
            } else {
                this.hotelServices.getAllHotelsByCity(this.selectedService.country_id).subscribe((res: any) => {
                    if (this.form.get('use_service_configurations').value == 0) {
                        this.form.get('hotels').enable();
                        this.form.get('hotels').setValidators(Validators.required);
                    }
                });
            }
        } else {
            if (this.form.get('use_destination_hotel').value === 1) {
                this.form.get('use_destination_hotel').setValue(0);
                this.switchServiceHandler(true);
            }
            this.bookings = [];
            this.form.get('hotels').setValue(null);
            this.form.get('hotels').disable();
            this.form.get('hotels').removeValidators(Validators.required);
        }
    }

    async serviceInfoButtonClick(event, fill = true) {
        if (event.checked === 1) {
            this.disabilitySwitch(true);
            if (this.form.get('service_id').value !== null && fill) {
                this.fillServiceInfo();
            }
        } else {
            this.disabilitySwitch(false);
        }
    }


    async getCompanyTypes() {
        this.companiesService.getCompanyTypes().subscribe((res: any) => {
            this.serviceHandlersTypesIds.forEach(type => {
                this.serviceHandlerTypes.push(res.filter(x => x.id === type)[0]);
            });
        });
    }

    serviceChanged(event) {
        this.selectedService = this.services.filter(x => x.id == event.value)[0];
        if (this.selectedService == null && this.form.get('use_service_configurations').value == 1) {
            this.clearAlternatives();
        } else if (this.selectedService !== null) {
            if (this.form.get('use_service_configurations').value == 1) {
                this.fillServiceInfo();
            }
        }
    }

    bookingCodeChanged() {
        if (this.form.get('booking_code').value) {
            this.productsServices.getServicesByBookingCode(this.form.get('booking_code')?.value).subscribe(res => {
                this.services = res;
            });
        } else {
            this.form.get('service_id').setValue(null);
        }
    }

    getBookingCodeName(code) {
        return code.booking_code + ' - ' + code.name;
    }

    clearAlternatives() {
        this.form.get('invoice_handler_id').setValue(null);
        this.form.get('service_handler_type_id').setValue(null);
        this.form.get('service_handler_id').setValue(null);
        this.form.get('booking_possible_for').setValue(null);
        this.form.get('hotels').setValue(null);
    }

    submit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.findInvalidControls();
            return;
        }
        const clone = this.form.getRawValue();
        clone.invoice_handler_id = clone.invoice_handler_id;
        clone.booking_code = clone.booking_code.booking_code;
        /*clone.validity_from = this.sharedService.dateToIsoString(clone.validity_from).slice(0, 10);
        clone.validity_to = this.sharedService.dateToIsoString(clone.validity_to).slice(0, 10);*/
        clone.details = this.details;
        if (!this.checkTeeTimesCount(clone)) {
            return;
        }
        if (!clone.hotels) {
            clone.hotels = [];
        }

        ///////////////////  TAGS /////////////////////
        if (clone.tags != null) {
            clone.tags = clone.tags.map(tag => tag.id);
        } else {
            clone.tags = [];
        }
        ///////////////////  TAGS /////////////////////
        if (!this.editMode) {
            this.gfpServices.addProduct(clone).subscribe(() => {
                this.messageService.add({
                    severity: 'success', summary: 'Create GPF',
                    detail: 'GFP has been Added successfully'
                });
                this.router.navigateByUrl(this.previousRoute.includes('gfp') ? this.previousRoute :
                    (this.user.company_id === 1 ? '/product-setup/gfp' : '/admin/companies/edit/' +
                        this.user.company_id + '/gfps'));
            });
        } else {
            this.gfpServices.updateProduct(clone).subscribe(() => {
                this.messageService.add({
                    severity: 'success', summary: 'Update Product Service',
                    detail: 'Product service has been updated successfully'
                });
            });
        }
    }

    checkTeeTimesCount(pack) {
        let teeTimesCount = 0;
        pack.details.forEach(item => {
            if (!item.tee_time_id || item.tee_time_id == 0) {
                teeTimesCount += (+item.max_tee_time_name);
            } else {
                teeTimesCount += (+item.tee_time_name);
            }
        });
        if (teeTimesCount < (pack.tee_time_id - 1)) {
            this.showTeeTimeCountError = true;
            this.missingTeeTimesCount = pack.tee_time_id - 1 - teeTimesCount;
            return false;
        }
        return true;
    }

    searchGolfClubs(keyword) {
        this.companiesService.searchCompaniesByType(keyword, 3).subscribe((res: any) => {
            this.golfClubs = res.companies;
        });
    }


    removeDetail(i) {
        this.details.splice(i, 1);
        this.detailForm.reset();
        this.updateAvailableTeeTimes();
        this.initAvailableGolfCourses();
    }

    typeChanged() {
        if (this.detailForm.get('type').value == 'Fixed') {
            this.detailForm.get('tee_time').enable();
            this.detailForm.get('tee_time').setValidators(Validators.required);
            this.detailForm.get('min_tee_time').removeValidators(Validators.required);
            this.detailForm.get('min_tee_time').setValue(null);
            this.detailForm.get('max_tee_time').removeValidators(Validators.required);
            this.detailForm.get('max_tee_time').setValue(null);
            this.detailForm.get('min_tee_time').disable();
            this.detailForm.get('max_tee_time').disable();
            this.updateAvailableTeeTimes();
        } else if (this.detailForm.get('type').value == 'Min-Max') {
            this.detailForm.get('tee_time').removeValidators(Validators.required);
            this.detailForm.get('tee_time').setValue(null);
            this.detailForm.get('tee_time').disable();
            this.detailForm.get('min_tee_time').enable();
            this.detailForm.get('min_tee_time').setValidators(Validators.required);
            this.detailForm.get('max_tee_time').disable();
            this.updateAvailableTeeTimes();
            this.availableTimes.unshift({id: 1, name: '0'});
            this.availableTimes.pop();
        }
    }

    updateAvailableTeeTimes() {
        let currentAmount = 0;
        this.details.forEach(detail => {
            if (detail.tee_time_id && detail.tee_time_id !== 0) {
                currentAmount += +detail.tee_time_name;
            } else if (detail.min_tee_time_id && detail.min_tee_time_id !== 0) {
                currentAmount += +detail.min_tee_time_name;
            }
        });
        this.calculateAvailableTeeTime(+this.form.get('tee_time_id').value - 1 - currentAmount);
    }

    ///////////////////////////////////////


    addDetail() {
        if (!this.detailForm.valid) {
            this.detailForm.markAllAsTouched();
            return false;
        }
        this.detailForm.value.company_id = this.detailForm.value.company.id;
        this.detailForm.value.company_name = this.detailForm.value.company.name;

        this.detailForm.value.golf_course_id = this.detailForm.value.golf_course.id;
        this.detailForm.value.golf_course_name = this.detailForm.value.golf_course.name;

        if (this.detailForm.value.tee_time != null) {
            this.detailForm.value.tee_time_id = this.detailForm.value.tee_time?.id;
            this.detailForm.value.tee_time_name = this.detailForm.value.tee_time?.name;
        }

        if (this.detailForm.value.min_tee_time != null) {
            this.detailForm.value.min_tee_time_id = this.detailForm.value.min_tee_time?.id;
            this.detailForm.value.min_tee_time_name = this.detailForm.value.min_tee_time?.name;
        }
        if (this.detailForm.value.max_tee_time != null) {
            this.detailForm.value.max_tee_time_id = this.detailForm.value.max_tee_time?.id;
            this.detailForm.value.max_tee_time_name = this.detailForm.value.max_tee_time?.name;
        }
        if (this.detailForEdit == null) {
            this.details.push(this.detailForm.value);
        } else {
            this.details.push(this.detailForm.value);
            this.detailForEdit = null;
        }
        this.initAvailableGolfCourses();
        this.detailForm.reset();
        this.showTeeTimeCountError = false;
    }


    teeTimeChanges() {
        this.details = [];
        if (this.form.get('tee_time_id').value == null) {
            this.detailForm.reset();
            this.detailForm.disable();
        } else {
            this.detailForm.enable();
        }
    }

    calculateAvailableTeeTime(num: number) {
        this.availableTimes = [];
        for (let i = 0; i < num; i++) {
            this.availableTimes.push(this.teeTimes[i]);
        }
    }

    minChanged(value) {
        if (value == null) {
            this.typeChanged();
            this.detailForm.get('max_tee_time').disable();
            this.detailForm.get('max_tee_time').removeValidators(Validators.required);
        } else {
            this.detailForm.get('max_tee_time').enable();
            this.detailForm.get('max_tee_time').setValidators(Validators.required);
            this.calcMaxValues(value.id, this.availableTimes.length);
        }
    }

    calcMaxValues(num, currentMax) {
        this.availableMaxValues = this.availableTimes;
        for (let i = 0; i < num; i++) {
            this.availableMaxValues.shift();
        }
        this.availableMaxValues.push({
            id: currentMax + 1,
            name: currentMax.toString()
        });
    }

    async editDetail(i) {
        this.detailForEdit = this.details[i];
        await this.getGolfCourses(this.detailForEdit.company_id);
        const obj = {
            company: {
                id: this.detailForEdit.company_id,
                name: this.detailForEdit.company_name,
            },
            golf_course: this.golfCourses.filter(course => course.id == this.detailForEdit.golf_course_id)[0],
            type: this.detailForEdit.type,
            tee_time: {
                id: this.detailForEdit.tee_time_id,
                name: this.detailForEdit.tee_time_name,
            },
            min_tee_time: {
                id: this.detailForEdit.min_tee_time_id,
                name: this.detailForEdit.min_tee_time_name,
            },
            max_tee_time: {
                id: this.detailForEdit.max_tee_time_id,
                name: this.detailForEdit.max_tee_time_name,
            }
        };
        this.detailForm.patchValue(obj);
        this.details.splice(i, 1);
        this.typeChanged();
        this.minChanged(this.detailForm.value.min_tee_time);
    }

    initAvailableGolfCourses() {
        const selectedCourses = [];
        this.details.forEach(detail => {
            selectedCourses.push(detail.golf_course_id);
        });
        this.golfCourses.map(course => {
            course.disabled = false;
        });
        this.golfCourses.forEach(course => {
            selectedCourses.forEach(item => {
                if (course.id == item) {
                    course.disabled = true;
                }
            });
        });
    }

    async fillServiceInfo() {
        await this.serviceHandlerTypeChanged(this.selectedService.service_handler_type_id);
        if (this.selectedService.booking_possible_for == 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }
        /*this.form.get('invoice_handler_id').setValue({
            id: this.selectedService.invoice_handler_id,
            name: this.selectedService.invoice_handler_name
        });*/
        this.form.get('service_handler_type_id').setValue(this.selectedService.service_handler_type_id);

        this.form.get('service_handler_id').setValue(this.selectedService.service_handler_id);

        this.form.get('use_destination_hotel').setValue(this.selectedService.use_destination_hotel);
        this.form.get('booking_possible_for').setValue(this.selectedService.booking_possible_for);
        const hotelsIds = [];
        this.selectedService.hotels.forEach(hotel => {
            hotelsIds.push(hotel.id);
        });
        this.form.get('hotels').setValue(hotelsIds);
    }

    disabilitySwitch(disable: boolean) {
        if (disable) {
            this.form.get('invoice_handler_id').disable();
            this.form.get('service_handler_type_id').disable();
            this.form.get('hotels').disable();
            this.form.get('service_handler_id').disable();
            this.form.get('booking_possible_for').disable();
            this.form.get('use_destination_hotel').disable();
        } else {
            this.form.get('invoice_handler_id').enable();
            this.form.get('service_handler_type_id').enable();
            this.form.get('booking_possible_for').enable();
            this.form.get('booking_possible_for').value == null ? this.form.get('hotels').disable() :
                this.form.get('hotels').enable();
            this.form.get('use_destination_hotel').enable();
            if (this.form.get('service_handler_type_id').value === 3 ||
                (this.form.get('service_handler_type_id').value === 4 && this.form.get('use_destination_hotel').value === 1)) {
                this.form.get('service_handler_id').disable();
            } else if (this.form.get('service_handler_type_id').value === 6 ||
                (this.form.get('service_handler_type_id').value === 4 && this.form.get('use_destination_hotel').value === 0)) {
                this.form.get('service_handler_id').enable();
                this.form.get('service_handler_id').setValidators(Validators.required);
            }
        }
        this.form.updateValueAndValidity();
    }

    public findInvalidControls() {
        this.invalidControls = [];
        const controls = this.form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalidControls.push(name.replace(/_/g, ' '));
            }
        }
    }

    useDestHotelsChanges(event) {
        if (event === 1) {
            this.form.get('service_handler_id').setValue(null);
            this.switchServiceHandler(false);
        } else {
            this.switchServiceHandler(true);
        }
    }

    switchServiceHandler(value) {
        if (value) {
            this.form.get('service_handler_id').enable();
            this.form.get('service_handler_id').setValidators(Validators.required);
            this.form.get('service_handler_id').updateValueAndValidity();
        } else {
            this.form.get('service_handler_id').disable();
            this.form.get('service_handler_id').removeValidators(Validators.required);
            this.form.get('service_handler_id').updateValueAndValidity();
        }
    }

    filterTags(event) {
        this.tagsService.getAllTags(event.query).subscribe(res => {
            this.filteredTags = res;
            const matchedTags = this.filteredTags.filter(x => x.name == event.query);
            if (matchedTags.length === 0) {
                this.filteredTags.unshift({id: 0, name: event.query + ' (Add new)'});
            }
        });
    }

    tagSelected(event) {
        if (event.id === 0) {
            const tagValues = [...this.form.get('tags').value];
            const newTag = tagValues[tagValues.length - 1];
            const selections = this.form.get('tags').value.slice(0, tagValues.length - 1);
            this.tagsService.addTag({name: newTag.name.split('(')[0].trim()}).subscribe(res => {
                selections.push(res);
                this.form.get('tags').setValue(selections);
            });
        }
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    createCompany() {
        window.open(window.location.origin + '/admin/companies/add', '_blank');
    }
}
