import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AccountService} from './_services/account.service';
import {User} from './_models/user';
import {UsersServices} from './_services/users-services';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
                    <div class="layout-topbar-logo-wrapper">
                        <a href="#" class="layout-topbar-logo">
                            <img src="assets/layout/images/pages/golf-logo-min.png" alt="mirage-layout"/>
                            <span class="app-name">GOLF GLOBE</span>
                        </a>
                        <form *ngIf="user.role_id === 2" [formGroup]="form">
                            <p-autoComplete class="autocomplete" [field]="'full_name'" formControlName="user"
                                            [suggestions]="users" [placeholder]="'Users'" (onSelect)="userSelected($event)"
                                            (completeMethod)="searchUsers($event)" [forceSelection]="true" delay="1000">
                                <ng-template let-user pTemplate="item">
                                    <div>{{user.first_name + ' ' + user.last_name}}</div>
                                </ng-template>
                            </p-autoComplete>
                        </form>
                    </div>

                    <a href="#" class="sidebar-menu-button" (click)="appMain.onMenuButtonClick($event)">
                        <i class="pi pi-bars"></i>
                    </a>

                    <!--<a href="#" class="megamenu-mobile-button" (click)="appMain.onMegaMenuMobileButtonClick($event)">
                        <i class="pi pi-align-right megamenu-icon"></i>
                    </a>-->

                    <a href="#" class="topbar-menu-mobile-button" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
                        <i class="pi pi-ellipsis-v"></i>
                    </a>

                    <div class="layout-megamenu-wrapper">
                        <!--<a class="layout-megamenu-button" href="#" (click)="appMain.onMegaMenuButtonClick($event)">
                            <i class="pi pi-comment"></i>
                            Mega Menu
                        </a>-->
                        <ul class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': appMain.megaMenuActive}"
                            (click)="appMain.onMegaMenuClick($event)">
                            <li [ngClass]="{'active-topmenuitem': activeItem === 1}" (click)="mobileMegaMenuItemClick(1)">
                                <a href="#">JavaServer Faces <i class="pi pi-angle-down"></i></a>
                                <ul>
                                    <li class="active-row ">
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>PrimeFaces</h5>
                                        <span>UI Components for JSF</span>
                                    </span>
                                    </li>
                                    <li>
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>Premium Templates</h5>
                                        <span>UI Components for JSF</span>
                                    </span>
                                    </li>
                                    <li>
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>Extensions</h5>
                                        <span>UI Components for JSF</span>
                                    </span>
                                    </li>
                                </ul>
                            </li>
                            <li [ngClass]="{'active-topmenuitem': activeItem === 2}" (click)="mobileMegaMenuItemClick(2)">
                                <a href="#">Angular <i class="pi pi-angle-down"></i></a>
                                <ul>
                                    <li>
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>PrimeNG</h5>
                                        <span>UI Components for Angular</span>
                                    </span>

                                    </li>
                                    <li>
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>Premium Templates</h5>
                                        <span>UI Components for Angular</span>
                                    </span>
                                    </li>
                                </ul>
                            </li>
                            <li [ngClass]="{'active-topmenuitem': activeItem === 3}" (click)="mobileMegaMenuItemClick(3)">
                                <a href="#">React <i class="pi pi-angle-down"></i></a>
                                <ul>
                                    <li>
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>PrimeReact</h5>
                                        <span>UI Components for React</span>
                                    </span>
                                    </li>
                                    <li class="active-row">
                                        <i class="pi pi-circle-on"></i>
                                        <span>
                                        <h5>Premium Templates</h5>
                                        <span>UI Components for React</span>
                                    </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="layout-topbar-right fadeInDown">
                    <ul class="layout-topbar-actions">
                        <!--<li #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === search}">
                            <a href="#" class="topbar-search-mobile-button" (click)="appMain.onTopbarItemClick($event,search)">
                                <i class="topbar-icon pi pi-search"></i>
                            </a>
                            <ul class="search-item-submenu fadeInDown" (click)="appMain.topbarItemClick = true">
                                <li>
                                    <span class="md-inputfield search-input-wrapper">
                                        <input pInputText placeholder="Search..."/>
                                        <i class="pi pi-search"></i>
                                    </span>
                                </li>
                            </ul>
                        </li>-->
                        <!--						<li #calendar class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === calendar}">-->
                        <!--							<a href="#" (click)="appMain.onTopbarItemClick($event,calendar)">-->
                        <!--								<i class="topbar-icon pi pi-calendar"></i>-->
                        <!--							</a>-->
                        <!--							<ul class="fadeInDown" (click)="appMain.topbarItemClick = true">-->
                        <!--								<li class="layout-submenu-header">-->
                        <!--									<h1>Calendar</h1>-->
                        <!--								</li>-->
                        <!--								<li class="calendar">-->
                        <!--                                    <p-calendar [inline]="true"></p-calendar>-->
                        <!--								</li>-->
                        <!--							</ul>-->
                        <!--						</li>-->

                        <!--<li><a style="cursor: pointer"
                               (click)="switchLanguage()">{{accountService.currUser.lang === 'de' ? 'EN' : 'DE'}}</a></li>-->
                        <p-selectButton [options]="languages" [ngModel]="accountService.currUser.lang"
                                        optionLabel="name" optionValue="id" (onChange)="switchLanguage()"
                        styleClass="language-checkbox"></p-selectButton>
                        <li #message class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === message}">
                            <a href="#" (click)="appMain.onTopbarItemClick($event,message)">
                                <i class="pi pi-bell p-mr-4 p-text-secondary" pBadge style="font-size: 2rem"
                                   [value]="accountService.notificationsCount > 0 ? accountService.notificationsCount : null"></i>
                            </a>
                            <ul class="fadeInDown notifications-list" (mouseleave)="accountService.changeNotificationsToSeen()">
                                <!--<li class="layout-submenu-header">
                                    <span class="p-mr-2"><i class="pi pi-circle-on"></i></span>
                                    <h1>Notifications</h1>
                                    <span>You have new 1 notification</span>
                                </li>-->
                                <li *ngFor="let notification of accountService.notifications" [routerLink]="'/requests/request-view'"
                                    class="layout-submenu-item notifications-item">
                                    <div class="menu-text">
                                        <p class="notifications-title" [ngClass]="{'bold-font': notification.seen === 0}">
                                            {{notification.title}}</p>
                                        <span class="notifications-body" [innerHTML]="notification.shortText"
                                              [pTooltip]="notification.body" tooltipPosition="left"></span>
                                        <div class="mt-2">
                                            <span class="notifications-user">
                                                <i class="pi pi-user notifications-icon"></i> {{notification.user}}</span>
                                            <span class="notifications-time">
                                                <i class="pi pi-calendar notifications-icon"></i>{{notification.created_at | date: 'short'}}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <!--						<li #gift class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === gift}">-->
                        <!--							<a href="#" (click)="appMain.onTopbarItemClick($event,gift)">-->
                        <!--								<i class="topbar-icon pi pi-envelope"></i>-->
                        <!--							</a>-->
                        <!--							<ul class="fadeInDown">-->
                        <!--								<li class="layout-submenu-header">-->
                        <!--									<h1>Deals</h1>-->
                        <!--								</li>-->

                        <!--								<li class="deals">-->
                        <!--									<ul>-->
                        <!--										<li>-->
                        <!--											<img src="assets/layout/images/topbar/deal-icon-sapphire.png" alt="mirage-layout" width="35" />-->
                        <!--											<div class="menu-text">-->
                        <!--												<p>Sapphire</p>-->
                        <!--												<span>Angular</span>-->
                        <!--											</div>-->
                        <!--											<i class="pi pi-angle-right"></i>-->
                        <!--										</li>-->
                        <!--										<li>-->
                        <!--											<img src="assets/layout/images/topbar/deal-icon-roma.png" alt="mirage-layout" width="35" />-->
                        <!--											<div class="menu-text">-->
                        <!--												<p>Roma</p>-->
                        <!--												<span>Minimalism</span>-->
                        <!--											</div>-->
                        <!--											<i class="pi pi-angle-right"></i>-->
                        <!--										</li>-->
                        <!--										<li>-->
                        <!--											<img src="assets/layout/images/topbar/deal-icon-babylon.png" alt="mirage-layout" width="35" />-->
                        <!--											<div class="menu-text">-->
                        <!--												<p>Babylon</p>-->
                        <!--												<span>Powerful</span>-->
                        <!--											</div>-->
                        <!--											<i class="pi pi-angle-right"></i>-->
                        <!--										</li>-->
                        <!--									</ul>-->
                        <!--									<ul>-->
                        <!--										<li>-->
                        <!--											<img src="assets/layout/images/topbar/deal-icon-harmony.png" alt="mirage-layout" width="35" />-->
                        <!--											<div class="menu-text">-->
                        <!--												<p>Harmony</p>-->
                        <!--												<span>USWDS</span>-->
                        <!--											</div>-->
                        <!--											<i class="pi pi-angle-right"></i>-->
                        <!--										</li>-->
                        <!--										<li>-->
                        <!--											<img src="assets/layout/images/topbar/deal-icon-prestige.png" alt="mirage-layout" width="35" />-->
                        <!--											<div class="menu-text">-->
                        <!--												<p>Prestige</p>-->
                        <!--												<span>Elegancy</span>-->
                        <!--											</div>-->
                        <!--											<i class="pi pi-angle-right"></i>-->
                        <!--										</li>-->
                        <!--										<li>-->
                        <!--											<img src="assets/layout/images/topbar/deal-icon-ultima.png" alt="mirage-layout" width="35" />-->
                        <!--											<div class="menu-text">-->
                        <!--												<p>Ultima</p>-->
                        <!--												<span>Material</span>-->
                        <!--											</div>-->
                        <!--											<i class="pi pi-angle-right"></i>-->
                        <!--										</li>-->
                        <!--									</ul>-->
                        <!--								</li>-->
                        <!--							</ul>-->
                        <!--						</li>-->

                        <li #profile class="topbar-item profile-item"
                            [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}">
                            <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                            <span class="profile-image-wrapper">
                                <img src="{{user?.profile_image}}" alt="profile-picture"/>
                            </span>
                                <span class="profile-info-wrapper">
                                <h3>{{user?.first_name + ' ' + user?.last_name}}</h3>
                                <span>{{user?.title}}</span>
                            </span>
                            </a>
                            <ul class="profile-item-submenu fadeInDown">
                                <li class="profile-submenu-header">
                                    <div class="performance">
                                        <!--<span>Weekly Performance</span>
                                        <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />-->
                                    </div>
                                    <div class="profile">
                                        <img [src]="user?.profile_image || 'assets/layout/images/pages/user.png'" alt="mirage-layout"
                                             width="40"/>
                                        <h1>{{user?.first_name + ' ' + user?.last_name}}</h1>
                                        <span>{{user?.title}}</span>
                                    </div>
                                </li>
                                <!--<li class="layout-submenu-item">
                                    <i class="pi pi-list icon icon-1"></i>
                                    <div class="menu-text">
                                        <p>Tasks</p>
                                        <span>3 open issues</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-item">
                                    <i class="pi pi-shopping-cart icon icon-2"></i>
                                    <div class="menu-text">
                                        <p>Payments</p>
                                        <span>24 new</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>-->
                                <li class="layout-submenu-item" routerLink="/user-profile">
                                    <i class="pi pi-users icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>{{'userProfile' | translate}}</p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-item" routerLink="/company/company-profile/{{user.company_id}}">
                                    <i class="pi-briefcase icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>{{'companyProfile' | translate}}</p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-item" (click)="changePassword()">
                                    <i class="pi pi-lock icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>{{'changePassword' | translate}}</p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <!--<li class="layout-submenu-item">
                                    <p class="lang-title">
                                        <i class="pi pi-language lang-icon"></i>{{'language' | translate}}</p>
                                    <p-selectButton [options]="languages" [(ngModel)]="selectedLang"
                                                    optionLabel="name" (onChange)="switchLanguage()"
                                    styleClass="contract-checkbox"></p-selectButton>
                                </li>-->
                                <li class="layout-submenu-footer">
                                    <button class="signout-button" (click)="signOut()">{{'signOut' | translate}}</button>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#" class="layout-rightpanel-button" (click)="appMain.onRightPanelButtonClick($event)">
                                <i class="pi pi-arrow-left"></i>
                            </a>
                        </li>
                    </ul>

                    <ul class="profile-mobile-wrapper">
                        <li #mobileProfile class="topbar-item profile-item"
                            [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === mobileProfile}">
                            <a href="#" (click)="appMain.onTopbarItemClick($event,mobileProfile)">
                            <span class="profile-image-wrapper">
                                <img [src]="user?.profile_image" alt="mirage-layout"/>
                            </span>
                                <span class="profile-info-wrapper">
                                <h3>{{user?.first_name + ' ' + user?.last_name}}</h3>
                                <span>{{user?.title}}</span>
                            </span>
                            </a>
                            <ul class="fadeInDown">
                                <li class="profile-submenu-header">
                                    <div class="performance">
                                        <!--<span>Weekly Performance</span>
                                        <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />-->
                                    </div>
                                    <div class="profile">
                                        <img [src]="user?.profile_image" alt="mirage-layout" width="45"/>
                                        <h1>{{user?.first_name + ' ' + user?.last_name}}</h1>
                                        <span>{{user?.title}}</span>
                                    </div>
                                </li>
                                <!--<li>
                                    <i class="pi pi-list icon icon-1"></i>
                                    <div class="menu-text">
                                        <p>Tasks</p>
                                        <span>3 open issues</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li>
                                    <i class="pi pi-shopping-cart icon icon-2"></i>
                                    <div class="menu-text">
                                        <p>Payments</p>
                                        <span>24 new</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li>
                                    <i class="pi pi-users icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>Clients</p>
                                        <span>+80%</span>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>-->
                                <li class="layout-submenu-item" routerLink="/user-profile">
                                    <i class="pi pi-users icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>User profile</p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-item" routerLink="/company/company-profile/{{user.company_id}}">
                                    <i class="pi-briefcase icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>Company profile</p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-item" (click)="changePassword()">
                                    <i class="pi pi-lock icon icon-3"></i>
                                    <div class="menu-text">
                                        <p>Change password</p>
                                    </div>
                                    <i class="pi pi-angle-right"></i>
                                </li>
                                <li class="layout-submenu-footer">
                                    <button class="signout-button">Sign Out</button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <p-dialog [(visible)]="showPasswordComponent" [showHeader]="false" [modal]="true">
            <app-change-password (cancelRegister)="showPasswordComponent = false"></app-change-password>
        </p-dialog>
    `
})
export class AppTopBarComponent implements OnInit {

    activeItem: number;
    user: User;
    showPasswordComponent = false;
    users: User[];
    form: UntypedFormGroup;
    languages = [{id: 'en', name: 'EN'}, {id: 'de', name: 'DE'}];

    constructor(public appMain: AppMainComponent, public accountService: AccountService, private usersService: UsersServices,
                private fb: UntypedFormBuilder) {
    }
    switchLanguage() {
        this.accountService.switchLanguage(this.accountService.currUser.lang === 'en' ? 'de' : 'en');
    }

    mobileMegaMenuItemClick(index) {
        this.appMain.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

    ngOnInit() {
        this.accountService.currentUser$.subscribe(user => {
            this.user = user;
        });
        this.accountService.getLatestNotifications();
        this.initForm();
        this.form.get('user').setValue({id: this.user.id, full_name: this.user.first_name + ' ' + this.user.last_name});
    }

    initForm() {
        this.form = this.fb.group({
            user: null
        });
    }

    signOut() {
        this.accountService.logout();
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    changePassword() {
        this.showPasswordComponent = true;
    }

    searchUsers(event) {
        this.usersService.searchUsers(event.query).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    userSelected(event) {
        // this.accountService.logout(false, event.id);
        if (localStorage.getItem('user')) {
            localStorage.removeItem('user');
        } else {
            sessionStorage.removeItem('user');
        }
        this.accountService.directLogin(event.id).subscribe(res => {
            console.log(res);
            window.location.reload();
        });
    }
}
