import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Document} from '../_models/document';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    baseUrl = environment.apiUrl;
    documents: Document[] = [];

    constructor(private http: HttpClient) {
    }

    getAllDocuments(companyId) {
        return this.http.get(this.baseUrl + 'companies/documents/all?company_id=' + companyId);
    }

    getDocumentsById(id) {
        /*const document = this.documents.find(x => x.id == id);
        if (document !== undefined) return of(document);*/
        return this.http.get<Document>(this.baseUrl + 'companies/documents/' + id);
    }

    addDocument(obj) {
        return this.http.post(this.baseUrl + 'companies/documents/create', obj);
    }

    updateDocument(obj, id) {
        return this.http.post(this.baseUrl + 'companies/documents/update/' + id, obj);
    }

    deleteDocument(id) {
        return this.http.delete(this.baseUrl + 'companies/documents/' + id);
    }

    getDocumentTypes() {
        return this.http.get(this.baseUrl + 'companies/document/types');
    }
}
