import { Component, OnInit } from '@angular/core';
import {GolfHolidayService} from '../../_services/golf-holiday.service';
import {ProductAPIService} from '../../_services/product-api.service';

@Component({
  selector: 'app-golf-holiday-tree',
  templateUrl: './golf-holiday-tree.component.html',
  styleUrls: ['./golf-holiday-tree.component.css']
})
export class GolfHolidayTreeComponent implements OnInit {
  services: any[];
  selectedService: any;
  products: any[];
  selectedProduct: any;

  constructor(private golfHolidayService: GolfHolidayService, private prodAPIService: ProductAPIService) {
  }

  ngOnInit() {
  }

  citySelected(event) {
    this.prodAPIService.getServicesByCity(event.id).subscribe((res: any) => {
      this.services = res;
      this.services.map((service, index) => {
        service.label = service.name;
        service.level = 3;
        service.indexes = [...event.indexes, index];
        service.key = 'service-' + service.id;
      });
    });
  }

  serviceSelected(event) {
    this.golfHolidayService.getByService(event.id).subscribe((res: any) => {
      this.products = res;
      this.products.map(product => {
        product.label = product.name;
        product.level = 4;
        product.indexes = [];
        product.key = 'product-' + product.id;
      });
    });
  }

  productSelected(event) {
    this.selectedProduct = null;
    this.selectedProduct = event;
  }

}
