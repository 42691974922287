import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {City} from '../../_models/destinations';
import {DestinationsService} from '../_services/destinations.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Paginator} from 'primeng/paginator';

@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit, AfterViewInit {
    cities: City[];
    selectedCities: City[];
    first = 0;
    pagination: any;
    searchValue = '';
    publishStatuses = ['None', 'Published', 'Pending Publish', 'Unpublished'];
    publishFilterValue = 'None';
    topStatuses = ['None', 'Yes', 'No'];
    topFilterValue = 'None';
    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('p', {static: false}) paginator: Paginator;

    // Filter vars
    tableHelper = new GgTableCustomFN();
    filters = {
        [this.translateService.currentLang === 'de' ? 'region_name_de' : 'region_name']: [],
        [this.translateService.currentLang === 'de' ? 'country_name_de' : 'country_name']: [],
        publish: []
    };
    booleanFilter = [{name: this.translate.transform('active'), val: 1},
        {name: this.translate.transform('inactive'), val: 0}];
    TopFilter = [{name: this.translate.transform('yes'), val: 1},
        {name: this.translate.transform('no'), val: 0}];

    constructor(
        private destService: DestinationsService,
        private msgService: MessageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private accountService: AccountService,
        private confirmationService: ConfirmationService, private translate: TranslatePipe,
        public translateService: TranslateService
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('regions')},
        ]);
    }

    ngOnInit(): void {
        // check if there is a countryId
        const countryId = +this.activatedRoute.snapshot.paramMap.get('id');
        if (countryId > 0) {
            this.getAllCities(1, 10, countryId);
        } else {
            this.getAllCities(1, 10);
        }

    }

    ngAfterViewInit() {
        // server-side search
        fromEvent(this.searchInput.nativeElement,'keyup')
            .pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                tap((text) => {
                    this.filterCities();
                })
            )
            .subscribe();
    }

    getAllCities(pageNumber, pagination, countryId?: number): void {
        let topNValue: any = 'None';
        if (this.topFilterValue === 'Yes') {
            topNValue = 1;
        } else if (this.topFilterValue === 'No') {
            topNValue = 0;
        }
        this.destService.getPaginatedCities(pageNumber, pagination, this.searchValue,
            this.publishFilterValue, topNValue).subscribe((res: any) => {
            this.cities = res.data;
            this.pagination = res.meta;
            this.tableHelper.populateFilter(this.filters, this.cities);
        });
    }


    addNew(): void {
        this.router.navigateByUrl('destinations/regions/add');
    }

    topChanged(city: City): void {
        this.destService.updateCity(city).subscribe(res => {
            if (res) {
                this.msgService.add({
                    severity: 'success',
                    summary: this.translate.transform('regions'),
                    detail: this.translate.transform('regions')
                });
            }
        });
    }

    edit(city: City): void {
        this.router.navigateByUrl(`destinations/regions/edit/${city.id}`);
    }

    delete(city: City): void {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.destService.deleteCity(city.id).subscribe(res => {
                    if (res) {
                        this.getAllCities(this.pagination.current_page, +this.pagination.per_page);
                        this.msgService.add({
                            severity: 'success',
                            summary: this.translate.transform('deleteRegion'),
                            detail: this.translate.transform('deleteRegionMessage')
                        });
                    }
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedCities || this.selectedCities.length < 1) {
            this.msgService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedCities);
    }
    pageChanged(event: any) {
        event.page = event.first / event.rows + 1;
        this.getAllCities(event.page, +event.rows);
    }

    filterCities() {
        this.paginator?.changePage(0);
        this.getAllCities(1, this.pagination.per_page);
    }
}
