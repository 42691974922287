<p-toast></p-toast>
<div class="p-grid">
    <div class="p-col">
        <button *ngIf="this.country?.is_publish_required && this.country.show_website == 1"
                class="p-button-danger float-right" pButton type="button"
                (click)="publishToWebsite()" pRipple>{{'publish' | translate}}
        </button>
    </div>
</div><br><br>
<form [formGroup]="countryForm" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5 class="p-mb-4">{{isEditMode ? ('edit' | translate) : ('add' | translate)}} {{'country' | translate}}</h5>
            </div>
            <div>
                <button pButton type="submit" class="float-right ml-3">{{isEditMode ? ('saveChanges' | translate) :
                    ('create' | translate)}}</button>
                <app-switch-input
                        [label]="countryForm.controls['status'].value ? ('active' | translate) : ('inactive' | translate)"
                        [formControl]="countryForm.controls['status']" [inline]="true"
                        class="float-right">
                </app-switch-input>
                <app-switch-input
                        [label]="'onWebsite' | translate" class="float-right mr-3"
                        [formControl]="countryForm.controls['show_website']" [inline]="true"
                        (changed)="onWebsiteChanged($event)">
                </app-switch-input>
                <app-switch-input
                        [label]="'topCountry' | translate" class="float-right mr-3"
                        [formControl]="countryForm.controls['top']" [inline]="true">
                </app-switch-input>
            </div>
        </div>
        <div *ngIf="isEditMode" class="p-grid layout-dashboard">
            <div class="p-col-12 p-xl-4 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/hotel-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>{{'hotels' | translate}}</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{country?.hotels_number}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-4 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-4">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/golf-ball-tee-solid.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>{{'golfCourses' | translate}}</h5>
                        <!--<span>24 new since last visit</span>--></div>
                    <div class="p-col-3 overview-box-status">{{country?.golf_courses_number}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-4 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/product-hunt-brands.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>{{'regions' | translate}}</h5></div>
                    <div class="p-col-3 overview-box-status">{{this.cities?.length || '0'}}</div>
                </div>
            </div>
            <!--<div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-2">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/product-hunt-brands.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Golf Holiday Products</h5></div>
                    <div class="p-col-3 overview-box-status">{{country?.golf_holidays_number}}</div>
                </div>
            </div>
            <div class="p-col-12 p-xl-3 p-md-6">
                <div class="p-grid p-nogutter widget-overview-box overview-box-1">
                    <div class="p-col-3 overview-box-icon">
                        <img src="assets/dashboard/product-hotel.svg" alt="mirage-layout"/>
                    </div>
                    <div class="p-col-6 overview-box-text"><h5>Hotel Products</h5></div>
                    <div class="p-col-3 overview-box-status">{{country?.hotel_products_number}}</div>
                </div>
            </div>-->
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <app-text-input
                        [formControl]="countryForm.controls['name_en']"
                        [label]="'Name EN'"
                        [type]="'text'">
                </app-text-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-text-input
                        [formControl]="countryForm.controls['name_de']"
                        [label]="'Name DE'"
                        [type]="'text'">
                </app-text-input>
            </div>

            <div class="p-col-12 p-md-3">
                <app-text-input
                        [formControl]="countryForm.controls['code']"
                        [label]="'Code'"
                        [type]="'text'">
                </app-text-input>
            </div>
            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label>{{'continent' | translate}}</label>
                    <p-dropdown [options]="regions" [formControl]="countryForm.get('region_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" filterBy="name_en,name_de,code"
                                [placeholder]="'selectContinent' | translate" [filter]="true">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getRegionName(countryForm.get('region_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item?.name_en}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="countryForm.get('region_id').touched && countryForm.get('region_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <!--<div class="p-col-12 p-md-4">
                <app-select-input [options]="currencies" [formControl]="countryForm.controls['currency']"
                                  [label]="'Currency'" [optionLabel]="'name'" [optionValue]="'id'"></app-select-input>
            </div>

            <div class="p-col-12 p-md-4">
                <app-text-input
                        [formControl]="countryForm.controls['phone_code']"
                        [label]="'Phone Code'"
                        [type]="'text'">
                </app-text-input>
            </div>-->
        </div>

        <div class="p-grid" *ngIf="isEditMode">
            <div class="p-col-12 half-rem-padding">
                <app-multi-select [options]="cities" [formControl]="countryForm.get('featured_cities')"
                                  [label]="'featuredRegions' | translate" [placeholder]="'selectRegions' | translate"
                                  [optionValue]="'id'" [filter]="true"
                                  [optionLabel]="translateService.currentLang === 'de' ? 'name_de' : 'name_en'">
                </app-multi-select>
            </div>
            <div class="p-col-12 half-rem-padding">
                <app-multi-select [options]="golfCourses" [formControl]="countryForm.get('featured_golf_courses')"
                                  [label]="'featuredGolfCourses' | translate"
                                  [placeholder]="'selectGolfCourses' | translate" [filter]="true"
                                  [optionValue]="'id'" [optionLabel]="'name'"></app-multi-select>
            </div>
            <div class="p-col-12 half-rem-padding">
                <app-multi-select [options]="hotels" [formControl]="countryForm.get('featured_hotels')"
                                  [label]="'featuredHotels' | translate" [placeholder]="'selectHotels' | translate"
                                  [optionValue]="'id'" [filter]="true"
                                  [optionLabel]="'name'"></app-multi-select>
            </div>
            <!--<div class="p-col-12 p-md-4 half-rem-padding">
                <app-multi-select [options]="golfProducts" [formControl]="countryForm.get('featured_products')"
                                  [label]="'Featured Products'" [placeholder]="'Select Products'"
                                  [optionValue]="'id'" [optionLabel]="'name'"></app-multi-select>
            </div>
            <div class="p-col-12 p-md-4 half-rem-padding">
                <app-multi-select [options]="hotelProducts"
                                  [formControl]="countryForm.get('featured_hotel_products')"
                                  [label]="'Featured Hotel Products'" [placeholder]="'Select Hotel Product'"
                                  [optionValue]="'id'" [optionLabel]="'name'"></app-multi-select>
            </div>
            <div class="p-col-12 p-md-4 half-rem-padding">
                <app-multi-select [options]="golfHolidays" [formControl]="countryForm.get('featured_golf_holidays')"
                                  [label]="'Featured Golf Holidays'" [placeholder]="'Select Golf Holidays'"
                                  [optionValue]="'id'" [optionLabel]="'name'"></app-multi-select>
            </div>-->
            <div class="p-col-12 half-rem-padding">
                <app-multi-select [options]="countries" [formControl]="countryForm.get('related_countries')"
                                  [label]="'relatedCountries' | translate" [placeholder]="'selectCountries' | translate"
                                  [optionValue]="'id'"
                                  [optionLabel]="translateService.currentLang === 'de' ? 'name_de' : 'name_en'"
                                  [filter]="true">
                </app-multi-select>
            </div>
        </div>
        <div class="card toolbar-demo mt-3">
            <h5>{{'fields' | translate}}</h5>
            <ng-container *ngIf="fields.controls.length > 0">
                <div *ngFor="let field of fields.controls; let i = index; let last = last">
                    <div class="p-3" [ngClass]="i % 2 == 0 ? 'gray-bg' : null">
                        <h6>{{getFieldName(field.get('type_id').value)}}</h6>
                        <div *ngIf="field.get('is_html')?.value == 1" class="p-col-12 p-md-12 quill">
                            <div style="display: flow-root">
                                <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                                optionValue="id" optionLabel="name"
                                                [formControl]="field.get('is_html')"></p-selectButton>
                                <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                                [formControl]="field.get('language_mode')"
                                                styleClass="language-switch"></p-selectButton>
                            </div>
                            <quill-editor [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                          [placeholder]="'Content'"></quill-editor>
                        </div>
                        <div *ngIf="field.get('is_html').value == 0" class="p-col-12 p-md-12">
                            <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                            optionValue="id" optionLabel="name"
                                            [formControl]="field.get('is_html')"></p-selectButton>
                            <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                            [formControl]="field.get('language_mode')"
                                            styleClass="language-switch"></p-selectButton>
                            <textarea class="full-width" pInputText
                                      [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                      [placeholder]="'Content'"
                                      rows="4" autocomplete="false"></textarea>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="card toolbar-demo mt-3">
            <h5>FAQs</h5>
            <ng-container *ngIf="faqs.controls.length > 0">
                <div *ngFor="let faq of faqs.controls; let i = index; let last = last">
                    <div class="p-grid mt-2 mb-2" style="border: 1px solid #dddada;"
                         [ngClass]="i % 2 == 0 ? 'gray-bg' : null">
                        <div class="p-col-12 p-md-8 rem-padding">
                            <h6>FAQ {{i + 1}}</h6>
                        </div>
                        <div class="p-col-12 p-md-4 half-rem-padding">
                            <i class="pi pi-trash removeNote" (click)="removeFaq(i)"></i>
                        </div>
                        <div class="p-col-12">
                            <app-text-input [label]="'question' | translate"
                                            [formControl]="faq.get('question')"></app-text-input>
                        </div>
                        <div class="p-col-12">
                            <app-text-area [label]="'answer' | translate" [rows]="3"
                                           [formControl]="faq.get('answer')"></app-text-area>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="buttons">
                <button pButton class="float-right" type="button" (click)="addFaq()">{{'addFAQ' | translate}}</button>
            </div>
        </div>
        <div *ngIf="isEditMode" class="card toolbar-demo mt-3">
            <h5>{{'photos' | translate}}</h5>
            <div class="p-grid">
                <div class="p-col-12">
                    <app-images-list [limit]="20" [count]="country?.images?.length"
                                     #uploader (delete)="deleteImage($event)" [images]="country?.images"
                                     [showMainIcon]="true"
                                     (mainImageChanged)="changeMainImage($event)"
                                     (onSelectImages)="uploadImages()"></app-images-list>
                </div>

            </div>
        </div>
        <app-testimonies *ngIf="isEditMode" [testimonies]="country?.testimonies"
                         (testimonyAdded)="addTestimony($event)" #testimony></app-testimonies>
    </div>

    <!--<div class="buttons">
        <button pButton type="submit" class="float-right">{{isEditMode ? ('saveChanges' | translate) :
            ('create' | translate)}}</button>
    </div>-->
</form>
<p-dialog header="Attention" [(visible)]="showUnPublishAlert" [style]="{width: '50vw'}"
          closable="false">
    <p>All regions under this country will be unpublished because we cannot have regions on the website without a
        published parent country.</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showUnPublishAlert=false" label="Ok"
                  styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
