import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {NavigationExtras, Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {AccountService} from '../_services/account.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, private messageService: MessageService, private loader: NgxUiLoaderService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (error) {
                    switch (error.status) {
                        case 400:
                            if (error.error.errors) {     // Validation Errors
                                const modalStateErrors = [];
                                for (const key in error.error.errors) {
                                    if (error.error.errors[key]) {
                                        modalStateErrors.push(error.error.errors[key]);
                                    }
                                }
                                throw modalStateErrors.reduce((acc, val) => acc.concat(val), []);
                            } else {
                                this.showErrorViaToast(error.statusText, error.status);
                            }
                            break;

                        case 401:
                            /*this.messageService.add({severity: 'error', summary: error.statusText,
                              detail: 'Wrong username or password!' });*/
                            this.loader.stopAll();
                            this.router.navigateByUrl('/login');
                            break;

                        case 404:
                            // this.router.navigateByUrl('/not-found');
                            break;

                        case 500:
                            const navigationExtras: NavigationExtras = {state: {error: error.error.error}};
                            this.router.navigateByUrl('/server-error', navigationExtras);
                            break;

                        default:
                            this.showErrorViaToast('Something unexpected went wrong');
                            console.log(error);
                            break;
                    }
                }
                return throwError(error);
            })
        );
    }

    showErrorViaToast(summary, detail?) {
        this.messageService.add({key: 'error', severity: 'error', summary: summary, detail: detail});
    }
}
