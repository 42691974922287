import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminRoutingModule} from './admin-routing.module';
import {CompaniesComponent} from './companies/companies.component';
import {PermissionsComponent} from './permissions/permissions.component';
import {RolesComponent} from './roles/roles.component';
import {UsersComponent} from './users/users.component';
import {SharedModule} from '../_shared/shared.module';
import {AddPermissionComponent} from './permissions/add-permission/add-permission.component';
import {AddRoleComponent} from './roles/add-role/add-role.component';
import {AddUserComponent} from './users/add-user/add-user.component';
import {AddCompanyComponent} from './companies/add-company/add-company.component';
import {UserFormComponent} from './users/user-form/user-form.component';
import { IntegrationComponent } from './integration/integration.component';
import {UserProfileComponent} from './users/user-profile/user-profile.component';
import { EditCompanyComponent } from './companies/edit-company/edit-company.component';
import { CompanyDataComponent } from './companies/edit-company/company-data/company-data.component';
import { CompanyUsersComponent } from './companies/edit-company/company-users/company-users.component';
import { CompanyDocumentsComponent } from './companies/edit-company/company-documents/company-documents.component';
import { CompanyAddressBookComponent } from './companies/edit-company/company-address-book/company-address-book.component';
import { CompanySeasonsComponent } from './companies/edit-company/company-seasons/company-seasons.component';
import { CompanyContractsComponent } from './companies/edit-company/company-contracts/company-contracts.component';
import {ProductSetupModule} from '../product-setup/product-setup.module';
import { CompanyGfpComponent } from './companies/edit-company/company-gfp/company-gfp.component';
import {TranslateModule} from '@ngx-translate/core';
import { ReportsComponent } from './reports/reports.component';


@NgModule({
    declarations: [
        CompaniesComponent,
        PermissionsComponent,
        RolesComponent,
        UsersComponent,
        AddPermissionComponent,
        AddRoleComponent,
        AddUserComponent,
        AddCompanyComponent,
        IntegrationComponent,
        UserProfileComponent,
        UserFormComponent,
        EditCompanyComponent,
        CompanyDataComponent,
        CompanyUsersComponent,
        CompanyDocumentsComponent,
        CompanyAddressBookComponent,
        CompanySeasonsComponent,
        CompanyContractsComponent,
        CompanyGfpComponent,
        ReportsComponent
    ],
    imports: [
        CommonModule,
        AdminRoutingModule,
        SharedModule,
        ProductSetupModule,
        TranslateModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AdminModule {
}
