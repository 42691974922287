import {Component, EventEmitter, Input, OnInit, Output, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {SharedService} from '../../_services/shared.service';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Output() blurEvent = new EventEmitter();
  @Input() showTrash = false;
  @Output() delete = new EventEmitter();

  constructor(@Self() public ngControl: NgControl, public sharedService: SharedService) {
    this.ngControl.valueAccessor = this;
  }

  checkRequired(control) {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
  }

  writeValue(obj: any) {
  }

  registerOnChange(fn: any) {
  }

  registerOnTouched(fn: any) {
  }

  onBlur(event) {
    this.blurEvent.emit(event.target.value);
  }
  remove() {
    this.delete.emit();
  }

}
