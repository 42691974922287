import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Area} from '../../_models/destinations';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {DestinationsService} from '../_services/destinations.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AccountService} from '../../_services/account.service';
import {tap} from 'rxjs/operators';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {

    areas$: Observable<Area[]>;
    selectedAreas: Area[];

    // Filter vars
    tableHelper = new GgTableCustomFN();
    filters = {
        [this.translateService.currentLang === 'de' ? 'region_name_de' : 'region_name']: [],
        [this.translateService.currentLang === 'de' ? 'country_name_de' : 'country_name']: [],
        [this.translateService.currentLang === 'de' ? 'city_name_de' : 'city_name']: [],
    };
    booleanFilter = [{name: this.translate.transform('active'), val: 1},
        {name: this.translate.transform('inactive'), val: 0}];

    constructor(
        private destService: DestinationsService,
        private msgService: MessageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private accountService: AccountService,
        private confirmationService: ConfirmationService, private translate: TranslatePipe,
        public translateService: TranslateService
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('areas')},
        ]);
    }

    ngOnInit(): void {
        // check if there is a countryId
        const areaId = +this.activatedRoute.snapshot.paramMap.get('id');
        if (areaId > 0) {
            this.getAllAreas(areaId);
        } else {
            this.getAllAreas();
        }

    }

    getAllAreas(areaId?: number): void {
        this.areas$ = this.destService.getAreasListTable(areaId).pipe(tap(areas => {
            this.tableHelper.populateFilter(this.filters, areas);
        }));
    }


    addNew(): void {
        this.router.navigateByUrl('destinations/areas/add');
    }

    changeAreaStatus(area: Area): void {
        const cloneArea = {...area};
        cloneArea.status = area.status ? 1 : 0;
        this.destService.updateArea(cloneArea).subscribe(res => {
            if (res) {
                this.msgService.add({
                    severity: 'success',
                    summary: this.translate.transform('areas'),
                    detail: this.translate.transform('updateAreaMessage')
                });
            }
        });
    }

    edit(area: Area): void {
        this.router.navigateByUrl(`destinations/areas/edit/${area.id}`);
    }

    delete(area: Area): void {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.destService.deleteArea(area.id).subscribe(res => {
                    if (res) {
                        this.getAllAreas();
                        this.msgService.add({
                            severity: 'success',
                            summary: this.translate.transform('areas'),
                            detail: this.translate.transform('deleteAreaMessage')
                        });
                    }
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedAreas || this.selectedAreas.length < 1) {
            this.msgService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedAreas);
    }

}
