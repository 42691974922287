import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Table} from 'primeng/table';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  @Input() fieldName: string;
  @Input() isBoolean: boolean;
  @Input() isColor: boolean;
  @Input() filterOptions: string[] = [];
  filterValues: string[] = [];

  constructor(public translate: TranslatePipe) { }

  ngOnInit(): void {
  }

}
