import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PackagesComponent} from './packages/packages.component';
import {PackageFormComponent} from './package-form/package-form.component';
import {PackageCmsRoutingModule} from './package-cms-routing.module';
import {SharedModule} from '../_shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        PackagesComponent,
        PackageFormComponent
    ],
    imports: [
        CommonModule,
        PackageCmsRoutingModule,
        SharedModule,
        TranslateModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PackageCmsModule {
}
