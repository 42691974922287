import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../breadcrumb.service';
import {DestinationsService} from './_services/destinations.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.css']
})
export class DestinationComponent implements OnInit {
  selectedNode: any;

  constructor(
      private breadcrumbService: BreadcrumbService, public translate: TranslatePipe) {
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: this.translate.transform('destinations')}
    ]);
  }

  itemSelected(event) {
    this.selectedNode = event;
  }
}
