import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BreadcrumbService} from '../../breadcrumb.service';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { Role } from 'src/app/_models/roles-permissions';
import { RolesPermissionService } from 'src/app/_services/roles-permission.service';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

    showDialog: boolean;
    role: any;
    selectedRoles: any[];
    roles$: Observable<Role[]>;
    menuActions = ['View', 'Edit', 'Delete'];

    // Filter vars
    tableHelper = new GgTableCustomFN();
    booleanFilter = [{name: this.translate.transform('active'), val: 1},
        {name: this.translate.transform('inactive'), val: 0}];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        private router: Router, private activatedRoute: ActivatedRoute,
        private rolesPermissionsAPI: RolesPermissionService,
        private accountService: AccountService,
        public translate: TranslatePipe
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Roles'}
        ]);
    }

    ngOnInit() {
        this.getAllRoles();
    }

    addNew() {
        this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    getAllRoles(): void {
        try {
            this.roles$ = this.rolesPermissionsAPI.getRoles();
        } catch (error) {
            console.error('Get Roles Error', error);
            this.messageService.add({
                severity: 'error', summary: 'Listing Roles',
                detail: 'Something unexpected went wrong, please refresh the page!'
            });
        }
    }

    async changeRoleStatus(role: Role): Promise<void> {
        try {
            // const role: Role = await this.rolesPermissionsAPI.getRoleDetails(roleId);
            // role.status = role.status ? 1 : 0;
            console.log(role);
            // update role api
            this.rolesPermissionsAPI.updateRole(role).then(res => {
                if (res) {
                    this.messageService.add({
                        severity: 'success', summary: 'Role Updated',
                        detail: 'Role has been updated successfully!'
                    });
                }
            }).catch(error => {
                role.status = role.status > 0 ? 0 : 1;
                console.error('Update Role Error', error);
                this.messageService.add({
                    severity: 'error', summary: 'Update Role',
                    detail: 'Something unexpected went wrong, please refresh the page!'
                });
            });
        } catch (error) {
            console.error('Update Role Error', error);
            this.messageService.add({
                severity: 'error', summary: 'Update Role',
                detail: 'Something unexpected went wrong, please refresh the page!'
            });
        }
    }

    view(role) {
    }

    edit(role: Role) {
        this.router.navigate(['edit', role.id], { relativeTo: this.activatedRoute });
    }

    async delete(role: Role) {
        try {
            const res = await this.rolesPermissionsAPI.deleteRole(role.id);
            this.messageService.add({
                severity: 'success', summary: 'Role Deleted',
                detail: 'Role has been deleted successfully'
            });
            this.roles$ = this.rolesPermissionsAPI.getRoles();
        } catch (error) {
            console.error('Delete Role Error', error);
            this.messageService.add({
                severity: 'error', summary: 'Role Delete',
                detail: 'Something unexpected went wrong'
            });
        }
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedRoles || this.selectedRoles.length < 1) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedRoles);
    }
}
