<router-outlet></router-outlet>
<!--<ngx-ui-loader [fgsColor]="'#7CFAC3'" [pbColor]="'#007db0'" [fgsType]="'chasing-dots'"
               [logoUrl]="'../assets/layout/images/pages/golf-logo-min.png'" [bgsOpacity]="'0.2'"
               [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>-->
<ngx-ui-loader [bgsOpacity]="'0.2'"
               [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>

<ngx-ui-loader [loaderId]="'destinationsLoader'" [fgsTemplate]="foregroundSpinner"
               [text]="'Just a minute!, we are caching necessary data for better performance!'"
               [textColor]="'#FFFFFF'"
               [bgsOpacity]="'0.2'"></ngx-ui-loader>
<p-toast [baseZIndex]="10000"></p-toast>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [baseZIndex]="10000"></p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="notiConfirm" position="top-right" [baseZIndex]="10000"
                 rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-toast key="notification"></p-toast>

<ng-template #foregroundSpinner>
    <div class="spinner">
        <span class="ball-1"></span>
        <span class="ball-2"></span>
        <span class="ball-3"></span>
        <span class="ball-4"></span>
        <span class="ball-5"></span>
        <span class="ball-6"></span>
        <span class="ball-7"></span>
        <span class="ball-8"></span>
    </div>
</ng-template>
