import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {TourOperator} from '../../_models/tour-operator';

@Injectable({
  providedIn: 'root'
})
export class TourOperatorService {

  baseUrl = environment.apiUrl;
  operators: TourOperator[] = [];

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get(this.baseUrl + 'touroperators').pipe(map((res: any) => {
      this.operators = res.touroperators;
      return this.operators;
    }));
  }

  tourSearch(keyword) {
    return this.http.get(this.baseUrl + 'touroperators?search=' + keyword + '&active=1');
  }

  getById(id) {
    /*const hotel = this.hotels.find(x => x.id == id);
    if (hotel !== undefined) return of(hotel);*/
    return this.http.get<TourOperator>(this.baseUrl + 'touroperators/' + id);
  }

  add(obj) {
    return this.http.post(this.baseUrl + 'touroperators', obj);
  }

  update(obj) {
    return this.http.put(this.baseUrl + 'touroperators/' + obj.id, obj);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + 'touroperators/' + id);
  }
}
