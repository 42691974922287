<div class="card widget-team">
    <div class="p-grid p-jc-between mt-2 mb-3">
        <div class="p-d-flex p-ai-center">
            <h5>{{'testimonies' | translate}}</h5>
            <button pButton pRipple label="" icon="pi pi-plus" type="button"
                    class="p-button-rounded p-button-primary p-ml-2"
                    (click)="openAddTestimony()"></button>
        </div>
    </div>
    <ul>
        <li *ngFor="let item of testimonies; let i = index">
            <div class="p-grid member">
                <div class="p-col-7 team-box">
                    <div class="profile-image">
                        <img [src]="item.image || '../../../assets/layout/images/pages/user.png'"/>
                    </div>
                    <div>
                        <h6 class="team-member">{{item.name}}</h6>
                        <span class="team-member-role">{{item.text}}</span>
                    </div>
                </div>
                <div class="p-col-5 icons">
                    <div class="icon icon-twitter">
                        <i class="pi pi-pencil" (click)="editTestimony(i)"></i>
                    </div>
                    <div class="icon icon-hastag">
                        <i class="pi pi-trash" (click)="deleteTestimony(i)"></i>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
<div *ngIf="displayForm">
    <p-dialog [header]="(currentTestimony ? ('edit' | translate) : ('add' | translate)) +
    ' ' + ('testimony' | translate)" [(visible)]="displayForm"
              [position]="'center'" [modal]="true"
              [style]="{width: '50vw'}"
              [draggable]="false" [resizable]="false" [maximizable]="true">
        <form [formGroup]="testimoniesForm" (ngSubmit)="submitTestimony()">
            <div class="p-grid p-4">
                <div *ngIf="currentTestimony?.image" class="p-col-12 text-center">
                    <img src="{{imageToShow ? imageToShow :
                            (currentTestimony?.image? currentTestimony?.image :
                            '../../../assets/layout/images/pages/user.png')}}" width="100px"
                         height="100px" alt="user image" class="testimony-img"><br>
                    <button pButton type="button" label="{{'delete' | translate}}" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteImage()"></button>
                </div>
                <div *ngIf="!currentTestimony?.image" class="p-col-12">
                    <p-fileUpload name="myfile[]" customUpload="true"
                                  mode="advanced"
                                  [chooseLabel]="'selectImage' | translate" accept="image/*"
                                  #uploader maxFileSize="1000000" (onSelect)="imageSelected($event)"
                                  withCredentials="true" styleClass="uploader-class"
                                  [showUploadButton]="false" [showCancelButton]="false">
                    </p-fileUpload>
                    <p *ngIf="showEmptyMsg && uploader.files.length === 0"
                       class="p-error">{{'noImagesAdded' | translate}}</p>
                </div>

                <div class="p-col-12">
                    <app-text-input [label]="'Name'"
                                    [formControl]="testimoniesForm.get('name')"></app-text-input>
                </div>
                <div class="p-col-12">
                    <app-text-area [label]="'description' | translate" rows="3"
                                   [formControl]="testimoniesForm.get('text')"></app-text-area>
                </div>
            </div>
            <div class="buttons">
                <button pButton type="submit" class="float-right">
                    {{currentTestimony ? ('saveChanges' | translate) : ('create' | translate)}}</button>
            </div>
        </form>
    </p-dialog>
</div>
