<p-toast></p-toast>

<div class="card">
    <p-table #dt dataKey="id"
         [value]="countries$ | async"
         [(selection)]="selectedCountries"
         [rowHover]="true"
         [rows]="100"
         [paginator]="true"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
         [showCurrentPageReport]="true"  [showJumpToPageInput]="true" [rowsPerPageOptions]="[10, 50, 100, 200]"
         [globalFilterFields]="['name_en', 'name_de', 'code', 'region_name', 'currency']"
         [scrollable]="true" [scrollDirection]="'both'"
             (sortFunction)="sort($event)" [customSort]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">{{'countries' | translate}}</h5>


                    <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-primary p-ml-2" (click)="addNew()" *ngIf="checkPermission('1636370748894')"></button>
                </div>

                <button pButton pRipple label="{{'export' | translate}}" (click)="exportExcel()"
                        icon="pi pi-upload" class="p-button-help float-right"
                        *ngIf="checkPermission('1636370833653')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width"
                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'search' | translate}}"/>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width:80px; max-width: 80px;" class="p-d-flex p-jc-between p-ai-center">
                    Top
                    <app-filter fieldName="top" [filterOptions]="TopFilter" [isBoolean]="true"></app-filter>
                </th>
                <th style="width: 80px;"><!--{{'publish' | translate}}-->Web
                    <app-filter fieldName="publish" [filterOptions]="filters.publish"></app-filter>
                </th>
                <th style="width: 120px" pSortableColumn="name_en">Name EN <p-sortIcon field="name_en"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="name_de">Name DE <p-sortIcon field="name_de"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="code">Code <p-sortIcon field="code"></p-sortIcon></th>
                <!--<th style="width: 120px" pSortableColumn="currency">Currency</th>-->
                <th style="width: 120px" pSortableColumn="region_name"
                    class="p-d-flex p-jc-between p-ai-center">
                    {{'continent' | translate}}
                    <p-sortIcon field="region_name"></p-sortIcon>
                    <app-filter [fieldName]="translateService.currentLang === 'de' ? 'region_name_de' : 'region_name'"
                                [filterOptions]="filters[translateService.currentLang === 'de' ? 'region_name_de' : 'region_name']"></app-filter>
                </th>
                <th style="width: 120px" pSortableColumn="status" class="p-d-flex p-jc-between p-ai-center">
                    Status
                    <app-filter fieldName="status" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>
                </th>
                <th style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370765293') || checkPermission('1636370786752') || checkPermission('1636370802985')"
                >{{'actions' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-country>
            <tr>
                <td style="min-width:80px; max-width: 80px;">
                    <p-inputSwitch class="switch-input" (onChange)="topChanged(country)"
                                   [trueValue]="1" [falseValue]="0"
                                   name="top" [(ngModel)]="country.top"></p-inputSwitch>
                </td>
                <td style="width: 80px;">
                    <i class="{{country.publish == 'Published' ? 'pi pi-check-circle' :
          (country.publish == 'Pending Publish' ? 'pi pi-hourglass' : 'pi pi-times-circle')}}"
                       [pTooltip]="country.publish" tooltipPosition="top"></i>
                </td>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(country)">
                    {{country.name_en}}
                    <!--<a [routerLink]="'/destinations/countries/edit/' + country.id">{{country.name}}</a>-->
                </td>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(country)">
                    {{country.name_de}}
                    <!--<a [routerLink]="'/destinations/countries/edit/' + country.id">{{country.name}}</a>-->
                </td>
                <td style="width: 120px">
                    {{country.code}}
                </td>
                <!--<td style="width: 120px">
                    {{country.currency}}
                </td>-->
                <td style="width: 120px">
                    <a [routerLink]="'/destinations/continents/edit/' + country.region.id">
                        {{translateService.currentLang === 'de' ? country.region?.name_de : country.region?.name_en}}</a>
                </td>
                <td style="width: 120px" class="status-td">
                    <!--<p-inputSwitch [(ngModel)]="country.status" (onChange)="changeCountryStatus(country)"></p-inputSwitch>-->
                    <span class="status-txt">{{country.status === 1 || country.status? ('active' | translate) :
                        ('inactive' | translate)}}</span>
                </td>
                <td style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370765293') || checkPermission('1636370786752') || checkPermission('1636370802985')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngIf="checkPermission('1636370786752')">
                                <a class="dropdown-item" (click)="edit(country)">{{'edit' | translate}}</a></li>
                            <!--<li role="menuitem" *ngIf="checkPermission('1636370802985')">
                                <a class="dropdown-item" (click)="delete(country)">{{'delete' | translate}}</a></li>-->
                        </ul>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

