import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Hotel} from '../../_models/hotel';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PackageCmsService {

    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getPaginatedPackages(pageNumber = 1, pagination, searchKeyword, sortField?, sortOrder?) {
        return this.http.get(this.baseUrl + 'packages?page=' + pageNumber
          + '&pagination=' + pagination
          + (searchKeyword ? '&search=' + searchKeyword : '')
          + (sortField === '' ? '' : ('&sortField=' + sortField + '&sortOrder=' + sortOrder))
        );
    }

    getFieldTypes() {
        return this.http.get(this.baseUrl + 'packages/field-types/all').pipe(map((res: any) => {
            return res.field_types;
        }));
    }

    getPackageById(id) {
        return this.http.get<any>(this.baseUrl + 'packages/' + id).pipe(map((res: any) => {
            return res.data;
        }));
    }
    createPackage(obj) {
        return this.http.post<any>(this.baseUrl + 'packages', obj);
    }
    updatePackage(obj, packId) {
        return this.http.put<any>(this.baseUrl + 'packages/' + packId, obj);
    }
    deletePackage(id) {
        return this.http.delete(this.baseUrl + 'packages/' + id);
    }
    uploadRoomImages(roomId, obj) {
        return this.http.post(this.baseUrl + 'packages/upload-images/' + roomId, obj);
    }
    deleteRoomImages(roomId, obj) {
        return this.http.post(this.baseUrl + 'packages/delete-image/' + roomId, obj);
    }
    changeMainImage(id, obj) {
        return this.http.post(this.baseUrl + 'packages/' + id + '/change-main-image', obj);
    }
}
