<form (ngSubmit)="submit(permissionForm)" #permissionForm="ngForm">
    <div class="p-grid p-jc-between">
        <div class="p-col">
            <div class="p-field">
                <label>Name</label>
                <input class="full-width" type="text" pInputText [(ngModel)]="permission.name" name="name" required>
            </div>
        </div>
        <div>
            <div class="p-field">
                <p>{{permission.status > 0 ? ('active' | translate) : ('inactive' | translate)}}</p>
                <p-inputSwitch [(ngModel)]="permission.status" name="status" required [trueValue]="1" [falseValue]="0"></p-inputSwitch>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-field">
                <label>Module</label>
                <p-dropdown 
                    name="module"
                    [options]="modulesList" 
                    [(ngModel)]="permission.module" 
                    optionLabel="name" 
                    [filter]="true" 
                    filterBy="name"
                    [showClear]="true" 
                    placeholder="Select a Module"
                    (onChange)="createpagesList(permission.module)"
                    required>
                </p-dropdown>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-field">
                <label>Page</label>
                <p-dropdown 
                    name="page"
                    [options]="pagesList" 
                    [(ngModel)]="permission.page" 
                    optionLabel="name" 
                    [filter]="true" 
                    filterBy="name"
                    [showClear]="true" 
                    placeholder="Select a Page"
                    [disabled]="!permission.module"
                    required>
                </p-dropdown>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-field">
                <div><label>Permission Description</label></div>
                <textarea class="full-width" rows="3" pInputTextarea autoResize="autoResize" name="description" [(ngModel)]="permission.description" required></textarea>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-field">
                <label>Permission Code</label>
                <p># {{permission.code ? permission.code : 'Generating code, please select Module and Page!'}}</p>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button pButton type="submit" [disabled]="!permissionForm.valid">
            {{isEditMode ? 'Update Permission' : 'Create Permission'}}
        </button>
    </div>
</form>
