import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GolfCourse} from '../../../../_models/golf-course';
import {Company} from '../../../../_models/company';
import {Hotel} from '../../../../_models/hotel';
import {Lookup} from '../../../../_models/lookup';
import {User} from '../../../../_models/user';
import {ImagesListComponent} from '../../../../_shared/images-list/images-list.component';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {GolfCoursesService} from '../../../_services/golf-courses.service';
import {CompaniesService} from '../../../../_services/companies-service';
import {ActivatedRoute} from '@angular/router';
import {UsersServices} from '../../../../_services/users-services';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HotelsService} from '../../../../hotels/_services/hotels.service';
import {TagsService} from '../../../../_services/tags.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../environments/environment';
import {AccountService} from '../../../../_services/account.service';

@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit, OnChanges {

    generalForm: UntypedFormGroup;
    golfCourse: GolfCourse = {links_hd_images: [], links_logo_images: []};
    golfClubs: Company[];
    hotels: Hotel[];
    styles: Lookup[];
    handlerTypes: Lookup[];
    regions: Lookup[];
    countries: Lookup[];
    cities: Lookup[];
    ratingOptions: Lookup[] = [];
    difficulties: Lookup[];
    dresses: Lookup[];
    terrains: Lookup[];
    holes: Lookup[] = [];
    users: User[];
    @ViewChild('uploader', {static: true}) imagesUploader: ImagesListComponent;
    invalidControls: any[];
    fieldTypes: any[];
    playables: any[];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    showCompanyInfoButton = true;
    @Input() golfCourseId: number;
    companies: Company[];
    filteredTags: any;
    selectedCompany: Company;
    timePattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
    showWebsiteCurrentValue: number;
    golfCourses: GolfCourse[];
    basics: any;
    currentUser: User;

    constructor(private breadcrumbService: BreadcrumbService, private coursesServices: GolfCoursesService,
                private fb: UntypedFormBuilder, private companiesServices: CompaniesService, private route: ActivatedRoute,
                private usersServices: UsersServices, private messageService: MessageService,
                private companiesService: CompaniesService, private confirmationService: ConfirmationService,
                private hotelService: HotelsService, private tagsService: TagsService,
                private loader: NgxUiLoaderService, private translate: TranslatePipe,
                public translateService: TranslateService, private accountService: AccountService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('golfCourses'), routerLink: '/golf-courses'},
            {label: this.translate.transform('cms')},
        ]);
    }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe(res => {
            this.currentUser = res;
        });
        this.initForm();
        this.initOptions();
        this.getBasics();
        this.getGolfClubs();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.generalForm) {
            this.initForm();
        }
        this.getGolfCourseById();
    }

    initForm() {
        this.generalForm = this.fb.group({
            id: [null],
            company_id: [null],
            company_name: [null],
            golf_course_style_id: [null],
            internal_description_mode: [1],
            designer: [null],
            length_men: [null],
            length_women: [null],
            par_men: [null],
            par_women: [null],
            holes: [null, Validators.required],
            course_rating: [null],
            club_rating: [null],
            academy: [null],
            pros: [null],
            payee: [null],
            is_payee_only: [null],
            payee_key_created: [null],
            bank: [null],
            bank_location: [null],
            account_number: [null],
            swift_code: [null],
            iban: [null],
            membership: [null],
            hcp_men: [null],
            hcp_women: [null],
            difficulties: [null],
            terrains: [null],
            dresses: [null],
            playables: [null],
            related_golf_courses: [null],
            fields: this.fb.array([]),
            slope_from: [null],
            slope_to: [null],
            links_hd_images: this.fb.array([null]),
            links_logo_images: this.fb.array([null]),
            translations: this.fb.array([
                this.fb.group({
                    language_id: 1,
                    internal_description: null,
                    website_description: null
                }),
                this.fb.group({
                    language_id: 2,
                    internal_description: null,
                    website_description: null
                })
            ]),
            tags: null,
            start_time: [null, [Validators.pattern(this.timePattern)]],
            end_time: null,
            location_link: [null],
            latitude: [null],
            longitude: [null],
            is_publish_required: null,
            show_website: [0],
            top: 0
        });
    }

    getBasics() {
        this.basics = this.route.snapshot.parent.data?.basics[0];
        this.dresses = this.basics.dressed;
        this.dresses = this.getTranslatedName(this.dresses);
        this.terrains = this.basics.terrains;
        this.terrains = this.getTranslatedName(this.terrains);
        this.difficulties = this.basics.difficulties;
        this.difficulties = this.getTranslatedName(this.difficulties);
        this.styles = this.basics.styles;
        this.styles = this.getTranslatedName(this.styles);
        this.fieldTypes = this.basics.field_types;
        this.playables = this.basics.playables;
        this.playables = this.getTranslatedName(this.playables);
        this.getGolfCourseById();
    }

    getTranslatedName(list: any) {
        list.forEach(item => {
            if (this.translateService.currentLang === 'de') {
                item.name = item.translations[1].name;
            } else {
                item.name = item.translations[0].name;
            }
        });
        return list;
    }

    get form() {
        return this.generalForm.controls;
    }

    get translations() {
        return this.form.translations as UntypedFormArray;
    }

    initOptions() {
        for (let i = 10; i >= 1; i--) {
            const option: Lookup = {id: i, name: i.toString(), code: null};
            this.ratingOptions.push(option);
        }
        const holes = [9, 18, 27, 36, 45];
        for (let i = 0; i < holes.length; i++) {
            const hole: Lookup = {id: (i + 1), name: holes[i], code: null};
            this.holes.push(hole);
        }
    }

    async getGolfCourseById() {
        if (!this.golfCourseId) {
            this.golfCourse = this.route.snapshot.parent.data.basics[1];
        } else {
            await this.coursesServices.getGolfCourseById(this.route.parent.snapshot.paramMap.get('id'))
                .toPromise().then((res: any) => {
                    this.golfCourse = res;
                });
        }
        this.coursesServices.course = this.golfCourse;
        this.golfCourse.start_time = this.golfCourse?.start_time?.slice(0, -3);
        this.golfCourse.end_time = this.golfCourse?.end_time?.slice(0, -3);

        this.showWebsiteCurrentValue = this.golfCourse.show_website;
        this.generalForm.patchValue(this.golfCourse);

        ////////////// Fetch Basics /////////////////

        const terrainsObj = [];
        this.golfCourse.terrains.forEach((terrain: any) => terrainsObj.push(terrain.id));
        this.generalForm.controls.terrains.setValue(terrainsObj);


        const difficultiesObj = [];
        this.golfCourse.difficulties.forEach((difficulty: any) => difficultiesObj.push(difficulty.id));
        this.generalForm.controls.difficulties.setValue(difficultiesObj);


        const dressesObj = [];
        this.golfCourse.dresses.forEach((code: any) => dressesObj.push(code.id));
        this.generalForm.controls.dresses.setValue(dressesObj);

        const playableObj = [];
        this.golfCourse.playables.forEach((pl: any) => playableObj.push(pl.id));
        this.generalForm.controls.playables.setValue(playableObj);

        ////////////// Fetch Basics /////////////////
        this.fillFieldsData(this.golfCourse.fields);
        ////////////// Fetch HD Images /////////////////
        if (this.golfCourse.links_hd_images?.length > 0) {
            const links = this.generalForm.controls.links_hd_images as UntypedFormArray;
            links.clear();
            this.golfCourse.links_hd_images.forEach((link: any) => {
                links.push(this.fb.control(link.link));
            });
        }
        ////////////// Fetch HD Images /////////////////
        ////////////// Fetch Logos /////////////////
        if (this.golfCourse.links_logo_images?.length > 0) {
            const logos = this.generalForm.controls.links_logo_images as UntypedFormArray;
            logos.clear();
            this.golfCourse.links_logo_images.forEach((logo: any) => {
                logos.push(this.fb.control(logo.link));
            });
        }
        ////////////// Fetch Logos /////////////////
    }

    fillFieldsData(fields: any[]) {
        this.fieldTypes?.forEach((fl, index) => {
            const field = fields.filter(x => +x.type_id === fl.id)[0];
            if (field) {
                this.fields.push(
                    this.fb.group({
                        id: field.id,
                        type_id: field.type_id,
                        is_html: field.is_html,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach((translation, n) => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: field.translations[n]?.description
                        })
                    );
                });
            } else {
                this.fields.push(
                    this.fb.group({
                        id: null,
                        type_id: fl.id,
                        is_html: 0,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach(translation => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: null
                        })
                    );
                });
            }
        });
    }

    getFieldName(fieldId) {
        const name = this.fieldTypes
            .filter(x => x.id === +fieldId)[0].translations[this.translateService.currentLang === 'de' ? 1 : 0].name;
        return name;
    }

    get fields() {
        return this.form.fields as UntypedFormArray;
    }

    getGolfClubs() {
        this.companiesServices.getGolfClubs().subscribe((res: any) => {
            this.golfClubs = res.companies.data;
        });
    }

    public findInvalidControls() {
        this.invalidControls = [];
        const controls = this.generalForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalidControls.push(name.replace(/_/g, ' '));
            }
        }
    }

    submitForm() {
        if (!this.generalForm.valid) {
            this.generalForm.markAllAsTouched();
            this.findInvalidControls();
            return false;
        }
        this.invalidControls = [];
        const formValue = {...this.generalForm.getRawValue()};
        ////////////// Fetch Tour Operators ///////////////
        const coursesList = [];
        if (formValue.related_golf_courses) {
            formValue.related_golf_courses.forEach(course => {
                coursesList.push(course.id);
            });
        }
        formValue.related_golf_courses = coursesList;
        ////////////// Fetch Tour Operators ///////////////
        ///////////////////  TAGS /////////////////////
        if (formValue.tags != null) {
            formValue.tags = formValue.tags.map(tag => tag.id);
        } else {
            formValue.tags = [];
        }
        ///////////////////  TAGS /////////////////////

        formValue.links_hd_images = formValue.links_hd_images.filter(x => x != null);
        formValue.links_logo_images = formValue.links_logo_images.filter(x => x != null);
        formValue.images = this.imagesUploader.form.value;

        this.coursesServices.updateGolfCourseCMS(this.golfCourse.id, formValue).subscribe((res: any) => {
            this.golfCourse = res.golfcourse;
            this.coursesServices.course = this.golfCourse;
            if (this.showWebsiteCurrentValue === 1 && this.golfCourse.show_website === 0) {
                this.publishToWebsite();
            } else {
                this.showWebsiteCurrentValue = this.golfCourse.show_website;
            }
            this.showWebsiteCurrentValue = this.golfCourse.show_website;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('updateGolfCourseMessage')
            });
        });
    }

    publishToWebsite() {
        const obj = {
            auth: 'GAP_API',
            token: environment.token,
            type: 'golfcourse',
            object_id: this.golfCourse.id
        };
        this.coursesServices.publishToWebsite(obj).subscribe((res: any) => {
            if (res.update_status === 'Updated Successfully') {
                this.coursesServices.publish(this.coursesServices.course.id).subscribe((resp: any) => {
                    this.coursesServices.course.is_publish_required = false;
                    this.messageService.add({
                        severity: 'success', summary: this.translate.transform('golfCourses'),
                        detail: this.translate.transform('publishMessage')
                    });
                });
            }
        });
    }

    uploadImages() {
        const files = this.imagesUploader.getFiles();
        const formData: FormData = new FormData();
        if (files.length === 0) {
            return false;
        }
        this.loader.start('uploadImages');
        files.forEach(file => {
            formData.append('images[]', file);
            formData.append('size[]', file.size.toString());
            formData.append('original_file_name[]', file.name);
        });
        this.coursesServices.uploadImages(this.golfCourse.id, formData).subscribe((res: any) => {
            this.golfCourse.images = res.golfcourse.images;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('updateGolfCourseMessage')
            });
            this.imagesUploader.clearUploader();
            this.loader.stop('uploadImages');
            this.checkMainImage();
        });
    }

    deleteImage(idsList) {
        this.coursesServices.deleteImage(this.golfCourse.id, idsList).subscribe((res: any) => {
            this.golfCourse.images = res.golfcourse.images;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('updateGolfCourseMessage')
            });
            this.checkMainImage();
        });
    }

    get hdLinks() {
        return this.form.links_hd_images as UntypedFormArray;
    }

    addHdLinks() {
        const hdLinks = this.generalForm.controls.links_hd_images as UntypedFormArray;
        hdLinks.push(this.fb.control(null));
        this.golfCourse?.links_hd_images?.push(hdLinks[hdLinks.length - 1]);
    }

    changePicLink(event, index) {
        this.golfCourse.links_hd_images[index] = {link: event};
    }

    removeHdLinks(i) {
        const hdLinks = this.generalForm.controls.links_hd_images as UntypedFormArray;
        hdLinks.removeAt(i);
        this.golfCourse?.links_hd_images.splice(i, 1);
    }

    get logoLinks() {
        return this.form.links_logo_images as UntypedFormArray;
    }

    addLogoLinks() {
        const logoLinks = this.generalForm.controls.links_logo_images as UntypedFormArray;
        logoLinks.push(this.fb.control(null));
        this.golfCourse?.links_logo_images.push(logoLinks[logoLinks.length]);
    }

    changeLogoLink(event, index) {
        this.golfCourse.links_logo_images[index] = {link: event};
    }

    removeLogoLink(i) {
        const logoLinks = this.generalForm.controls.links_logo_images as UntypedFormArray;
        logoLinks.removeAt(i);
        this.golfCourse?.links_logo_images.splice(i, 1);
    }

    changeMainImage(id) {
        this.coursesServices.changeMainImage(this.golfCourse.id, {image_id: id}).subscribe((res: any) => {
            /*this.golfCourse.images.forEach(image => {
               if (image.id === id) {
                   image.is_main = 1;
               } else if (image.id !== id && image.is_main === 1) {
                   image.is_main = 0;
               }
            });*/
            this.golfCourse.images = res.golfcourse.images;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('updateGolfCourseMessage')
            });
        });
    }

    checkMainImage() {
        let isMain = false;
        this.golfCourse.images.forEach(image => {
            if (image.is_main) {
                isMain = true;
            }
        });
        if (!isMain && this.golfCourse.images.length > 0) {
            this.changeMainImage(this.golfCourse.images[0]?.id);
        }
    }

    filterTags(event) {
        this.tagsService.getAllTags(event.query).subscribe(res => {
            this.filteredTags = res;
            const matchedTags = this.filteredTags.filter(x => x.name == event.query);
            if (matchedTags.length === 0) {
                this.filteredTags.unshift({id: 0, name: event.query + ' (Add new)'});
            }
        });
    }

    tagSelected(event) {
        if (event.id === 0) {
            const tagValues = [...this.generalForm.get('tags').value];
            const newTag = tagValues[tagValues.length - 1];
            const selections = this.generalForm.get('tags').value.slice(0, tagValues.length - 1);
            this.tagsService.addTag({name: newTag.name.split('(')[0].trim()}).subscribe(res => {
                selections.push(res);
                this.generalForm.get('tags').setValue(selections);
            });
        }
    }

    getLangLat(event) {
        const regex = new RegExp('@(.*),(.*),');
        const latLongMatch = event.match(regex);
        this.generalForm.controls.latitude.setValue(latLongMatch[1]);
        this.generalForm.controls.longitude.setValue(latLongMatch[2]);
    }

    searchGolfCourses(event) {
        this.coursesServices.searchGolfCourses(event.query).subscribe((res: any) => {
            this.golfCourses = res.golfcourses;
        });
    }
}
