import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HotelRoomsService {

    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    addHotelRoom(hotelId, obj) {
        return this.http.post(this.baseUrl + 'hotels/' + hotelId + '/rooms', obj);
    }
    updateHotelRoom(obj) {
        return this.http.put(this.baseUrl + 'rooms/' + obj.id, obj);
    }
    deleteHotelRoom(roomId) {
        return this.http.delete(this.baseUrl + 'rooms/' + roomId);
    }
    uploadRoomImages(roomId, obj) {
        return this.http.post(this.baseUrl + 'rooms/upload-images/' + roomId, obj);
    }
    deleteRoomImages(roomId, obj) {
        return this.http.post(this.baseUrl + 'rooms/delete-image/' + roomId, obj);
    }
    changeMainImage(id, obj) {
        return this.http.post(this.baseUrl + 'rooms/' + id + '/change-main-image', obj);
    }
}
