<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="lists$ | async" [rows]="10" [paginator]="true"
             [globalFilterFields]="[]"
             [(selection)]="selectedLists" [rowHover]="true" dataKey="id"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">Price Lists</h5>

                    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                            (click)="addNew()"></button>
                </div>
                <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                        (click)="deleteSelectedlists()"
                        [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->

                <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import" chooseLabel="Import"
                              class="p-mr-2 p-d-inline-block float-right p-ml-1"></p-fileUpload>
                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="listId">List ID
                    <p-sortIcon field="listId"></p-sortIcon>
                </th>
                <th pSortableColumn="listType">List Type
                    <p-sortIcon field="listType"></p-sortIcon>
                </th>
                <th pSortableColumn="listName">List Name
                    <p-sortIcon field="listName"></p-sortIcon>
                </th>
                <th pSortableColumn="serviceType">Service Type
                    <p-sortIcon field="serviceType"></p-sortIcon>
                </th>
                <th pSortableColumn="serviceCode">Service Code
                    <p-sortIcon field="serviceCode"></p-sortIcon>
                </th>
                <th pSortableColumn="status">Status
                    <p-sortIcon field="status"></p-sortIcon>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-list let-index>
            <tr>
                <td><p-tableCheckbox [value]="list"></p-tableCheckbox></td>
                <td style="width:120px;">{{list.listId}}</td>
                <td style="width:120px;">{{list.companyName}}</td>
                <td>{{list.provider}}</td>
                <td style="width:120px; cursor: pointer;" (click)="edit(list)">{{list.name}}</td>
                <td>{{list.listCode}}</td>
                <td>{{list.refCode}}</td>
                <td class="status-td">
                    <p-inputSwitch [(ngModel)]="list.active" (onChange)="changeStatus(list, $event)"
                                   [trueValue]="1" [falseValue]="0"></p-inputSwitch>
                    <span class="status-txt">{{list.active == 1 ? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td>
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" (click)="edit(list)">Edit</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="addPriceList()">Add price list</a></li>
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
