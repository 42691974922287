import {Component, EventEmitter, Input, Output, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {Lookup} from '../../_models/lookup';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() placeholder: string;
    @Input() filter: false;
    @Input() options: Lookup[];
    @Input() optionLabel: string;
    @Input() optionValue: string;
    @Output() change = new EventEmitter();

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }

    writeValue(obj: any) {
    }

    registerOnChange(fn: any) {
    }

    registerOnTouched(fn: any) {
    }

    blurEvent(event) {
        this.change.emit(event.value);
    }

}
