<p-carousel [value]="news" styleClass="custom-carousel" [numVisible]="4" [numScroll]="1" [circular]="true"
            [autoplayInterval]="3000" [responsiveOptions]="responsiveOptions">
    <ng-template pTemplate="header">
        <h5>{{'news' | translate}}</h5>
    </ng-template>
    <ng-template let-item pTemplate="item">
        <div class="product-item">
            <div class="product-item-content">
                <div class="mb-3">
                    <img src="../../assets/slides/{{item.image}}" [alt]="item.title" class="product-image"
                    (click)="openLink(item.link)"/>
                </div>
                <div>
                    <p class="text-center">{{item.title}}</p>
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>
