import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-images-list',
    templateUrl: './images-list.component.html',
    styleUrls: ['./images-list.component.css']
})
export class ImagesListComponent implements OnInit, OnChanges {

    @ViewChild('uploader', {static: true}) uploader: FileUpload;
    @Output() delete = new EventEmitter();
    @Output() mainImageChanged = new EventEmitter();
    @Output() onSelectImages = new EventEmitter();
    @Input() limit: number;
    @Input() count: number;
    @Input() images: any[] = [];
    @Input() disabled = false;
    @Input() showMainIcon = false;
    @Input() showImageSelection = true;
    @Input() showGallery = true;
    displayGalley = false;
    activeIndex = 0;
    responsiveOptions2: any[] = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    form: UntypedFormArray;
    selectionsCount = 0;
    selectedItem: any;
    initialized = false;

    constructor(private fb: UntypedFormBuilder,
                public translate: TranslatePipe,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit(): void {
        this.images = this.images?.sort((a, b) => a?.rank - b?.rank);
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['images'].currentValue && this.initialized) {
            this.images = changes['images'].currentValue;
            if (this.images?.length === this.form.controls?.length) {
                this.setMainImage();
            } else if (this.images?.length > this.form.controls?.length) {
                this.FormAddNewImages();
            } else if (this.images?.length < this.form.controls?.length) {
                this.FormDeleteImages();
            }
        }
    }

    initForm() {
        this.form = this.fb.array([]);
        this.images?.forEach(image => {
            const img = this.fb.group({
                id: image.id,
                alt: image.alt,
                src: image.file_name,
                original_file_name: image.original_file_name,
                size: image.size,
                rank: image.rank,
                isMain: image.is_main,
                checked: false,
            });
            this.form.push(img);
        });
        if (!this.initialized) {
            this.initialized = true;
        }
    }

    clearUploader() {
        this.uploader.clear();
    }

    deleteImage(id) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                const idsList = [];
                idsList.push(id);
                this.delete.emit(idsList);
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    changeMainImage(id) {
        this.mainImageChanged.emit(id);
    }

    getFiles() {
        return this.uploader.files;
    }

    photosSelected() {
        this.onSelectImages.emit(this.uploader.files);
    }

    viewImage(index) {
        this.activeIndex = index;
        this.displayGalley = true;
    }

    getFormValue() {
        return this.form.value;
    }

    drop(event) {
        const element = this.images.splice(event.previousIndex, 1)[0];
        const formElement = this.form.controls.splice(event.previousIndex, 1)[0];
        this.images.splice(event.currentIndex, 0, element);
        this.form.controls.splice(event.currentIndex, 0, formElement);
        this.updateRank();
    }

    updateRank() {
        this.form.controls.forEach((control, index) => {
            control.get('rank').setValue(index + 1);
        });
    }

    selectionChanged(event) {
        if (event.checked) {
            this.selectionsCount++;
        } else {
            this.selectionsCount--;
        }
    }

    deleteSelected() {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                const idsList = [];
                this.form.controls.forEach(control => {
                    if (control.get('checked').value) {
                        idsList.push(control.get('id').value);
                    }
                });
                this.delete.emit(idsList);
                this.selectionsCount = 0;
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    allSelection() {
        if (this.selectionsCount === this.images.length) {
            this.form.controls.forEach(control => {
                control.get('checked').setValue(false);
            });
            this.selectionsCount = 0;
        } else {
            this.form.controls.forEach(control => {
                control.get('checked').setValue(true);
            });
            this.selectionsCount = this.images.length;
        }
    }

    itemSelected(item) {
        this.selectedItem = item;
    }

    setMainImage() {
        this.images.forEach(image => {
            this.form.controls.forEach(control => {
                if (image.id === control.get('id').value) {
                    control.get('isMain').setValue(image.is_main);
                }
            });
        });
    }

    FormAddNewImages() {
        this.images.forEach(image => {
            if (!this.form.controls.find(r => r.get('id').value === image.id)) {
                const img = this.fb.group({
                    id: image.id,
                    alt: image.alt,
                    src: image.file_name,
                    original_file_name: image.original_file_name,
                    size: image.size,
                    rank: image.rank,
                    isMain: image.is_main,
                    checked: false,
                });
                this.form.push(img);
            }
        });
        this.updateRank();
    }

    FormDeleteImages() {
        const indexesToRemove = [];
        this.form.controls.forEach((control, index) => {
            if (!this.images.find(r => control.get('id').value === r.id)) {
                indexesToRemove.push(index);
            }
        });
        for (let i = indexesToRemove.length - 1; i >= 0; i--)
            this.form.controls.splice(indexesToRemove[i], 1);
        this.updateRank();
    }

    emptyForm() {
        this.form = this.fb.array([]);
    }

}
