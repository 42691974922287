<p-toast></p-toast>
<div class="card toolbar-demo">
    <div class="p-grid p-jc-between mt-2 mb-3">
        <div class="p-d-flex p-ai-center">
            <h5 class="p-mr-2">Golf Hotels</h5>
            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                    (click)="addNew()"></button>
        </div>
        <div style="display: flex; align-items: center">
            <button pButton pRipple type="button" icon="pi pi-sitemap" (click)="changeActiveView('tree')" [ngClass]="{'p-button-outlined': activeView != 'tree'}" class="p-button-rounded" style="margin-right: 5px"></button>
            <button pButton pRipple type="button" icon="pi pi-table" (click)="changeActiveView('table')" [ngClass]="{'p-button-outlined': activeView != 'table'}" class="p-button-rounded"></button>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12">
            <app-hotels-table *ngIf="activeView === 'table'"></app-hotels-table>

            <app-hotels-tree *ngIf="activeView === 'tree'"></app-hotels-tree>
        </div>
    </div>
</div>
