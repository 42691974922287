<div class="p-grid">
  <div class="p-md-3">
    <app-tree-view [object]="'GolfHolidays'" [l4Data]="services" [l5Data]="this.products ? this.products : null" (selectedCity)="citySelected($event)"
                   (selectedL4Item)="productSelected($event)" (selectedL5Item)="serviceSelected($event)"></app-tree-view>
  </div>
  <div class="p-md-9 mt-1">
    <app-golf-holiday-form *ngIf="selectedProduct"
                                    [productId]="selectedProduct.id"></app-golf-holiday-form>
  </div>
</div>
