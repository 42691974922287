import {Component, Input, Output, Self, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NgControl} from '@angular/forms';

@Component({
    selector: 'app-switch-input',
    templateUrl: './switch-input.component.html',
    styleUrls: ['./switch-input.component.css']
})
export class SwitchInputComponent implements ControlValueAccessor, OnChanges {

    @Input() label: string;
    @Output() changed = new EventEmitter();
    @Input() disabled = false;
    value: boolean;
    @Input() inline = false;

    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.disabled = changes.disabled?.currentValue || this.disabled;
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }

    writeValue(obj: any) {
    }

    registerOnChange(fn: any) {
    }

    registerOnTouched(fn: any) {
    }

    valueChanged(event) {
        this.changed.emit(event);
    }

}
