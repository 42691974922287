<p-toast></p-toast>

<div class="card">
    <form [formGroup]="documentForm">
        <p-table #dt [value]="documents" [rows]="10" [paginator]="true"
                 [rowHover]="true" dataKey="id"
                 currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
                 [showCurrentPageReport]="true"
                 [globalFilterFields]="['title','document_type_name','expire_date','is_notify']">
            <ng-template pTemplate="caption">
                <div class="p-ai-center">
                    <!--<h5 class="p-m-1 float-left">Documents</h5>-->

                    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                            (click)="addNew()" pTooltip="Add new document" tooltipPosition="top"></button>

                    <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                            (click)="deleteSelectedProducts()"
                            [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->

                    <span class="p-input-icon-left float-right p-mr-1">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>View
                        <p-sortIcon field="title"></p-sortIcon>
                    <th pSortableColumn="title">Title
                        <p-sortIcon field="title"></p-sortIcon>
                    </th>
                    <th pSortableColumn="document_type_name">Document type
                        <p-sortIcon field="document_type_name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="expire_date">Expiry date
                        <p-sortIcon field="expire_date"></p-sortIcon>
                    </th>
                    <th pSortableColumn="is_notify">Notify
                        <p-sortIcon field="is_notify"></p-sortIcon>
                    </th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-document let-index="rowIndex">
                <tr>
                    <td>
                        <div *ngIf="document.file_type == 'pdf'" class="file-type" (click)="viewDocument(document)">
                            {{document.file_type.toUpperCase()}}
                        </div>
                        <p-image *ngIf="document.file_type != 'pdf'"
                                 [src]="document.file_path" width="40" height="40" [preview]="true"></p-image>
                    </td>
                    <td>
                        {{document.title}}
                    </td>
                    <td>
                        {{document.document_type_name}}
                    </td>
                    <td>
                        {{document.expire_date  | date: 'dd.MM.yyyy'}}
                    </td>
                    <td>
                        {{document.is_notify == 1 ? 'On' : 'Off'}}
                    </td>

                    <td>
                        <div class="btn-group" dropdown>
                            <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                    class="p-button-rounded p-button-text"></button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                role="menu" aria-labelledby="button-basic">
                                <li role="menuitem"><a class="dropdown-item" (click)="edit(document)">Edit</a></li>
                                <li role="menuitem"><a class="dropdown-item"
                                                       (click)="delete(document)">Delete</a></li>
                            </ul>
                        </div>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</div>

<p-dialog [(visible)]="showDialog" [header]="selectedDocument ? 'Edit Document' : 'Add New Document'" [modal]="true"
          (onHide)="clearForm()"
          styleClass="p-fluid details-dialog">
    <form [formGroup]="documentForm" (ngSubmit)="submit()">
        <div class="p-grid p-4">
            <div class="p-col-6">
                <app-text-input [label]="'Document title'"
                                [formControl]="documentForm.controls['title']"></app-text-input>
            </div>
            <div class="p-col-6">
                <label>Expiry date <span class="p-error">*</span></label>
                <p-calendar formControlName="expire_date" [yearNavigator]="true" [monthNavigator]="true"
                            [minDate]="minDate" [yearRange]="yearRange" [dateFormat]="'dd.mm.yy'"
                            [appendTo]="'body'" placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                <small *ngIf="documentForm.controls.expire_date.touched && documentForm.controls.expire_date.errors?.required"
                       class="p-error">required</small>
            </div>
            <div class="p-col-6">
                <app-select-input [label]="'Document type'" [options]="documentTypes" [optionLabel]="'name'"
                                  [optionValue]="'id'"
                                  [formControl]="documentForm.controls['document_type_id']"></app-select-input>
            </div>
            <div class="p-col-6">
                <app-switch-input [label]="'Expiry notification'"
                                  [formControl]="documentForm.controls['is_notify']"></app-switch-input>
            </div>
            <div class="p-col-12">
                <p-fileUpload accept=".png, .jpg, .jpeg, .pdf"
                              maxFileSize="5000000"
                              customUpload="true" #uploader
                              withCredentials="true"
                              [showUploadButton]="false">
                    <ng-template pTemplate="toolbar">
                        <div class="hint">Max photo size: 5 MB</div>
                    </ng-template>

                </p-fileUpload>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <div class="buttons">
            <button pButton type="button" (click)="submit()" class="float-right"
                    [disabled]="!documentForm.valid || (!selectedDocument && uploader?.files.length < 1)">
                {{selectedDocument ? ('saveChanges' | translate) : 'Create'}}
            </button>
        </div>
    </ng-template>
</p-dialog>

