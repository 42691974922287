import {Component, Input, Output, Self, EventEmitter, Optional} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.css']
})
export class SelectInputComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() optionLabel: string;
    @Input() optionValue: string;
    @Input() options: any[];
    @Output() optionChanged = new EventEmitter();
    @Output() optionClicked = new EventEmitter();
    @Input() disabled = false;
    @Input() filter = false;
    @Input() emptyMessage = 'No results found';

    constructor(@Optional() @Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }

    writeValue(obj: any) {
    }

    registerOnChange(fn: any) {
    }

    registerOnTouched(fn: any) {
    }

    valueChanged(event) {
        this.optionChanged.emit(event);
    }
    clicked() {
        this.optionClicked.emit();
    }
    call() {
        console.log('jkhjihui');
    }

}
