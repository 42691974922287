import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {CalendarService} from '../../_services/calendar';
import {Observable, Subject} from 'rxjs';
import {Activity, CalendarEventEmitter, PeakTime, Season} from '../../_models/calendar';
import {MessageService} from 'primeng/api';
import {GolfCoursesService} from '../_services/golf-courses.service';
import {scan} from 'rxjs/operators';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  events$: Observable<(Activity|Season|PeakTime)[]>;
  newEvent$: Subject<(Activity|Season|PeakTime)[]> = new Subject<(Activity|Season|PeakTime)[]>();

  golfCourse: { name: string; id: number; timezone: string; };
  golfCourses$: Observable<{ name: string; id: string; }[]>;

  constructor(
      private breadcrumbService: BreadcrumbService,
      private calendarService: CalendarService,
      private msgService: MessageService,
      private golfCourseService: GolfCoursesService
  ) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: 'Golf Courses', routerLink: ['/golf-courses']},
      {label: 'Calendar'},
    ]);
  }

  ngOnInit(): void {
    this.getGolfCourse();
  }

  getGolfCourse(): void {
    this.golfCourses$ = this.golfCourseService.getAllGolfCourseList();
  }

  getSeasons(golfCourseId: number): void {
    try {
      this.calendarService.getSeasons(golfCourseId).subscribe(seasons => {
        this.newEvent$.next(seasons);
      });
    } catch (e) {
      console.error('get seasons error:', e);
      this.msgService.add({
        severity: 'error',
        summary: 'Get Season',
        detail: 'Something unexpected went wrong!'
      });
    }
  }

  getActivities(golfCourseId: number): void {
    try {
      this.calendarService.getActivities(golfCourseId).subscribe(activities => {
        this.newEvent$.next(activities);
      });
    } catch (e) {
      console.error('get seasons error:', e);
      this.msgService.add({
        severity: 'error',
        summary: 'Get Season',
        detail: 'Something unexpected went wrong!'
      });
    }
  }

  async addEvent(e: CalendarEventEmitter) {
    switch (e.type) {
      case 'activity':
        try {
          // tslint:disable-next-line:max-line-length
          const res: any = e.isEditMode ? await this.calendarService.updateActivity(e.data, +e.data.id).toPromise() : await this.calendarService.addActivity(e.data, this.golfCourse.id).toPromise();
          if (res.status) {
            this.updateGolfCourse(this.golfCourse);
            this.msgService.add({
              severity: 'success',
              summary: e.isEditMode ? 'Update Activity' : 'Add Activity',
              detail: e.isEditMode ? 'Activity has been updated successfully!' : 'Activity has been added successfully!'
            });
          }
        } catch (e) {
          console.error('add/edit activity error:', e);
          this.msgService.add({
            severity: 'error',
            summary: e.isEditMode ? 'Update Activity' : 'Add Activity',
            detail: 'Something unexpected went wrong!'
          });
        }
        break;
      case 'season':
        try {
          const res: any = await this.calendarService.addSeason(e.data, this.golfCourse.id).toPromise();
          if (res.status) {
            this.updateGolfCourse(this.golfCourse);
            this.msgService.add({
              severity: 'success',
              summary: 'Add Season',
              detail: 'Season has been added successfully!'
            });
          }
        } catch (e) {
          console.error('add season error:', e);
          this.msgService.add({
            severity: 'error',
            summary: 'Add Season',
            detail: 'Something unexpected went wrong!'
          });
        }
        break;
    }
  }

  updateGolfCourse(golfCourse) {
    this.events$ = this.newEvent$.pipe(scan(acc => ([]), []));
    this.newEvent$.next([]);
    this.events$ = this.newEvent$.pipe(
        scan((acc, val) => ([...acc, ...val]), [])
    );

    this.getSeasons(golfCourse.id);
    this.getActivities(golfCourse.id);
  }
}
