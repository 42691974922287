import {Component, OnInit} from '@angular/core';
import {GfpService} from '../../_services/gfp.service';
import {ProductAPIService} from '../../_services/product-api.service';

@Component({
    selector: 'app-gfp-tree',
    templateUrl: './gfp-tree.component.html',
    styleUrls: ['./gfp-tree.component.css']
})
export class GfpTreeComponent implements OnInit {
    services: any[];
    selectedService: any;
    products: any[];
    selectedProduct: any;

    constructor(private gfpService: GfpService, private prodAPIService: ProductAPIService) {
    }

    ngOnInit() {
    }

    citySelected(event) {
        this.selectedProduct = null;
        this.prodAPIService.getServicesByCity(event.id).subscribe((res: any) => {
            this.services = res;
            this.services.map((service, index) => {
                service.label = service.name;
                service.level = 3;
                service.indexes = [...event.indexes, index];
                service.key = 'service-' + service.id;
            });
        });
    }

    serviceSelected(event) {
        this.selectedProduct = null;
        this.gfpService.getByService(event.id).subscribe((res: any) => {
            this.products = res;
            this.products.map(product => {
                product.label = product.name;
                product.level = 4;
                product.indexes = [];
                product.key = 'product-' + product.id;
            });
        });
    }

    productSelected(event) {
        this.selectedProduct = null;
        this.selectedProduct = event;
    }

}
