import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Permission } from 'src/app/_models/roles-permissions';
import { RolesPermissionService } from 'src/app/_services/roles-permission.service';

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-permission.component.html',
  styleUrls: ['./add-permission.component.css']
})
export class AddPermissionComponent implements OnInit {

  permission: any = {
    name: null,
    status: 1,
    module: null,
    page: null,
    description: null,
    code: this.generateCode()
  };

  activeOptions = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 }
  ];
  modules: any[] = [];
  modulesList: any[] = [];
  pagesList: any[] = [];

  isEditMode = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private rolesPermissionsAPI: RolesPermissionService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    await this.createModuelsPagesLists();
    const editPermissionId: number = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (editPermissionId > -1) {
      this.isEditMode = true;
      try {
        const permissionDetails: Permission = await this.rolesPermissionsAPI.getPermissionDetails(editPermissionId);

        this.permission = {
          id: permissionDetails.id,
          name: permissionDetails.name,
          status: permissionDetails.status,
          module: this.modulesList.filter(module => module.id === permissionDetails.module_id)[0],
          page: this.getPageSelection(this.modulesList.filter(module => module.id === permissionDetails.module_id)[0], permissionDetails),
          description: permissionDetails.description,
          code: permissionDetails.code
        };

      } catch (error) {
        console.error('Reading permisison deatails error', error);
        this.messageService.add({
          severity: 'error', summary: 'Edit Permission Error',
          detail: 'Unexpected error happened while reading permission details!'
        });
      }
    }
    this.breadcrumbService.setItems([
      { label: 'Dashboard', routerLink: '/' },
      { label: 'Permissions', routerLink: '/admin/permissions' },
      { label: this.isEditMode ? 'Edit Permission' : 'New Permission' },
    ]);
  }

  async createModuelsPagesLists() {
    try {
      this.modulesList = (await this.rolesPermissionsAPI.getModulesList()).filter(module => module.pages.length > 0);

    } catch (error) {
      console.error('Getting Modules Error', error);

    }
  }

  getPageSelection(module, permissionDetails) {
    this.pagesList = module.pages;
    return module.pages.filter(page => page.id === permissionDetails.page_id)[0];
  }

  createpagesList(module) {
    this.permission.page = null;
    if (module) {
      this.pagesList = module.pages;
    }
  }

  async submit(form) {
    const permission: Permission = {
      name: form.value.name,
      status: form.value.status,
      code: this.permission.code,
      module_id: form.value.module.id,
      page_id: form.value.page.id,
      description: form.value.description
    };

    // submit
    if (!this.isEditMode) {
      await this.createNewPermission(form, permission);
    } else {
      permission.id = this.permission.id;
      await this.editPermission(permission);
    }

  }

  async createNewPermission(form, permission) {
    try {
      const res = await this.rolesPermissionsAPI.createPermission(permission);
      if (res) {
        this.messageService.add({
          severity: 'success', summary: 'New Permission Created',
          detail: 'New Permission has been createded successfully'
        });
        // Reset form values
        this.resetForm(form);
      }
    } catch (error) {
      console.error('Create new permission error', error);
      this.messageService.add({
        severity: 'error', summary: 'Create New Permission',
        detail: 'Unexpected error happened while creating new permission!'
      });
    }
  }

  async editPermission(permission) {
    try {
      const res = await this.rolesPermissionsAPI.updatePermission(permission);
      if (res) {
        this.messageService.add({
          severity: 'success', summary: 'Permission Updated',
          detail: 'Permission has been updated successfully!'
        });
      }
    } catch (error) {
      console.error('Update permission error', error);
      this.messageService.add({
        severity: 'error', summary: 'Update Permission',
        detail: 'Unexpected error happened while updating permission!'
      });
    }
  }

  resetForm(form) {
    form.reset();

    this.permission = {
      name: null,
      status: 1,
      module: null,
      page: null,
      description: null,
      code: this.generateCode()
    };
  }

  generateCode(): string {
    return '' + Date.now();
  }

}
