import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GolfCoursesRoutingModule} from './golf-courses-routing.module';
import {CoursesComponent} from './courses/courses.component';
import {CalendarComponent} from './calendar/calendar.component';
import {AddCourseComponent} from './courses/add-course/add-course.component';
import {EditCourseComponent} from './courses/edit-course/edit-course.component';
import {GeneralComponent} from './courses/edit-course/general/general.component';
import {ServicesComponent} from './courses/edit-course/services/services.component';
import {TrainingComponent} from './courses/edit-course/training/training.component';
import {SharedModule} from '../_shared/shared.module';
import { ServicesFormComponent } from './courses/edit-course/services-form/services-form.component';
import {CoursesTableComponent} from './courses/courses-table/courses-table.component';
import {CoursesTreeComponent} from './courses/courses-tree/courses-tree.component';
import { CmsComponent } from './courses/edit-course/cms/cms.component';
import { GcFacilitiesComponent } from './courses/edit-course/gc-facilities/gc-facilities.component';


@NgModule({
    declarations: [
        CoursesComponent,
        CalendarComponent,
        AddCourseComponent,
        EditCourseComponent,
        GeneralComponent,
        ServicesComponent,
        TrainingComponent,
        ServicesFormComponent,
        CoursesTableComponent,
        CoursesTreeComponent,
        CmsComponent,
        GcFacilitiesComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        GolfCoursesRoutingModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class GolfCoursesModule {
}
