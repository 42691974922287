import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {GolfHoliday} from '../../_models/golf-holiday';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GolfHolidayService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    create(obj) {
        return this.http.post(this.baseUrl + 'golf-holidays', obj);
    }
    update(obj) {
        return this.http.put(this.baseUrl + 'golf-holidays/' + obj.id, obj);
    }
    getPaginated(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'golf-holidays?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + (searchKeyword ? searchKeyword : ''));
    }
    getAll(keyword = '', cityId?, countryId?) {
        return this.http.get(this.baseUrl + 'golf-holidays/get/all?' + (keyword ? 'search=' + keyword : '')
            + (cityId ? 'city_id=' + cityId : '') + (countryId ? 'country_id=' + countryId : ''));
    }
    getById(id): Observable<GolfHoliday> {
        return this.http.get<GolfHoliday>(this.baseUrl + 'golf-holidays/' + id).pipe(map((res: any) => {
            return res.product;
        }));
    }
    delete(id) {
        return this.http.delete(this.baseUrl + 'golf-holidays/' + id);
    }
    getByService(serviceId) {
        return this.http.get(this.baseUrl + 'golf-holidays/get/all?page=' + '&service_id=' + serviceId)
            .pipe(map((res: any) => res.products));
    }
}
