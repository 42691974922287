import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TourOperator} from '../../../_models/tour-operator';
import {Lookup} from '../../../_models/lookup';
import {User} from '../../../_models/user';
import {CompaniesService} from '../../../_services/companies-service';
import {UsersServices} from '../../../_services/users-services';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {TourOperatorService} from '../../_services/tour-operator.service';
import {GGTreeNode} from '../../../_models/GGTreeModel';
import {DestinationsService} from '../../../destination/_services/destinations.service';
import {TravelAgencyService} from '../../../travel-agency/_services/travel-agency.service';
import {Company} from '../../../_models/company';
import {SharedService} from '../../../_services/shared.service';
import {GgTreeComponent} from '../../../_shared/gg-tree/gg-tree.component';
import {Area, City, Country, Region} from '../../../_models/destinations';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-add-tour-operator',
    templateUrl: './add-tour-operator.component.html',
    styleUrls: ['./add-tour-operator.component.css']
})
export class AddTourOperatorComponent implements OnInit {

    form: UntypedFormGroup;
    operator: TourOperator;
    users: User[];
    companies: Lookup[];
    agencies: Lookup[];
    addMode = true;
    showCompanyInfoButton = true;
    @ViewChild('tree', {static: true}) GgTree: GgTreeComponent;
    company: Company;

    destinations: GGTreeNode[];
    initSelectedDestinations: any[];
    destinations$: Observable<Region[]>;

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    langKey: string;
    countriesLoaded = false;
    citiesLoaded = false;
    areasLoaded = false;

    constructor(private breadcrumbService: BreadcrumbService, private fb: UntypedFormBuilder, private companiesServices: CompaniesService,
                private usersServices: UsersServices, private agenciesService: TravelAgencyService,
                private messageService: MessageService, private router: Router, private route: ActivatedRoute,
                private confirmationService: ConfirmationService, private companiesService: CompaniesService,
                private operatorsServices: TourOperatorService, private destinationService: DestinationsService,
                public sharedServices: SharedService, private destinationsService: DestinationsService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.langKey = this.translateService.currentLang === 'de' ? 'name_de' : 'name_en';
        this.getRegions();
        // this.destinations$ = this.destinationService.getAllRegions();
        this.initForm();

        if (this.route.snapshot.paramMap.get('id')) {
            this.addMode = false;
            this.getOperatorById();
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Tour Operators', routerLink: ['/tour-operators']},
                {label: 'Edit Tour Operator'},
            ]);
        } else {
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Tour Operators', routerLink: ['/tour-operators']},
                {label: 'New Tour Operator'},
            ]);
        }
    }

    initForm() {
        this.form = this.fb.group({
            id: [null],
            is_parent: [0],
            company: [null, Validators.required],
            company_id: [null],
            company_name: [null],
            name: [null, Validators.required],
            ref_id: [null],
            active: [1],
            has_gfp_requests: [0],
            delegate_name: [null],
            delegate_email: [null, [Validators.email]],
            delegate_mobile_number: [null],
            assigned_user: [null],
            assigned_user_id: [null],
            assigned_user_name: [null],
            translations: this.fb.array([
                this.fb.group({
                    language_id: 1,
                    internal_description: null,
                    website_description: null
                }),
                this.fb.group({
                    language_id: 2,
                    internal_description: null,
                    website_description: null
                })
            ]),
            website: [null],
            phone: [null],
            fax: [null],
            email: [null, [Validators.required, Validators.email]],
            notes: this.fb.array([null]),
            cities: [null],
            travelagencies: [null],
            is_company_address: [0],
            region_id: new UntypedFormControl(null, Validators.required),
            country_id: new UntypedFormControl(null, Validators.required),
            city_id: new UntypedFormControl(null, Validators.required),
            area_id: new UntypedFormControl(null),
            street: new UntypedFormControl(null),
            postal_code: new UntypedFormControl(null)
        });
    }

    getOperatorById() {
        this.operatorsServices.getById(this.route.snapshot.paramMap.get('id')).subscribe((res: any) => {
            this.operator = res.touroperator;
            this.operator.area_id = this.operator.area?.id;
            this.fillDestinations();
            this.form.patchValue(this.operator);
            ////////////// Fetch Notes /////////////////
            if (this.operator.notes.length > 0) {
                const notes = this.form.controls.notes as UntypedFormArray;
                notes.removeAt(0);
                this.operator.notes.forEach((note: any) => {
                    notes.push(this.fb.control(note.title));
                });
            }
            ////////////// Fetch Notes /////////////////

            ////////////// Fetch Users /////////////////
            this.form.controls.assigned_user.setValue({
                id: this.form.value.assigned_user_id,
                full_name: this.form.value.assigned_user_name
            });
            this.form.controls.company.setValue({
                id: this.form.value.company_id,
                name: this.form.value.company_name
            });
            this.companiesService.getCompany(this.form.value.company_id).subscribe(response => {
                this.company = response;
            });
            ////////////// Fetch Users /////////////////
            this.initSelectedDestinations = this.operator.cities;
            if (this.form.get('is_company_address').value === 1) {
                this.disabilitySwitch(true);
            }
        });
    }

    get _form() {
        return this.form.controls;
    }

    get notes() {
        return this._form.notes as UntypedFormArray;
    }

    addNote() {
        const notes = this.form.controls.notes as UntypedFormArray;
        notes.push(this.fb.control(null));
    }

    removeNote(i) {
        const notes = this.form.controls.notes as UntypedFormArray;
        notes.removeAt(i);
    }

    searchUsers(event) {
        this.usersServices.searchUsers(event.query).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    searchCompanies(event) {
        this.companiesServices.searchCompanies(event.query).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    searchTravelAgencies(event) {
        this.agenciesService.search(event.query).subscribe((res: any) => {
            this.agencies = res.travelagencies.data;
        });
    }

    isParentChanged(event) {
        if (event.checked) {
            this.showCompanyInfoButton = false;
            this.form.get('company').setValue(null);
            this.form.get('company').disable();
            this.form.get('company').clearValidators();
            if (this.form.get('is_company_address').value == 1) {
                this.form.get('is_company_address').setValue(0);
                this.clearCompanyInfo();
                this.disabilitySwitch(false);
            }
        } else {
            this.showCompanyInfoButton = true;
            this.form.get('company').enable();
            this.form.get('company').addValidators(Validators.required);
            this.clearCompanyInfo();
        }
    }

    submitForm() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return false;
        }
        this.form.get('cities').setValue(this.destinations?.filter(dest => dest.leaf).map(city => city.id));
        const formValue = {...this.form.getRawValue(), ...this.form.getRawValue().destinations};

        formValue.delegate_mobile_number = formValue.delegate_mobile_number
            ?.internationalNumber?.toString().replace(/\s/g, '');
        formValue.fax = formValue.fax?.internationalNumber?.toString().replace(/\s/g, '');
        formValue.phone = formValue.phone?.internationalNumber?.toString().replace(/\s/g, '');

        formValue.assigned_user_id = formValue.assigned_user?.id;
        formValue.company_id = formValue.company?.id;

        formValue.notes = formValue.notes.filter(x => x != null);

        if (formValue.travelagencies == null) {
            formValue.travelagencies = [];
        } else {
            formValue.travelagencies = formValue.travelagencies.map(x => x.id);
        }
        if (this.addMode) {
            if (formValue.is_parent) {
                formValue.is_company_address = 1;
                const company = {
                    ...formValue,
                    name: formValue.name,
                    company_type_id: 5,
                    delegate_email: formValue.delegate_email,
                    delegate_mobile_number: formValue.delegate_mobile_number,
                    delegate_name: formValue.delegate_name,
                    postal_code: formValue.postal_code,
                    user_id: formValue.assigned_user_id
                };
                this.companiesServices.addCompany(company).subscribe((res: any) => {
                    formValue.company_id = res.company.id;
                    this.addTourOperator(formValue);
                });
            } else {
                this.addTourOperator(formValue);
            }
        } else {
            this.updateTourOperator(formValue);
        }
    }

    addTourOperator(formValue) {
        this.operatorsServices.add(formValue).subscribe(res => {
            this.resetPage();
            this.showCompanyInfoButton = true;
            this.form.get('active').setValue(1);
            this.form.get('company').enable();
            this.disabilitySwitch(false);
            this.initSelectedDestinations = [];
            this.messageService.add({
                severity: 'success', summary: 'Add Tour Operator',
                detail: 'New tour operator has been Added successfully'
            });
        });
    }

    updateTourOperator(formValue) {
        this.operatorsServices.update(formValue).subscribe(res => {
            this.messageService.add({
                severity: 'success', summary: 'Update Tour Operator',
                detail: 'Tour operator has been updated successfully'
            });
            this.areasLoaded = this.countriesLoaded = this.citiesLoaded = false;
        });
    }

    async companyInfoButtonClick(event) {
        if (event.checked == 1) {
            if (this.form.get('company').value !== null) {
                this.fillCompanyInfo(this.company);
            }
            this.disabilitySwitch(true);
        } else {
            this.countriesLoaded = false;
            this.citiesLoaded = false;
            this.areasLoaded = false;
            this.disabilitySwitch(false);
        }
    }

    async companySelected(company) {
        this.company = company;
        if (this.form.get('is_company_address').value == 1) {
            this.fillCompanyInfo(company);
        }
    }

    fillCompanyInfo(company: Company) {
        this.form.get('region_id').setValue(company.region_id);
        this.form.get('country_id').setValue(company.country_id);
        this.form.get('city_id').setValue(company.city_id);
        this.form.get('area_id').setValue(company.area?.id);
        this.form.get('street').setValue(company.street);
        this.form.get('postal_code').setValue(company.postal_code);
        this.form.get('website').setValue(company.website);
        this.form.get('phone').setValue(company.phone);
        this.form.get('fax').setValue(company.fax);
        this.form.get('email').setValue(company.email);
        this.fetchDestinations(company);
    }

    clearCompanyInfo() {
        this.form.get('region_id').setValue(null);
        this.form.get('country_id').setValue(null);
        this.form.get('city_id').setValue(null);
        this.form.get('area_id').setValue(null);
        this.form.get('street').setValue(null);
        this.form.get('postal_code').setValue(null);
        this.form.get('website').setValue(null);
        this.form.get('phone').setValue(null);
        this.form.get('fax').setValue(null);
        this.form.get('email').setValue(null);
    }

    disabilitySwitch(disable: boolean) {
        disable ? this.form.get('region_id').disable() : this.form.get('region_id').enable();
        disable ? this.form.get('country_id').disable() : this.form.get('country_id').enable();
        disable ? this.form.get('city_id').disable() : this.form.get('city_id').enable();
        disable ? this.form.get('area_id').disable() : this.form.get('area_id').enable();
        disable ? this.form.get('street').disable() : this.form.get('street').enable();
        disable ? this.form.get('postal_code').disable() : this.form.get('postal_code').enable();
        disable ? this.form.get('website').disable() : this.form.get('website').enable();
        disable ? this.form.get('phone').disable() : this.form.get('phone').enable();
        disable ? this.form.get('fax').disable() : this.form.get('fax').enable();
        disable ? this.form.get('email').disable() : this.form.get('email').enable();
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    //////////////// Destination Section ///////////////
    getRegions(id?: number) {
        if (id) {
            this.destinationsService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destinationsService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destinationsService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destinationsService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destinationsService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destinationsService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    getAreas(id?: number, cityId?: number) {
        if (id) {
            this.destinationsService.getArea(id).subscribe((res: any) => {
                this.areas = [res];
            });
        } else if (cityId && !this.areasLoaded) {
            this.destinationsService.getAreasList(cityId).subscribe((res: any) => {
                this.areas = res;
                this.areasLoaded = true;
            });
        }
    }

    regionChanged() {
        this.form.get('country_id').setValue(null);
        this.form.get('city_id').setValue(null);
        this.form.get('area_id').setValue(null);

        this.countries = [];
        this.cities = [];
        this.areas = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;
        this.areasLoaded = false;

        this.getCountries(null, this.form.get('region_id').value);
    }

    countryChanged() {
        this.form.get('city_id').setValue(null);
        this.form.get('area_id').setValue(null);

        this.cities = [];
        this.areas = [];

        this.citiesLoaded = false;
        this.areasLoaded = false;

        this.getCities(null, this.form.get('country_id').value);
    }

    cityChanged() {
        this.form.get('area_id').setValue(null);
        this.areas = [];
        this.areasLoaded = false;
        this.getAreas(null, this.form.get('city_id').value);

    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getAreaName(areaId) {
        console.log(areaId);
        if (areaId) {
            const areaName = this.areas.filter(area => area.id === areaId)[0][this.langKey];
            return areaName;
        }
    }

    fetchDestinations(item) {
        this.getCountries(item.country_id);
        this.getCities(item.city_id);
        if (item.area?.id) {
            this.getAreas(item.area?.id);
        }
    }

    fillDestinations() {
        this.countries = [this.operator.country];
        this.cities = [this.operator.city];
        if (this.operator.area) {
            this.areas = [this.operator.area];
        }
    }

    //////////////// Destination Section ///////////////
    viewCompany() {
        window.open(window.location.origin + '/admin/companies/edit/' + this.form.get('company').value.id,
            '_blank');
    }
}
