import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AccountService} from '../_services/account.service';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private accountService: AccountService, private router: Router,
              private messageService: MessageService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // check login
    if (!this.isLogin()) {
      this.router.navigateByUrl('/login');
      this.messageService.add({severity: 'warning', summary: 'Unauthorized',
        detail: 'You are not allowed to access this page. Please log in first!'
      });

      return false;
    }

    // check if user has permission to open module / page
    if (next.data.requirePermission) {
      if (this.isHavePermission(next.data)) {
        return true;
      } else {
          this.router.navigateByUrl('/accessdenied');
          this.messageService.add({
            severity: 'error', summary: 'Unauthorized',
            detail: 'You are not allowed to access this page!'
          });

          return false;
      }
    }

    return true;
  }

  /**
   * private method to check if user is logged in or no.
   * @returns boolean
   */
  private isLogin(): boolean {
    if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
      return true;
    }

    return false;
  }

  private isHavePermission(routeData: PermissionRouteData): boolean {

    switch (routeData.type) {
      case 'module':
        return this.accountService.checkModulePagePermission(routeData.module_id, 'module_id');
        break;
      case 'page':
        return this.accountService.checkModulePagePermission(routeData.page_id, 'page_id');
        break;
      default:
        return this.accountService.checkPermission(routeData.code);
        break;
    }
  }

}

interface PermissionRouteData {
  requirePermission?: boolean;
  module_id?: number;
  page_id?: number;
  type?: string;
  code?: string;
}
