import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TourOperator} from '../../_models/tour-operator';
import {Observable} from 'rxjs';
import {TourOperatorService} from '../_services/tour-operator.service';
import {map, tap} from 'rxjs/operators';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-tour-operator',
  templateUrl: './tour-operator.component.html',
  styleUrls: ['./tour-operator.component.css']
})
export class TourOperatorComponent implements OnInit {

  operator: TourOperator;
  selectedOperators: TourOperator[];
  operators$: Observable<TourOperator[]>;

  // Filter vars
  tableHelper = new GgTableCustomFN();
  filters = {
    company_name: [],
    region_name: [],
    country_name: [],
    city_name: [],
    area_name: [],
    assigned_user_name: [],
    created_by_user_name: []
  };
  booleanFilter = [{name: this.translate.transform('active'), val: 1},
    {name: this.translate.transform('inactive'), val: 0}];
  showDeletePrevent = false;

  constructor(private breadcrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService, private operatorsServices: TourOperatorService,
              private messageService: MessageService, private accountService: AccountService,
              public translate: TranslatePipe) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: 'Tour Operator'}
    ]);
  }

  log(msg) {
    console.log(msg);
  }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.operators$ = this.operatorsServices.getAll().pipe(tap(operators => {
      this.tableHelper.populateFilter(this.filters, operators);
      operators.forEach(operator => {
        operator.created_at = new Date(operator.created_at);
      });
    }));
  }

  addNew() {
    this.router.navigate(['add'], {relativeTo: this.route});
  }

  edit(operator) {
    this.router.navigate(['edit/' + operator.id], {relativeTo: this.route});
  }
  delete(operator, index) {
    this.confirmationService.confirm({
      message: this.translate.transform('confirmMessage'),
      accept: () => {
        this.operatorsServices.delete(operator.id).subscribe((res: any) => {
          if (res.has_requests) {
            this.showDeletePrevent = true;
          } else {
            this.operators$.pipe(map(operators => {
              return operators.splice(index, 1);
            }));
            this.messageService.add({
              severity: 'success', summary: 'Delete Tour Operator',
              detail: 'Selected tour operator has been deleted successfully'
            });
            this.getAll();
          }
        });
      },
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }

  changeStatus(operator, event) {
    operator.active = event.checked;
    this.operatorsServices.update(operator).subscribe(res => {
      this.messageService.add({
        severity: 'success', summary: 'Update Tour Operator',
        detail: 'Tour operator status has been deleted successfully'
      });
    });
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedOperators || this.selectedOperators.length < 1) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedOperators);
  }
}
