
<div class="card">

  <p-table #dt [value]="products" [scrollable]="true" [scrollDirection]="'both'"
           [(selection)]="selectedProducts" [rowHover]="true" dataKey="id">
    <ng-template pTemplate="caption">
      <div class="p-ai-center">


        <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right"></button>

        <span class="p-input-icon-left float-right p-mr-1">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" [(ngModel)]="searchValue"
                           (input)="searchProducts()" placeholder="Search..."/>
                </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:60px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th style="width:120px" pSortableColumn="productId">Product ID
          <p-sortIcon field="productId"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="product_name">Product Name
          <p-sortIcon field="product_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="service_name">Service
          <p-sortIcon field="service_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="hotel_name">Hotel
          <p-sortIcon field="hotel_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="code">Code
          <p-sortIcon field="ode"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="refCode">Ref. Code
          <p-sortIcon field="refCode"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="room_type_name">Room type
          <p-sortIcon field="room_type_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="view_type_name">View
          <p-sortIcon field="view_type_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="board_type_name">Board
          <p-sortIcon field="board_type_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="validity_from">Validity from
          <p-sortIcon field="validity_from"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="validity_to">Validity to
          <p-sortIcon field="validity_to"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="status">Status
          <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th style="width:60px" alignFrozen="right" pFrozenColumn
        >Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-index>
      <tr>
        <td style="width:60px"><p-tableCheckbox [value]="product"></p-tableCheckbox></td>
        <td style="width:120px;">{{product.id}}</td>
        <td style="width:120px; cursor: pointer" (click)="edit(product)">{{product.name}}</td>
        <td style="width:120px">{{product.service_name}}</td>
        <td style="width:120px; cursor: pointer;">{{product.hotel_name}}</td>
        <td style="width:120px">{{product.code}}</td>
        <td style="width:120px">{{product.ref_code}}</td>
        <td style="width:120px">{{product.room_type_name}}</td>
        <td style="width:120px">{{product.room_view_name}}</td>
        <td style="width:120px">{{product.room_board_name}}</td>
        <td style="width:120px">{{product.validity_from}}</td>
        <td style="width:120px">{{product.validity_to}}</td>
        <td class="status-td" style="width:120px">
          <span class="status-txt">{{product.status == 1 ? ('active' | translate) : ('inactive' | translate)}}</span>
        </td>
        <td style="width:60px"  alignFrozen="right" pFrozenColumn>
          <div class="btn-group" dropdown>
            <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                    class="p-button-rounded p-button-text"></button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" (click)="edit(product)">Edit</a></li>
              <li role="menuitem"><a class="dropdown-item" (click)="delete(product)">Delete</a></li>
            </ul>
          </div>

        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator *ngIf="products?.length > 0" [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
               (onPageChange)="pageChanged($event)" [showJumpToPageInput]="true"
               [rowsPerPageOptions]="[10, 50, 100, 200]" #p [showCurrentPageReport]="true"
               currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
</div>
