import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TourOperatorRoutingModule} from './tour-operator-routing.module';
import {TourOperatorComponent} from './tour-operator/tour-operator.component';
import {SharedModule} from '../_shared/shared.module';
import { AddTourOperatorComponent } from './tour-operator/add-tour-operator/add-tour-operator.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        TourOperatorComponent,
        AddTourOperatorComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        TourOperatorRoutingModule,
        TranslateModule
    ]
})
export class TourOperatorModule {
}
