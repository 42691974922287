<div class="p-field">
    <label *ngIf="label">{{label}} <span class="p-error">{{checkRequired(ngControl.control) ? '*' : ''}}</span></label>
    <i *ngIf="showTrash" class="pi pi-trash" (click)="remove()"></i>

    <textarea class="full-width" pInputText [formControl]="ngControl.control" [placeholder]="placeholder"
    [rows]="rows" autocomplete="false" (blur)="onBlur($event)"></textarea>

    <small *ngIf="ngControl.control.touched && ngControl.control.errors?.required"
           class="p-error">{{'required' | translate}}</small>


    <small *ngIf="ngControl.control.errors?.minlength" class="p-error">
        {{ngControl.control.errors.minlength['requiredLength']}} letters at least
    </small>

    <small *ngIf="ngControl.control.errors?.maxlength" class="p-error">
        {{ngControl.control.errors.maxlength['requiredLength']}} letters at most
    </small>
</div>
