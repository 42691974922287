import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-gfp-form',
  templateUrl: './gfp-form.component.html',
  styleUrls: ['./gfp-form.component.css']
})
export class GfpFormComponent implements OnInit {

  isEdit = false;
  options = [
    {name: 'Package', id: 1},
    {name: 'Single', id: 0}
  ];
  selectedType = 1;

  constructor(private breadcrumbService: BreadcrumbService,
              private route: ActivatedRoute) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: 'Product Setup'},
      {label: 'GFPs', routerLink: '/product-setup/gfp'}
    ]);
  }

  ngOnInit(): void {
  }

}
