import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DestinationRoutingModule } from './destination-routing.module';
import { DestinationComponent } from './destination.component';
import { RegionComponent } from './region/region.component';
import { CountryComponent } from './country/country.component';
import { CityComponent } from './city/city.component';
import { SharedModule } from '../_shared/shared.module';
import { RegionFormComponent } from './region/region-form/region-form.component';
import { CountryFormComponent } from './country/country-form/country-form.component';
import { CityFormComponent } from './city/city-form/city-form.component';
import {TestimoniesComponent} from './testimonies/testimonies.component';
import { AreaComponent } from './area/area.component';
import { AreaFormComponent } from './area/area-form/area-form.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        DestinationComponent,
        RegionComponent,
        CountryComponent,
        CityComponent,
        RegionFormComponent,
        CountryFormComponent,
        CityFormComponent,
        TestimoniesComponent,
        AreaComponent,
        AreaFormComponent
    ],
    exports: [
        DestinationComponent
    ],
    imports: [
        CommonModule,
        DestinationRoutingModule,
        SharedModule,
        TranslateModule
    ]
})
export class DestinationModule { }
