import {AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Lookup} from '../../../_models/lookup';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-price-list-form',
    templateUrl: './price-list-form.component.html',
    styleUrls: ['./price-list-form.component.css']
})
export class PriceListFormComponent implements OnInit {

    form: UntypedFormGroup;

    sales: any[];

    lastYearTotal: number;

    thisYearTotal: number;

    services: Lookup[] = [{id: 1, name: 'Service1'}, {id: 2, name: 'Service2'}, {id: 3, name: 'Service3'}];
    listTypes: Lookup[] = [{id: 1, name: 'Selling'}, {id: 2, name: 'Purchasing'}, {id: 3, name: 'Tui'}];
    seasons: Lookup[] = [{id: 1, name: 'Season 1'}, {id: 2, name: 'Season 2'}, {id: 3, name: 'Season 3'}];

    markupList: Lookup[];


    constructor(private breadcrumbService: BreadcrumbService, private fb: UntypedFormBuilder,
                private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService,
                private messageService: MessageService, @Inject(DOCUMENT) private document: Document) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Price List', routerLink: '/product-setup/price-list'},
            {label: 'Add Price List'},
        ]);
    }

    ngOnInit() {
        this.initForm();
        this.sales = [
            {product: 'Bamboo Watch', lastYearSale: 51, thisYearSale: 40, lastYearProfit: 54406, thisYearProfit: 43342},
            {product: 'Black Watch', lastYearSale: 83, thisYearSale: 9, lastYearProfit: 423132, thisYearProfit: 312122},
            {product: 'Blue Band', lastYearSale: 38, thisYearSale: 5, lastYearProfit: 12321, thisYearProfit: 8500},
            {product: 'Blue T-Shirt', lastYearSale: 49, thisYearSale: 22, lastYearProfit: 745232, thisYearProfit: 65323},
            {product: 'Brown Purse', lastYearSale: 17, thisYearSale: 79, lastYearProfit: 643242, thisYearProfit: 500332},
            {product: 'Chakra Bracelet', lastYearSale: 52, thisYearSale: 65, lastYearProfit: 421132, thisYearProfit: 150005},
            {product: 'Galaxy Earrings', lastYearSale: 82, thisYearSale: 12, lastYearProfit: 131211, thisYearProfit: 100214},
            {product: 'Game Controller', lastYearSale: 44, thisYearSale: 45, lastYearProfit: 66442, thisYearProfit: 53322},
            {product: 'Gaming Set', lastYearSale: 90, thisYearSale: 56, lastYearProfit: 765442, thisYearProfit: 296232},
            {product: 'Gold Phone Case', lastYearSale: 75, thisYearSale: 54, lastYearProfit: 21212, thisYearProfit: 12533}
        ];

    }

    initForm(): void {
        this.form = this.fb.group({
            service: [null, Validators.required],
            active: [1],
            name: [null, Validators.required],
            types: [[1, 2, 3], Validators.required],
            markup: [0],
            purchasing: [0],
        });
    }

    calculateLastYearTotal() {
        let total = 0;
        for (const sale of this.sales) {
            total += sale.lastYearProfit;
        }

        this.lastYearTotal = total;
    }

    calculateThisYearTotal() {
        let total = 0;
        for (const sale of this.sales) {
            total += sale.thisYearProfit;
        }

        this.thisYearTotal = total;
    }

    serviceSelected(event) {
        console.log(event);
    }

    submit() {
        console.log(this.form.value);
    }
    getTypeName(id) {
        return this.listTypes.filter(x => x.id == id)[0].name;
    }
    markupChanged(event) {
        console.log(event);
    }
    createMarkupList(type) {
        this.markupList = this.listTypes.filter(x => x.id !== type);
        this.markupList.unshift({id: 0, name: 'Manual'});
    }
}
