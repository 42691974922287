import {Component, Input, OnInit} from '@angular/core';
import {CompaniesService} from '../../_services/companies-service';
import {ControlContainer, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DestinationsService} from '../../destination/_services/destinations.service';
import {AccountService} from '../../_services/account.service';
import {User} from '../../_models/user';
import {Area, City, Country, Region} from '../../_models/destinations';
import {ReplaySubject, Subscription} from 'rxjs';

@Component({
    selector: 'app-destination-form',
    templateUrl: './destination-form.component.html',
    styleUrls: ['./destination-form.component.css']
})
export class DestinationFormComponent implements OnInit {

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    form: UntypedFormGroup;
    @Input() parentForm: UntypedFormGroup;
    @Input() showArea = true;
    @Input() showPostalCode = true;
    @Input() showStreet = true;
    @Input() editArea = false;
    langKey: string;

    countriesLoaded = false;
    citiesLoaded = false;
    areasLoaded = false;
    public formSub: Subscription = new Subscription();

    private formDataSource = new ReplaySubject<any>(1);
    formData$ = this.formDataSource.asObservable();

    constructor(private companiesService: CompaniesService, private fb: UntypedFormBuilder,
                private controlContainer: ControlContainer,
                private destinationsService: DestinationsService,
                public accountService: AccountService) {
    }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((res: User) => {
            this.langKey = res.lang === 'de' ? 'name_de' : 'name_en';
        });
        this.initForm();
        this.getRegions();
        this.fillForm();
    }

    initForm() {
        this.form = this.controlContainer.control as UntypedFormGroup;
    }

    fillForm(second = false) {
        /*this.formSub.add(this.parentForm.valueChanges.subscribe(data => {
            console.log(second);
            console.log(data.destinations);
            if (data.destinations.country_id) {
                this.getCountries(data.destinations.country_id);
            }
            if (data.destinations.city_id) {
                this.getCities(data.destinations.city_id);
            }
            if (data.destinations.area_id) {
                this.getAreas(data.destinations.area_id);
            }
            if (data.destinations.region_id) {
                this.formSub.unsubscribe();
                setTimeout(() => {
                    this.fillForm(true);
                    console.log('here');
                }, 1000);
            }
        }));*/
        this.parentForm.valueChanges.subscribe(value => {
            this.formDataSource.next(value);
            this.formData$.subscribe(data => {
            });
        });
    }


    getRegions(id?: number) {
        if (id) {
            this.destinationsService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destinationsService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destinationsService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destinationsService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destinationsService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destinationsService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    getAreas(id?: number, cityId?: number) {
        if (id) {
            this.destinationsService.getArea(id).subscribe((res: any) => {
                this.areas = [res];
            });
        } else if (cityId && !this.areasLoaded) {
            this.destinationsService.getAreasList(cityId).subscribe((res: any) => {
                this.areas = res;
                this.areasLoaded = true;
            });
        }
    }

    regionChanged() {
        this.form.get('country_id').setValue(null);
        this.form.get('city_id').setValue(null);
        if (this.showArea) {
            this.form.get('area_id').setValue(null);
        }

        this.countries = [];
        this.cities = [];
        this.areas = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;
        this.areasLoaded = false;

        this.getCountries(null, this.form.get('region_id').value);
    }

    countryChanged() {
        this.form.get('city_id').setValue(null);
        if (this.showArea) {
            this.form.get('area_id').setValue(null);
        }

        this.cities = [];
        this.areas = [];

        this.citiesLoaded = false;
        this.areasLoaded = false;

        this.getCities(null, this.form.get('country_id').value);
    }

    cityChanged() {
        if (this.showArea) {
            this.form.get('area_id').setValue(null);
            this.areas = [];
            this.areasLoaded = false;
            this.getAreas(null, this.form.get('city_id').value);
        }
    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getAreaName(areaId) {
        const areaName = this.areas.filter(area => area.id === areaId)[0][this.langKey];
        return areaName;
    }
}

