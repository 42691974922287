import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {TravelAgency} from '../../_models/travel-agency';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TravelAgencyService {

    baseUrl = environment.apiUrl;
    agencies: TravelAgency[] = [];

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.baseUrl + 'travelagencies').pipe(map((res: any) => {
            this.agencies = res.travelagencies.data;
            return this.agencies;
        }));
    }

    getPaginatedAgencies(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'travelagencies?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + searchKeyword);
    }

    search(value: string) {
        return this.http.get(this.baseUrl + 'travelagencies?search=' + value);
    }

    getById(id) {
        /*const hotel = this.hotels.find(x => x.id == id);
        if (hotel !== undefined) return of(hotel);*/
        return this.http.get<TravelAgency>(this.baseUrl + 'travelagencies/' + id);
    }

    getByCode(code) {
        return this.http.get<TravelAgency>(this.baseUrl + 'travelagencies/get/all?ref_id=' + code);
    }

    add(obj) {
        return this.http.post(this.baseUrl + 'travelagencies', obj);
    }

    update(obj) {
        return this.http.put(this.baseUrl + 'travelagencies/' + obj.id, obj);
    }

    delete(id) {
        return this.http.delete(this.baseUrl + 'travelagencies/' + id);
    }

    getTravelTypes() {
        return this.http.get(this.baseUrl + 'dmcs/traveltypes/all');
    }
}
