<form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-8">
                <h5>{{'basic' | translate}}</h5>
            </div>
            <div class="p-col-4">
                <button *ngIf="form.get('id').value" pButton pRipple type="button" label="{{'delete' | translate}}"
                        class="p-button-outlined p-button-danger float-right" (click)="deleteRoom()"
                        styleClass="p-button-sm"></button>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <app-text-input label="Name"
                                [formControl]="form.get('name')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-text-input label="Code"
                                [formControl]="form.get('code')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-switch-input [label]="'website' | translate"
                                  [formControl]="form.get('show_website')"></app-switch-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-12">
                <h5>{{'roomFields' | translate}}</h5>
            </div>
        </div>
        <div class="pb-3" *ngFor="let field of fields.controls; let i = index; let last = last">
            <div class="p-grid" [ngClass]="i % 2 == 0 ? 'gray-bg' : null">
                <div class="p-col-12 p-md-12">
                    <h6>{{getFieldName(field.get('room_field_type_id').value)}}</h6>
                    <div *ngIf="field.get('is_html')?.value == 1" class="p-col-12 p-md-12 quill">
                        <div style="display: flow-root">
                            <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                            optionValue="id" optionLabel="name"
                                            [formControl]="field.get('is_html')"></p-selectButton>
                            <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                            [formControl]="field.get('language_mode')"
                                            styleClass="language-switch"></p-selectButton>
                        </div>
                        <app-editor [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                      [placeholder]="'Content'"></app-editor>
                    </div>
                    <div *ngIf="field.get('is_html').value == 0" class="p-col-12 p-md-12">
                        <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                        optionValue="id" optionLabel="name"
                                        [formControl]="field.get('is_html')"></p-selectButton>
                        <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                        [formControl]="field.get('language_mode')"
                                        styleClass="language-switch"></p-selectButton>
                        <textarea class="full-width" pInputText
                                  [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                  [placeholder]="'Content'"
                                  rows="4" autocomplete="false"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="facilities.controls.length > 0" class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-12">
                <h5>{{'roomFacilities' | translate}}</h5>
            </div>
        </div>
        <div class="p-grid mt-3">
            <div class="p-md-3"
                 *ngFor="let facility of  facilities.controls; let i=index">
                <app-switch-input [label]="facility.value.name"
                                  [formControl]="facility.get('available')"
                                  [disabled]="!form.get('id').value"></app-switch-input>
                <img *ngIf="facility.get('icon')?.value" [src]="'../../../../assets/services-icons/' +
                                        facility.get('icon')?.value + '.svg'"
                     width="22px" class="filter-petrol service-icon"/>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-12">
                <h5>{{'roomPhotos' | translate}}</h5>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <app-images-list [limit]="20" [count]="room?.images?.length"
                                 #uploader (delete)="deleteImage($event)"
                                 [images]="room?.images"
                                 [showMainIcon]="true"
                                 [showGallery]="room ? true : false"
                                 (mainImageChanged)="changeMainImage($event)"
                                 (onSelectImages)="uploadImages()"></app-images-list>
            </div>

        </div>
    </div>
    <div class="buttons" [ngStyle]="{'margin-bottom': (room ? '0px' : '70px')}">
        <button class="float-right" pButton
                type="submit">{{(room ? 'saveChanges' : 'create') | translate}}</button>
    </div>
</form>
