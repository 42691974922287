import {GGTreeNode, MixedNode, Options} from '../_models/GGTreeModel';
import {TreeNode} from 'primeng/api';

export class GgTree {
    options: Options;
    tree: GGTreeNode[];
    stack: MixedNode[] = [];

    constructor(options: Options) {
        this.options = options;
    }

    getTree(): GGTreeNode[] {
        const data = [...this.options.data];
        this.tree = this.buildTree(data);
        return this.tree;
    }

    getSelectedNodes(selectedNodes: any[], idKeyName: string): GGTreeNode[] {
        let treeSelectedNodes: GGTreeNode[] = [];
        selectedNodes.forEach(sNode => {
            for (const node of this.tree) {
                const path: GGTreeNode[] = [];
                const isFound = false;
                const searchResult = this.searchInTree(node, sNode[idKeyName], path, isFound, idKeyName);
                if (searchResult.isFound) {
                    treeSelectedNodes.push(...searchResult.path);
                    return searchResult.path;
                }
            }
        });

        // Remove Duplicates nodes
        treeSelectedNodes = [... new Set(treeSelectedNodes)];

        // remove parent node if its children are not all selected and set partialSelected property to true
        this.filterParentNodes(treeSelectedNodes);

        return treeSelectedNodes;
    }

    // Private
    private buildTree(data: MixedNode[]): GGTreeNode[] {
        if (this.options.filterPaths) {
            // filter data from nodes that has no leaves.
            let dataFiltered = [];
            dataFiltered = this.nodesFilter(data, data[data.length - 1], {isEmpty: true}).filter(node => node !== undefined);

            return this.mapNodesToGGTreeNode(dataFiltered);
        } else  {
            return this.mapNodesToGGTreeNode(data);
        }
    }

    private mapNodesToGGTreeNode(nodes: MixedNode[]): GGTreeNode[] {
        return nodes.map(node => {
            return {
                ...node,
                label: node.name_en,
                expandedIcon: 'pi pi-folder-open',
                collapsedIcon: 'pi pi-folder',
                key: `${node.id}`,
                children: this.buildChildren(node[node.children_key], `${node.id}`)
            } as GGTreeNode;
        }) as GGTreeNode[];
    }

    private buildChildren(branch: MixedNode[], parentKey: string): GGTreeNode[]  {
        return branch.map(bNode => {
            if (bNode.children_key) {
                return {
                    ...bNode,
                    label: bNode.name_en,
                    key: `${bNode.id}-${parentKey}`,
                    children: this.buildChildren(bNode[bNode.children_key], `${bNode.id}-${parentKey}`),
                    parent_key: parentKey
                } as GGTreeNode;
            } else if (!bNode.children_key) {
                return {
                    ...bNode,
                    label: bNode.name_en,
                    key: `${bNode.id}-${parentKey}`,
                    leaf: true,
                    parent_key: parentKey
                } as GGTreeNode;
            }
        });
    }

    private nodesFilter(nodes: MixedNode[], endNode: MixedNode, isEmptyRef: { isEmpty: boolean; }): MixedNode[] {
        let currNode: MixedNode;
        const emptyRef = {
            isEmpty: true
        };

        for ( const [i, n] of nodes.entries()) {
            currNode = n;
            if (n.children_key && n[n.children_key].length > 0) {
                this.stack.push(n);
                this.nodesFilter(n[n.children_key], endNode, emptyRef);
                if (emptyRef.isEmpty && n[n.children_key][0] === undefined) {
                    delete nodes[i];
                } else {
                    isEmptyRef.isEmpty = false;
                }
            } else if (!n.children_key) {
                // it is leaf do nothing!
                isEmptyRef.isEmpty = false;
            } else {
                delete nodes[i];
                isEmptyRef.isEmpty = true;
            }
        }
        if (endNode.id === currNode.id) {
            return nodes;
        }
    }

    getSelectedLeaves(selection: GGTreeNode[]): GGTreeNode[] {
        return selection.filter(node => node.leaf);
    }

    // Search helpers
    /**
     * private method to search in tree for a given value for "permission_id" and returns the path to this leaf.
     * @param node tree node to search in - init with root
     * @param value search value we are looking for
     * @param path array of tree nodes making the path to the search node
     * @param isFound is search value found or no
     * @param idKeyName node object key name to use to find node with - ex: [id, code, name, etc...]
     * @returns path and isFound
     */
    private searchInTree(
        node: GGTreeNode | any,
        value: string | number,
        path: GGTreeNode[],
        isFound: boolean,
        idKeyName: string): { path: GGTreeNode[], isFound: boolean } {

        path.push(node);
        if (node.children) {
            for (const childNode of node.children) {
                const searchResult = this.searchInTree(childNode, value, path, isFound, idKeyName);

                if (searchResult.isFound) {
                    return { path: searchResult.path, isFound: true };
                } else {
                    continue;
                }
            }
            path.pop();
            return { path, isFound: false };
        } else {
            if (node[idKeyName] === value) {
                return { path, isFound: true };
            } else {
                path.pop();
                return { path, isFound: false };
            }
        }
    }

    /**
     * filter selected nodes from partial selected nodes and mark partial selected nodes to the UI.
     * @param nodes selected nodes
     * @returns tree nodes array
     */
    private filterParentNodes(nodes: GGTreeNode[]): GGTreeNode[] {
        const leaves = nodes.filter(node => node.leaf);
        const partialSelected: GGTreeNode[] = [];

        leaves.forEach((leaf: any) => {
            let isAllExist = false;

            // get parent
            const parentIndex = nodes.findIndex(node => node.key === leaf.parent_key);

            // check if all children available in selected nodes array
            if (parentIndex > -1) {
                for (const child of nodes[parentIndex].children) {
                    if (nodes.findIndex(node => node.key === child.key) > -1) {
                        isAllExist = true;
                    } else {
                        isAllExist = false;
                        break;
                    }
                }
            }

            // if all siblings are not selected ---> remove all parents for this leaf
            if (!isAllExist && (parentIndex > -1)) {
                let parent = nodes[parentIndex];
                do {
                    // clone parent node
                    const cloneParent: any = { ...parent };
                    const newIndex = nodes.findIndex(node => node.key === cloneParent.key);
                    // remove parent node
                    partialSelected.push(...nodes.splice(newIndex, 1));
                    // find parent of parent
                    parent = nodes.filter((node) => node.key === cloneParent.parent_key)[0];
                } while (parent);
            }
        });

        // add partialSelect property to parents
        partialSelected.forEach((parent: any) => {
            this.tree.map(node => {
                if (node.key === parent.key) {
                    return node.partialSelected = true;
                } else {
                    // TODO: check in multi level children - now it checks only 1 level
                    // check in children
                    if (parent.parent_key && node.key === parent.parent_key) {
                        return node.children.map(child => {
                            if (child.key === parent.key) {
                                return parent.partialSelected = true;
                            }
                        });
                    }
                }
            });
        });

        return nodes;
    }
}

