import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class HttpErrrorHandler {
    constructor() { }

    handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError({
            msg: 'Something bad happened; please try again later.',
            error
        });
    }

    formatErrorMsg(err): string {
        let msg = '';
        const errorsObj = err.error?.error?.errors || err.error.errors;

        for (const key in errorsObj) {
            if (Object.prototype.hasOwnProperty.call(errorsObj, key)) {
                msg += key + ': ' + errorsObj[key] + ' ';
            }
        }

        return msg;
    }
}
