<form [formGroup]="userDataForm" (ngSubmit)="submitForm()" autocomplete="off">
    <div class="card toolbar-demo">
        <h5>User Details</h5>
        <div class="p-grid p-ai-center vertical-container">
            <div *ngIf="!isAddMode" class="p-col-12 p-md-3 text-center align-middle">
                <app-image-upload [image]="userData?.profile_image" [chooseLabel]="'Change profile picture'"
                                  (submitUpload)="updateUserPhoto($event)" #uploader
                                  (delete)="deleteUserPhoto()" [showDelete]="true"
                                  [disabled]="false"></app-image-upload>
            </div>
            <div [class]="isAddMode ? 'p-col-12 p-md-12' : 'p-col-12 p-md-9'">
                <div class="p-grid">
                    <div *ngIf="!isAddMode" class="p-col-12 p-md-1 half-rem-padding">
                        <div class="p-field">
                            <label>User ID</label>
                            <input class="full-width" type="text" pInputText formControlName="id">
                        </div>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <app-text-input [formControl]="userDataForm.controls['first_name']" [label]="'First name'"
                                        [type]="'text'"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <app-text-input [formControl]="userDataForm.controls['last_name']" [label]="'Last name'"
                                        [type]="'text'"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <div class="p-field">
                            <label>Mobile number</label>
                            <ngx-intl-tel-input
                                    [cssClass]="'ngx-phone'"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [selectFirstCountry]="false"
                                    [maxLength]="15"
                                    [customPlaceholder]="'Mobile number'"
                                    aria-autocomplete="none"
                                    formControlName="mobile_number"
                            ></ngx-intl-tel-input>
                            <small *ngIf="userDataForm.controls.mobile_number.touched && userDataForm.controls.mobile_number.errors?.required"
                                   class="p-error">{{'required' | translate}}</small>
                            <small class="p-error" *ngIf="userDataForm.controls.mobile_number.touched &&
                            userDataForm.controls.mobile_number.invalid">
                                Invalid number
                            </small>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <div class="p-field">
                            <label>Department</label>
                            <input class="full-width" type="text" pInputText formControlName="department">
                        </div>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <div class="p-field">
                            <label>Title</label>
                            <input class="full-width" type="text" pInputText formControlName="title">
                        </div>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <app-text-input [formControl]="userDataForm.controls['username']" [label]="'Username'"
                                        [type]="'text'"></app-text-input>
                    </div>
                    <div *ngIf="isAddMode" class="p-col-12 p-md-2">
                        <app-text-input [formControl]="userDataForm.controls['password']" [label]="'Password'"
                                        [type]="'password'"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <app-text-input [formControl]="userDataForm.controls['email']" [label]="'Email'"
                                        [type]="'email'"></app-text-input>
                    </div>
                    <div *ngIf="user.role_id === 2" class="p-col-12 p-md-2">
                        <app-select-input [options]="rolesList$ | async"
                                          [formControl]="userDataForm.controls['role_id']"
                                          [optionValue]="'id'" [optionLabel]="'name'"
                                          [label]="'Role'" [filter]="true"></app-select-input>
                    </div>
                    <div class="p-col-12 p-md-3 half-rem-padding">
                        <div class="p-field">
                            <label class="full-width">Company <span class="p-error">*</span></label>
                            <p-autoComplete class="autocomplete" [field]="'name'" formControlName="company_id"
                                            [suggestions]="companies" (onSelect)="getCompanyChildren($event.id)"
                                            (completeMethod)="searchCompanies($event)" [forceSelection]="true" delay="1000">
                                <ng-template let-company pTemplate="item">
                                    <div>{{company.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <small *ngIf="userDataForm.controls.company_id.touched && userDataForm.controls.company_id.errors?.required"
                                   class="p-error">{{'required' | translate}}</small>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-3 half-rem-padding">
                        <app-multi-select [options]="companyChildren" [label]="'Child'"
                        [formControl]="userDataForm.get('childs')" [optionLabel]="'name'"></app-multi-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button pButton class="float-right" type="submit" [label]="isAddMode ? 'Create' : ('saveChanges' | translate)"></button>
    </div>
</form>
