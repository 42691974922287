import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AccountService} from '../../_services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {PackageCmsService} from '../_services/package-cms.service';
import {Paginator} from "primeng/paginator";

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {

  pagination: any;
  searchValue = '';
  packages: any;
  first = 0;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('paginator', {static: false}) paginator: Paginator;

  constructor(private breadcrumbService: BreadcrumbService,
              private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute,
              public translate: TranslatePipe,
              public translateService: TranslateService,
              private packagesService: PackageCmsService,
              private confirmationService: ConfirmationService) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: this.translate.transform('packageCMS')}
    ]);
  }

  ngOnInit() {
    this.getPaginatedPackages(1, 10);
  }

  getPaginatedPackages(pageNumber, pagination, searchKeyword?, sortField?, sortOrder?) {
    this.packagesService
      .getPaginatedPackages(pageNumber, pagination, searchKeyword, sortField, sortOrder)
      .subscribe((res: any) => {
        this.packages = res.data;
        this.pagination = res.meta;
      });
  }

  loadPackagesLazy(event) {
    this.getPaginatedPackages(event.first / event.rows + 1,
      event.rows,
      event.filters?.global?.value,
      event.sortField,
      event.sortOrder);
  }

  addNew() {
    this.router.navigate(['add'], {relativeTo: this.route});
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  pageChanged(event) {
    this.getPaginatedPackages(event.page + 1, +event.rows);
  }

  edit(pack) {
    this.router.navigate(['edit/' + pack.id], {relativeTo: this.route});
  }

  delete(pack) {
    this.confirmationService.confirm({
      message: this.translate.transform('confirmMessage'),
      accept: () => {
        this.packagesService.deletePackage(pack.id).subscribe(res => {
          this.getPaginatedPackages(this.pagination.current_page, this.pagination.per_page);
        });
      },
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }
}
