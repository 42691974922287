<p-toast></p-toast>

<div class="card">
    <p-table #dt [value]="contacts" [rows]="10" [paginator]="true"
             [(selection)]="selectedContact" [rowHover]="true" dataKey="id"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">

                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                        (click)="addNew()" pTooltip="Add new contact" tooltipPosition="top"></button>

                <span class="p-input-icon-left float-right p-mr-1">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="title">Name
                    <p-sortIcon field="title"></p-sortIcon>
                </th>
                <th pSortableColumn="documentType">Email
                    <p-sortIcon field="documentType"></p-sortIcon>
                </th>
                <th pSortableColumn="expiryDate">Phone
                    <p-sortIcon field="expiryDate"></p-sortIcon>
                </th>
                <th pSortableColumn="notify">Department
                    <p-sortIcon field="notify"></p-sortIcon>
                </th>
                <th pSortableColumn="notify">Title
                    <p-sortIcon field="notify"></p-sortIcon>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contact let-index="rowIndex">
            <tr>
                <td style="min-width:160px; max-width: 160px; cursor: pointer" (click)="edit(contact)">{{contact.first_name + ' ' + contact.last_name}}</td>
                <td>{{contact.email}}</td>
                <td>{{contact.mobile_number}}</td>
                <td>{{contact.department}}</td>
                <td>{{contact.title}}</td>

                <td>
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" (click)="edit(contact)">Edit</a></li>
                            <li *ngIf="!contact.user_id" role="menuitem"><a class="dropdown-item" (click)="addAsUser(contact)">Add as user</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item"
                                                   (click)="delete(contact)">Delete</a></li>
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="showDialog" [header]="selectedContact ? 'Edit Contact' : 'Add New Contact'" [modal]="true"
          (onHide)="clearForm()"
          styleClass="p-fluid details-dialog">
    <form [formGroup]="contactForm" (ngSubmit)="submit()">
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="contactForm.get('first_name')" [label]="'First name'"
                                [type]="'text'"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="contactForm.get('last_name')" [label]="'Last name'"
                                [type]="'text'"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Mobile number <span class="p-error">*</span></label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Mobile number'"
                            aria-autocomplete="none"
                            formControlName="mobile_number"
                    ></ngx-intl-tel-input>
                    <small *ngIf="contactForm.controls.mobile_number.touched && contactForm.controls.mobile_number.errors?.required"
                           class="p-error">required</small>
                    <small class="p-error" *ngIf="contactForm.controls.mobile_number.touched && contactForm.controls.mobile_number.invalid">
                        Invalid number
                    </small>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="contactForm.get('email')" [label]="'Email'"
                                [type]="'email'"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Department</label>
                    <input class="full-width" type="text" pInputText [formControl]="contactForm.get('department')">
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Title</label>
                    <input class="full-width" type="text" pInputText [formControl]="contactForm.get('title')">
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <div class="buttons">
            <button pButton type="button" (click)="submit()" class="float-right">
                {{selectedContact ? ('saveChanges' | translate) : 'Create'}}
            </button>
        </div>
    </ng-template>
</p-dialog>

