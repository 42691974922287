import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../_services/account.service';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    dark: boolean;
    email: string;
    showResetForm = false;
    showPassword = false;

    constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParamMap.has('token')) {
            this.showResetForm = true;
        }
    }

    requestVerificationCode() {
        const obj = {
            email: this.email,
            route_url: window.location.origin + '/forgot-password'
        };
        this.accountService.requestVerificationCode(obj).subscribe(res => {
            this.messageService.add({severity: 'success', summary: 'Reset password',
                detail: 'A password reset link has been sent to your email'});
            this.router.navigateByUrl('/login');
        }, error => {
            this.messageService.add({severity: 'error', summary: 'Reset password',
                detail: 'You have entered a wrong email address!'});
        });
    }

    resetPassword(newPassForm: NgForm) {
        const obj = {
            ...newPassForm.value,
            token: this.route.snapshot.queryParamMap.get('token')
        };
        this.accountService.resetPassword(obj).subscribe(res => {
            this.messageService.add({severity: 'success', summary: 'Reset password',
                detail: 'Your password has been reset successfully'});
            this.router.navigateByUrl('/login');
        });
    }

}
