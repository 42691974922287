<p-toast></p-toast>
<p-confirmDialog key="deleteConfirm" rejectButtonStyleClass="p-button-text" acceptButtonStyleClass="p-button-danger"></p-confirmDialog>
<div class="card">
    <p-table #dt [value]="integrations$ | async" dataKey="id" editMode="row"
             [(selection)]="selectedIntegration"
             [rowHover]="true"
             [rows]="10"
             [paginator]="true"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showJumpToPageInput]="true" [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10, 50, 100, 200]"
             [globalFilterFields]="['name']"
             [scrollable]="true"
             [scrollDirection]="'both'"
    >
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">Integrations</h5>

                    <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-primary p-ml-2" (click)="addNew()" *ngIf="checkPermission('1636371483254')"></button>
                </div>

                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636371565464')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 60px"></th>
                <th style="width: 200px" pSortableColumn="company_name" class="p-d-flex p-jc-between p-ai-center">
                    Company
                    <p-sortIcon field="company_name"></p-sortIcon>
                    <app-filter fieldName="company_name" [filterOptions]="filters.company_name"></app-filter>
                </th>
                <th style="width: 200px" pSortableColumn="name">
                    Name
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="width: 200px">Description</th>
                <th style="width: 200px" pSortableColumn="expiry_date" class="p-d-flex p-jc-between p-ai-center">
                    Expiry Date
                    <p-sortIcon field="expiry_date"></p-sortIcon>
                    <p-columnFilter field="expiry_date" type="date" display="menu"></p-columnFilter>
                </th>
                <th style="width: 200px">API Key</th>
                <th style="width: 200px">
                    Status
                    <app-filter fieldName="status" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>
                </th>
                <th style="width: 8rem" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371499114') || checkPermission('1636371513530') || checkPermission('1636371530353')"
                >Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-integration let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="integration" [formGroup]="integForm" >
                <td style="width: 60px">
                    <p-tableCheckbox [value]="integration"></p-tableCheckbox>
                </td>
                <td style="width: 200px">
                    <p class="text-ellipsis">{{integration.company_name}}</p>
                </td>
                <td style="width: 200px">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="name">
                            <small *ngIf="integForm.get('name').touched && integForm.get('name').errors?.required" class="p-error">required</small>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <p class="text-ellipsis">{{integration.name}}</p>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width: 200px">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" formControlName="description">
                            <small *ngIf="integForm.get('description').touched && integForm.get('description').errors?.required" class="p-error">required</small>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <p class="text-ellipsis">{{integration.description}}</p>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width: 200px">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-calendar [showTime]="false" [showIcon]="true" formControlName="expiry_date" [touchUI]="true"></p-calendar>
                            <small *ngIf="integForm.get('expiry_date').touched && integForm.get('expiry_date').errors?.required" class="p-error">required</small>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{integration.expiry_date | date}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width: 200px">
                    <p class="text-ellipsis">{{integration.api_key}}</p>
                </td>
                <td style="width: 200px">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <app-switch-input [formControl]="integForm.get('status')"></app-switch-input>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{integration.status > 0 ? ('active' | translate) : ('inactive' | translate)}}
                        </ng-template>
                    </p-cellEditor>
                </td>

                <td style="width:8rem; text-align: center"  alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371499114') || checkPermission('1636371513530') || checkPermission('1636371530353')"
                >
                    <button *ngIf="!editing && checkPermission('1636371513530')" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(integration)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(integration, ri)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button #cancelEdit *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(integration, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                    <button *ngIf="checkPermission('1636371530353')" pButton pRipple type="button" icon="pi pi-trash" (click)="confirmDelete(integration, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>


<!--Create new Integration-->
<p-dialog [modal]="true" (onHide)="resetForm()"
          [(visible)]="showNewIntegrationDialog"
          header="Create API" [style]="{width: '50vw'}"
          [breakpoints]="{'960px': '75vw'}"
          [ngClass]="{'calendar-form': showNewIntegrationDialog, 'calendar-dialog': true}"
>
    <form [formGroup]="integForm" (ngSubmit)="submitIntegration()">
        <div class="p-grid">
            <div class="p-col-12 p-md-col" *ngIf="showNewIntegrationDialog">
                <label class="full-width">Company Name <span class="p-error">*</span></label>
                <p-autoComplete class="autocomplete" formControlName="company"
                                [suggestions]="companies$ | async"
                                (completeMethod)="searchCompanies($event)"
                                field="name" delay="1000"
                >
                    <ng-template let-company pTemplate="item">
                        <div>{{company.name}}</div>
                    </ng-template>
                </p-autoComplete>
                <small *ngIf="(integForm.get('company').touched && integForm.get('company').errors?.required) || (integForm.get('company').touched && !integForm.value.company.id)"
                       class="p-error">required</small>
            </div>

            <div class="p-col-12">
                <app-switch-input [inline]="true" [label]="integForm.get('status').value > 0 ? ('active' | translate) : ('inactive' | translate)" [formControl]="integForm.get('status')"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input label="Name" [formControl]="integForm.get('name')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <label>Expiry Date <span class="p-error">*</span> </label>
                <p-calendar class="full-width" formControlName="expiry_date"
                            [showTime]="false"
                            [showIcon]="true"
                ></p-calendar>
                <small *ngIf="integForm.controls['expiry_date'].touched && integForm.controls['expiry_date'].errors?.required" class="p-error">required</small>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input label="Description" [formControl]="integForm.get('description')"></app-text-input>
            </div>
        </div>

        <p-divider></p-divider>

        <div class="buttons">
            <div class="buttons">
                <button pButton type="submit">Create API</button>
            </div>
        </div>
    </form>
</p-dialog>
