import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ProductAPIService} from '../_services/product-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {ProductSeason} from '../../_models/product-season';
import {ProductService} from '../../_models/product-service';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {tap} from 'rxjs/operators';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-product-season',
  templateUrl: './product-season.component.html',
  styleUrls: ['./product-season.component.css']
})
export class ProductSeasonComponent implements OnInit {

  seasons$: Observable<ProductSeason[]>;
  selectedSeasons: ProductSeason[];

  // Filter vars
  tableHelper = new GgTableCustomFN();
  filters = {
    color: []
  };
  booleanFilter = [{name: this.translate.transform('active'), val: 1},
    {name: this.translate.transform('inactive'), val: 0}];
  companyId: number;

  constructor(
      private breadcrumbService: BreadcrumbService,
      private prodAPIService: ProductAPIService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private msgService: MessageService,
      private accountService: AccountService,
      public translate: TranslatePipe
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.parent.snapshot.paramMap.get('id')) {
      this.companyId = +this.activatedRoute.parent.snapshot.paramMap.get('id');
      this.breadcrumbService.setItems([
        {label: 'Dashboard', routerLink: '/'},
        {label: 'Companies', routerLink: '/admin/companies'},
        {label: 'Company Seasons'}
      ]);
      this.getCompanySeasons();
    } else {
      this.breadcrumbService.setItems([
        {label: 'Dashboard', routerLink: '/'},
        {label: 'Product Setup'},
        {label: 'Seasons'},
      ]);
      this.seasons$ = this.prodAPIService.getAllSeason().pipe(tap(seasons => {
        this.tableHelper.populateFilter(this.filters, seasons);
        seasons.forEach(season => {
          season.from = new Date(season.from);
          season.to = new Date(season.to);
        });
      }));
    }
  }
  getCompanySeasons() {
    this.seasons$ = this.prodAPIService.getCompanySeasons(this.companyId);
  }

  addNew(): void {
    if (this.companyId) {
      this.router.navigate(['product-setup/seasons/add'],
          {queryParams: {company_id: this.companyId}});
    } else {
      this.router.navigate(['product-setup/seasons/add']);
    }
  }

  edit(season: ProductSeason): void {
    this.router.navigate(['product-setup/seasons/edit', season.id],
        {queryParams: this.companyId ? ({'company_id': this.companyId}) : {}});
  }

  delete(season: ProductSeason): void {
    try {
      this.prodAPIService.deleteSeason(season.id).subscribe(res => {
        if (res) {
          this.seasons$ = this.prodAPIService.getAllSeason();
          this.msgService.add({
            severity: 'success',
            summary: 'Delete Season',
            detail: 'Season has been Deleted successfully!'
          });
        }
      });
    } catch (err) {
      console.error('delete Season error', err);
      this.msgService.add({
        severity: 'error',
        summary: 'Delete Season',
        detail: 'Something unexpected went wrong!'
      });
    }
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }
  exportExcel() {
    if (!this.selectedSeasons || this.selectedSeasons.length < 1) {
      this.msgService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedSeasons);
  }
}
