import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {SharedService} from './_services/shared.service';
import {User} from './_models/user';
import {AccountService} from './_services/account.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];
    user: User;

    constructor(public appMain: AppMainComponent, private sharedService: SharedService,
                private accountService: AccountService, private translationPipe: TranslatePipe) {
    }

    ngOnInit() {
        this.user = this.sharedService.getCurrentUser();
        setTimeout(() => {
            this.model = [
                {
                    label: 'Dashboard', svg: 'dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'],
                    permissionsData: {page_id: 23, type: 'page'}
                },

                {
                    label: this.translationPipe.transform('request'),
                    svg: 'request', icon: 'pi pi-fw pi-check-square', routerLink: ['/requests'],
                    permissionsData: {module_id: 3, type: 'module'}
                },
                /*{label: 'Booking', svg: 'frame', icon: 'pi pi-fw pi-check-square', routerLink: ['/booking']},
                {
                    label: 'Golf Courses', svg: 'frame', icon: 'pi pi-fw pi-th-large', routerLink: ['/golf-courses'],
                    permissionsData: {module_id: 5, type: 'module'},
                    items: [
                        {label: 'Courses', routerLink: '/golf-courses/courses', permissionsData: {page_id: 2, type: 'page'}},
                        {label: 'Calendar', routerLink: '/golf-courses/calendar', permissionsData: {page_id: 3, type: 'page'}}
                    ]
                },*/
                {
                    label: this.translationPipe.transform('golfCourses'),
                    svg: 'course', icon: 'pi pi-fw pi-th-large', routerLink: ['/golf-courses'],
                    permissionsData: {module_id: 5, type: 'module'}, allowedCompanies: [1, 3],
                },
                {
                    label: this.translationPipe.transform('golfHotels'),
                    svg: 'hotel', icon: 'pi pi-fw pi-credit-card', routerLink: ['/hotels'],
                    permissionsData: {module_id: 6, type: 'module'}, allowedCompanies: [1, 4],
                },
                {
                    label: this.translationPipe.transform('packageCMS'),
                    svg: 'product', icon: 'pi pi-fw pi-globe',
                    routerLink: ['/package-cms'],
                    permissionsData: {module_id: 18, type: 'module'}, allowedCompanies: [1],
                },
                {
                    label: 'DMC', svg: 'dmc', icon: 'pi pi-fw pi-credit-card', routerLink: ['/dmc'],
                    permissionsData: {module_id: 7, type: 'module'}, allowedCompanies: [1, 6],
                },
                {
                    label: this.translationPipe.transform('tourOperators'),
                    svg: 'tour', icon: 'pi pi-fw pi-map', routerLink: ['/tour-operators'],
                    permissionsData: {module_id: 8, type: 'module'}, allowedCompanies: [1, 5],
                },
                {
                    label: this.translationPipe.transform('travelAgencies'),
                    svg: 'agency', icon: 'pi pi-fw pi-globe',
                    routerLink: ['/travel-agencies'],
                    permissionsData: {module_id: 9, type: 'module'}, allowedCompanies: [1, 2],
                },
                {
                    label: this.translationPipe.transform('productSetup'),
                    svg: 'product',
                    icon: 'pi pi-fw pi-globe',
                    routerLink: ['/product-setup'],
                    allowedCompanies: [1],
                    permissionsData: {module_id: 10, type: 'module'},
                    items: [
                        /*{label: 'Contracts', routerLink: ['/product-setup/contracts'], permissionsData: {page_id: 8, type: 'page'}},
                        {label: 'Seasons', routerLink: ['/product-setup/seasons'], permissionsData: {page_id: 9, type: 'page'}},*/
                        {label: 'GFP', routerLink: ['/product-setup/gfp'], permissionsData: {page_id: 10, type: 'page'}},
                        /*{label: 'Hotel', routerLink: ['/product-setup/hotel-products'], permissionsData: {page_id: 11, type: 'page'},
                        notCompleted: true},
                        {label: 'Special Prices', routerLink: ['/product-setup/price-list'], notCompleted: true},
                        {label: 'Golf Holiday', routerLink: ['/product-setup/golf-holiday'], notCompleted: true},
                        {label: 'Price List', routerLink: ['/product-setup/price-list'], notCompleted: true},*/
                    ]
                },
                {
                    label: this.translationPipe.transform('destinations'),
                    svg: 'destination',
                    icon: 'pi pi-fw pi-send',
                    routerLink: ['/destinations'],
                    allowedCompanies: [1],
                    permissionsData: {module_id: 13, type: 'module'},
                    items: [
                        {
                            label: this.translationPipe.transform('destinations'),
                            routerLink: '/destinations', permissionsData: {page_id: 14, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('continents'),
                            routerLink: '/destinations/continents',
                            permissionsData: {page_id: 15, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('countries'),
                            routerLink: '/destinations/countries',
                            permissionsData: {page_id: 16, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('regions'),
                            routerLink: '/destinations/regions',
                            permissionsData: {page_id: 17, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('areas'),
                            routerLink: '/destinations/areas',
                            permissionsData: {page_id: 26, type: 'page'}
                        },
                    ]
                },
                {
                    label: this.translationPipe.transform('administrations'),
                    svg: 'admin',
                    icon: 'pi pi-fw pi-cog',
                    routerLink: ['/administrations'],
                    allowedCompanies: [1],
                    permissionsData: {module_id: 14, type: 'module'},
                    items: [
                        {
                            label: this.translationPipe.transform('companies'),
                            routerLink: '/admin/companies', permissionsData: {page_id: 18, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('users'),
                            routerLink: '/admin/users', permissionsData: {page_id: 19, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('roles'),
                            routerLink: '/admin/roles', permissionsData: {page_id: 20, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('permissions'),
                            routerLink: '/admin/permissions', permissionsData: {page_id: 21, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('reports'),
                            routerLink: '/admin/reports', permissionsData: {page_id: 27, type: 'page'}
                        },
                        {
                            label: this.translationPipe.transform('integrations'),
                            routerLink: '/admin/integrations', permissionsData: {page_id: 22, type: 'page'}
                        },
                    ]
                },
                /*{
                    label: 'Enquiry', svg: 'enquiry', icon: 'pi pi-fw pi-check-square', routerLink: ['/enquiry'],
                    permissionsData: {module_id: 2, type: 'module'}, allowedCompanies: [1],
                }*/
            ];
        }, 500);
    }


    onMenuClick() {
        this.appMain.menuClick = true;
    }

    isUserAllowed(item) {
        if (item.allowedCompanies && !item.allowedCompanies?.includes(this.user.company_type_id)) {
            return false;
        }
        return true;
    }

    checkPermission(data): boolean {
        if (data) {
            switch (data.type) {
                case 'module':
                    return this.accountService.checkModulePagePermission(data.module_id, 'module_id');
                    break;
                case 'page':
                    return this.accountService.checkModulePagePermission(data.page_id, 'page_id');
                    break;
                default:
                    break;
            }
        }

        return true;
    }
}
