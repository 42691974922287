<p-toast></p-toast>
<div class="card toolbar-demo">
  <div class="p-grid p-jc-between mt-2 mb-3">
    <div class="p-d-flex p-ai-center">
      <h5 class="p-mr-2">{{ translate.transform('packageCMS') }}</h5>
      <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
              (click)="addNew()" *ngIf="checkPermission('1691135761682')"></button>
    </div>
  </div>


  <p-table #dt [value]="packages"
           [rowHover]="true" dataKey="id"
           [lazy]="true"
           (onLazyLoad)="loadPackagesLazy($event)"
           [lazyLoadOnInit]="false"
           [paginator]="true"
           [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
           [showJumpToPageInput]="true" [first]="first"
           [rowsPerPageOptions]="[10, 50, 100, 200]" [showCurrentPageReport]="true"
           currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}">
    <ng-template pTemplate="caption">
      <div class="p-ai-center">

                    <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span>

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="davinci_booking_code">
          {{ 'davinciBookingCode' | translate }}
          <p-sortIcon field="davinci_booking_code"></p-sortIcon>
        </th>
        <th pSortableColumn="price_cache">
          {{ 'PriceCache' | translate }}
          <p-sortIcon field="price_cache"></p-sortIcon>
        </th>
        <th alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1691135928282') || checkPermission('1691135971422')"
        >{{ 'actions' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-package let-index>
      <tr>
        <td style="cursor: pointer"
            (click)="edit(package)">{{ package.name }}
        </td>
        <td>{{ package.davinci_booking_code }}</td>
        <td>
          <i *ngIf="!package.price_cache" class="pi pi-circle-fill" style="color: red"></i>
          <i *ngIf="package.price_cache" class="pi pi-circle-fill" style="color: green"
             pTooltip="{{package.price_cache}}" tooltipPosition="top"></i>
        </td>
        <td alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1691135928282') || checkPermission('1691135971422')"
        >
          <div class="btn-group" dropdown>
            <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                    class="p-button-rounded p-button-text"></button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngIf="checkPermission('1691135928282')"><a class="dropdown-item"
                                                                              (click)="edit(package)">{{ 'edit' | translate }}</a>
              </li>
              <li role="menuitem" *ngIf="checkPermission('1691135971422')"><a class="dropdown-item"
                                                                              (click)="delete(package)">{{ 'delete' | translate }}</a>
              </li>
            </ul>
          </div>

        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

