<p-toast></p-toast>
<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5 class="p-mb-4">{{isEditMode ? ('editArea' | translate) : ('addArea' | translate)}}</h5>
            </div>
            <div>
                <button pButton type="submit" class="float-right ml-3"
                        *ngIf="checkPermission('1678311266923')">
                    {{(isEditMode ? 'saveChanges' : 'create') | translate}}</button>
                <app-switch-input
                        [label]="form.controls['status'].value ? ('active' | translate) : ('inactive' | translate)"
                        [formControl]="form.controls['status']" [inline]="true" class="float-right">
                </app-switch-input>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <app-text-input [label]="'Name - EN'" [formControl]="form.controls['name_en']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-text-input [label]="'Name - DE'" [formControl]="form.controls['name_de']"></app-text-input>
            </div>

            <div class="p-col-12 p-md-4">
                <app-text-input
                        [formControl]="form.controls['code']"
                        [label]="'areaCode' | translate"
                        [type]="'text'">
                </app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <div class="p-field">
                    <label>{{'continent' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="regions" [formControl]="form.get('region_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                                placeholder="{{'selectContinent' | translate}}" [filter]="true"
                                filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getRegionName(form.get('region_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('region_id').touched && form.get('region_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-4">
                <div class="p-field">
                    <label>{{'country' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="countries" [formControl]="form.get('country_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()"
                                placeholder="{{'selectCountry' | translate}}" [filter]="true"
                                filterBy="name_en,name_de,code"
                                (onClick)="getCountries(null, form.get('region_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCountryName(form.get('country_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('country_id').touched && form.get('country_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-4">
                <div class="p-field">
                    <label>{{'region' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="cities" [formControl]="form.get('city_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true"
                                (onClick)="getCities(null, form.get('country_id').value)"
                                placeholder="{{'selectRegion' | translate}}" [filter]="true"
                                filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCityName(form.get('city_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                            + ' - ' + item?.code}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('city_id').touched && form.get('city_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="buttons">
        <button pButton type="submit" class="float-right">{{(isEditMode ? 'saveChanges' : 'create') | translate}}</button>
    </div>-->
</form>
