<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="operators$ | async" [rows]="10" [paginator]="true"
             [globalFilterFields]="['name', 'ref_id', 'company_name', 'assigned_user_name', 'created_by_user_name', 'region_name', 'country_name', 'city_name']"
             [scrollable]="true" [scrollDirection]="'both'"
             [(selection)]="selectedOperators" [rowHover]="true" dataKey="id"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showCurrentPageReport]="true" [showJumpToPageInput]="true" [rowsPerPageOptions]="[10, 50, 100, 200]">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">Tour Operators</h5>

                    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                            (click)="addNew()" pTooltip="Add new course" tooltipPosition="top" *ngIf="checkPermission('1636369659195')"></button>
                </div>
                <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                        (click)="deleteSelectedProducts()"
                        [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->


                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636369747082')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:60px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="name" class="p-d-flex p-jc-between p-ai-center">
                    Name
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="company_name" class="p-d-flex p-jc-between p-ai-center">
                    Company Name
                    <p-sortIcon field="company_name"></p-sortIcon>
                    <app-filter fieldName="company_name" [filterOptions]="filters.company_name"></app-filter>

                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="region_name" class="p-d-flex p-jc-between p-ai-center">
                    Continent
                    <p-sortIcon field="region_name"></p-sortIcon>
                    <app-filter fieldName="region_name" [filterOptions]="filters.region_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="country_name" class="p-d-flex p-jc-between p-ai-center">
                    Country
                    <p-sortIcon field="country_name"></p-sortIcon>
                    <app-filter fieldName="country_name" [filterOptions]="filters.country_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="city_name" class="p-d-flex p-jc-between p-ai-center">
                    Region
                    <p-sortIcon field="city_name"></p-sortIcon>
                    <app-filter fieldName="city_name" [filterOptions]="filters.city_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="city_name" class="p-d-flex p-jc-between p-ai-center">
                    Area
                    <p-sortIcon field="city_name"></p-sortIcon>
                    <app-filter fieldName="city_name" [filterOptions]="filters.area_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="ref_id" class="p-d-flex p-jc-between p-ai-center">
                    Ref. ID
                    <p-sortIcon field="ref_id"></p-sortIcon>
                </th>
                <!--<th style="min-width:160px; max-width: 160px;" pSortableColumn="assigned_user_name" class="p-d-flex p-jc-between p-ai-center">
                    assigned GG user
                    <p-sortIcon field="assigned_user_name"></p-sortIcon>
                    <app-filter fieldName="assigned_user_name" [filterOptions]="filters.assigned_user_name"></app-filter>
                </th>-->
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="created_at" class="p-d-flex p-jc-between p-ai-center">
                    Creation date
                    <p-sortIcon field="created_at"></p-sortIcon>
                    <p-columnFilter field="created_at" type="date" display="menu"></p-columnFilter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="created_by_user_name" class="p-d-flex p-jc-between p-ai-center">
                    Created by
                    <p-sortIcon field="created_by_user_name"></p-sortIcon>
                    <app-filter fieldName="created_by_user_name" [filterOptions]="filters.created_by_user_name"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" class="p-d-flex p-jc-between p-ai-center">
                    Status
                    <app-filter fieldName="active" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>
                </th>
                <th style="width:60px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636369674313') || checkPermission('1636369691269') || checkPermission('1636369717295')"
                >Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-operator let-index>
            <tr>
                <td style="width:60px">
                    <p-tableCheckbox [value]="operator"></p-tableCheckbox>
                </td>
                <td style="min-width:160px; max-width: 160px;cursor: pointer" (click)="edit(operator)">{{operator.name}}</td>
                <td style="min-width:160px; max-width: 160px;;">{{operator.company_name}}</td>
                <td style="min-width:160px; max-width: 160px;">{{operator.region?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{operator.country?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{operator.city?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{operator.area?.name_en}}</td>
                <td style="min-width:160px; max-width: 160px;">{{operator.ref_id}}</td>
                <!--<td style="min-width:160px; max-width: 160px;">{{operator.assigned_user_name}}</td>-->
                <td style="min-width:160px; max-width: 160px;">{{operator.created_at | date: 'dd.MM.yyyy'}}</td>
                <td style="min-width:160px; max-width: 160px;">{{operator.created_by_user_name}}</td>
                <td class="status-td" style="min-width:160px; max-width: 160px;">
                    <span class="status-txt">{{operator.active == 1 ? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td style="width:60px"  alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636369674313') || checkPermission('1636369691269') || checkPermission('1636369717295')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngIf="checkPermission('1636369691269')"><a class="dropdown-item" (click)="edit(operator)">Edit</a></li>
                            <li role="menuitem" *ngIf="checkPermission('1636369717295')"><a class="dropdown-item" (click)="delete(operator, index)">Delete</a></li>
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-dialog header="{{'DeleteTO' | translate}}" [(visible)]="showDeletePrevent" (onHide)="showDeletePrevent = false">
    {{'TONoDeleteMsg' | translate}}
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showDeletePrevent = false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
