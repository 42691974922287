
<div class="card">

  <p-table #dt [value]="hotels" [scrollable]="true" [scrollDirection]="'both'"
           [(selection)]="selectedHotels" [rowHover]="true" dataKey="id"
           [lazy]="true"
           (onLazyLoad)="getPaginatedHotels($event)"
           [lazyLoadOnInit]="false"
           [paginator]="true"
           [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
           [showJumpToPageInput]="true" [first]="first"
           [rowsPerPageOptions]="[10, 50, 100, 200]" [showCurrentPageReport]="true"
           currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}">
    <ng-template pTemplate="caption">
      <div class="p-ai-center">

        <button pButton pRipple label="{{'export' | translate}}" icon="pi pi-upload" class="p-button-help float-right"
                (click)="exportExcel()" *ngIf="checkPermission('1636369505984')"></button>

        <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span>
        <div class="p-field float-right mr-3">
          <label class="mr-2 mt-2">{{'golfGlobeHotels' | translate}}</label>
          <p-triStateCheckbox (onChange)="valueChanged($event)" [(ngModel)]="isGolfHotelModel"
          styleClass="hotel-tri-check"></p-triStateCheckbox>
        </div>


      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width:80px; max-width: 80px;" class="p-d-flex p-jc-between p-ai-center">
          Top
          <p-columnFilter field="top" matchMode="equals" display="menu"
                          [showClearButton]="false" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false"
                          [showApplyButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [options]="topStatuses" [ngModel]="value" placeholder="Any"
                          (onChange)="filter($event.value)">
                <ng-template let-status pTemplate="group">
                  <div class="flex align-items-center">
                    <span>{{ translate.transform(status) }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>
        <th style="width: 90px;">
          <div class="flex justify-content-center align-items-center">
            <!--{{'publish' | translate}}-->Web
            <p-columnFilter field="publish" matchMode="equals" display="menu"
                            [showClearButton]="false" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false"
                            [showApplyButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [options]="publishStatuses" [ngModel]="value" placeholder="Any"
                            (onChange)="filter($event.value)">
                  <ng-template let-status pTemplate="group">
                    <div class="flex align-items-center">
                      <span>{{ translate.transform(status) }}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="name"
            class="p-d-flex p-jc-between p-ai-center">
          Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="company_name"
            class="p-d-flex p-jc-between p-ai-center">
          {{'companyName' | translate}}
          <p-sortIcon field="company_name"></p-sortIcon>
          <!--<app-filter fieldName="company_name" [filterOptions]="filters.company_name"></app-filter>-->
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="letter_code"
            class="p-d-flex p-jc-between p-ai-center">
          {{'lettersCode' | translate}}
          <p-sortIcon field="letter_code"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" class="p-d-flex p-jc-between p-ai-center">
          {{'contractType' | translate}}
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="region"
            class="p-d-flex p-jc-between p-ai-center">
          {{'continent' | translate}}
          <p-sortIcon field="region"></p-sortIcon>
          <!--<app-filter fieldName="region_name" [filterOptions]="filters.region_name"></app-filter>-->
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="country"
            class="p-d-flex p-jc-between p-ai-center">
          {{'country' | translate}}
          <p-sortIcon field="country"></p-sortIcon>
          <!--<app-filter fieldName="country_name" [filterOptions]="filters.country_name"></app-filter>-->
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="city"
            class="p-d-flex p-jc-between p-ai-center">
          {{'region' | translate}}
          <p-sortIcon field="city"></p-sortIcon>
          <!--<app-filter fieldName="city_name" [filterOptions]="filters.city_name"></app-filter>-->
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="area"
            class="p-d-flex p-jc-between p-ai-center">
          {{'area' | translate}}
          <p-sortIcon field="area"></p-sortIcon>
          <!--<app-filter fieldName="city_name" [filterOptions]="filters.city_name"></app-filter>-->
        </th>
        <th style="min-width:80px; max-width: 80px;" class="p-d-flex p-jc-between p-ai-center">
          ID
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="ref_id"
            class="p-d-flex p-jc-between p-ai-center">
          Ref. ID
          <p-sortIcon field="ref_id"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="booking_code"
            class="p-d-flex p-jc-between p-ai-center">
          {{'SPId' | translate}}
          <p-sortIcon field="booking_code"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="davinci_booking_code"
            class="p-d-flex p-jc-between p-ai-center">
          DaVinci ID
          <p-sortIcon field="davinci_booking_code"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="created_at"
            class="p-d-flex p-jc-between p-ai-center">
          {{'creationDate' | translate}}
          <p-sortIcon field="created_at"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" pSortableColumn="created_by_user_name"
            class="p-d-flex p-jc-between p-ai-center">
          {{'createdBy' | translate}}
          <p-sortIcon field="created_by_user_name"></p-sortIcon>
        </th>
        <th style="min-width:160px; max-width: 160px;" class="p-d-flex p-jc-between p-ai-center">
          Status
        </th>
        <th style="width:60px" alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1636369442591') || checkPermission('1636369458287') || checkPermission('1636369471964')"
        >{{'actions' | translate}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-hotel let-index>
      <tr>
        <td style="width:80px">
          <p-inputSwitch class="switch-input" (onChange)="topChanged(hotel)"
                         [trueValue]="1" [falseValue]="0"
          name="top" [(ngModel)]="hotel.top"></p-inputSwitch>
        </td>
        <td style="width: 90px;">
          <i class="{{hotel.show_website == 1 && !hotel.is_publish_required ? 'pi pi-check-circle' :
          (hotel.show_website == 1 && hotel.is_publish_required ? 'pi pi-hourglass' : 'pi pi-times-circle')}}"
             [pTooltip]="hotel.show_website == 1 && !hotel.is_publish_required ? 'Published' :
          (hotel.show_website == 1 && hotel.is_publish_required ? 'Pending publish' : 'Unpublished')"
             tooltipPosition="top"></i>
        </td>
        <td style="min-width:160px; max-width: 160px;cursor: pointer"
            (click)="edit(hotel)">{{hotel.name}}</td>
        <td style="min-width:160px; max-width: 160px;;">{{hotel.company_name}}</td>
        <td style="min-width:160px; max-width: 160px;">{{hotel.letter_code}}</td>
        <td style="min-width:160px; max-width: 160px;">
          {{hotel.direct_contract == 1 ? 'Direct contract' : null}}<br *ngIf="hotel.direct_contract">
          {{hotel.via_dmc == 1 ? 'Via DMC' : null}}
        </td>
        <td style="min-width:160px; max-width: 160px;">
          {{translateService.currentLang === 'de' ? hotel.region?.name_de : hotel.region?.name_en}}
        </td>
        <td style="min-width:160px; max-width: 160px;">
          {{translateService.currentLang === 'de' ? hotel.country?.name_de : hotel.country?.name_en}}
        </td>
        <td style="min-width:160px; max-width: 160px;">
          {{translateService.currentLang === 'de' ? hotel.city?.name_de : hotel.city?.name_en}}
        </td>
        <td style="min-width:160px; max-width: 160px;">
          {{translateService.currentLang === 'de' ? hotel.area?.name_de : hotel.area?.name_en}}
        </td>
        <td class="status-td" style="min-width:80px; max-width: 80px;">
          <span class="status-txt">{{hotel.id}}</span>
        </td>
        <td style="min-width:160px; max-width: 160px;"><span class="word-break">{{hotel.ref_id}}</span></td>
        <td style="min-width:160px; max-width: 160px;"><span class="word-break">{{hotel.booking_code}}</span></td>
        <td style="min-width:160px; max-width: 160px;"><span class="word-break">{{hotel.davinci_booking_code}}</span></td>
        <!--<td style="min-width:160px; max-width: 160px;">{{hotel.assigned_user_name}}</td>-->
        <td style="min-width:160px; max-width: 160px;">{{hotel.created_at | date: 'dd.MM.yyyy'}}</td>
        <td style="min-width:160px; max-width: 160px;">{{hotel.created_by_user_name}}</td>
        <td class="status-td" style="min-width:160px; max-width: 160px;">
          <span class="status-txt">{{hotel.active == 1 ? ('active' | translate) : ('inactive' | translate)}}</span>
        </td>
        <td style="width:60px" alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1636369442591') || checkPermission('1636369458287') || checkPermission('1636369471964')"
        >
          <div class="btn-group" dropdown>
            <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                    class="p-button-rounded p-button-text"></button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngIf="checkPermission('1636369458287')"><a class="dropdown-item"
                                                                              (click)="edit(hotel)">{{'edit' | translate}}</a>
              </li>
              <li role="menuitem" *ngIf="checkPermission('1636369471964')"><a class="dropdown-item"
                                                                              (click)="delete(hotel)">{{'delete' | translate}}</a>
              </li>
            </ul>
          </div>

        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-dialog header="{{'DeleteHO' | translate}}" [(visible)]="showDeletePrevent" (onHide)="showDeletePrevent = false">
  {{'HONoDeleteMsg' | translate}}
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="showDeletePrevent = false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
