import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TourOperatorComponent} from './tour-operator/tour-operator.component';
import {AddTourOperatorComponent} from './tour-operator/add-tour-operator/add-tour-operator.component';
import {AuthGuard} from '../_guards/auth.guard';

const routes: Routes = [
  {path: '', redirectTo: './', pathMatch: 'full'},
  {
    path: '', component: TourOperatorComponent, canActivate: [AuthGuard],
    data: {requirePermission: true, code: '1636369642795', type: 'action'}
  },
  {
    path: 'add', component: AddTourOperatorComponent, canActivate: [AuthGuard],
    data: {requirePermission: true, code: '1636369659195', type: 'action'}
  },
  {
    path: 'edit/:id', component: AddTourOperatorComponent, canActivate: [AuthGuard],
    data: {requirePermission: true, code: '1636369691269', type: 'action'}
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TourOperatorRoutingModule { }
