import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {ProductService} from '../../../_models/product-service';
import GgTableCustomFN from '../../../_classes/ggTableCustomFN';
import {ProductAPIService} from '../../_services/product-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-product-services-table',
  templateUrl: './product-services-table.component.html',
  styleUrls: ['./product-services-table.component.css']
})
export class ProductServicesTableComponent implements OnInit {

  services$: Observable<ProductService[]>;
  services: ProductService[];
  selectedServices: ProductService[];
  pagination: any;

  // Filter vars
  tableHelper = new GgTableCustomFN();
  searchValue = '';
  companyId: number;

  constructor(
      private prodAPIService: ProductAPIService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private msgService: MessageService,
      private accountService: AccountService,
      private confirmationService: ConfirmationService, private translate: TranslatePipe
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.parent.snapshot.paramMap.get('id')) {
      this.companyId = +this.activatedRoute.parent.snapshot.paramMap.get('id');
      this.getCompanyContracts();
    } else {
      this.getServices(1, 10, this.searchValue);
    }
  }
  getServices(pageNumber, pagination, searchKeyword) {
    this.prodAPIService.getPaginatedServices(pageNumber, pagination, searchKeyword).subscribe((res: any) => {
      this.services = res.services.data;
      this.pagination = res.services.meta;
    });
  }

  getCompanyContracts() {
    this.prodAPIService.getServicesByCompany(this.companyId).subscribe((res: any) => {
      this.services = res;
    });
  }

  edit(service: ProductService): void {
    this.router.navigate(['product-setup/contracts/edit', service.id]);
  }

  delete(service: ProductService): void {
    this.confirmationService.confirm({
      message: this.translate.transform('confirmMessage'),
      accept: () => {
        this.prodAPIService.deleteService(service.id).subscribe(res => {
          if (res) {
            this.getServices(this.pagination.current_page, this.pagination.per_page,
                this.searchValue);
            this.msgService.add({
              severity: 'success',
              summary: 'Delete Service',
              detail: 'Service has been Deleted successfully!'
            });
          }
        });
      },
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedServices || this.selectedServices.length < 1) {
      this.msgService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedServices);
  }
  pageChanged(event) {
    this.getServices(event.page + 1, +event.rows, this.searchValue);
  }
  searchServices() {
    this.getServices(1, this.pagination.per_page, this.searchValue);
  }
}
