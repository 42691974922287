import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DestinationsService} from '../_services/destinations.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {SharedService} from '../../_services/shared.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FileUpload} from 'primeng/fileupload';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-testimonies',
    templateUrl: './testimonies.component.html',
    styleUrls: ['./testimonies.component.css']
})
export class TestimoniesComponent implements OnInit {

    testimoniesForm: UntypedFormGroup;
    @Input() testimonies: any;
    @Output() testimonyAdded: EventEmitter<any> = new EventEmitter<any>();
    showEmptyMsg = false;
    displayForm = false;
    currentTestimony: any;
    imageToShow: any;
    @ViewChild('uploader', {static: false}) uploader: FileUpload;

    constructor(private fb: UntypedFormBuilder, private destService: DestinationsService, private sharedService: SharedService,
                private confirmationService: ConfirmationService, private messageService: MessageService,
                private sanitizer: DomSanitizer, private translate: TranslatePipe) {
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.testimoniesForm = this.fb.group({
            name: [null, Validators.required],
            text: [null, Validators.required]
        });
    }

    submitTestimony() {
        if ((!this.currentTestimony && (this.uploader.files.length === 0 || !this.testimoniesForm.valid)) ||
            (this.currentTestimony && (!this.currentTestimony?.image || !this.testimoniesForm.valid))) {
            this.testimoniesForm.markAllAsTouched();
            this.showEmptyMsg = true;
            return false;
        }
        if (this.currentTestimony) {
            const oldTestimony = {
                name: this.currentTestimony.name,
                text: this.currentTestimony.text,
            };
            if (!this.sharedService.checkObjectChanges(oldTestimony, this.testimoniesForm.value)) {
                const obj = {...this.testimoniesForm.value, id: this.currentTestimony.id};
                this.destService.editTestimony(obj).subscribe((res: any) => {
                    this.messageService.add({
                        severity: 'success', summary: this.translate.transform('updateTestimony'),
                        detail: this.translate.transform('updateTestimonyMessage')
                    });
                    const testimony = this.testimonies.filter(x => x.id === this.currentTestimony.id)[0];
                    testimony.name = res.testimony.name;
                    testimony.text = res.testimony.text;
                    this.displayForm = false;
                });
            } else {
                this.displayForm = false;
            }
        } else {
            const formData: FormData = new FormData();
            formData.append('name', this.testimoniesForm.value.name);
            formData.append('text', this.testimoniesForm.value.text);
            this.uploader.files.forEach(file => {
                formData.append('image', file);
            });
            this.testimonyAdded.emit(formData);
        }
    }

    openAddTestimony() {
        this.currentTestimony = null;
        this.initForm();
        this.showEmptyMsg = false;
        this.displayForm = true;
    }

    deleteTestimony(i) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.destService.deleteTestimony(this.testimonies[i].id).subscribe((res: any) => {
                    this.testimonies.splice(i, 1);
                    this.messageService.add({
                        severity: 'success', summary: this.translate.transform('deleteTestimony'),
                        detail: this.translate.transform('deleteTestimonyMessage')
                    });
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    editTestimony(i) {
        this.currentTestimony = this.testimonies[i];
        this.testimoniesForm.patchValue(this.currentTestimony);
        this.displayForm = true;
    }

    deleteImage() {
        this.currentTestimony.image = null;
        /*this.destService.deleteTestimonyImage(this.currentTestimony.id).subscribe((res: any) => {
            this.currentTestimony.image = '';
            this.testimonies.filter(testimony => testimony.id == this.currentTestimony.id)[0].image = '';
        });*/
    }

    uploadTestimonyImage() {
        if (this.currentTestimony) {
            this.updateTestimonyImage();
        } else {
            this.showEmptyMsg = false;
        }
    }

    updateTestimonyImage() {
        const formData: FormData = new FormData();
        this.uploader.files.forEach(file => {
            formData.append('image', file);
        });
        this.destService.uploadTestimonyImage(this.currentTestimony.id, formData).subscribe((res: any) => {
            this.currentTestimony = res.testimony;
            this.testimonies.filter(testimony => testimony.id == this.currentTestimony.id)[0].image = res.testimony.image;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('updateTestimony'),
                detail: this.translate.transform('updateTestimonyMessage')
            });
        });
    }

    afterAdd() {
        this.displayForm = false;
        this.testimoniesForm.reset();
    }

    imageSelected(event) {
        if (this.currentTestimony) {
            this.uploadTestimonyImage();
        }
    }
}
