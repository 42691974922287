import * as FileSaver from 'file-saver';

export default class GgTableCustomFN {
    constructor() { }

    populateFilter(filterObj, values) {
        for (const key in filterObj) {
            if (filterObj.hasOwnProperty(key)) {
                filterObj[key] = [... new Set(values.map(item => item[key]))];
            }
        }
    }

    exportExcel(jsonDataToExport?: any[]) {
        if (!jsonDataToExport) {
            throw new Error(('No Data to export'));
        }

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(jsonDataToExport);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'products');
        });
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
