import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {GolfCoursesService} from '../../../_services/golf-courses.service';
import {Lookup} from '../../../../_models/lookup';
import {Company} from '../../../../_models/company';
import {CompaniesService} from '../../../../_services/companies-service';
import {ActivatedRoute} from '@angular/router';
import {GolfCourse} from '../../../../_models/golf-course';
import {User} from '../../../../_models/user';
import {UsersServices} from '../../../../_services/users-services';
import {ImagesListComponent} from '../../../../_shared/images-list/images-list.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Hotel} from '../../../../_models/hotel';
import {HotelsService} from '../../../../hotels/_services/hotels.service';
import {AccountService} from '../../../../_services/account.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {SharedService} from '../../../../_services/shared.service';
import {Area, City, Country, Region} from '../../../../_models/destinations';
import {DestinationsService} from '../../../../destination/_services/destinations.service';
@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit, OnChanges {

    generalForm: UntypedFormGroup;
    golfCourse: GolfCourse = {links_hd_images: [], links_logo_images: []};
    golfClubs: Company[];
    hotels: Hotel[];
    handlerTypes: Lookup[];
    users: User[];
    @ViewChild('uploader', {static: true}) imagesUploader: ImagesListComponent;
    invalidControls: any[];
    fieldTypes: any[];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    showCompanyInfoButton = true;
    @Input() golfCourseId: number;
    companies: Company[];
    selectedCompany: Company;
    currentUser: User;
    styles: Lookup[];
    ratingOptions: Lookup[] = [];
    basics: any;
    timePattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    langKey: string;
    countriesLoaded = false;
    citiesLoaded = false;
    areasLoaded = false;

    constructor(private breadcrumbService: BreadcrumbService, private coursesServices: GolfCoursesService,
                private fb: UntypedFormBuilder, private companiesServices: CompaniesService, private route: ActivatedRoute,
                private usersServices: UsersServices, private messageService: MessageService,
                private companiesService: CompaniesService, private confirmationService: ConfirmationService,
                private hotelService: HotelsService, private accountService: AccountService,
                private translate: TranslatePipe, public sharedService: SharedService,
                public translateService: TranslateService, private destinationsService: DestinationsService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('golfCourses'), routerLink: '/golf-courses'},
            {label: this.translate.transform('editGolfCourse')},
        ]);
    }

    ngOnInit(): void {
        this.langKey = this.translateService.currentLang === 'de' ? 'name_de' : 'name_en';
        this.accountService.currentUser$.subscribe(res => {
            this.currentUser = res;
        });
        this.loadDependencies();
    }

    loadDependencies() {
        this.initForm();
        this.getBasics();
        this.initOptions();
        this.getHandlerTypes();
        this.getGolfClubs();
        this.getRegions();
    }

    async getBasics() {
        if (this.route.snapshot.parent.data?.basics) {
            this.basics = this.route.snapshot.parent.data?.basics[0];
        } else {
            await this.loadBasics();
        }
        this.styles = this.basics.styles;
        this.styles = this.getTranslatedName(this.styles);
        this.getGolfCourseById();
    }

    async loadBasics() {
        await this.coursesServices.getBasics().toPromise().then(res => {
            this.basics = res;
        });
    }

    getTranslatedName(list: any) {
        list.forEach(item => {
            if (this.translateService.currentLang === 'de') {
                item.name = item.translations[1].name;
            } else {
                item.name = item.translations[0].name;
            }
        });
        return list;
    }

    initOptions() {
        for (let i = 10; i >= 1; i--) {
            const option: Lookup = {id: i, name: i.toString(), code: null};
            this.ratingOptions.push(option);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.generalForm) {
            this.loadDependencies();
        }
        this.getGolfCourseById();
    }

    initForm() {
        this.generalForm = this.fb.group({
            id: [null],
            company: [null, Validators.required],
            company_id: [null],
            company_name: [null],
            hotel: [null],
            hotel_id: [null],
            hotel_name: [null],
            ref_id: [null],
            tui_ref_code: [null],
            giata_code: [null],
            reservation_email: [null, Validators.email],
            letter_code: [null, [Validators.minLength(3), Validators.maxLength(3)]],
            name: [null, Validators.required],
            designer: [null],
            active: [0],
            direct_contract: [0],
            via_dmc: [0],
            via_hotel: [0],
            delegate_name: [null],
            delegate_email: [null, Validators.email],
            delegate_mobile_number: [null],
            assigned_user: [null],
            assigned_user_id: [null],
            assigned_user_name: [null],
            region_id: new UntypedFormControl(null, Validators.required),
            country_id: new UntypedFormControl(null, Validators.required),
            city_id: new UntypedFormControl(null, Validators.required),
            area_id: new UntypedFormControl(null),
            street: new UntypedFormControl(null),
            postal_code: new UntypedFormControl(null),
            website_link: [null],
            phone: [null],
            fax: [null],
            email: [null, Validators.required],
            is_company_address: [0],
            booking_code: {value: null, disabled: true},
            davinci_booking_code: null,
            davinici_codes: this.fb.array([]),
            golf_course_style_id: [null],
            course_rating: [null],
            club_rating: [null],
            academy: [null],
            pros: [null],
            start_time: [null, [Validators.pattern(this.timePattern)]],
            end_time: null,
        });
    }

    getHandlerTypes() {
        this.companiesService.getCompanyTypes().subscribe((res: any) => {
            this.handlerTypes = res;
        });
    }

    get form() {
        return this.generalForm.controls;
    }


    getGolfCourseById() {
        this.coursesServices.getGolfCourseById(this.golfCourseId ? this.golfCourseId :
            this.route.parent.snapshot.paramMap.get('id')).subscribe((res: any) => {
            this.golfCourse = res.golfcourse;
            this.fetchDestinations(this.golfCourse);
            this.selectedCompany = this.golfCourse.company;
            this.coursesServices.course = this.golfCourse;
            this.golfCourse.start_time = this.golfCourse?.start_time?.slice(0, -3);
            this.golfCourse.end_time = this.golfCourse?.end_time?.slice(0, -3);
            this.companyInfoButtonClick({checked: this.golfCourse.is_company_address});
            this.generalForm.patchValue(this.golfCourse);

            ////////////// Fetch Users /////////////////
            this.generalForm.controls.company.setValue({
                id: this.generalForm.value.company_id,
                name: this.generalForm.value.company_name
            });
            this.generalForm.controls.assigned_user.setValue({
                id: this.generalForm.value.assigned_user_id,
                full_name: this.generalForm.value.assigned_user_name
            });
            this.generalForm.controls.hotel.setValue({
                id: this.generalForm.value.hotel_id,
                name: this.generalForm.value.hotel_name
            });
            ////////////// Fetch Users /////////////////

            // FILL BOOKING CODES
            if (this.golfCourse.davinici_codes.length > 0) {
                this.fillDaVinciBookingCodes();
            } else {
                this.addDaVinciBookingCode();
            }
        });
    }

    fillDaVinciBookingCodes() {
        this._DaVinciBookingCodes.clear();
        this.golfCourse.davinici_codes.forEach((code: any) => {
            this._DaVinciBookingCodes.push(this.fb.control(code.davinici_code));
        });
    }

    getGolfClubs() {
        this.companiesServices.getGolfClubs().subscribe((res: any) => {
            this.golfClubs = res.companies.data;
        });
    }


    searchUsers(event) {
        this.usersServices.searchUsers(event.query).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    searchCompanies(event) {
        this.companiesServices.searchCompaniesByType(event.query, 3).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    public findInvalidControls() {
        this.invalidControls = [];
        const controls = this.generalForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalidControls.push(name.replace(/_/g, ' '));
            }
        }
    }

    get _DaVinciBookingCodes() {
        return this.generalForm.get('davinici_codes') as UntypedFormArray;
    }

    removeDaVinciBookingCode(i) {
        this._DaVinciBookingCodes.removeAt(i);
    }

    addDaVinciBookingCode() {
        this._DaVinciBookingCodes.push(this.fb.control(null));
    }

    submitForm() {
        if (!this.generalForm.valid) {
            this.generalForm.markAllAsTouched();
            this.findInvalidControls();
            return false;
        }
        this.invalidControls = [];
        const formValue = {...this.generalForm.getRawValue(), ...this.generalForm.getRawValue().destinations};
        if (formValue.delegate_mobile_number != null) {
            formValue.delegate_mobile_number = formValue.delegate_mobile_number
                ?.internationalNumber?.toString().replace(/\s/g, '');
        }
        if (formValue.phone != null) {
            formValue.phone = formValue.phone?.internationalNumber?.toString().replace(/\s/g, '');
        }
        if (formValue.fax != null) {
            formValue.fax = formValue.fax?.internationalNumber?.toString().replace(/\s/g, '');
        }

        formValue.hotel_id = formValue.hotel?.id;

        formValue.assigned_user_id = formValue.assigned_user?.id;

        this.coursesServices.updateGolfCourse(this.golfCourse.id, formValue).subscribe((res: any) => {
            this.golfCourse = res.golfcourse;
            this.coursesServices.course = this.golfCourse;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('updateGolfCourseMessage')
            });
            this.areasLoaded = this.countriesLoaded = this.citiesLoaded = false;
        });
    }

    searchHotels(event) {
        this.hotelService.search(event.query).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    isParentChanged(event) {
        this.showCompanyInfoButton = false;
        if (event.checked) {
            this.generalForm.get('company').setValue(null);
            this.generalForm.get('company').disable();
            this.generalForm.get('company').clearValidators();
            if (this.generalForm.get('is_company_address').value == 1) {
                this.generalForm.get('is_company_address').setValue(0);
                this.clearCompanyInfo();
                this.disabilitySwitch(false);
            }
        } else {
            this.showCompanyInfoButton = true;
            this.generalForm.get('company').enable();
            this.generalForm.get('company').addValidators(Validators.required);
            this.clearCompanyInfo();
        }
    }

    async companyInfoButtonClick(event) {
        if (event.checked == 1) {
            this.disabilitySwitch(true);
            if (this.selectedCompany) {
                this.fillCompanyInfo(this.selectedCompany);
            }
        } else {
            this.disabilitySwitch(false);
        }
    }

    async companySelected(company) {
        this.selectedCompany = company;
        if (this.generalForm.get('is_company_address').value == 1) {
            this.fillCompanyInfo(company);
        }
    }

    fillCompanyInfo(company: Company) {
        this.generalForm.get('region_id').setValue(company.region_id);
        this.generalForm.get('country_id').setValue(company.country_id);
        this.generalForm.get('city_id').setValue(company.city_id);
        this.generalForm.get('area_id').setValue(company.area?.id);
        this.generalForm.get('street').setValue(company.street);
        this.generalForm.get('postal_code').setValue(company.postal_code);
        this.generalForm.get('website_link').setValue(company.website);
        this.generalForm.get('phone').setValue(company.phone);
        this.generalForm.get('fax').setValue(company.fax);
        this.generalForm.get('email').setValue(company.email);
        this.fetchDestinations(company);
    }

    clearCompanyInfo() {
        this.generalForm.get('region_id').setValue(null);
        this.generalForm.get('country_id').setValue(null);
        this.generalForm.get('city_id').setValue(null);
        this.generalForm.get('area_id').setValue(null);
        this.generalForm.get('street').setValue(null);
        this.generalForm.get('postal_code').setValue(null);
        this.generalForm.get('website_link').setValue(null);
        this.generalForm.get('phone').setValue(null);
        this.generalForm.get('fax').setValue(null);
        this.generalForm.get('email').setValue(null);
    }

    disabilitySwitch(disable: boolean) {
        disable ? this.generalForm.get('region_id').disable() : this.generalForm.get('region_id').enable();
        disable ? this.generalForm.get('country_id').disable() : this.generalForm.get('country_id').enable();
        disable ? this.generalForm.get('city_id').disable() : this.generalForm.get('city_id').enable();
        disable ? this.generalForm.get('area_id').disable() : this.generalForm.get('area_id').enable();
        disable ? this.generalForm.get('street').disable() : this.generalForm.get('street').enable();
        disable ? this.generalForm.get('postal_code').disable() : this.generalForm.get('postal_code').enable();
        disable ? this.generalForm.get('phone').disable() : this.generalForm.get('phone').enable();
        disable ? this.generalForm.get('fax').disable() : this.generalForm.get('fax').enable();
        disable ? this.generalForm.get('email').disable() : this.generalForm.get('email').enable();
        disable ? this.generalForm.get('website_link').disable() : this.generalForm.get('website_link').enable();
    }


    //////////////// Destination Section ///////////////
    getRegions(id?: number) {
        if (id) {
            this.destinationsService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destinationsService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destinationsService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destinationsService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destinationsService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destinationsService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    getAreas(id?: number, cityId?: number) {
        if (id) {
            this.destinationsService.getArea(id).subscribe((res: any) => {
                this.areas = [res];
            });
        } else if (cityId && !this.areasLoaded) {
            this.destinationsService.getAreasList(cityId).subscribe((res: any) => {
                this.areas = res;
                this.areasLoaded = true;
            });
        }
    }

    regionChanged() {
        this.generalForm.get('country_id').setValue(null);
        this.generalForm.get('city_id').setValue(null);
        this.generalForm.get('area_id').setValue(null);

        this.countries = [];
        this.cities = [];
        this.areas = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;
        this.areasLoaded = false;

        this.getCountries(null, this.generalForm.get('region_id').value);
    }

    countryChanged() {
        this.generalForm.get('city_id').setValue(null);
        this.generalForm.get('area_id').setValue(null);

        this.cities = [];
        this.areas = [];

        this.citiesLoaded = false;
        this.areasLoaded = false;

        this.getCities(null, this.generalForm.get('country_id').value);
    }

    cityChanged() {
        this.generalForm.get('area_id').setValue(null);
        this.areas = [];
        this.areasLoaded = false;
        this.getAreas(null, this.generalForm.get('city_id').value);

    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getAreaName(areaId) {
        const areaName = this.areas.filter(area => area.id === areaId)[0][this.langKey];
        return areaName;
    }

    fetchDestinations(item) {
        this.getCountries(item.country_id);
        this.getCities(item.city_id);
        if (item.area?.id) {
            this.getAreas(item.area?.id);
        }
    }

    //////////////// Destination Section ///////////////
    viewCompany() {
        window.open(window.location.origin + '/admin/companies/edit/' + this.generalForm.get('company').value.id,
            '_blank');
    }
}
