<div class="card toolbar-demo">
    <div class="p-grid">
        <div class="p-col-6">
            <h5>{{this.pageName | translate}}</h5>
        </div>
        <div class="p-col-6">
            <button pButton type="button" (click)="submitForm()" class="float-right mr-4">{{'saveChanges' | translate}}</button>
        </div>
    </div>
    <div class="p-grid">
        <ng-container *ngFor="let service of newForm.controls; let i = index">
            <div class="p-col-3">
                <div class="p-field">
                    <label class="full-width">
                        {{service.get('name').value}}</label>

                    <p-inputSwitch class="switch-input" [formControl]="service.get('selected_option')"
                                   [trueValue]="1" [falseValue]="0"></p-inputSwitch>
                    <img *ngIf="service.get('icon').value" [src]="'../../../../assets/services-icons/' +
                                        service.get('icon').value + '.svg'"
                         width="22px" class="filter-petrol service-icon"/>
                </div>
            </div>
        </ng-container>
    </div>
    <!--<div class="buttons mt-1">
        <button pButton type="button" (click)="submitForm()" class="float-right mr-4">{{'saveChanges' | translate}}</button>
    </div>-->
</div>

<!--
<form *ngIf="services.length > 0" [formGroup]="servicesForm" (ngSubmit)="submitForm()">
    <div class="p-grid mt-4 frame">
        <div class="col-3 service-menu scroll">
            <div *ngFor="let service of services; let i = index" class="service-menu-item"
                 (click)="getServiceDetails(service)"
                 [ngClass]="currentService.name == service.name ? 'active-menu-item' : null">
                {{service.name}}
                <img *ngIf="service.icon" [src]="'../../../assets/services-icons/' + service.icon + '.svg'"
                     width="18px" class="filter-white service-menu-icon"/>
                <i *ngIf="isServicesExists(service)" class="pi pi-check-circle"></i>
            </div>
        </div>
        <div class="col-9 service-data scroll">

            <div class="p-grid" [formGroupName]="createPropertyName(currentService.translations[0].name)">
                &lt;!&ndash; SERVICE HEADER &ndash;&gt;
                <div class="p-col-10">
                    <img *ngIf="currentService.icon" [src]="'../../../assets/services-icons/' + currentService.icon + '.svg'"
                         width="18px" class="filter-petrol service-title-icon"/>
                    <label class="service-title">{{currentService.translations[0].name}}</label>
                </div>
                <div class="p-col-2" *ngIf="currentService.view_type == 'boolean'">
                    <app-switch-input [label]="'Available'" (changed)="availableChange($event)"
                                      [formControl]="serviceFormObject.controls.selected_option">
                    </app-switch-input>
                </div>
                <div class="p-col-2" *ngIf="currentService.view_type == 'select'">
                    <app-select-input [label]="'Available'" [options]="fetchRadioOptions(currentService.options)"
                                      (optionChanged)="availableChange($event)"
                                      [formControl]="serviceFormObject.controls.selected_option"></app-select-input>
                </div>
                &lt;!&ndash;<div class="p-col-2">
                    <app-switch-input [label]="'Website'"
                                      [formControl]="serviceFormObject.controls.show_website">
                    </app-switch-input>
                </div>&ndash;&gt;
                &lt;!&ndash; SERVICE HEADER &ndash;&gt;
                &lt;!&ndash; FEES SECTION &ndash;&gt;
                <div class="p-col-1">
                    <app-text-input [label]="'Quantity'" [type]="'number'"
                                    [formControl]="serviceFormObject.controls.qty"></app-text-input>
                </div>
                <div class="p-col-1">
                    <app-text-input [label]="'Fees'" [type]="'number'"
                                    [formControl]="serviceFormObject.controls.fees"></app-text-input>
                </div>
                <div class="p-col-10">
                    <app-text-area [label]="'Notes'"
                                   [formControl]="serviceFormObject.controls.notes"></app-text-area>
                </div>
                &lt;!&ndash; FEES SECTION &ndash;&gt;
            </div>
            &lt;!&ndash; PROPERTIES SECTION &ndash;&gt;
            <div *ngIf="currentService.properties.length > 0" class="full-width">
                <p-divider></p-divider>
                <div class="p-grid">
                    <div class="p-col-3"
                         *ngFor="let property of _properties.controls; let i = index">
                        <app-switch-input *ngIf="currentService.properties[i].view_type == 'boolean'"
                                          [label]="currentService.properties[i].name" [formControl]="property.get('selected_option')">
                        </app-switch-input>

                        <app-text-input *ngIf="currentService.properties[i].view_type == 'number'" [type]="'number'"
                                        [label]="currentService.properties[i].name"
                                        [formControl]="property.get('selected_option')">
                        </app-text-input>

                        <app-text-area *ngIf="currentService.properties[i].view_type == 'textarea'"
                                       [label]="currentService.properties[i].name"
                                       [formControl]="property.get('selected_option')">
                        </app-text-area>

                        <app-select-input *ngIf="currentService.properties[i].view_type == 'select'"
                                          [label]="currentService.properties[i].name"
                                          [options]="fetchRadioOptions(currentService.properties[i].options)"
                                          [formControl]="property.get('selected_option')"></app-select-input>

                        <div class="p-field" *ngIf="currentService.properties[i].view_type == 'radio'">
                            <label>{{currentService.properties[i].translations[0].name}}</label>
                            <p-selectButton [options]="fetchRadioOptions(currentService.properties[i].options)"
                                            styleClass="contract-checkbox"
                                            [formControl]="property.get('selected_option')"></p-selectButton>
                        </div>
                    </div>
                </div>
            </div>
            &lt;!&ndash; PROPERTIES SECTION &ndash;&gt;
            &lt;!&ndash; FEES DETAILS SECTION &ndash;&gt;
            <div *ngIf="currentService.fees.length > 0" class="full-width mt-5">
                <p-divider></p-divider>
                <h5 class="mb-3">FEE DETAILS</h5>
                <div class="p-grid" *ngFor="let fee of _feesDetails.controls let i =  index">
                    <div class="p-col-1">
                        <p class="fee-title">{{fetchRadioOptions(currentService.fees[0].unit_options)[i]}}</p>
                    </div>
                    <div class="p-col-1">
                        <app-text-input [type]="'number'" [label]="'Quantity'"
                                        [formControl]="fee.get('qty')">
                        </app-text-input>
                    </div>
                    <div class="p-col-1">
                        <app-text-input [type]="'number'" [label]="'Fees'"
                                        [formControl]="fee.get('fees')">
                        </app-text-input>
                    </div>
                    <div class="p-col-9">
                        <app-text-area [label]="'Notes'" [formControl]="fee.get('notes')"></app-text-area>
                    </div>
                </div>
            </div>
            &lt;!&ndash; FEES DETAILS SECTION &ndash;&gt;
            &lt;!&ndash; ADDONS SECTION &ndash;&gt;
            <div *ngIf="currentService.addons.length > 0" class="full-width mt-5">
                <p-divider></p-divider>
                <h5 class="mb-3">SERVICE ADDONS</h5>
                <div *ngFor="let addon of _addons.controls; let i = index">
                    <p-fieldset [legend]="currentService.addons[i].name" styleClass="services-fieldset">
                        <div class="p-grid p-jc-end">
                            <div class="p-col-2">
                                <app-switch-input *ngIf="currentService?.addons[i]?.view_type == 'boolean'"
                                                  [label]="'Available'" [formControl]="addon.get('selected_option')">
                                </app-switch-input>

                                <app-select-input *ngIf="currentService?.addons[i]?.view_type == 'select'"
                                                  [label]="currentService.addons[i].name"
                                                  [options]="fetchRadioOptions(currentService.addons[i].options)"
                                                  [formControl]="addon.get('selected_option')"></app-select-input>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-1">
                                <app-text-input [label]="'Quantity'" [type]="'number'"
                                                [formControl]="addon.get('qty')"></app-text-input>
                            </div>
                            <div class="p-col-1">
                                <app-text-input [label]="'Fees'" [type]="'number'"
                                                [formControl]="addon.get('fees')"></app-text-input>
                            </div>
                            <div class="p-col-10">
                                <app-text-area [label]="'Notes'" [formControl]="addon.get('notes')"></app-text-area>
                            </div>
                        </div>

                        &lt;!&ndash; ADDONS FEES START &ndash;&gt;
                        <div *ngIf="currentService.addons[i].fees.length > 0" class="full-width mt-2">
                            <h5>Addon Fees</h5>
                            <div class="p-grid" *ngFor="let addonFee of _addonsFees(i).controls; let n = index">
                                <div class="p-col-1">
                                    <p class="fee-title">{{fetchRadioOptions(currentService.fees[0].unit_options)[n]}}</p>
                                </div>
                                <div class="p-col-1">
                                    <app-text-input [type]="'number'" [label]="'Quantity'"
                                                    [formControl]="addonFee.get('qty')">
                                    </app-text-input>
                                </div>
                                <div class="p-col-1">
                                    <app-text-input [type]="'number'" [label]="'Fees'"
                                                    [formControl]="addonFee.get('fees')">
                                    </app-text-input>
                                </div>
                                <div class="p-col-9">
                                    <app-text-area [label]="'Notes'"
                                                   [formControl]="addonFee.get('notes')"></app-text-area>
                                </div>
                            </div>
                        </div>
                        &lt;!&ndash; ADDONS FEES END &ndash;&gt;
                    </p-fieldset>
                </div>
                &lt;!&ndash; ADDONS SECTION &ndash;&gt;
            </div>
        </div>
    </div>
    <div class="buttons mt-1">
        <button pButton type="submit" class="float-right mr-4">{{'saveChanges' | translate}}</button>
    </div>
</form>
-->
