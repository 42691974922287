import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {DestinationsService} from '../../../destination/_services/destinations.service';
import {ProductAPIService} from '../../_services/product-api.service';
import {Company} from '../../../_models/company';
import {City, Country} from '../../../_models/destinations';
import {Observable} from 'rxjs';
import {CompaniesService} from '../../../_services/companies-service';
import {ProductService} from '../../../_models/product-service';
import {Lookup} from '../../../_models/lookup';
import {HotelsService} from '../../../hotels/_services/hotels.service';
import {SharedService} from '../../../_services/shared.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AccountService} from '../../../_services/account.service';
import {User} from '../../../_models/user';
import {map} from 'rxjs/operators';
import {RoutingState} from '../../../_services/route-state.service';

@Component({
    selector: 'app-product-services-form',
    templateUrl: './product-services-form.component.html',
    styleUrls: ['./product-services-form.component.css']
})
export class ProductServicesFormComponent implements OnInit, OnChanges {

    isEditMode = false;
    form: UntypedFormGroup;
    service: ProductService;
    bookingOptions = ['Hotel', 'Region'];
    serviceHandlerTypes: Lookup[] = [];
    bookings: Company[] = [];
    countries: Country[];
    cities: City[] = [];
    companies: Company[];
    invoicingCompanies: Company[];
    serviceHandlersTypesIds = [3, 4, 6];
    serviceHandlers: Company[];
    providers: Company[] = [];
    yearRange: string;
    @Input() serviceId: number;
    company: Company;
    user: User;
    previousRoute: string;

    constructor(
        private fb: UntypedFormBuilder, private routingState: RoutingState,
        private breadcrumbService: BreadcrumbService, private accountService: AccountService,
        private route: ActivatedRoute, private sharedService: SharedService,
        private msgService: MessageService, private hotelServices: HotelsService,
        private destinationService: DestinationsService, private ngxService: NgxUiLoaderService,
        private prodAPIService: ProductAPIService, private messageService: MessageService,
        private router: Router, private companiesServices: CompaniesService
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Contracts', routerLink: '/product-setup/contracts'},
            {label: this.route.snapshot.paramMap.get('id') ? 'Edit Contract' : 'New Contract'}
        ]);
        this.yearRange = `${new Date().getFullYear()}:${new Date().getFullYear() + 15}`;
    }

    async ngOnInit() {
        this.previousRoute = this.routingState.getPreviousUrl();
        this.accountService.currentUser$.subscribe(res => {
            this.user = res;
        });
        this.getCountries();
        this.initForm();
        await this.getCompanyTypes();
        if (this.serviceId || this.route.snapshot.paramMap.get('id')) {
            this.fetchData();
        } else if (this.route.snapshot.queryParamMap.get('company_id')) {
            this.companiesServices.getCompany(+this.route.snapshot.queryParamMap.get('company_id'))
                .subscribe(res => {
                    this.company = res;
                    this.form.get('company').setValue({id: this.company?.id, name: this.company?.name});
                    this.form.get('company').disable();
                    this.companyChanged(this.company);
                });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.form) {
            this.form.reset();
            this.initForm();
        }
        this.fetchData();
    }

    initForm(): void {
        this.form = this.fb.group({
            id: null,
            name: [null, Validators.required],
            company: [{value: null, disabled: this.user.company_type_id !== 1 ? true : false}, Validators.required],
            type: [null],
            provider: [{value: null, disabled: true}],
            country_id: [{value: null, disabled: true}, Validators.required],
            cities: [null, Validators.required],
            code: [{value: null, disabled: true}],
            booking_code: [{value: null, disabled: true}],
            letter_code: [null, [Validators.maxLength(3), Validators.minLength(3)]],
            validity_from: [new Date(), Validators.required],
            validity_to: [new Date(new Date().setFullYear(new Date().getFullYear() + 1)), Validators.required],
            invoice_handler_id: null,
            service_handler_type_id: [null, Validators.required],
            service_handler_id: [{value: null, disabled: true}],
            booking_possible_for: [null, Validators.required],
            booking_from_id: [{value: null, disabled: true}],
            hotels: [{value: null, disabled: true}],
            use_destination_hotel: 0,
            active: 1,
        });
    }

    getCountries() {
        this.destinationService.getCountriesList().subscribe(res => {
            this.countries = res;
        });
    }

    async getCompanyTypes() {
        await this.companiesServices.getCompanyTypes().toPromise().then((res: any) => {
            this.serviceHandlersTypesIds.forEach(type => {
                this.serviceHandlerTypes.push(res.filter(x => x.id === type)[0]);
            });
        });
    }

    searchCompaniesByTypeList(event) {
        this.companiesServices.searchCompaniesByTypeList(event.query, this.serviceHandlersTypesIds)
            .toPromise().then((res: any) => {
            this.companies = res.companies;
        });
    }

    searchCompanies(event) {
        this.companiesServices.searchCompaniesMin(event.query).subscribe((res: any) => {
            this.invoicingCompanies = res.companies;
        });
    }

    getChildren(value) {
        if (value) {
            this.companiesServices.getAllCompanyChildren(value.id).toPromise().then((res: any) => {
                this.form.get('provider').enable();
                this.providers = res;
                if (this.service?.provider_id) {
                    this.form.controls.provider.setValue(this.providers.filter(x => x.id == this.service.provider_id)[0]);
                }
                this.form.get('provider').updateValueAndValidity();
            });
        } else {
            this.form.get('provider').disable();
        }
    }

    async fetchData() {
        this.isEditMode = true;
        const serviceId = this.serviceId ? this.serviceId : +this.route.snapshot.paramMap.get('id');
        this.service = await this.prodAPIService.getService(serviceId).toPromise();
        //////////// RESET BREADCRUMB ///////////////
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {
                label: 'Contracts', routerLink: 'admin/companies/edit/' +
                    this.service.company_id + '/contracts'
            },
            {label: this.route.snapshot.paramMap.get('id') ? 'Edit Contract' : 'New Contract'}
        ]);
        //////////// RESET BREADCRUMB ///////////////
        this.service.validity_to = new Date(this.service.validity_to);
        this.service.validity_from = new Date(this.service.validity_from);
        this.form.patchValue(this.service);

        await this.populateCity(this.service);
        if (this.service.booking_possible_for === 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }

        this.form.controls.invoice_handler_id.setValue({
            id: this.service.invoice_handler_id,
            name: this.service.invoice_handler_name
        });
        this.form.controls.company.setValue({
            id: this.service.company_id,
            name: this.service.company_name
        });
        this.getChildren({id: this.service.company_id});
        ////////////// Fetch Hotels /////////////////
        const hotelsObj = this.service.hotels.map(hotel => {
            return hotel.id;
        });
        this.form.controls.hotels.setValue(hotelsObj);
        ////////////// Fetch Hotels /////////////////
        ////////////// Fetch Cities /////////////////
        const cities = this.cities.filter(city =>
            this.form.controls['cities'].value.some(serviceCity => city.id === serviceCity.id));
        this.form.get('cities').setValue(cities);
        ////////////// Fetch Cities /////////////////
        ////////////// SET SERVICE HANDLER /////////////////
        if (this.service.service_handler_type_id === 6 ||
            (this.service.service_handler_type_id === 4 && this.service.use_destination_hotel === 0)) {
            this.getServiceHandlers(this.service.service_handler_type_id);
            this.switchServiceHandler(true);
        }
        ////////////// Fetch Hotels /////////////////
        if (this.service.booking_possible_for == 'Hotel') {
            this.form.get('hotels').enable();
            this.form.get('hotels').setValidators(Validators.required);
            this.bookingPossibleChanged('Hotel');
        }
    }

    async populateCountry(company) {
        this.form.get('country_id').setValue(company.country_id);
        this.populateCity(company);
    }

    async populateCity(company) {
        await this.destinationService.getCitiesList(company.country_id).toPromise().then(res => {
            this.cities = res;
        });
        if (this.form.get('booking_possible_for').value == 'Hotel') {
            this.bookingPossibleChanged('Hotel');
        }
    }

    countryChanged(event) {
        this.form.get('hotels').setValue(null);
        this.form.get('cities').setValue(null);
        this.bookings = null;
        if (event == null) {
            this.cities = null;
        } else {
            this.destinationService.getCitiesList(event).toPromise().then(res => {
                this.cities = res;
            });
            const providerCity = this.form.get('provider').value?.city_id || this.form.get('company').value?.city_id;
            if (providerCity) {
                this.form.get('cities').setValue([providerCity]);
            }
            if (this.form.get('booking_possible_for').value == 'Hotel') {
                this.bookingPossibleChanged('Hotel');
            }
        }
    }

    async companyChanged(value) {
        if (value == null) {
            this.clearAlternatives();
            this.providers = null;
            this.form.get('provider').setValue(null);
            this.form.get('booking_code').setValue(null);
        } else {
            this.form.get('invoice_handler_id').setValue({id: value.id, name: value.name});
            this.form.get('service_handler_type_id').setValue(value.company_type_id);
            this.form.get('booking_code').setValue(value.booking_code);
            if (value.company_type_id !== 3) {
                this.serviceHandlerTypeChanged(value.company_type_id);
                this.form.get('service_handler_id').setValue(value.id);
            }
            await this.getChildren(value);
            this.populateCountry(value);
        }
    }

    providerChanged(value) {
        this.clearAlternatives();
        if (value == null) {
            if (this.companies) {
                const company = this.companies.filter(x => x.id == this.form.get('company').value.id)[0];
                this.form.get('booking_code').setValue(company.booking_code);
                this.populateCountry(company);
            } else {
                this.companiesServices.getCompany(this.form.get('company').value.id).subscribe(res => {
                    this.form.get('booking_code').setValue(res.booking_code);
                    this.populateCountry(res);
                });
            }
        } else {
            this.populateCountry(this.form.get('provider').value);
            this.form.get('booking_code').setValue(this.form.get('provider').value.booking_code);
        }
    }

    clearAlternatives() { // clear country - city - booking from
        this.bookings = [];
        this.form.get('country_id').setValue(null);
        this.form.get('cities').setValue(null);
        if (this.form.get('booking_possible_for').value == 'Hotel') {
            this.form.get('hotels').setValue(null);
        }
    }

    serviceHandlerTypeChanged(value) {
        this.form.get('service_handler_id').setValue(null);
        if (value === 3 || value == null) { // golf club or null
            this.switchServiceHandler(false);
            this.form.get('use_destination_hotel').setValue(0);
        } else if ((value === 6 || (value === 4 && this.form.get('use_destination_hotel').value === 0))) { // hotel selected
            this.switchServiceHandler(true);
            this.getServiceHandlers(value);
        }
    }

    getServiceHandlers(type) {
        this.companiesServices.getAllCompaniesMinByType(type).subscribe((res: any) => {
            this.serviceHandlers = res.companies;
        });
    }

    switchServiceHandler(value) {
        if (value) {
            if (!this.serviceHandlers) {
                this.getServiceHandlers(this.form.get('service_handler_type_id').value);
            }
            this.form.get('service_handler_id').enable();
            this.form.get('service_handler_id').setValidators(Validators.required);
            this.form.get('service_handler_id').updateValueAndValidity();
        } else {
            this.form.get('service_handler_id').disable();
            this.form.get('service_handler_id').removeValidators(Validators.required);
            this.form.get('service_handler_id').updateValueAndValidity();
        }
    }

    citiesChanged() {
        if (this.form.get('booking_possible_for').value === 'Hotel') {
            this.form.get('hotels').setValue(null);
            this.bookingPossibleChanged('Hotel');
        }
    }

    async bookingPossibleChanged(value) {
        if (value === 'Hotel') {
            if (this.form.controls['cities'].value?.length > 0) {
                let citiesIds = '';
                this.form.controls['cities'].value.forEach(city => citiesIds += 'city_id_list[]=' + city.id + '&');
                await this.hotelServices.getAllHotelsByCitiesList(citiesIds).toPromise().then((res: any) => {
                    this.bookings = res.hotels;
                    this.form.get('hotels').enable();
                    this.form.get('hotels').setValidators(Validators.required);
                    this.form.get('hotels').updateValueAndValidity();
                });
            } /*else if (this.form.get('country_id').value) {
                await this.hotelServices.getAllHotelsByCountry(this.form.get('country_id').value).toPromise().then((res: any) => {
                    this.bookings = res.hotels;
                    this.form.get('hotels').enable();
                    this.form.get('hotels').setValidators(Validators.required);
                    this.form.get('hotels').updateValueAndValidity();
                });
            }*/ else {
                this.bookings = [];
            }
        } else {
            if (this.form.get('use_destination_hotel').value === 1) {
                this.form.get('use_destination_hotel').setValue(0);
                this.switchServiceHandler(true);
            }
            this.form.get('hotels').setValue(null);
            this.form.get('hotels').disable();
            this.form.get('hotels').removeValidators(Validators.required);
        }
    }

    submit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        const clone = {
            ...this.form.getRawValue(),
            company_id: this.form.get('company').value.id,
            provider_id: this.form.get('provider').value ? this.form.get('provider').value.id : null
        };
        clone.invoice_handler_id = clone.invoice_handler_id.id;
        clone.validity_from = this.sharedService.dateToIsoString(clone.validity_from);
        clone.validity_to = this.sharedService.dateToIsoString(clone.validity_to);
        if (!clone.hotels) {
            clone.hotels = [];
        }
        const selectedCitiesIds = clone.cities.map(({id}) => id);
        clone.cities = selectedCitiesIds;
        if (!this.isEditMode) {
            this.prodAPIService.addService(clone).subscribe(() => {
                this.form.reset();
                this.messageService.add({
                    severity: 'success', summary: 'Create Contract',
                    detail: 'New contract has been Added successfully'
                });
                if (this.company) {
                    this.router.navigateByUrl(this.previousRoute.includes('contracts') ? this.previousRoute :
                        (this.user.company_id === 1 ? '/product-setup/contracts' : '/admin/companies/edit/' +
                            this.user.company_id + '/contracts'));
                } else {
                    this.resetPage();
                }
            });
        } else {
            this.prodAPIService.updateService(clone).subscribe(() => {
                this.messageService.add({
                    severity: 'success', summary: 'Update Contract',
                    detail: 'Contract has been updated successfully'
                });
                if (this.company) {
                    this.router.navigateByUrl(this.previousRoute.includes('contracts') ? this.previousRoute :
                        (this.user.company_id === 1 ? '/product-setup/contracts' : '/admin/companies/edit/' +
                            this.user.company_id + '/contracts'));
                }
            });
        }
    }

    useDestHotelsChanges(event) {
        if (event === 1) {
            this.form.get('service_handler_id').setValue(null);
            this.switchServiceHandler(false);
        } else {
            this.switchServiceHandler(true);
        }
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    getCountryName(countryId) {
        return this.countries.filter((cr) => cr.id === countryId)[0].name_en;
    }

    removeSelectedCity(index) {
        const cities = [...this.form.get('cities').value];
        cities.splice(index, 1);
        this.form.get('cities').setValue(cities);
        this.citiesChanged();
    }
}

