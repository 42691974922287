<p-table #dt [value]="courses" [scrollable]="true" [scrollDirection]="'both'"
         [rowHover]="true" dataKey="id"
         [lazy]="true"
         (onLazyLoad)="getAllGolfCourses($event)"
         [lazyLoadOnInit]="false"
         [paginator]="true"
         [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
         [showJumpToPageInput]="true" [first]="first"
         [rowsPerPageOptions]="[10, 50, 100, 200]" [showCurrentPageReport]="true"
         currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}">
  <ng-template pTemplate="caption">
    <div class="p-ai-center">

      <button pButton pRipple label="{{'export' | translate}}" icon="pi pi-upload" class="p-button-help float-right"
              (click)="exportExcel()" *ngIf="checkPermission('1636369321049')"></button>

      <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:80px; max-width: 80px;" class="p-d-flex p-jc-between p-ai-center">
        Top
        <p-columnFilter field="top" matchMode="equals" display="menu"
                        [showClearButton]="false" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false"
                        [showApplyButton]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown [options]="topStatuses" [ngModel]="value" placeholder="Any"
                        (onChange)="filter($event.value)">
              <ng-template let-status pTemplate="group">
                <div class="flex align-items-center">
                  <span>{{ translate.transform(status) }}</span>
                </div>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
      <th style="width: 90px;">
        <div class="flex justify-content-center align-items-center">
          <!--{{'publish' | translate}}-->Web
          <p-columnFilter field="publish" matchMode="equals" display="menu"
                          [showClearButton]="false" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false"
                          [showApplyButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [options]="publishStatuses" [ngModel]="value" placeholder="Any"
                          (onChange)="filter($event.value)">
                <ng-template let-status pTemplate="group">
                  <div class="flex align-items-center">
                    <span>{{ translate.transform(status) }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="name">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="company_name" class="p-d-flex p-jc-between p-ai-center">
        {{'golfClub' | translate}}
        <p-sortIcon field="company_name"></p-sortIcon>
        <!--<app-filter fieldName="company_name" [filterOptions]="filters.company_name"></app-filter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="region_name" class="p-d-flex p-jc-between p-ai-center">
        {{'continent' | translate}}
        <p-sortIcon field="region_name"></p-sortIcon>
        <!--<app-filter fieldName="region_name" [filterOptions]="filters.region_name"></app-filter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="country_name" class="p-d-flex p-jc-between p-ai-center">
        {{'country' | translate}}
        <p-sortIcon field="country_name"></p-sortIcon>
        <!--<app-filter fieldName="country_name" [filterOptions]="filters.country_name"></app-filter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="city_name" class="p-d-flex p-jc-between p-ai-center">
        {{'region' | translate}}
        <p-sortIcon field="city_name"></p-sortIcon>
        <!--<app-filter fieldName="city_name" [filterOptions]="filters.city_name"></app-filter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="area_name" class="p-d-flex p-jc-between p-ai-center">
        {{'area' | translate}}
        <p-sortIcon field="area_name"></p-sortIcon>
        <!--<app-filter fieldName="area_name" [filterOptions]="filters.area_name"></app-filter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="letter_code">{{'lettersCode' | translate}}
        <p-sortIcon field="letter_code"></p-sortIcon>
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="ref_id">Ref. ID
        <p-sortIcon field="ref_id"></p-sortIcon>
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="booking_code">{{'SPId' | translate}}
        <p-sortIcon field="booking_code"></p-sortIcon>
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="davinci_booking_code">DaVinci ID
        <p-sortIcon field="davinci_booking_code"></p-sortIcon>
      </th>
      <th style="min-width:160px; max-width: 160px;" class="p-d-flex p-jc-between p-ai-center">
        {{'holes' | translate}}
        <!--<app-filter fieldName="holes" [filterOptions]="filters.holes"></app-filter>-->
      </th>
      <!--<th style="min-width:160px; max-width: 160px;" pSortableColumn="assigned_user_name" class="p-d-flex p-jc-between p-ai-center">
        Assigned GG User
        <p-sortIcon field="assigned_user_name"></p-sortIcon>
        <app-filter fieldName="assigned_user_name" [filterOptions]="filters.assigned_user_name"></app-filter>
      </th>-->
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="created_at" class="p-d-flex p-jc-between p-ai-center">
        {{'creationDate' | translate}}
        <p-sortIcon field="created_at"></p-sortIcon>
        <!--<p-columnFilter type="date" field="created_at" display="menu"></p-columnFilter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" pSortableColumn="created_by_user_name" class="p-d-flex p-jc-between p-ai-center">
        {{'createdBy' | translate}}
        <p-sortIcon field="created_by_user_name"></p-sortIcon>
        <!--<app-filter fieldName="created_by_user_name" [filterOptions]="filters.created_by_user_name"></app-filter>-->
      </th>
      <th style="min-width:160px; max-width: 160px;" class="p-d-flex p-jc-between p-ai-center">
        Status
        <!--<app-filter fieldName="active" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>-->
      </th>
      <th style="width:60px" alignFrozen="right" pFrozenColumn
          *ngIf="checkPermission('1636369254962') || checkPermission('1636369274819') || checkPermission('1636369291037')"
      >{{'actions' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-course let-index="rowIndex">
    <tr>
      <td style="width:80px">
        <p-inputSwitch class="switch-input" (onChange)="topChanged(course)"
                       [trueValue]="1" [falseValue]="0"
                       name="top" [(ngModel)]="course.top"></p-inputSwitch>
      </td>
      <td style="width: 90px;">
        <i class="{{course.publish == 'Published' ? 'pi pi-check-circle' :
          (course.publish == 'Pending Publish' ? 'pi pi-hourglass' : 'pi pi-times-circle')}}"
        [pTooltip]="course.publish" tooltipPosition="top"></i>
      </td>
      <td style="min-width:160px; max-width: 160px; cursor: pointer" (click)="edit(course)">{{course.name}}</td>
      <td style="min-width:160px; max-width: 160px;">{{course.company_name}}</td>
      <td style="min-width:160px; max-width: 160px;">{{translateService.currentLang === 'de' ? course.region?.name_de : course.region?.name_en}}</td>
      <td style="min-width:160px; max-width: 160px;">{{translateService.currentLang === 'de' ? course.country?.name_de : course.country?.name_en}}</td>
      <td style="min-width:160px; max-width: 160px;">{{translateService.currentLang === 'de' ? course.city?.name_de : course.city?.name_en}}</td>
      <td style="min-width:160px; max-width: 160px;">{{translateService.currentLang === 'de' ? course.area?.name_de : course.area?.name_en}}</td>
      <td style="min-width:160px; max-width: 160px;">{{course.letter_code}}</td>
      <td style="min-width:160px; max-width: 160px;"><span class="word-break">{{course.ref_id}}</span></td>
      <td style="min-width:160px; max-width: 160px;"><span class="word-break">{{course.booking_code}}</span></td>
      <td style="min-width:160px; max-width: 160px;"><span class="word-break">{{course.davinci_booking_code}}</span></td>
      <td style="min-width:160px; max-width: 160px;">{{course.holes}}</td>
      <!--<td style="min-width:160px; max-width: 160px;">{{course.assigned_user_name}}</td>-->
      <td style="min-width:160px; max-width: 160px;">{{course.created_at | date: 'dd.MM.yyyy'}}</td>
      <td style="min-width:160px; max-width: 160px;">{{course.created_by_user_name}}</td>
      <td style="min-width:160px; max-width: 160px;">{{course.active == 1 ? ('active' | translate) : ('inactive' | translate)}}</td>

      <td style="width:60px"  alignFrozen="right" pFrozenColumn
          *ngIf="checkPermission('1636369254962') || checkPermission('1636369274819') || checkPermission('1636369291037')"
      >
        <div class="btn-group" dropdown>
          <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                  class="p-button-rounded p-button-text"></button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
              role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngIf="checkPermission('1636369274819')">
              <a class="dropdown-item" (click)="edit(course)">{{'edit' | translate}}</a></li>
            <li role="menuitem" *ngIf="checkPermission('1636369291037')">
              <a class="dropdown-item" (click)="delete(course, index)">{{'delete' | translate}}</a></li>
          </ul>
        </div>

      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="{{'DeleteGC' | translate}}" [(visible)]="showDeletePrevent" (onHide)="showDeletePrevent = false">
  {{'GCNoDeleteMsg' | translate}}
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="showDeletePrevent = false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
