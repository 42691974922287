<div class="full-width-mobile p-mb-4 p-mb-md-0 mb-3">
    <h5 class="p-m-1 float-left">Requests</h5>
</div>

<div *ngIf="requests?.length === 0" class="mt-3 mb-3">
    <p class="hint">No requests to show!</p>
</div>
<p-card *ngFor="let request of requests; let i = index" styleClass="request-card">
    <div class="p-grid">
        <div class="p-md-3 side-div">
            <div class="full-width side-request-details">
                <div class="p-grid mt-3">
                    <div class="p-md-5 side-request-details-title">Request ID</div>
                    <div class="p-md-7 side-request-details-value">{{request.id}}</div>
                </div>
                <p-divider></p-divider>
                <div class="p-grid">
                    <div class="p-md-5 side-request-details-title">Request Date</div>
                    <div class="p-md-7 side-request-details-value">{{request.created_at | date: 'dd.MM.yyyy'}}</div>
                </div>
                <p-divider></p-divider>
                <div class="p-grid">
                    <div class="p-md-5 side-request-details-title">Destinations</div>
                    <div class="p-md-7 side-request-details-value"></div>
                </div>
                <div class="p-grid" *ngFor="let dest of request.destinations">
                    <div class="p-md-4 side-request-details-title dest-name">{{dest.city_name}}</div>
                    <div class="p-md-7 side-request-details-value">
                        <a *ngIf="dest.hotel_id" [routerLink]="'/hotels/edit/' + dest.hotel_id">{{dest.hotel_name}}</a>
                        <span *ngIf="!dest.hotel_id">No hotel</span><br>
                        {{dest.arrival_date ? (dest.arrival_date | date: 'dd.MM.yyyy') : 'No arrival date'}}
                        -
                        {{dest.departure_date ? (dest.departure_date | date: 'dd.MM.yyyy') : 'No departure date'}}<br>
                    </div>
                </div>
                <p-divider></p-divider>
                <div class="p-grid">
                    <div class="p-md-5 side-request-details-title">Travel Agency</div>
                    <div class="p-md-7 side-request-details-value">
                        <a
                                [routerLink]="'/travel-agencies/edit/' + request.travel_agency_id">{{request.travel_agency_name}}</a><br>
                        phone: {{request.phone}}<br>
                        fax: {{request.fax}}<br>
                        email: {{request.email}}<br>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-md-9 p-3">
            <h5>Players</h5>
            <app-players-form [request]="request" #playersForm></app-players-form>
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="buttons">
                        <button pButton type="button" class="float-right" (click)="saveChanges(i)">{{'saveChanges' | translate}}</button>
                    </div>
                </div>
            </div>
            <br>
            <p-divider></p-divider>
            <br>
            <h5>Destinations</h5>
            <app-request-stage-three [request]="request" [isPortal]="true"
                                     (requestSubmitted)="getAllRequests(1, requestService.pagination.per_page)"></app-request-stage-three>

        </div>
    </div>
</p-card>
<p-paginator *ngIf="requests?.length > 0" [rows]="requestService.pagination?.per_page"
             [totalRecords]="requestService.pagination?.total"
             [showJumpToPageInput]="true" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 50, 100, 200]"
             (onPageChange)="pageChanged($event)" #p
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
