import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {GolfCourse} from '../../_models/golf-course';

@Injectable({
    providedIn: 'root'
})
export class GolfCoursesService {
    baseUrl = environment.apiUrl;
    webUrl = environment.webHookUrl;
    courses: any[];
    course: any;
    private courseDetails: Subject<any> = new Subject<any>();    // consider putting the actual type of the data you will receive
    public course$ = this.courseDetails.asObservable();

    constructor(private http: HttpClient) {
    }
    getAllGolfCourseList() {
        return this.http.get<{status: boolean; golfcourses: any[]}>(this.baseUrl + 'golfcourses/get/all')
            .pipe(map(res => res.golfcourses));
    }

    searchGolfCourses(keyword): Observable<GolfCourse[]> {
        return this.http.get<GolfCourse[]>(this.baseUrl + 'golfcourses/get/all?search=' + keyword);
    }
    getAllGolfCourses() {
        return this.http.get(this.baseUrl + 'golfcourses').pipe(map((res: any) => {
            this.courses = res.golfcourses;
            return this.courses;
        }));
    }
    getPaginatedGolfCourses(pageNumber = 1,
                            pagination,
                            searchKeyword,
                            publishValue,
                            topValue,
                            sortField?,
                            sortOrder?) {
        return this.http.get(this.baseUrl + 'golfcourses/get/paginate?page=' + pageNumber
          + '&pagination=' + pagination
          + (searchKeyword ? '&search=' + searchKeyword : '')
          + (sortField === '' ? '' : ('&sortField=' + sortField + '&sortOrder=' + sortOrder)) +
          ((publishValue === 'None' || !publishValue) ? '' : ('&publish=' + publishValue)) +
          (topValue === 'None' ? '' : ('&top=' + topValue))
        );
    }

    getGolfCoursesByCity(cityId) {
        return this.http.get(this.baseUrl + 'golfcourses/get/all?city_id=' + cityId).pipe(map((res: any) => {
            return res.golfcourses;
        }));
    }
    getGolfCoursesByCountry(countryId) {
        return this.http.get(this.baseUrl + 'golfcourses/get/all?country_id=' + countryId).pipe(map((res: any) => {
            return res.golfcourses;
        }));
    }

    getGolfCourseById(id) {
        return this.http.get(this.baseUrl + 'golfcourses/' + id).pipe(map((res: any) => {
            this.course = res.golfcourse;
            this.courseDetails.next(this.course);
            return res;
        }));
    }

    getBasics() {
        return this.http.get(this.baseUrl + 'golfcourses/basics/all');
    }
    getFieldTypes() {
        return this.http.get(this.baseUrl + 'golfcourses/field-types/all');
    }
    deleteGolfCourse(id) {
        return this.http.delete(this.baseUrl + 'golfcourses/' + id);
    }

    //////////////////// GENERAL ///////////////////////

    getStyles() {
        return this.http.get(this.baseUrl + 'golfcourses/styles/all');
    }

    addCourse(obj) {
        return this.http.post(this.baseUrl + 'golfcourses', obj);
    }

    updateGolfCourse(id, obj) {
        return this.http.put(this.baseUrl + 'golfcourses/' + id, obj).pipe(map((res: any) => {
            this.course = res.golfcourse;
            this.courseDetails.next(this.course);
            return res;
        }));
    }

    updateGolfCourseCMS(id, obj) {
        return this.http.put(this.baseUrl + 'golfcourses/' + id + '/cms', obj).pipe(map((res: any) => {
            this.course = res.golfcourse;
            this.courseDetails.next(this.course);
            return res;
        }));
    }
    updateGolfCourseFacilities(id, obj) {
        return this.http.put(this.baseUrl + 'golfcourses/' + id + '/facility', obj).pipe(map((res: any) => {
            this.course = res.golfcourse;
            this.courseDetails.next(this.course);
            return res;
        }));
    }

    uploadImages(id, obj) {
        return this.http.post(this.baseUrl + 'golfcourses/upload-images/' + id, obj);
    }
    deleteImage(courseId, obj) {
        return this.http.post(this.baseUrl + 'golfcourses/delete-image/' + courseId, {image_id: obj});
    }

    /////////////////// SERVICES ////////////////////
    getServices(type) {
        return this.http.get(this.baseUrl + 'golfcourses/services/all?type=' + type);
    }
    addServices(obj, id) {
        return this.http.post(this.baseUrl + 'golfcourses/services/' + id, obj);
    }
    /////////////////// SERVICES ////////////////////

    publish(id) {
        return this.http.put(this.baseUrl + 'golfcourses/publish/' + id, null);
    }
    publishToWebsite(obj) {
        return this.http.post(this.webUrl + 'receive-callback', obj);
    }
    changeMainImage(id, obj) {
        return this.http.post(this.baseUrl + 'golfcourses/' + id + '/change-main-image', obj);
    }
}
