<div class="p-grid">
  <div class="p-md-3">
    <app-tree-view [object]="'Hotels'" [l4Data]="hotels" (selectedCity)="citySelected($event)"
                   (selectedL4Item)="hotelSelected($event)"></app-tree-view>
  </div>
  <div class="p-md-9">
    <div *ngIf="selectedHotel" class="card toolbar-demo mt-3">
      <app-add-hotel [hotelId]="selectedHotel.id"></app-add-hotel>
    </div>
  </div>
</div>
