<p-toast></p-toast>
<form [formGroup]="seasonForm" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid p-mb-4">
            <div>
                <h5>{{isEditMode ? 'Edit Season' : 'Add New Season'}}</h5>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [label]="'Name'" [formControl]="seasonForm.controls['title']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <label class="full-width">Contract name <span class="p-error">*</span></label>
                <p-autoComplete class="autocomplete" formControlName="service_id" forceSelection="true"
                                [suggestions]="services" delay="1000" (completeMethod)="searchServices($event)" field="name"
                (onSelect)="serviceSelected($event)">
                    <ng-template let-service pTemplate="item">
                        <div>{{service.name}}{{service.code ? ', ' + service.code : ''}}</div>
                    </ng-template>
                </p-autoComplete>
                <small *ngIf="seasonForm.get('service_id').touched && seasonForm.get('service_id').errors?.required"
                       class="p-error">required</small>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input type="text" maxlength="1" formControlName="code" label="Season code"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <label>Start date <span class="p-error">*</span></label>
                <p-calendar class="full-width" formControlName="start_date" [disabled]="!seasonForm.get('service_id').value"
                            [showTime]="false" [showIcon]="true" (onSelect)="clearEndDate()"
                            [minDate]="selectedService?.validity_from" dateFormat="dd.mm.yy"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
                            placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                <small *ngIf="seasonForm.controls['start_date'].touched && seasonForm.controls['start_date'].errors?.required"
                       class="p-error">required</small>
            </div>
            <div class="p-col-12 p-md-col">
                <label>End date <span class="p-error">*</span></label>
                <p-calendar class="full-width" formControlName="end_date"
                            [disabled]="!seasonForm.get('service_id').value || !seasonForm.get('start_date').value"
                            [showTime]="false" [showIcon]="true" [minDate]="seasonForm.get('start_date').value"
                            [maxDate]="selectedService?.validity_to" dateFormat="dd.mm.yy"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
                            placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                <small *ngIf="seasonForm.controls['end_date'].touched && seasonForm.controls['end_date'].errors?.required"
                       class="p-error">required</small>
            </div>
            <div class="p-col-12 p-md-col">
                <label>Color <span class="p-error">*</span></label>
                <p-dropdown placeholder="Season Colour" [formControl]="seasonForm.controls['color']"
                            [options]="colorsPalette">
                    <ng-template pTemplate="selectedItem">
                        <div class="color-item color-item-value" *ngIf="seasonForm.controls['color'].value.code">
                            <span class="color-box"
                                  [ngStyle]="{'background-color': seasonForm.controls['color'].value.code}"></span>
                            <div class="color-name">{{seasonForm.controls['color'].value.name}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-color pTemplate="item">
                        <div class="color-item">
                            <span class="color-box" [ngStyle]="{'background-color': color.code}"></span>
                            <div class="color-name">{{color.name}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small *ngIf="seasonForm.controls['color'].touched && seasonForm.controls['color'].errors?.required"
                       class="p-error">required</small>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button pButton type="submit">{{isEditMode ? ('saveChanges' | translate) : 'Create'}}</button>
    </div>
</form>
