<div class="card">

  <p-table #dt [value]="products" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
           [globalFilterFields]="['name', 'service_name', 'golf_course_name', 'tee_time_name' ,'hole_name',
           'validity_to', 'booking_code', 'code', 'ref_code', 'tui_code',
             'status']" [scrollable]="true" [scrollDirection]="'both'" [showCurrentPageReport]="true"
           currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}">
    <ng-template pTemplate="caption">
      <div class="p-ai-center">


        <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636370241530')"></button>

        <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" [(ngModel)]="searchValue"
                           (input)="searchProducts()" placeholder="Search..."/>
                </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:120px" pSortableColumn="name">Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="service_name">Contract
          <p-sortIcon field="service_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="tee_time_name">Tee Time
          <p-sortIcon field="tee_time_name"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="validity_to">Company/Service Provider ID
          <p-sortIcon field="validity_to"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="code">GFP ID
          <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="ref_code">DaVinci Booking Code
          <p-sortIcon field="ref_code"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="tui_code">@ComRes Booking Code
          <p-sortIcon field="tui_code"></p-sortIcon>
        </th>
        <th style="width:120px" pSortableColumn="status">Status
          <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th style="width:60px" alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1636370174690') || checkPermission('1636370191472') || checkPermission('1636370207939')"
        >Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-index>
      <tr>
        <td style="width:120px; cursor: pointer" (click)="edit(product)">{{product.name}}</td>
        <td style="width:120px;">{{product.service_name}}</td>
        <td style="width:120px; cursor: pointer;">{{product.tee_time_name}}</td>
        <td style="width:120px">{{product.booking_code?.booking_code}}</td>
        <td style="width:120px">{{product.code}}</td>
        <td style="width:120px">{{product.ref_code}}</td>
        <td style="width:120px">{{product.tui_code}}</td>
        <td class="status-td" style="width:120px">
          <!--<p-inputSwitch [(ngModel)]="product.active" (onChange)="changeStatus(product, $event)"
                         [trueValue]="1" [falseValue]="0"></p-inputSwitch>-->
          <span class="status-txt">{{product.status == 1 ? ('active' | translate) : ('inactive' | translate)}}</span>
        </td>
        <td style="width:60px"  alignFrozen="right" pFrozenColumn
            *ngIf="checkPermission('1636370174690') || checkPermission('1636370191472') || checkPermission('1636370207939')"
        >
          <div class="btn-group" dropdown>
            <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                    class="p-button-rounded p-button-text"></button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem"*ngIf="checkPermission('1636370191472')"><a class="dropdown-item" (click)="edit(product)">Edit</a></li>
              <li role="menuitem"*ngIf="checkPermission('1636370207939')"><a class="dropdown-item" (click)="delete(product)">Delete</a></li>
            </ul>
          </div>

        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator *ngIf="products?.length > 0" [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
               (onPageChange)="pageChanged($event)" [showJumpToPageInput]="true"
               [rowsPerPageOptions]="[10, 50, 100, 200]" #p [showCurrentPageReport]="true"
               currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
</div>
