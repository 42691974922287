import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {RequestAPIService} from '../../request/_services/request-api.service';
import {PlayersFormComponent} from '../../request/stages/request-stage-one/players-form/players-form.component';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-client-requests',
    templateUrl: './client-requests.component.html',
    styleUrls: ['./client-requests.css', '../../request/requests-view/requests-view.component.css']
})
export class ClientRequestsComponent implements OnInit {

    requests: any[];
    @ViewChildren('playersForm') playersComponents: QueryList<PlayersFormComponent>;

    constructor(private breadcrumbService: BreadcrumbService, public requestService: RequestAPIService,
                private messageService: MessageService) {
        this.breadcrumbService.setItems([
            {label: 'Client Portal', routerLink: '/client-portal'},
            {label: 'Client Requests'}
        ]);
    }

    ngOnInit(): void {
        this.getAllRequests(1, 10);
    }

    getAllRequests(pageNumber, pagination) {
        this.requestService.getAllRequests(pageNumber, pagination).subscribe((res: any) => {
            this.requests = res;
        });
    }

    pageChanged(event) {
        this.getAllRequests(event.page + 1, event.rows);
    }

    saveChanges(requestIndex) {
        const players: any = this.playersComponents.toArray()[requestIndex].submit();
        const newPlayers = players.filter(player => player.id == null);
        if (newPlayers.length > 0) {
            this.requestService.createPlayerBulk({players: newPlayers}, this.requests[requestIndex].id).subscribe((res: any) => {
                this.requests[requestIndex] = res.request;
            });
        } else {
            this.messageService.add({
                severity: 'info', summary: 'Request Players', detail: 'All player info is up to date'
            });
        }
    }

}
