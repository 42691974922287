import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestAPIService} from '../../_services/request-api.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestDetails} from '../../../_models/request-details';
import {SharedService} from '../../../_services/shared.service';
import {MessageService} from 'primeng/api';
import {AccountService} from '../../../_services/account.service';
import {User} from '../../../_models/user';

@Component({
    selector: 'app-request-stage-three',
    templateUrl: './request-stage-three.component.html',
    styleUrls: ['./request-stage-three.component.css']
})
export class RequestStageThreeComponent implements OnInit {

    @Input() request: RequestDetails = null;
    @Input() isPortal = false;
    @Output() requestSubmitted: EventEmitter<any> = new EventEmitter<any>();
    destinationsForm: UntypedFormGroup;
    confirmForm: UntypedFormGroup;
    today = new Date();
    notes: string;
    fillCount: number;
    timePattern = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
    user: User;

    constructor(private router: Router, private requestService: RequestAPIService, private fb: UntypedFormBuilder,
                public route: ActivatedRoute, public sharedService: SharedService, private messageService: MessageService,
                private accountService: AccountService) {
    }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe(res => {
            this.user = res;
        });
        this.initDestinationsForm();
        this.initConfirmForm();
        if (!this.request) {
            this.getRequest();
        } else {
            this.fetchData();
        }
    }

    getRequest() {
        this.request = this.route.snapshot.data.request;
        if (this.request.notes === null) {
            this.request.notes = '';
        }
        this.notes = this.request.notes;
        if (this.request.status_id !== 1) {
            this.messageService.add({
                severity: 'success', summary: 'Edit Request',
                detail: 'Approved requests cannot be Edited.'
            });
            this.router.navigateByUrl('/requests');
        } else {
            this.fetchData();
        }
        /*this.requestService.getRequest(this.route.snapshot.paramMap.get('id')).subscribe((res: any) => {
            this.request = res.request;
            if (this.request.notes === null) {
                this.request.notes = '';
            }
            this.notes = this.request.notes;
            if (this.request.status_id !== 1) {
                this.messageService.add({
                    severity: 'success', summary: 'Edit Request',
                    detail: 'Approved requests cannot be Edited.'
                });
                this.router.navigateByUrl('/requests');
            } else {
                this.fetchData();
            }
        });*/
    }

    fetchData() {
        this.request.players.forEach(player => {
            player.disabled = false;
        });
        this.fillForm();
    }

    initDestinationsForm() {
        this.destinationsForm = this.fb.group({
            destinations: this.fb.array([])
        });
    }

    initConfirmForm() {
        this.confirmForm = this.fb.group({
            confirmOne: false,
            confirmTwo: false,
            confirmThree: false,
        });
    }


    get _destinations() {
        return this.destinationsForm.get('destinations') as UntypedFormArray;
    }

    _products(destIndex) {
        return this._destinations.controls[destIndex].get('products') as UntypedFormArray;
    }

    _product(destIndex, prodIndex) {
        return this.destinationsForm.get(['destinations', destIndex, 'products', prodIndex]) as UntypedFormGroup;
    }

    _players(destIndex, prodIndex) {
        return this.destinationsForm.get(['destinations', destIndex, 'products', prodIndex, 'players']) as UntypedFormArray;
    }

    _player(destIndex, prodIndex, playerIndex) {
        return this.destinationsForm.get(['destinations', destIndex, 'products', prodIndex, 'players', playerIndex]) as UntypedFormGroup;
    }


    fillForm() {
        this.request.destinations.forEach((dest, destIndex) => {
            this._destinations.push(this.fb.group({
                products: this.fb.array([]),
            }));
            dest.products.forEach((prod, pIndex) => {
                this._products(destIndex).push(this.fb.group({
                    players: this.fb.array([]),
                    hcpExceeded: false
                }));
                ///////////////////////// PUSH PLAYERS ////////////////////////
                this._players(destIndex, pIndex).clear();
                let numberOfPlayersArray;
                numberOfPlayersArray = new Array(+this.request.destinations[destIndex].products[pIndex].tee_time_name)
                    .fill(0);
                const playersFormArray = this._players(destIndex, pIndex);
                numberOfPlayersArray.forEach((num, index) => {
                    playersFormArray.push(
                        this.fb.group({
                            player: [null, prod.configure_players_with_tee_times ? Validators.required : null],
                            date: [dest.arrival_date ? this.sharedService.addNDays(new Date(dest.arrival_date), index + 1) :
                                new Date(), Validators.required],
                            time_from: ['6:00:00'],
                            time_to: ['14:00:00'],
                            pref_time: ['10:00', [Validators.required, Validators.pattern(this.timePattern)]],
                            time_margin: [4, Validators.required],
                            request_product_details_id: [prod.details.length === 0 ? {
                                    value: prod.golf_course_id,
                                    disabled: true
                                }
                                : null, Validators.required],
                            link: 0
                        })
                    );
                });
                ///////////////////////// PUSH PLAYERS ///////////////////////
                /////////////////////// SET AVAILABLE COURSES ////////////////
                const availableCourses = [];
                if (prod.details.length > 0) {
                    prod.details.forEach(detail => {
                        availableCourses.push({...detail, disable: false});
                    });
                } else {
                    availableCourses.push({id: prod.golf_course_id, golf_course_name: prod.golf_course_name});
                }
                this.request.destinations[destIndex].products[pIndex].availableCourses = availableCourses;
                this.request.destinations[destIndex].products[pIndex].minCounterError = [];
                this.request.destinations[destIndex].products[pIndex].showDateWarning = false;
                this.request.destinations[destIndex].products[pIndex].showNotSavedError = false;
                this.request.destinations[destIndex].products[pIndex].hcpWarning = false;
                /////////////////////// SET AVAILABLE COURSES ////////////////
                ///////////////// FILL TEE TIMES ////////////////
                if (prod.request_tee_times.length > 0) {
                    this._players(destIndex, pIndex).controls.forEach((player, index) => {
                        const obj = {
                            id: prod.request_tee_times[index].id,
                            player: this.request.players
                                .filter(pl => pl.id == prod.request_tee_times[index].request_player_id)[0],
                            request_product_details_id:
                                this.request.destinations[destIndex].products[pIndex].details.length > 0 ?
                                    availableCourses.filter(course => course.id == prod.request_tee_times[index]
                                        .request_product_details_id)[0].id :
                                    availableCourses.filter(course => course.id == prod.golf_course_id)[0].id,
                            date: new Date(prod.request_tee_times[index].date),
                            pref_time: prod.request_tee_times[index].pref_time.slice(0, -3),
                            time_margin: prod.request_tee_times[index].time_margin,
                            time_from: prod.request_tee_times[index].time_from,
                            time_to: prod.request_tee_times[index].time_to,
                        };
                        player.patchValue(obj);
                    });
                    this.checkHCPLimit(destIndex, pIndex);
                }
                ///////////////// FILL TEE TIMES ////////////////
            });
        });
    }


    dateChanged(event, destIndex, productsIndex, playerIndex) {
        if (event) {
            if (playerIndex == 0 && this._player(destIndex, productsIndex, playerIndex).get('link').value == 1) {
                const players = this._players(destIndex, productsIndex).controls;
                players.forEach(player => {
                    player.get('date').setValue(event);
                });
            }
            this.showDateWarning(this.sharedService.dateToIsoString(event), destIndex, productsIndex);
        }
    }

    showDateWarning(teeDate, destIndex, prodIndex) {
        this.request.destinations[destIndex].products[prodIndex].showDateWarning = false;
        this._players(destIndex, prodIndex).controls.forEach(pl => {
            if (teeDate == this.request.destinations[destIndex].arrival_date ||
                teeDate == this.request.destinations[destIndex].departure_date) {
                this.request.destinations[destIndex].products[prodIndex].showDateWarning = true;
            }
        });
    }

    checkGolfCourseAvailability(dIndex, pIndex) {
        const availableCourses = this.request.destinations[dIndex].products[pIndex].availableCourses;
        const selectedCourses = this.getSelectedCourses(dIndex, pIndex);
        availableCourses.forEach(course => {
            if (course.type === 'Fixed') {
                const courseCount = selectedCourses.filter(x => x === course.id).length;
                if (+course.tee_time_name === courseCount) {
                    course.disable = true;
                } else {
                    course.disable = false;
                }
            } else if (course.type === 'Min-Max') {
                const courseCount = selectedCourses.filter(x => x === course.id).length;
                if (+course.max_tee_time_name === courseCount) {
                    course.disable = true;
                } else {
                    course.disable = false;
                }
            }
        });
    }

    checkHCPLimit(dIndex, pIndex) {
        this.request.destinations[dIndex].products[pIndex].hcpWarning = false;
        const configuredTimes = {...this._product(dIndex, pIndex).value};
        const product = this.request.destinations[dIndex].products[pIndex];
        this.request.players.forEach(player => {
            configuredTimes.players.forEach(time => {
                if (time.request_product_details_id) {
                    const hcpMen = product.details.filter(x => x.id === time.request_product_details_id)[0].hcp_men;
                    const hcpWomen = product.details.filter(x => x.id === time.request_product_details_id)[0].hcp_women;
                    if (player.gender === 'Male' && +player.hcp > hcpMen) {
                        this.request.destinations[dIndex].products[pIndex].hcpWarning = true;
                    } else if (player.gender === 'Female' && +player.hcp > hcpWomen) {
                        this.request.destinations[dIndex].products[pIndex].hcpWarning = true;
                    }
                }
            });
        });
    }

    getSelectedCourses(dIndex, pIndex) {
        const selectedCourses = [];
        this._product(dIndex, pIndex).value.players.forEach(player => {
            if (player.request_product_details_id !== null) {
                selectedCourses.push(player.request_product_details_id);
            }
        });
        return selectedCourses;
    }

    CreateTeeTimes(dIndex, pIndex) {
        const teeTimesList = [];
        if (!this._product(dIndex, pIndex).valid) {
            this._product(dIndex, pIndex).markAllAsTouched();
            return false;
        }
        if (!this.checkCourseMin(dIndex, pIndex)) {
            return false;
        }
        this._product(dIndex, pIndex).getRawValue().players.forEach(item => {
            const obj = {
                request_product_id: this.request.destinations[dIndex].products[pIndex].id,
                request_product_details_id: this.request.destinations[dIndex].products[pIndex].details.length > 0 ?
                    item.request_product_details_id : null,
                request_player_id: item.player == null ? null : item.player.id,
                date: this.sharedService.dateToIsoString(item.date),
                time_from: item.time_from,
                time_to: item.time_to,
                time_margin: item.time_margin,
                pref_time: item.pref_time
            };
            teeTimesList.push(obj);
        });
        if (this.request.destinations[dIndex].products[pIndex].request_tee_times.length == 0) {
            this.requestService.createTeeTimesList({tee_times: teeTimesList}, this.request.id)
                .subscribe((res: any) => {
                    this.request = res.request;
                    this.fillForm();
                });
        } else {
            this.requestService.updateTeeTimesList({tee_times: teeTimesList},
                this.request.destinations[dIndex].products[pIndex].id)
                .subscribe((res: any) => {
                    this.request = res.request;
                    this.fillForm();
                });
        }
    }

    calcTimeFromTo(clientsIndex, productsIndex, playerIndex, event?) {
        const configuredTime = {
            ...this.destinationsForm.value.destinations[clientsIndex]
                .products[productsIndex].players[playerIndex]
        };
        if (event) {
            configuredTime.time_margin = event;
        }
        ////////// CALCULATE TIME FROM /////////
        const minHour = Math.max((configuredTime.pref_time.split(':')[0] - configuredTime.time_margin),
            (configuredTime.time_margin <= configuredTime.pref_time.split(':')[0] ?
                configuredTime.pref_time.split(':')[0] - configuredTime.time_margin : 0));
        const minHourD = new Date();
        minHourD.setHours(minHour);

        if (configuredTime.pref_time.split(':')[1] !== '00' &&
            +configuredTime.pref_time.split(':')[0] >= configuredTime.time_margin) {
            minHourD.setMinutes(+configuredTime.pref_time.split(':')[1]);
        } else {
            minHourD.setMinutes(0);
        }
        this._player(clientsIndex, productsIndex, playerIndex).get('time_from')
            .setValue(this.sharedService.timeToString(minHourD));
        ////////// CALCULATE TIME FROM /////////
        ////////// CALCULATE TIME TO /////////
        const maxHour = new Date();
        maxHour.setHours(+configuredTime.pref_time.split(':')[0] + configuredTime.time_margin);
        maxHour.setMinutes(configuredTime.pref_time.split(':')[1]);
        this._player(clientsIndex, productsIndex, playerIndex).get('time_to')
            .setValue(this.sharedService.timeToString(maxHour));
        ////////// CALCULATE TIME TO /////////

        ////////// FILL TEE TIMES VALUES IF FIRST TEE TIME AND LINK IS ON /////////
        if (this.fillCount !== 1) {
            this.fillCount = 0;
        }
        if (playerIndex === 0 && this._player(clientsIndex, productsIndex, playerIndex).get('link').value == 1 &&
            this.fillCount === 0) {
            this.fillCount = 1;
            const players = this._players(clientsIndex, productsIndex).controls;
            players.forEach((player, index) => {
                player.get('pref_time').setValue(configuredTime.pref_time);
                this.calcTimeFromTo(clientsIndex, productsIndex, index);
                if (index === players.length - 1) {
                    this.fillCount = null;
                }
            });
        }
        ////////// FILL TEE TIMES VALUES IF FIRST TEE TIME AND LINK IS ON /////////
    }

    checkCourseMin(dIndex, pIndex) {
        let result = true;
        this.request.destinations[dIndex].products[pIndex].minCounterError = [];
        const availableCourses = this.request.destinations[dIndex].products[pIndex].availableCourses;
        const selectedCourses = this.getSelectedCourses(dIndex, pIndex);
        availableCourses.forEach(course => {
            if (course.type === 'Fixed') {
                const courseCount = selectedCourses.filter(x => x === course.id).length;
                if (courseCount < +course.tee_time_name) {
                    const errorObj = {
                        name: course.golf_course_name,
                        type: 'fixed',
                        minValue: +course.tee_time_name
                    };
                    this.request.destinations[dIndex].products[pIndex].minCounterError.push(errorObj);
                    result = false;
                }
            }
            if (course.type === 'Min-Max') {
                const courseCount = selectedCourses.filter(x => x === course.id).length;
                if (+course.min_tee_time_name > courseCount) {
                    const errorObj = {
                        name: course.golf_course_name,
                        type: 'min',
                        minValue: +course.min_tee_time_name
                    };
                    this.request.destinations[dIndex].products[pIndex].minCounterError.push(errorObj);
                    result = false;
                }
            }
        });
        return result;
    }

    submit() {
        if (!this.destinationsForm.valid) {
            this.destinationsForm.markAllAsTouched();
            return false;
        }
        let pass = true;
        this.request.destinations.forEach(dest => {
            dest.products.forEach(prod => {
                prod.showNotSavedError = false;
                if (prod.request_tee_times.length == 0) {
                    prod.showNotSavedError = true;
                    pass = false;
                }
            });
        });
        if (pass) {
            const obj = {
                is_submit: 1
            };
            this.requestService.submitRequest(this.request.id, obj).subscribe((res: any) => {
                this.request = res.request;
                this.fillForm();
                this.messageService.add({
                    severity: 'success', summary: 'Submit Request',
                    detail: 'Request has been submitted successfully'
                });
            });
        }
    }

    goBack() {
        this.router.navigateByUrl('requests/stage/one/' + this.request.id).then();
    }

    calcMinDate(dIndex) {
        let minDate;
        this.request.destinations[dIndex].arrival_date ?
            minDate = new Date(this.request.destinations[dIndex].arrival_date) : minDate = this.today;
        return minDate;
    }

    calcMaxDate(dIndex) {
        let maxDate;
        this.request.destinations[dIndex].departure_date ?
            maxDate = new Date(this.request.destinations[dIndex].departure_date) : maxDate = '';
        return maxDate;
    }

    submitPortal() {
        let result = true;
        this.request.destinations.forEach(dest => {
            dest.products.forEach(prod => {
                if (prod.request_tee_times.length < +prod.tee_time_name) {
                    result = false;
                }
            });
        });
        if (result) {
            const obj = {
                id: this.request.id,
                is_client_submit: 1,
                type_id: this.request.type_id,
                is_submit: 1
            };
            this.requestService.submitRequest(this.request.id, obj).subscribe(() => {
                this.requestService.updateRequest(obj).subscribe((res: any) => {
                    this.request = res.request;
                    this.requestSubmitted.emit();
                    this.messageService.add({
                        severity: 'success', summary: 'Submit Request',
                        detail: 'Request has been submitted successfully'
                    });
                });
            });
        }

    }

    print() {
        window.open(window.location.origin + '/requests/stage/print/' + this.request.id,
            '_blank');
    }

    updateNotes(event) {
        if (this.request.notes !== this.notes) {
            const obj = {
                id: this.request.id,
                type_id: this.request.type_id,
                travel_agency_id: this.request.travel_agency_id,
                tour_operator_id: this.request.tour_operator_id,
                email: this.request.email,
                booking_codes: this.request.booking_codes.map(code => {
                    return code.id;
                }),
                notes: this.notes
            };
            this.requestService.updateRequest(obj).subscribe((res: any) => {
                res.request.notes === null ? this.request.notes = '' : this.request.notes = res.request.notes;
                this.messageService.add({
                    severity: 'success', summary: 'Update Request',
                    detail: 'Request notes has been updated successfully'
                });
            });
        }
    }

    marginMaxTime(clientsIndex, productsIndex, playerIndex) {
        const selectedPrefTime = this._player(clientsIndex, productsIndex, playerIndex).get('pref_time').value;
        if (!selectedPrefTime) return false;
        return (23 - selectedPrefTime.split(':')[0]);
    }
}
