import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../../_models/lookup';
import {GolfCoursesService} from '../../_services/golf-courses.service';
import {CompaniesService} from '../../../_services/companies-service';
import {Company} from '../../../_models/company';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../_models/user';
import {UsersServices} from '../../../_services/users-services';
import {Hotel} from '../../../_models/hotel';
import {HotelsService} from '../../../hotels/_services/hotels.service';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {Area, City, Country, Region} from '../../../_models/destinations';
import {DestinationsService} from '../../../destination/_services/destinations.service';

@Component({
    selector: 'app-add-course',
    templateUrl: './add-course.component.html',
    styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {

    addCourseForm: UntypedFormGroup;
    golfClubs: Company[];
    styles: Lookup[];
    showCompanyInfoButton = true;
    companies: Company[];
    hotels: Hotel[];
    users: User[];
    selectedCompany: Company;
    invalidControls: any[];
    currentUser: User;

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    langKey: string;
    countriesLoaded = false;
    citiesLoaded = false;
    areasLoaded = false;

    constructor(private breadcrumbService: BreadcrumbService, private coursesServices: GolfCoursesService,
                private fb: UntypedFormBuilder, private companiesServices: CompaniesService,
                private usersServices: UsersServices,
                private messageService: MessageService, private router: Router, private route: ActivatedRoute,
                private hotelService: HotelsService, private accountService: AccountService,
                private translate: TranslatePipe, private destinationsService: DestinationsService,
                public translateService: TranslateService) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: this.translate.transform('golfCourses'), routerLink: ['/golf-courses']},
                {label: this.translate.transform('addGolfCourse')},
            ]);
        }, 300);
        if (this.route.snapshot.queryParamMap.get('companyId')) {
            this.fillCompanyName();
        }
        this.langKey = this.translateService.currentLang === 'de' ? 'name_de' : 'name_en';
        this.accountService.currentUser$.subscribe(res => {
            this.currentUser = res;
        });
        this.initForm();
        this.getStyles();
        this.getRegions();
    }

    initForm() {
        this.addCourseForm = this.fb.group({
            is_parent: [0],
            company: [null, Validators.required],
            company_id: [null],
            company_name: [null],
            hotel: [null],
            hotel_id: [null],
            hotel_name: [null],
            name: [null, Validators.required],
            ref_id: [null],
            reservation_email: [null, Validators.email],
            active: [1],
            direct_contract: [0],
            via_dmc: [0],
            via_hotel: [0],
            delegate_name: [null],
            delegate_email: [null, [Validators.email]],
            delegate_mobile_number: [null],
            assigned_user: [null],
            assigned_user_id: [null],
            assigned_user_name: [null],
            region_id: new UntypedFormControl(null, Validators.required),
            country_id: new UntypedFormControl(null, Validators.required),
            city_id: new UntypedFormControl(null, Validators.required),
            area_id: new UntypedFormControl(null),
            street: new UntypedFormControl(null),
            postal_code: new UntypedFormControl(null),
            location_link: [null],
            phone: [null],
            fax: [null],
            email: [null, [Validators.required, Validators.email]],
            latitude: [null],
            longitude: [null],
            is_company_address: [0]
        });
    }

    getStyles() {
        this.coursesServices.getStyles().subscribe((res: any) => {
            this.styles = res.styles;
        });
    }

    fillCompanyName() {
        this.companiesServices.getCompany(this.route.snapshot.queryParamMap.get('companyId')).subscribe(response => {
            this.addCourseForm.get('company').setValue(response);
            this.addCourseForm.get('company').disable();
            this.addCourseForm.get('is_parent').setValue(0);
            this.addCourseForm.get('is_parent').disable();
        });
    }

    findInvalidControls() {
        this.invalidControls = [];
        const controls = this.addCourseForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalidControls.push(name.replace(/_/g, ' '));
            }
        }
    }

    submitForm() {
        if (!this.addCourseForm.valid) {
            this.addCourseForm.markAllAsTouched();
            this.findInvalidControls();
            return false;
        }
        this.invalidControls = [];
        const formValue = {...this.addCourseForm.getRawValue(), ...this.addCourseForm.getRawValue().destinations};
        if (formValue.delegate_mobile_number != null) {
            formValue.delegate_mobile_number = formValue.delegate_mobile_number
                ?.internationalNumber?.toString().replace(/\s/g, '');
        }
        if (formValue.phone != null) {
            formValue.phone = formValue.phone?.internationalNumber?.toString().replace(/\s/g, '');
        }
        if (formValue.fax != null) {
            formValue.fax = formValue.fax?.internationalNumber?.toString().replace(/\s/g, '');
        }
        formValue.hotel_id = formValue.hotel?.id;
        formValue.assigned_user_id = formValue.assigned_user?.id;
        formValue.booking_accounting_id = formValue.booking_accounting?.id;
        formValue.company_id = formValue.company?.id;
        if (formValue.is_parent) {
            formValue.is_company_address = 1;
            const company = {
                ...formValue,
                name: formValue.name,
                company_type_id: 3,
                delegate_email: formValue.delegate_email,
                delegate_mobile_number: formValue.delegate_mobile_number,
                delegate_name: formValue.delegate_name,
                postal_code: formValue.postal_code,
                user_id: formValue.assigned_user_id
            };
            this.companiesServices.addCompany(company).subscribe((res: any) => {
                formValue.company_id = res.company.id;
                this.addCourse(formValue);
            });
        } else {
            this.addCourse(formValue);
        }
    }

    addCourse(formValue) {
        this.coursesServices.addCourse(formValue).subscribe((res: any) => {
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('addGolfCourseMessage')
            });
            this.router.navigate(['../edit/' + res.golfcourse.id], {relativeTo: this.route});
        });
    }

    getLangLat(event) {
        const regex = new RegExp('@(.*),(.*),');
        const latLongMatch = event.match(regex);
        this.addCourseForm.controls.latitude.setValue(latLongMatch[1]);
        this.addCourseForm.controls.longitude.setValue(latLongMatch[2]);
    }

    searchCompanies(event) {
        this.companiesServices.searchCompaniesByType(event.query, 3).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    searchHotels(event) {
        this.hotelService.search(event.query).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    searchUsers(event) {
        this.usersServices.searchUsers(event.query).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    isParentChanged(event) {
        this.showCompanyInfoButton = false;
        if (event.checked) {
            this.addCourseForm.get('company').setValue(null);
            this.addCourseForm.get('company').disable();
            this.addCourseForm.get('company').clearValidators();
            if (this.addCourseForm.get('is_company_address').value == 1) {
                this.addCourseForm.get('is_company_address').setValue(0);
                this.clearCompanyInfo();
                this.disabilitySwitch(false);
            }
        } else {
            this.showCompanyInfoButton = true;
            this.addCourseForm.get('company').enable();
            this.addCourseForm.get('company').addValidators(Validators.required);
            this.clearCompanyInfo();
        }
    }

    async companyInfoButtonClick(event) {
        if (event.checked == 1) {
            this.disabilitySwitch(true);
            if (this.addCourseForm.get('company').value !== null) {
                this.fillCompanyInfo(this.addCourseForm.get('company').value);
            }
            this.disabilitySwitch(true);
        } else {
            this.countriesLoaded = false;
            this.citiesLoaded = false;
            this.areasLoaded = false;
            this.disabilitySwitch(false);
        }
    }

    async companySelected(company) {
        this.selectedCompany = company;
        if (this.addCourseForm.get('is_company_address').value == 1) {
            this.fillCompanyInfo(company);
        }
    }

    fillCompanyInfo(company: Company) {
        this.addCourseForm.get('region_id').setValue(company.region_id);
        this.addCourseForm.get('country_id').setValue(company.country_id);
        this.addCourseForm.get('city_id').setValue(company.city_id);
        this.addCourseForm.get('area_id').setValue(company.area?.id);
        this.addCourseForm.get('street').setValue(company.street);
        this.addCourseForm.get('postal_code').setValue(company.postal_code);
        this.addCourseForm.get('location_link').setValue(company.location_link);
        this.addCourseForm.get('phone').setValue(company.phone?.replace(/\s/g, ''));
        this.addCourseForm.get('fax').setValue(company.fax?.replace(/\s/g, ''));
        this.addCourseForm.get('email').setValue(company.email);
        this.fetchDestinations(company);
    }

    clearCompanyInfo() {
        this.addCourseForm.get('region_id').setValue(null);
        this.addCourseForm.get('country_id').setValue(null);
        this.addCourseForm.get('city_id').setValue(null);
        this.addCourseForm.get('area_id').setValue(null);
        this.addCourseForm.get('street').setValue(null);
        this.addCourseForm.get('postal_code').setValue(null);
        this.addCourseForm.get('location_link').setValue(null);
        this.addCourseForm.get('phone').setValue(null);
        this.addCourseForm.get('fax').setValue(null);
        this.addCourseForm.get('email').setValue(null);
    }

    disabilitySwitch(disable: boolean) {
        disable ? this.addCourseForm.get('region_id').disable() : this.addCourseForm.get('region_id').enable();
        disable ? this.addCourseForm.get('country_id').disable() : this.addCourseForm.get('country_id').enable();
        disable ? this.addCourseForm.get('city_id').disable() : this.addCourseForm.get('city_id').enable();
        disable ? this.addCourseForm.get('area_id').disable() : this.addCourseForm.get('area_id').enable();
        disable ? this.addCourseForm.get('street').disable() : this.addCourseForm.get('street').enable();
        disable ? this.addCourseForm.get('postal_code').disable() : this.addCourseForm.get('postal_code').enable();
        disable ? this.addCourseForm.get('location_link').disable() : this.addCourseForm.get('location_link').enable();
        disable ? this.addCourseForm.get('phone').disable() : this.addCourseForm.get('phone').enable();
        disable ? this.addCourseForm.get('fax').disable() : this.addCourseForm.get('fax').enable();
        disable ? this.addCourseForm.get('email').disable() : this.addCourseForm.get('email').enable();
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }


    //////////////// Destination Section ///////////////
    getRegions(id?: number) {
        if (id) {
            this.destinationsService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destinationsService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destinationsService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destinationsService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destinationsService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destinationsService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    getAreas(id?: number, cityId?: number) {
        if (id) {
            this.destinationsService.getArea(id).subscribe((res: any) => {
                this.areas = [res];
            });
        } else if (cityId && !this.areasLoaded) {
            this.destinationsService.getAreasList(cityId).subscribe((res: any) => {
                this.areas = res;
                this.areasLoaded = true;
            });
        }
    }

    regionChanged() {
        this.addCourseForm.get('country_id').setValue(null);
        this.addCourseForm.get('city_id').setValue(null);
        this.addCourseForm.get('area_id').setValue(null);

        this.countries = [];
        this.cities = [];
        this.areas = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;
        this.areasLoaded = false;

        this.getCountries(null, this.addCourseForm.get('region_id').value);
    }

    countryChanged() {
        this.addCourseForm.get('city_id').setValue(null);
        this.addCourseForm.get('area_id').setValue(null);

        this.cities = [];
        this.areas = [];

        this.citiesLoaded = false;
        this.areasLoaded = false;

        this.getCities(null, this.addCourseForm.get('country_id').value);
    }

    cityChanged() {
        this.addCourseForm.get('area_id').setValue(null);
        this.areas = [];
        this.areasLoaded = false;
        this.getAreas(null, this.addCourseForm.get('city_id').value);

    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getAreaName(areaId) {
        const areaName = this.areas.filter(area => area.id === areaId)[0][this.langKey];
        return areaName;
    }

    fetchDestinations(item) {
        this.getCountries(item.country_id);
        this.getCities(item.city_id);
        if (item.area?.id) {
            this.getAreas(item.area?.id);
        }
    }

    //////////////// Destination Section ///////////////

    viewCompany() {
        window.open(window.location.origin + '/admin/companies/edit/' + this.addCourseForm.get('company').value.id,
            '_blank');
    }
}
