import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HotelsService} from '../../../_services/hotels.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HotelRoomsService} from '../../../_services/hotel-rooms.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Lookup} from '../../../../_models/lookup';
import {ImagesListComponent} from '../../../../_shared/images-list/images-list.component';

@Component({
    selector: 'app-room-form',
    templateUrl: './room-form.component.html',
    styleUrls: ['./room-form.component.css']
})
export class RoomFormComponent implements OnInit, OnChanges {

    @Input() hotelId: any;
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    item: any;
    form: UntypedFormGroup;
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];
    @Input() facilitiesList: Lookup[] = [];
    @Input() roomFieldTypes: any[] = [];
    @ViewChild('uploader', {static: false}) imagesUploader: ImagesListComponent;
    @Output() hotelUpdated: EventEmitter<any> = new EventEmitter();
    @Output() roomDeleted: EventEmitter<any> = new EventEmitter();
    @Input() room: any;

    constructor(private fb: UntypedFormBuilder,
                private hotelsService: HotelsService,
                private route: ActivatedRoute,
                private messageServices: MessageService,
                private hotelRoomsService: HotelRoomsService,
                public translateService: TranslateService,
                private loader: NgxUiLoaderService,
                public translate: TranslatePipe,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.room) {
            this.room = changes.room.currentValue;
            this.initForm();
        }
        /*if (changes.hotelId) {
            this.hotelId = changes.hotelId.currentValue;
            this.selectedType = this.roomTypes[0];
            if (this.form) {
                this.form.reset();
                this.initForm();
                this.isHotelRoom(this.selectedType);
            }
        }*/
    }

    initForm() {
        this.form = this.fb.group({
            name: [null, Validators.required],
            code: [null, [Validators.minLength(3), Validators.maxLength(3), Validators.required]],
            status: 0,
            show_website: 0,
            id: [null],
            fields: this.fb.array([]),
            room_type_id: null,
            facilities: this.fb.array([]),
        });

        if (this.room) {
            this.form.patchValue(this.room);
        }
        this.initFacilities();
        this.fillFieldsData(this.room?.fields);
    }

    get facilities() {
        return this.form.get('facilities') as UntypedFormArray;
    }

    initFacilities() {
        this.facilities.clear();
        this.facilitiesList.forEach(facility => {
            this.facilities.push(this.fb.group({
                id: facility.id,
                name: facility.translations[this.translateService.currentLang === 'de' ? 1 : 0].name,
                available: this.checkAvailability(facility.id)
            }));
        });
    }

    checkAvailability(facilityId) {
        if (this.room) {
            let result = 0;
            const exists = this.room.facilities.find(c => c.id === facilityId);
            if (exists) {
                result = 1;
            }
            return result;
        } else {
            return 0;
        }
    }

    get fields() {
        return this.form.get('fields') as UntypedFormArray;
    }

    fillFieldsData(fields: any[]) {
        this.fields.clear();
        this.roomFieldTypes?.forEach((fl, index) => {
            const field = fields?.filter(x => +x.room_field_type_id === fl.id)[0];
            if (field) {
                this.fields.push(
                    this.fb.group({
                        id: field.id,
                        room_field_type_id: +field.room_field_type_id,
                        is_html: field.is_html,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                field.translations.forEach(translation => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: translation.description
                        })
                    );
                });
            } else {
                this.fields.push(this.fb.group({
                    id: null,
                    room_field_type_id: [fl.id],
                    is_html: 0,
                    language_mode: 2,
                    translations: this.fb.array([
                        this.fb.group({
                            id: null,
                            language_id: 1,
                            description: null
                        }),
                        this.fb.group({
                            id: null,
                            language_id: 2,
                            description: null
                        })
                    ])
                }));
            }
        });
    }

    getFieldName(fieldId) {
        const name = this.roomFieldTypes
            .filter(x => x.id === +fieldId)[0]?.translations[this.translateService.currentLang === 'de' ? 1 : 0].name;
        return name;
    }

    deleteImage(idsList) {
        this.hotelRoomsService.deleteRoomImages(this.form.value.id, {image_id: idsList}).subscribe((res: any) => {
            this.room.images = res.room.images;
            this.closeModal.emit(this.room);
        });
    }

    uploadImages() {
        const files = this.imagesUploader.getFiles();
        const formData: FormData = new FormData();
        if (files.length === 0 || !this.form.get('id').value) {
            return false;
        }
        this.loader.start('uploadImages');
        files.forEach(file => {
            formData.append('images[]', file);
            formData.append('size[]', file.size.toString());
            formData.append('original_file_name[]', file.name);
        });
        this.hotelRoomsService.uploadRoomImages(this.form.value.id, formData).subscribe((res: any) => {
            this.imagesUploader.clearUploader();
            this.loader.stop('uploadImages');
            this.messageServices.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('addHotelRoomMessage')
            });
            this.room = res.room;
            this.room ? this.closeModal.emit(this.room) : this.closeModal.emit();
        });
    }

    submitForm() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return false;
        }
        ////////////// Fetch Facilities ///////////////
        const facilitiesObj = [];
        this.form.value.facilities.forEach(facility => {
            if (facility.available == 1) {
                facilitiesObj.push(facility.id);
            }
        });
        this.form.value.facilities = facilitiesObj;
        this.form.value.images = this.imagesUploader.form.value;
        ////////////// Fetch Facilities ///////////////
        if (this.room) {
            this.updateRoom();
        } else {
            this.addRoom();
        }
    }

    addRoom() {
        this.hotelRoomsService.addHotelRoom(this.hotelId, this.form.value).subscribe((res: any) => {
            this.form.get('id').setValue(res.room.id);
            if (this.imagesUploader && this.imagesUploader.getFiles().length > 0) {
                this.uploadImages();
            } else {
                this.messageServices.add({
                    severity: 'success', summary: this.translate.transform('golfHotels'),
                    detail: this.translate.transform('addHotelRoomMessage')
                });
                this.closeModal.emit(res.room);
            }
        });
    }

    updateRoom() {
        this.hotelRoomsService.updateHotelRoom(this.form.value).subscribe((res: any) => {
            this.room = res.room;
            this.closeModal.emit(res.room);
            this.messageServices.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelRoomMessage')
            });
        });
    }


    changeMainImage(id) {
        this.hotelRoomsService.changeMainImage(this.room.id, {image_id: id}).subscribe((res: any) => {
            this.room.images = res.room.images;
            this.messageServices.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelRoomMessage')
            });
            this.room = res.room;
            this.closeModal.emit(this.room);
        });
    }

    deleteRoom() {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                const deletedRoomId = this.form.value.id;
                this.hotelRoomsService.deleteHotelRoom(deletedRoomId).subscribe((res: any) => {
                    this.messageServices.add({
                        severity: 'success', summary: this.translate.transform('golfHotels'),
                        detail: this.translate.transform('deleteHotelRoomMessage')
                    });
                    this.roomDeleted.emit(deletedRoomId);
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }
}
