import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import GgTableCustomFN from '../../../_classes/ggTableCustomFN';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {GfpService} from '../../_services/gfp.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-gfp-table',
  templateUrl: './gfp-table.component.html',
  styleUrls: ['./gfp-table.component.css']
})
export class GfpTableComponent implements OnInit {

  products$: Observable<any[]>;
  products: any[];
  pagination: any;
  product: any;
  selectedProducts: any[];

  // Filter vars
  tableHelper = new GgTableCustomFN();
  searchValue = '';
  companyId: number;

  constructor(private breadcrumbService: BreadcrumbService, private gfpService: GfpService,
              private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private accountService: AccountService, private translate: TranslatePipe) {

  }

  ngOnInit(): void {
    if (this.route.parent.snapshot.paramMap.get('id')) {
      this.companyId = +this.route.parent.snapshot.paramMap.get('id');
      this.getCompanyGfps();
    } else {
      this.getProducts(1, 10, this.searchValue);
    }
  }

  getProducts(pageNumber, pagination, searchKeyword) {
    this.gfpService.getPaginatedProducts(pageNumber, pagination, searchKeyword).subscribe((res: any) => {
      this.products = res.products.data;
      this.pagination = res.products.meta;
    });
  }

  getCompanyGfps() {
    this.gfpService.getGfpsByCompany(this.companyId).subscribe((res: any) => {
      this.products = res;
    });
  }

  addNew() {
    this.router.navigate(['add'], {relativeTo: this.route});
  }

  edit(product) {
    if (product.is_package) {
      this.router.navigate(['product-setup/gfp/edit-package/' + product.id]);
    } else {
      this.router.navigate(['edit/' + product.id], {relativeTo: this.route});
    }
  }

  delete(product) {
    this.confirmationService.confirm({
      message: this.translate.transform('confirmMessage'),
      accept: () => {
        this.gfpService.deleteProduct(product).subscribe(res => {
          this.getProducts(this.pagination.current_page, this.pagination.per_page,
              this.searchValue);
          this.messageService.add({
            severity: 'success',
            summary: 'Delete Product',
            detail: 'Selected product has been deleted successfully!'
          });
        });
      },
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }

  changeStatus(product, event) {

  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedProducts || this.selectedProducts.length < 1) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedProducts);
  }
  pageChanged(event) {
    this.getProducts(event.page + 1, +event.rows, this.searchValue);
  }
  searchProducts() {
    this.getProducts(1, this.pagination.per_page, this.searchValue);
  }
}
