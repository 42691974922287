import {Component, Input, Optional, Output, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.css']
})


export class EditorComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() placeholder: string;
    @Input() disabled = false;
    quillConfig = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                [{'color': []}],
                //[{ 'color': [] }, { 'background': [] }],
                // ['code-block'],
                // [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                //[{ 'direction': 'rtl' }],                         // text direction

                //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],

                //[{ 'font': [] }],
                //[{ 'align': [] }],

                ['clean'],                                         // remove formatting button
                //['link', 'image', 'video']
            ],

        }
    };
    formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        {'list': 'ordered'},
        {'list': 'bullet'},
        {'script': 'sub'},
        {'script': 'super'},
        {'header': [1, 2, 3, 4, 5, 6, false]},
    ];

    constructor(@Optional() @Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }

    writeValue(obj: any) {
    }

    registerOnChange(fn: any) {
    }

    registerOnTouched(fn: any) {
    }

}
