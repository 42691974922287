<p-toast></p-toast>

<div class="card">
    <p-table #dt dataKey="id"
             [value]="cities"
             [(selection)]="selectedCities"
             [rowHover]="true"
             [globalFilterFields]="['name_en', 'name_de','code',
             translateService.currentLang === 'de' ? 'country.name_de' : 'country.name_en',
             translateService.currentLang === 'de' ? 'region.name_de' : 'region.name_en']"
             [scrollable]="true" [scrollDirection]="'both'">
        <!--<p-table #dt [value]="cities" [lazy]="true" (onLazyLoad)="pageChanged($event)"
                 dataKey="id" [lazyLoadOnInit]="false"  [(first)]="first"
                 [rowHover]="true" [globalFilterFields]="['name_en', 'name_de','code',
             translateService.currentLang === 'de' ? 'country.name_de' : 'country.name_en',
             translateService.currentLang === 'de' ? 'region.name_de' : 'region.name_en']"
                 [paginator]="true" [rows]="10" [totalRecords]="pagination?.total"
                 [rowsPerPageOptions]="[10,25,50,100]" [showJumpToPageInput]="true" [showCurrentPageReport]="true"
                 currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
             {totalRecords} {{'entries' | translate}}" [scrollable]="true" scrollDirection="horizontal"
                 [resizableColumns]="true">-->
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">{{'regions' | translate}}</h5>

                    <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-primary p-ml-2" (click)="addNew()" *ngIf="checkPermission('1636370877107')"></button>
                </div>

                <button pButton pRipple label="{{'export' | translate}}" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636370929834')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" [(ngModel)]="searchValue"
                           #searchInput
                           placeholder="{{'search' | translate}}"/>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width:80px; max-width: 80px;" class="p-d-flex p-jc-between p-ai-center">Top
                    <p-columnFilter field="top" matchMode="equals" display="menu"
                                    [showClearButton]="false" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false"
                                    [showApplyButton]="false">
                        <ng-template pTemplate="filter">
                            <div *ngFor="let status of topStatuses" class="field-checkbox">
                                <p-radioButton style="margin-bottom: 15px" name="top" [value]="status" [(ngModel)]="topFilterValue"
                                               (onClick)="filterCities()"></p-radioButton>
                                <label style="margin: 0 0 14px 8px;">{{status | translate}}</label>
                            </div>
                        </ng-template>
                    </p-columnFilter>
                <th style="width: 80px"><!--{{'publish' | translate}}-->Web
                <p-columnFilter field="publish" matchMode="equals" display="menu"
                                [showClearButton]="false" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false"
                                [showApplyButton]="false">
                    <ng-template pTemplate="filter">
                        <div *ngFor="let status of publishStatuses" class="field-checkbox">
                            <p-radioButton style="margin-bottom: 15px" name="publish" [value]="status" [(ngModel)]="publishFilterValue"
                                           (onClick)="filterCities()"></p-radioButton>
                            <label style="margin: 0 0 14px 8px;">{{translate.transform(status)}}</label>
                        </div>
                    </ng-template>
                </p-columnFilter>
                </th>
                <th style="width: 120px" pSortableColumn="name_en">Name EN <p-sortIcon field="name_en"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="name_de">Name DE <p-sortIcon field="name_de"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="code">Code <p-sortIcon field="code"></p-sortIcon></th>
                <th style="width: 120px"
                    [pSortableColumn]="translateService.currentLang === 'de' ? 'country.name_de' : 'country.name_en'" class="p-d-flex p-jc-between p-ai-center">
                    {{'country' | translate}}
                    <p-sortIcon [field]="translateService.currentLang === 'de' ? 'country.name_de' : 'country.name_en'"></p-sortIcon>
                </th>
                <th style="width: 120px"
                    [pSortableColumn]="translateService.currentLang === 'de' ? 'region.name_de' : 'region.name_en'" class="p-d-flex p-jc-between p-ai-center">
                    {{'continent' | translate}}
                    <p-sortIcon [field]="translateService.currentLang === 'de' ? 'region.name_de' : 'region.name_en'"></p-sortIcon>
                </th>
                <th style="width: 120px" class="p-d-flex p-jc-between p-ai-center">
                    Status
                </th>
                <th style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370893229') || checkPermission('1636370949146') || checkPermission('1636370966228')"
                >{{'actions' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-city>
            <tr>
                <td style="width:80px">
                    <p-inputSwitch class="switch-input" (onChange)="topChanged(city)"
                                   [trueValue]="1" [falseValue]="0"
                                   name="top" [(ngModel)]="city.top"></p-inputSwitch>
                </td>
                <td style="width: 80px;">
                    <i class="{{city.publish == 'Published' ? 'pi pi-check-circle' :
          (city.publish == 'Pending Publish' ? 'pi pi-hourglass' : 'pi pi-times-circle')}}"
                       [pTooltip]="city.publish" tooltipPosition="top"></i>
                </td>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(city)">
                    {{city.name_en}}
                </td>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(city)">
                    {{city.name_de}}
                </td>
                <td style="width: 120px">
                    {{city.code}}
                </td>
                <td style="width: 120px">
                    <!--<a [routerLink]="'/destinations/regions/country/' + city.country.id">
                        {{translateService.currentLang === 'de' ? city.country?.name_de : city.country?.name_en}}</a>-->
                    {{translateService.currentLang === 'de' ? city.country?.name_de : city.country?.name_en}}
                </td>
                <td style="width: 120px">
                    <!--<a [routerLink]="'/destinations/countries/continent/' + city.region.id">
                        {{translateService.currentLang === 'de' ? city.region?.name_de : city.region?.name_en}}</a>-->
                    {{translateService.currentLang === 'de' ? city.region?.name_de : city.region?.name_en}}
                </td>
                <td style="width: 120px" class="status-td">
                    <!--<p-inputSwitch [(ngModel)]="city.status" (onChange)="changeCountryStatus(city)"></p-inputSwitch>-->
                    <span class="status-txt">{{city.status === 1 || city.status? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370893229') || checkPermission('1636370949146') || checkPermission('1636370966228')"
                >
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-text mr-2"
                            (click)="edit(city)" *ngIf="checkPermission('1636370949146')"></button>
                    <!--<div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngIf="checkPermission('1636370949146')">
                                <a class="dropdown-item" (click)="edit(city)">{{'edit' | translate}}</a></li>
                            &lt;!&ndash;<li role="menuitem" *ngIf="checkPermission('1636370966228')">
                                <a class="dropdown-item" (click)="delete(city)">{{'delete' | translate}}</a></li>&ndash;&gt;
                        </ul>
                    </div>-->
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator *ngIf="cities?.length > 0" [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
                 (onPageChange)="pageChanged($event)" [showJumpToPageInput]="true" [first]="first"
                 [rowsPerPageOptions]="[10, 50, 100, 200]" #p [showCurrentPageReport]="true"
                 currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
</div>

