<p-toast></p-toast>

<form [formGroup]="form" (ngSubmit)="submitForm()">

    <!-- BASIC SECTION -->
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col-12">
                <h5>{{'basic' | translate}}</h5>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-6 p-md-6 p-xl-6">
                <app-text-input [formControl]='form.controls["name"]'
                                label="Name"></app-text-input>
            </div>
            <div class="p-col-6 p-md-6 p-xl-6">
                <app-text-input [formControl]='form.controls["davinci_booking_code"]'
                                [label]='"davinciBookingCode" | translate'></app-text-input>
            </div>
        </div>
    </div>

    <!-- CMS SECTION -->
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col-12">
                <h5>{{'cms' | translate}}</h5>
            </div>
        </div>

        <div *ngIf="fields.controls.length > 0" class="p-grid">
            <div class="p-col p-md-2 p-xl-12">
                <div *ngFor="let field of fields.controls; let i = index; let last = last">
                    <div class="p-grid p-3" [ngClass]="i % 2 == 0 ? 'gray-bg' : null">
                        <div class="p-col-12 p-md-12">
                            <h6>{{getFieldName(field.get(addMode ? 'id' : 'type_id').value)}}</h6>
                            <div *ngIf="field.get('is_html')?.value == 1" class="p-col-12 p-md-12 quill">
                                <div style="display: flow-root">
                                    <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                                    optionValue="id" optionLabel="name"
                                                    [formControl]="field.get('is_html')"></p-selectButton>
                                    <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                                    [formControl]="field.get('language_mode')"
                                                    styleClass="language-switch"></p-selectButton>
                                </div>
                                <app-editor [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                              [placeholder]="'Content'"></app-editor>
                            </div>
                            <div *ngIf="field.get('is_html').value == 0" class="p-col-12 p-md-12">
                                <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                                optionValue="id" optionLabel="name"
                                                [formControl]="field.get('is_html')"></p-selectButton>
                                <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                                [formControl]="field.get('language_mode')"
                                                styleClass="language-switch"></p-selectButton>
                                <textarea class="full-width" pInputText
                                          [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                          [placeholder]="'Content'"
                                          rows="4" autocomplete="false"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col-12">
                <h5>{{'packagePhotos' | translate}}</h5>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <app-images-list [limit]="20" [count]="package?.images?.length"
                                 #uploader (delete)="deleteImage($event)"
                                 [images]="package?.images"
                                 [showMainIcon]="true"
                                 [showGallery]="package ? true : false"
                                 (mainImageChanged)="changeMainImage($event)"
                                 (onSelectImages)="uploadImages()"></app-images-list>
            </div>

        </div>
    </div>

    <div class="buttons">
        <button class="float-right" pButton type="submit">{{(addMode ? 'create' : 'saveChanges') | translate}}</button>
    </div>
</form>
