<p-toast></p-toast>

<div class="card">
    <p-table #dt dataKey="id"
             [value]="areas$ | async"
             [(selection)]="selectedAreas"
             [rowHover]="true"
             [rows]="100"
             [paginator]="true"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showCurrentPageReport]="true"  [showJumpToPageInput]="true" [rowsPerPageOptions]="[10, 50, 100, 200]"
             [globalFilterFields]="['name_en', 'name_de', 'code', 'city_name', 'country_name', 'region_name']"
             [scrollable]="true" [scrollDirection]="'both'"
    >
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">{{'areas' | translate}}</h5>

                    <button pButton pRipple label="" icon="pi pi-plus"
                            class="p-button-rounded p-button-primary p-ml-2"
                            (click)="addNew()" *ngIf="checkPermission('1678311247964')"></button>
                </div>

                <button pButton pRipple label="{{'export' | translate}}" (click)="exportExcel()"
                        icon="pi pi-upload" class="p-button-help float-right"
                        *ngIf="checkPermission('1678311322983')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'search' | translate}}"/>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 120px" pSortableColumn="name_en">Name EN <p-sortIcon field="name_en"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="name_de">Name DE <p-sortIcon field="name_de"></p-sortIcon></th>
                <th style="width: 150px" pSortableColumn="code">{{'areaCode' | translate}} <p-sortIcon field="code"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="city_name" class="p-d-flex p-jc-between p-ai-center">
                    {{'region' | translate}}
                    <p-sortIcon field="city_name"></p-sortIcon>
                    <app-filter [fieldName]="translateService.currentLang === 'de' ? 'city_name_de' : 'city_name'"
                                [filterOptions]="filters[translateService.currentLang === 'de' ? 'city_name_de' : 'city_name']"></app-filter>
                </th>
                <th style="width: 120px" pSortableColumn="country_name" class="p-d-flex p-jc-between p-ai-center">
                    {{'country' | translate}}
                    <p-sortIcon field="country_name"></p-sortIcon>
                    <app-filter [fieldName]="translateService.currentLang === 'de' ? 'country_name_de' : 'country_name'"
                                [filterOptions]="filters[translateService.currentLang === 'de' ? 'country_name_de' : 'country_name']"></app-filter>
                </th>
                <th style="width: 120px" pSortableColumn="region_name" class="p-d-flex p-jc-between p-ai-center">
                    {{'continent' | translate}}
                    <p-sortIcon field="region_name"></p-sortIcon>
                    <app-filter [fieldName]="translateService.currentLang === 'de' ? 'region_name_de' : 'region_name'"
                                [filterOptions]="filters[translateService.currentLang === 'de' ? 'region_name_de' : 'region_name']"></app-filter>
                </th>
                <th style="width: 120px" class="p-d-flex p-jc-between p-ai-center">
                    Status
                    <app-filter fieldName="status" [filterOptions]="booleanFilter" [isBoolean]="true"></app-filter>
                </th>
                <th style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1678311266923') || checkPermission('1678311282763')"
                >{{'actions' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-area>
            <tr>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(area)">
                    {{area.name_en}}
                </td>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(area)">
                    {{area.name_de}}
                </td>
                <td style="width: 150px">
                    {{area.code}}
                </td>
                <td style="width: 120px">
                    <a [routerLink]="'/destinations/areas/region/' + area.city_id">
                        {{translateService.currentLang === 'de' ? area.city?.name_de : area.city?.name_en}}
                    </a>
                </td>
                <td style="width: 120px">
                    <a [routerLink]="'/destinations/regions/country/' + area.country_id">
                        {{translateService.currentLang === 'de' ? area.country?.name_de : area.country?.name_en}}</a>
                </td>
                <td style="width: 120px">
                    <a [routerLink]="'/destinations/countries/continent/' + area.region_id">
                        {{translateService.currentLang === 'de' ? area.region?.name_de : area.region?.name_en}}</a>
                </td>
                <td style="width: 120px" class="status-td">
                    <span class="status-txt">{{area.status === 1 || area.status? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1678311266923') || checkPermission('1678311282763')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngIf="checkPermission('1678311266923')">
                                <a class="dropdown-item" (click)="edit(area)">{{'edit' | translate}}</a></li>
                            <!--<li role="menuitem" *ngIf="checkPermission('1636370966228')">
                                <a class="dropdown-item" (click)="delete(area)">{{'delete' | translate}}</a></li>-->
                        </ul>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

