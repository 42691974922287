import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoursesComponent} from './courses/courses.component';
import {AddCourseComponent} from './courses/add-course/add-course.component';
import {EditCourseComponent} from './courses/edit-course/edit-course.component';
import {CalendarComponent} from './calendar/calendar.component';
import {GeneralComponent} from './courses/edit-course/general/general.component';
import {ServicesComponent} from './courses/edit-course/services/services.component';
import {TrainingComponent} from './courses/edit-course/training/training.component';
import {AuthGuard} from '../_guards/auth.guard';
import {CmsComponent} from './courses/edit-course/cms/cms.component';
import {GcFacilitiesComponent} from './courses/edit-course/gc-facilities/gc-facilities.component';
import {GolfCourseResolver} from '../_resolvers/golf-course.resolver';

const routes: Routes = [
    {path: '', redirectTo: 'courses', pathMatch: 'full'},
    {
        path: 'courses', children: [
            {path: '', redirectTo: './', pathMatch: 'full'},
            {
                path: '', component: CoursesComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636369197717', type: 'action'}
            },
            {
                path: 'add', component: AddCourseComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636369238620', type: 'action'}
            },
            {
                path: 'edit/:id', component: EditCourseComponent, children: [
                    {path: '', redirectTo: 'general', pathMatch: 'full'},
                    {path: 'general', component: GeneralComponent, data: {activeTab: 0}},
                    {path: 'cms', component: CmsComponent, data: {activeTab: 1}},
                    {path: 'facilities', component: GcFacilitiesComponent, data: {activeTab: 2}},
                    {path: 'services', component: ServicesComponent, data: {activeTab: 3}},
                    {path: 'trainings', component: TrainingComponent, data: {activeTab: 4}}
                ], resolve: {basics: GolfCourseResolver}, runGuardsAndResolvers: 'always',
                canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636369274819', type: 'action'}
            },
        ]
    },
    {
        path: 'calendar', component: CalendarComponent,
        canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636369337446', type: 'action'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GolfCoursesRoutingModule {
}
