<p-tree
        [filter]="true"
        filterPlaceholder="Search for Destination"
        name="destinations"
        [(selection)]="selections"
        (onNodeSelect)="selector(selections)"
        (onNodeUnselect)="selector(selections)"
        [value]="treeValues"
        selectionMode="checkbox"
        scrollHeight="400px"
        [loading]="isLoadingTree">
    <ng-template let-node pTemplate="default">
        <p [ngClass]="{'node-grey': node.status === 0}">{{node.label}} {{node.children_key ? '' : ' - ' +  node.code}}</p>
    </ng-template>
</p-tree>
