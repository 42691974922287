import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Hotel} from '../../_models/hotel';

@Injectable({
    providedIn: 'root'
})
export class HotelsService {

    baseUrl = environment.apiUrl;
    webUrl = environment.webHookUrl;
    hotels: Hotel[] = [];
    hotel: Hotel;

    constructor(private http: HttpClient) {
    }

    getAllHotels() {
        return this.http.get(this.baseUrl + 'hotels').pipe(map((res: any) => {
            this.hotels = res.hotels;
            return this.hotels;
        }));
    }
    getPaginatedHotels(pageNumber = 1,
                       pagination,
                       searchKeyword,
                       publishValue,
                       topValue,
                       sortField?,
                       sortOrder?,
                       isGolfHotel?) {
        return this.http.get(this.baseUrl + 'hotels?page=' + pageNumber
          + '&pagination=' + pagination
          + (searchKeyword ? '&search=' + searchKeyword : '')
          + (sortField === '' ? '' : ('&sortField=' + sortField + '&sortOrder=' + sortOrder)) +
            (isGolfHotel !== null ? '&is_golf_globe=' + isGolfHotel : '') +
            (publishValue === 'None' ? '' : ('&publish=' + publishValue)) +
            (topValue === 'None' ? '' : ('&top=' + topValue))
        );
    }

    getAllHotelsByCity(key) {
        return this.http.get(this.baseUrl + 'hotels/get/all?city_id=' + key);
    }
    getAllHotelsByCitiesList(key) {
        return this.http.get(this.baseUrl + 'hotels/get/all?' + key);
    }

    getAllHotelsByCountry(key) {
        return this.http.get(this.baseUrl + 'hotels/get/all?country_id=' + key);
    }

    requestHotel(keyword, cityId, travelAgencyId) {
        return this.http.get(this.baseUrl + 'hotels/get/request-hotel?search=' + keyword + '&city_id=' + cityId
            + '&travel_agency_id=' + travelAgencyId);
    }

    search(keyword, cityId?, travelAgencyId?) {
        return this.http.get(this.baseUrl + 'hotels/get/all?search=' + keyword + (cityId ? ('&city_id=' + cityId) : '')
            + (travelAgencyId ? ('&travel_agency_id=' + travelAgencyId) : ''));
    }

    getHotelById(id) {
        return this.http.get<Hotel>(this.baseUrl + 'hotels/' + id).pipe(map((res: any) => {
            this.hotel = res.hotel;
            return this.hotel;
        }));
    }
    getByCode(code) {
        return this.http.get<Hotel>(this.baseUrl + 'hotels/get/all?tui_ref_code=' + code);
    }

    getBasics() {
        return this.http.get(this.baseUrl + 'hotels/basics/all');
    }

    getFacilities() {
        return this.http.get(this.baseUrl + 'hotels/facilities/all');
    }

    getBoards() {
        return this.http.get(this.baseUrl + 'hotels/boards/all');
    }

    getSeasons() {
        return this.http.get(this.baseUrl + 'hotels');
    }

    getActivities() {
        return this.http.get(this.baseUrl + 'hotels');
    }

    addHotel(obj) {
        return this.http.post(this.baseUrl + 'hotels', obj);
    }

    updateHotel(obj) {
        return this.http.put(this.baseUrl + 'hotels/' + obj.id, obj);
    }

    deleteHotel(id) {
        return this.http.delete(this.baseUrl + 'hotels/' + id);
    }

    uploadImages(id, obj) {
        return this.http.post(this.baseUrl + 'hotels/upload-images/' + id, obj);
    }

    deleteImage(courseId, obj) {
        return this.http.post(this.baseUrl + 'hotels/delete-image/' + courseId, {image_id: obj});
    }

    getFieldTypes() {
        return this.http.get(this.baseUrl + 'hotels/field-types/all').pipe(map((res: any) => {
            const types = res.field_types;
            types.forEach(type => {
                type.disabled = false;
            });
            return types;
        }));
    }

    getHotelServices() {
        return this.http.get(this.baseUrl + 'hotels/services/all');
    }

    updateHotelServices(hotelId, obj) {
        return this.http.post(this.baseUrl + 'hotels/services/' + hotelId, obj);
    }
    getHotelsByCity(cityId) {
        return this.http.get(this.baseUrl + 'hotels/get/all?city_id=' + cityId).pipe(map((res: any) => {
            return res.hotels;
        }));
    }
    publishHotel(id) {
        return this.http.put(this.baseUrl + 'hotels/publish/' + id, null);
    }
    publishHotelToWebsite(obj) {
        return this.http.post(this.webUrl + 'receive-callback', obj);
    }
    changeMainImage(id, obj) {
        return this.http.post(this.baseUrl + 'hotels/' + id + '/change-main-image', obj);
    }
}
