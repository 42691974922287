<form [formGroup]="companyDataForm" (ngSubmit)="submitForm()">
    <div class="card toolbar-demo">
        <h5>Basic</h5>
        <div class="p-grid p-ai-center vertical-container">
            <div *ngIf="!isAddMode" class="p-col-12 p-md-3 text-center align-middle">
                <app-image-upload [image]="companyData?.logo"
                                  [chooseLabel]="isAddMode? 'Upload logo' : 'Change logo'"
                                  (submitUpload)="updateCompanyLogo($event)"
                                  (delete)="deleteLogo()" [showDelete]="true"
                                  [disabled]="false"></app-image-upload>
            </div>
            <div [class]="isAddMode ? 'p-col-12 p-md-12' : 'p-col-12 p-md-9'">
                <div class="p-grid">
                    <div class="p-col-12 p-md-col">
                        <app-text-input [label]="'Name'" [placeholder]="'Name'"
                                        [formControl]="companyDataForm.controls['name']"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <app-select-input [label]="'Company Type'" [options]="companyTypes" [optionValue]="'id'"
                                          [optionLabel]="'name'" (optionChanged)="companyTypeChanged($event)"
                                          [filter]="true"
                                          [formControl]="companyDataForm.controls['company_type_id']"></app-select-input>
                    </div>
                    <div *ngIf="companyDataForm.get('company_type_id').value == 3" class="p-col-12 p-md-col">
                        <div class="p-field">
                            <label class="full-width">Parent hotel</label>
                            <p-autoComplete class="autocomplete" [field]="'name'"
                                            [formControl]="companyDataForm.controls['hotel_id']"
                                            [suggestions]="hotels" [placeholder]="'Hotels'" delay="1000"
                                            (completeMethod)="searchHotels($event)" [forceSelection]="true">
                                <ng-template let-hotel pTemplate="item">
                                    <div>{{hotel.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-col">
                        <app-text-input [label]="'Rank'" [placeholder]="'Rank'" [type]="'number'"
                                        [formControl]="companyDataForm.controls['rank']"></app-text-input>
                    </div>
                    <!--<div class="p-col-12 p-md-col">
                        <app-switch-input [label]="'Active contract'"
                                          [formControl]="companyDataForm.controls['contract']"></app-switch-input>
                    </div>-->
                    <div class="p-col-12 p-md-col" *ngIf="companyDataForm.get('company_type_id').value !== 1
&& companyDataForm.get('company_type_id').value !== null && isAddMode">
                        <app-switch-input [label]="createChildButtonName"
                                          [formControl]="companyDataForm.controls['create_child']"></app-switch-input>
                    </div>
                </div>
                <div class="p-grid">
                    <div *ngIf="!isAddMode" class="p-col-12 p-md-3">
                        <app-text-input [label]="'Company/Service Provider ID'"
                                        [formControl]="companyDataForm.controls['booking_code']"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-3">
                        <app-text-input [label]="'DaVinci ID'"
                                        [formControl]="companyDataForm.controls['davinci_booking_code']"></app-text-input>
                    </div>
                    <div class="p-col-12 p-md-3">
                        <label>Default language</label>
                        <p-selectButton [styleClass]="'contract-checkbox'" [options]="langOptions"
                                        [optionValue]="'code'" [optionLabel]="'name'"
                                        [formControl]="companyDataForm.get('lang')"></p-selectButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="companyData?.company_type_id == 3 && !isAddMode" class="card toolbar-demo">
        <div class="full-width-mobile p-mb-4 p-mb-md-0">
            <h5 class="p-m-1 float-left">Club Golf Courses</h5>
            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                    (click)="addNewGolfCourse()" pTooltip="Add new golf course" tooltipPosition="top"></button>
        </div>
        <ul *ngIf="companyChildren?.length > 0" class="children">
            <li *ngFor="let child of companyChildren;" (click)="openChild(child)">{{child.name}}</li>
        </ul>
    </div>
    <!--<div class="card toolbar-demo">
        <h5>Delegate Contact</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'text'" [label]="'Delegate name'" [placeholder]="'Delegate name'"
                                [formControl]="companyDataForm.controls['delegate_name']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'email'" [label]="'Delegate email'" [placeholder]="'Delegate email'"
                                [formControl]="companyDataForm.controls['delegate_email']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Delegate mobile</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Delegate mobile'"
                            formControlName="delegate_mobile_number"
                    ></ngx-intl-tel-input>
                    <small *ngIf="companyDataForm.controls.delegate_mobile_number.touched && companyDataForm.controls.delegate_mobile_number.errors?.required"
                           class="p-error">required</small>
                    <small class="p-error"
                           *ngIf="companyDataForm.controls.delegate_mobile_number.touched && companyDataForm.controls.delegate_mobile_number.invalid">
                        Invalid number
                    </small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label class="full-width">GG assigned user</label>
                    <p-autoComplete class="autocomplete" [field]="'full_name'" formControlName="assigned_user"
                                    [suggestions]="users" [placeholder]="'GG assigned user'" delay="1000"
                                    (completeMethod)="searchUsers($event)" [forceSelection]="true">
                        <ng-template let-user pTemplate="item">
                            <div>{{user.first_name + ' ' + user.last_name}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
        </div>
    </div>-->
    <div class="card toolbar-demo">
        <h5>Address</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'continent' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="regions" [formControl]="companyDataForm.get('region_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                                placeholder="{{'selectContinent' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getRegionName(companyDataForm.get('region_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="companyDataForm.get('region_id').touched && companyDataForm.get('region_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'country' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="countries" [formControl]="companyDataForm.get('country_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()"
                                placeholder="{{'selectCountry' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getCountries(null, companyDataForm.get('region_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCountryName(companyDataForm.get('country_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="companyDataForm.get('country_id').touched && companyDataForm.get('country_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'region' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="cities" [formControl]="companyDataForm.get('city_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="cityChanged()"
                                (onClick)="getCities(null, companyDataForm.get('country_id').value)"
                                placeholder="{{'selectRegion' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCityName(companyDataForm.get('city_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                            + ' - ' + item?.code}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="companyDataForm.get('city_id').touched && companyDataForm.get('city_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'area' | translate}}</label>
                    <p-dropdown [options]="areas" [formControl]="companyDataForm.get('area_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true"
                                placeholder="{{'selectArea' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getAreas(null, companyDataForm.get('city_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getAreaName(companyDataForm.get('area_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="companyDataForm.get('area_id').touched && companyDataForm.get('area_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="companyDataForm.controls['street']" placeholder="{{'street' | translate}}"
                                label="{{'street' | translate}}"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="companyDataForm.controls['postal_code']"
                                label="{{'postalCode' | translate}}" placeholder="{{'postalCode' | translate}}"
                                [type]="'number'"></app-text-input>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-8">
                <app-text-input [label]="'Location link'" (blurEvent)="getLangLat($event)"
                                [placeholder]="'Location link'"
                                [formControl]="companyDataForm.controls['location_link']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [label]="'Latitude'" [placeholder]="'Latitude'"
                                [formControl]="companyDataForm.controls['latitude']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [label]="'Longitude'" [placeholder]="'Longitude'"
                                [formControl]="companyDataForm.controls['longitude']"></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>Company Contact</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label>Phone</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Phone'"
                            formControlName="phone"
                    ></ngx-intl-tel-input>
                    <small *ngIf="companyDataForm.controls.phone.touched && companyDataForm.controls.phone.errors?.required"
                           class="p-error">required</small>
                    <small class="p-error"
                           *ngIf="companyDataForm.controls.phone.touched && companyDataForm.controls.phone.invalid">
                        Invalid number
                    </small>
                </div>
            </div>

            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label>Fax</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [phoneValidation]=""
                            [maxLength]="15"
                            [customPlaceholder]="'Fax'"
                            formControlName="fax"
                    ></ngx-intl-tel-input>
                    <small *ngIf="companyDataForm.controls.fax.touched && companyDataForm.controls.fax.errors?.required"
                           class="p-error">required</small>
                    <small class="p-error"
                           *ngIf="companyDataForm.controls.fax.touched && companyDataForm.controls.fax.invalid">
                        Invalid number
                    </small>
                </div>
            </div>
            <div class="p-col-12 p-md-3">
                <app-text-input [type]="'email'" [label]="'Email'" [placeholder]="'Email'"
                                [formControl]="companyDataForm.controls['email']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-text-input [type]="'text'" [label]="'Website'" [placeholder]="'Website'"
                                [formControl]="companyDataForm.controls['website']"
                ></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>Social</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [label]="'Facebook'" [placeholder]="'Facebook'"
                                [formControl]="companyDataForm.controls['facebook']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [label]="'Linkedin'" [placeholder]="'Linkedin'"
                                [formControl]="companyDataForm.controls['linkedin']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [label]="'Twitter'" [placeholder]="'Twitter'"
                                [formControl]="companyDataForm.controls['twitter']"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [label]="'Instagram'" [placeholder]="'Instagram'"
                                [formControl]="companyDataForm.controls['instagram']"></app-text-input>
            </div>
        </div>
    </div>
    <div class="buttons" *ngIf="hasEditPermission">
        <button pButton type="submit" class="float-right" label="{{isAddMode ? 'Create' : ('saveChanges' | translate)}}"></button>
    </div>
</form>
