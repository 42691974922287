<div class="row">
    <div class="col-md-6">
        <h4 class="mb-3">{{course.name}}</h4>
    </div>
    <div class="col-md-6">
        <button *ngIf="this.coursesService.course?.is_publish_required && this.coursesService.course.show_website == 1"
                class="p-button-danger float-right" pButton type="button"
                (click)="publishToWebsite()" pRipple>{{'publish' | translate}}</button>
    </div>
</div>
<p-tabMenu [model]="tabItems" [activeItem]="activeTab"></p-tabMenu>
<router-outlet></router-outlet>

