import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestDetails} from '../../../../_models/request-details';
import {RequestAPIService} from '../../../_services/request-api.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {SharedService} from '../../../../_services/shared.service';
import {Player} from '../../../../_models/player';
import {CompaniesService} from '../../../../_services/companies-service';
import {TuiService} from '../../../_services/tui.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-players-form',
    templateUrl: './players-form.component.html',
    styleUrls: ['./players-form.component.css']
})
export class PlayersFormComponent implements OnInit, OnChanges {

    @Input() request: RequestDetails;
    @Input() clientsParams: any;
    form: UntypedFormGroup;
    genderOptions = ['Male', 'Female'];
    @Output() playersUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private fb: UntypedFormBuilder, private requestService: RequestAPIService,
                private confirmationService: ConfirmationService, private messageService: MessageService,
                private sharedService: SharedService, private companiesService: CompaniesService,
                private tuiService: TuiService, public translate: TranslatePipe) {
    }

    ngOnInit(): void {
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.initForm();
        if (!this.tuiService.tuiParsedParams) {
            this.fillPlayers();
        }
        if (changes?.clientsParams?.currentValue) {
            this.fillTuiPlayers();
        }
    }


    initForm() {
        this.form = this.fb.group({
            players: this.fb.array([])
        });
    }

    get _players() {
        return this.form.get('players') as UntypedFormArray;
    }

    fillPlayers() {
        if (this.request?.players.length > 0) {
            this._players.clear();
            this.request.players.forEach((player, index) => {
                this._players.push(
                    this.fb.group({
                        id: player.id,
                        first_name: [player.first_name, Validators.required],
                        last_name: [player.last_name, Validators.required],
                        gender: [player.gender, Validators.required],
                        hcp: [player.hcp, Validators.required],
                        show_additional: 1,
                        additional_information: [player.additional_information]
                    })
                );
                if (player.additional_information) {
                    this._players.controls[index].get('show_additional').setValue(1);
                }
            });
        } else {
            this.addPlayer();
        }
    }

    addPlayer() {
        this._players.push(
            this.fb.group({
                id: null,
                first_name: [null, Validators.required],
                last_name: [null, Validators.required],
                gender: ['Male', Validators.required],
                hcp: [null, Validators.required],
                show_additional: 1,
                additional_information: [null]
            })
        );
    }

    updatePlayer(pIndex) {
        if (!this._players.controls[pIndex].valid) {
            this._players.controls[pIndex].markAllAsTouched();
            return false;
        }
        if (this._players.controls[pIndex].value.id !== null &&
            !this.sharedService.checkObjectChanges(this._players.controls[pIndex].value, this.request.players[pIndex])) {
            const player = this._players.getRawValue()[pIndex];
            this.requestService.updatePlayer(player).subscribe((res: any) => {
                this.request = res.request;
                this.playersUpdatedEvent.emit(this.request.players);
            });
        }
    }

    removePlayer(playerIndex) {
        if (this._players.controls[playerIndex].value.id == null) {
            this._players.removeAt(playerIndex);
        } else {
            if (!this.requestService.checkPlayerAssignee(this.request, playerIndex)) {
                this.messageService.add({
                    severity: 'error', summary: 'Delete Player',
                    detail: 'A player assigned to a tee time cannot be deleted'
                });
            } else {
                this.confirmationService.confirm({
                    message: this.translate.transform('confirmMessage'),
                    accept: () => {
                        this.requestService.deletePlayer(this.request.players[playerIndex].id).subscribe((res: any) => {
                            this.request = res.request;
                            this._players.removeAt(playerIndex);
                        });
                    },
                    acceptLabel: this.translate.transform('yes'),
                    rejectLabel: this.translate.transform('no'),
                    header: this.translate.transform('confirmation')
                });
            }
        }
    }

    public submit(): Player[] | boolean {
        if (!this.form.valid) {
            this.form.get('players').markAllAsTouched();
            return false;
        }
        const clone = this.form.getRawValue().players;
        return clone;
    }

    getPlayers() {
        return this.request.players;
    }

    fillTuiPlayers() {
        console.log('filltui');
        
        this._players.clear();
        this.tuiService.tuiParsedParams.clients.forEach(player => {
            if (player.first_name || player.last_name) {
                this.addPlayer();
            }
        });
        this.tuiService.tuiParsedParams.clients.forEach((client, index) => {
            if (client.first_name || client.last_name) {
                this._players.controls[index].get('first_name').setValue(client.first_name);
                this._players.controls[index].get('last_name').setValue(client.last_name);
                this._players.controls[index].get('gender').setValue(client.gender
                    .toLocaleLowerCase() === 'm' ? 'Male' : 'Female');
            }
        });
    }

    additional(event, i) {
        this._players.controls[i].get('show_additional').setValue(event.checked);
        if (event.checked === 0) {
            this._players.controls[i].get('additional_information').setValue(null);
            this.updatePlayer(i);
        }
    }
}
