import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ProductAPIService} from '../../_services/product-api.service';
import {ProductService} from '../../../_models/product-service';
import {SharedService} from '../../../_services/shared.service';

@Component({
    selector: 'app-product-season-form',
    templateUrl: './product-season-form.component.html',
    styleUrls: ['./product-season-form.component.css']
})
export class ProductSeasonFormComponent implements OnInit {

    season: any;
    isEditMode = false;
    seasonForm: UntypedFormGroup;
    services: ProductService[];
    selectedService: ProductService;
    yearRange: string;
    colorsPalette = [
        {code: '#eca400', name: 'Marigold'},
        {code: '#eaf8bf', name: 'Pale Spring Bud'},
        {code: '#150578', name: 'Navy Blue'},
        {code: '#78c0e0', name: 'Dark Sky Blue'},
        {code: '#f06c9b', name: 'Cyclamen'},
        {code: '#6b654b', name: 'Gold Fusion'},
        {code: '#ff331f', name: 'Red RYB'},
        {code: '#4c1c00', name: 'Seal Brown'},
        {code: '#ffd447', name: 'Mustard'},
        {code: '#320d6d', name: 'Parisian Indigo'},
        {code: '#7cdedc', name: 'Middle Blue Green'},
        {code: '#9e788f', name: 'Mountbatten Pink'},
    ];
    companyId: number;

    constructor(
        private fb: UntypedFormBuilder,
        private breadcrumbService: BreadcrumbService,
        private activatedRoute: ActivatedRoute,
        private msgService: MessageService, private messageService: MessageService,
        private prodAPIService: ProductAPIService, private sharedService: SharedService
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Seasons', routerLink: '/product-setup/seasons'},
            {label: this.activatedRoute.snapshot.paramMap.get('id') ? 'Edit Season' : 'New Season'}
        ]);
        this.yearRange = `${new Date().getFullYear()}:${new Date().getFullYear() + 15}`;
    }

    ngOnInit(): void {
        if (this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.companyId = +this.activatedRoute.snapshot.queryParamMap.get('company_id');
        }
        this.initForm();
        const seasonId = +this.activatedRoute.snapshot.paramMap.get('id');
        if (seasonId > 0) {
            this.isEditMode = true;
            this.prodAPIService.getSeason(seasonId).subscribe((res: any) => {
                this.season = res.season;
                this.season.start_date = new Date(this.season.start_date);
                this.season.end_date = new Date(this.season.end_date);
                this.season.color = this.colorsPalette.filter(color => color.code == this.season.color)[0];
                this.season.service_id = {
                    id: this.season.service_id,
                    name: this.season.service_name
                };
                this.seasonForm.patchValue(this.season);
            });
            // TODO: Patch form value
        }
    }

    initForm() {
        this.seasonForm = this.fb.group({
            id: null,
            service_id: [null, Validators.required],
            title: [null, Validators.required],
            code: [null, [Validators.required, Validators.maxLength(1)]],
            start_date: [null, Validators.required],
            end_date: [null, Validators.required],
            color: [null, Validators.required]
        });
    }

    submit(): void {
        if (!this.seasonForm.valid) {
            this.seasonForm.markAllAsTouched();
            return;
        }
        const obj = this.seasonForm.value;
        obj.start_date = this.sharedService.dateToIsoString(obj.start_date);
        obj.end_date = this.sharedService.dateToIsoString(obj.end_date);
        obj.service_id = obj.service_id.id;
        obj.color = obj.color.code;
        if (!this.isEditMode) {
            this.prodAPIService.addSeason(obj).subscribe(res => {
                this.messageService.add({
                    severity: 'success', summary: 'Create New Season',
                    detail: 'New season has been created successfully'
                });
                this.seasonForm.reset();
            });
        } else {
            this.prodAPIService.updateSeason(obj).subscribe(res => {
                this.messageService.add({
                    severity: 'success', summary: 'Update Season',
                    detail: 'Selected season has been updated successfully'
                });
            });
        }
    }

    searchServices(ev) {
        this.prodAPIService.searchService(ev.query, this.companyId).subscribe(res => {
            this.services = res;
        });
    }

    clearEndDate() {
        this.seasonForm.get('end_date').setValue(null);
    }

    serviceSelected(event: ProductService) {
        this.selectedService = event;
        this.selectedService.validity_from = new Date(this.selectedService.validity_from);
        this.selectedService.validity_to = new Date(this.selectedService.validity_to);
    }
}
