import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GGTreeNode, MixedNode} from '../../_models/GGTreeModel';
import {GgTree} from '../../_classes/ggTree';

@Component({
  selector: 'app-gg-tree',
  templateUrl: './gg-tree.component.html',
  styleUrls: ['./gg-tree.component.css']
})
export class GgTreeComponent implements OnInit, OnChanges {
  @Input() inputTreeValues: MixedNode[];
  @Input() initSelections: any[];
  @Input() select!: GGTreeNode[];
  @Output() selectChange: EventEmitter<GGTreeNode[]> = new EventEmitter<GGTreeNode[]>();

  treeValues: GGTreeNode[];
  selections: GGTreeNode[];
  isLoadingTree = false;

  tree = new GgTree({data: [], filterPaths: false});

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputTreeValues && changes.inputTreeValues.currentValue) {
      this.tree.options.data = changes.inputTreeValues.currentValue;
      this.treeValues = this.tree.getTree();

      if (this.initSelections) {
        this.selections = this.tree.getSelectedNodes(this.initSelections, 'id');
        this.initSelections = null;
        this.selector(this.selections);
      }
    }

    this.initSelections = changes.initSelections?.currentValue || this.initSelections;
    if (this.initSelections && this.treeValues) {
      this.selections = this.tree.getSelectedNodes(this.initSelections, 'id');
    }
  }

  selector(selections: GGTreeNode[]) {
    this.select = selections;
    this.selectChange.emit(this.select);
  }

}
