import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-seasons',
  templateUrl: './company-seasons.component.html',
  styleUrls: ['./company-seasons.component.css']
})
export class CompanySeasonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
