<div class="p-grid">
    <div class="p-md-3">
        <app-tree-view [object]="'GolfCourses'" [l4Data]="courses" (selectedCity)="citySelected($event)"
                       (selectedL4Item)="courseSelected($event)"></app-tree-view>
    </div>
    <div class="p-md-9">
        <div *ngIf="selectedCourse" class="card toolbar-demo mt-3">
            <h5>Golf Course Details</h5>
            <a (click)="viewFullCourse()" style="cursor:pointer;">View full details</a>
            <app-general [golfCourseId]="selectedCourse.id"></app-general>
        </div>
    </div>
</div>
