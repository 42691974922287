<div class="p-grid">
    <div class="p-col-4">
        <h5>New Golf Product</h5>
    </div>
    <!--<div class="p-col-4 text-center">
        <p-selectButton [styleClass]="'contract-checkbox'" [options]="options" [(ngModel)]="selectedType"
                        optionValue="id" optionLabel="name"></p-selectButton>
    </div>-->
    <div class="p-col-12">
        <app-gfp-form-single *ngIf="selectedType == 0"></app-gfp-form-single>
        <app-gfp-package *ngIf="selectedType == 1"></app-gfp-package>
    </div>
</div>
