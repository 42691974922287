import {Component, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {PackageCmsService} from '../_services/package-cms.service';
import {Lookup} from '../../_models/lookup';
import {ImagesListComponent} from '../../_shared/images-list/images-list.component';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-package-form',
    templateUrl: './package-form.component.html',
    styleUrls: ['./package-form.component.css']
})
export class PackageFormComponent implements OnInit {

    form: UntypedFormGroup;
    addMode = true;
    fieldTypes: any[] = [];
    package: any;
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];
    @ViewChild('uploader', {static: false}) imagesUploader: ImagesListComponent;

    constructor(private breadcrumbService: BreadcrumbService,
                private fb: UntypedFormBuilder,
                private packagesService: PackageCmsService,
                private messageService: MessageService,
                private router: Router,
                private route: ActivatedRoute,
                public translate: TranslatePipe,
                public translateService: TranslateService,
                private messageServices: MessageService,
                private loader: NgxUiLoaderService) {
    }

    async ngOnInit() {
        if (this.route.snapshot.paramMap.get('id')) {
            this.addMode = false;
        }
        setTimeout(() => {
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: this.translate.transform('packageCMS'), routerLink: ['/package-cms']},
                {label: this.translate.transform(this.addMode ? 'addPackageCMS' : 'editPackageCMS')}
            ]);
        }, 300);
        this.initForm();
        await this.getFieldTypes();
        if (this.route.snapshot.paramMap.get('id')) {
            this.getPackageById();
        }
    }

    getPackageById() {
        this.packagesService.getPackageById(this.route.snapshot.paramMap.get('id'))
            .subscribe((res: any) => {
                this.package = res;
                this.form.patchValue(this.package);
                this.fillFieldsData(this.package.fields);
            });
    }

    initForm() {
        this.form = this.fb.group({
            id: null,
            name: [null, Validators.required],
            davinci_booking_code: [null, Validators.required],
            fields: this.fb.array([]),
        });
    }

    async getFieldTypes() {
        await this.packagesService.getFieldTypes().toPromise().then(res => {
            this.fieldTypes = res;
            if (this.addMode) {
                this.initFieldsData();
            }
        });
    }

    getFieldName(fieldId) {
        const name = this.fieldTypes
            .filter(x => x.id === +fieldId)[0].translations[this.translateService.currentLang === 'de' ? 1 : 0].name;
        return name;
    }


    get fields() {
        return this.form.controls.fields as UntypedFormArray;
    }

    initFieldsData() {
        this.fieldTypes.forEach((fl, index) => {
            this.fields.push(
                this.fb.group({
                    id: fl.id,
                    type_id: fl.id,
                    is_html: 0,
                    language_mode: 2,
                    translations: this.fb.array([])
                })
            );
            const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
            fl.translations.forEach((translation, n) => {
                fieldTranslations.push(
                    this.fb.group({
                        language_id: translation.language_id,
                        description: fl.translations[n]?.description
                    })
                );
            });
        });
    }

    fillFieldsData(fields: any[]) {
        this.fieldTypes?.forEach((fl, index) => {
            const field = fields.filter(x => +x.type_id === fl.id)[0];
            if (field) {
                this.fields.push(
                    this.fb.group({
                        id: field.id,
                        type_id: field.type_id,
                        is_html: field.is_html,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach((translation, n) => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: field.translations[n]?.description
                        })
                    );
                });
            } else {
                this.fields.push(
                    this.fb.group({
                        id: null,
                        type_id: fl.id,
                        is_html: 0,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach(translation => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: null
                        })
                    );
                });
            }
        });
    }

    submitForm() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return false;
        }
        this.form.value.images = this.imagesUploader.form.value;
        if (this.addMode) {
            this.addPackage(this.form.value);
        } else {
            this.editPackage(this.form.value);
        }
    }

    addPackage(formValue) {
        this.packagesService.createPackage(formValue).subscribe((res: any) => {
            this.form.get('id').setValue(res.data.id);
            if (this.imagesUploader && this.imagesUploader.getFiles().length > 0) {
                this.uploadImages(res.data.id);
            } else {
                this.messageService.add({
                    severity: 'success', summary: this.translate.transform('packageCMS'),
                    detail: this.translate.transform('addPackageMessage')
                });
                this.router.navigate(['../edit/' + res.data.id], {relativeTo: this.route});
            }
        }, error => {
          if (error.error.message.includes('booking_code_unique')) {
            this.messageService.add({severity: 'error', summary: this.translate.transform('packageCMS'),
              detail: this.translate.transform('packageCMSDuplicatedCode')});
          }
        });
    }

    editPackage(formValue) {
        this.packagesService.updatePackage(formValue, this.route.snapshot.paramMap.get('id')).subscribe((res: any) => {
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('packageCMS'),
                detail: this.translate.transform('updatePackageMessage')
            });
        }, error => {
          if (error.error.message.includes('booking_code_unique')) {
            this.messageService.add({severity: 'error', summary: this.translate.transform('packageCMS'),
              detail: this.translate.transform('packageCMSDuplicatedCode')});
          }
        });
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }
    deleteImage(idsList) {
        this.packagesService.deleteRoomImages(this.form.value.id, {image_id: idsList}).subscribe((res: any) => {
            this.package.images = res.package.images;
        });
    }

    uploadImages(newPackId = 0) {
        const files = this.imagesUploader.getFiles();
        const formData: FormData = new FormData();
        if (files.length === 0 || !this.form.get('id').value) {
            return false;
        }
        this.loader.start('uploadImages');
        files.forEach(file => {
            formData.append('images[]', file);
            formData.append('size[]', file.size.toString());
            formData.append('original_file_name[]', file.name);
        });
        this.packagesService.uploadRoomImages(this.form.value.id, formData).subscribe((res: any) => {
            this.imagesUploader.clearUploader();
            this.loader.stop('uploadImages');
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('packageCMS'),
                detail: this.translate.transform('addPackageMessage')
            });
            this.package = res.package;
            if (newPackId > 0) {
                this.router.navigate(['../edit/' + newPackId], {relativeTo: this.route});
            }
        });
    }
    changeMainImage(id) {
        this.packagesService.changeMainImage(this.package.id, {image_id: id}).subscribe((res: any) => {
            this.package.images = res.package.images;
            this.messageServices.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelRoomMessage')
            });
        });
    }
}
