import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {SharedService} from '../../../../_services/shared.service';
import {User} from '../../../../_models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {AddressBookService} from '../../../../_services/address-book.service';
import {UsersServices} from '../../../../_services/users-services';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-company-address-book',
    templateUrl: './company-address-book.component.html',
    styleUrls: ['./company-address-book.component.css']
})
export class CompanyAddressBookComponent implements OnInit {


    showDialog: boolean;
    selectedContact: User;
    contacts: User[];
    contactForm: UntypedFormGroup;
    companyId: number;
    user: User;

    constructor(private breadcrumbService: BreadcrumbService, private confirmationService: ConfirmationService,
                private messageService: MessageService, private usersService: UsersServices,
                private fb: UntypedFormBuilder, private sharedService: SharedService, private route: ActivatedRoute,
                private addressBookService: AddressBookService, private router: Router,
                private usersServices: UsersServices, public translate: TranslatePipe) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Companies', routerLink: '/admin/companies'},
            {label: 'Company Contacts'}
        ]);
    }

    ngOnInit(): void {
        this.companyId = +this.route.parent.snapshot.paramMap.get('id');
        this.getAllContacts();
        this.initForm();
    }

    initForm() {
        this.contactForm = this.fb.group({
            id: null,
            email: [null, Validators.required],
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            mobile_number: [null, Validators.required],
            department: null,
            title: null,
            user_id: null,
        });
    }

    getAllContacts() {
        this.addressBookService.getAllContacts(this.companyId).subscribe((res: any) => {
            this.contacts = res.address_books;
        });
    }

    addNew() {
        this.clearForm();
        this.showDialog = true;
    }

    edit(contact) {
        this.selectedContact = contact;
        this.showDialog = true;
        this.contactForm.patchValue(this.selectedContact);
        if (this.selectedContact.user_id) {
            this.getUser();
        }
    }

    getUser() {
        this.usersService.getUser(this.selectedContact.user_id).subscribe(res => {
            this.user = res;
        });
    }

    addAsUser(contact) {
        const obj = {...contact};
        obj.address_book_id = obj.id;
        delete obj.id;
        this.router.navigate(['/admin/users/add'], {queryParams: obj});
    }

    delete(contact) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.addressBookService.deleteContact(contact.id).subscribe(() => {
                    this.getAllContacts();
                    this.messageService.add({
                        severity: 'success', summary: 'Delete Contact',
                        detail: 'Selected contact has been deleted successfully'
                    });
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    submit() {
        if (!this.contactForm.valid) {
            this.contactForm.markAllAsTouched();
            return false;
        }

        const formValue = {...this.contactForm.value};
        formValue.company_id = this.companyId;
        formValue.mobile_number = formValue.mobile_number?.internationalNumber?.toString().replace(/\s/g, '');

        if (this.selectedContact) {
            this.addressBookService.updateContact(formValue, formValue.id).subscribe(res => {
                this.showDialog = false;
                this.getAllContacts();
                this.messageService.add({
                    severity: 'success', summary: 'Update Contact',
                    detail: 'Contact has been updated successfully'
                });
                if (formValue.user_id) {
                    console.log(this.user);
                    const userObj = {...formValue, address_book_id: this.user.address_book_id, role_id: this.user.role_id};
                    userObj.id = userObj.user_id;
                    this.usersService.updateUser(userObj).subscribe(resp => {
                        console.log(resp);
                    });
                }
            });
        } else {
            this.addressBookService.addContact(formValue).subscribe(res => {
                this.showDialog = false;
                this.getAllContacts();
                this.messageService.add({
                    severity: 'success', summary: 'Add New Contact',
                    detail: 'New contact has been added successfully'
                });
            });
        }
    }

    clearForm() {
        this.selectedContact = null;
        this.contactForm.reset();
    }

}
