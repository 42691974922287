import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Permission } from 'src/app/_models/roles-permissions';
import { RolesPermissionService } from 'src/app/_services/roles-permission.service';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {tap} from 'rxjs/operators';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {

  permission: any;
  selectedPermissions: any[];
  permissions$: Observable<Permission[]>;
  menuActions = ['View', 'Edit', 'Delete'];

  // Filter vars
  tableHelper = new GgTableCustomFN();
  filters = {
    module_name: [],
    page_name: [],
  };
  booleanFilter = [{name: this.translate.transform('active'), val: 1},
    {name: this.translate.transform('inactive'), val: 0}];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private rolesPermissionsAPI: RolesPermissionService,
    private accountService: AccountService,
    public translate: TranslatePipe
  ) {
    this.breadcrumbService.setItems([
      { label: 'Dashboard', routerLink: '/' },
      { label: 'Permissions' }
    ]);
  }

  async ngOnInit() {
    this.getAllPermissions();
  }

  addNew() {
    this.router.navigate(['add'], { relativeTo: this.activatedRoute });
  }

  getAllPermissions(): void {
    try {
      this.permissions$ = this.rolesPermissionsAPI.getPermissions().pipe(tap(permissions => {
        this.tableHelper.populateFilter(this.filters, permissions);
      }));
    } catch (error) {
      console.error('Get Permissions Error', error);
      this.messageService.add({
        severity: 'error', summary: 'Listing Permissiosn',
        detail: 'Something unexpected went wrong, please refresh the page!'
      });
    }
  }

  async changePermissionStatus(permission: Permission): Promise<void> {
    try {
      // const permission: Permission = await this.rolesPermissionsAPI.getPermissionDetails(permissionId);
      // permission.status = permission.status ? 1 : 0;
      // update permission api
      this.rolesPermissionsAPI.updatePermission(permission).then(res => {
        if (res) {
          this.messageService.add({
            severity: 'success', summary: 'Permission Updated',
            detail: 'Permission has been updated successfully!'
          });
        }
      }).catch(error => {
        permission.status = permission.status > 0 ? 0 : 1;
        console.error('Update Role Error', error);
        this.messageService.add({
          severity: 'error', summary: 'Update Role',
          detail: 'Something unexpected went wrong, please refresh the page!'
        });
      });
    } catch (error) {
      console.error('Update Permission Error', error);
      this.messageService.add({
        severity: 'error', summary: 'Update Permission',
        detail: 'Something unexpected went wrong, please refresh the page!'
      });
    }
  }

  edit(permission: Permission) {
    this.router.navigate(['edit', permission.id], { relativeTo: this.activatedRoute });
  }

  async delete(permission: Permission) {
    try {
      await this.rolesPermissionsAPI.deletePermission(permission.id);
      this.messageService.add({
        severity: 'success', summary: 'Permission Deleted',
        detail: 'Permission has been deleted successfully'
      });
      this.permissions$ = this.rolesPermissionsAPI.getPermissions();
    } catch (error) {
      console.error('Delete Permission Error', error);
      this.messageService.add({
        severity: 'error', summary: 'Permission Delete',
        detail: 'Something unexpected went wrong'
      });
    }
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedPermissions || this.selectedPermissions.length < 1) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedPermissions);
  }
}
