import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AccountService} from '../_services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import * as Bowser from 'bowser';

@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {

    dark: boolean;

    checked: boolean;
    @ViewChild('usernameInput') usernameInput: ElementRef;
    userData = {
        browserName: '',
        ip: '',
        country: '',
        city: ''
    };

    constructor(private accountService: AccountService, private router: Router, private messageService: MessageService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
            this.router.navigateByUrl('/');
        } else if (this.route.snapshot.queryParamMap.has('client_token')) {
            this.accountService.tokenLogin(this.route.snapshot.queryParamMap.get('client_token')).subscribe(res => {
                this.router.navigate(['/client-portal']);
            });
        }
        this.userData.browserName = this.detectBrowserName();
        // this.getUserIP();
    }

    submitLogin(loginForm: NgForm) {
        const obj = {...loginForm.value,
            browser_name: this.userData.browserName,
            ip: this.userData.ip,
            geoip_city_name: this.userData.country + ' - ' + this.userData.city};
        this.accountService.login(obj).subscribe((res: any) => {
            const user = {...res};
            if (user.role_id == 4) {
                this.router.navigate(['/client-portal']);
            } else {
                this.router.navigate(['/']);
            }
        }, error => {
            this.messageService.add({
                severity: 'error', summary: error.statusText,
                detail: 'Wrong username or password!'
            });
        });
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (this.usernameInput.nativeElement.selectionStart === 0 && event.key === ' ') {
            event.preventDefault();
        }
    }
    detectBrowserName() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        console.log(browser);
        return browser.getBrowserName();
    }
    getUserIP() {
        this.accountService.getUserIP().subscribe((res: any) => {
           this.userData.ip = res.ip;
           this.userData.country = res.country_name;
           this.userData.city = res.city;
        });
    }
}
