import { Component, OnInit } from '@angular/core';
import {HotelsService} from '../../_services/hotels.service';

@Component({
  selector: 'app-hotels-tree',
  templateUrl: './hotels-tree.component.html',
  styleUrls: ['./hotels-tree.component.css']
})
export class HotelsTreeComponent implements OnInit {
  hotels: any[];
  selectedHotel: any;

  constructor(private hotelsService: HotelsService) {
  }

  ngOnInit() {
  }

  citySelected(event) {
    this.hotelsService.getHotelsByCity(event.id).subscribe((res: any) => {
      this.hotels = res;
      this.hotels.map(hotel => {
        hotel.label = hotel.name;
        hotel.level = 3;
        hotel.indexes = [];
        hotel.key = 'hotel-' + hotel.id;
      });
    });
  }
  hotelSelected(event) {
    this.selectedHotel = event;
  }

}
