import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';

@Component({
    selector: 'app-add-company',
    templateUrl: './add-company.component.html',
    styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {

    constructor(private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit(): void {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Companies', routerLink: ['/admin/companies']},
            {label: 'New Company'},
        ]);
    }
}
