import {Component, OnInit, ViewChild} from '@angular/core';
import {Document} from '../../../../_models/document';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../../../_models/lookup';
import {FileUpload} from 'primeng/fileupload';
import {BreadcrumbService} from '../../../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DocumentsService} from '../../../../_services/documents.service';
import {SharedService} from '../../../../_services/shared.service';
import {ActivatedRoute} from '@angular/router';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-company-documents',
    templateUrl: './company-documents.component.html',
    styleUrls: ['./company-documents.component.css']
})
export class CompanyDocumentsComponent implements OnInit {

    showDialog: boolean;
    selectedDocument: Document;
    documents: Document[];
    documentForm: UntypedFormGroup;
    documentTypes: Lookup[];
    minDate = new Date();
    yearRange: string;
    @ViewChild('uploader', {static: true}) uploader: FileUpload;
    companyId: number;

    constructor(private breadcrumbService: BreadcrumbService, private confirmationService: ConfirmationService,
                private messageService: MessageService, private documentsService: DocumentsService,
                private fb: UntypedFormBuilder, private sharedService: SharedService,
                private route: ActivatedRoute, public translate: TranslatePipe) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Companies', routerLink: '/admin/companies'},
            {label: 'Company Documents'}
        ]);
        this.yearRange = `${new Date().getFullYear()}:${new Date().getFullYear() + 15}`;
    }

    ngOnInit(): void {
        this.companyId = +this.route.parent.snapshot.paramMap.get('id');
        this.getAllDocuments();
        this.initForm();
        this.getDocumentTypes();
    }

    initForm() {
        this.documentForm = this.fb.group({
            id: [null],
            title: [null, Validators.required],
            document_type_id: [null, Validators.required],
            expire_date: [null, Validators.required],
            is_notify: [0]
        });
    }

    getAllDocuments() {
        this.documentsService.getAllDocuments(this.companyId).subscribe((res: any) => {
            this.documents = res.documents.data;
        });
    }

    getDocumentTypes() {
        this.documentsService.getDocumentTypes().subscribe((res: any) => this.documentTypes = res.types);
    }

    addNew() {
        this.clearForm();
        this.showDialog = true;
    }

    edit(document) {
        this.selectedDocument = document;
        this.selectedDocument.expire_date = new Date(this.selectedDocument.expire_date);
        this.showDialog = true;
        this.documentForm.patchValue(this.selectedDocument);
    }

    delete(document) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.documentsService.deleteDocument(document.id).subscribe(() => {
                    this.getAllDocuments();
                    this.messageService.add({
                        severity: 'success', summary: 'Delete Document',
                        detail: 'Selected document has been deleted successfully'
                    });
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    submit() {
        const formData: FormData = new FormData();
        formData.append('title', this.documentForm.value.title);
        formData.append('document_type_id', this.documentForm.value.document_type_id);
        formData.append('expire_date', this.sharedService.dateToIsoString(this.documentForm.value.expire_date));
        formData.append('is_notify', this.documentForm.value.is_notify ? this.documentForm.value.is_notify : 0);
        formData.append('company_id', this.companyId.toString());
        this.uploader.files.forEach(file => {
            formData.append('file', file);
        });
        if (this.selectedDocument) {
            this.documentsService.updateDocument(formData, this.selectedDocument.id).subscribe(res => {
                this.showDialog = false;
                this.uploader.clear();
                this.getAllDocuments();
                this.messageService.add({
                    severity: 'success', summary: 'Update Document',
                    detail: 'Document has been updated successfully'
                });
            });
        } else {
            this.documentsService.addDocument(formData).subscribe(res => {
                this.showDialog = false;
                this.uploader.clear();
                this.getAllDocuments();
                this.messageService.add({
                    severity: 'success', summary: 'Add New Document',
                    detail: 'New document has been added successfully'
                });
            });
        }
    }

    convertTimeStampToString(date) {
        return (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    }

    viewDocument(document) {
        if (document.file_type == 'pdf') {
            window.open(document.file_path, '_blank');
        }
    }

    clearForm() {
        this.selectedDocument = null;
        this.documentForm.reset();
        if (this.uploader?.files.length > 1) {
            this.uploader.clear();
        }
    }

}
