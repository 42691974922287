import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductSetupComponent} from './product-setup.component';
import {ProductServicesComponent} from './product-services/product-services.component';
import {ProductServicesFormComponent} from './product-services/product-services-form/product-services-form.component';
import {ProductSeasonComponent} from './product-season/product-season.component';
import {ProductSeasonFormComponent} from './product-season/product-season-form/product-season-form.component';
import {GfpFormBulkComponent} from './gfp/gfp-form-bulk/gfp-form-bulk.component';
import {GfpComponent} from './gfp/gfp.component';
import {GfpFormSingleComponent} from './gfp/gfp-form/gfp-form-single/gfp-form-single.component';
import {HotelProductsComponent} from './hotel-products/hotel-products.component';
import {HotelProductsFormSingleComponent} from './hotel-products/hotel-products-form-single/hotel-products-form-single.component';
import {HotelProductsFormBulkComponent} from './hotel-products/hotel-products-form-bulk/hotel-products-form-bulk.component';
import {PriceListComponent} from './price-list/price-list.component';
import {PriceListFormComponent} from './price-list/price-list-form/price-list-form.component';
import {AuthGuard} from '../_guards/auth.guard';
import {AddPriceListComponent} from './price-list/add-price-list/add-price-list.component';
import {GolfHolidayComponent} from './golf-holiday/golf-holiday.component';
import {GfpFormComponent} from './gfp/gfp-form/gfp-form.component';
import {GfpPackageComponent} from './gfp/gfp-form/gfp-package/gfp-package.component';
import {GolfHolidayFormComponent} from './golf-holiday/golf-holiday-form/golf-holiday-form.component';

const routes: Routes = [
    {path: '', redirectTo: 'price-list', pathMatch: 'full'},
    {
        path: 'contracts', component: ProductServicesComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636369892311', type: 'action'}
    },
    {
        path: 'contracts/add', component: ProductServicesFormComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636369918214', type: 'action'}
    },
    {
        path: 'contracts/edit/:id', component: ProductServicesFormComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636369948581', type: 'action'}
    },
    {
        path: 'seasons', component: ProductSeasonComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636370019601', type: 'action'}
    },
    {
        path: 'seasons/add', component: ProductSeasonFormComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636370036704', type: 'action'}
    },
    {
        path: 'seasons/edit/:id', component: ProductSeasonFormComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636370066439', type: 'action'}
    },
    {
        path: 'gfp', children: [
            {path: '', redirectTo: './', pathMatch: 'full'},
            {
                path: '', component: GfpComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370131231', type: 'action'}
            },
            {
                path: 'add', component: GfpFormComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370157369', type: 'action'}
            },
            {
                path: 'add-bulk', component: GfpFormBulkComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370157369', type: 'action'}
            },
            {
                path: 'edit/:id', component: GfpFormSingleComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370191472', type: 'action'}
            },
            {
                path: 'edit-package/:id', component: GfpPackageComponent
            }
        ], canActivate: [AuthGuard], data: {requirePermission: true, page_id: 10, type: 'page'}
    },
    {
        path: 'hotel-products', children: [
            {path: '', redirectTo: './', pathMatch: 'full'},
            {
                path: '', component: HotelProductsComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370256051', type: 'action'}
            },
            {
                path: 'add', component: HotelProductsFormSingleComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370273100', type: 'action'}
            },
            {
                path: 'add-bulk', component: HotelProductsFormBulkComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370273100', type: 'action'}
            },
            {
                path: 'edit/:id', component: HotelProductsFormSingleComponent, canActivate: [AuthGuard],
                data: {requirePermission: true, code: '1636370307957', type: 'action'}
            }
        ], canActivate: [AuthGuard], data: {requirePermission: true, page_id: 11, type: 'page'}
    },
    {
        path: 'golf-holiday', children: [
            {path: '', redirectTo: './', pathMatch: 'full'},
            {path: '', component: GolfHolidayComponent},
            {
                path: 'add', component: GolfHolidayFormComponent, canActivate: [AuthGuard]
            },
            {
                path: 'edit/:id', component: GolfHolidayFormComponent, canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'price-list', children: [
            {path: '', redirectTo: './', pathMatch: 'full'},
            {path: '', component: PriceListComponent},
            {path: 'add', component: AddPriceListComponent},
            {path: 'edit/:id', component: PriceListFormComponent},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductSetupRoutingModule {
}
