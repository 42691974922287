<div *ngIf="form.controls.length > 0" class="card toolbar-demo">
    <div class="p-grid">
        <div class="p-col-6 p-md-3"
             *ngFor="let facility of  form.controls; let i=index">
            <!--<div class="p-grid">
                <div class="p-col-12 p-md-5 rem-padding">
                    <app-switch-input [label]="facility.get('name').value"
                                      [formControl]="facility.get('available')"
                    ></app-switch-input>
                </div>
                <div class="p-col-12 p-md-5 rem-padding">
                    <app-text-input [label]="'Count'" [formControl]="facility.get('number')"
                                    [type]="'number'"></app-text-input>
                </div>
                <div class="p-col-12 p-md-2">
                </div>
            </div>-->
            <div class="p-field">
                <label class="full-width">
                    {{facility.get('name').value}}</label>

                <p-inputSwitch class="switch-input" [formControl]="facility.get('available')"
                               [trueValue]="1" [falseValue]="0"></p-inputSwitch>
                <img *ngIf="facility.get('icon')?.value" [src]="'../../../../assets/services-icons/' +
                                        facility.get('icon')?.value + '.svg'"
                     width="22px" class="filter-petrol service-icon"/>
            </div>
        </div>
    </div>
    <div class="buttons mt-1">
        <button pButton type="button" (click)="submitForm()" class="float-right mr-4">{{'saveChanges' | translate}}</button>
    </div>
</div>

