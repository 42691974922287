import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {User} from '../_models/user';

@Injectable({
    providedIn: 'root'
})
export class UsersServices {
    baseUrl = environment.apiUrl;
    users: User[] = [];

    constructor(private http: HttpClient) {
    }

    updateUserData(obj) {
        return this.http.put(this.baseUrl + 'user/update-profile', obj);
    }

    changeProfileImage(obj) {
        return this.http.post<any>(this.baseUrl + 'user/update-image', obj);
    }

    changeUserProfileImage(obj, id) {
        return this.http.post<any>(this.baseUrl + 'users/update-image/' + id, obj);
    }

    deleteProfilePicture(userId) {
        return this.http.post<any>(this.baseUrl + 'users/remove-image/' + userId, null);
    }

    getPaginatedUsers(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'users?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + (searchKeyword ? searchKeyword : ''));
    }

    getCompanyUsers(companyId) {
        return this.http.get(this.baseUrl + 'companies/get/users?company_id=' + companyId);
    }

    getUser(id) {
        const user = this.users.find(x => x.id == id);
        if (user !== undefined) {
            return of(user);
        }
        return this.http.get<User>(this.baseUrl + 'users/' + id).pipe(map((res: any) => {
            return res.user;
        }));
    }

    deleteCompany(id) {
        return this.http.delete(this.baseUrl + 'companies/' + id).pipe(map(res => {
            this.users.slice(this.users.findIndex(x => x.id == id), 1);
            return of(this.users);
        }));
    }

    addUser(user) {
        return this.http.post(this.baseUrl + 'users', user);
    }

    updateUser(user) {
        return this.http.put(this.baseUrl + 'users/' + user.id, user);
    }

    deleteUser(userId) {
        return this.http.delete(this.baseUrl + 'users/' + userId);
    }

    searchUsers(value: string) {
        return this.http.get(this.baseUrl + 'companies/get/users?search=' + value);
    }
}
