<div class="card">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="p-grid">
            <div class="p-col-10">
                <h5>Create New Price List</h5>
            </div>
            <div class="p-col-2">
                <app-switch-input [formControl]="form.controls['active']" [label]="'Active'"
                                  [inline]="true" class="float-right"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-select-input [label]="'Service type'" [optionValue]="'id'" [optionLabel]="'name'"
                                  [options]="serviceTypes"
                                  [formControl]="form.controls['serviceType']"></app-select-input>
            </div>
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-select-input [label]="'Service'" [optionValue]="'id'" [optionLabel]="'name'"
                                  [options]="services"
                                  [formControl]="form.controls['service']"></app-select-input>
            </div>

            <div class="p-col-12 p-md-3 p-lg-col">
                <app-text-input [formControl]="form.controls['type']" [label]="'Name'" [placeholder]="'Name'"></app-text-input>
            </div>

            <div class="p-col-12 p-md-3 p-lg-col">
                <app-select-input [label]="'List type'" [optionValue]="'id'" [optionLabel]="'name'"
                                  [options]="listTypes"
                                  [formControl]="form.controls['type']"></app-select-input>
            </div>
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-select-input [label]="'Markup'" [optionValue]="'id'" [optionLabel]="'name'"
                                  [options]="markupList" (optionChanged)="markupChanged($event)"
                                  [formControl]="form.controls['markup']"></app-select-input>
            </div>
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-text-input [formControl]="form.controls['markupValue']" [label]="'Markup value'"
                                [placeholder]="'Markup value'" [type]="'number'"></app-text-input>
            </div>
        </div>
    </form>
</div>
