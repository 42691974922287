import {Component, OnInit} from '@angular/core';
import {Country, Region} from '../../_models/destinations';
import {Observable} from 'rxjs';
import {DestinationsService} from '../_services/destinations.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-region',
    templateUrl: './region.component.html',
    styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {
    regions$: Observable<Region[]>;
    selectedRegions: Region[];
    loading = false;

    // Filter vars
    tableHelper = new GgTableCustomFN();
    booleanFilter = [{name: this.translate.transform('active'), val: 1},
        {name: this.translate.transform('inactive'), val: 0}];

    constructor(
        private destService: DestinationsService,
        private msgService: MessageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private accountService: AccountService,
        public translate: TranslatePipe,
        private confirmationService: ConfirmationService
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('continents')},
        ]);
    }

    ngOnInit(): void {
        this.getAllRegions();
    }

    getAllRegions(): void {
        this.regions$ = this.destService.getRegionsListTable();
    }

    addNew(): void {
        this.router.navigate(['add'], {relativeTo: this.activatedRoute});
    }

    changeRegionStatus(region: Region): void {
        this.loading = true;
        const cloneRegion = {...region};
        cloneRegion.status = region.status ? 1 : 0;
        this.destService.updateRegion(cloneRegion).subscribe(res => {
            if (res) {
                this.loading = false;
                this.msgService.add({
                    severity: 'success',
                    summary: 'Update Continent',
                    detail: 'Continent has been updated successfully!'
                });
            }
        });
    }

    edit(region: Region): void {
        this.router.navigate(['edit', region.id], {relativeTo: this.activatedRoute});
    }

    delete(region: Region): void {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.loading = false;
                this.destService.deleteRegion(region.id).subscribe(res => {
                    if (res) {
                        this.getAllRegions();
                        this.loading = false;
                        this.msgService.add({
                            severity: 'success',
                            summary: this.translate.transform('deleteContinent'),
                            detail: this.translate.transform('deleteContinentMessage')
                        });
                    }
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    //  helpers
    printCountries(countries: Country[]): string {
        if (!countries) {
            return '';
        }

        const countriesName = [];

        for (const country of countries) {
            countriesName.push(country.name);
        }

        return countriesName.join(', ');
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedRegions || this.selectedRegions.length < 1) {
            this.msgService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedRegions);
    }
}
