import { Component, OnInit } from '@angular/core';
import {ProductAPIService} from '../../_services/product-api.service';

@Component({
  selector: 'app-product-services-tree',
  templateUrl: './product-services-tree.component.html',
  styleUrls: ['./product-services-tree.component.css']
})
export class ProductServicesTreeComponent implements OnInit {
  services: any[];
  selectedService: any;

  constructor(private prodAPIService: ProductAPIService) {
  }

  ngOnInit() {
  }

  citySelected(event) {
    this.prodAPIService.getServicesByCity(event.id).subscribe((res: any) => {
      this.services = res;
      this.services.map(service => {
        service.label = service.name;
        service.level = 3;
        service.indexes = [];
        service.key = 'service-' + service.id;
      });
    });
  }
  serviceSelected(event) {
    this.selectedService = event;
  }

}
