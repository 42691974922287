import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../_services/account.service';

@Component({
  selector: 'app-product-services',
  templateUrl: './product-services.component.html',
  styleUrls: ['./product-services.component.css']
})
export class ProductServicesComponent implements OnInit {

  activeView = 'table';
  companyId: number;

  constructor(private breadcrumbService: BreadcrumbService, private accountService: AccountService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.route.parent.snapshot.paramMap.get('id')) {
      this.companyId = +this.route.parent.snapshot.paramMap.get('id');
      this.breadcrumbService.setItems([
        {label: 'Dashboard', routerLink: '/'},
        {label: 'Companies', routerLink: '/admin/companies'},
        {label: 'Company Contracts'}
      ]);
    } else {
      this.breadcrumbService.setItems([
        {label: 'Dashboard', routerLink: '/'},
        {label: 'Product Setup'},
        {label: 'Contracts'},
      ]);
    }
  }

  addNew(): void {
    if (this.companyId) {
      this.router.navigate(['product-setup/contracts/add'],
          {queryParams: {company_id: this.companyId}});
    } else {
      this.router.navigate(['product-setup/contracts/add']);
    }
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }
  changeActiveView(view: string) {
    this.activeView = view;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        view: view
      }
    });
  }
}
