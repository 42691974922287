<div class="card">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="p-grid">
            <div class="p-col-10">
                <h5>Add New Price List</h5>
            </div>
            <div class="p-col-2">
                <app-switch-input [formControl]="form.controls['active']" [label]="'Active'"
                                  [inline]="true" class="float-right"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-select-input [label]="'Services'" [optionValue]="'id'" [optionLabel]="'name'"
                                  [options]="services" (optionChanged)="serviceSelected($event)"
                                  [formControl]="form.controls['service']"></app-select-input>
            </div>
            <div class="p-col-12 p-md-3 p-lg-col">
                <app-multi-select [options]="listTypes" [formControl]="form.controls['types']"
                                  [label]="'List type'" [placeholder]="'Select types'"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
        </div>
    </form>
    <p-table [value]="sales" responsiveLayout="scroll" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th>Seasons</th>

                <th [attr.colspan]="form.get('types').value.length"
                    *ngFor="let season of seasons">{{season.name}}</th>
            </tr>
            <tr>
                <th>Types</th>
                <ng-container *ngFor="let season of seasons">
                    <th *ngFor="let type of this.form.value.types">
                        {{getTypeName(type)}}
                    </th>
                </ng-container>
            </tr>
            <tr>
                <th>List name</th>
                <ng-container *ngFor="let season of seasons">
                    <th pEditableColumn *ngFor="let type of this.form.value.types">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input type="text" pInputText>
                            </ng-template>
                            <ng-template pTemplate="output">
                                name
                            </ng-template>
                        </p-cellEditor>

                </ng-container>
            </tr>
            <tr>
                <th>Markup</th>
                <ng-container *ngFor="let season of seasons">
                    <th pEditableColumn *ngFor="let type of this.form.value.types">
                        <p-cellEditor (click)="createMarkupList(type)">
                            <ng-template pTemplate="input">
                                <p-dropdown [options]="markupList" optionLabel="name"
                                            (onChange)="markupChanged($event)"></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                Manual
                            </ng-template>
                        </p-cellEditor>

                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sale>
            <tr>
                <td>{{sale.product}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[0].id)">{{sale.lastYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[0].id)">{{sale.thisYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[0].id)">{{sale.lastYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[1].id)">{{sale.thisYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[1].id)">{{sale.lastYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[1].id)">{{sale.thisYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[2].id)">{{sale.thisYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[2].id)">{{sale.lastYearSale}}</td>
                <td *ngIf="form.get('types').value.includes(listTypes[2].id)">{{sale.thisYearSale}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
