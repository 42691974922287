import {Component, OnInit, ViewChild} from '@angular/core';
import {Hotel} from '../../../_models/hotel';
import GgTableCustomFN from '../../../_classes/ggTableCustomFN';
import {Paginator} from 'primeng/paginator';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelsService} from '../../_services/hotels.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-hotels-table',
  templateUrl: './hotels-table.component.html',
  styleUrls: ['./hotels-table.component.css']
})
export class HotelsTableComponent implements OnInit {
  hotel: Hotel;
  selectedHotels: Hotel[];
  hotels: Hotel[];

  // Filters Vars
  tableHelper = new GgTableCustomFN();
  filters = {
    company_name: [],
    region_name: [],
    country_name: [],
    city_name: [],
    assigned_user_name: [],
    created_by_user_name: [],
    publish: []
  };
  booleanFilter = [{name: this.translate.transform('active'), val: 1},
    {name: this.translate.transform('inactive'), val: 0}];

  exporter = new GgTableCustomFN();
  pagination: any;
  searchValue = '';
  isGolfHotelValue = 1;
  isGolfHotelModel = true;
  @ViewChild('dt', {static: false}) paginator: Paginator;
  publishStatuses = ['None', 'Published', 'Pending Publish', 'Unpublished'];
  topStatuses = ['None', 'Yes', 'No'];
  first = 0;
  showDeletePrevent = false;
  initialPageParams = {
    first: 0,
    rows: 10,
    globalFilter: null,
    sortField: null,
    sortOrder: null,
    filters: null
  };
  @ViewChild('dt', {static: true}) private dataTable: Table;

  constructor(private breadcrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute,
              private hotelsService: HotelsService, private confirmationService: ConfirmationService,
              private accountService: AccountService, private translateService: TranslateService,
              private messageService: MessageService, protected translate: TranslatePipe) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: 'Golf Hotels'}
    ]);
  }

  ngOnInit(): void {
    this.getPaginatedHotels(this.initialPageParams);
  }

  getPaginatedHotels(event) {
    let topNValue: any = 'None';
    if (event.filters?.top[0]?.value === 'Yes') {
      topNValue = 1;
    } else if (event.filters?.top[0]?.value === 'No') {
      topNValue = 0;
    }
    this.hotelsService.getPaginatedHotels(
      event.first / event.rows + 1,
      event.rows,
      event.filters?.global?.value,
      event.filters?.publish[0]?.value,
      topNValue,
      event.sortField,
      event.sortOrder,
      this.isGolfHotelValue).subscribe((res: any) => {
      this.hotels = res.hotels.data;
      this.pagination = res.hotels.meta;
      this.tableHelper.populateFilter(this.filters, this.hotels);
    });
  }

  edit(hotel) {
    this.router.navigate(['edit/' + hotel.id], {relativeTo: this.route});
  }

  delete(hotel) {
    this.confirmationService.confirm({
      message: this.translate.transform('confirmMessage'),
      accept: () => {
        this.hotelsService.deleteHotel(hotel.id).subscribe((res: any) => {
          if (res.has_requests) {
            this.showDeletePrevent = true;
          } else {
            this.getPaginatedHotels(this.dataTable);
            this.unPublishDeletedHotel(hotel);
          }
        });
      },
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }

  unPublishDeletedHotel(hotel: Hotel) {
    if (hotel.show_website) {
      const obj = {
        auth: 'GAP_API',
        token: environment.token,
        type: 'hotel',
        object_id: hotel.id,
        action: 'delete'
      };
      this.hotelsService.publishHotelToWebsite(obj).subscribe((res: any) => {
        if (res.update_status === 'Updated Successfully') {
          this.hotelsService.publishHotel(hotel.id).subscribe((resp: any) => {
            this.deleteMessage();
          });
        }
      });
    } else {
      this.deleteMessage();
    }
  }

  deleteMessage() {
    this.messageService.add({
      severity: 'success', summary: 'Delete Hotel',
      detail: 'selected Hotel has been deleted successfully'
    });
  }

  updateHotel(hotel) {
    this.hotelsService.updateHotel(hotel).subscribe(res => {
      this.messageService.add({
        severity: 'success', summary: 'Update Hotel',
        detail: 'Hotel has been deleted successfully'
      });
    });
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedHotels || this.selectedHotels.length < 1) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.exporter.exportExcel(this.selectedHotels);
  }

  valueChanged(event) {
    switch (event.value) {
      case true:
        this.isGolfHotelValue = 1;
        break;
      case false:
        this.isGolfHotelValue = 0;
        break;
      case null:
        this.isGolfHotelValue = null;
        break;
      default:
        this.isGolfHotelValue = null;
    }
    this.getPaginatedHotels(this.dataTable);
  }

  topChanged(hotel) {
    this.updateHotel(hotel);
  }
}
