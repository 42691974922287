<form [formGroup]="addCourseForm" (ngSubmit)="submitForm()">
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>{{'basic' | translate}}</h5>
            </div>
            <div>
                <app-switch-input [formControl]="addCourseForm.controls['active']" [label]="'active' | translate"
                                  [inline]="true"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-switch-input [formControl]="addCourseForm.controls['is_parent']"
                                  (changed)="isParentChanged($event)"
                                  [label]="'createGolfClubProfile' | translate"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label class="full-width">{{'golfClubName' | translate}}
                        <span class="p-error">{{checkRequired(addCourseForm.get('company')) ? '*' : ''}}</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="company"
                                    delay="1000" [suggestions]="companies" (onSelect)="companySelected($event)"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="addCourseForm.get('company').touched && addCourseForm.get('company').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="addCourseForm.controls['name']" [label]="'golfCourseName' | translate"
                                [type]="'text'"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label class="full-width">{{'parentHotel' | translate}}</label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="hotel"
                                    [suggestions]="hotels" delay="1000"
                                    (completeMethod)="searchHotels($event)" [forceSelection]="true">
                        <ng-template let-hotel pTemplate="item">
                            <div>{{hotel.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>

            <!--<div class="p-col-12 p-md-col">
                <app-text-input [formControl]="addCourseForm.controls['ref_id']" [label]="'Ref. ID'"
                                [type]="'text'"></app-text-input>
            </div>-->
            <div class="p-col-12 p-md-col" *ngIf="currentUser.role_id !== 3">
                <app-text-input [formControl]='addCourseForm.controls["reservation_email"]' [type]="'email'"
                                [label]='"reservationEmail" | translate'></app-text-input>
            </div>
        </div>
        <!--<p-fieldset legend="Contract" styleClass="services-fieldset">
            <div class="p-grid">
                <div class="p-col-12 p-md-2">
                    <app-switch-input [formControl]="addCourseForm.controls['direct_contract']"
                                      [label]="'Direct'" [inline]="true"></app-switch-input>
                </div>
                <div class="p-col-12 p-md-2">
                    <app-switch-input [formControl]="addCourseForm.controls['via_dmc']"
                                      [label]="'DMC'" [inline]="true"></app-switch-input>
                </div>
                <div class="p-col-12 p-md-2">
                    <app-switch-input [formControl]="addCourseForm.controls['via_hotel']"
                                      [label]="'Hotel'" [inline]="true"></app-switch-input>
                </div>
            </div>
        </p-fieldset>-->
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>{{'companyContact' | translate}}</h5>
            </div>
            <div>
                <app-switch-input class="float-right" *ngIf="showCompanyInfoButton"
                                  [formControl]="addCourseForm.controls['is_company_address']"
                                  [label]="'useCompanyInfo' | translate"
                                  [inline]="true"
                                  (changed)="companyInfoButtonClick($event)"></app-switch-input>
                <a class="view-company-link"
                   *ngIf="addCourseForm.get('company').value && addCourseForm.get('is_company_address').value == 1"
                   (click)="viewCompany()">
                    {{'viewCompany' | translate}}</a>

            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'continent' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="regions" [formControl]="addCourseForm.get('region_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                                placeholder="{{'selectContinent' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getRegionName(addCourseForm.get('region_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="addCourseForm.get('region_id').touched && addCourseForm.get('region_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'country' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="countries" [formControl]="addCourseForm.get('country_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()"
                                placeholder="{{'selectCountry' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getCountries(null, addCourseForm.get('region_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCountryName(addCourseForm.get('country_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="addCourseForm.get('country_id').touched && addCourseForm.get('country_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'region' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="cities" [formControl]="addCourseForm.get('city_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="cityChanged()"
                                (onClick)="getCities(null, addCourseForm.get('country_id').value)"
                                placeholder="{{'selectRegion' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCityName(addCourseForm.get('city_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                            + ' - ' + item?.code}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="addCourseForm.get('city_id').touched && addCourseForm.get('city_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'area' | translate}}</label>
                    <p-dropdown [options]="areas" [formControl]="addCourseForm.get('area_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true"
                                placeholder="{{'selectArea' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getAreas(null, addCourseForm.get('city_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getAreaName(addCourseForm.get('area_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="addCourseForm.get('area_id').touched && addCourseForm.get('area_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="addCourseForm.controls['street']" placeholder="{{'street' | translate}}"
                                label="{{'street' | translate}}"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="addCourseForm.controls['postal_code']"
                                label="{{'postalCode' | translate}}" placeholder="{{'postalCode' | translate}}"
                                [type]="'number'"></app-text-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <app-text-input [formControl]="addCourseForm.controls['email']" [label]="'email' | translate"
                                [type]="'email'"></app-text-input>
            </div>
            <ng-container *ngIf="currentUser.role_id !== 3">
            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label>{{'phone' | translate}}</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'phone' | translate"
                            formControlName="phone"
                    ></ngx-intl-tel-input>
                    <small *ngIf="addCourseForm.controls.phone.touched && addCourseForm.controls.phone.errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                    <small  class="p-error" *ngIf="addCourseForm.controls.phone.touched && addCourseForm.controls.phone.invalid">
                        {{'invalidNumber' | translate}}
                    </small>
                </div>
            </div>

            <div class="p-col-12 p-md-3">
                <div class="p-field">
                    <label>{{'fax' | translate}}</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'fax' | translate"
                            formControlName="fax"
                    ></ngx-intl-tel-input>
                    <small *ngIf="addCourseForm.controls.fax.touched && addCourseForm.controls.fax.errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                    <small  class="p-error" *ngIf="addCourseForm.controls.fax.touched && addCourseForm.controls.fax.invalid">
                        {{'invalidNumber' | translate}}
                    </small>
                </div>
            </div>
            </ng-container>
        </div>

    </div>
    <ul class="invalid-controls">
        <li *ngFor="let control of invalidControls">{{control}} {{'isInvalid' | translate}}</li>
    </ul>
    <div class="buttons">
        <button pButton class="float-right" type="submit" label="{{'create' | translate}}"></button>
    </div>
</form>
