import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {HttpErrrorHandler} from '../../_classes/httpErrorHandler';
import {Area, City, Country, Region} from 'src/app/_models/destinations';
import {environment} from '../../../environments/environment';
import {NgxUiLoaderService} from 'ngx-ui-loader';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class DestinationsService {

  apiUrl = environment.apiUrl;
  handleError = new HttpErrrorHandler();

  constructor(private http: HttpClient, private loader: NgxUiLoaderService) {
  }

  //  Regions APIs
  /**
   * Get all Regions data
   * @returns Observable Regions array
   */
  getAllRegions(): Observable<Region[]> {
    return this.loadRegions().pipe(catchError(this.handleError.handleError))
      .pipe(map(res => {
        res.regions.forEach(region => {
          region.countries.forEach(country => {
            country.region_name = country.region.name_en;
            country.region_name_de = country.region.name_de;
            country.cities.forEach(city => {
              city.country_name = city.country.name_en;
              city.country_name_de = city.country.name_de;
              city.region_name = city.region.name_en;
              city.region_name_de = city.region.name_de;
              city.areas.forEach(area => {
                area.city_name = area.city?.name_en;
                area.city_name_de = area.city?.name_de;
                area.country_name = area.country?.name_en;
                area.country_name_de = area.country?.name_de;
                area.region_name = area.region?.name_en;
                area.region_name_de = area.region?.name_de;
              });
            });
          });
        });
        return res.regions;
      }));
  }

  loadRegions() {
    return this.http.get<{ status: boolean; regions: Region[] }>(`${this.apiUrl}regions`);
  }

  isDateChanged(latestUpdate) {
    if (localStorage.getItem('destinationsLastUpdate') !== latestUpdate) {
      return true;
    }
    return false;
  }

  lastDestinationsUpdate() {
    return this.http.get(this.apiUrl + 'regions/check-region-updated');
  }

  /**
   * Get Region data by id
   * @param id Region id
   * @returns Region Data
   */
  getRegion(id: number): Observable<Region> {
    return this.http.get<{ status: boolean; region: Region }>(`${this.apiUrl}regions/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map(res => res.region));
  }

  /**
   * update Region Data
   * @param region updated Region data
   * @return status of update
   */
  updateRegion(region: Region): Observable<boolean> {
    return this.http.put<{ status: boolean; region: Region; }>(`${this.apiUrl}regions/${region.id}`, region)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  /**
   * Add new region
   * @param region Region data
   * @returns status of update
   */
  addRegion(region: Region): Observable<boolean> {
    return this.http.post<{ status: boolean; region: Region; }>(`${this.apiUrl}regions`, region)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  /**
   * Delete region
   * @param id Region id
   * @returns status of update
   */
  deleteRegion(id: number): Observable<boolean> {
    return this.http.delete<{ status: boolean; region: Region; }>(`${this.apiUrl}regions/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  //  Countries APIs
  /**
   * Get all Countries data
   * @param regionId optional - if want to get all countries in specific region
   * @returns Observable Countries array
   */
  getAllCountries(regionId?: number): Observable<Country[]> {
    if (regionId) {
      return this.http.get<{ status: boolean; countries: Country[] }>(`${this.apiUrl}countries?region_id=${regionId}`)
        .pipe(catchError(this.handleError.handleError))
        .pipe(map(res => {
          return res.countries;
        }));
    } else {
      return this.http.get<{
        status: boolean;
        countries: Country[]
      }>(`${this.apiUrl}countries?not_ignore_empty_cities=1`)
        .pipe(catchError(this.handleError.handleError))
        .pipe(map(res => {
          return res.countries;
        }));
    }
  }

  /**
   * Get Country data by id
   * @param id Country id
   * @returns Country Data
   */
  getCountry(id: number): Observable<Country> {
    return this.http.get<{ status: boolean; country: Country }>(`${this.apiUrl}countries/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map(res => res.country));
  }

  /**
   * update Country Data
   * @param Country updated Country data
   * @return status of update
   */
  updateCountry(country: Country) {
    return this.http.put<{ status: boolean; country: Country; }>(`${this.apiUrl}countries/${country.id}`, country)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.country;
      }));
  }

  /**
   * Add new Country
   * @param country Country data
   * @returns status of update
   */
  addCountry(country: Country): Observable<boolean> {
    return this.http.post<{ status: boolean; country: Country; }>(`${this.apiUrl}countries`, country)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  /**
   * Delete Country
   * @param id Country data
   * @returns status of update
   */
  deleteCountry(id: number): Observable<boolean> {
    return this.http.delete<{ status: boolean; country: Country; }>(`${this.apiUrl}countries/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  //  Cities APIs
  /**
   * Get all Cities data
   * @param countryId optional - if want to get all cities in specific country
   * @returns Observable Cities array
   */
  getAllCities(countryId?: number): Observable<City[]> {
    return this.http.get<{ status: boolean; cities: City[] }>
    (this.apiUrl + 'cities' + (countryId ? '?country_id=' + countryId : ''))
      .pipe(catchError(this.handleError.handleError))
      .pipe(map(res => {
        return res.cities;
      }));
  }

  /**
   * Get City data by id
   * @param id City id
   * @returns City Data
   */
  getCity(id: number): Observable<City> {
    return this.http.get<{ status: boolean; city: City }>(`${this.apiUrl}cities/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map(res => res.city));
  }

  /**
   * update City Data
   * @param City updated City data
   * @return status of update
   */
  updateCity(city: City) {
    return this.http.put<{ status: boolean; city: City; }>(`${this.apiUrl}cities/${city.id}`, city)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.city;
      }));
  }

  /**
   * Add new City
   * @param city City data
   * @returns status of update
   */
  addCity(city: City): Observable<boolean> {
    return this.http.post<{ status: boolean; city: City; }>(`${this.apiUrl}cities`, city)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  /**
   * Delete City
   * @param id City id
   * @returns status of update
   */
  deleteCity(id: number): Observable<boolean> {
    return this.http.delete<{ status: boolean; city: City; }>(`${this.apiUrl}cities/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  searchCities(keyword: string) {
    return this.http.get(this.apiUrl + 'cities?search=' + keyword);
  }

  getCityByCode(code) {
    return this.http.get(this.apiUrl + 'cities?code=' + code);
  }

  getCityFieldTypes() {
    return this.http.get(this.apiUrl + 'cities/field-types/all').pipe(map((res: any) => {
      const types = res.field_types;
      types.forEach(type => {
        type.disabled = false;
      });
      return types;
    }));
  }

  getCountryFieldTypes() {
    return this.http.get(this.apiUrl + 'countries/field-types/all').pipe(map((res: any) => {
      const types = res.field_types;
      types.forEach(type => {
        type.disabled = false;
      });
      return types;
    }));
  }

  uploadCityImages(id, obj) {
    return this.http.post(this.apiUrl + 'cities/upload-images/' + id, obj);
  }

  deleteCityImage(courseId, obj) {
    return this.http.post(this.apiUrl + 'cities/delete-image/' + courseId, {image_id: obj});
  }

  uploadCountryImages(id, obj) {
    return this.http.post(this.apiUrl + 'countries/upload-images/' + id, obj);
  }

  deleteCountryImage(courseId, obj) {
    return this.http.post(this.apiUrl + 'countries/delete-image/' + courseId, {image_id: obj});
  }

  getCurrencies() {
    return this.http.get(this.apiUrl + 'countries/currencies/all');
  }

  createCountryTestimony(countryId, obj) {
    return this.http.post(this.apiUrl + 'countries/testimonies/' + countryId, obj);
  }

  createCityTestimony(cityId, obj) {
    return this.http.post(this.apiUrl + 'cities/testimonies/' + cityId, obj);
  }

  deleteTestimony(id) {
    return this.http.delete(this.apiUrl + 'testimonies/' + id);
  }

  editTestimony(obj) {
    return this.http.put(this.apiUrl + 'testimonies/' + obj.id, obj);
  }

  deleteTestimonyImage(id) {
    return this.http.post(this.apiUrl + 'testimonies/delete-image/' + id, {});
  }

  uploadTestimonyImage(id, obj) {
    return this.http.post(this.apiUrl + 'testimonies/upload-image/' + id, obj);
  }

  changeCountryMainImage(id, obj) {
    return this.http.put(this.apiUrl + 'countries/' + id + '/change-main-image', obj);
  }

  changeCityMainImage(id, obj) {
    return this.http.put(this.apiUrl + 'cities/' + id + '/change-main-image', obj);
  }

  publishCity(id) {
    return this.http.put(this.apiUrl + 'cities/publish/' + id, null);
  }

  publishCountry(id) {
    return this.http.put(this.apiUrl + 'countries/publish/' + id, null);
  }

  //  Areas APIs
  /**
   * Get all Areas data
   * @param cityId optional - if want to get all cities in specific city
   * @returns Observable Areas array
   */
  getAllAreas(cityId?: number): Observable<Area[]> {
    if (localStorage.getItem('areas')) {
      if (cityId) {
        const areas = JSON.parse(localStorage.getItem('areas')).filter(x => x.city.id === cityId);
        return areas.length > 0 ? of(areas) : of(null);
      } else {
        return of(JSON.parse(localStorage.getItem('areas')));
      }
    } else {
      if (cityId) {
        return this.http.get<{ status: boolean; areas: Area[] }>(`${this.apiUrl}areas?city_id=${cityId}`)
          .pipe(catchError(this.handleError.handleError))
          .pipe(map(res => {
            return res.areas;
          }));
      } else {
        return this.http.get<{ status: boolean; areas: Area[] }>(`${this.apiUrl}areas`)
          .pipe(catchError(this.handleError.handleError))
          .pipe(map(res => {
            return res.areas;
          }));
      }
    }
  }

  /**
   * Get Area data by id
   * @param id Area id
   * @returns Area Data
   */
  getArea(id: number): Observable<Area> {
    return this.http.get<{ status: boolean; area: Area }>(`${this.apiUrl}areas/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.area;
      }));
  }

  /**
   * update Area Data
   * @param Area updated Area data
   * @return status of update
   */
  updateArea(area: Area) {
    return this.http.put<{ status: boolean; area: Area; }>(`${this.apiUrl}areas/${area.id}`, area)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  /**
   * Add new Area
   * @param area Area data
   * @returns status of update
   */
  addArea(area: Area): Observable<boolean> {
    return this.http.post<{ status: boolean; area: Area; }>(`${this.apiUrl}areas`, area)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  /**
   * Delete Area
   * @param id Area id
   * @returns status of update
   */
  deleteArea(id: number): Observable<boolean> {
    return this.http.delete<{ status: boolean; area: Area; }>(`${this.apiUrl}areas/${id}`)
      .pipe(catchError(this.handleError.handleError))
      .pipe(map((res: any) => {
        return res.status;
      }));
  }

  searchAreas(keyword: string) {
    return this.http.get(this.apiUrl + 'areas?search=' + keyword);
  }

  getAreaByCode(code) {
    return this.http.get(this.apiUrl + 'areas?code=' + code);
  }


  /////////////////////// NEW ////////////////////////
  getRegionsList() {
    return this.http.get<{ status: boolean, regions: Region[] }>(this.apiUrl + 'regions/list').pipe(map(res => {
      return res.regions;
    }));
  }

  getRegionsListTable() {
    return this.http.get<any>(this.apiUrl + 'regions/list-table')
      .pipe(map(res => {
        res.regions.sort((a, b) => a.name_en.localeCompare(b.name_en));
        return res.regions;
      }));
  }

  getCountriesList(regionId?: number) {
    /*return this.http.get<any>(this.apiUrl + 'countries/list' +
        (regionId ? ('?region_id=' + regionId) : '?not_ignore_empty_cities=1'))
        .pipe(map(res => {
            return res.countries;
        }));*/

    return this.http.get<any>(this.apiUrl + 'countries/list?region_id=' + regionId + '&not_ignore_empty_cities=1')
      .pipe(map(res => {
        return res.countries;
      }));
  }


  getCountriesListTable(regionId?: number) {
    return this.http.get<any>(this.apiUrl + 'countries/list-table' +
      /*(regionId ? ('?region_id=' + regionId) : ''))*/
      (regionId ? ('?region_id=' + regionId) : '?not_ignore_empty_cities=1'))

      .pipe(map(res => {
        res.countries.sort((a, b) => a.name_en.localeCompare(b.name_en));
        res.countries.map(country => {
          country.region_name = country.region.name_en;
          country.region_name_de = country.region.name_de;
        });
        return res.countries;
      }));
  }

  getCitiesList(countryId?: number) {
    return this.http.get<any>(this.apiUrl + 'cities/list' +
      (countryId ? ('?country_id=' + countryId) : '')).pipe(map(res => {
      return res.cities;
    }));
  }

  getCitiesListTable(countryId?: number) {
    return this.http.get<any>(this.apiUrl + 'cities/list-table' +
      (countryId ? ('?country_id=' + countryId) : '')).pipe(map(res => {
      return res.cities;
    }));
  }

  getPaginatedCities(pageNumber, pagination, searchKeyword, publishValue, topValue) {
    return this.http.get(this.apiUrl + 'cities/paginate?page=' + pageNumber + '&pagination=' + pagination
      + (searchKeyword === '' ? '' : '&search=' + searchKeyword) +
      (publishValue === 'None' ? '' : ('&publish=' + publishValue)) +
      (topValue === 'None' ? '' : ('&top=' + topValue))
    );
  }

  getAreasList(cityId?: number) {
    return this.http.get<any>(this.apiUrl + 'areas/list' +
      (cityId ? ('?city_id=' + cityId) : '')).pipe(map(res => {
      return res.areas;
    }));
  }

  getAreasListTable(cityId?: number) {
    return this.http.get<any>(this.apiUrl + 'areas/list-table' +
      (cityId ? ('?city_id=' + cityId) : '')).pipe(map(res => {
      return res.areas;
    }));
  }

  /////////////////////// NEW ////////////////////////

}

interface Cache {
  regions: Region[];
  countries: Country[];
  cities: City[];
  areas: Area[];
}
