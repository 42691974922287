import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HotelProduct} from '../../../_models/hotel-product';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Hotel} from '../../../_models/hotel';
import {Lookup} from '../../../_models/lookup';
import {Company} from '../../../_models/company';
import {SharedService} from '../../../_services/shared.service';
import {HotelsService} from '../../../hotels/_services/hotels.service';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ProductAPIService} from '../../_services/product-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelProductsService} from '../../_services/hotel-products.service';
import {MessageService} from 'primeng/api';
import {CompaniesService} from '../../../_services/companies-service';
import {GfpService} from '../../_services/gfp.service';
import {GolfHolidayService} from '../../_services/golf-holiday.service';
import {GolfHoliday} from '../../../_models/golf-holiday';
import {TagsService} from '../../../_services/tags.service';

@Component({
    selector: 'app-golf-holiday-form',
    templateUrl: './golf-holiday-form.component.html',
    styleUrls: ['./golf-holiday-form.component.css']
})
export class GolfHolidayFormComponent implements OnInit, OnChanges {
    isAddMode = true;
    product: GolfHoliday;
    form: UntypedFormGroup;
    services: any[];
    hotels: Hotel[];
    hotelProducts: HotelProduct[];
    products: any[];
    roomTypes: Lookup[];
    views: Lookup[];
    boards: Lookup[];
    companies: Company[];
    serviceHandlerTypes: Lookup[] = [];
    bookings: Company[] = [];
    serviceHandlers: Company[];
    serviceHandlersTypesIds = [3, 4, 6];
    providers: Company[] = [];
    showServiceInfoButton = true;
    selectedService: any;
    bookingOptions = ['Hotel', 'Region'];
    @Input() productId: number;
    invalidControls: any[];
    filteredTags: any;

    constructor(private fb: UntypedFormBuilder, public sharedService: SharedService, private hotelsService: HotelsService,
                private breadcrumbService: BreadcrumbService, private productsServices: ProductAPIService,
                private route: ActivatedRoute, private hotelProductsService: HotelProductsService,
                private messageService: MessageService, private companiesService: CompaniesService,
                private gfpService: GfpService, private golfHolidayService: GolfHolidayService,
                private router: Router, private tagsService: TagsService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Golf Holiday Products', routerLink: '/product-setup/golf-holiday'},
            {label: this.isAddMode ? 'New Golf Holiday Product' : 'Update Golf Holiday Product'}
        ]);
    }

    ngOnInit(): void {
        this.initForm();
        this.getBasics();
        this.getCompanyTypes();
        if (this.productId || this.route.snapshot.paramMap.get('id')) {
            this.isAddMode = false;
            this.getProduct();
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.form) {
            this.resetPage();
            this.initForm();
        }
        this.getProduct();
    }

    initForm() {
        this.form = this.fb.group({
            id: null,
            name: [null, Validators.required],
            service: [null, Validators.required],
            hotel: [null, Validators.required],
            room_type_id: [null, Validators.required],
            room_view_id: [null, Validators.required],
            room_board_id: [null, Validators.required],
            ref_code: [null],
            code: [null],
            tui_code: [null],
            validity_from: [new Date(), Validators.required],
            validity_to: [new Date(new Date().setFullYear(new Date().getFullYear() + 10)), Validators.required],
            status: [1],
            unlimited_rounds: 0,
            number_of_nights: 0,
            number_of_guests: 0,
            number_of_rounds: 0,
            number_of_golf_courses: 0,
            invoice_handler_id: null,
            service_handler_type_id: [null, Validators.required],
            service_handler_id: [{value: null, disabled: true}],
            booking_possible_for: [null, Validators.required],
            booking_from_id: [{value: null, disabled: true}],
            use_service_configurations: [1],
            use_destination_hotel: 0,
            products: null,
            hotels: null,
            tags: null
        });
        this.serviceInfoButtonClick({checked: 1});
    }

    getBasics() {
        this.hotelsService.getBasics().subscribe((res: any) => {
            this.views = res.room_views;
            this.boards = res.room_boards;
            this.roomTypes = res.room_types;
        });
    }

    getCompanyTypes() {
        this.companiesService.getCompanyTypes().subscribe((res: any) => {
            this.serviceHandlersTypesIds.forEach(type => {
                this.serviceHandlerTypes.push(res.filter(x => x.id === type)[0]);
            });
        });
    }

    searchHotels(event) {
        this.hotelsService.search(event.query).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    async getProduct() {
        this.product = await this.golfHolidayService.getById(this.productId ? this.productId :
            this.route.snapshot.paramMap.get('id')).toPromise();
        this.product.validity_to = new Date(this.product.validity_to);
        this.product.validity_from = new Date(this.product.validity_from);
        this.product.booking_from_id = +this.product.booking_from_id;
        this.form.patchValue(this.product);
        if (this.product.unlimited_rounds === 1) {
            this.form.get('number_of_rounds').disable();
        }
        this.form.get('service').setValue({
            id: this.product.service_id,
            name: this.product.service_name
        });
        this.form.get('hotel').setValue({
            id: this.product.hotel_id,
            name: this.product.hotel_name
        });
        ////////////// Fetch Hotel Products /////////////////
        const hProds = [];
        this.product.hotels?.forEach((prod: any) => hProds.push(prod.id));
        this.form.get('hotels').setValue(hProds);
        ////////////// Fetch Hotel Products /////////////////
        ////////////// Fetch Products /////////////////
        const prods = [];
        this.product.hotels?.forEach((prod: any) => prods.push(prod.id));
        this.form.get('products').setValue(prods);
        ////////////// Fetch Hotel Products /////////////////
        await this.getServiceById(this.product.service_id);
        this.getHotelProducts();
        this.getProducts();
        if (this.product.booking_possible_for == 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }
        this.form.get('service').setValue({
            id: this.product.service_id,
            name: this.product.service_name
        });
        this.form.controls.invoice_handler_id.setValue({
            id: this.product.invoice_handler_id,
            name: this.product.invoice_handler_name
        });
        if (this.product.use_service_configurations == 1) {
            this.form.get('use_service_configurations').setValue(1);
            await this.serviceInfoButtonClick({checked: 1}, false);
        } else {
            this.disabilitySwitch(false);
        }
        await this.serviceHandlerTypeChanged(this.product.service_handler_type_id);
    }

    async getServiceById(id) {
        await this.productsServices.getService(id).toPromise().then((res: any) => {
            this.selectedService = res;
        });
    }

    submit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.findInvalidControls();
            return false;
        }
        const obj = this.form.getRawValue();
        obj.hotel_id = obj.hotel.id;
        obj.service_id = obj.service.id;
        obj.invoice_handler_id = obj.invoice_handler_id.id;
        obj.validity_from = this.sharedService.dateToIsoString(obj.validity_from).slice(0, 10);
        obj.validity_to = this.sharedService.dateToIsoString(obj.validity_to).slice(0, 10);
        if (obj.hotels == null) {
            obj.hotels = [];
        }
        if (obj.products == null) {
            obj.products = [];
        }
        ///////////////////  TAGS /////////////////////
        if (obj.tags != null) {
            obj.tags = obj.tags.map(tag => tag.id);
        } else {
            obj.tags = [];
        }
///////////////////  TAGS /////////////////////
        if (this.isAddMode) {
            this.golfHolidayService.create(obj).subscribe((res: any) => {
                this.resetPage();
                this.initForm();
                this.form.get('number_of_rounds').enable();
                this.messageService.add({
                    severity: 'success', summary: 'Add Golf Holiday Product',
                    detail: 'New golf holiday product has been added successfully'
                });
            });
        } else {
            this.golfHolidayService.update(obj).subscribe((res: any) => {
                this.messageService.add({
                    severity: 'success', summary: 'Update Golf Holiday Product',
                    detail: 'Golf holiday product has been updated successfully'
                });
            });
        }
    }

    searchServices(event) {
        this.productsServices.searchService(event.query).subscribe((res: any) => {
            this.services = res;
        });
    }

    getHotelProducts() {
        this.hotelProductsService.getByServiceId(this.form.get('service').value.id).subscribe((res: any) => {
            this.hotelProducts = res.products;
        });
    }

    getProducts() {
        this.gfpService.getProductsByServiceCity(this.form.get('service').value.id, this.form.get('service').value.city_id)
            .subscribe((res: any) => {
                this.products = res.products;
            });
    }

    async searchCompanies(event) {
        this.companiesService.searchCompanies(event.query).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    async serviceHandlerTypeChanged(value) {
        if (value === 3 || value == null) {
            // golf club type selected
            this.form.get('service_handler_id').disable();
            this.form.get('service_handler_id').setValue(null);
            this.serviceHandlers = [];
            this.form.get('service_handler_id').removeValidators(Validators.required);
        } else {
            await this.companiesService.getAllCompaniesByType(value).toPromise().then((res: any) => {
                this.serviceHandlers = res.companies;
                if (this.form.get('use_service_configurations').value == 0) {
                    this.form.get('service_handler_id').enable();
                    this.form.get('service_handler_id').setValidators(Validators.required);
                }
            });
        }
    }

    async bookingPossibleChanged(value) {
        if (value === 'Hotel') {
            if (this.selectedService.city_id) {
                this.hotelsService.getAllHotelsByCity(this.selectedService.city_id).subscribe((res: any) => {
                    this.bookings = res.hotels;
                    if (this.form.get('use_service_configurations').value == 0) {
                        this.form.get('booking_from_id').enable();
                    }
                });
            } else {
                this.hotelsService.getAllHotelsByCity(this.selectedService.country_id).subscribe((res: any) => {
                    if (this.form.get('use_service_configurations').value == 0) {
                        this.form.get('booking_from_id').enable();
                    }
                });
            }
        } else {
            if (this.form.get('use_destination_hotel').value === 1) {
                this.form.get('use_destination_hotel').setValue(0);
                this.switchServiceHandler(true);
            }
            this.bookings = [];
            this.form.get('booking_from_id').setValue(null);
            this.form.get('booking_from_id').disable();
        }
    }

    async serviceInfoButtonClick(event, fill = true) {
        if (event.checked === 1) {
            this.disabilitySwitch(true);
            if (this.form.get('service').value !== null && fill) {
                await this.fillServiceInfo();
            }
        } else {
            this.disabilitySwitch(false);
        }
    }

    async fillServiceInfo() {
        await this.serviceHandlerTypeChanged(this.selectedService.service_handler_type_id);
        if (this.selectedService.booking_possible_for == 'Hotel') {
            await this.bookingPossibleChanged('Hotel');
        }
        this.form.get('invoice_handler_id').setValue({
            id: this.selectedService.invoice_handler_id,
            name: this.selectedService.invoice_handler_name
        });
        this.form.get('service_handler_type_id').setValue(this.selectedService.service_handler_type_id);
        this.form.get('service_handler_id').setValue(this.selectedService.service_handler_id);
        /*if (this.selectedService.service_handler_id !== null) {
            this.form.get('service_handler_id').setValue(this.selectedService.service_handler_id);
        } else {
            this.form.get('use_destination_hotel').setValue(1);
        }*/
        this.form.get('booking_possible_for').setValue(this.selectedService.booking_possible_for);
        this.form.get('booking_from_id').setValue(this.selectedService.booking_from_id);
    }

    disabilitySwitch(disable: boolean) {
        if (disable) {
            this.form.get('invoice_handler_id').disable();
            this.form.get('service_handler_type_id').disable();
            this.form.get('service_handler_id').disable();
            this.form.get('booking_possible_for').disable();
            this.form.get('booking_from_id').disable();
            this.form.get('use_destination_hotel').disable();
        } else {
            this.form.get('invoice_handler_id').enable();

            this.form.get('service_handler_type_id').enable();

            this.form.get('service_handler_type_id').value == null ? this.form.get('service_handler_id').disable() :
                this.form.get('service_handler_id').enable();

            this.form.get('booking_possible_for').enable();

            this.form.get('booking_possible_for').value == null ? this.form.get('booking_from_id').disable() :
                this.form.get('booking_from_id').enable();

            this.form.get('use_destination_hotel').enable();
        }
    }

    async serviceChanged(value) {
        this.getHotelProducts();
        this.getProducts();
        this.selectedService = value;
        if (value == null && this.form.get('use_service_configurations').value == 1) {
            this.clearAlternatives();
        }
        if (value !== null) {
            this.selectedService.booking_from_id = +this.selectedService.booking_from_id;
            if (this.form.get('use_service_configurations').value === 1) {
                this.fillServiceInfo();
            }
        }
    }

    clearAlternatives() {
        this.form.get('invoice_handler_id').setValue(null);
        this.form.get('service_handler_type_id').setValue(null);
        this.form.get('service_handler_id').setValue(null);
        this.form.get('booking_possible_for').setValue(null);
        this.form.get('booking_from_id').setValue(null);
    }

    ultimateNightsChanged(event) {
        if (event.checked) {
            this.form.get('number_of_rounds').disable();
        } else {
            this.form.get('number_of_rounds').enable();
        }
    }
    useDestHotelsChanges(event) {
        if (event === 1) {
            this.switchServiceHandler(false);
        } else {
            this.switchServiceHandler(true);
        }
    }
    switchServiceHandler(value) {
        if (value) {
            this.form.get('service_handler_id').enable();
            this.form.get('service_handler_id').setValidators(Validators.required);
            this.form.get('service_handler_id').updateValueAndValidity();
        } else {
            this.form.get('service_handler_id').setValue(null);
            this.form.get('service_handler_id').disable();
            this.form.get('service_handler_id').removeValidators(Validators.required);
            this.form.get('service_handler_id').updateValueAndValidity();
        }
    }
    public findInvalidControls() {
        this.invalidControls = [];
        const controls = this.form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalidControls.push(name.replace(/_/g, ' '));
            }
        }
    }
    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    filterTags(event) {
        this.tagsService.getAllTags(event.query).subscribe(res => {
            this.filteredTags = res;
            const matchedTags = this.filteredTags.filter(x => x.name == event.query);
            if (matchedTags.length === 0) {
                this.filteredTags.unshift({id: 0, name: event.query + ' (Add new)'});
            }
        });
    }
    tagSelected(event) {
        if (event.id === 0) {
            const tagValues = [...this.form.get('tags').value];
            const newTag = tagValues[tagValues.length - 1];
            const selections = this.form.get('tags').value.slice(0, tagValues.length - 1);
            this.tagsService.addTag({name: newTag.name.split('(')[0].trim()}).subscribe(res => {
                selections.push(res);
                this.form.get('tags').setValue(selections);
            });
        }
    }
}
