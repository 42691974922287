import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HotelsRoutingModule} from './hotels-routing.module';
import {SharedModule} from '../_shared/shared.module';
import {HotelsComponent} from './hotels/hotels.component';
import {AddHotelComponent} from './add-hotel/add-hotel.component';
import {RoomsDetailsComponent} from './add-hotel/rooms-details/rooms-details.component';
import {HotelsTreeComponent} from './hotels/hotels-tree/hotels-tree.component';
import {HotelsTableComponent} from './hotels/hotels-table/hotels-table.component';
import {TranslateModule} from '@ngx-translate/core';
import { RoomFormComponent } from './add-hotel/rooms-details/room-form/room-form.component';


@NgModule({
    declarations: [
        HotelsComponent,
        AddHotelComponent,
        RoomsDetailsComponent,
        HotelsTreeComponent,
        HotelsTableComponent,
        RoomFormComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        HotelsRoutingModule,
        TranslateModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HotelsModule {
}
