import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../_models/product-service';
import {Lookup} from '../../../_models/lookup';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-gfp-form-bulk',
    templateUrl: './gfp-form-bulk.component.html',
    styleUrls: ['./gfp-form-bulk.component.css']
})
export class GfpFormBulkComponent implements OnInit {

    form: UntypedFormGroup;
    services: Lookup[] = [{id: 1, name: 'Service1'}, {id: 2, name: 'Service2'}, {id: 3, name: 'Service3'}];
    teeTimes: Lookup[] = [];
    holes: Lookup[] = [{id: 1, name: '9'}, {id: 2, name: '18'}, {id: 3, name: '27'}, {id: 4, name: '36'}, {id: 5, name: 'Twilight'}];

    generatedProducts = [];
    selectedProducts = [];
    currentProductName: string;
    productName: string;

    constructor(private fb: UntypedFormBuilder, private confirmationService: ConfirmationService,
                private breadcrumbService: BreadcrumbService,
                private route: ActivatedRoute) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'GFP', routerLink: '/product-setup/gfp'},
            {label: 'New Products Bulk'}
        ]);
    }

    ngOnInit(): void {
        for (let i = 0; i < 12; i++) {
            const tee: Lookup = {id: i + 1, name: i + 1};
            this.teeTimes.push(tee);
        }
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            services: [null, Validators.required],
            tee_times: [null, Validators.required],
            holes: [null, Validators.required],
        });
    }

    submit(): void {
        this.generatedProducts = [];
        this.fetch(this.form.value.services, this.form.value.tee_times, this.form.value.holes);
    }

    fetch(services: Lookup[], tees: Lookup[], holes: Lookup[]) {
        services.forEach(service => {
            tees.forEach(tee => {
                holes.forEach(hole => {
                    this.generatedProducts.push({
                        name: tee.name + 'x' + hole.name + ' ' + service.name,
                        service_id: service.id,
                        service_name: service.name,
                        tee_time_id: tee.id,
                        tee_time_name: tee.name,
                        hole_id: hole.id,
                        hole_name: hole.name,
                        validity_from: new Date(),
                        validity_to: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
                        active: 1
                    });
                });
            });
        });
    }

    saveChanges() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        const clone: ProductService = {
            ...this.form.value,
            company: this.form.get('company').value.id,
            provider: this.form.get('provider').value.id,
            country: this.form.get('country').value.id,
            city: this.form.get('city').value?.id || null,
        };
    }

    edit(product) {
    }

    delete(index) {
        this.generatedProducts.splice(index, 1);
    }

    inlineUpdate(index) {
    }

    openNameEdit(product) {
        this.productName = product.name;
        this.currentProductName = product.name;
    }

    changeStatus(event, index) {
        this.generatedProducts[index].active = event.checked;
    }
}
