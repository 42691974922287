import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import GgTableCustomFN from '../../../_classes/ggTableCustomFN';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {GolfCoursesService} from '../../_services/golf-courses.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {GolfCourse} from '../../../_models/golf-course';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-courses-table',
  templateUrl: './courses-table.component.html',
  styleUrls: ['./courses-table.component.css']
})
export class CoursesTableComponent implements OnInit {

  course: any;
  selectedCourses: any[];
  courses: any[];

  // filter vars
  tableHelper = new GgTableCustomFN();
  filters = {
    company_name: [],
    region_name: [],
    country_name: [],
    city_name: [],
    area_name: [],
    holes: [],
    assigned_user_name: [],
    created_by_user_name: []
  };
  booleanFilter = [{name: this.translate.transform('active'), val: 1},
    {name: this.translate.transform('inactive'), val: 0}];

  exporter = new GgTableCustomFN();
  actionMenuItems: MenuItem[] = [];
  pagination: any;
  searchValue = '';
  publishStatuses = ['None', 'Published', 'Pending Publish', 'Unpublished'];
  topStatuses = ['None', 'Yes', 'No'];
  @ViewChild('searchInput') searchInput: ElementRef;
  showDeletePrevent = false;
  first = 0;
  initialPageParams = {
    first: 0,
    rows: 10,
    globalFilter: null,
    sortField: null,
    sortOrder: null,
    filters: null
  };
  @ViewChild('dt', {static: true}) private dataTable: Table;

  constructor(private golfCoursesService: GolfCoursesService,
              private msgService: MessageService, private confirmationService: ConfirmationService,
              private router: Router, private route: ActivatedRoute, private accountService: AccountService,
              private messageService: MessageService, protected translate: TranslatePipe,
              public translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.actionMenuItems = [
      {label: 'Edit', icon: 'pi pi-fw pi-plus'},
      {label: 'Delete', icon: 'pi pi-fw pi-download'},
    ];
    this.getAllGolfCourses(this.initialPageParams);
  }

  getAllGolfCourses(event) {
    let topNValue: any = 'None';
    if (event.filters?.top[0]?.value === 'Yes') {
      topNValue = 1;
    } else if (event.filters?.top[0]?.value === 'No') {
      topNValue = 0;
    }
    this.golfCoursesService.getPaginatedGolfCourses(
      event.first / event.rows + 1,
      event.rows,
      event.filters?.global?.value,
      event.filters?.publish[0]?.value,
      topNValue,
      event.sortField,
      event.sortOrder)
      .subscribe((res: any) => {
        this.courses = res.data;
        this.pagination = res.meta;
        this.courses.forEach(course => {
          course.created_at = new Date(course.created_at);
          course.region_name = course.region?.name_en;
          course.country_name = course.country?.name_en;
          course.city_name = course.city?.name_en;
          course.area_name = course.area?.name_en;
        });
        this.tableHelper.populateFilter(this.filters, this.courses);
      });
  }

  edit(course) {
    this.router.navigate(['edit/' + course.id], {relativeTo: this.route});
  }

  delete(course, index) {
    this.confirmationService.confirm({
      message: this.translate.transform('confirmMessage'),
      accept: () => {
        this.golfCoursesService.deleteGolfCourse(course.id).subscribe((res: any) => {
          if (res.has_requests) {
            this.showDeletePrevent = true;
          } else {
            this.courses.splice(index, 1);
            this.unPublishDeletedCourse(course);
          }
        });
      },
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }

  unPublishDeletedCourse(course: GolfCourse) {
    if (course.show_website) {
      const obj = {
        auth: 'GAP_API',
        token: environment.token,
        type: 'golfcourse',
        object_id: course.id,
        action: 'delete'
      };
      this.golfCoursesService.publishToWebsite(obj).subscribe((res: any) => {
        if (res.update_status === 'Updated Successfully') {
          this.golfCoursesService.publish(course.id).subscribe((resp: any) => {
            this.deleteMessage();
          });
        }
      });
    } else {
      this.deleteMessage();
    }
  }

  deleteMessage() {
    this.msgService.add({
      severity: 'success', summary: this.translate.transform('golfCourses'),
      detail: this.translate.transform('deleteGolfCourseMessage')
    });
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedCourses || this.selectedCourses.length < 1) {
      this.msgService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.exporter.exportExcel(this.selectedCourses);
  }

  topChanged(course) {
    this.golfCoursesService.updateGolfCourse(course.id, course).subscribe(res => {
      this.messageService.add({
        severity: 'success', summary: 'Update Golf Course',
        detail: 'Golf course has been deleted successfully'
      });
    });
  }
}
