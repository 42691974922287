import {Component, Inject, OnInit} from '@angular/core';
import {Lookup} from '../../../_models/lookup';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-add-price-list',
    templateUrl: './add-price-list.component.html',
    styleUrls: ['./add-price-list.component.css']
})
export class AddPriceListComponent implements OnInit {

    form: UntypedFormGroup;
    services: Lookup[] = [{id: 1, name: 'Service1'}, {id: 2, name: 'Service2'}, {id: 3, name: 'Service3'}];
    serviceTypes: Lookup[] = [{id: 1, name: 'Golf'}, {id: 2, name: 'Hotel'}, {id: 3, name: 'Golf Hotel'}];
    listTypes: Lookup[] = [{id: 1, name: 'Selling'}, {id: 2, name: 'Purchasing'}, {id: 3, name: 'Tui'}];
    markupList: Lookup[] = [{id: 0, name: 'Manual'}, {id: 1, name: 'Selling'}, {id: 2, name: 'Purchasing'}, {id: 3, name: 'Tui'}];
    seasons: Lookup[] = [{id: 1, name: 'Season 1'}, {id: 2, name: 'Season 2'}, {id: 3, name: 'Season 3'}];

    constructor(private breadcrumbService: BreadcrumbService, private fb: UntypedFormBuilder,
                private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService,
                private messageService: MessageService, @Inject(DOCUMENT) private document: Document) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Price List', routerLink: '/product-setup/price-list'},
            {label: 'Add Price List'},
        ]);
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            service: [null, Validators.required],
            serviceType: [null, Validators.required],
            name: [null, Validators.required],
            type: [null, Validators.required],
            active: [1],
            markup: [0, Validators.required],
            markupValue: [{value: null, disabled: true}],
        });
    }

    markupChanged(event) {
        console.log(event.value);
        if (event.value == 0) {
            this.form.get('markupValue').disable();
        } else {
            this.form.get('markupValue').enable();
        }
    }

    submit() {
        console.log(this.form.value);
    }
}
