import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UsersServices} from '../../../_services/users-services';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../_models/user';
import {AccountService} from '../../../_services/account.service';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

    addMode = false;
    editMode = false;
    user: User;
    loginUser: User;

    constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService,
                private confirmationService: ConfirmationService, public usersServices: UsersServices,
                private router: Router, private route: ActivatedRoute, private accountService: AccountService) {
    }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe(res => {
            this.loginUser = res;
        });
        if (this.route.snapshot.paramMap.get('id')) {
            this.editMode = true;
            this.getUserById(this.route.snapshot.paramMap.get('id'));
        } else {
            this.addMode = true;
            if (this.loginUser.company_id === 1 && this.route.snapshot.queryParamMap.get('company_id')) {
                this.breadcrumbService.setItems([
                    {label: 'Dashboard', routerLink: '/'},
                    {label: 'Users', routerLink: 'admin/users'},
                    {
                        label: 'Company Users', routerLink: 'admin/companies/edit/' +
                            this.route.snapshot.queryParamMap.get('company_id') + '/users'
                    },
                    {label: 'New User'},
                ]);
            } else if (this.loginUser.company_id === 1) {
                this.breadcrumbService.setItems([
                    {label: 'Dashboard', routerLink: '/'},
                    {label: 'Users', routerLink: 'admin/users'},
                    {label: 'New User'},
                ]);
            } else {
                this.breadcrumbService.setItems([
                    {label: 'Dashboard', routerLink: '/'},
                    {
                        label: 'Company Users', routerLink: 'admin/companies/edit/' +
                            this.route.snapshot.queryParamMap.get('company_id') + '/users'
                    },
                    {label: 'New User'},
                ]);
            }
        }
    }

    getUserById(id) {
        this.usersServices.getUser(id).subscribe(res => {
            this.user = res;
            if (this.loginUser.company_id === 1) {
                this.breadcrumbService.setItems([
                    {label: 'Dashboard', routerLink: '/'},
                    {label: 'Users', routerLink: 'admin/users'},
                    {label: 'Company Users', routerLink: 'admin/companies/edit/' + this.user?.company_id + '/users'},
                    {label: 'Edit User'},
                ]);
            } else {
                this.breadcrumbService.setItems([
                    {label: 'Dashboard', routerLink: '/'},
                    {label: 'Company Users', routerLink: 'admin/companies/edit/' + this.user?.company_id + '/users'},
                    {label: 'Edit User'},
                ]);
            }
        });
    }
}
