import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ProductService} from '../../_models/product-service';

@Injectable({
    providedIn: 'root'
})
export class GfpService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getAllProducts() {
        return this.http.get(this.baseUrl + 'products/get/all')
            .pipe(map((res: any) => {
                return res.products;
            }));
    }
    getProductsByCity(cityId) {
        return this.http.get(this.baseUrl + 'products/get/all?city_id=' + cityId)
            .pipe(map((res: any) => {
                return res.products;
            }));
    }
    getGfpsByCompany(companyId) {
        return this.http.get(this.baseUrl + 'products/get/all?company_id=' + companyId)
            .pipe(map((res: any) => {
                return res.products;
            }));
    }
    getProductsByCountry(countryId) {
        return this.http.get(this.baseUrl + 'products/get/all?country_id=' + countryId)
            .pipe(map((res: any) => {
                return res.products;
            }));
    }
    getProductsByTuiCode(tuiCode) {
        return this.http.get(this.baseUrl + 'products/get/all?tui_code=' + tuiCode);
    }
    getPaginatedProducts(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'products?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + searchKeyword);
    }
    search(searchKeyword, cityId?, hotelId?) {
        return this.http.get(this.baseUrl + 'products/get/all?search=' + searchKeyword +
            (cityId ? ('&city_id=' + cityId) : '') + (hotelId ? ('&hotel_id=' + hotelId) : ''));
    }
    getByService(serviceId) {
        return this.http.get(this.baseUrl + 'products/get/all?page=' + '&service_id=' + serviceId)
            .pipe(map((res: any) => res.products));
    }

    getProduct(id: number): Observable<ProductService> {
        return this.http.get<{ status: boolean; service: ProductService }>(`${this.baseUrl}products/${id}`)
            .pipe(map((res: any) => res.product));
    }

    getTeeTimes() {
        return this.http.get(this.baseUrl + 'products/tee-times/all');
    }

    getHoles() {
        return this.http.get(this.baseUrl + 'products/holes/all');
    }

    addProduct(obj) {
        return this.http.post(this.baseUrl + 'products', obj);
    }
    updateProduct(obj) {
        return this.http.put(this.baseUrl + 'products/' + obj.id, obj);
    }
    deleteProduct(obj) {
        return this.http.delete(this.baseUrl + 'products/' + obj.id);
    }
    getProductsByServiceCity(serviceId, cityId) {
        return this.http.get(this.baseUrl + 'products/get/all?service_id=' + serviceId + '&city_id=' + cityId);
    }
}
