<div class="p-grid">
    <div class="p-col-12">
        <img src="{{image ? image : '../../../assets/layout/images/pages/user.png'}}" width="100px"
             height="100px" alt="user image">
    </div>
    <div class="p-col-12">
        <p-fileUpload name="myfile[]" customUpload="true" multiple="true" [auto]="true"
                      mode="basic"
                      [chooseLabel]="chooseLabel" chooseIcon="pi-pencil" (onSelect)="imageSelected($event)"
                      #uploader maxFileSize="1000000" (uploadHandler)="upload($event)"
                      withCredentials="true" styleClass="uploader-class"
                      [showUploadButton]="false" [showCancelButton]="false" [disabled]="disabled">
        </p-fileUpload>
    </div>
    <div *ngIf="showDelete" class="p-col-12">
        <button pButton type="button" label="Delete" icon="pi pi-trash"
                class="p-button-outlined p-button-danger" (click)="delete.emit()"></button>
    </div>
</div>
