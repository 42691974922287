<p-toast></p-toast>
<div class="p-grid">
    <div class="p-md-3">
        <app-countries-tree (selectedItem)="itemSelected($event)"></app-countries-tree>
    </div>
    <div class="p-md-9">
        <app-country-form *ngIf="selectedNode && selectedNode.level == 0" [countyId]="selectedNode.id"></app-country-form>
        <app-city-form *ngIf="selectedNode && selectedNode.level == 1" [cityId]="selectedNode.id"></app-city-form>
    </div>
</div>
