import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../_services/account.service';

@Component({
    selector: 'app-golf-holiday',
    templateUrl: './golf-holiday.component.html',
    styleUrls: ['./golf-holiday.component.css']
})
export class GolfHolidayComponent implements OnInit {
    activeView = 'table';

    constructor(private breadcrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute,
                private accountService: AccountService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Product Setup'},
            {label: 'Golf Holiday Products'}
        ]);
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParamMap.has('view')) {
            if (this.route.snapshot.queryParamMap.get('view') == 'tree') {
                this.activeView = 'tree';
            } else if (this.route.snapshot.queryParamMap.get('view') == 'table') {
                this.activeView = 'table';
            }
        }
    }

    addNew() {
        this.router.navigate(['add'], {relativeTo: this.route});
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }
    changeActiveView(view: string) {
        this.activeView = view;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                view: view
            }
        });
    }
}
