import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ConfirmationService, MessageService, TreeNode} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { RolesPermissionService } from 'src/app/_services/roles-permission.service';
import { Role } from 'src/app/_models/roles-permissions';
import {GGTreeNode} from '../../../_models/GGTreeModel';
import {GgTree} from '../../../_classes/ggTree';

@Component({
    selector: 'app-add-role',
    templateUrl: './add-role.component.html',
    styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

    role: FormRole = {
        name: null,
        status: 1,
        permissions: [],
        id: null
    };

    activeOptions = [
        {name: 'Yes', value: 1},
        {name: 'No', value: 0}
    ];

    permissionsList: GGTreeNode[];

    isLoadingPermissionsTree = true;

    isEditMode = false;

    tree = new GgTree({data: [], filterPaths: true});


    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router, private activatedRoute: ActivatedRoute,
        private rolesPermissionsAPI: RolesPermissionService,
        private route: ActivatedRoute,
    ) {}

    async ngOnInit() {
        const editRoleId: number = parseInt(this.route.snapshot.paramMap.get('id'), 10);

        if (editRoleId > -1) {
            this.isEditMode = true;
        }

        await this.getPermissions();

        // Edit mode
        if (this.isEditMode) {
            try {
                const roleDetails: Role = await this.rolesPermissionsAPI.getRoleDetails(editRoleId);
                console.log(roleDetails);

                this.role.id = roleDetails.id;
                this.role.name = roleDetails.name;
                this.role.status = roleDetails.status;
                this.role.permissions = this.convertPermissionIdToPermissionsTreeObject(roleDetails);

                this.isLoadingPermissionsTree = false;
            } catch (error) {
                console.error('Reading role details error', error);
                this.messageService.add({
                    severity: 'error', summary: 'Edit Role Error',
                    detail: 'Unexpected error happened while reading role details'
                });
                this.isLoadingPermissionsTree = false;
            }
        } else {
            this.isLoadingPermissionsTree = false;
        }
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Roles', routerLink: '/admin/roles'},
            {label: this.isEditMode ? 'Edit Role' : 'New Role'},
        ]);
    }

    async getPermissions() {
        try {
            const modules = await this.rolesPermissionsAPI.getModulesList();

            this.tree.options.data = modules;
            this.permissionsList = this.tree.getTree();
        } catch (error) {
            console.error('Get modules for Permissions error', error);
        }
    }

    async submit(form) {
        // re-format permissions array
        const cloneRole: any = { ...this.role };
        cloneRole.permissions = this.convertRolePermissionsToArray(this.role.permissions);

        // submit
        if (!this.isEditMode) {
            await this.createNewRole(form, cloneRole);
        } else {
            await this.editRole(cloneRole);
        }

    }

    async createNewRole(form, role: Role) {
        try {
            const res = await this.rolesPermissionsAPI.createRole(role);
            if (res) {
                this.messageService.add({
                    severity: 'success', summary: 'New Role Created',
                    detail: 'New role has been created successfully'
                });
                // Reset form values
                form.reset();
                this.role.permissions = [];
            }
        } catch (error) {
            console.error('Create new role error', error);
            this.messageService.add({
                severity: 'error', summary: 'Create New Role',
                detail: 'Unexpected error happened while creating new role'
            });
        }
    }

    async editRole(role: Role) {
        try {
            const res = await this.rolesPermissionsAPI.updateRole(role);
            if (res) {
                this.messageService.add({
                    severity: 'success', summary: 'Role Updated',
                    detail: 'Role has been updated successfully!'
                });
            }
        } catch (error) {
            console.error('Update role error', error);
            this.messageService.add({
                severity: 'error', summary: 'Update Role',
                detail: 'Unexpected error happened while updating role!'
            });
        }
    }


    // helpers
    /**
     * convert selected permissions TreeNode to number array to be compatible with role object at API end point.
     * @param permissions TreeNode
     * @returns number array of permissions id
     */
    private convertRolePermissionsToArray(permissions: any[]): number[] {
        const filteredPermissions = permissions.filter(permission => permission.leaf);
        return filteredPermissions.map(permission => permission.id);
    }

    /**
     * convert Role Permissions to TreeNode array to use it as selected values for Tree UI
     * @param role GG Role tree
     * @returns NodeTree array
     */
    private convertPermissionIdToPermissionsTreeObject(role: Role): TreeNode[] {
        return this.tree.getSelectedNodes(role.permissions, 'code');
    }

}

interface FormRole {
    name: string;
    status: number;
    permissions: TreeNode[];
    id?: number;
}
