<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid">
            <div class="p-col-10">
                <h5>{{this.editMode ? 'Edit GFP' : 'Add GFP'}}</h5>
            </div>
            <div class="p-col-2">
                <app-switch-input [formControl]="form.get('status')" [label]="'Active'"
                                  [inline]="true" class="float-right"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <app-text-input [label]="'GFP name'" [formControl]="form.get('name')"
                                [placeholder]="'GFP name'"></app-text-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-select-input [label]="'Company/Service Provider ID'" [formControl]="form.get('booking_code')"
                [options]="bookingCodes" [optionLabel]="'displayName'"
                (optionChanged)="bookingCodeChanged()"></app-select-input>
            </div>
            <div class="p-col-12 p-md-3">
                <div class="p-field">

                    <p class="mb-2">Contract <span class="p-error">*</span>
                        <i class="pi pi-info-circle input-hint" *ngIf="!form.get('booking_code').value"
                           pTooltip="You must select Company/Service Provider ID first" tooltipPosition="top"></i>
                    </p>

                    <p-dropdown [options]="services" [formControl]="form.get('service_id')" [appendTo]="'body'"
                                optionLabel="name" optionValue="id" [showClear]="true"
                                placeholder="Select a contract" (onChange)="serviceChanged($event)"
                                [disabled]="!form.get('booking_code').value" [filter]="true"
                    ></p-dropdown>

                    <small *ngIf="form.get('service_id').touched && form.get('service_id').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-12 p-md-3">
                <app-select-input [options]="teeTimes" [formControl]="form.get('tee_time_id')"
                                  [optionLabel]="'name'" [optionValue]="'id'" [filter]="true"
                                  [label]="'Tee time'" (optionChanged)="teeTimeChanges()"></app-select-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-6">
                <app-text-area [label]="'Additional information'"
                               [formControl]="form.controls['additional_information']">
                </app-text-area>
            </div>

            <div class="p-col-6">
                <span class="p-fluid">
                    <label class="full-width">Tags</label>
                    <p-autoComplete [formControl]="form.get('tags')" [suggestions]="filteredTags" delay="1000"
                                    (completeMethod)="filterTags($event)" field="name" [multiple]="true"
                                    (onSelect)="tagSelected($event)">
                    </p-autoComplete>
                </span>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>Reference Codes</h5>
        <div class="p-grid">
            <div *ngIf="editMode" class="p-col-12 p-md-4">
                <app-text-input label="GFP ID" [formControl]="form.get('code')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-text-input label="DaVinci Booking Code" [formControl]="form.get('ref_code')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-4">
                <app-text-input label="@ComRes Booking Code" [formControl]="form.get('tui_code')"></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>Contract, Booking & Service Handling</h5>
            </div>
            <div>
                <app-switch-input class="float-right" *ngIf="showServiceInfoButton"
                                  [formControl]="form.get('use_service_configurations')"
                                  [label]="'Use contract configurations'"
                                  [inline]="true"
                                  (changed)="serviceInfoButtonClick($event)"></app-switch-input>
                <a class="view-company-link"
                   *ngIf="form.get('service_id').value && form.get('use_service_configurations').value == 1"
                   [routerLink]="'/product-setup/contracts/edit/' + form.get('service_id').value" target="_blank">View
                    contract</a>

            </div>
        </div>
        <div class="p-grid">
            <!--<div class="p-col-12 p-md-2">
                <div class="p-field">
                    <label class="full-width">Invoicing <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="invoice_handler_id"
                                    [suggestions]="companies" delay="1000"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.get('invoice_handler_id').touched && form.get('invoice_handler_id').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>-->
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="bookingOptions" [label]="'Booking possible for'"
                                  [formControl]="form.controls['booking_possible_for']"
                                  (optionChanged)="bookingPossibleChanged($event.value)"></app-select-input>
            </div>
            <div class="p-col-12 p-md-10 half-rem-padding">
                <app-multi-select [options]="bookings" [formControl]="form.controls['hotels']" [filter]="true"
                                  [label]="'Hotels'" [placeholder]="'Select hotels'"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="serviceHandlerTypes"
                                  (optionChanged)="serviceHandlerTypeChanged($event.value)"
                                  [label]="'Service handler type'"
                                  [formControl]="form.controls['service_handler_type_id']"
                                  [filter]="true" [optionLabel]="'name'" [optionValue]="'id'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-2"
                 *ngIf="(form.get('service_handler_type_id').value === 4)
                 && form.get('booking_possible_for').value == 'Hotel'">
                <app-switch-input [label]="'Use Destination Hotel'" (changed)="useDestHotelsChanges($event.checked)"
                                  [formControl]="form.controls['use_destination_hotel']"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-4 half-rem-padding">
                <app-select-input [formControl]="form.controls['service_handler_id']" [options]="serviceHandlers"
                                  [optionValue]="'id'" [optionLabel]="'name'"
                                  [label]="'Service Handler'" [filter]="true"></app-select-input>
            </div>
        </div>
    </div>

    <div class="card toolbar-demo">
        <h5>Package Details</h5>
        <div *ngIf="details.length > 0" class="card">
            <p-table [value]="details" responsiveLayout="scroll"
                     styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Golf club</th>
                        <th>Golf course</th>
                        <th>Type</th>
                        <th>Fixed</th>
                        <th>Min</th>
                        <th>Max</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-detail let-i="rowIndex">
                    <tr>
                        <td>{{detail.company_name}}</td>
                        <td>{{detail.golf_course_name}}</td>
                        <td>{{detail.type}}</td>
                        <td>{{detail.tee_time_name}}</td>
                        <td>{{detail.min_tee_time_name}}</td>
                        <td>{{detail.max_tee_time_name}}</td>
                        <td>
                            <i class="pi pi-trash" (click)="removeDetail(i)"></i>
                            <i class="pi pi-pencil ml-4" (click)="editDetail(i)"></i>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <ng-container>
            <div class="p-grid p-jc-between">
                <div class="p-col-12 p-md-col">
                    <!--<app-select-input [options]="golfClubs" [formControl]="detailForm.get('company')"
                                      [optionLabel]="'name'" [filter]="true"
                                      [label]="'Golf club'" (optionChanged)="getGolfCourses($event.value.id)"></app-select-input>-->
                    <label class="full-width">Golf club <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" [formControl]="detailForm.get('company')"
                                    [suggestions]="golfClubs" (onSelect)="getGolfCourses($event.id)" delay="1000"
                                    (completeMethod)="searchGolfClubs($event.query)" [forceSelection]="true">
                        <ng-template let-golfClub pTemplate="item">
                            <div>{{golfClub.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="detailForm.get('company').touched && detailForm.get('company').errors?.required"
                           class="p-error">required</small>
                </div>
                <div class="p-col-12 p-md-col">
                    <label>Golf course <span class="p-error">*</span></label>
                    <p-dropdown [options]="golfCourses" optionDisabled="disabled"
                                [formControl]="detailForm.get('golf_course')"
                                [filter]="true" [optionLabel]="'name'"
                                placeholder="Golf course">
                        <ng-template let-option pTemplate="item">
                            {{option.name}}
                            <span *ngIf="option.disabled"
                                  class="p-error"> (Cannot choose the same golf course twice!)</span>
                        </ng-template>
                    </p-dropdown>
                    <small *ngIf="detailForm.get('golf_course').touched && detailForm.get('golf_course').errors?.required"
                           class="p-error">required</small>
                </div>
                <div class="p-col-12 p-md-col">
                    <label>Type <span class="p-error">*</span></label>
                    <p-selectButton [styleClass]="'contract-checkbox'" [options]="switchOptions"
                                    (onChange)="typeChanged()"
                                    [formControl]="detailForm.get('type')"></p-selectButton>
                    <small *ngIf="detailForm.get('type').touched && detailForm.get('type').errors?.required"
                           class="p-error">required</small>
                </div>
                <div class="p-col-12 p-md-col" *ngIf="detailForm.get('type').value == 'Fixed'">
                    <app-select-input [options]="availableTimes" [optionLabel]="'name'"
                                      [formControl]="detailForm.get('tee_time')"
                                      [label]="'Fixed'"></app-select-input>
                </div>
                <div class="p-col-12 p-md-col" *ngIf="detailForm.get('type').value == 'Min-Max'">
                    <app-select-input [options]="availableTimes" [optionLabel]="'name'"
                                      [formControl]="detailForm.get('min_tee_time')"
                                      [label]="'Min'" (optionChanged)="minChanged($event.value)"></app-select-input>
                </div>
                <div class="p-col-12 p-md-col" *ngIf="detailForm.get('type').value == 'Min-Max'">
                    <app-select-input [options]="availableMaxValues" [optionLabel]="'name'"
                                      [formControl]="detailForm.get('max_tee_time')"
                                      [label]="'Max'"></app-select-input>
                </div>
            </div>
            <div class="buttons">
                <button pButton type="button" (click)="addDetail()"
                        class="float-right">{{detailForEdit == null ? 'Save GFP detail' : 'Update GFP detail'}}
                </button>
            </div>
        </ng-container>
    </div>
    <ul class="invalid-controls">
        <li *ngFor="let control of invalidControls">{{control}} is invalid</li>
    </ul>
    <div class="buttons">
        <p *ngIf="showTeeTimeCountError" class="p-error">Add {{missingTeeTimesCount}} more tee times!</p>
        <button pButton class="float-right" type="submit">{{editMode ? ('saveChanges' | translate) : 'Create'}}</button>
    </div>
</form>
<!--<ng-container *ngIf="showContract">
    <p-dialog [(visible)]="showContract" [position]="'center'" [modal]="true"
              [style]="{width: '80vw'}"
              [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="onCloseNewContract($event)">
        <app-product-services-form></app-product-services-form>
    </p-dialog>
</ng-container>-->
