import {Component, OnInit} from '@angular/core';
import {HotelProduct} from '../../../_models/hotel-product';
import GgTableCustomFN from '../../../_classes/ggTableCustomFN';
import {HotelProductsService} from '../../_services/hotel-products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-hotel-products-table',
    templateUrl: './hotel-products-table.component.html',
    styleUrls: ['./hotel-products-table.component.css']
})
export class HotelProductsTableComponent implements OnInit {

    products: HotelProduct[];
    pagination: any;
    product: any;
    selectedProducts: any[];

    // Filter vars
    tableHelper = new GgTableCustomFN();
    searchValue = '';

    constructor(private hotelProductsService: HotelProductsService,
                private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private accountService: AccountService, private translate: TranslatePipe) {
    }

    ngOnInit(): void {
        this.getHotelProducts(1, 10, this.searchValue);
    }

    getHotelProducts(pageNumber, pagination, searchKeyword) {
        this.hotelProductsService.getPaginated(pageNumber, pagination, searchKeyword).subscribe((res: any) => {
            this.products = res.products.data;
            this.pagination = res.products.meta;
        });
    }


    edit(product) {
        this.router.navigate(['edit/' + product.id], {relativeTo: this.route});
    }

    delete(product) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.hotelProductsService.delete(product.id).subscribe(res => {
                    this.getHotelProducts(this.pagination.current_page, this.pagination.per_page,
                        this.searchValue);
                    this.messageService.add({
                        severity: 'success', summary: 'Delete Hotel Product',
                        detail: 'selected product has been deleted successfully'
                    });
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedProducts || this.selectedProducts.length < 1) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedProducts);
    }

    searchProducts() {
        this.getHotelProducts(1, this.pagination.per_page, this.searchValue);
    }

    pageChanged(event) {
        this.getHotelProducts(event.page + 1, +event.rows, this.searchValue);
    }
}

