<p-toast></p-toast>
<div class="card toolbar-demo">
    <div class="p-grid p-jc-between mt-2 mb-3">
        <div class="p-d-flex p-ai-center">
            <h5 class="p-m-1 float-left">Golf Holiday Products</h5>

            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-ml-2"
                    (click)="addNew()"></button>
            <!--<button pButton pRipple label="Bulk" icon="pi pi-plus" class="p-button-primary p-ml-2"
                    (click)="addNewBulk()" *ngIf="checkPermission('1636370273100')"></button>-->

            <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger float-right"
                    (click)="deleteSelectedProducts()"
                    [disabled]="!selectedCompanies || !selectedCompanies.length"></button>-->
        </div>
        <div style="display: flex; align-items: center">
            <button pButton pRipple type="button" icon="pi pi-sitemap" (click)="changeActiveView('tree')" [ngClass]="{'p-button-outlined': activeView != 'tree'}" class="p-button-rounded" style="margin-right: 5px"></button>
            <button pButton pRipple type="button" icon="pi pi-table" (click)="changeActiveView('table')" [ngClass]="{'p-button-outlined': activeView != 'table'}" class="p-button-rounded"></button>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12">
            <app-golf-holiday-table *ngIf="activeView === 'table'"></app-golf-holiday-table>

            <app-golf-holiday-tree *ngIf="activeView === 'tree'"></app-golf-holiday-tree>
        </div>
    </div>
</div>
