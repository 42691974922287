import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {GolfCourse} from '../../../../_models/golf-course';
import {GolfCoursesService} from '../../../_services/golf-courses.service';
import {MessageService} from 'primeng/api';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';

@Component({
    selector: 'app-gc-facilities',
    templateUrl: './gc-facilities.component.html',
    styleUrls: ['./gc-facilities.component.css']
})
export class GcFacilitiesComponent implements OnInit {
    facilitiesList: any;
    form: UntypedFormArray;
    course: GolfCourse;

    constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, public translateService: TranslateService,
                private coursesServices: GolfCoursesService, private messageService: MessageService,
                private translate: TranslatePipe, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('golfCourses'), routerLink: '/golf-courses'},
            {label: this.translate.transform('facilities')},
        ]);
    }

    ngOnInit(): void {
        this.initForm();
        this.facilitiesList = this.route.parent.snapshot.data.basics[0].facilities;
        this.course = this.route.parent.snapshot.data.basics[1];
        this.initFacilities();
    }

    initForm() {
        this.form = this.fb.array([]);
    }

    initFacilities() {
        this.form.clear();
        this.facilitiesList.forEach(facility => {
            this.form.push(this.fb.group({
                id: facility.id,
                name: facility.translations[this.translateService.currentLang === 'de' ? 1 : 0].name,
                available: null,
                number: null,
                icon: facility.icon
            }));
        });
        ////////////// Fetch Facilities /////////////////
        const matchedFacilities = this.form.controls.filter(o1 => this.course.facilities
            .some(o2 => o1.value.id === o2.id));
        matchedFacilities.forEach(control => {
            console.log(control.value);
            control.get('available').setValue(1);
        });
        ////////////// Fetch Facilities /////////////////
    }

    submitForm() {
        ////////////// Fetch Facilities ///////////////
        const facilitiesObj = [];
        this.form.value.forEach(facility => {
            if (facility.available === 1) {
                facilitiesObj.push(facility);
            }
        });
        this.course.facilities = facilitiesObj;
        ////////////// Fetch Facilities ///////////////
        const courseId = this.route.snapshot.parent.paramMap.get('id');
        this.coursesServices.updateGolfCourseFacilities(courseId, {facilities: facilitiesObj}).subscribe((res: any) => {
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfCourses'),
                detail: this.translate.transform('updateGolfCourseMessage')
            });
        });
    }

}
