import {Component, OnInit} from '@angular/core';
import {GolfCoursesService} from '../../_services/golf-courses.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-courses-tree',
    templateUrl: './courses-tree.component.html',
    styleUrls: ['./courses-tree.component.css']
})
export class CoursesTreeComponent implements OnInit {

    courses: any[];
    selectedCourse: any;

    constructor(private golfCoursesService: GolfCoursesService, private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    citySelected(event) {
        this.golfCoursesService.getGolfCoursesByCity(event.id).subscribe((res: any) => {
            this.courses = res;
            this.courses.map(course => {
                course.label = course.name;
                course.level = 3;
                course.indexes = [];
                course.key = 'course-' + course.id;
            });
        });
    }
    courseSelected(event) {
        this.selectedCourse = event;
    }
    viewFullCourse() {
        window.open(window.location.origin + '/golf-courses/courses/edit/' + this.selectedCourse.id,
            '_blank');
    }

}
