import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../../../_models/company';
import {BreadcrumbService} from '../../../../breadcrumb.service';

@Component({
    selector: 'app-company-data',
    templateUrl: './company-data.component.html',
    styleUrls: ['./company-data.component.css']
})
export class CompanyDataComponent implements OnInit {

    company: Company;

    constructor(private breadcrumbService: BreadcrumbService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Companies', routerLink: '/admin/companies'},
            {label: 'Company Data'}
        ]);
        this.company = this.route.snapshot.parent.data.company;
    }

}
