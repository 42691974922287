import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AbstractControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    apiUrl = environment.apiUrl;
    petrolTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: '#415464',
            buttonColor: '#fff'
        },
        dial: {
            dialBackgroundColor: '#495761',
        },
        clockFace: {
            clockFaceBackgroundColor: '#495761',
            clockHandColor: '#7CFAC3',
            clockFaceTimeInactiveColor: '#fff'
        }
    };

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    }

    getCurrentUser() {
        let user;
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        } else {
            user = JSON.parse(sessionStorage.getItem('user'));
        }
        return user;
    }

    getDestinations(object?) {
        return this.http.get(this.apiUrl + 'regions' + (object ? '?object=' + object : ''));
    }

    dateToIsoString(date: Date): string {
        const d = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('0' + date.getDate()).slice(-2);
        return d;
    }

    timeToString(date: Date) {
        const t = date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + '00';
        return t;
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }

    addNYears(date: Date, numOfYears: number) {
        date.setFullYear(date.getFullYear() + numOfYears);
        return date;
    }

    addNDays(date: Date, numOfDays: number) {
        date.setDate(date.getDate() + numOfDays);
        return date;
    }

    checkObjectChanges(formValue, requestValue) {
        if (Object.entries(formValue).sort().toString() === Object.entries(requestValue).sort().toString()) {
            return true;
        }
        return false;
    }

    setDefaultTime(stringTime) {
        const time = new Date();
        time.setHours(stringTime.split(':')[0]);
        time.setMinutes(stringTime.split(':')[1]);
        return time;
    }

    preventMinus(event) {
        if (event.target.value < 0) {
            event.target.value = 0;
        }
    }

    updateQueryParams(params) {
        this.router.navigate([],
            {
                relativeTo: this.route,
                queryParams: params
            });
    }
}
