import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import {AccountService} from './_services/account.service';
import {Router} from '@angular/router';
import {GcmService} from './_services/gcm.service';
import {RoutingState} from './_services/route-state.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    horizontalMenu: boolean;

    darkMode = false;

    menuColorMode = 'light';

    menuColor = 'layout-menu-light';

    themeColor = 'blue';

    layoutColor = 'blue';

    ripple = true;

    inputStyle = 'outlined';
    message;

    constructor(
        private primengConfig: PrimeNGConfig,
        private accountService: AccountService,
        private router: Router,
        private gcmService: GcmService,
        private confirmationService: ConfirmationService,
        private msgService: MessageService,
        routingState: RoutingState,
        public translate: TranslateService
    ) {
        routingState.loadRouting();
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.setCurrentUser();
        if (this.gcmService.isNotiAllowed === 'default' ||
            (this.gcmService.isNotiAllowed === 'granted' && !localStorage.getItem('device_key'))) {
            this.gcmService.requestToken();
        } else if (this.gcmService.isNotiAllowed === 'granted') {
            this.gcmService.currentMessage.subscribe(noti => {
                this.msgService.add({
                    severity: 'info',
                    summary: noti?.title || 'Notification',
                    detail: noti?.body || 'You got a notification',
                    icon: 'pi-bell'
                });
            });
        }
        this.message = this.gcmService.currentMessage;

    }

    setCurrentUser() {
        if (localStorage.getItem('user')) {
            this.accountService.setCurrentUser(JSON.parse(localStorage.getItem('user')));
            this.accountService.setDefaultLanguage();
        } else if (sessionStorage.getItem('user')) {
            this.accountService.setCurrentUser(JSON.parse(sessionStorage.getItem('user')));
            this.accountService.setDefaultLanguage();
        }
    }
}
