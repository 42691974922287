import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CompaniesComponent} from './companies/companies.component';
import {AddCompanyComponent} from './companies/add-company/add-company.component';
import {AuthGuard} from '../_guards/auth.guard';
import {UsersComponent} from './users/users.component';
import {AddUserComponent} from './users/add-user/add-user.component';
import {RolesComponent} from './roles/roles.component';
import {AddRoleComponent} from './roles/add-role/add-role.component';
import {PermissionsComponent} from './permissions/permissions.component';
import {AddPermissionComponent} from './permissions/add-permission/add-permission.component';
import {IntegrationComponent} from './integration/integration.component';
import {CompanyDataComponent} from './companies/edit-company/company-data/company-data.component';
import {CompanyUsersComponent} from './companies/edit-company/company-users/company-users.component';
import {CompanyDocumentsComponent} from './companies/edit-company/company-documents/company-documents.component';
import {EditCompanyComponent} from './companies/edit-company/edit-company.component';
import {CompanyAddressBookComponent} from './companies/edit-company/company-address-book/company-address-book.component';
import {CompanyContractsComponent} from './companies/edit-company/company-contracts/company-contracts.component';
import {CompanyGfpComponent} from './companies/edit-company/company-gfp/company-gfp.component';
import {CompanyResolver} from '../_resolvers/company.resolver';
import {ReportsComponent} from './reports/reports.component';

const routes: Routes = [
  {path: '', redirectTo: 'users', pathMatch: 'full'},
  {
    path: 'companies', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
        path: '', component: CompaniesComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636370981775', type: 'action'}
      },
      {
        path: 'add', component: AddCompanyComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371001099', type: 'action'}
      },
      {
        path: 'edit/:id', component: EditCompanyComponent, canActivate: [AuthGuard], children: [
          {path: '', redirectTo: 'basic', pathMatch: 'full'},
          {path: 'basic', component: CompanyDataComponent, data: {activeTab: 0}},
          {path: 'users', component: CompanyUsersComponent, data: {activeTab: 1}},
          {path: 'documents', component: CompanyDocumentsComponent, data: {activeTab: 2}},
          {path: 'address-book', component: CompanyAddressBookComponent, data: {activeTab: 3}},
          {path: 'contracts', component: CompanyContractsComponent, data: {activeTab: 4}},
          {path: 'gfps', component: CompanyGfpComponent, data: {activeTab: 5}},
        ], resolve: {company: CompanyResolver}, runGuardsAndResolvers: 'always',
        data: {requirePermission: true, code: '1636371030691', type: 'action'}
      }
    ], canActivate: [AuthGuard], data: {requirePermission: true, page_id: 18, type: 'page'}
  },
  {
    path: 'users', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
        path: '', component: UsersComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371104882', type: 'action'}
      },
      {
        path: 'add', component: AddUserComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371119598', type: 'action'}
      },
      {
        path: 'edit/:id', component: AddUserComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371153093', type: 'action'}
      }
    ], canActivate: [AuthGuard], data: {requirePermission: true, page_id: 19, type: 'page'}
  },
  {
    path: 'roles', children: [

      {path: '', redirectTo: './', pathMatch: 'full'},
      {
        path: '', component: RolesComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371219141', type: 'action'}
      },
      {
        path: 'add', component: AddRoleComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371241875', type: 'action'}
      },
      {
        path: 'edit/:id', component: AddRoleComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371273090', type: 'action'}
      },
    ], canActivate: [AuthGuard], data: {requirePermission: true, page_id: 20, type: 'page'}

  },
  {
    path: 'permissions', children: [
      {path: '', redirectTo: './', pathMatch: 'full'},
      {
        path: '', component: PermissionsComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371336486', type: 'action'}
      },
      {
        path: 'add', component: AddPermissionComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371364127', type: 'action'}
      },
      {
        path: 'edit/:id', component: AddPermissionComponent, canActivate: [AuthGuard],
        data: {requirePermission: true, code: '1636371399146', type: 'action'}
      }
    ], canActivate: [AuthGuard], data: {requirePermission: true, page_id: 21, type: 'page'}
  },
  {
    path: 'integrations',
    component: IntegrationComponent,
    canActivate: [AuthGuard],
    data: {requirePermission: true, page_id: 22, type: 'page'}
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: {requirePermission: true, page_id: 27, type: 'page'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
