import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {City, Country} from '../../_models/destinations';
import {DestinationsService} from '../_services/destinations.service';
import {ConfirmationService, MessageService, SortEvent} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {tap} from 'rxjs/operators';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-country',
    templateUrl: './country.component.html',
    styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
    countries$: Observable<Country[]>;
    selectedCountries: Country[];

    // Filter vars
    tableHelper = new GgTableCustomFN();
    filters = {
        [this.translateService.currentLang === 'de' ? 'region_name_de' : 'region_name']: [],
        publish: [],
    };
    booleanFilter = [{name: this.translate.transform('active'), val: 1},
        {name: this.translate.transform('inactive'), val: 0}];

    TopFilter = [{name: this.translate.transform('yes'), val: 1},
        {name: this.translate.transform('no'), val: 0}];

    constructor(
        private destService: DestinationsService,
        private msgService: MessageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private accountService: AccountService,
        private confirmationService: ConfirmationService, private translate: TranslatePipe,
        public translateService: TranslateService
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('countries')},
        ]);
    }

    ngOnInit(): void {
        // check if there is a countryId
        const regionId = +this.activatedRoute.snapshot.paramMap.get('id');
        if (regionId > 0) {
            this.getAllCountries(regionId);
        } else {
            this.getAllCountries();
        }
    }

    getAllCountries(regionId?: number): void {
        this.countries$ = this.destService.getCountriesListTable(regionId).pipe(tap(countries => {
            this.tableHelper.populateFilter(this.filters, countries);
        }));
    }

    addNew(): void {
        this.router.navigateByUrl('destinations/countries/add');
    }

    topChanged(country: Country) {
        country.region_id = country.region.id;
        this.destService.updateCountry(country).subscribe(res => {
            if (res) {
                this.msgService.add({
                    severity: 'success',
                    summary: this.translate.transform('countries'),
                    detail: this.translate.transform('updateCountryMessage')
                });
            }
        });
    }

    printCities(cities: City[]): string {
        if (!cities) {
            return '';
        }

        const citiesName = [];

        for (const city of cities) {
            citiesName.push(city.name);
        }

        return citiesName.join(', ');
    }

    edit(country: Country) {
        this.router.navigateByUrl(`destinations/countries/edit/${country.id}`);
    }

    delete(country: Country) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.destService.deleteCountry(country.id).subscribe(res => {
                    if (res) {
                        this.getAllCountries();
                        this.msgService.add({
                            severity: 'success',
                            summary: this.translate.transform('countries'),
                            detail: this.translate.transform('deleteCountryMessage')
                        });
                    }
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedCountries || this.selectedCountries.length < 1) {
            this.msgService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedCountries);
    }

    sort(event: SortEvent) {
        console.log(event);
        event.data.sort((data1, data2) => {
            // @ts-ignore
            const newObject = event.field.split('.').reduceRight((obj, next) => ({
                [next]: obj
            }), '');
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return (event.order * result);
        });
    }
}
