import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';

import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AuthGuard} from './_guards/auth.guard';
import {UserProfileComponent} from './admin/users/user-profile/user-profile.component';
import {VerifyVoucherComponent} from './verify-voucher/verify-voucher.component';
import {EditCompanyComponent} from './admin/companies/edit-company/edit-company.component';
import {CompanyDataComponent} from './admin/companies/edit-company/company-data/company-data.component';
import {CompanyUsersComponent} from './admin/companies/edit-company/company-users/company-users.component';
import {CompanyDocumentsComponent} from './admin/companies/edit-company/company-documents/company-documents.component';
import {CompanyAddressBookComponent} from './admin/companies/edit-company/company-address-book/company-address-book.component';
import {CompanyResolver} from './_resolvers/company.resolver';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {
                        path: '', component: DashboardDemoComponent, canActivate: [AuthGuard],
                        data: {requirePermission: true, page_id: 23, type: 'page'}
                    },
                    {
                        path: 'client-portal',
                        loadChildren: () => import('./client-portal/client-portal.module').then(m => m.ClientPortalModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 17, type: 'module'}
                    },
                    {
                        path: 'requests', loadChildren: () => import('./request/request.module').then(m => m.RequestModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 3, type: 'module'}
                    },
                    {
                        path: 'company', children: [
                            {path: '', redirectTo: 'company-profile', pathMatch: 'full'},
                            {
                                path: 'company-profile/:id', component: EditCompanyComponent, canActivate: [AuthGuard], children: [
                                    {path: '', redirectTo: 'basic', pathMatch: 'full'},
                                    {path: 'basic', component: CompanyDataComponent, data: {activeTab: 0}},
                                    {path: 'users', component: CompanyUsersComponent, data: {activeTab: 1}},
                                    {path: 'documents', component: CompanyDocumentsComponent, data: {activeTab: 2}},
                                    {path: 'address-book', component: CompanyAddressBookComponent, data: {activeTab: 3}}
                                ], resolve: {company: CompanyResolver},
                                data: {requirePermission: true, code: '1636370374661', type: 'action'}
                            },
                        ], canActivate: [AuthGuard], data: {requirePermission: true, module_id: 12, type: 'module'}
                    },
                    {path: 'user-profile', component: UserProfileComponent},
                    {
                        path: 'admin',
                        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 14, type: 'module'}
                    },
                    {
                        path: 'destinations',
                        loadChildren: () => import('./destination/destination.module').then(m => m.DestinationModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 13, type: 'module'}
                    },
                    {
                        path: 'enquiry', loadChildren: () => import('./enquiry/enquiry.module').then(m => m.EnquiryModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 2, type: 'module'}
                    },
                    {
                        path: 'golf-courses',
                        loadChildren: () => import('./golf-courses/golf-courses.module').then(m => m.GolfCoursesModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 5, type: 'module'}
                    },
                    {
                        path: 'hotels',
                        loadChildren: () => import('./hotels/hotels.module').then(m => m.HotelsModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 6, type: 'module'}
                    },
                    {
                        path: 'dmc', loadChildren: () => import('./dmc/dmc.module').then(m => m.DmcModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 7, type: 'module'}
                    },

                    {
                        path: 'tour-operators',
                        loadChildren: () => import('./tour-operator/tour-operator.module').then(m => m.TourOperatorModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 8, type: 'module'}
                    },

                    {
                        path: 'travel-agencies',
                        loadChildren: () => import('./travel-agency/travel-agency.module').then(m => m.TravelAgencyModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 9, type: 'module'}
                    },

                    {
                        path: 'product-setup',
                        loadChildren: () => import('./product-setup/product-setup.module').then(m => m.ProductSetupModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 10, type: 'module'}
                    },

                    {
                        path: 'package-cms',
                        loadChildren: () => import('./package-cms/package-cms.module').then(m => m.PackageCmsModule),
                        canActivate: [AuthGuard], data: {requirePermission: true, module_id: 18, type: 'module'}
                    }
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: 'verify-voucher/:id', component: VerifyVoucherComponent},
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
