import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {

    @Input() image: any;
    @Input() chooseLabel: string;
    @Input() showDelete = false;
    @Input() disabled = true;
    @Output() submitUpload = new EventEmitter();
    @Output() delete = new EventEmitter();
    @ViewChild('uploader', {static: true}) uploader: FileUpload;

    constructor() {
    }

    ngOnInit(): void {
        this.uploader.clear();
    }


    upload(event) {
        this.submitUpload.emit(event);
    }
    imageSelected(event) {
        const reader = new FileReader();
        if (event.currentFiles && event.currentFiles.length > 0) {
            const file = event.currentFiles[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.image = reader.result;
            };
        }
    }

    clear() {
        this.uploader.clear();
        this.image = null;
        this.showDelete = false;
    }

    filesCount() {
        return this.uploader.files.length;
    }

}
