import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../breadcrumb.service';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
    constructor(private translate: TranslatePipe, private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit(): void {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('golfCourses'), routerLink: '/golf-courses'},
            {label: this.translate.transform('trainings')},
        ]);
    }
}
