import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {map} from 'rxjs/operators';
import {Company} from '../_models/company';
import {CompaniesService} from '../_services/companies-service';

@Injectable({
  providedIn: 'root'
})
export class CompanyResolver implements Resolve<Company> {
  constructor(private companiesService: CompaniesService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company> {
    const companyId = route.params['id'];
    if (companyId) {
      return this.companiesService.getCompany(companyId).pipe(map((res: any) => {
        return res;
      }));
    } else {
      return of(null);
    }
  }
}
