<p-toast></p-toast>

<div class="card">

    <p-table #dt [value]="users"
             [globalFilterFields]="['first_name','delegate_name','delegate_email','assigned_username', 'mobile_number']"
             [(selection)]="selectedUsers" [rowHover]="true" dataKey="id"
             [scrollable]="true" [scrollDirection]="'both'"
             [rows]="10" [paginator]="companyId ? true : false"
             [showCurrentPageReport]="companyId ? true : false"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 *ngIf="!companyId" class="p-m-1 float-left">Users </h5>

                    <button pButton pRipple icon="pi pi-plus" class="p-button-rounded p-ml-2"
                            (click)="addNew()" *ngIf="checkPermission('1636371119598')"></button>
                </div>

                <button pButton pRipple label="Export" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right"  *ngIf="checkPermission('1636371203335')"></button>

                <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" [(ngModel)]="searchValue"
                           (input)="companyId ? dt.filterGlobal($event.target.value, 'contains') : searchUsers()" placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="first_name">Name
                    <p-sortIcon field="first_name"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="username">Username
                    <p-sortIcon field="username"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="email">Email
                    <p-sortIcon field="email"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="mobile_number">Phone
                    <p-sortIcon field="mobile_number"></p-sortIcon>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="department" class="p-d-flex p-jc-between p-ai-center">
                    Department
                    <p-sortIcon field="department"></p-sortIcon>
                    <app-filter fieldName="department" [filterOptions]="filters.department"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" pSortableColumn="title" class="p-d-flex p-jc-between p-ai-center">
                    Title
                    <p-sortIcon field="title"></p-sortIcon>
                    <app-filter fieldName="title" [filterOptions]="filters.title"></app-filter>
                </th>
                <th style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371135145') || checkPermission('1636371153093') || checkPermission('1636371172053') "
                >Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-index="rowIndex">
            <tr>
                <td style="width: 3rem">
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td style="min-width:160px; max-width: 160px; cursor: pointer" (click)="edit(user)">{{user.first_name + ' ' + user.last_name}}</td>
                <td style="min-width:160px; max-width: 160px;" class="word-break">{{user.username}}</td>
                <td style="min-width:160px; max-width: 160px;" class="word-break">{{user.email}}</td>
                <td style="min-width:160px; max-width: 160px;">{{user.mobile_number}}</td>
                <td style="min-width:160px; max-width: 160px;">{{user.department}}</td>
                <td style="min-width:160px; max-width: 160px;">{{user.title}}</td>
                <td style="min-width:160px; max-width: 160px;" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636371135145') || checkPermission('1636371153093') || checkPermission('1636371172053') "
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"
                                *ngIf="checkPermission('1636371153093')"
                            ><a class="dropdown-item" (click)="edit(user)">Edit</a></li>
                            <li role="menuitem"
                                *ngIf="checkPermission('1636371172053')"
                            ><a class="dropdown-item" (click)="delete(user, index)">Delete</a></li>
                        </ul>
                    </div>

                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator *ngIf="users?.length > 0 && !companyId" [rows]="+pagination?.per_page" [totalRecords]="pagination?.total"
                 (onPageChange)="pageChanged($event)" [showJumpToPageInput]="true"
                 [rowsPerPageOptions]="[10, 50, 100, 200]" #p [showCurrentPageReport]="true"
                 currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"></p-paginator>
</div>


