import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Lookup} from '../../_models/lookup';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../../_models/user';
import {PageState} from '../../_models/page-state';
import {SharedService} from '../../_services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class RequestAPIService {

    baseUrl = environment.apiUrl;
    leaderTypes$: Observable<Lookup[]>;
    user: User;
    pagination: any;
    pageState: PageState = {pageNumber: 1, perPage: 10};

    constructor(private http: HttpClient) {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        } else {
            this.user = JSON.parse(sessionStorage.getItem('user'));
        }
        if (this.user.childs.length > 0) {
            this.user.childs = this.user.childs.map(child => {
                return child.child_id;
            });
        }
    }

    prepareParams() {
        const params = {
            page: this.pageState.pageNumber,
            per_page: this.pageState.perPage,
            keyword: this.pageState?.searchParams?.keyword || null,
            value: this.pageState?.searchParams?.value || null
        };
        return params;
    }

    getAllRequests(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests?page=' + pageNumber + '&pagination=' +
            pagination + (params ? params : '')).pipe(map((res: any) => {
            this.pagination = res.requests.meta;
            const requests = res.requests.data;
            return requests;
        }));
    }

    getLeaderTypes() {
        if (!this.leaderTypes$) {
            this.leaderTypes$ = this.http.get<any>(this.baseUrl + 'requests/leader-types/all');
        }
        return this.leaderTypes$;
    }

    getRequestTypes() {
        return this.http.get(this.baseUrl + 'requests/types/all');
    }

    getRequest(id) {
        return this.http.get(this.baseUrl + 'requests/' + id);
    }

    updateRequest(obj) {
        return this.http.put(this.baseUrl + 'requests/' + obj.id + '/update-bulk', obj);
    }

    createRequest(obj){
        return this.http.post(this.baseUrl + 'requests/store-bulk', obj);
    }

    updateDestination(obj) {
        return this.http.put(this.baseUrl + 'requests/destinations/' + obj.id, obj);
    }

    deleteDestination(destinationId) {
        return this.http.delete(this.baseUrl + 'requests/destinations/' + destinationId);
    }

    createPlayerBulk(obj, requestId) {
        return this.http.post(this.baseUrl + 'requests/' + requestId + '/players/bulk', obj);
    }

    updatePlayer(obj) {
        return this.http.put(this.baseUrl + 'requests/players/' + obj.id, obj);
    }

    deletePlayer(playerId) {
        return this.http.delete(this.baseUrl + 'requests/players/' + playerId);
    }

    createProduct(obj, requestId) {
        return this.http.post(this.baseUrl + 'requests/' + requestId + '/products', obj);
    }

    deleteProduct(productId) {
        return this.http.delete(this.baseUrl + 'requests/products/' + productId);
    }

    createTeeTimesList(obj, requestId) {
        return this.http.post(this.baseUrl + 'requests/' + requestId + '/tee-times/bulk', obj);
    }

    updateTeeTimesList(obj, prodId) {
        return this.http.put(this.baseUrl + 'requests/tee-times/bulk/' + prodId, obj);
    }

    submitRequest(requestId, obj) {
        return this.http.put(this.baseUrl + 'requests/' + requestId + '/status', obj);
    }

    getRequestStatuses() {
        return this.http.get(this.baseUrl + 'requests/statuses/all');
    }

    createComment(id, obj) {
        return this.http.post(this.baseUrl + 'requests/' + id + '/comment', obj);
    }

    updateComment(obj) {
        return this.http.put(this.baseUrl + 'comments/' + obj.id, obj);
    }

    deleteComment(id) {
        return this.http.delete(this.baseUrl + 'comments/' + id);
    }

    createDocument(id, obj) {
        return this.http.post(this.baseUrl + 'requests/' + id + '/document', obj);
    }

    deleteDocument(id) {
        return this.http.delete(this.baseUrl + 'documents/' + id);
    }

    addAlternativeTeeTime(teeTimeId, obj) {
        return this.http.post(this.baseUrl + 'requests/tee-times/' + teeTimeId + '/alternative', obj);
    }

    getProductStatuses() {
        return this.http.get(this.baseUrl + 'requests/products/statuses/all');
    }

    getTeeTimeStatuses() {
        return this.http.get(this.baseUrl + 'requests/tee-times/statuses/all');
    }

    changeProductStatus(productId, obj) {
        return this.http.put(this.baseUrl + 'requests/products/' + productId + '/status', obj);
    }

    changeTeeTimeStatus(teeTimeId, obj) {
        return this.http.put(this.baseUrl + 'requests/tee-times/' + teeTimeId + '/status', obj);
    }

    checkPlayerAssignee(request, playerIndex) {
        let result = true;
        request.destinations.forEach(destination => {
            destination.products.forEach(product => {
                product.request_tee_times.forEach(teeTime => {
                    if (teeTime.request_player_id == request.players[playerIndex].id) {
                        result = false;
                    }
                });
            });
        });
        return result;
    }

    deleteAlternative(id) {
        return this.http.delete(this.baseUrl + 'requests/tee-times/' + id);
    }

    deleteRequest(requestId) {
        return this.http.delete(this.baseUrl + 'requests/' + requestId);
    }

    delegateClient(obj, requestId) {
        return this.http.put(this.baseUrl + 'requests/' + requestId + '/delegate-client-link', obj);
    }
    getDelegationToken(requestId) {
        return this.http.get(this.baseUrl + 'requests/' + requestId + '/delegate-client-token');
    }

    searchTaTo(keyword) {
        return this.http.get(this.baseUrl + 'requests/agencies/operators/all?search=' + keyword);
    }

    checkRequestTuiCode(code) {
        return this.http.get(this.baseUrl + 'requests/validation/check?tui_ref_code=' + code);
    }

    //////////////// TEE TIME VIEW ///////////////////
    getByHandlers(pageNumber, params?) {
        return this.http.get(this.baseUrl + 'requests/handlers-teetimes/all?page=' + pageNumber +
        (params ? params : ''));
    }

    getByGolfCourses(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests/golfcourses-teetimes/all?page=' + pageNumber +
            (params ? params : ''));
    }

    getByAgencies(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests/agencies-teetimes/all?page=' + pageNumber +
            (params ? params : ''));
    }

    getByOperators(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests/operators-teetimes/all?page=' + pageNumber +
            (params ? params : ''));
    }

    getByTeeTimeDate(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests/date-teetimes/all?page=' + pageNumber +
            (params ? params : ''));
    }

    getByRequestsDate(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests/request-date-teetimes/all?page=' + pageNumber +
            (params ? params : ''));
    }

    getByRequests(pageNumber, pagination, params?) {
        return this.http.get(this.baseUrl + 'requests/requests-teetimes/all?page=' + pageNumber +
            (params ? params : ''));
    }

    //////////////// TEE TIME VIEW ///////////////////
    //////////////// VOUCHERS ///////////////////
    getRequestVouchers(requestId, productId?) {
        return this.http.get(this.baseUrl + 'requests/' + requestId + '/vouchers' +
            (productId ? '?request_product_id=' + productId : ''));
    }

    //////////////// VOUCHERS ///////////////////
    getHandlerTeeTimes(handlerId) {
        return this.http.get(this.baseUrl + 'requests/company/' + handlerId + '/teetimes');
    }
    getRequestTeeTimes(requestId) {
        return this.http.get(this.baseUrl + 'requests/' + requestId + '/teetimes');
    }
    getGolfCourseTeeTimes(golfCourseId) {
        return this.http.get(this.baseUrl + 'requests/golfCourse/' + golfCourseId + '/teetimes');
    }
    getAgencyTeeTimes(agencyId) {
        return this.http.get(this.baseUrl + 'requests/travelAgency/' + agencyId + '/teetimes');
    }
    getOperatorTeeTimes(operatorId) {
        return this.http.get(this.baseUrl + 'requests/tourOperator/' + operatorId + '/teetimes');
    }
    getTeeTimeDateTeeTimes(teeTimeDate) {
        return this.http.get(this.baseUrl + 'requests/teetimes/date?tee_time_date=' + teeTimeDate);
    }
    getRequestsDateTeeTimes(requestsDate) {
        return this.http.get(this.baseUrl + 'requests/teetimes/request-date?request_date=' + requestsDate);
    }
    getAlternatives(teeTimeId) {
        return this.http.get(this.baseUrl + 'requests/tee-times/' + teeTimeId + '/alternative');
    }
}
