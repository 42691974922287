import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HotelProductsService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    create(obj) {
        return this.http.post(this.baseUrl + 'hotel-products', obj);
    }
    update(obj) {
        return this.http.put(this.baseUrl + 'hotel-products/' + obj.id, obj);
    }
    delete(id) {
        return this.http.delete(this.baseUrl + 'hotel-products/' + id);
    }
    getAll(cityId?, countryId?) {
        return this.http.get(this.baseUrl + 'hotel-products/get/all?' + (cityId ? 'city_id=' + cityId : '')
            + (countryId ? 'country_id=' + countryId : ''));
    }
    getPaginated(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'hotel-products?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + searchKeyword);
    }
    getById(id) {
        return this.http.get(this.baseUrl + 'hotel-products/' + id).pipe(map((res: any) => {
            return res.product;
        }));
    }
    getByServiceId(serviceId) {
        return this.http.get(this.baseUrl + 'hotel-products/get/all?service_id=' + serviceId);
    }
    getByService(serviceId) {
        return this.http.get(this.baseUrl + 'hotel-products/get/all?page=' + '&service_id=' + serviceId)
            .pipe(map((res: any) => res.products));
    }
}
