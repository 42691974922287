import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GolfCoursesService} from '../../_services/golf-courses.service';
import {MessageService} from 'primeng/api';
import {TranslatePipe} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-edit-course',
    templateUrl: './edit-course.component.html',
    styleUrls: ['./edit-course.component.css']
})
export class EditCourseComponent implements OnInit {

    tabItems: any[];
    activeTab: any;
    course: any;

    constructor(private router: Router, private route: ActivatedRoute, private translate: TranslatePipe,
                public coursesService: GolfCoursesService, private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.tabItems = [
            {label: this.translate.transform('basic'), routerLink: ['./general']},
            {label: this.translate.transform('cms'), routerLink: ['./cms']},
            {label: this.translate.transform('facilities'), routerLink: ['./facilities']},
            {label: this.translate.transform('services'), routerLink: ['./services']},
            {label: this.translate.transform('trainings'), routerLink: ['./trainings']}
        ];
        this.activeTab = this.tabItems[this.route.snapshot.firstChild.data['activeTab']];

        this.course = this.route.snapshot.data.basics[1];
    }

    publishToWebsite() {
        const obj = {
            auth: 'GAP_API',
            token: environment.token,
            type: 'golfcourse',
            object_id: this.coursesService.course.id
        };
        this.coursesService.publishToWebsite(obj).subscribe((res: any) => {
            if (res.update_status === 'Updated Successfully') {
                this.coursesService.publish(this.coursesService.course.id).subscribe((resp: any) => {
                    this.coursesService.course.is_publish_required = false;
                    this.messageService.add({
                        severity: 'success', summary: 'Publish Golf Course',
                        detail: 'New updates has been published successfully to the website'
                    });
                });
            }
        });
    }

}
