<form [formGroup]='form' (ngSubmit)="submitForm()">

    <!-- BASIC SECTION -->
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>Basic</h5>
            </div>
            <div>
                <app-switch-input [formControl]="form.controls['active']" [label]="'Active'"
                                  [inline]="true"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-2" *ngIf="addMode">
                <app-switch-input [formControl]="form.controls['is_parent']" (changed)="isParentChanged($event)"
                                  [label]="'Create company profile'"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label class="full-width">Company name
                        <span *ngIf="this.sharedServices.checkRequired(form.get('company'))" class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="company" delay="1000"
                                    [suggestions]="companies" (onSelect)="companySelected($event)"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.controls.company.touched && form.controls.company.errors?.required"
                           class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="form.controls['name']" [label]="'Tour operator name'"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]='form.controls["ref_id"]'
                                [label]='"Ref. ID"'></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-switch-input [formControl]="form.controls['has_gfp_requests']"
                                  [label]="'GFP'"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <!--<div class="p-col-12 p-md-6">
                <label>Destinations</label>
                <app-gg-tree [(select)]="destinations" #tree
                             [inputTreeValues]="destinations$ | async"
                             [initSelections]="initSelectedDestinations"
                ></app-gg-tree>
               &lt;!&ndash; <small *ngIf="form.controls.cities.touched && form.controls.cities.errors?.required"
                       class="p-error">required</small>&ndash;&gt;
            </div>-->
            <!--<div class="p-col-12 p-md-6">
                <div class="p-field">
                    <label class="full-width">Travel agencies</label>
                    <p-autoComplete class="autocomplete" delay="1000" [field]="'name'" formControlName="travelagencies"
                                    [suggestions]="agencies" [multiple]="true"
                                    (completeMethod)="searchTravelAgencies($event)" [forceSelection]="true">
                        <ng-template let-agency pTemplate="item">
                            <div>{{agency.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>-->
        </div>
        <div class="p-grid">

            <div class="p-col-12 mt-4" formArrayName="notes">
                <div *ngFor="let note of notes.controls; let i=index">
                    <app-text-area [formControl]="note" [label]="'Note (' + (i + 1) + ')'" (delete)="removeNote(i)"
                                   [showTrash]="notes.controls.length > 1 ? true : false"></app-text-area>
                </div>
                <div class="buttons">
                    <button pButton type="button" (click)="addNote()">Add note</button>
                </div>
            </div>
        </div>
    </div>

    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>Company Contact</h5>
            </div>
            <div>
                <app-switch-input class="float-right" *ngIf="showCompanyInfoButton"
                                  [formControl]="form.controls['is_company_address']" [label]="'Use company Info'"
                                  [inline]="true"
                                  (changed)="companyInfoButtonClick($event)"></app-switch-input>
                <a class="view-company-link" *ngIf="form.get('company').value && form.get('is_company_address').value == 1"
                   (click)="viewCompany()">{{'viewCompany' | translate}}</a>

            </div>
        </div>
        <!--<div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'text'" [label]="'Delegate name'"
                                [formControl]="form.controls['delegate_name']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'email'" [label]="'Delegate email'"
                                [formControl]="form.controls['delegate_email']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Delegate mobile</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Delegate mobile'"
                            formControlName="delegate_mobile_number"
                    ></ngx-intl-tel-input>
                    <small *ngIf="form.controls.delegate_mobile_number.touched && form.controls.delegate_mobile_number.errors?.required"
                           class="p-error">required</small>
                    <small  class="p-error" *ngIf="form.controls.delegate_mobile_number.touched && form.controls.delegate_mobile_number.invalid">
                        Invalid number
                    </small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label class="full-width">GG assigned user</label>
                    <p-autoComplete class="autocomplete" [field]="'full_name'" formControlName="assigned_user"
                                    [suggestions]="users" delay="1000"
                                    (completeMethod)="searchUsers($event)" [forceSelection]="true">
                        <ng-template let-user pTemplate="item">
                            <div>{{user.first_name + ' ' + user.last_name}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
        </div>-->
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'continent' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="regions" [formControl]="form.get('region_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                                placeholder="{{'selectContinent' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getRegionName(form.get('region_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('region_id').touched && form.get('region_id').errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'country' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="countries" [formControl]="form.get('country_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()"
                                placeholder="{{'selectCountry' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getCountries(null, form.get('region_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCountryName(form.get('country_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('country_id').touched && form.get('country_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'region' | translate}} <span class="p-error">*</span></label>
                    <p-dropdown [options]="cities" [formControl]="form.get('city_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="cityChanged()"
                                (onClick)="getCities(null, form.get('country_id').value)"
                                placeholder="{{'selectRegion' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCityName(form.get('city_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                            + ' - ' + item?.code}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('city_id').touched && form.get('city_id').errors?.required" class="p-error">{{'required' | translate}}</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>{{'area' | translate}}</label>
                    <p-dropdown [options]="areas" [formControl]="form.get('area_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true"
                                placeholder="{{'selectArea' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                                (onClick)="getAreas(null, form.get('city_id').value)">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getAreaName(form.get('area_id')?.value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item[langKey]}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('area_id')?.touched && form.get('area_id')?.errors?.required"
                           class="p-error">{{'required' | translate}}</small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="form.controls['street']" placeholder="{{'street' | translate}}"
                                label="{{'street' | translate}}"></app-text-input>
            </div>

            <div class="p-col-12 p-md-col">
                <app-text-input [formControl]="form.controls['postal_code']"
                                label="{{'postalCode' | translate}}" placeholder="{{'postalCode' | translate}}"
                                [type]="'number'"></app-text-input>
            </div>
        </div>
        <h5>Tour Operator Contact</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Phone</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Phone'"
                            formControlName="fax"
                    ></ngx-intl-tel-input>
                    <small *ngIf="form.controls.fax.touched && form.controls.fax.errors?.required"
                           class="p-error">required</small>
                    <small  class="p-error" *ngIf="form.controls.fax.touched && form.controls.fax.invalid">
                        Invalid number
                    </small>
                </div>
            </div>

            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Fax</label>
                    <ngx-intl-tel-input
                            [cssClass]="'ngx-phone'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [maxLength]="15"
                            [customPlaceholder]="'Fax'"
                            formControlName="phone"
                    ></ngx-intl-tel-input>
                    <small *ngIf="form.controls.phone.touched && form.controls.phone.errors?.required"
                           class="p-error">required</small>
                    <small  class="p-error" *ngIf="form.controls.phone.touched && form.controls.phone.invalid">
                        Invalid number
                    </small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input [type]="'email'" [label]="'Email'"
                                [formControl]="form.controls['email']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col"></div>
            <div class="p-col-12 p-md-col"></div>
        </div>
    </div>

    <div class="buttons">
        <button class="float-right" pButton type="submit">{{addMode ? 'Create' : ('saveChanges' | translate)}}</button>
    </div>
</form>
