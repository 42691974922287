import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HotelsService} from '../../_services/hotels.service';
import {TranslatePipe} from '@ngx-translate/core';
import {RoomFormComponent} from './room-form/room-form.component';

@Component({
    selector: 'app-rooms-details',
    templateUrl: './rooms-details.component.html',
    styleUrls: ['./rooms-details.component.css',
        '../../../golf-courses/courses/edit-course/services-form/services-form.component.css']
})
export class RoomsDetailsComponent implements OnInit, OnChanges {
    details: any[] = [];
    selectedRoom: any;
    @Input() hotelRooms: any[] = [];
    @Input() facilitiesList: any;
    @Input() roomFieldTypes: any;
    @Input() hotelId: number;
    @Output() hotelUpdated: EventEmitter<any> = new EventEmitter();
    showRoomForm = false;
    @ViewChild('roomFormComponent') roomFormComponent: RoomFormComponent;

    constructor(private hotelsService: HotelsService,
                private route: ActivatedRoute,
                public translate: TranslatePipe) {
    }

    ngOnInit() {
        if (this.route.snapshot.paramMap.has('id')) {
            this.hotelId = +this.route.snapshot.paramMap.get('id');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hotelRooms) {
            this.hotelRooms = changes.hotelRooms.currentValue;
            this.selectedRoom = this.hotelRooms[0];
        }
    }

    changeSelectedRoom(room) {
        if (this.selectedRoom && room.id !== this.selectedRoom.id) {
            this.selectedRoom = null;
            setTimeout(() => {
                this.selectedRoom = room;
            }, 100);
        }
    }

    updateRoomData(room) {
        const roomIndex = this.hotelRooms.findIndex(r => r.id == room.id);
        this.hotelRooms[roomIndex] = room;
        this.selectedRoom = room;
        this.hotelUpdated.emit(room.is_publish_required);
    }

    addNewRoom() {
        this.showRoomForm = true;
    }

    roomDeleted(roomId) {
        this.hotelRooms = this.hotelRooms.filter(x => x.id !== roomId);
        this.selectedRoom = null;
        setTimeout(() => {
            this.selectedRoom = this.hotelRooms[0];
        }, 100);
    }
    closeAddModal(event) {
        this.hotelRooms.push(event);
        this.selectedRoom = null;
        setTimeout(() => {
            this.selectedRoom = this.hotelRooms[this.hotelRooms.length - 1];
        }, 100);
        this.showRoomForm = false;
    }
    roomUpdated(room) {
        this.selectedRoom = room;
        const roomIndex = this.hotelRooms.findIndex(x => x.id === room.id);
        this.hotelRooms[roomIndex] = this.selectedRoom;
    }
}
