import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VoucherService} from '../_services/voucher.service';
import {RequestDetails} from '../_models/request-details';

@Component({
    selector: 'app-verify-voucher',
    templateUrl: './verify-voucher.component.html',
    styleUrls: ['./verify-voucher.component.css']
})
export class VerifyVoucherComponent implements OnInit {

    voucherCode: number;
    request: RequestDetails;
    teeTime: any;
    showNotExistVoucher = false;
    showExpiredVoucher = false;
    today = new Date().setHours(0, 0, 0, 0);

    constructor(private route: ActivatedRoute, private voucherService: VoucherService) {
    }

    ngOnInit(): void {
        this.voucherCode = +this.route.snapshot.paramMap.get('id');
        this.getVoucherByCode();
    }

    getVoucherByCode() {
        this.voucherService.getVoucherByCode(this.voucherCode).subscribe((res: any) => {
            if (res.status === false) {
                this.showNotExistVoucher = true;
                return;
            } else if (new Date(res.request_tee_time.date).setHours(0, 0, 0, 0) < this.today) {
                this.showExpiredVoucher = true;
                return;
            }
            this.request = res.request;
            this.teeTime = [res.request_tee_time];
        });
    }

}
