import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-edit-company',
    templateUrl: './edit-company.component.html',
    styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

    tabItems: any[];
    activeTab: any;
    company: any;

    constructor(private breadcrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Companies', routerLink: ['admin/companies']},
            {label: 'Edit Company'},
        ]);
    }

    ngOnInit(): void {
        if (this.route.snapshot.parent.url[0].path === 'companies') {
            this.tabItems = [
                {label: 'Basic', routerLink: ['./basic']},
                {label: 'Users', routerLink: ['./users']},
                {label: 'Documents', routerLink: ['./documents']},
                {label: 'Address Book', routerLink: ['./address-book']},
                {label: 'Contracts', routerLink: ['./contracts']},
                {label: 'GFPs', routerLink: ['./gfps']}
            ];
        } else if (this.route.snapshot.parent.url[0].path === 'company') {
            this.tabItems = [
                {label: 'Basic', routerLink: ['./basic']},
                {label: 'Users', routerLink: ['./users']},
                {label: 'Documents', routerLink: ['./documents']},
                {label: 'Address Book', routerLink: ['./address-book']}
            ];
        }
        this.activeTab = this.tabItems[this.route.snapshot.firstChild.data['activeTab']];
        this.company = this.route.snapshot.data.company;
    }

}
