<div *ngIf="!inline" class="p-field">
    <label class="full-width">{{label}}</label>
    <p-inputSwitch class="switch-input" [formControl]="ngControl?.control" (onChange)="valueChanged($event)"
                   [trueValue]="1" [falseValue]="0" [disabled]="disabled"></p-inputSwitch>
</div>
<div *ngIf="inline" class="p-field">
    <label class="mr-2">{{label}}</label>
    <p-inputSwitch class="switch-input" [formControl]="ngControl?.control" (onChange)="valueChanged($event)"
                   [trueValue]="1" [falseValue]="0" [disabled]="disabled"></p-inputSwitch>
</div>

