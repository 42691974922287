<div [ngStyle]="{'margin-bottom': (isPortal ? '0px' : '66px')}">
    <div class="p-grid" *ngFor="let player of form.controls; let pIndex = index">
        <div class="p-md-col">
            <app-text-input [label]="'First name'" [formControl]="player.get('first_name')"
                            (blurEvent)="updatePlayer(pIndex)"></app-text-input>
        </div>
        <div class="p-md-col">
            <app-text-input [label]="'Last name'" [formControl]="player.get('last_name')"
                            (blurEvent)="updatePlayer(pIndex)"></app-text-input>
        </div>
        <div class="p-md-col">
            <app-select-input [label]="'Gender'" [options]="genderOptions"
                              [formControl]="player.get('gender')"
                              (optionChanged)="updatePlayer(pIndex)"></app-select-input>
        </div>
        <div class="p-md-col">
            <app-text-input [label]="'HCP'" [formControl]="player.get('hcp')"
                            [type]="'number'"
                            (blurEvent)="updatePlayer(pIndex)"></app-text-input>
        </div>
        <div *ngIf="request.destinations.length > 1" class="p-col-12 p-md-3 half-rem-padding">
            <app-multi-select [options]="request.destinations" [filter]="true"
                              [formControl]="player.get('destinations')" [optionLabel]="'city_name'"
                              [label]="'Destinations'" [optionValue]="'id'" (change)="updatePlayer(pIndex)"
                              [placeholder]="'Select a destination'"></app-multi-select>
        </div>
        <div class="p-md-1">
            <!--<i *ngIf="showPlayerSaveMark(pIndex)"
               class="pi pi-check ml-3 mt-40" (click)="createPlayer(pIndex)"></i>-->
            <i *ngIf="form.controls.length > 1 && player.get('client_id').value == null" class="pi pi-trash mt-40"
               (click)="removePlayer(pIndex)"></i>
            <i *ngIf="pIndex == form.controls.length - 1"
               class="pi pi-plus ml-3 mt-40" (click)="addPlayer()"></i>
        </div>
    </div>
</div>
<div [ngClass]="isPortal ? '' : 'footer'">
    <div class="buttons">
        <button pButton type="button" (click)="submit()" class="float-right" label="{{'saveChanges' | translate}}"></button>
    </div>
</div>

