<p-tree [value]="destinationsTree" [filter]="true" selectionMode="single" scrollHeight="flex"
        (onNodeSelect)="nodeSelected($event)" scrollHeight="400px" (onFilter)="onFilter($event)" #tree
        filterPlaceholder="{{'searchLettersCode' | translate}}">
    <ng-template let-node pTemplate="default">
        <span style="color: #415464">
        <!--<span [ngStyle]="{'color': node.level == 0 ? 'rgb(0,0,0)' : (node.level == 1 ? 'rgb(100,172,229)' :
        (node.level == 2 ? 'rgb(173,83,90)' : (node.level == 3 ? 'rgb(117,182,47)' : 'rgb(4,49,103)'))) }">-->
            <img *ngIf="node.level == 1" src="../../../assets/icons/earth-europe-solid.svg" width="15px"
                 style="margin: 0 10px 3px 0">
            <img *ngIf="node.level == 2" src="../../../assets/icons/city-solid.svg" width="15px"
                 style="margin: 0 10px 3px 0">
            <img *ngIf="node.level == 3" src="../../../assets/icons/file-contract-solid.svg" width="15px"
                 style="margin: 0 10px 3px 0">
            <img *ngIf="node.level == 4" src="../../../assets/icons/golf-ball-tee-solid.svg" width="15px"
                 style="margin: 0 10px 3px 0">
            {{node.label}}</span>
    </ng-template>
</p-tree>
