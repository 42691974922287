import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Company} from '../_models/company';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    baseUrl = environment.apiUrl;
    companies: Company[] = [];

    constructor(private http: HttpClient) {
    }

    getCompanyProfileData() {
        return this.http.get(this.baseUrl + 'user/company');
    }

    getTravelTypes() {
        return this.http.get(this.baseUrl + 'dmcs/traveltypes/all');
    }

    updateCompanyProfile(obj) {
        return this.http.put(this.baseUrl + 'user/company/update-profile', obj);
    }

    changeCompanyProfileLogo(obj) {
        return this.http.post<any>(this.baseUrl + 'user/company/update-logo', obj);
    }

    changeCompanyLogo(obj, id) {
        return this.http.post<any>(this.baseUrl + 'companies/update-logo/' + id, obj);
    }

    getRegions() {
        return this.http.get(this.baseUrl + 'regions');
    }

    getCountries(regionId) {
        return this.http.get(this.baseUrl + 'countries?region_id=' + regionId);
    }

    getCities(countryId) {
        return this.http.get(this.baseUrl + 'cities?country_id=' + countryId);
    }
    getAreas(cityId) {
        return this.http.get(this.baseUrl + 'areas?city_id=' + cityId);
    }

    deleteCompanyLogo() {
        return this.http.post<any>(this.baseUrl + 'user/company/remove-logo', null);
    }

    getAllCompanies() {
        return this.http.get(this.baseUrl + 'companies?pagination=1000&page=1').pipe(map((res: any) => {
            this.companies = res.companies.data;
            return this.companies;
        }));
    }

    getPaginatedCompanies(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'companies?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + (searchKeyword ? searchKeyword : ''));
    }

    getAllCompanyChildren(companyId) {
        return this.http.get(this.baseUrl + 'companies/get/childs?company_id=' + companyId).pipe(map((res: any) => {
            this.companies = res.childs;
            return this.companies;
        }));
    }

    getAllCompaniesByType(typeId) {
        return this.http.get(this.baseUrl + 'users/get/companies?company_type_id=' + typeId);
    }

    getAllCompaniesMinByType(typeId) { // minimized object
        return this.http.get(this.baseUrl + 'users/get/companies-min?company_type_id=' + typeId);
    }

    getAllCompaniesList() {
        return this.http.get(this.baseUrl + 'companies').pipe(map((res: any) => {
            this.companies = res.companies.data;
            return this.companies;
        }));
    }

    getCompany(id) {
        const company = this.companies.find(x => x.id == id);
        if (company !== undefined) {
            return of(company);
        }
        return this.http.get<Company>(this.baseUrl + 'companies/' + id).pipe(map((res: any) => {
            return res.company;
        }));
    }

    deleteCompany(id) {
        return this.http.delete(this.baseUrl + 'companies/' + id).pipe(map(res => {
            this.companies.slice(this.companies.findIndex(x => x.id == id), 1);
            return (this.companies);
        }));
    }

    addCompany(company) {
        return this.http.post(this.baseUrl + 'companies', company);
    }

    updateCompany(company) {
        return this.http.put(this.baseUrl + 'companies/' + company.id, company);
    }

    getCompanyTypes() {
        return this.http.get(this.baseUrl + 'company-types').pipe(map((res: any) => {
            const ggIndex = res.types.findIndex(x => x.id === 1);
            res.types[ggIndex].disabled = true;
            return res.types;
        }));
    }

    getGolfClubs() {
        return this.http.get(this.baseUrl + 'companies?company_type_id=3');
    }

    getHotels() {
        return this.http.get(this.baseUrl + 'companies?company_type_id=4');
    }

    searchCompaniesByTypeList(value, list) {
        let paramsList = new HttpParams();
        for (const item of list) {
            paramsList = paramsList.append('company_type_list[]', item);
        }
        return this.http.get(this.baseUrl + 'users/get/companies?search=' + value, {params: paramsList});
    }

    searchCompanies(value: string) {
        return this.http.get(this.baseUrl + 'users/get/companies?search=' + value);
    }
    searchCompaniesMin(value: string) { // minimized object
        return this.http.get(this.baseUrl + 'users/get/companies-min?search=' + value);
    }

    searchCompaniesByType(value: string, type) {
        return this.http.get(this.baseUrl + 'users/get/companies?search=' + value + '&company_type_id=' + type);
    }

    getGolfCourses() {
        return this.http.get(this.baseUrl + 'golfcourses/get/all');
    }

    getHotelsList() {
        return this.http.get(this.baseUrl + 'hotels/get/all');
    }

    searchBookingCodes(code) {
        return this.http.get(this.baseUrl + 'companies/get/booking-codes?booking_code=' + code)
            .pipe(map((res: any) => {
                    return res.childs;
                })
            );
    }
}
