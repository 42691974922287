import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {SharedService} from '../../_services/shared.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslatePipe} from '@ngx-translate/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ReportsService} from '../../_services/reports.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    searchParams = '';
    searchForm: UntypedFormGroup;
    filterForm: UntypedFormGroup;
    items: any;
    filterTypes: any;
    currentFilter = 1;
    pagination = {page: 1, pagination: 10};
    first = 0;
    today = new Date();

    constructor(private router: Router, private reportsService: ReportsService, private fb: UntypedFormBuilder,
                public sharedService: SharedService, private loader: NgxUiLoaderService,
                private translate: TranslatePipe, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('Reports')}
        ]);
    }

    ngOnInit(): void {
        this.initSearchForm();
        this.initFilterForm();
        setTimeout(() => {
            this.filterTypes = [
                {code: 1, value: this.translate.transform('userActivity')}
            ];
        }, 200);
    }

    getUserActivities() {
        const obj = {...this.filterForm.value, ...this.pagination};
        obj.date_from = this.sharedService.dateToIsoString(obj.date_from);
        obj.date_to = this.sharedService.dateToIsoString(obj.date_to);
        this.reportsService.getUserActivities(obj).subscribe((res: any) => {
            this.items = res;
        });
    }

    initSearchForm() {
        this.searchForm = this.fb.group({
            groupBy: [1]
        });
    }

    initFilterForm() {
        this.filterForm = this.fb.group({
            search: null,
            date_from: new Date(),
            date_to: new Date()
        });
    }

    changeView(route) {
        this.router.navigateByUrl('requests/' + route);
    }

    submitSearch(pageNumber = 1, skipCheckCurrentFilter = false) {
        if ((this.searchForm.value.groupBy !== this.currentFilter) || skipCheckCurrentFilter) {
            this.currentFilter = this.searchForm.value.groupBy;
            switch (this.currentFilter) {
                case 1:
                    this.getUserActivities();
                    break;
                default:
                    this.getUserActivities();
                    break;
            }
        }
    }

    pageChanged(event) {
        this.pagination.pagination = event.rows;
        this.pagination.page = (event.first / event.rows) + 1;
        this.getUserActivities();
    }
}
