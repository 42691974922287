<div class="p-grid">
    <div class="p-col-12 p-md-col">
        <div class="p-field">
            <label>{{'continent' | translate}} <span class="p-error">*</span></label>
            <p-dropdown [options]="regions" [formControl]="form.get('region_id')" [appendTo]="'body'" [disabled]="editArea"
                        [optionValue]="'id'" [showClear]="true" (onChange)="regionChanged()"
                        placeholder="{{'selectContinent' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                <ng-template pTemplate="selectedItem">
                    <div>{{getRegionName(form.get('region_id').value)}}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div>{{item[langKey]}}</div>
                </ng-template>
            </p-dropdown>

            <small *ngIf="form.get('region_id').touched && form.get('region_id').errors?.required"
                   class="p-error">{{'required' | translate}}</small>
        </div>
    </div>

    <div class="p-col-12 p-md-col">
        <div class="p-field">
            <label>{{'country' | translate}} <span class="p-error">*</span></label>
            <p-dropdown [options]="countries" [formControl]="form.get('country_id')" [appendTo]="'body'"
                        [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged()" [disabled]="editArea"
                        placeholder="{{'selectCountry' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                        (onClick)="getCountries(null, form.get('region_id').value)">
                <ng-template pTemplate="selectedItem">
                    <div>{{getCountryName(form.get('country_id').value)}}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name}}</div>
                </ng-template>
            </p-dropdown>

            <small *ngIf="form.get('country_id').touched && form.get('country_id').errors?.required" class="p-error">{{'required' | translate}}</small>
        </div>
    </div>

    <div class="p-col-12 p-md-col">
        <div class="p-field">
            <label>{{'region' | translate}} <span class="p-error">*</span></label>
            <p-dropdown [options]="cities" [formControl]="form.get('city_id')" [appendTo]="'body'" [disabled]="editArea"
                        [optionValue]="'id'" [showClear]="true" (onChange)="cityChanged()"
                        (onClick)="getCities(null, form.get('country_id').value)"
                        placeholder="{{'selectRegion' | translate}}" [filter]="true" filterBy="name_en,name_de,code">
                <ng-template pTemplate="selectedItem">
                    <div>{{getCityName(form.get('city_id').value)}}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div>{{item[this.langKey] || item.translations[this.langKey === 'name_de' ? 1 : 0].name
                    + ' - ' + item?.code}}</div>
                </ng-template>
            </p-dropdown>

            <small *ngIf="form.get('city_id').touched && form.get('city_id').errors?.required" class="p-error">{{'required' | translate}}</small>
        </div>
    </div>
    <div *ngIf="showArea" class="p-col-12 p-md-col">
        <div class="p-field">
            <label>{{'area' | translate}}</label>
            <p-dropdown [options]="areas" [formControl]="form.get('area_id')" [appendTo]="'body'"
                        [optionValue]="'id'" [showClear]="true"
                        placeholder="{{'selectArea' | translate}}" [filter]="true" filterBy="name_en,name_de,code"
                        (onClick)="getAreas(null, form.get('city_id').value)">
            <ng-template pTemplate="selectedItem">
                    <div>{{getAreaName(form.get('area_id').value)}}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
                    <div>{{item[langKey]}}</div>
            </ng-template>
            </p-dropdown>

            <small *ngIf="form.get('area_id').touched && form.get('area_id').errors?.required"
                   class="p-error">{{'required' | translate}}</small>
        </div>
    </div>

    <div *ngIf="showStreet" class="p-col-12 p-md-col">
        <app-text-input [formControl]="form.controls['street']" placeholder="{{'street' | translate}}"
                        label="{{'street' | translate}}"></app-text-input>
    </div>

    <div *ngIf="showPostalCode" class="p-col-12 p-md-col">
        <app-text-input [formControl]="form.controls['postal_code']"
                        label="{{'postalCode' | translate}}" placeholder="{{'postalCode' | translate}}"
                        [type]="'number'"></app-text-input>
    </div>
</div>
