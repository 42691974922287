import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getVoucherByCode(code: string | number) {
    return this.http.get(this.baseUrl + 'voucher/' + code);
  }
}
