import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../_models/lookup';
import {CompaniesService} from '../../_services/companies-service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelsService} from '../_services/hotels.service';
import {User} from '../../_models/user';
import {UsersServices} from '../../_services/users-services';
import {ImagesListComponent} from '../../_shared/images-list/images-list.component';
import {Hotel} from '../../_models/hotel';
import {Company} from '../../_models/company';
import {GolfCourse} from '../../_models/golf-course';
import {GolfCoursesService} from '../../golf-courses/_services/golf-courses.service';
import {TourOperator} from '../../_models/tour-operator';
import {TourOperatorService} from '../../tour-operator/_services/tour-operator.service';
import {TagsService} from '../../_services/tags.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AccountService} from '../../_services/account.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {Area, City, Country, Region} from '../../_models/destinations';
import {DestinationsService} from '../../destination/_services/destinations.service';

@Component({
    selector: 'app-add-hotel',
    templateUrl: './add-hotel.component.html',
    styleUrls: ['./add-hotel.component.css']
})
export class AddHotelComponent implements OnInit, OnChanges {

    hotelForm: UntypedFormGroup;
    hotel: Hotel = {links_hd_images: [], links_logo_images: []};
    hotels: Hotel[];
    users: User[];
    facilitiesList: Lookup[] = [];
    boards: Lookup[];
    addMode = true;
    companies: Company[];
    golfCourses: GolfCourse[];
    storageRoomOptions = [
        {label: 'Yes', value: '1'},
        {label: 'No', value: '0'},
        {label: this.translate.transform('availableGC'), value: '2'},
    ];
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    showCompanyInfoButton = true;
    fieldTypes: any[];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];
    hotelServicesList: any[];
    hotelServicesTypes: any[];
    selectedHotelServices: any[];
    selectedHotelFacilities: any[];
    activeIndex = 0;
    roomFieldTypes: Lookup[];
    roomTypes: Lookup[];
    roomFacilities: Lookup[];
    tourOperators: TourOperator[];
    @Input() hotelId: number;
    invalidControls: any[];
    showWebsiteCurrentValue: number;
    selectedCompany: Company;

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    langKey: string;
    countriesLoaded = false;
    citiesLoaded = false;
    areasLoaded = false;

    @ViewChild('uploader', {static: false}) imagesUploader: ImagesListComponent;
    rating = ['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7'];
    filteredTags: any;
    currentUser: User;

    constructor(private breadcrumbService: BreadcrumbService, private hotelsService: HotelsService,
                private fb: UntypedFormBuilder, private companiesServices: CompaniesService, private usersServices: UsersServices,
                private messageService: MessageService, private router: Router, private route: ActivatedRoute,
                private confirmationService: ConfirmationService, private companiesService: CompaniesService,
                private coursesService: GolfCoursesService, private tourOperatorsService: TourOperatorService,
                private tagsService: TagsService, private loader: NgxUiLoaderService,
                private accountService: AccountService, private translate: TranslatePipe,
                public translateService: TranslateService, private destinationsService: DestinationsService) {
        setTimeout(() => {

            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: this.translate.transform('golfHotels'), routerLink: ['/hotels']},
                {
                    label: (this.hotelId || this.route.snapshot.paramMap.get('id')) ?
                        this.translate.transform('editGolfHotel') : this.translate.transform('newGolfHotel')
                }
            ]);
        }, 200);
    }

    async ngOnInit() {
        this.langKey = this.translateService.currentLang === 'de' ? 'name_de' : 'name_en';
        if (this.hotelId || this.route.snapshot.paramMap.get('id')) {
            this.addMode = false;
        }
        this.accountService.currentUser$.subscribe(res => {
            this.currentUser = res;
        });

        this.initForm();
        this.getFieldTypes();
        this.getBasics();
        this.getRegions();

        await this.getServices();
        if (!this.addMode) {
            this.getHotelById();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initForm();
        this.getHotelById();
    }

    initForm() {
        this.hotelForm = this.fb.group({
            id: [null],
            is_parent: [0],
            company: [null, Validators.required],
            company_id: [null],
            company_name: [null],
            name: [null, Validators.required],
            letter_code: [null, [Validators.maxLength(3), Validators.minLength(3)]],
            ref_id: [null],
            tui_ref_code: [null],
            giata_code: [null],
            internal_description_mode: [1],
            active: [1],
            show_website: [0],
            direct_contract: [0],
            via_dmc: [0],
            handler_type_id: [null],
            handler_id: [null],
            delegate_name: [null],
            delegate_email: [null, Validators.email],
            delegate_mobile_number: [null],
            assigned_user: [null],
            assigned_user_id: [null],
            assigned_user_name: [null],
            region_id: new UntypedFormControl(null, Validators.required),
            country_id: new UntypedFormControl(null, Validators.required),
            city_id: new UntypedFormControl(null, Validators.required),
            area_id: new UntypedFormControl(null),
            street: new UntypedFormControl(null),
            postal_code: new UntypedFormControl(null),
            website: [null],
            location_link: [null],
            latitude: [null],
            longitude: [null],
            phone: [null],
            fax: [null],
            email: [null, [Validators.required, Validators.email]],
            payee: [null],
            is_payee_only: [null],
            payee_key_created: [null],
            bank: [null],
            bank_location: [null],
            account_number: [null],
            swift_code: [null],
            iban: [null],
            fields: this.fb.array([]),
            boards: [null],
            links_hd_images: this.fb.array([null]),
            links_logo_images: this.fb.array([null]),
            reservation_email: [null],
            booking_accounting: [null],
            booking_accounting_id: [null],
            booking_accounting_name: [null],
            has_golf_course: [0],
            golfcourses: null,
            golf_desk: [0],
            golf_shuttle: [0],
            storage_room: [null],
            is_company_address: [0],
            number_of_rooms: null,
            hotel_rating: null,
            facilities: this.fb.array([]),
            services: this.fb.array([]),
            translations: this.fb.array([
                this.fb.group({
                    language_id: 1,
                    internal_description: null,
                    website_description: null
                }),
                this.fb.group({
                    language_id: 2,
                    internal_description: null,
                    website_description: null
                })
            ]),
            touroperators: [null, Validators.required],
            related_hotels: [null],
            leader_offer: 0,
            leader_offer_number: null,
            leader_offer_notes: null,
            pro_leader_offer: 0,
            pro_leader_offer_number: null,
            pro_leader_offer_notes: null,
            junior: 0,
            junior_ratio: null,
            junior_notes: null,
            travel_agent: 0,
            travel_agent_ratio: null,
            travel_agent_notes: null,
            president: 0,
            president_ratio: null,
            president_notes: null,
            pro: 0,
            pro_ratio: null,
            pro_notes: null,
            tags: null,
            notes: null,
            booking_code: {value: null, disabled: true},
            davinci_booking_code: null,
            top: 0,
            davinici_codes: this.fb.array([]),
        });
        this.addDaVinciBookingCode();
    }

    async getServices() {
        await this.hotelsService.getHotelServices().toPromise().then((res: any) => {
            this.hotelServicesList = res.services;
            this.initHotelServices();
            this.fetchServicesTypes();
        });
    }

    initHotelServices() {
        this.hotelServicesFormArray.reset();
        this.hotelServicesList.forEach(service => {
            this.hotelServicesFormArray.push(this.fb.group({
                service_id: service.id,
                name: service.translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name,
                selected_option: null,
                type: service.type,
                qty: null,
                fees: null,
                properties: null,
                notes: null,
                addons: null,
                fee_details: null,
                active: 1,
                icon: service.icon
            }));
        });
    }

    fetchServicesTypes() {
        const allTypes = [];
        this.hotelServicesList.forEach(service => {
            allTypes.push(service.type);
        });
        this.hotelServicesTypes = [...new Set(allTypes)];
    }

    getHotelById() {
        this.hotelsService.getHotelById(this.hotelId ? this.hotelId :
            this.route.snapshot.paramMap.get('id')).subscribe((res: any) => {
            this.hotel = res;
            this.fetchDestinations(this.hotel);
            this.selectedCompany = this.hotel.company;
            this.showWebsiteCurrentValue = this.hotel.show_website;
            this.companyInfoButtonClick({checked: this.hotel.is_company_address});
            this.selectedHotelServices = this.hotel.services;
            delete this.hotel['services'];
            this.selectedHotelFacilities = this.hotel.facilities;
            delete this.hotel['facilities'];
            this.hotelForm.patchValue(this.hotel);
            ////////////// Fetch HD Images /////////////////
            if (this.hotel.links_hd_images?.length > 0) {
                const links = this.hotelForm.controls.links_hd_images as UntypedFormArray;
                links.clear();
                this.hotel.links_hd_images.forEach((link: any) => {
                    links.push(this.fb.control(link.link));
                });
            }
            ////////////// Fetch HD Images /////////////////
            ////////////// Fetch Logos /////////////////
            if (this.hotel.links_logo_images?.length > 0) {
                const logos = this.hotelForm.controls.links_logo_images as UntypedFormArray;
                logos.clear();
                this.hotel.links_logo_images.forEach((logo: any) => {
                    logos.push(this.fb.control(logo.link));
                });
            }
            ////////////// Fetch Logos /////////////////

            ////////////// Fetch Users /////////////////
            this.hotelForm.controls.assigned_user.setValue({
                id: this.hotelForm.value.assigned_user_id,
                full_name: this.hotelForm.value.assigned_user_name
            });
            this.hotelForm.controls.booking_accounting.setValue({
                id: this.hotelForm.value.booking_accounting_id,
                name: this.hotelForm.value.booking_accounting_name
            });
            this.hotelForm.controls.company.setValue({
                id: this.hotelForm.value.company_id,
                name: this.hotelForm.value.company_name
            });
            ////////////// Fetch Users /////////////////
            ////////////// Fetch Facilities /////////////////
            this.selectedHotelFacilities.forEach((item: any) => {
                this.facilities.controls.forEach(facility => {
                    if (item.id == facility.get('id').value) {
                        facility.get('available').setValue(1);
                        facility.get('number').setValue(item.number);
                    }
                });
            });
            ////////////// Fetch Facilities /////////////////
            ////////////// Fetch Hotel Services /////////////////
            this.selectedHotelServices.forEach((item: any) => {
                this.hotelServicesFormArray.controls?.forEach(service => {
                    if (item.service_id == service.get('service_id').value) {
                        service.get('selected_option').setValue(+item.selected_option);
                    }
                });
            });
            ////////////// Fetch Hotel Services /////////////////
            ////////////// Fetch Boards /////////////////
            const boardsObj = [];
            this.hotel.boards?.forEach((board: any) => boardsObj.push(board.id));
            this.hotelForm.controls.boards.setValue(boardsObj);
            ////////////// Fetch Boards /////////////////

            this.fillFieldsData(this.hotel.fields);

            // FILL BOOKING CODES
            if (this.hotel.davinici_codes.length > 0) {
                this.fillDaVinciBookingCodes();
            }
        });
    }

    fillFieldsData(fields: any[]) {
        this.fieldTypes?.forEach((fl, index) => {
            const field = fields.filter(x => +x.type_id === fl.id)[0];
            if (field) {
                this.fields.push(
                    this.fb.group({
                        id: field.id,
                        type_id: field.type_id,
                        is_html: field.is_html,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach((translation, n) => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: field.translations[n]?.description
                        })
                    );
                });
            } else {
                this.fields.push(
                    this.fb.group({
                        id: null,
                        type_id: fl.id,
                        is_html: 0,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach(translation => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: null
                        })
                    );
                });
            }
        });
    }

    getFieldName(fieldId) {
        const name = this.fieldTypes
            .filter(x => x.id === +fieldId)[0].translations[this.translateService.currentLang === 'de' ? 1 : 0].name;
        return name;
    }

    async getFieldTypes() {
        this.hotelsService.getFieldTypes().toPromise().then(res => {
            this.fieldTypes = res;
        });
    }

    initFacilities() {
        this.facilities.clear();
        this.facilitiesList.forEach(facility => {
            this.facilities.push(this.fb.group({
                id: facility.id,
                name: facility.name,
                available: null,
                number: null
            }));
        });
    }

    async getBasics() {
        await this.hotelsService.getBasics().toPromise().then((res: any) => {
            this.boards = res.boards;
            this.facilitiesList = res.facilities;
            this.roomFieldTypes = res.room_field_types;
            this.roomTypes = res.room_types;
            this.roomFacilities = res.room_facilities;
            this.initFacilities();
        });
    }

    get form() {
        return this.hotelForm.controls;
    }

    get facilities() {
        return this.form.facilities as UntypedFormArray;
    }

    get translations() {
        return this.form.translations as UntypedFormArray;
    }

    get hotelServicesFormArray() {
        return this.form.services as UntypedFormArray;
    }

    get fields() {
        return this.form.fields as UntypedFormArray;
    }

    get hdLinks() {
        return this.form.links_hd_images as UntypedFormArray;
    }

    addHdLinks() {
        const hdLinks = this.hotelForm.controls.links_hd_images as UntypedFormArray;
        hdLinks.push(this.fb.control(null));
        this.hotel?.links_hd_images?.push(hdLinks[hdLinks.length - 1]);
    }

    changePicLink(event, index) {
        this.hotel.links_hd_images[index] = {link: event};
    }

    removeHdLinks(i) {
        const hdLinks = this.hotelForm.controls.links_hd_images as UntypedFormArray;
        hdLinks.removeAt(i);
        this.hotel?.links_hd_images.splice(i, 1);
    }

    get logoLinks() {
        return this.form.links_logo_images as UntypedFormArray;
    }

    addLogoLinks() {
        const logoLinks = this.hotelForm.controls.links_logo_images as UntypedFormArray;
        logoLinks.push(this.fb.control(null));
        this.hotel?.links_logo_images.push(logoLinks[logoLinks.length]);
    }

    changeLogoLink(event, index) {
        this.hotel.links_logo_images[index] = {link: event};
    }

    removeLogoLink(i) {
        const logoLinks = this.hotelForm.controls.links_logo_images as UntypedFormArray;
        logoLinks.removeAt(i);
        this.hotel?.links_logo_images.splice(i, 1);
    }

    getLangLat(event) {
        const regex = new RegExp('@(.*),(.*),');
        const latLongMatch = event.match(regex);
        this.hotelForm.controls.latitude.setValue(latLongMatch[1]);
        this.hotelForm.controls.longitude.setValue(latLongMatch[2]);
    }

    searchUsers(event) {
        this.usersServices.searchUsers(event.query).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    searchTourOperators(event) {
        this.tourOperatorsService.tourSearch(event.query).subscribe((res: any) => {
            this.tourOperators = res.touroperators;
        });
    }

    searchHotels(event) {
        this.hotelsService.search(event.query).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    submitForm() {
        if (!this.hotelForm.valid) {
            this.hotelForm.markAllAsTouched();
            this.findInvalidControls();
            return false;
        }
        this.invalidControls = [];
        const formValue = {...this.hotelForm.getRawValue(), ...this.hotelForm.getRawValue().destinations};
        ////////////// Fetch Facilities ///////////////
        const facilitiesObj = [];
        formValue.facilities.forEach(facility => {
            if (facility.available == 1) {
                facilitiesObj.push(facility);
            }
        });
        formValue.facilities = facilitiesObj;
        ////////////// Fetch Facilities ///////////////
        ////////////// Fetch Hotel Services ///////////////
        const servicesObj = [];
        formValue.services.forEach(service => {
            if (service.selected_option == 1) {
                service.properties = [];
                service.addons = [];
                service.fee_details = [];
                servicesObj.push(service);
            }
        });
        formValue.services = servicesObj;
        ////////////// Fetch Hotel Services ///////////////
        ////////////// Fetch Tour Operators ///////////////
        const toursList = [];
        if (formValue.touroperators) {
            formValue.touroperators.forEach(operator => {
                toursList.push(operator.id);
            });
        }
        formValue.touroperators = toursList;
        ////////////// Fetch Tour Operators ///////////////
        ////////////// Fetch Related Hotels ///////////////
        const hotelsList = [];
        if (formValue.related_hotels) {
            formValue.related_hotels.forEach(hotel => {
                hotelsList.push(hotel.id);
            });
        }
        formValue.related_hotels = hotelsList;
        ////////////// Fetch Related Hotels ///////////////

        formValue.delegate_mobile_number = formValue.delegate_mobile_number
            ?.internationalNumber?.toString().replace(/\s/g, '');
        formValue.fax = formValue.fax?.internationalNumber ?
            formValue.fax?.internationalNumber?.toString().replace(/\s/g, '') : null;
        formValue.phone = formValue.phone?.internationalNumber ?
            formValue.phone?.internationalNumber?.toString().replace(/\s/g, '') : null;

        formValue.assigned_user_id = formValue.assigned_user?.id;
        formValue.booking_accounting_id = formValue.booking_accounting?.id;
        formValue.company_id = formValue.company?.id;
        ///////////////////  GOLF COURSES /////////////////////
        if (formValue.golfcourses != null) {
            formValue.golfcourses = formValue.golfcourses.map(course => course.id);
        } else {
            formValue.golfcourses = [];
        }
        ///////////////////  GOLF COURSES /////////////////////
        ///////////////////  TAGS /////////////////////
        if (formValue.tags != null) {
            formValue.tags = formValue.tags.map(tag => tag.id);
        } else {
            formValue.tags = [];
        }
        ///////////////////  TAGS /////////////////////
        formValue.links_hd_images = formValue.links_hd_images.filter(x => x != null);
        formValue.links_logo_images = formValue.links_logo_images.filter(x => x != null);

        if (formValue.boards == null) {
            formValue.boards = [];
        }
        formValue.images = this.imagesUploader?.form?.value;

        if (this.addMode) {
            if (formValue.is_parent) {
                formValue.is_company_address = 1;
                const company = {
                    ...formValue,
                    name: formValue.name,
                    company_type_id: 4,
                    delegate_email: formValue.delegate_email,
                    delegate_mobile_number: formValue.delegate_mobile_number,
                    delegate_name: formValue.delegate_name,
                    postal_code: formValue.postal_code,
                    user_id: formValue.assigned_user_id
                };
                this.companiesServices.addCompany(company).subscribe((res: any) => {
                    formValue.company_id = res.company.id;
                    this.addHotel(formValue);
                });
            } else {
                this.addHotel(formValue);
            }
        } else {
            this.updateHotel(formValue);
            this.updateHotelServices(this.hotel.id, formValue.services);
        }

    }

    addHotel(formValue) {
        this.hotelsService.addHotel(formValue).subscribe((res: any) => {
            this.updateHotelServices(res.hotel.id, formValue.services);
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('addHotelMessage')
            });
            this.resetPage();
            this.hotelForm.get('company').enable();
            this.initHotelServices();
            this.disabilitySwitch(false);
        });
    }

    updateHotel(formValue) {
        this.hotelsService.updateHotel(formValue).subscribe((res: any) => {
            this.hotel = res.hotel;
            if (this.showWebsiteCurrentValue === 1 && this.hotel.show_website === 0) {
                this.publishToWebsite();
            } else {
                this.showWebsiteCurrentValue = this.hotel.show_website;
            }
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelMessage')
            });
            this.areasLoaded = this.countriesLoaded = this.citiesLoaded = false;
        });
    }

    uploadImages() {
        const files = this.imagesUploader.getFiles();
        const formData: FormData = new FormData();
        if (files.length === 0) {
            return false;
        }
        this.loader.start('uploadImages');
        files.forEach(file => {
            formData.append('images[]', file);
            formData.append('size[]', file.size.toString());
            formData.append('original_file_name[]', file.name);
        });
        this.hotelsService.uploadImages(this.hotel.id, formData).subscribe((res: any) => {
            this.hotel.images = res.hotel.images;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelMessage')
            });
            this.imagesUploader.clearUploader();
            this.loader.stop('uploadImages');
            this.checkMainImage();
        });
    }

    checkMainImage() {
        let isMain = false;
        this.hotel.images.forEach(image => {
            if (image.is_main) {
                isMain = true;
            }
        });
        if (!isMain && this.hotel.images.length > 0) {
            this.changeMainImage(this.hotel.images[0]?.id);
        }
    }

    updateHotelServices(hotelId, obj) {
        this.hotelsService.updateHotelServices(hotelId, {services: obj}).subscribe(res => {
            console.log(res);
        });
    }

    deleteImage(idsList) {
        this.hotelsService.deleteImage(this.hotel.id, idsList).subscribe((res: any) => {
            this.hotel.images = res.hotel.images;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelMessage')
            });
            this.checkMainImage();
        });
    }

    isParentChanged(event) {
        this.showCompanyInfoButton = false;
        if (event.checked) {
            this.hotelForm.get('company').setValue(null);
            this.hotelForm.get('company').disable();
            this.hotelForm.get('company').clearValidators();
            if (this.hotelForm.get('is_company_address').value == 1) {
                this.hotelForm.get('is_company_address').setValue(0);
                this.clearCompanyInfo();
                this.disabilitySwitch(false);
            }
        } else {
            this.showCompanyInfoButton = true;
            this.hotelForm.get('company').enable();
            this.hotelForm.get('company').addValidators(Validators.required);
            this.clearCompanyInfo();
        }
    }

    searchCompanies(event) {
        this.companiesServices.searchCompanies(event.query).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    searchCourses(event) {
        this.coursesService.searchGolfCourses(event.query).subscribe((res: any) => {
            this.golfCourses = res.golfcourses;
        });
    }

    checkRequired(control) {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
    }

    async companyInfoButtonClick(event) {
        if (event.checked === 1) {
            this.disabilitySwitch(true);
            if (this.hotelForm.get('company').value !== null) {
                this.fillCompanyInfo(this.selectedCompany);
            }
        } else {
            this.countriesLoaded = false;
            this.citiesLoaded = false;
            this.areasLoaded = false;
            this.disabilitySwitch(false);
        }
    }

    async companySelected(company) {
        this.selectedCompany = company;
        if (this.hotelForm.get('is_company_address').value == 1) {
            this.fillCompanyInfo(company);
        }
    }

    fillCompanyInfo(company: Company) {
        this.hotelForm.get('region_id').setValue(company.region_id);
        this.hotelForm.get('country_id').setValue(company.country_id);
        this.hotelForm.get('city_id').setValue(company.city_id);
        this.hotelForm.get('area_id').setValue(company.area?.id);
        this.hotelForm.get('street').setValue(company.street);
        this.hotelForm.get('postal_code').setValue(company.postal_code);
        this.hotelForm.get('website').setValue(company.website);
        this.hotelForm.get('phone').setValue(company.phone);
        this.hotelForm.get('fax').setValue(company.fax);
        this.hotelForm.get('email').setValue(company.email);
        this.fetchDestinations(company);
    }

    clearCompanyInfo() {
        this.hotelForm.get('region_id').setValue(null);
        this.hotelForm.get('country_id').setValue(null);
        this.hotelForm.get('city_id').setValue(null);
        this.hotelForm.get('area_id').setValue(null);
        this.hotelForm.get('street').setValue(null);
        this.hotelForm.get('postal_code').setValue(null);
        this.hotelForm.get('website').setValue(null);
        this.hotelForm.get('phone').setValue(null);
        this.hotelForm.get('fax').setValue(null);
        this.hotelForm.get('email').setValue(null);
    }

    disabilitySwitch(disable: boolean) {
        disable ? this.hotelForm.get('region_id').disable() : this.hotelForm.get('region_id').enable();
        disable ? this.hotelForm.get('country_id').disable() : this.hotelForm.get('country_id').enable();
        disable ? this.hotelForm.get('city_id').disable() : this.hotelForm.get('city_id').enable();
        disable ? this.hotelForm.get('area_id').disable() : this.hotelForm.get('area_id').enable();
        disable ? this.hotelForm.get('street').disable() : this.hotelForm.get('street').enable();
        disable ? this.hotelForm.get('postal_code').disable() : this.hotelForm.get('postal_code').enable();
        disable ? this.hotelForm.get('phone').disable() : this.hotelForm.get('phone').enable();
        disable ? this.hotelForm.get('fax').disable() : this.hotelForm.get('fax').enable();
        disable ? this.hotelForm.get('email').disable() : this.hotelForm.get('email').enable();
        disable ? this.hotelForm.get('website').disable() : this.hotelForm.get('website').enable();
    }

    isPublishAvailable() {
        const tours = this.hotel.touroperators.filter(tour => tour.id === 2000);
        if (tours.length > 0 && this.hotel?.is_publish_required && this.hotel.show_website === 1) {
            return true;
        }
        return false;
    }

    publishToWebsite() {
        const obj = {
            auth: 'GAP_API',
            token: environment.token,
            type: 'hotel',
            object_id: this.hotel.id
        };
        this.hotelsService.publishHotelToWebsite(obj).subscribe((res: any) => {
            if (res.update_status === 'Updated Successfully') {
                this.hotelsService.publishHotel(this.hotel.id).subscribe((resp: any) => {
                    this.hotel.is_publish_required = 0;
                    this.messageService.add({
                        severity: 'success', summary: this.translate.transform('golfHotels'),
                        detail: this.translate.transform('publishMessage')
                    });
                });
            }
        });
    }

    public findInvalidControls() {
        this.invalidControls = [];
        const controls = this.hotelForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.invalidControls.push(name.replace(/_/g, ' '));
            }
        }
    }

    changeMainImage(id) {
        this.hotelsService.changeMainImage(this.hotel.id, {image_id: id}).subscribe((res: any) => {
            this.hotel.images = res.hotel.images;
            this.messageService.add({
                severity: 'success', summary: this.translate.transform('golfHotels'),
                detail: this.translate.transform('updateHotelMessage')
            });
        });
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    filterTags(event) {
        this.tagsService.getAllTags(event.query).subscribe(res => {
            this.filteredTags = res;
            const matchedTags = this.filteredTags.filter(x => x.name == event.query);
            if (matchedTags.length === 0) {
                this.filteredTags.unshift({id: 0, name: event.query + ' (Add new)'});
            }
        });
    }

    tagSelected(event) {
        if (event.id === 0) {
            const tagValues = [...this.hotelForm.get('tags').value];
            const newTag = tagValues[tagValues.length - 1];
            const selections = this.hotelForm.get('tags').value.slice(0, tagValues.length - 1);
            this.tagsService.addTag({name: newTag.name.split('(')[0].trim()}).subscribe(res => {
                selections.push(res);
                this.hotelForm.get('tags').setValue(selections);
            });
        }
    }

    tourOperatorSelected(event) {
        const selectedItems = [...this.hotelForm.get('touroperators').value];
        const isExist = selectedItems.filter(x => x.id === event.id);
        if (isExist.length > 1) {
            this.hotelForm.get('touroperators').setValue(selectedItems.slice(0, selectedItems.length - 1));
        }
    }

    get _DaVinciBookingCodes() {
        return this.hotelForm.get('davinici_codes') as UntypedFormArray;
    }

    removeDaVinciBookingCode(i) {
        this._DaVinciBookingCodes.removeAt(i);
    }

    addDaVinciBookingCode() {
        this._DaVinciBookingCodes.push(this.fb.control(null));
    }

    fillDaVinciBookingCodes() {
        this._DaVinciBookingCodes.clear();
        this.hotel.davinici_codes.forEach((code: any) => {
            this._DaVinciBookingCodes.push(this.fb.control(code.davinici_code));
        });
    }

    //////////////// Destination Section ///////////////
    getRegions(id?: number) {
        if (id) {
            this.destinationsService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destinationsService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destinationsService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destinationsService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destinationsService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destinationsService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    getAreas(id?: number, cityId?: number) {
        if (id) {
            this.destinationsService.getArea(id).subscribe((res: any) => {
                this.areas = [res];
            });
        } else if (cityId && !this.areasLoaded) {
            this.destinationsService.getAreasList(cityId).subscribe((res: any) => {
                this.areas = res;
                this.areasLoaded = true;
            });
        }
    }

    regionChanged() {
        this.hotelForm.get('country_id').setValue(null);
        this.hotelForm.get('city_id').setValue(null);
        this.hotelForm.get('area_id').setValue(null);

        this.countries = [];
        this.cities = [];
        this.areas = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;
        this.areasLoaded = false;

        this.getCountries(null, this.hotelForm.get('region_id').value);
    }

    countryChanged() {
        this.hotelForm.get('city_id').setValue(null);
        this.hotelForm.get('area_id').setValue(null);

        this.cities = [];
        this.areas = [];

        this.citiesLoaded = false;
        this.areasLoaded = false;

        this.getCities(null, this.hotelForm.get('country_id').value);
    }

    cityChanged() {
        this.hotelForm.get('area_id').setValue(null);
        this.areas = [];
        this.areasLoaded = false;
        this.getAreas(null, this.hotelForm.get('city_id').value);

    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getAreaName(areaId) {
        const areaName = this.areas.filter(area => area.id === areaId)[0][this.langKey];
        return areaName;
    }

    fetchDestinations(item) {
        this.getCountries(item.country_id);
        this.getCities(item.city_id);
        if (item.area?.id) {
            this.getAreas(item.area?.id);
        }
    }

    //////////////// Destination Section ///////////////
    viewCompany() {
        window.open(window.location.origin + '/admin/companies/edit/' + this.hotelForm.get('company').value.id,
            '_blank');
    }
}
