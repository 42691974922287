<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

    <div class="login-content">
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                    <img src="assets/layout/images/pages/golf-logo.png" alt="Golf Globe"/>
                </div>
                <img class="exclamation" 
                     src="{{showResetForm ? 'assets/layout/images/pages/lock-icon.png' :
                        'assets/layout/images/pages/exclamation-icon.png'}}" alt="Golf Globe"/>
                <h1>{{showResetForm ? 'Reset your password' : 'Forgot your password?'}}</h1>
                <p *ngIf="!showResetForm">Please enter your email and we will send you a link to reset your password</p>
                <form *ngIf="!showResetForm" class="forms" (ngSubmit)="requestVerificationCode()"
                      #emailForm="ngForm">
                    <div class="login-input-wrapper">
                        <label for="username">Email</label>
                        <input id="username" type="email" email pInputText placeholder="Enter your email"
                               name="email" [(ngModel)]="email" required/>
                        <i class="pi pi-email"></i>
                    </div>

                    <div class="buttons">
                        <button pButton type="submit" [disabled]="!emailForm.valid" label="Send verification code"></button>
                        <a routerLink="/login" class="secondary-button">Login</a>
                    </div>
                </form>

                <form *ngIf="showResetForm" class="forms" (ngSubmit)="resetPassword(newPassForm)"
                      #newPassForm="ngForm">
                    <div class="login-input-wrapper">
                        <label for="username">New Password</label>
                        <input id="newPassword" [type]="showPassword ? 'text' : 'password'" pPassword
                               placeholder="New password" minlength="8" #password="ngModel"
                               name="password" ngModel required/>
                        <i [class]="showPassword ? 'pi pi-eye-slash eye1' : 'pi pi-eye eye1'"
                           (click)="showPassword = !showPassword"></i>
                        <small>8 characters at least</small>
                    </div>

                    <div class="login-input-wrapper">
                        <label for="username">Confirm New Password</label>
                        <input id="confirmNewPassword" [type]="showPassword ? 'text' : 'password'" pInputText
                               placeholder="Confirm New password"
                               name="password_confirmation" ngModel required #cPassword="ngModel" />
                        <small class="danger-hint" *ngIf="cPassword.touched && cPassword.value != password.value"
                        >Confirm password does not match password!</small>
                    </div>

                    <div class="buttons">
                        <button pButton type="submit" label="Reset password"
                        [disabled]="!newPassForm.valid || password.value != cPassword.value"
                        ></button>
                        <a *ngIf="!route.snapshot.queryParamMap.has('token')"
                           (click)="requestVerificationCode()" class="secondary-button">Resend email</a>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
