<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

    <div class="login-content">
        <!--<div class="night-switch">
            <p-button styleClass="dark-switch"  icon="pi pi-circle-on" (click)="dark = true"></p-button>
            <p-button styleClass="light-switch"  icon="pi pi-circle-off" (click)="dark = false"></p-button>
        </div>-->
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                    <img src="assets/layout/images/pages/golf-logo.png" alt="Golf Globe" />
                </div>
                <p>Macht die Welt zu Deinem Fairway.</p>
                <h1>Sign in to GOLF GLOBE</h1>
                <p>Welcome, please use the form to sign-in GOLF GLOBE network</p>
                <form class="forms" (ngSubmit)="submitLogin(loginForm)" #loginForm="ngForm">
                    <div class="login-input-wrapper">
                        <label for="username">Username</label>
                        <input id="username" pInputText placeholder="Username"
                        name="username" ngModel required #usernameInput/>
                        <i class="pi pi-user"></i>
                    </div>

                    <div class="login-input-wrapper">
                        <label for="password">Password</label>
                        <input type="password" id="password" placeholder="Password" pPassword [feedback]="false"
                        name="password" ngModel required/>
                        <i class="pi pi-lock"></i>
                    </div>

                    <div class="p-col-12">
                        <p-checkbox ngModel name="rememberMe" label="Remember me" [binary]="true"></p-checkbox>
                    </div>


                    <div class="buttons">
                        <button pButton type="submit" [disabled]="!loginForm.valid" label="Login"></button>
                        <a routerLink="/forgot-password" class="secondary-button">Forget Password?</a>
                    </div>
                </form>
            </div>
        </div>

        <!--<div class="desert"></div>-->
    </div>
</div>
