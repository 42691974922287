import {Component, Input, OnInit} from '@angular/core';
import {Company} from '../../_models/company';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Lookup} from '../../_models/lookup';
import {CompaniesService} from '../../_services/companies-service';
import {MessageService} from 'primeng/api';
import {User} from '../../_models/user';
import {UsersServices} from '../../_services/users-services';
import {ActivatedRoute, Router} from '@angular/router';
import {Hotel} from '../../_models/hotel';
import {HotelsService} from '../../hotels/_services/hotels.service';
import {Area, City, Country, Region} from '../../_models/destinations';
import {DestinationsService} from '../../destination/_services/destinations.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.css']
})
export class CompanyFormComponent implements OnInit {

    @Input() companyData: Company;
    @Input() isAddMode: boolean;
    @Input() isProfile: boolean;
    @Input() hasEditPermission = true;
    companyDataForm: UntypedFormGroup;
    companyTypes: Lookup[];
    hotels: Hotel[];
    users: User[];
    createChildButtonName: string;
    companyChildren: any[] = [];

    regions: Region[] = [];
    countries: Country[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    langKey: string;
    countriesLoaded = false;
    citiesLoaded = false;
    areasLoaded = false;
    langOptions = [{code: 'en', name: 'EN'}, {code: 'de', name: 'DE'}];


    constructor(private companiesService: CompaniesService, private messageService: MessageService,
                private usersServices: UsersServices, private hotelServices: HotelsService,
                private destinationsService: DestinationsService, private translationService: TranslateService,
                private router: Router, private route: ActivatedRoute) {
    }

    async ngOnInit() {
        this.langKey = this.translationService.currentLang === 'de' ? 'name_de' : 'name_en';
        this.initCompanyDataForm();
        this.getCompanyTypes();
        this.getRegions();
        this.getCountries(this.companyData.country_id);
        this.getCities(this.companyData.city_id);
        if (this.companyData.area) {
            this.getAreas(this.companyData.area.id);
        }

        if (this.companyData) {
            this.companyData.area_id = this.companyData.area?.id;
            if (this.companyData.company_type_id === 1) {
                this.companyDataForm.get('company_type_id').disable();
            }
            this.companyDataForm.patchValue(this.companyData);
            this.companyDataForm.controls.delegate_user.setValue({
                id: this.companyDataForm.value.delegate_user_id,
                full_name: this.companyDataForm.value.delegate_user_name
            });
            this.companyDataForm.controls.assigned_user.setValue({
                id: this.companyDataForm.value.assigned_user_id,
                full_name: this.companyDataForm.value.assigned_user_name
            });
            this.companyDataForm.controls.hotel_id.setValue({
                id: this.companyData.hotel_id,
                name: this.companyData.hotel_name
            });
            if (this.companyData.company_type_id == 3) {
                this.companiesService.getAllCompanyChildren(this.companyData.id).subscribe((res: any) => {
                    this.companyChildren = res;
                });
            }
        }
    }

    initCompanyDataForm() {
        this.companyDataForm = new UntypedFormGroup({
            name: new UntypedFormControl(null, Validators.required),
            company_type_id: new UntypedFormControl(null, Validators.required),
            hotel_id: new UntypedFormControl(null),
            phone: new UntypedFormControl(null),
            fax: new UntypedFormControl(null),
            website: new UntypedFormControl(null),
            email: new UntypedFormControl(null, Validators.required),
            delegate_name: new UntypedFormControl(null),
            delegate_email: new UntypedFormControl(null, [Validators.email]),
            delegate_mobile_number: new UntypedFormControl(this.companyData?.delegate_mobile_number),
            delegate_user: new UntypedFormControl(null),
            delegate_user_id: new UntypedFormControl(null),
            delegate_user_name: new UntypedFormControl(null),
            assigned_user: new UntypedFormControl(null),
            assigned_user_id: new UntypedFormControl(null),
            assigned_user_name: new UntypedFormControl(null),
            rank: new UntypedFormControl(null),
            contract: new UntypedFormControl(null),
            create_child: new UntypedFormControl(1),
            region_id: new UntypedFormControl(null, Validators.required),
            country_id: new UntypedFormControl(null, Validators.required),
            city_id: new UntypedFormControl(null, Validators.required),
            area_id: new UntypedFormControl(null),
            street: new UntypedFormControl(null),
            postal_code: new UntypedFormControl(null),
            instagram: new UntypedFormControl(null),
            twitter: new UntypedFormControl(null),
            facebook: new UntypedFormControl(null),
            linkedin: new UntypedFormControl(null),
            location_link: new UntypedFormControl(null),
            latitude: new UntypedFormControl(null),
            longitude: new UntypedFormControl(null),
            booking_code: new UntypedFormControl({value: null, disabled: true}),
            davinci_booking_code: new UntypedFormControl(null),
            lang: new UntypedFormControl('en')
        });
    }

    getCompanyTypes() {
        this.companiesService.getCompanyTypes().subscribe((res: any) => {
            this.companyTypes = res;
            if (!this.companyData) {
                this.companyTypes = this.companyTypes.filter(x => x.id !== 1);
            }
        });
    }

    submitForm() {
        if (!this.companyDataForm.valid) {
            this.companyDataForm.markAllAsTouched();
            return false;
        }
        const formValue = {...this.companyDataForm.getRawValue(), ...this.companyDataForm.value.destinations};
        formValue.delegate_mobile_number = formValue.delegate_mobile_number
            ?.internationalNumber?.toString().replace(/\s/g, '');

        formValue.fax = formValue.fax?.internationalNumber ?
            formValue.fax?.internationalNumber?.toString().replace(/\s/g, '') : null;
        formValue.phone = formValue.phone?.internationalNumber ?
            formValue.phone?.internationalNumber?.toString().replace(/\s/g, '') : null;

        formValue.hotel_id = formValue.hotel_id?.id;
        formValue.delegate_user_id = formValue.delegate_user?.id;
        formValue.assigned_user_id = formValue.assigned_user?.id;
        formValue.booking_accounting_id = formValue.booking_accounting?.id;
        formValue.user_id = 1;
        formValue.id = this.companyData?.id;

        if (this.isAddMode) {
            this.companiesService.addCompany(formValue).subscribe((res: any) => {
                this.messageService.add({
                    severity: 'success', summary: 'Add new company',
                    detail: 'New company has been added successfully.'
                });
                this.resetPage();
            });
        } else if (!this.isAddMode && this.isProfile) {
            this.companiesService.updateCompanyProfile(formValue).subscribe((res: User) => {
                this.messageService.add({
                    severity: 'success', summary: 'Update company profile',
                    detail: 'Company profile has been updated successfully.'
                });
            });
        } else if (!this.isAddMode && !this.isProfile) {
            this.companiesService.updateCompany(formValue).subscribe(res => {
                this.messageService.add({
                    severity: 'success', summary: 'Update company profile',
                    detail: 'Company has been updated successfully.'
                });
            });
        }
    }

    updateCompanyLogo(event) {
        event.files.forEach(file => {
            const formData: FormData = new FormData();
            formData.append('image', file);
            if (this.isProfile) {
                this.companiesService.changeCompanyProfileLogo(formData).subscribe((response: any) => {
                    this.companyData.logo = response.company.logo;
                    this.messageService.add({
                        severity: 'success', summary: 'Change company logo',
                        detail: 'Company logo has been updated successfully'
                    });
                });
            } else {
                this.companiesService.changeCompanyLogo(formData, this.companyData.id).subscribe((response: any) => {
                    this.companyData.logo = response.company.logo;
                    this.messageService.add({
                        severity: 'success', summary: 'Change company logo',
                        detail: 'Company logo has been updated successfully'
                    });
                });
            }
        });
    }

    deleteLogo() {
        this.companiesService.deleteCompanyLogo().subscribe(res => {
            this.companyData.logo = null;
            this.messageService.add({
                severity: 'success', summary: 'Delete company logo',
                detail: 'Company logo has been Deleted successfully'
            });
        });
    }

    getLangLat(event) {
        const regex = new RegExp('@(.*),(.*),');
        const latLongMatch = event.match(regex);
        this.companyDataForm.controls.latitude.setValue(latLongMatch[1]);
        this.companyDataForm.controls.longitude.setValue(latLongMatch[2]);
    }

    searchUsers(event) {
        this.usersServices.searchUsers(event.query).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    searchHotels(event) {
        this.hotelServices.search(event.query).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    companyTypeChanged(event) {
        switch (event.value) {
            case 2:
                this.createChildButtonName = 'Create TA profile';
                break;
            case 3:
                this.createChildButtonName = 'Create GC profile';
                break;
            case 4:
                this.createChildButtonName = 'Create HO profile';
                break;
            case 5:
                this.createChildButtonName = 'Create TO profile';
                break;
            case 6:
                this.createChildButtonName = 'Create DMC profile';
                break;
        }
    }

    openChild(child) {
        window.open(window.location.origin + '/golf-courses/courses/edit/' + child.id + '/general',
            '_blank');
    }

    addNewGolfCourse() {
        window.open(window.location.origin + '/golf-courses/courses/add?companyId=' + this.companyData.id,
            '_blank');
    }

    //////////////// Destination Section ///////////////
    getRegions(id?: number) {
        if (id) {
            this.destinationsService.getRegion(id).subscribe((res: any) => {
                this.regions = [res];
            });
        } else {
            this.destinationsService.getRegionsList().subscribe((res: any) => {
                this.regions = res;
            });
        }
    }

    getCountries(id?: number, regionId?: number) {
        if (id) {
            this.destinationsService.getCountry(id).subscribe((res: any) => {
                this.countries = [res];
            });
        } else if (regionId && !this.countriesLoaded) {
            this.destinationsService.getCountriesList(regionId).subscribe((res: any) => {
                this.countries = res;
                this.countriesLoaded = true;
            });
        }
    }

    getCities(id?: number, countryId?: number) {
        if (id) {
            this.destinationsService.getCity(id).subscribe((res: any) => {
                this.cities = [res];
            });
        } else if (countryId && !this.citiesLoaded) {
            this.destinationsService.getCitiesList(countryId).subscribe((res: any) => {
                this.cities = res;
                this.citiesLoaded = true;
            });
        }
    }

    getAreas(id?: number, cityId?: number) {
        if (id) {
            this.destinationsService.getArea(id).subscribe((res: any) => {
                this.areas = [res];
            });
        } else if (cityId && !this.areasLoaded) {
            this.destinationsService.getAreasList(cityId).subscribe((res: any) => {
                this.areas = res;
                this.areasLoaded = true;
            });
        }
    }

    regionChanged() {
        this.companyDataForm.get('country_id').setValue(null);
        this.companyDataForm.get('city_id').setValue(null);
        this.companyDataForm.get('area_id').setValue(null);

        this.countries = [];
        this.cities = [];
        this.areas = [];

        this.countriesLoaded = false;
        this.countriesLoaded = false;
        this.areasLoaded = false;

        this.getCountries(null, this.companyDataForm.get('region_id').value);
    }

    countryChanged() {
        this.companyDataForm.get('city_id').setValue(null);
        this.companyDataForm.get('area_id').setValue(null);

        this.cities = [];
        this.areas = [];

        this.citiesLoaded = false;
        this.areasLoaded = false;

        this.getCities(null, this.companyDataForm.get('country_id').value);
    }

    cityChanged() {
        this.companyDataForm.get('area_id').setValue(null);
        this.areas = [];
        this.areasLoaded = false;
        this.getAreas(null, this.companyDataForm.get('city_id').value);

    }


    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0][this.langKey];
        return regionName;
    }

    getCountryName(countryId) {
        const co = this.countries.filter(country => country.id === countryId)[0];
        return (co[this.langKey] || co.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getCityName(cityId) {
        const ct = this.cities.filter(city => city.id === cityId)[0];
        return (ct[this.langKey] || ct.translations[this.langKey === 'name_de' ? 1 : 0].name);
    }

    getAreaName(areaId) {
        const areaName = this.areas.filter(area => area.id === areaId)[0][this.langKey];
        return areaName;
    }

    //////////////// Destination Section ///////////////

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }
}
