import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HotelsComponent} from './hotels/hotels.component';
import {AddHotelComponent} from './add-hotel/add-hotel.component';
import {AuthGuard} from '../_guards/auth.guard';

const routes: Routes = [
  {path: '', redirectTo: './', pathMatch: 'full'},
  {
    path: '', component: HotelsComponent, canActivate: [AuthGuard],
    data: {requirePermission: true, code: '1636369406243', type: 'action'}
  },
  {
    path: 'add', component: AddHotelComponent, canActivate: [AuthGuard],
    data: {requirePermission: true, code: '1636369426819', type: 'action'}
  },
  {
    path: 'edit/:id', component: AddHotelComponent, canActivate: [AuthGuard],
    data: {requirePermission: true, code: '1636369458287', type: 'action'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelsRoutingModule { }
