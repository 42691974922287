<div class="p-field">
    <label *ngIf="label">{{label}} <span class="p-error">{{checkRequired(ngControl.control) ? '*' : ''}}</span></label>
    <i *ngIf="showTrash" class="pi pi-trash" (click)="remove()"></i>
    <input class="full-width" [type]="type" pInputText [formControl]="ngControl.control"
           (blur)="onBlur($event)" [placeholder]="placeholder" [autocomplete]="false"
    (input)="type == 'number' ? sharedService.preventMinus($event) : null">

    <small *ngIf="ngControl.control.touched && ngControl.control.errors?.required" class="p-error">required</small>


    <small *ngIf="ngControl.control.errors?.minlength" class="p-error">
        {{ngControl.control.errors.minlength['requiredLength']}} letters at least
    </small>

    <small *ngIf="ngControl.control.errors?.maxlength" class="p-error">
        {{ngControl.control.errors.maxlength['requiredLength']}} letters at most
    </small>
    <small *ngIf="ngControl.control.errors?.email" class="p-error">
        This email is not valid
    </small>

    <small *ngIf="ngControl.control.errors?.isMatching" class="p-error">Passwords do not match</small>
    <small *ngIf="ngControl.control.errors?.isPhoto" class="p-error">Invalid image</small>
</div>
