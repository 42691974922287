import {Component, OnInit, ViewChild} from '@angular/core';
import {GolfHoliday} from '../../../_models/golf-holiday';
import GgTableCustomFN from '../../../_classes/ggTableCustomFN';
import {Paginator} from 'primeng/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {GolfHolidayService} from '../../_services/golf-holiday.service';
import {AccountService} from '../../../_services/account.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-golf-holiday-table',
    templateUrl: './golf-holiday-table.component.html',
    styleUrls: ['./golf-holiday-table.component.css']
})
export class GolfHolidayTableComponent implements OnInit {
    products: GolfHoliday[];
    selectedProducts: GolfHoliday[];
    product: GolfHoliday[];
    searchValue: string;
    exporter = new GgTableCustomFN();
    pagination: any;
    @ViewChild('p', {static: false}) paginator: Paginator;

    constructor(private router: Router, private route: ActivatedRoute,
                private confirmationService: ConfirmationService, private messageService: MessageService,
                private golfHolidayService: GolfHolidayService,
                private accountService: AccountService, private translate: TranslatePipe) {
        }

    ngOnInit(): void {
        this.getPaginatedProducts(1, 10, this.searchValue);
    }

    getPaginatedProducts(pageNumber, pagination, searchKeyword) {
        this.golfHolidayService.getPaginated(pageNumber, pagination, searchKeyword).subscribe((res: any) => {
            this.products = res.products.data;
            this.pagination = res.products.meta;
            console.log(this.selectedProducts);
        });
    }

    edit(product) {
        this.router.navigate(['edit/' + product.id], {relativeTo: this.route});
    }

    delete(product) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.golfHolidayService.delete(product.id).subscribe(res => {
                    this.getPaginatedProducts(this.pagination.current_page, this.pagination.per_page,
                        this.searchValue);
                    this.messageService.add({
                        severity: 'success', summary: 'Delete Golf Holiday Product',
                        detail: 'selected product has been deleted successfully'
                    });
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    exportExcel() {
        if (!this.selectedProducts || this.selectedProducts.length < 1) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.exporter.exportExcel(this.selectedProducts);
    }

    searchProducts() {
        this.getPaginatedProducts(1, this.pagination.per_page, this.searchValue);
    }

    pageChanged(event) {
        this.getPaginatedProducts(event.page + 1, +event.rows, this.searchValue);
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }
}
