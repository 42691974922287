<p-toast></p-toast>
<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div>
                <h5>{{isEditMode ? 'Edit Contract' : 'Add New Contract'}}</h5>
            </div>
            <div>
                <app-switch-input [inline]="true" [label]="form.get('active').value ? ('active' | translate) : ('inactive' | translate)"
                                  [formControl]="form.get('active')"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-2">
                <app-text-input label="Contract Name" [formControl]="form.get('name')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <div class="p-field">
                    <label class="full-width">Company <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" [formControl]="form.controls['company']"
                                    [suggestions]="companies" delay="1000" (onClear)="companyChanged(null)"
                                    (completeMethod)="searchCompaniesByTypeList($event)" [forceSelection]="true"
                                    (onSelect)="companyChanged($event); getChildren($event)">
                    <ng-template let-company pTemplate="item">
                        <div>{{company.name}}</div>
                    </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.get('company').touched && form.get('company').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="providers" [filter]="true" [optionLabel]="'name'"
                                  (optionChanged)="providerChanged($event.value)"
                                  [formControl]="form.controls['provider']" [label]="'Provider'"></app-select-input>
            </div>

            <div class="p-col-12 p-md-2">
                <div class="p-field">
                    <label>Country</label>
                    <p-dropdown [options]="countries" [formControl]="form.get('country_id')" [appendTo]="'body'"
                                [optionValue]="'id'" [showClear]="true" (onChange)="countryChanged($event)"
                                [placeholder]="'Select country'" [filter]="true" filterBy="name_en,name_de">
                        <ng-template pTemplate="selectedItem">
                            <div>{{getCountryName(form.get('country_id').value)}}</div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div>{{item?.name_en}}</div>
                        </ng-template>
                    </p-dropdown>

                    <small *ngIf="form.get('country_id').touched && form.get('country_id').errors?.required" class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Regions <span class="p-error">*</span></label>
                    <p-multiSelect class="full-width" styleClass="custom-multi-select" [options]="cities"
                                   [formControl]="form.controls['cities']"
                                   display="chip" [defaultLabel]="'Regions'" filterBy="name_en,name_de,code"
                                   [filter]="true" (onChange)="citiesChanged()">
                        <ng-template let-value pTemplate="selectedItems">
                            <div class="country-item country-item-value"
                                 *ngFor="let option of form.controls['cities'].value; let i = index">
                                <div>{{option?.name_en}}
                                    <i class="pi pi-times-circle" (click)="removeSelectedCity(i)"></i></div>
                            </div>
                            <div *ngIf="!form.controls['cities'].value || form.controls['cities'].value.length === 0"
                                 class="country-placeholder">
                                Select Regions
                            </div>
                        </ng-template>
                        <ng-template let-city pTemplate="item">
                            <div class="country-item">{{city.name_en}} - {{city.code}}</div>
                        </ng-template>
                    </p-multiSelect>

                    <small *ngIf="form.controls['cities'].touched && form.controls['cities'].errors?.required"
                           class="p-error">required</small>
                </div>

            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <app-text-input label="Company/Service Provider ID" [formControl]="form.get('booking_code')"></app-text-input>
            </div>
            <div *ngIf="isEditMode" class="p-col-12 p-md-2">
                <app-text-input label="Contract code" [formControl]="form.get('code')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2 p-field">
                <label>Validity from</label>
                <p-calendar class="full-width" formControlName="validity_from" [yearNavigator]="true"
                            [monthNavigator]="true" [showIcon]="true" [yearRange]="yearRange"
                            placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
            </div>
            <div class="p-col-12 p-md-2 p-field">
                <label>Validity to</label>
                <p-calendar class="full-width" formControlName="validity_to" [showIcon]="true"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
                            placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>Contract, Booking & Service Handling</h5>
        <div class="p-grid">
            <!--<div class="p-col-12 p-md-2">
                <div class="p-field">
                    <label class="full-width">Invoicing <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="invoice_handler_id"
                                    [suggestions]="invoicingCompanies" delay="1000"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.get('invoice_handler_id').touched && form.get('invoice_handler_id').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>-->
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="bookingOptions" [label]="'Booking possible for'"
                                  [formControl]="form.controls['booking_possible_for']"
                                  (optionChanged)="bookingPossibleChanged($event.value)"></app-select-input>
            </div>
            <div class="p-col-12 p-md-10 half-rem-padding">
                <app-multi-select [options]="bookings" [formControl]="form.controls['hotels']" [filter]="true"
                                  [label]="'Hotels'" [placeholder]="'Select hotels'"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="serviceHandlerTypes"
                                  (optionChanged)="serviceHandlerTypeChanged($event.value)"
                                  [label]="'Service handler type'"
                                  [formControl]="form.controls['service_handler_type_id']"
                                  [filter]="true" [optionLabel]="'name'" [optionValue]="'id'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-2"
                 *ngIf="(form.get('service_handler_type_id').value === 4)
                 && form.get('booking_possible_for').value == 'Hotel'">
                <app-switch-input [label]="'Use Destination Hotel'" (changed)="useDestHotelsChanges($event.checked)"
                                  [formControl]="form.controls['use_destination_hotel']"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-4 half-rem-padding">
                <app-select-input [formControl]="form.controls['service_handler_id']" [options]="serviceHandlers"
                                  [optionValue]="'id'" [optionLabel]="'name'"
                                  [label]="'Service Handler'" [filter]="true"></app-select-input>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button pButton type="submit" class="float-right">{{isEditMode ? ('saveChanges' | translate) : 'Create'}}</button>
    </div>
</form>

