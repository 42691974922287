import {Component, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ProductService} from '../service/productservice';
import {Product} from '../domain/product';
import {DashboardService} from '../../_services/dashboard.service';
import {User} from '../../_models/user';
import {Router} from '@angular/router';
import {AccountService} from '../../_services/account.service';
import {ApexAxisChartSeries, ApexTitleSubtitle, ApexDataLabels, ApexChart, ChartComponent} from 'ng-apexcharts';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    title: ApexTitleSubtitle;
    colors: any;
};

@Component({
    templateUrl: './dashboard.component.html'
})
export class DashboardDemoComponent implements OnInit {

    activeNews = 1;
    statistics: any;
    taStatistics: any;
    user: User;
    products: Product[];

    monthsChartData: any;
    monthsChartOptions: any;
    currentYear: string;
    pastYear: string;
    yearBeforeLast: string;
    showMonthsChart = false;

    coursesChartData: any;
    showCoursesChart = false;

    hotelsChartData: any;
    showHotelsChart = false;
    showHeatMap = false;

    colors = ['#fd7f6f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7'];


    @ViewChild('chart') chart: ChartComponent;
    public heatMapOptions: Partial<ChartOptions>;

    constructor(private productService: ProductService, private breadcrumbService: BreadcrumbService,
                private dashboardService: DashboardService, private router: Router,
                private accountService: AccountService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: ['/']}
        ]);
    }

    ngOnInit() {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        } else {
            this.user = JSON.parse(sessionStorage.getItem('user'));
        }
        if (this.user.role_id == 4) {
            this.router.navigateByUrl('client-portal');
        }
        this.getStatistics();
        this.productService.getProducts().then(data => this.products = data);
    }

    getStatistics() {
        this.dashboardService.getStatistics().subscribe((res: any) => {
            this.statistics = res;
            /*const ccc = [
                {'2023-6': 48, '2022-6': 52},
                {'2023-5': 87, '2022-5': 74},
                {'2023-4': 55, '2022-4': 65},
                {'2023-3': 61, '2022-3': 76},
                {'2023-2': 84, '2022-2': 80},
                {'2023-1': 77, '2022-1': 66},
                {'2022-12': 52, '2021-12': 48},
                {'2022-11': 55, '2021-11': 53},
                {'2022-10': 58, '2021-10': 49},
                {'2022-9': 25, '2021-9': 22},
                {'2022-8': 33, '2021-8': 40},
                {'2022-7': 32, '2021-7': 28}
            ];*/
            this.prepareRequestsPerMonthChart();
            this.prepareMostRequestedChart(this.statistics.most_golfcourse_request, 'courses');
            this.prepareMostRequestedChart(this.statistics.most_hotels_request, 'hotels');
            this.prepareRequestedHoursChart(this.statistics.most_request_hour);
        }, error => {
            this.accountService.logout();
        });
    }

    prepareRequestsPerMonthChart() {
        const today = new Date();
        this.currentYear = today.getFullYear().toString();
        this.pastYear = (+this.currentYear - 1).toString();
        this.yearBeforeLast = (+this.currentYear - 2).toString();

        this.monthsChartData = {
            labels: [],
            datasets: [
                {label: this.yearBeforeLast, backgroundColor: '#fd7f6f', borderColor: '#fd7f6f', data: []},
                {label: this.pastYear, backgroundColor: '#7eb0d5', borderColor: '#7eb0d5', data: []},
                {label: this.currentYear, backgroundColor: '#bd7ebe', borderColor: '#bd7ebe', data: []}
            ]
        };

        this.monthsChartOptions = {maintainAspectRatio: false, aspectRatio: 0.8};
        const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'];
        const d = new Date();
        d.setDate(1);
        for (let i = 0; i <= 11; i++) {
            this.monthsChartData.labels.push(monthName[d.getMonth()]);
            d.setMonth(d.getMonth() - 1);
        }
        this.statistics?.requests_per_month.forEach(item => {
            if (Object.keys(item)[0].includes(this.currentYear)) {
                this.monthsChartData.datasets[2].data.push(item[Object.keys(item)[0]]);
                this.monthsChartData.datasets[1].data.push(item[Object.keys(item)[1]]);
                this.monthsChartData.datasets[0].data.push(0);
            } else {
                this.monthsChartData.datasets[1].data.push(item[Object.keys(item)[0]]);
                this.monthsChartData.datasets[0].data.push(item[Object.keys(item)[1]]);
                this.monthsChartData.datasets[2].data.push(0);
            }
        });
        this.showMonthsChart = true;
    }

    prepareMostRequestedChart(group: any, type: string) {
        if (type === 'courses') {
            this.coursesChartData = {
                datasets: [{data: [], backgroundColor: [], label: 'My dataset'}],
                labels: []
            };
            group.forEach((item, i) => {
                this.coursesChartData.datasets[0].data.push(item.count);
                this.coursesChartData.datasets[0].backgroundColor.push(this.colors[i] || this.getRandomColor());
                this.coursesChartData.labels.push(item.name);
            });
            this.showCoursesChart = true;
        } else if (type === 'hotels') {
            this.hotelsChartData = {
                datasets: [{data: [], backgroundColor: [], label: 'My dataset'}],
                labels: []
            };
            group.forEach((item, i) => {
                this.hotelsChartData.datasets[0].data.push(item.count);
                this.hotelsChartData.datasets[0].backgroundColor.push(this.colors[i] || this.getRandomColor());
                this.hotelsChartData.labels.push(item.name);
            });
            this.showHotelsChart = true;
        }
    }

    prepareRequestedHoursChart(group) {
        this.heatMapOptions = {
            series: [
                {name: 'Sun', data: []},
                {name: 'Mon', data: []},
                {name: 'Tue', data: []},
                {name: 'Wed', data: []},
                {name: 'Thu', data: []},
                {name: 'Fri', data: []},
                {name: 'Sat', data: []}
            ],
            chart: {height: 350, type: 'heatmap'},
            dataLabels: {enabled: false},
            colors: ['#008FFB']
        };

        for (let i = 0; i < 24; i++) {
            this.heatMapOptions.series.forEach((item: any) => {
                item.data.push({x: i.toString(), y: 0});
            });
        }

        group.forEach((item: any, i) => {
            item.day = new Date(item.request_date).getDay();
            // @ts-ignore
            this.heatMapOptions.series[item.day].data[+item.request_hour].y += item.request_count;
        });
        this.showHeatMap = true;
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    public generateData(count, yRange) {
        let i = 0;
        const series = [];
        while (i < count) {
            const xAxis = 'w' + (i + 1).toString();
            const yAxis = Math.floor(Math.random() * (yRange.max - yRange.min + 1)) + yRange.min;
            series.push({x: xAxis, y: yAxis});
            i++;
        }
        return series;
    }
}
