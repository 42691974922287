import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {AccountService} from './account.service';

@Injectable({
    providedIn: 'root'
})
export class GcmService {

    currentMessage = new BehaviorSubject(null);
    deviceKey: string;
    isNotiAllowed: string = localStorage.getItem('isNotiAllowed') || window.Notification?.permission;

    constructor(private afMessaging: AngularFireMessaging, private injector: Injector) {
        this.deviceKey = localStorage.getItem('device_key');
        if (this.deviceKey) {
            this.afMessaging.messages
                .subscribe((message) => {
                    this.currentMessage.next(message.notification);
                    const accountService = this.injector.get(AccountService);
                    accountService.getLatestNotifications();
                });
        }
    }

    requestToken() {
        this.afMessaging.requestToken.subscribe(token => {
                localStorage.setItem('device_key', token);
            }
        );
    }

    deleteToken(refresh = false) {
        this.afMessaging.deleteToken(this.deviceKey).subscribe(
            (token) => {
                console.log('Token deleted! Delete from the server!');
            },
        );
    }

    rejectNotification() {
        this.isNotiAllowed = 'rejected';
        localStorage.setItem('isNotiAllowed', 'rejected');
    }
}
