import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestAPIService} from './request-api.service';

@Injectable({
    providedIn: 'root'
})
export class TuiService {

    baseUrl = environment.apiUrl;
    queryString = require('query-string');
    queryParamsString: string;
    tuiParsedParams: any;

    constructor(private requestService: RequestAPIService) {
    }

    async parseParams() {
        this.queryParamsString = location.href.split('?')[1];
        const parsed = this.queryString.parse(decodeURIComponent(this.queryParamsString));
        const checkResult = await this.checkTuiCode(parsed.ref);
        if (checkResult.request) {
            return {requestExist: true, request: checkResult.request};
        }
        const keys = Object.keys(parsed);
        let clientsCount: any = [];
        let destCount: any = [];
        let productsCount: any = [];
        keys.forEach(key => {
            if (key.includes('cf')) {
                clientsCount++;
            }
            if (key.includes('dc')) {
                destCount++;
            }
            if (key.includes('pc')) {
                productsCount++;
            }
        });
        const clients = [];
        for (let i = 1; i <= clientsCount; i++) {
            clients.push({
                first_name: parsed['cf' + i],
                last_name: parsed['cl' + i],
                groups: parsed['cgc' + i],
                gender: parsed['cg' + i],
                email: parsed['em' + i],
                phone: parsed['mob' + i],
                destinations: parsed['cd' + i],
            });
        }
        const destinations = [];
        for (let i = 1; i <= destCount; i++) {
            destinations.push({
                city_code: parsed['dc' + i],
                city_name: parsed['daa' + i],
                arrival_date: parsed['da' + i],
                departure_date: parsed['dd' + i],
                hotel_code: parsed['dh' + i],
                hotel_name: parsed['dhn' + i]
            });
        }
        const products = [];
        for (let i = 1; i <= productsCount; i++) {
            products.push({
                code: parsed['pc' + i],
                destination: parsed['pdc' + i],
                date: parsed['pd' + i]
            });
        }
        this.tuiParsedParams = {
            params: parsed,
            destinations: destinations,
            clients: clients,
            products: products
        };
        console.log(this.tuiParsedParams);
        
        return this.tuiParsedParams;
    }

    async checkTuiCode(code) {
        return await this.requestService.checkRequestTuiCode(code).toPromise().then((res: any) => {
            return res;
        });
    }
}
