import {Injectable} from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {GolfCoursesService} from '../golf-courses/_services/golf-courses.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GolfCourseResolver implements Resolve<any> {

    basics: any;

    constructor(private golfCourseServices: GolfCoursesService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const basics = this.golfCourseServices.getBasics().pipe(map(res => {
                return res;
            })
        );

        const courseId = route.params['id'];
        let golfCourseData;
        if (courseId) {
            golfCourseData = this.golfCourseServices.getGolfCourseById(courseId).pipe(map((res: any) => {
                return res.golfcourse;
            }));
        }

        return forkJoin(basics, golfCourseData);
    }
}
