<h5>Players <i class="pi pi-pencil" (click)="editPlayers()"
             *ngIf="(requestServices.user.company_type_id == 1 || requestServices.user.company_type_id == 2) &&
                                    request.sub_status_id < 11 && !viewOnly"></i>
</h5>
<p-table [value]="request?.players" responsiveLayout="scroll" styleClass="participants-table">
  <ng-template pTemplate="header">
    <tr>
      <th>Gender</th>
      <th>Last name</th>
      <th>First name</th>
      <th>HCP</th>
      <th>Additional info</th>
      <th *ngIf="request.is_delegate && !viewOnly"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-participant let-pIndex=rowIndex>
    <tr>
      <td><span style="text-transform: capitalize">{{participant?.gender}}</span></td>
      <td>{{participant?.last_name}}</td>
      <td>{{participant?.first_name}}</td>
      <td>{{participant?.hcp}}</td>
      <td>{{participant?.additional_information}}</td>
      <td *ngIf="request.is_delegate && !viewOnly">
        <i class="pi pi-user mr-2" pTooltip="Request delegated to this client" tooltipPosition="top"
           style="font-size: 1.2rem" *ngIf="request.delegate_player_id == participant.id"></i>
        <i class="pi pi-send" pTooltip="Request submitted by this client" tooltipPosition="top"
           style="font-size: 1.2rem"
           *ngIf="(request.delegate_player_id == participant.id) && request.is_client_submit"></i>
        <i class="pi pi-copy" pTooltip="Get delegation URL" tooltipPosition="top"
           style="font-size: 1.2rem; cursor: pointer" (click)="copyDelegationLink()"
           *ngIf="(request.delegate_player_id == participant.id) && request.sub_status_id === 1"></i>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- EDIT PLAYERS -->
<p-dialog *ngIf="displayEditPlayers" header="Edit Players" [(visible)]="displayEditPlayers" [position]="'top'" [modal]="true"
          [style]="{width: '75vw'}"
          [draggable]="false" [resizable]="false" [maximizable]="true" (onHide)="onHidePlayersForm()">
    <app-players-form [request]="request" #playersForm (playersUpdatedEvent)="playersUpdated($event)"></app-players-form>
  <ng-template pTemplate="footer">
    <div class="buttons">
      <button pButton type="button" class="float-right" (click)="saveChanges()">{{'saveChanges' | translate}}</button>
    </div>
  </ng-template>
</p-dialog>
<!-- EDIT PLAYERS -->
