import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {Integration} from '../../_models/integration';
import {IntegrationService} from '../../_services/integration.service';
import {map, tap} from 'rxjs/operators';
import {Company} from '../../_models/company';
import {CompaniesService} from '../../_services/companies-service';
import {Table} from 'primeng/table';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {SharedService} from '../../_services/shared.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit {

  integForm: UntypedFormGroup;
  integrations$: Observable<Integration[]>;
  selectedIntegration: Integration[];
  showNewIntegrationDialog = false;
  companies$: Observable<Company[]>;

  @ViewChild('dt') cancelEditEL: Table;

  // Filter vars
  tableHelper = new GgTableCustomFN();
  filters = {
    company_name: [],
  };
  booleanFilter = [{name: this.translate.transform('active'), val: 1},
    {name: this.translate.transform('inactive'), val: 0}];

  constructor(
      private breadcrumbService: BreadcrumbService,
      private msgService: MessageService,
      private fb: UntypedFormBuilder,
      private integrationService: IntegrationService,
      private confirmationService: ConfirmationService,
      private companiesService: CompaniesService,
      private accountService: AccountService,
      private sharedServices: SharedService,
      public translate: TranslatePipe
  ) {
    this.breadcrumbService.setItems([
      {label: 'Dashboard', routerLink: '/'},
      {label: 'Administration'},
      {label: 'Integrations'},
    ]);
  }

  ngOnInit(): void {
    this.integrations$ = this.integrationService.getIntegrations().pipe(tap(integrations => {
      this.tableHelper.populateFilter(this.filters, integrations);
      integrations.forEach(integration => {
        integration.expiry_date = new Date(integration.expiry_date);
      });
    }));

    this.initForm();
  }

  initForm() {
    this.integForm = this.fb.group({
      id: '',
      name: ['', Validators.required],
      description: ['', Validators.required],
      expiry_date: ['', Validators.required],
      status: [1, Validators.required]
    });
  }

  resetForm() {
    if (this.integForm.controls.company) {
      this.integForm.removeControl('company');
    }

    this.integForm.reset();
    this.integForm.get('status').setValue(1);
  }

  addNew() {
    this.cancelEditEL.cancelRowEdit(this.integForm.value);
    this.resetForm();
    this.integForm.addControl('company', this.fb.control('', Validators.required));
    this.showNewIntegrationDialog = true;
  }

  onRowEditInit(integration: Integration) {
    const clone = {
      ...integration,
      expiry_date: new Date(integration.expiry_date)
    };
    this.integForm.patchValue(clone);
  }

  deleteIntegration() {
    this.integrations$ = this.integrationService.getIntegrations();
  }

  submitIntegration() {
    if (!this.integForm.valid || !this.integForm.value.company.id) {
      this.integForm.markAllAsTouched();
      return;
    }

    const clone = {
      ...this.integForm.value,
      expiry_date: this.sharedServices.dateToIsoString(this.integForm.value.expiry_date),
      company_id: this.integForm.value.company.id
    };

    try {
      this.integrationService.createIntegration(clone).subscribe(res => {
        this.integrations$ = this.integrationService.getIntegrations();
        this.showNewIntegrationDialog = false;
        this.resetForm();
        this.msgService.add({severity: 'success', summary: 'Create Integration', detail: 'New Integration has been saved successfully!'});
      }, e => {
        console.error('Create Integration error', e);
        this.msgService.add({
          severity: 'error',
          summary: 'Create Integration',
          detail: 'Oh! No! Something unexpected went wrong!'
        });
      });
    } catch (e) {
      console.error('Edit Integration error', e);
      this.msgService.add({
        severity: 'error',
        summary: 'Edit Integration',
        detail: 'Oh! No! Something unexpected went wrong!'
      });
    }
  }

  onRowEditSave(integration: Integration, index: number) {
    if (!this.integForm.valid) {
      this.integForm.markAllAsTouched();
      return;
    }

    const clone = {
      ...this.integForm.value,
      expiry_date: this.sharedServices.dateToIsoString(this.integForm.value.expiry_date)
    };

    try {
      this.integrationService.updateIntegration(clone, clone.id).subscribe(res => {
        this.integrations$ = this.integrationService.getIntegrations();
        this.resetForm();
        this.msgService.add({severity: 'success', summary: 'Edit Integration', detail: 'Edit has been saved successfully!'});
      }, e => {
        console.error('Edit Integration error', e);
        this.msgService.add({
          severity: 'error',
          summary: 'Edit Integration',
          detail: 'Oh! No! Something unexpected went wrong!'
        });
      });
    } catch (e) {
      console.error('Edit Integration error', e);
      this.msgService.add({
        severity: 'error',
        summary: 'Edit Integration',
        detail: 'Oh! No! Something unexpected went wrong!'
      });
    }
  }

  onRowEditCancel(integration: Integration, index: number) {
    this.resetForm();
  }

  confirmDelete(integration: Integration, index: number) {
    this.confirmationService.confirm({
      message: 'Do you want to remove the integration?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteRow(integration, index);
      },
      reject: (type) => {
        // do nothing
      },
      key: 'deleteConfirm',
      acceptLabel: this.translate.transform('yes'),
      rejectLabel: this.translate.transform('no'),
      header: this.translate.transform('confirmation')
    });
  }

  deleteRow(integration: Integration, index: number) {
    try {
      this.integrationService.deleteIntegration(integration.id).subscribe(res => {
        this.integrations$ = this.integrationService.getIntegrations();
        this.resetForm();
        this.msgService.add({
          severity: 'success', summary: 'Delete Integration',
          detail: 'Delete integration has been saved successfully!'
        });
      }, e => {
        console.error('Delete Integration error', e);
        this.msgService.add({
          severity: 'error',
          summary: 'Delete Integration',
          detail: 'Oh! No! Something unexpected went wrong!'
        });
      });
    } catch (e) {
      console.error('Delete Integration error', e);
      this.msgService.add({
        severity: 'error',
        summary: 'Delete Integration',
        detail: 'Oh! No! Something unexpected went wrong!'
      });
    }
  }

  searchCompanies($event: any) {
    this.companies$ = this.companiesService.searchCompanies($event.query).pipe(map((res: any) => res.companies));
  }

  checkPermission(permissionCode: string): boolean {
    return this.accountService.checkPermission(permissionCode);
  }

  exportExcel() {
    if (!this.selectedIntegration || this.selectedIntegration.length < 1) {
      this.msgService.add({
        severity: 'error',
        summary: this.translate.transform('noExportMessage')
      });
      return;
    }
    this.tableHelper.exportExcel(this.selectedIntegration);
  }
}
