import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {GolfCoursesService} from '../../../_services/golf-courses.service';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-services-form',
    templateUrl: './services-form.component.html',
    styleUrls: ['./services-form.component.css']
})
export class ServicesFormComponent implements OnInit {
    @Input() type: string;
    servicesForm: UntypedFormGroup;
    services: any[] = [];
    currentService: any;
    golfCourseServices = [];
    pageName: string;
    serviceType: string;

    newForm: UntypedFormArray;

    constructor(private fb: UntypedFormBuilder, private coursesService: GolfCoursesService, private route: ActivatedRoute,
                private messagesServices: MessageService, public translateService: TranslateService,
                private translate: TranslatePipe) {
    }

    ngOnInit(): void {
        this.pageName = this.route.snapshot.url[0].path;
        // this.initForm();
        this.initNewForm();
        this.getServices();
    }

    getServices() {
        this.coursesService.getServices(this.type).subscribe((res: any) => {
            this.services = res.services;
            // this.currentService = this.services[0];
            // this.initServicesControls();
            this.initNewForm();
        });
    }

    initNewForm() {
        this.newForm = this.fb.array([]);
        this.newForm.reset();
        this.services.forEach(service => {
            this.newForm.push(this.fb.group({
                service_id: service.id,
                name: service.translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name,
                selected_option: null,
                type: service.type,
                qty: null,
                fees: null,
                properties: null,
                notes: null,
                addons: null,
                fee_details: null,
                active: 1,
                icon: service.icon
            }));
        });
        this.getGolfCourseServices();
    }

    getGolfCourseServices() {
        this.coursesService.getGolfCourseById(this.route.parent.snapshot.paramMap.get('id')).subscribe((res: any) => {
            if (this.pageName == 'services') {
                this.golfCourseServices = res.golfcourse.services;
            } else if (this.pageName == 'trainings') {
                this.golfCourseServices = res.golfcourse.trainings;
            }
            /*this.golfCourseServices.map(serv => {
                serv.selected_option = +serv.selected_option;
                serv.name = this.createPropertyName(serv.name);
            });
            if (this.golfCourseServices.length > 0) {
                const obj = Object.assign({}, ...this.golfCourseServices.map(key => ({
                    [key.name]: key
                })));
                this.servicesForm.patchValue(obj);
                this.getServiceDetails(this.services[0]);
            }*/
            /////////////// NEW FORM ///////////////
            this.golfCourseServices.forEach(service => {
                this.newForm.controls.forEach(control => {
                    if (control.get('service_id').value == service.service_id) {
                        control.get('selected_option').setValue(1);
                    }
                });
            });

            /*const matchedServices = this.newForm.controls.filter(o1 => this.golfCourseServices
                .some(o2 => o1.value.service_id === o2.service_id));
            matchedServices.forEach(control => {
                control.get('selected_option').setValue(1);
            });*/
            /////////////// NEW FORM ///////////////
        });
    }

    initForm() {
        this.servicesForm = this.fb.group({});
    }

    initServicesControls() {
        this.serviceType = this.services[0]?.type;
        this.services.forEach(service => {
            const services = this.fb.group({});
            services.addControl('selected_option',
                this.fb.control(service.view_type == 'boolean' ? 0 : 'No'));
            services.addControl('show_website', this.fb.control(1));
            services.addControl('view_type', this.fb.control(service.view_type));
            services.addControl('name', this.fb.control(service
                .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name));
            services.addControl('service_id', this.fb.control(service.id));
            services.addControl('qty', this.fb.control(1));
            services.addControl('fees', this.fb.control(0));
            services.addControl('notes', this.fb.control(null));
            this.servicesForm.addControl(this.createPropertyName(service
                .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name), services);

            ///////////////// PROPERTIES /////////////////
            const properties = this.fb.array([]);
            service.properties.forEach(prop => {
                const property = this.fb.group({});
                property.addControl('selected_option',
                    this.fb.control(prop.view_type == 'boolean' ? 0 : null));
                property.addControl('view_type', this.fb.control(prop.view_type));
                property.addControl('name', this.fb.control(prop.name));
                property.addControl('property_id', this.fb.control(prop.id));
                properties.push(property);
                const serviceGroup = this.servicesForm.get(this.createPropertyName(service
                    .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name)) as UntypedFormGroup;
                serviceGroup.addControl('properties', properties);
            });
            ///////////////// PROPERTIES /////////////////
            ///////////////// FEES /////////////////
            if (service.fees.length > 0) {
                const feesDetails = this.fb.array([]);
                const feesUnits = this.fetchRadioOptions(service.fees[0].unit_options);
                feesUnits.forEach(unit => {
                    const fee = this.fb.group({});
                    fee.addControl('fee_id', this.fb.control(service.fees[0].id));
                    fee.addControl('qty', this.fb.control(1));
                    fee.addControl('fees', this.fb.control(0));
                    fee.addControl('unit', this.fb.control(unit));
                    fee.addControl('notes', this.fb.control(null));
                    feesDetails.push(fee);
                    const serviceGroup = this.servicesForm.get(this.createPropertyName(service
                        .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name)) as UntypedFormGroup;
                    serviceGroup.addControl('fee_details', feesDetails);
                });
            }
            ///////////////// FEES /////////////////
            ///////////////// ADDONS /////////////////
            const addons = this.fb.array([]);
            service.addons.forEach((addonItem, index) => {
                const addon = this.fb.group({});
                addon.addControl('selected_option',
                    this.fb.control(addonItem.view_type == 'boolean' ? 0 : null));
                addon.addControl('view_type', this.fb.control(addonItem.view_type));
                addon.addControl('name', this.fb.control(addonItem.name));
                addon.addControl('addon_id', this.fb.control(addonItem.id));
                addon.addControl('qty', this.fb.control(1));
                addon.addControl('fees', this.fb.control(0));
                addon.addControl('notes', this.fb.control(null));
                addons.push(addon);
                const serviceGroup = this.servicesForm.get(this.createPropertyName(service
                    .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name)) as UntypedFormGroup;
                serviceGroup.addControl('addons', addons);
                ///////////////// ADDONS FEES /////////////////
                if (addonItem.fees.length > 0) {
                    const feesDetails = this.fb.array([]);
                    const addonFeesUnits = this.fetchRadioOptions(addonItem.fees[0].unit_options);
                    addonFeesUnits.forEach(unit => {
                        const fee = this.fb.group({});
                        fee.addControl('fee_id', this.fb.control(addonItem.fees[0].id));
                        fee.addControl('qty', this.fb.control(1));
                        fee.addControl('fees', this.fb.control(0));
                        fee.addControl('unit', this.fb.control(unit));
                        fee.addControl('notes', this.fb.control(null));
                        feesDetails.push(fee);
                        const addonGroup = this.servicesForm.get([this.createPropertyName(service
                            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name),
                            'addons', index]) as UntypedFormGroup;
                        addonGroup.addControl('fee_details', feesDetails);
                    });
                }
                ///////////////// ADDONS FEES /////////////////
            });
            ///////////////// ADDONS /////////////////
        });
        for (const key of Object.keys(this.servicesForm.value)) {
            this.servicesForm.get(key).disable();
            this.servicesForm.get([key, 'selected_option']).enable();
        }
        this.getGolfCourseServices();
    }

    getServiceDetails(service) {
        this.currentService = service;
        if (this.serviceFormObject.controls.selected_option.value == 0 ||
            this.serviceFormObject.controls.selected_option.value == 'No') {
            this.serviceFormObject.disable();
            this.serviceFormObject.controls.selected_option.enable();
        } else {
            this.servicesForm.enable();
        }
    }

    get form() {
        return this.servicesForm.controls;
    }

    get serviceFormObject() {
        return this.servicesForm.get([this.createPropertyName(this.currentService
            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name)]) as UntypedFormGroup;
    }

    get _properties() {
        return this.servicesForm.get([this.createPropertyName(this.currentService
            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name), 'properties']) as UntypedFormArray;
    }

    get _addons() {
        return this.servicesForm.get([this.createPropertyName(this.currentService
            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name), 'addons']) as UntypedFormArray;
    }

    _addonsFees(index) {
        return this.servicesForm.get([this.createPropertyName(this.currentService
            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name), 'addons', index, 'fee_details']) as UntypedFormArray;
    }

    get _feesDetails() {
        return this.servicesForm.get([this.createPropertyName(this.currentService
            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name), 'fee_details']) as UntypedFormArray;
    }

    createPropertyName(name) {
        return name.replace(' ', '_').toLowerCase();
    }

    fetchRadioOptions(options) {
        return options.split(',').map(option => option.trim());
    }

    availableChange(event) {
        if (event.checked == 0 || event.value == 'No') {
            this.serviceFormObject.disable();
            this.serviceFormObject.controls.selected_option.enable();
        } else {
            this.serviceFormObject.enable();
        }
    }

    submitForm() {
        // let obj = Object.keys(this.servicesForm.getRawValue()).map((k) => this.servicesForm.getRawValue()[k]);
        // obj = obj.filter(x => (x.selected_option !== 0 && x.selected_option !== 'No'));

        /////////// NEW FORM /////////////
        let obj = this.newForm.value;
        obj = obj.filter(x => (x.selected_option === 1));
        obj.forEach(item => {
            item.properties = [];
            item.addons = [];
            item.fee_details = [];
        });

        /////////// NEW FORM /////////////
        this.coursesService.addServices(
            {services: obj, type: this.type},
            this.route.parent.snapshot.paramMap.get('id'))
            .subscribe((res: any) => {
                this.messagesServices.add({
                    severity: 'success', summary: this.translate.transform('golfCourses'),
                    detail: this.translate.transform('updateGolfCourseMessage')
                });
                if (this.pageName == 'services') {
                    this.golfCourseServices = res.golfcourse.services;
                } else if (this.pageName == 'trainings') {
                    this.golfCourseServices = res.golfcourse.trainings;
                }
                this.coursesService.course = res.golfcourse;
            });
    }

    isServicesExists(service) {
        const isExist = this.golfCourseServices.filter(item => item?.service_id == service.id);
        if (isExist.length > 0) {
            return true;
        }
        return false;
    }
}
