import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Region, Country} from '../../../_models/destinations';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {DestinationsService} from '../../_services/destinations.service';
import {Lookup} from '../../../_models/lookup';
import {GolfCoursesService} from '../../../golf-courses/_services/golf-courses.service';
import {HotelsService} from '../../../hotels/_services/hotels.service';
import {GfpService} from '../../../product-setup/_services/gfp.service';
import {HotelProductsService} from '../../../product-setup/_services/hotel-products.service';
import {GolfHolidayService} from '../../../product-setup/_services/golf-holiday.service';
import {ImagesListComponent} from '../../../_shared/images-list/images-list.component';
import {TestimoniesComponent} from '../../testimonies/testimonies.component';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {AccountService} from "../../../_services/account.service";

@Component({
    selector: 'app-country-form',
    templateUrl: './country-form.component.html',
    styleUrls: ['./country-form.component.css']
})
export class CountryFormComponent implements OnInit, OnChanges {
    countryForm: UntypedFormGroup;
    country: any;
    isEditMode = false;
    regions: Region[];
    @Input() countyId: number;
    fieldTypes: any[];
    languages = [
        {label: 'EN', value: 1},
        {label: 'DE', value: 2},
    ];
    formatOptions: Lookup[] = [{id: 0, name: 'Text'}, {id: 1, name: 'HTML'}];

    golfCourses = [];
    hotels = [];
    golfProducts = [];
    hotelProducts = [];
    golfHolidays = [];
    currencies = [];
    cities = [];
    @ViewChild('uploader', {static: false}) imagesUploader: ImagesListComponent;
    @ViewChild('testimony', {static: false}) testimonyComponent: TestimoniesComponent;
    showWebsiteCurrentValue: number;
    countries: Country[];
    showUnPublishAlert = false;
    constructor(
        private breadcrumbService: BreadcrumbService,
        private msgService: MessageService, private loader: NgxUiLoaderService,
        private activatedRoute: ActivatedRoute, private router: Router,
        private fb: UntypedFormBuilder, private confirmationService: ConfirmationService,
        private destService: DestinationsService, private golfCoursesService: GolfCoursesService,
        private hotelsService: HotelsService, private gfpService: GfpService,
        private hotelProductsService: HotelProductsService,
        private golfHolidayService: GolfHolidayService, public translate: TranslatePipe,
        public translateService: TranslateService, private accountService: AccountService
    ) {
    }

    ngOnInit(): void {
        // init form
        this.initForm();

        // get Regions for dropdown select
        this.getRegions();
        this.getCurrencies();
        this.getFieldTypes();
        this.getCountries();

        // chek edit mode
        const countryId = this.countyId || +this.activatedRoute.snapshot.paramMap.get('id');
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: this.translate.transform('countries'), routerLink: '/destinations/countries'},
            {
                label: countryId > 0 ? this.translate.transform('editCountry') :
                    this.translate.transform('addCountry')
            },
        ]);
        if (countryId > 0) {
            this.fetchCountry(countryId);
            if (!this.accountService.checkPermission('1697456000522')) {
                this.countryForm.get('region_id').disable();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initForm();
        this.fetchCountry(this.countyId);
    }

    initForm(): void {
        this.countryForm = this.fb.group({
            id: [''],
            name_en: ['', Validators.required],
            name_de: ['', Validators.required],
            code: ['', Validators.required],
            phone_code: [''],
            status: [1],
            region_id: ['', Validators.required],
            currency: [''],
            fields: this.fb.array([]),
            faqs: this.fb.array([]),
            featured_golf_courses: null,
            featured_hotels: null,
            featured_cities: null,
            featured_products: null,
            featured_hotel_products: null,
            featured_golf_holidays: null,
            related_countries: null,
            show_website: 0,
            is_publish_required: null,
            translations: this.fb.array([
                this.fb.group({
                    language_id: 1,
                    name: [null]
                }),
                this.fb.group({
                    language_id: 2,
                    name: [null]
                })
            ]),
            top: 0
        });
    }

    initFields() {
        this.fieldTypes?.forEach((fl, index) => {
            this.fields.push(
                this.fb.group({
                    id: null,
                    type_id: fl.id,
                    is_html: 0,
                    language_mode: 2,
                    translations: this.fb.array([])
                })
            );
            const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
            fl.translations.forEach((translation, n) => {
                fieldTranslations.push(
                    this.fb.group({
                        language_id: translation.language_id,
                        description: null
                    })
                );
            });
        });
    }

    fetchCountry(countryId) {
        this.isEditMode = true;
        this.getCountry(countryId).subscribe(country => {
            this.country = country;
            this.country.name_en = this.country.translations[0].name;
            this.country.name_de = this.country.translations[1].name;
            this.showWebsiteCurrentValue = this.country.show_website;
            this.country.currency = this.country.currency_id;
            this.country.featured_golf_courses = this.country.featured_golf_courses?.map(course => {
                return course.id;
            });
            this.country.featured_hotels = this.country.featured_hotels?.map(course => {
                return course.id;
            });
            this.country.featured_products = this.country.featured_products?.map(course => {
                return course.id;
            });
            this.country.featured_hotel_products = this.country.featured_hotel_products?.map(course => {
                return course.id;
            });
            this.country.featured_golf_holidays = this.country.featured_golf_holidays?.map(course => {
                return course.id;
            });
            this.country.featured_cities = this.country.featured_cities?.map(course => {
                return course.id;
            });
            this.country.related_countries = this.country.related_countries?.split(',').map((e) => {
                return +e;
            });
            this.countryForm.patchValue(this.country);
            this.fillFieldsData(this.country.fields);
            this.fillFaqsData(this.country.faqs);
        });
        this.getCitiesByCountry(countryId);
        this.getGolfCoursesByCountry(countryId);
        this.getHotelsByCountry(countryId);
        /*this.getProductsByCountry(countryId);
        this.getHotelProductsByCountry(countryId);
        this.getGolfHolidaysByCountry(countryId);*/
    }

    getRegions(): void {
        this.destService.getRegionsList().subscribe(res => {
            this.regions = res;
        });
    }

    getCitiesByCountry(countryId) {
        this.destService.getAllCities(countryId).subscribe((res: any) => {
            this.cities = res;
        });
    }

    getGolfCoursesByCountry(countryId) {
        this.golfCoursesService.getGolfCoursesByCountry(countryId).subscribe((res: any) => {
            this.golfCourses = res;
        });
    }

    getHotelsByCountry(countryId) {
        this.hotelsService.getAllHotelsByCountry(countryId).subscribe((res: any) => {
            this.hotels = res.hotels;
        });
    }

    getProductsByCountry(countryId) {
        this.gfpService.getProductsByCountry(countryId).subscribe((res: any) => {
            this.golfProducts = res;
        });
    }

    getHotelProductsByCountry(countryId) {
        this.hotelProductsService.getAll(null, countryId).subscribe((res: any) => {
            this.hotelProducts = res.products;
        });
    }

    getGolfHolidaysByCountry(countryId) {
        this.golfHolidayService.getAll(null, null, countryId).subscribe((res: any) => {
            this.golfHolidays = res.products;
        });
    }

    getCurrencies() {
        this.destService.getCurrencies().subscribe((res: any) => {
            this.currencies = res.currencies;
        });
    }

    getCountry(id: number): Observable<Country> {
        return this.destService.getCountry(id);
    }

    get fields() {
        return this.countryForm.controls.fields as UntypedFormArray;
    }

    get faqs() {
        return this.countryForm.controls.faqs as UntypedFormArray;
    }

    fillFieldsData(fields: any[]) {
        this.fields.clear();
        this.fieldTypes?.forEach((fl, index) => {
            const field = fields.filter(x => +x.type_id === fl.id)[0];
            if (field) {
                this.fields.push(
                    this.fb.group({
                        id: field.id,
                        type_id: field.type_id,
                        is_html: field.is_html,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach((translation, n) => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: field.translations[n]?.description
                        })
                    );
                });
            } else {
                this.fields.push(
                    this.fb.group({
                        id: null,
                        type_id: fl.id,
                        is_html: 0,
                        language_mode: 2,
                        translations: this.fb.array([])
                    })
                );
                const fieldTranslations = this.fields.controls[index].get('translations') as UntypedFormArray;
                fl.translations.forEach(translation => {
                    fieldTranslations.push(
                        this.fb.group({
                            language_id: translation.language_id,
                            description: null
                        })
                    );
                });
            }
        });
    }

    getCountries() {
        this.destService.getAllCountries().subscribe(res => {
            this.countries = res;
        });
    }

    getFieldName(fieldId) {
        const name = this.fieldTypes.filter(x => x.id === +fieldId)[0]
            .translations[this.translateService.currentLang === 'de' ? 1 : 0]?.name;
        return name;
    }

    fillFaqsData(faqs: any[]) {
        this.faqs.clear();
        faqs?.forEach((faq, index) => {
            this.faqs.push(
                this.fb.group({
                    id: faq.id,
                    question: [faq.question, Validators.required],
                    answer: [faq.answer, Validators.required],
                })
            );
        });
    }

    getFieldTypes() {
        this.destService.getCountryFieldTypes().subscribe(res => {
            this.fieldTypes = res;
            if (!this.isEditMode) {
                this.initFields();
            }
        });
    }

    addFaq() {
        this.faqs.push(this.fb.group({
            id: null,
            question: [null, Validators.required],
            answer: [null, Validators.required],
        }));
    }

    removeFaq(i) {
        this.faqs.removeAt(i);
    }

    deleteImage(idsList) {
        this.destService.deleteCountryImage(this.country.id, idsList).subscribe((res: any) => {
            this.country.images = res.country.images;
            this.msgService.add({
                severity: 'success', summary: this.translate.transform('countries'),
                detail: this.translate.transform('updateCountryMessage')
            });
            this.checkMainImage();
        });
    }

    submit() {
        // check form validation
        if (!this.countryForm.valid) {
            this.countryForm.markAllAsTouched();
            return false;
        }

        // submit form
        const countryData: Country = {...this.countryForm.getRawValue()};
        countryData.translations[0].name = countryData.name_en;
        countryData.translations[1].name = countryData.name_de;
        countryData.status = this.countryForm.getRawValue().status ? 1 : 0;
        if (countryData.featured_golf_courses == null) {
            countryData.featured_golf_courses = [];
        }
        if (countryData.featured_hotels == null) {
            countryData.featured_hotels = [];
        }
        if (countryData.featured_cities == null) {
            countryData.featured_cities = [];
        }
        if (countryData.featured_products == null) {
            countryData.featured_products = [];
        }
        if (countryData.featured_hotel_products == null) {
            countryData.featured_hotel_products = [];
        }
        if (countryData.featured_golf_holidays == null) {
            countryData.featured_golf_holidays = [];
        }
        countryData.related_countries = countryData?.related_countries?.toString();

        // check if edit mode is active
        if (!this.isEditMode) {
            // add new Country
            this.destService.addCountry(countryData).subscribe(res => {
                if (res) {
                    this.msgService.add({
                        severity: 'success',
                        summary: this.translate.transform('countries'),
                        detail: this.translate.transform('updateCountryMessage')
                    });
                    this.resetPage();
                }
            });
        } else {
            // update Country
            countryData.images = this.imagesUploader.getFormValue();
            this.destService.updateCountry(countryData).subscribe((res: any) => {
                if (res) {
                    this.country = res;
                    if (+this.showWebsiteCurrentValue === 1 && +this.country.show_website === 0) {
                        this.publishToWebsite();
                    } else {
                        this.showWebsiteCurrentValue = this.country.show_website;
                    }
                    this.msgService.add({
                        severity: 'success',
                        summary: this.translate.transform('countries'),
                        detail: this.translate.transform('updateCountryMessage')
                    });
                }
            });
        }
    }

    uploadImages() {
        const files = this.imagesUploader.getFiles();
        const formData: FormData = new FormData();
        if (files.length === 0) {
            return false;
        }
        this.loader.start('uploadImages');
        files.forEach(file => {
            formData.append('images[]', file);
            formData.append('size[]', file.size.toString());
            formData.append('original_file_name[]', file.name);
        });
        this.destService.uploadCountryImages(this.country.id, formData).subscribe((res: any) => {
            this.country.images = res.country.images;
            this.msgService.add({
                severity: 'success', summary: this.translate.transform('countries'),
                detail: this.translate.transform('updateCountryMessage')
            });
            this.imagesUploader.clearUploader();
            this.loader.stop('uploadImages');
            this.checkMainImage();
        });
    }

    setPhoneCode(code: string) {
        this.countryForm.patchValue({
            phone_code: `+${code}`
        });
    }

    addTestimony(event) {
        this.destService.createCountryTestimony(this.country.id, event).subscribe((res: any) => {
            this.country.testimonies = res.country.testimonies;
            this.testimonyComponent.afterAdd();
        });
    }

    changeMainImage(id) {
        this.destService.changeCountryMainImage(this.country.id, {image_id: id}).subscribe((res: any) => {
            this.country.images = res.country.images;
            this.msgService.add({
                severity: 'success', summary: this.translate.transform('countries'),
                detail: this.translate.transform('updateCountryMessage')
            });
        });
    }

    publishToWebsite() {
        const obj = {
            auth: 'GAP_API',
            token: environment.token,
            type: 'country',
            object_id: this.country.id
        };
        this.hotelsService.publishHotelToWebsite(obj).subscribe((res: any) => {
            if (res.update_status === 'Updated Successfully') {
                this.destService.publishCountry(this.country.id).subscribe((resp: any) => {
                    this.country.is_publish_required = 0;
                    this.msgService.add({
                        severity: 'success', summary: this.translate.transform('countries'),
                        detail: this.translate.transform('publishMessage')
                    });
                });
            }
        });
    }

    checkMainImage() {
        let isMain = false;
        this.country.images.forEach(image => {
            if (image.is_main) {
                isMain = true;
            }
        });
        if (!isMain && this.country.images.length > 0) {
            this.changeMainImage(this.country.images[0]?.id);
        }
    }

    getRegionName(regionId) {
        const regionName = this.regions.filter(region => region.id === regionId)[0]
            [this.translateService.currentLang === 'de' ? 'name_de' : 'name_en'];
        return regionName;
    }

    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['./'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

    onWebsiteChanged(event: any) {
        if (event.checked === 0) {
            this.showUnPublishAlert = true;
        }
    }
}
