import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {BreadcrumbService} from '../../../breadcrumb.service';
import {UsersServices} from '../../../_services/users-services';
import {User} from '../../../_models/user';
import {AccountService} from '../../../_services/account.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

    userData: User;
    rememberMe: boolean;
    token: string;
    showChangePassword = false;

    constructor(private service: MessageService, private breadcrumbService: BreadcrumbService,
                private usersService: UsersServices, private accountService: AccountService) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'User profile'}
        ]);
    }

    ngOnInit(): void {
        this.getUserData();
    }

    getUserData() {
        this.accountService.currentUser$.subscribe(res => {
            this.userData = res;
        });
    }
}
