<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid">
            <div class="p-col-10">
                <h5>{{isAddMode? 'Add New Hotel Product' : 'Update Hotel Product'}}</h5>
            </div>
            <div class="p-col-2">
                <app-switch-input [formControl]="form.controls['status']" [label]="'Active'"
                                  [inline]="true" class="float-right"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-2">
                <app-text-input [formControl]="form.controls['name']" [label]="'Product name'"></app-text-input>
            </div>
            <div class="p-col-2">
                <div class="p-field">
                    <label class="full-width">Service
                        <span *ngIf="sharedService.checkRequired(form.get('service'))" class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="service" delay="1000"
                                    [suggestions]="services" (onSelect)="serviceChanged($event)" (onClear)="serviceChanged($event)"
                                    (completeMethod)="searchServices($event)" [forceSelection]="true">
                        <ng-template let-service pTemplate="item">
                            <div>{{service.name}}{{service.code ? ', ' + service.code : ''}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.controls.service.touched && form.controls.service.errors?.required"
                           class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-2">
                <div class="p-field">
                    <label class="full-width">Hotel
                        <span *ngIf="sharedService.checkRequired(form.get('hotel'))" class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="hotel"
                                    [suggestions]="hotels" delay="1000"
                                    (completeMethod)="searchHotels($event)" [forceSelection]="true">
                        <ng-template let-hotel pTemplate="item">
                            <div>{{hotel.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.controls.hotel.touched && form.controls.hotel.errors?.required"
                           class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-2">
                <app-select-input [options]="roomTypes" [formControl]="form.controls['room_type_id']"
                                  [optionLabel]="'name'" [optionValue]="'id'" [label]="'Room type'"></app-select-input>
            </div>
            <div class="p-col-2">
                <app-select-input [options]="views" [formControl]="form.controls['room_view_id']"
                                  [optionLabel]="'name'" [optionValue]="'id'" [label]="'View'"></app-select-input>
            </div>
            <div class="p-col-2">
                <app-select-input [options]="boards" [formControl]="form.controls['room_board_id']"
                                  [optionLabel]="'name'" [optionValue]="'id'" [label]="'Board'"></app-select-input>
            </div>
            <div *ngIf="!isAddMode" class="p-col-2">
                <app-text-input label="Product code" [formControl]="form.get('code')"></app-text-input>
            </div>
            <div class="p-col-2">
                <app-text-input label="Reference code" [formControl]="form.get('ref_code')"></app-text-input>
            </div>
            <div class="p-col-2">
                <div class="p-field">
                    <label>Validity from</label>
                    <p-calendar class="full-width" formControlName="validity_from" [showIcon]="true" placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                </div>
            </div>
            <div class="p-col-2">
                <div class="p-field">
                    <label>Validity to</label>
                    <p-calendar class="full-width" formControlName="validity_to" [showIcon]="true" placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>Contract, Booking & Service Handling</h5>
            </div>
            <div>
                <app-switch-input class="float-right" *ngIf="showServiceInfoButton"
                                  [formControl]="form.controls['use_service_configurations']" [label]="'Use contract configurations'"
                                  [inline]="true"
                                  (changed)="serviceInfoButtonClick($event)"></app-switch-input>
                <a class="view-company-link" *ngIf="form.get('service').value && form.get('use_service_configurations').value == 1"
                   [routerLink]="'/product-setup/contracts/edit/' + form.get('service').value.id" target="_blank">View contract</a>

            </div>
        </div>
        <div class="p-grid">
            <!--<div class="p-col-12 p-md-2">
                <div class="p-field">
                    <label class="full-width">Invoicing <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="invoice_handler_id"
                                    [suggestions]="companies" delay="1000"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.get('invoice_handler_id').touched && form.get('invoice_handler_id').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>-->
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="bookingOptions" [label]="'Booking possible for'"
                                  [formControl]="form.controls['booking_possible_for']"
                                  (optionChanged)="bookingPossibleChanged($event.value)"></app-select-input>
            </div>
            <div class="p-col-12 p-md-10 half-rem-padding">
                <app-select-input [formControl]="form.controls['booking_from_id']" [options]="bookings"
                                  [optionValue]="'id'" [optionLabel]="'name'"
                                  [label]="'Booking from'" [filter]="true"></app-select-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="serviceHandlerTypes" (optionChanged)="serviceHandlerTypeChanged($event.value)"
                                  [label]="'Service handler type'"
                                  [formControl]="form.controls['service_handler_type_id']"
                                  [filter]="true" [optionLabel]="'name'" [optionValue]="'id'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-2"
                 *ngIf="(form.get('service_handler_type_id').value === 4)
                 && form.get('booking_possible_for').value == 'Hotel'">
                <app-switch-input [label]="'Use Destination Hotel'" (changed)="useDestHotelsChanges($event.checked)"
                                  [formControl]="form.controls['use_destination_hotel']"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-4 half-rem-padding">
                <app-select-input [formControl]="form.controls['service_handler_id']" [options]="serviceHandlers"
                                  [optionValue]="'id'" [optionLabel]="'name'"
                                  [label]="'Service Handler'" [filter]="true"></app-select-input>
            </div>
        </div>
    </div>
    <ul class="invalid-controls">
        <li *ngFor="let control of invalidControls">{{control}} is invalid</li>
    </ul>
    <div class="buttons">
        <button pButton type="submit" [disabled]="!form.valid" class="float-right">
            {{!isAddMode ? ('saveChanges' | translate) : 'Create'}}</button>
    </div>
</form>

