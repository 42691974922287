<form [formGroup]='generalForm' (ngSubmit)="submitForm()" class="mt-4">
    <!--<div class="buttons">
        <button pButton class="float-right" type="submit">{{'saveChanges' | translate}}</button>
    </div>-->
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between p-mb-3">
            <div class="p-col">
                <h5>{{'golfCourseDetails' | translate}}</h5>
            </div>
            <div>
                <button pButton class="float-right ml-3" type="submit">{{'saveChanges' | translate}}</button>
                <app-switch-input [formControl]="generalForm.controls['show_website']" [label]="'onWebsite' | translate"
                                  [inline]="true"
                                  class="float-right mr-3"></app-switch-input>
                <app-switch-input [formControl]="generalForm.controls['top']" [label]="'topGolfCourse' | translate"
                                  [inline]="true"
                                  class="float-right mr-3"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-2">
                <app-select-input [formControl]="generalForm.controls['holes']" [label]="'holes' | translate"
                                  [options]="holes" [optionLabel]="'name'"
                                  [optionValue]="'name'"></app-select-input>
            </div>
            <div class="p-col">
                <app-multi-select [options]="difficulties"
                                  [formControl]="generalForm.controls['difficulties']"
                                  [label]="'levelOfDifficulty'| translate" [placeholder]="'selectLevels' | translate"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
           <!-- <div class="p-col">
                <app-multi-select [options]="terrains" [formControl]="generalForm.controls['terrains']"
                                  [label]="'Terrains'" [placeholder]="'selectTerrains' | translate"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
            <div class="p-col">
                <app-multi-select [options]="dresses" [formControl]="generalForm.controls['dresses']"
                                  [label]="'dressCodes' | translate" [placeholder]="'selectDressCode'| translate"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>-->
            <div class="p-col">
                <app-multi-select [options]="playables" [formControl]="generalForm.controls['playables']"
                                  [label]="'playable' | translate" [placeholder]="'selectPlayable' | translate"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
            <div class="p-col-12 half-rem-padding">
                <div class="p-field">
                    <label class="full-width">{{'relatedGolfCourses' | translate}}</label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="related_golf_courses"
                                    [suggestions]="golfCourses" [multiple]="true" delay="1000" appendTo="body"
                                    (completeMethod)="searchGolfCourses($event)" [forceSelection]="true"
                                    [unique]="true">
                        <ng-template let-value pTemplate="selectedItem">
                            <div>
                                <i class="pi pi-circle-fill"
                                   [ngStyle]="{'color': value.publish === 'Published' ? '#4cf94c' :
                                    (value.publish === 'Unpublished' ? '#f15454' : 'orange')}"></i>
                                {{value.name}}
                            </div>
                        </ng-template>
                        <ng-template let-course pTemplate="item">
                            <div>{{course?.name + ', ' +
                            course?.country?.[translateService.currentLang === 'de' ? 'name_de' : 'name_en'] + ', ' +
                            course?.city?.[translateService.currentLang === 'de' ? 'name_de' : 'name_en'] +
                            ' (' + course?.publish + ')'}}</div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]="generalForm.controls['length_men']"
                                [label]="'lengthMen' | translate"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]="generalForm.controls['length_women']"
                                [label]="'lengthWomen' | translate"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]="generalForm.controls['par_men']"
                                [label]="'parMen' | translate"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]="generalForm.controls['par_women']"
                                [label]="'parWomen' | translate"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
            <app-text-input [formControl]="generalForm.controls['slope_from']" [type]="'number'"
                            [label]="'slopeFrom' | translate"></app-text-input>
        </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [formControl]="generalForm.controls['slope_to']" [type]="'number'"
                                [label]="'slopeTo' | translate"></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'locationDetails' | translate}}</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-8">
                <app-text-input [type]="'text'" [label]="'locationLink' | translate" (blurEvent)="getLangLat($event)"
                                [formControl]="generalForm.controls['location_link']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [type]="'text'" [label]="'latitude' | translate"
                                [formControl]="generalForm.controls['latitude']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-text-input [type]="'text'" [label]="'longitude' | translate"
                                [formControl]="generalForm.controls['longitude']"></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'prerequisitesDetails' | translate}}</h5>
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <app-switch-input [formControl]="generalForm.controls['membership']"
                                  [label]="'membership' | translate"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-text-input [type]="'number'" [label]="'hcpMen' | translate"
                                [formControl]="generalForm.controls['hcp_men']"></app-text-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-text-input [type]="'number'" [label]="'hcpWomen' | translate"
                                [formControl]="generalForm.controls['hcp_women']"></app-text-input>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'golfCoursePhotos' | translate}}</h5>
        <div class="p-col-12">
            <app-images-list [limit]="20" [count]="golfCourse?.images?.length"
                             #uploader (delete)="deleteImage($event)"
                             [images]="golfCourse?.images"
                             (mainImageChanged)="changeMainImage($event)"
                             (onSelectImages)="uploadImages()"></app-images-list>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'imagesLinks' | translate}}</h5>
        <div class="p-grid">
            <div class="p-col-12" formArrayName="links_hd_images">
                <div *ngFor="let link of hdLinks.controls; let i=index">
                    <app-text-input [formControl]="link" [label]="('link' | translate)+ ' (' + (i + 1) + ')'"
                                    (delete)="removeHdLinks(i)" (blurEvent)="changePicLink($event, i)"
                                    [showTrash]="true"></app-text-input>
                </div>
                <div class="buttons">
                    <button pButton type="button" (click)="addHdLinks()">{{'addLink' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'logoLink' | translate}}</h5>
        <div class="p-grid">
            <div class="p-col-12 mt-3">
                <div class="image-div" *ngFor="let image of golfCourse?.links_logo_images">
                    <p-image [src]="image?.link ? image.link : '../../assets/layout/images/default-image.png'"
                             alt="{{'image' | translate}}"
                             width="100" height="100" [preview]="true"></p-image>
                </div>
            </div>
            <div class="p-col-12" formArrayName="links_logo_images">
                <div *ngFor="let link of logoLinks.controls; let i=index">
                    <app-text-input [formControl]="link" [label]="'logoLink' | translate"
                                    (delete)="removeLogoLink(i)"
                                    (blurEvent)="changeLogoLink($event, i)"></app-text-input>
                </div>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'fields' | translate}}</h5>
        <div *ngFor="let field of fields.controls; let i = index; let last = last">
            <div class="p-3" [ngClass]="i % 2 == 0 ? 'gray-bg' : null">
                <h6>{{getFieldName(field.get('type_id').value)}}</h6>
                <div *ngIf="field.get('is_html')?.value == 1" class="p-col-12 p-md-12 quill">
                    <div style="display: flow-root">
                        <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                        optionValue="id" optionLabel="name"
                                        [formControl]="field.get('is_html')"></p-selectButton>
                        <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                        [formControl]="field.get('language_mode')"
                                        styleClass="language-switch"></p-selectButton>
                    </div>
                    <app-editor [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                                  [placeholder]="'Content'"></app-editor>
                </div>
                <div *ngIf="field.get('is_html').value == 0" class="p-col-12 p-md-12">
                    <p-selectButton [styleClass]="'html-switch'" [options]="formatOptions"
                                    optionValue="id" optionLabel="name"
                                    [formControl]="field.get('is_html')"></p-selectButton>
                    <p-selectButton [options]="languages" optionLabel="label" optionValue="value"
                                    [formControl]="field.get('language_mode')"
                                    styleClass="language-switch"></p-selectButton>
                    <textarea class="full-width" pInputText
                              [formControl]="field.get('translations')?.controls[
                                      field.get('language_mode')?.value == 1 ? 0 : 1
                                      ]?.get('description')"
                              [placeholder]="'Content'"
                              rows="4" autocomplete="false"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <h5>{{'tags' | translate}}</h5>
        <div class="p-grid">
            <div class="p-col-12">
                        <span class="p-fluid">
                            <p-autoComplete [formControl]="generalForm.get('tags')" [suggestions]="filteredTags"
                                            (completeMethod)="filterTags($event)" field="name" [multiple]="true"
                                            (onSelect)="tagSelected($event)" delay="1000">
                            </p-autoComplete>
                        </span>
            </div>

        </div>
    </div>

    <!--<ul class="invalid-controls">
        <li *ngFor="let control of invalidControls">{{control}} {{'isInvalid' | translate}}</li>
    </ul>
    <div class="buttons">
        <button pButton class="float-right" type="submit">{{'saveChanges' | translate}}</button>
    </div>-->
</form>
