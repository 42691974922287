import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../../_models/user';
import {UsersServices} from '../../../_services/users-services';
import {MessageService} from 'primeng/api';
import {AccountService} from '../../../_services/account.service';
import {Role} from 'src/app/_models/roles-permissions';
import {RolesPermissionService} from 'src/app/_services/roles-permission.service';
import {Observable} from 'rxjs';
import {CompaniesService} from 'src/app/_services/companies-service';
import {catchError, map} from 'rxjs/operators';
import {Company} from '../../../_models/company';
import {SharedService} from '../../../_services/shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AddressBookService} from '../../../_services/address-book.service';
import {ImageUploadComponent} from '../../../_shared/image-upload/image-upload.component';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

    user: User;
    @Input() userData: User;
    userDataForm: UntypedFormGroup;

    @Input() isAddMode: boolean;
    @Input() isProfile: boolean;

    rememberMe: boolean;

    rolesList$: Observable<Role[]>;
    companies: Company[];
    companyChildren: Company[] = [];
    company: Company;
    params: any;
    @ViewChild('uploader', {static: false}) uploader: ImageUploadComponent;

    constructor(private usersServices: UsersServices, private messageService: MessageService,
                private companiesService: CompaniesService, private accountService: AccountService,
                private rolePermissionsAPI: RolesPermissionService, private sharedService: SharedService,
                private route: ActivatedRoute, private router: Router, private addressBookService: AddressBookService) {
    }

    ngOnInit(): void {
        this.user = this.sharedService.getCurrentUser();
        this.initUserDataForm();
        if (this.userData) {
            this.getCompanyChildren(this.userData.company_id);
            this.userDataForm.patchValue(this.userData);
            this.userDataForm.get('company_id').setValue({id: this.userData?.company_id, name: this.userData?.company_name});
        }

        this.getRolesList();

        if (this.isProfile) {
            this.getRememberMeStatus();
        }
        if (this.route.snapshot.queryParamMap.get('company_id')) {
            this.route.queryParams.subscribe(params => {
                    this.params = params;
                    this.userDataForm.patchValue(params);
                }
            );
            this.companiesService.getCompany(+this.params.company_id).subscribe(res => {
                this.company = res;
                this.userDataForm.get('company_id').setValue({id: this.company?.id, name: this.company?.name});
                this.userDataForm.get('company_id').disable();
                this.getCompanyChildren(this.company.id);
            });
        }
    }

    getRememberMeStatus() {
        this.accountService.currentUser$.subscribe((user: User) => {
            this.rememberMe = user.rememberMe;
        });
    }

    initUserDataForm() {
        this.userDataForm = new UntypedFormGroup({
            id: new UntypedFormControl({value: this.userData?.id, disabled: true}),
            username: new UntypedFormControl(this.userData?.username, [Validators.required]),
            email: new UntypedFormControl(this.userData?.email, [Validators.required]),
            role_id: new UntypedFormControl({value: this.userData?.role_id, disabled: this.user.company_id !== 1 ? true : false},
                [Validators.required]),
            company_id: new UntypedFormControl({value: null, disabled: this.user.company_id === 1 ? false : true}
                , [Validators.required]),
            first_name: new UntypedFormControl(this.userData?.first_name, [Validators.required]),
            last_name: new UntypedFormControl(this.userData?.last_name, [Validators.required]),
            mobile_number: new UntypedFormControl(this.userData?.mobile_number),
            department: new UntypedFormControl(this.userData?.department),
            title: new UntypedFormControl(this.userData?.title),
            password: this.isProfile || !this.isAddMode ? new UntypedFormControl({value: this.userData?.password, disabled: true})
                : new UntypedFormControl(this.userData?.password, [Validators.required, Validators.minLength(8)]),
            childs: new UntypedFormControl(),
            address_book_id: new UntypedFormControl()
        });
    }

    submitForm() {
        if (!this.userDataForm.valid) {
            this.userDataForm.markAllAsTouched();
            return false;
        }

        const obj = {...this.userDataForm.getRawValue()};
        obj.mobile_number = obj.mobile_number?.internationalNumber?.toString().replace(/\s/g, '');
        obj.company_id = obj.company_id.id;
        if (obj.childs) {
            obj.childs = obj.childs.map(child => {
                return {child_id: child.id, child_type_id: child.company_type_id};
            });
        } else {
            obj.childs = [];
        }
        if (this.isAddMode) {
            this.usersServices.addUser(obj).pipe(catchError(err => {
                console.error(err);
                this.messageService.add({
                    severity: 'error', summary: 'Add new user',
                    detail: this.formatErrorMsg(err)
                });
                return err;
            })).subscribe((res: any) => {
                this.initUserDataForm();
                this.messageService.add({
                    severity: 'success', summary: 'Add new user',
                    detail: 'New user has been added successfully.'
                });
                this.userDataForm.reset();
                if (this.params?.address_book_id) {
                    obj.user_id = res.user.id;
                    this.addressBookService.updateContact(obj, obj.address_book_id).subscribe(res => {
                        this.router.navigateByUrl('/admin/companies/edit/' + this.company.id + '/users');
                    });
                } else if (this.params?.company_id) {
                    this.router.navigateByUrl('/admin/companies/edit/' + this.company.id + '/users');
                }
            });
        } else if (!this.isAddMode && this.isProfile) {
            this.usersServices.updateUserData(obj).pipe(catchError(err => {
                console.error(err);
                this.messageService.add({
                    severity: 'error', summary: 'Add new user',
                    detail: this.formatErrorMsg(err)
                });
                return err;
            })).subscribe((res: any) => {
                const userUpdate: User = {...res.user};

                userUpdate.rememberMe = this.rememberMe;
                userUpdate.token = res.token;

                this.accountService.setCurrentUser(userUpdate);
                this.messageService.add({
                    severity: 'success', summary: 'Update user profile',
                    detail: 'Your data has been updated successfully.'
                });
            });
        } else if (!this.isAddMode && !this.isProfile) {
            obj.id = this.userData.id;
            this.usersServices.updateUser(obj).pipe(catchError(err => {
                this.messageService.add({
                    severity: 'error', summary: 'Add new user',
                    detail: this.formatErrorMsg(err)
                });
                return err;
            })).subscribe((res: any) => {
                this.messageService.add({
                    severity: 'success', summary: 'Update user profile',
                    detail: 'User Profile has been updated successfully.'
                });
                this.updateStorageData(res.user);
                if (this.userData?.address_book_id) {
                    this.addressBookService.updateContact(obj, obj.address_book_id).subscribe(res => {
                        this.messageService.add({
                            severity: 'success', summary: 'Update AddressBook',
                            detail: 'Contact has been updated successfully.'
                        });
                    });
                }
            });
        }
    }

    formatErrorMsg(err): string {
        let msg = '';
        for (const key in err.error.errors) {
            if (Object.prototype.hasOwnProperty.call(err.error.errors, key)) {
                msg += key + ': ' + err.error.errors[key] + ' ';
            }
        }
        return msg;
    }

    updateUserPhoto(event) {
        event.files.forEach(file => {
            const formData: FormData = new FormData();
            formData.append('image', file);
            if (this.isProfile) {
                this.usersServices.changeProfileImage(formData).subscribe((response: any) => {
                    this.userData.profile_image = response.profile_image;
                    this.messageService.add({
                        severity: 'success', summary: 'Change profile picture',
                        detail: 'Profile picture has been updated successfully'
                    });
                    const user = {...response.user, token: response.token};
                    this.accountService.setCurrentUser(user);
                    this.uploader.clear();
                    this.uploader.showDelete = true;
                });
            } else {
                this.usersServices.changeUserProfileImage(formData, this.userData.id).subscribe((response: any) => {
                    this.userData.profile_image = response.profile_image;
                    this.messageService.add({
                        severity: 'success', summary: 'Change user profile picture',
                        detail: 'User profile picture has been updated successfully'
                    });
                    this.userData.profile_image = response.user.profile_image;
                    this.uploader.clear();
                    this.uploader.showDelete = true;
                });
            }
        });
    }

    deleteUserPhoto() {
        this.usersServices.deleteProfilePicture(this.userData.id).subscribe(res => {
            this.userData.profile_image = null;
            this.messageService.add({
                severity: 'success', summary: 'Delete profile picture',
                detail: 'Profile picture has been Deleted successfully'
            });
            this.uploader.clear();
        });
    }

    getRolesList() {
        this.rolesList$ = this.rolePermissionsAPI.getRoles().pipe(map(roles => {
            return roles.filter(role => role.status !== 0);
        }));
    }

    searchCompanies(event) {
        this.companiesService.searchCompanies(event.query).subscribe((res: any) => {
            this.companies = res.companies;
        });
    }

    updateStorageData(newUserData) {
        let currentUser;
        if (localStorage.getItem('user')) {
            currentUser = JSON.parse(localStorage.getItem('user'));
            if (newUserData.id == currentUser.id) {
                newUserData.token = currentUser.token;
                localStorage.setItem('user', JSON.stringify(newUserData));
            }
        } else {
            currentUser = JSON.parse(sessionStorage.getItem('user'));
            if (newUserData.id == currentUser.id) {
                newUserData.token = currentUser.token;
                sessionStorage.setItem('user', JSON.stringify(newUserData));
            }
        }
    }

    getCompanyChildren(companyId) {
        this.companiesService.getAllCompanyChildren(companyId).subscribe(res => {
            this.companyChildren = res;
            if (this.userData) {
                /*const children = this.companyChildren.filter((element: any) => this.userData.childs.some(child => {
                    return child.id == element.child_id;
                }));*/
                const children = this.companyChildren.filter(companyChild =>
                    this.userData.childs.some(userChild => companyChild.id === userChild.child_id));
                this.userDataForm.get('childs').setValue(children);
            }
        });
    }
}
