<p-toast></p-toast>

<form (ngSubmit)="submit(roleForm)" #roleForm="ngForm">
    <div class="p-grid p-jc-between">
        <div class="p-col">
            <div class="p-field">
                <label>Name</label>
                <input class="full-width" type="text" pInputText [(ngModel)]="role.name" name="name" required>
            </div>
        </div>
        <div>
            <div class="p-field">
                <p>{{role.status > 0 ? ('active' | translate) : ('inactive' | translate)}}</p>
                <p-inputSwitch [(ngModel)]="role.status" name="status" required [trueValue]="1" [falseValue]="0"></p-inputSwitch>
            </div>
        </div>
        <div class="p-col-12">
            <p-tree
                name="permissions"
                [(selection)]="role.permissions"
                [value]="permissionsList"
                selectionMode="checkbox"
                [loading]="isLoadingPermissionsTree">
                
                <ng-template let-node pTemplate="default">
                    <p [ngClass]="{'node-grey': node.status === 0}">{{node.name}}</p>
                </ng-template>
            </p-tree>
        </div>
    </div>
    <div class="buttons">
        <button pButton type="submit" [disabled]="!roleForm.valid || role.permissions.length < 1">
            {{isEditMode ? ('saveChanges' | translate) : 'Create'}}
        </button>
    </div>
</form>

