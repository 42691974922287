<p-columnFilter [field]="fieldName" matchMode="in" [showClearButton]="false" [showAddButton]="false" [showApplyButton]="false" [showMatchModes]="false" [showOperator]="false" display="menu">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-scrollPanel [style]="{width: '100%', maxHeight: '200px'}">
            <p *ngFor="let item of filterOptions" class="p-d-flex p-ai-center">
                <label>
                    <p-checkbox [name]="fieldName + 'Model'" [value]="isBoolean ? item.val : item"
                                [(ngModel)]="filterValues" (onChange)="filter(filterValues)"></p-checkbox>
                    &nbsp;<span *ngIf="!isColor">{{(isBoolean ?
                    translate.transform(item.name) : translate.transform(item))}}</span>
                    <span [ngStyle]="{'display': 'inline-block', 'background-color': item, 'padding': '8px'}" *ngIf="isColor"></span>
                </label>
            </p>
        </p-scrollPanel>

        <button pButton pRipple type="button" label="{{'clear' | translate}}"
                icon="pi pi-filter-slash" iconPos="left"
                class="p-button-outlined"
                style="max-width: 100px; margin-bottom: -35px"
                (click)="filter([]); filterValues = []"
        ></button>
    </ng-template>
</p-columnFilter>
