import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Integration} from '../_models/integration';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HttpErrrorHandler} from '../_classes/httpErrorHandler';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  baseUrl = environment.apiUrl;
  handleError = new HttpErrrorHandler();
  constructor(
      private http: HttpClient
  ) {
    console.log('Integration Service running!');
  }

  getIntegrations(): Observable<Integration[]> {
    return this.http.get<{status: boolean; integrations: Integration[]}>(`${this.baseUrl}integration/providers`)
        .pipe(catchError(this.handleError.handleError))
        .pipe(map(res => res.integrations));
  }

  createIntegration(integration: Integration): Observable<boolean> {
    return this.http.post<{status: boolean; integration: Integration}>(`${this.baseUrl}integration/providers`, integration)
        .pipe(catchError(this.handleError.handleError))
        .pipe(map(res => res.status));
  }

  updateIntegration(integration: Integration, id: number): Observable<boolean> {
    return this.http.put<{status: boolean; integration: Integration}>(`${this.baseUrl}integration/providers/${id}`, integration)
        .pipe(catchError(this.handleError.handleError))
        .pipe(map(res => res.status));
  }

  deleteIntegration(id: number): Observable<boolean> {
    return this.http.delete<{status: boolean; integration: Integration}>(`${this.baseUrl}integration/providers/${id}`)
        .pipe(catchError(this.handleError.handleError))
        .pipe(map(res => res.status));
  }
}
