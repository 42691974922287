<div class="logo p-mt-3">
    <img src="../../../../../assets/layout/images/pages/lock-icon.png" alt="Golf Globe"/>
    <h5 class="mt-2">Change Password</h5>
</div>
<form [formGroup]="changePassForm" (ngSubmit)="submitForm()">

    <div class="p-float-label p-mt-5">
        <input class="full-width" type="password" pPassword formControlName="old_password" [autocomplete]="false">
        <label>Old password</label>
    </div>
        <small *ngIf="changePassForm.controls['old_password'].touched &&
        changePassForm.controls['old_password'].errors?.minlength"
               class="p-error">This field requires at least 8 characters length</small>
    <small *ngIf="changePassForm.controls['old_password'].touched &&
        changePassForm.controls['old_password'].errors?.required"
           class="p-error">{{'required' | translate}}</small>


    <div class="p-float-label p-mt-5">
        <input class="full-width" type="password" pPassword formControlName="password" [autocomplete]="false">
        <label>New password</label>
    </div><small *ngIf="changePassForm.controls['password'].touched &&
        changePassForm.controls['password'].errors?.minlength"
                 class="p-error">This field requires at least 8 characters length</small>
    <small *ngIf="changePassForm.controls['password'].touched &&
        changePassForm.controls['password'].errors?.required"
           class="p-error">required</small>



    <div class="p-float-label p-mt-5">
        <input class="full-width" type="password" pPassword formControlName="password_confirmation" [autocomplete]="false">
        <label>Confirm password</label>
    </div>
    <small *ngIf="changePassForm.controls['password_confirmation'].touched &&
        changePassForm.controls['password_confirmation'].errors?.isMatching"
           class="p-error">Confirm password does not match new password</small>
    <small *ngIf="changePassForm.controls['password_confirmation'].touched &&
        changePassForm.controls['password_confirmation'].errors?.required"
           class="p-error">{{'required' | translate}}</small>



    <div class="buttons p-mt-5">
        <button pButton type="submit" label="{{'saveChanges' | translate}}" class="p-mr-2" icon="pi pi-check"
        [disabled]="!changePassForm.valid"></button>
        <button pButton type="button" (click)="cancel()" label="cancel" icon="pi pi-times"
        class="p-button-outlined"></button>
    </div>
</form>
