<p-toast></p-toast>

<div class="card">
    <p-table #dt dataKey="id"
        [value]="regions$ | async"
        [(selection)]="selectedRegions"
        [rowHover]="true"
        [rows]="100"
        [paginator]="true"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
        [showCurrentPageReport]="true"  [showJumpToPageInput]="true" [rowsPerPageOptions]="[10, 50, 100, 200]"
        [globalFilterFields]="['name_en', 'name_de', 'code']"
        [scrollable]="true" [scrollDirection]="'both'"
    >
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <div class="full-width-mobile p-mb-4 p-mb-md-0">
                    <h5 class="p-m-1 float-left">{{'continents' | translate}}</h5>

                    <button pButton pRipple label="" icon="pi pi-plus" class="p-button-rounded p-button-primary p-ml-2" (click)="addNew()" *ngIf="checkPermission('1636370630870')"></button>
                </div>

                <button pButton pRipple label="{{'export' | translate}}" (click)="exportExcel()" icon="pi pi-upload" class="p-button-help float-right" *ngIf="checkPermission('1636370709647')"></button>

              <span class="p-input-icon-left float-right p-mr-md-1 p-mt-2 p-mt-md-0 full-width-mobile">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="full-width"
                           (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'search' | translate}}"/>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 120px" pSortableColumn="name_en">Name EN <p-sortIcon field="name_en"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="name_de">Name DE <p-sortIcon field="name_de"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="code">Code <p-sortIcon field="code"></p-sortIcon></th>
                <th style="width: 120px" pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                <th style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370646637') || checkPermission('1636370661741') || checkPermission('1636370680314')"
                >{{'actions' | translate}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-region>
            <tr>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(region)">
                    {{region.name_en}}
                </td>
                <td style="width: 120px; cursor: pointer; text-decoration: underline" (click)="edit(region)">
                    {{region.name_de}}
                    <!--<a [routerLink]="'/destinations/countries/region/' + region.id">{{region.name}}</a>-->
                </td>
                <td style="width: 120px">
                    {{region.code}}
                </td>
                <td style="width: 120px" class="status-td">
                    <!--<p-inputSwitch [(ngModel)]="region.status" (onChange)="changeRegionStatus(region)"></p-inputSwitch>-->
                    <span class="status-txt">{{region.status === 1 || region.status? ('active' | translate) : ('inactive' | translate)}}</span>
                </td>
                <td style="width: 120px" alignFrozen="right" pFrozenColumn
                    *ngIf="checkPermission('1636370646637') || checkPermission('1636370661741') || checkPermission('1636370680314')"
                >
                    <div class="btn-group" dropdown>
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v" dropdownToggle dropdown-toggle
                                class="p-button-rounded p-button-text"></button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngIf="checkPermission('1636370661741')">
                                <a class="dropdown-item" (click)="edit(region)">{{'edit' | translate}}</a></li>
                            <!--<li role="menuitem" *ngIf="checkPermission('1636370680314')">
                                <a class="dropdown-item" (click)="delete(region)">{{'delete' | translate}}</a></li>-->
                        </ul>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

