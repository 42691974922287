import {Component, OnInit} from '@angular/core';
import {Company} from '../../_models/company';
import {BreadcrumbService} from '../../breadcrumb.service';
import {CompaniesService} from '../../_services/companies-service';
import {AccountService} from '../../_services/account.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-company-profile',
    templateUrl: './company-profile.component.html',
    styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {

    companyData: Company;
    canEdit: boolean;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private companiesService: CompaniesService,
        private accountService: AccountService,
        private route: ActivatedRoute
    ) {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: '/'},
            {label: 'Company profile'}
        ]);
    }

    ngOnInit(): void {
        this.getCompanyData();
        // check if can edit
        if (this.checkPermission('1636370400126')) {
            this.canEdit = true;
        } else {
            this.canEdit = false;
        }
    }

    getCompanyData() {
        this.companiesService.getCompanyProfileData().subscribe((res: any) => {
            this.companyData = res.company;
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }
}
