import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpErrrorHandler} from '../../_classes/httpErrorHandler';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ProductService} from '../../_models/product-service';
import {ProductSeason} from '../../_models/product-season';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductAPIService {

    baseUrl = environment.apiUrl;
    handleError = new HttpErrrorHandler();

    constructor(private http: HttpClient) {
    }

    getPaginatedServices(pageNumber = 1, pagination, searchKeyword) {
        return this.http.get(this.baseUrl + 'product/services?page=' + pageNumber + '&pagination=' + pagination +
            '&search=' + searchKeyword);
    }

    getServicesByCompany(id): Observable<ProductService[]> {
        return this.http.get(`${this.baseUrl}product/services/get/all?&company_id=${id}`)
            .pipe(catchError(this.handleError.handleError))
            .pipe(map((res: any) => res.services));
    }

    getService(id: number): Observable<ProductService> {
        return this.http.get<{ status: boolean; service: ProductService }>(`${this.baseUrl}product/services/${id}`)
            .pipe(catchError(this.handleError.handleError))
            .pipe(map(res => res.service));
    }

    addService(service: ProductService): Observable<boolean> {
        return this.http.post<{ status: boolean; service: ProductService }>(`${this.baseUrl}product/services`, service)
            .pipe(catchError(this.handleError.handleError))
            .pipe(map(res => res.status));
    }

    updateService(service) {
        return this.http.put<{ status: boolean; service: ProductService }>(`${this.baseUrl}product/services/${service.id}`, service);
    }

    deleteService(id): Observable<boolean> {
        return this.http.delete<{ status: boolean; }>(`${this.baseUrl}product/services/${id}`)
            .pipe(catchError(this.handleError.handleError))
            .pipe(map(res => res.status));
    }

    searchService(key: string, companyId?): Observable<ProductService[]> {
        return this.http.get<{ status: boolean; services: ProductService[] }>(this.baseUrl +
            'product/services/get/all?active=1&search=' + key + (companyId ? '&company_id=' + companyId : ''))
            .pipe(catchError(this.handleError.handleError))
            .pipe(map(res => res.services));
    }

    // Seasons
    getAllSeason(): Observable<any> {
        return this.http.get(this.baseUrl + 'seasons')
            .pipe(map((res: any) => res.seasons));
    }

    getCompanySeasons(id): Observable<any> {
        return this.http.get(this.baseUrl + 'seasons?company_id=' + id)
            .pipe(map((res: any) => res.seasons));
    }

    getSeason(id: number) {
        return this.http.get(this.baseUrl + 'seasons/' + id);
    }

    addSeason(obj) {
        return this.http.post(this.baseUrl + 'product/services/seasons/' + obj.service_id, obj);
    }

    updateSeason(obj) {
        return this.http.put(this.baseUrl + 'seasons/' + obj.id, obj);
    }

    deleteSeason(id) {
        return this.http.delete(this.baseUrl + 'seasons/' + id);
    }

    getServicesByCity(cityId) {
        return this.http.get(this.baseUrl + 'product/services/get/all?city_id=' + cityId).pipe(map((res: any) => {
            return res.services;
        }));
    }
    getServicesByBookingCode(code) {
        return this.http.get(this.baseUrl + 'product/services/get/all?active=1&booking_code=' +
        code.booking_code + '&booking_code_type=' + code.type).pipe(map((res: any) => {
            return res.services;
        }));
    }

    getCompanyAndSPBoobingCodes(companyId: number) {
        return this.http.get(this.baseUrl + 'companies/get/booking-codes?company_id=' + companyId)
            .pipe(map((res: any) => {
                res.childs.map((code: any) => {
                    code.displayName = code.booking_code + ' - ' + code.name;
                });
                return res.childs;
            }));
    }
}
