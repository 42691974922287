import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TimelineModule} from 'primeng/timeline';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {GMapModule} from 'primeng/gmap';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {TreeSelectModule} from 'primeng/treeselect';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AppCodeModule} from '../app.code.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TextInputComponent} from '../_form/text-input/text-input.component';
import {SelectInputComponent} from '../_form/select-input/select-input.component';
import {SwitchInputComponent} from '../_form/switch-input/switch-input.component';
import { ImagesListComponent } from './images-list/images-list.component';
import {ImageModule} from 'primeng/image';
import {TextAreaComponent} from '../_form/text-area/text-area.component';
import {MultiSelectComponent} from '../_form/multi-select/multi-select.component';
import { GCalendarComponent } from './calendar/calendar.component';
import {CalendarModule} from 'primeng/calendar';
import { NgApexchartsModule } from 'ng-apexcharts';

/*import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import rrulePlugin from '@fullcalendar/rrule';*/
import { GgTreeComponent } from './gg-tree/gg-tree.component';
import {CompanyFormComponent} from './company-form/company-form.component';
import {ImageUploadComponent} from './image-upload/image-upload.component';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import { FilterComponent } from './filter/filter.component';
import {QuillModule} from 'ngx-quill';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {TreeViewComponent} from './tree-view/tree-view.component';
import {CountriesTreeComponent} from './countries-tree/countries-tree.component';
import {EditPlayersComponent} from '../request/requests-view/edit-players/edit-players.component';
import {RequestStageThreeComponent} from '../request/stages/request-stage-three/request-stage-three.component';
import {SafeUrlPipe} from '../_pipes/unsafe.pipe';
import {PlayersFormComponent} from '../request/stages/request-stage-one/players-form/players-form.component';
import {NewsSliderComponent} from '../news-slider/news-slider.component';
import {ProductServicesComponent} from '../product-setup/product-services/product-services.component';
import {
    ProductServicesTableComponent
} from '../product-setup/product-services/product-services-table/product-services-table.component';
import {
    ProductServicesTreeComponent
} from '../product-setup/product-services/product-services-tree/product-services-tree.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
    ProductServicesFormComponent
} from '../product-setup/product-services/product-services-form/product-services-form.component';
import {NgxPrintModule} from 'ngx-print';
import {QRCodeModule} from 'angularx-qrcode';
import {PlayersSectionComponent} from '../request/requests-view/players-section/players-section.component';
import {DestinationFormComponent} from '../_form/destination-form/destination-form.component';
import {TranslateModule, TranslateLoader, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayCorrectionService} from '../_services/overlay-correction.service';
import {EditorComponent} from '../_form/editor/editor.component';

/*FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
    momentTimezonePlugin,
    rrulePlugin
]);*/


@NgModule({
    declarations: [
        TextInputComponent,
        SelectInputComponent,
        SwitchInputComponent,
        ImagesListComponent,
        ImagesListComponent,
        MultiSelectComponent,
        TextAreaComponent,
        GCalendarComponent,
        GgTreeComponent,
        CompanyFormComponent,
        ImageUploadComponent,
        ForgotPasswordComponent,
        FilterComponent,
        TreeViewComponent,
        CountriesTreeComponent,
        EditPlayersComponent,
        RequestStageThreeComponent,
        SafeUrlPipe,
        PlayersFormComponent,
        NewsSliderComponent,
        ProductServicesComponent,
        ProductServicesTableComponent,
        ProductServicesTreeComponent,
        ProductServicesFormComponent,
        PlayersSectionComponent,
        DestinationFormComponent,
        EditorComponent
    ],
    imports: [
        CommonModule,
        TableModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        NgxUiLoaderModule,
        PasswordModule,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        TreeSelectModule,
        AppCodeModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ImageModule,
        /*FullCalendarModule,*/
        CalendarModule,
        DragDropModule,
        QuillModule.forRoot(),
        TriStateCheckboxModule,
        GMapModule,
        NgxMaterialTimepickerModule,
        NgxPrintModule,
        QRCodeModule,
        NgApexchartsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            },
            isolate: false
        })
    ],
    exports: [
        TableModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        NgxUiLoaderModule,
        PasswordModule,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        TreeSelectModule,
        BsDropdownModule,
        AppCodeModule,
        HttpClientModule,
        TextInputComponent,
        SelectInputComponent,
        SwitchInputComponent,
        ImagesListComponent,
        MultiSelectComponent,
        TextAreaComponent,
        GCalendarComponent,
        /*FullCalendarModule,*/
        CalendarModule,
        ImageModule,
        GgTreeComponent,
        CompanyFormComponent,
        ImageUploadComponent,
        DragDropModule,
        ForgotPasswordComponent,
        FilterComponent,
        QuillModule,
        GMapModule,
        TriStateCheckboxModule,
        TreeViewComponent,
        CountriesTreeComponent,
        EditPlayersComponent,
        RequestStageThreeComponent,
        SafeUrlPipe,
        PlayersFormComponent,
        NewsSliderComponent,
        ProductServicesComponent,
        ProductServicesTableComponent,
        ProductServicesTreeComponent,
        ProductServicesTreeComponent,
        NgxMaterialTimepickerModule,
        ProductServicesFormComponent,
        NgxPrintModule,
        QRCodeModule,
        PlayersSectionComponent,
        DestinationFormComponent,
        TranslateModule,
        NgApexchartsModule,
        EditorComponent
    ],
    providers: [TranslatePipe, OverlayCorrectionService],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
    constructor(private overlayCorrectionService: OverlayCorrectionService) {
        this.overlayCorrectionService.init();
    }
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
