<div class="card toolbar-demo">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <h5>Add New GFP Bulk</h5>
        <div class="p-grid">
            <div class="p-col-3">
                <app-multi-select [options]="services" [formControl]="form.controls['services']"
                                  [optionLabel]="'name'"
                                  [label]="'Services'" [placeholder]="'Select services'"></app-multi-select>
            </div>
            <div class="p-col-3">
                <app-multi-select [options]="roomTypes" [formControl]="form.controls['room_types']"
                                  [optionLabel]="'name'"
                                  [label]="'Room types'" [placeholder]="'Select room types'"></app-multi-select>
            </div>
            <div class="p-col-3">
                <app-multi-select [options]="views" [formControl]="form.controls['views']"
                                  [optionLabel]="'name'"
                                  [label]="'Views'" [placeholder]="'Select views'"></app-multi-select>
            </div>
            <div class="p-col-3">
                <app-multi-select [options]="boards" [formControl]="form.controls['boards']"
                                  [optionLabel]="'name'"
                                  [label]="'Boards'" [placeholder]="'Select boards'"></app-multi-select>
            </div>
        </div>
        <div class="buttons">
            <button pButton type="submit" [disabled]="!form.valid">Generate Products</button>
        </div>
    </form>
    <p-divider></p-divider>
    <p-table #dt [value]="generatedProducts" [rows]="20" [paginator]="true"
             [globalFilterFields]="['productName', 'service', 'roomType', 'view', 'board']"
             [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
             currentPageReportTemplate="{{'showing' | translate}} {first} {{'to' | translate}} {last} {{'of' | translate}}
         {totalRecords} {{'entries' | translate}}"
             [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="p-ai-center">
                <h5 class="p-m-1 float-left">Generated Products</h5>
                <span class="p-input-icon-left float-right p-mr-1">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search..."/>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="productName">Product name
                    <p-sortIcon field="productName"></p-sortIcon>
                </th>
                <th pSortableColumn="service">Service
                    <p-sortIcon field="service"></p-sortIcon>
                </th>
                <th pSortableColumn="roomType">Room type
                    <p-sortIcon field="roomType"></p-sortIcon>
                </th>
                <th pSortableColumn="view">View
                    <p-sortIcon field="view"></p-sortIcon>
                </th>
                <th pSortableColumn="board">Board
                    <p-sortIcon field="board"></p-sortIcon>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-index>
            <tr>
                <td pEditableColumn>
                    <p-cellEditor (click)="openNameEdit(product)">
                        <ng-template pTemplate="input">
                            <input class="full-width" type="text" pInputText [(ngModel)]="productName"
                                   (blur)="inlineUpdate(index)" (keydown.enter)="inlineUpdate(index)">
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{product.name}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>{{product.service_name}}</td>
                <td>{{product.room_type_name}}</td>
                <td>{{product.view_name}}</td>
                <td>{{product.board_name}}</td>
                <td>
                    <button pButton pRipple type="button" icon="pi pi-trash" (click)="delete(index)"
                            class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div class="buttons">
    <button pButton type="button" (click)="saveChanges()" [disabled]="generatedProducts.length == 0">
        Create New
    </button>
</div>

