import {Component, EventEmitter, Input, Output, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css']
})
export class TextAreaComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() rows = 1;
  @Input() placeholder = '';
  @Input() showTrash = false;
  @Output() delete = new EventEmitter();
  @Output() blurEvent = new EventEmitter();

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  checkRequired(control) {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
  }

  writeValue(obj: any) {
  }

  registerOnChange(fn: any) {
  }

  registerOnTouched(fn: any) {
  }
  onBlur(event) {
    this.blurEvent.emit(event.target.value);
  }

  remove() {
    this.delete.emit();
  }

}
