import {Component, OnInit} from '@angular/core';
import {User} from '../../_models/user';
import {BreadcrumbService} from '../../breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersServices} from '../../_services/users-services';
import {AccountService} from '../../_services/account.service';
import GgTableCustomFN from '../../_classes/ggTableCustomFN';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    user: User;
    selectedUsers: User[];
    users: User[];
    menuActions = ['View', 'Edit', 'Delete'];

    // Filter vars
    tableHelper = new GgTableCustomFN();
    filters = {
        department: [],
        title: []
    };
    pagination: any;
    searchValue = '';

    companyId: number;

    constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService,
                private confirmationService: ConfirmationService, public usersServices: UsersServices,
                private router: Router, private activatedRoute: ActivatedRoute,
                private accountService: AccountService, public translate: TranslatePipe) {
    }

    ngOnInit(): void {
        if (this.activatedRoute.parent.snapshot.paramMap.get('id')) {
            this.companyId = +this.activatedRoute.parent.snapshot.paramMap.get('id');
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Companies', routerLink: '/admin/companies'},
                {label: 'Company Users'}
            ]);
            this.getCompanyUsers();
        } else {
            this.breadcrumbService.setItems([
                {label: 'Dashboard', routerLink: '/'},
                {label: 'Users'}
            ]);
            this.getPaginatedUsers(1, 10, this.searchValue);
        }
    }

    getPaginatedUsers(pageNumber, pagination, searchKeyword) {
        this.usersServices.getPaginatedUsers(pageNumber, pagination, searchKeyword).subscribe((res: any) => {
            this.users = res.users.data;
            this.pagination = res.users.meta;
        });
    }

    getCompanyUsers() {
        this.usersServices.getCompanyUsers(this.companyId).subscribe((res: any) => {
            this.users = res.users;
        });
    }

    addNew() {
        if (this.activatedRoute.parent.snapshot.paramMap.get('id')) {
            const companyId = this.activatedRoute.parent.snapshot.paramMap.get('id');
            this.router.navigate(['admin/users/add'], {queryParams: {company_id: companyId}});
        } else {
            this.router.navigate(['admin/users/add']);
        }
    }


    edit(user: User) {
        this.router.navigate(['admin/users/edit', user.id]);
    }

    delete(user: User, index: number) {
        this.confirmationService.confirm({
            message: this.translate.transform('confirmMessage'),
            accept: () => {
                this.usersServices.deleteUser(user.id).subscribe(res => {
                    this.users.splice(index, 1);
                    this.messageService.add({
                        severity: 'success', summary: 'Delete user',
                        detail: 'User has been deleted successfully'
                    });
                });
            },
            acceptLabel: this.translate.transform('yes'),
            rejectLabel: this.translate.transform('no'),
            header: this.translate.transform('confirmation')
        });
    }

    checkPermission(permissionCode: string): boolean {
        return this.accountService.checkPermission(permissionCode);
    }

    exportExcel() {
        if (!this.selectedUsers || this.selectedUsers.length < 1) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.transform('noExportMessage')
            });
            return;
        }
        this.tableHelper.exportExcel(this.selectedUsers);
    }

    searchUsers() {
        this.getPaginatedUsers(1, this.pagination.per_page, this.searchValue);
    }

    pageChanged(event) {
        this.getPaginatedUsers(event.page + 1, +event.rows, this.searchValue);
    }
}
