<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}"
     (click)="appMain.onSidebarClick($event)"
     (mouseover)="appMain.sidebarActive=true" (mouseleave)="appMain.sidebarActive=false">
    <div class="sidebar-logo">
        <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="appMain.onToggleMenuClick($event)">
            <i class="pi pi-lock" *ngIf="appMain.staticMenuActive"></i>
            <i class="pi pi-lock-open" *ngIf="!appMain.staticMenuActive"></i>
        </a>
        <a routerLink="/">
            <img alt="logo" src="assets/layout/images/pages/golf-logo-min.png"/>
            <span class="app-name">GOLF GLOBE</span>
        </a>
    </div>

    <div class="layout-menu-container" (click)="onMenuClick()">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li *ngIf="isUserAllowed(item) && checkPermission(item.permissionsData)"
                    app-menuitem [item]="item" [index]="i" [root]="true"
                ></li>
            </ng-container>
        </ul>
    </div>
</div>
