import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AccountService} from '../../../../_services/account.service';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    @Output() cancelRegister = new EventEmitter();
    changePassForm: UntypedFormGroup;

    constructor(private accountService: AccountService, public messageService: MessageService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.changePassForm = new UntypedFormGroup({
            old_password: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)]),
            password: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)]),
            password_confirmation: new UntypedFormControl(null,
                [Validators.required, this.matchValues('password')]),
        });
    }

    matchValues(matchTo: string): ValidatorFn {
        return (control: AbstractControl) => {
            return control?.value === control?.parent?.controls[matchTo].value
                ? null : {isMatching: true};
        };
    }

    submitForm() {
        this.accountService.changePassword(this.changePassForm.value).subscribe(res => {
            this.messageService.add({severity: 'success', summary: 'Change password',
            detail: 'Your password has been changed successfully'});
            this.changePassForm.reset();
            this.cancelRegister.emit(false);
        }, error => {
            this.messageService.add({
                severity: 'error', summary: 'Change password',
                detail: 'You have entered a wrong old password!'
            });
        });
    }

    cancel() {
        this.cancelRegister.emit(false);
    }

}
