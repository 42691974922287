import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GGTreeNode} from '../../_models/GGTreeModel';
import {Tree} from 'primeng/tree';
import {DestinationsService} from '../../destination/_services/destinations.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-tree-view',
    templateUrl: './tree-view.component.html',
    styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit, OnChanges {

    @Input() object: any;
    @Input() l4Data: any;
    @Input() l5Data: any;
    destinationsTree: GGTreeNode[] = [];
    isLoadingTree = false;
    @Output() selectedCity: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedL4Item: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedL5Item: EventEmitter<any> = new EventEmitter<any>();
    currentIndex: any[] = [];
    filterValue = '';
    @ViewChild('tree', {static: true}) tree: Tree;
    regions: any[];

    constructor(private destinationService: DestinationsService, private elem: ElementRef,
                private translationService: TranslateService) {
    }

    ngOnInit() {
        this.getAllRegions();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.tree.resetFilter();
        if (changes.l4Data && changes.l4Data.currentValue) {
            /*this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].children = changes.l4Data.currentValue;
            this.destinationsTree[this.currentIndex[0]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].expanded = true;*/
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children = changes.l4Data.currentValue;
            this.destinationsTree[this.currentIndex[0]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]].expanded = true;
            this.refillFilterInput();
        } else if (changes.l5Data && changes.l5Data.currentValue) {
            /*this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].children[this.currentIndex[3]].children = changes.l5Data.currentValue;
            this.destinationsTree[this.currentIndex[0]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].children[this.currentIndex[3]].expanded = true;*/
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].children = changes.l5Data.currentValue;
            this.destinationsTree[this.currentIndex[0]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]].expanded = true;
            this.destinationsTree[this.currentIndex[0]].children[this.currentIndex[1]]
                .children[this.currentIndex[2]].expanded = true;
            this.refillFilterInput();
        }
    }

    getAllRegions() {
        this.destinationService.getAllCountries().subscribe((res: any) => {
            this.buildTree(res);
        });
    }

    buildTree(countries) {
        /*regions.forEach((region, regionIndex) => {
            let treeRegion: any = {};
            treeRegion.id = region.id;
            treeRegion.label = region.name;
            treeRegion.code = region.code;
            treeRegion.children = [];
            treeRegion.level = 0;
            treeRegion.indexes = [regionIndex];
            treeRegion.expandedIcon = 'pi pi-folder-open';
            treeRegion.collapsedIcon = 'pi pi-folder';
            treeRegion.key = 'region-' + region.id;*/

        let treeCountry: any = {};
        /*region.countries.forEach((country, countryIndex) => {*/
        countries.forEach((country, countryIndex) => {
            treeCountry.id = country.id;
            treeCountry.label = country.code + ' - ' +
                (this.translationService.currentLang === 'de' ? country.name_de : country.name_en);
            treeCountry.code = country.code;
            treeCountry.level = 1;
            treeCountry.children = [];
            treeCountry.indexes = [countryIndex];
            /*treeCountry.indexes = [regionIndex, countryIndex];*/
            treeCountry.key = 'country-' + country.id;

            let treeCity: any = {};
            country.cities.forEach((city, cityIndex) => {
                treeCity.id = city.id;
                treeCity.label = city.code + ' - ' +
                    (this.translationService.currentLang === 'de' ? city.name_de : city.name_en);
                treeCity.code = city.code;
                treeCity.level = 2;
                /*treeCity.indexes = [regionIndex, countryIndex, cityIndex];*/
                treeCity.indexes = [countryIndex, cityIndex];
                treeCity.children = [];
                treeCity.key = 'city-' + city.id;

                treeCountry.children.push(treeCity);
                treeCity = {};
            });
            /*treeRegion.children.push(treeCountry);*/
            this.destinationsTree.push(treeCountry);
            treeCountry = {};
        });
        /*this.destinationsTree.push(treeRegion);
        treeRegion = {};
    });*/
    }

    nodeSelected(event) {
        if (event.node.level === 2 && event.node.children.length === 0) {
            this.currentIndex = event.node.indexes;
            this.selectedCity.emit(event.node);
        } else if ((event.node.level === 3 && event.node.indexes.length === 0) || event.node.level === 4) {
            this.selectedL4Item.emit(event.node);
        } else if (event.node.level === 3 && event.node.indexes.length > 0) {
            this.currentIndex = event.node.indexes;
            this.selectedL5Item.emit(event.node);
        }
    }

    onFilter(event) {
        this.filterValue = event.filter;
    }

    refillFilterInput() {
        this.tree._filter(this.filterValue);
        const elements = this.elem.nativeElement.querySelectorAll('.p-tree-filter');
        elements[0].value = this.filterValue;
    }
}
