<div *ngFor="let player of _players.controls; let pIndex = index" class="p-grid">
    <div class="col-fixed" style="width:100px; padding: 30px">
        <p style="margin-top: 14px; font-size: 14px;">({{pIndex + 1}})</p>
    </div>
    <div class="p-md-col half-rem-padding">
        <app-text-input [label]="'First name'" [formControl]="player.get('first_name')"
                        (blurEvent)="updatePlayer(pIndex)"></app-text-input>
    </div>
    <div class="p-md-col half-rem-padding">
        <app-text-input [label]="'Last name'" [formControl]="player.get('last_name')"
                        (blurEvent)="updatePlayer(pIndex)"></app-text-input>
    </div>
    <div class="p-md-col half-rem-padding">
        <app-select-input [options]="genderOptions" [formControl]="player.get('gender')"
        (optionChanged)="updatePlayer(pIndex)" [label]="'Gender'"></app-select-input>
        <!--<label>Gender <span class="p-error">*</span></label>
        <p-dropdown [options]="genderOptions" (onBlur)="updatePlayer(pIndex)" (onChange)="selectedGender($event)"
                    [formControl]="player.get('gender')" [appendTo]="'body'">
            <ng-template let-option pTemplate="selectedItem">
                {{option === 'Male' ? 'M' : 'F'}}
            </ng-template>
        </p-dropdown>-->
    </div>
    <div class="p-md-col half-rem-padding">
        <app-text-input [label]="'HCP'" [formControl]="player.get('hcp')"
                        [type]="'number'"
                        (blurEvent)="updatePlayer(pIndex)"></app-text-input>
    </div>
    <!--<div class="p-md-col half-rem-padding">
        <app-switch-input [label]="'Special conditions'" [formControl]="player.get('show_additional')"
                          (changed)="additional($event, pIndex)"
        ></app-switch-input>
    </div>-->
    <div *ngIf="player.get('show_additional').value == 1" class="p-md-3 half-rem-padding">
        <app-text-area [label]="'What kind of special conditions?'" [formControl]="player.get('additional_information')"
                       (blurEvent)="updatePlayer(pIndex)"
        [placeholder]="'e.g. Junior, Golf President, etc..'"></app-text-area>
    </div>
    <div class="p-col-12 p-md-1">
        <i *ngIf="_players.controls.length > 1" class="pi pi-trash mt-40"
           (click)="removePlayer(pIndex)"></i>
        <i *ngIf="pIndex == _players.controls.length - 1"
           class="pi pi-plus ml-3 mt-40" (click)="addPlayer()"></i>
    </div>
</div>
