import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {finalize} from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(private ngxService: NgxUiLoaderService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request.url.includes('?search') && !request.url.includes('notifications') &&
            !request.url.includes('requests?page')) {
            this.ngxService.start('commonLoader');
        }
        return next.handle(request).pipe(
            finalize(() => {
                this.ngxService.stop('commonLoader');
            })
        );
    }
}
