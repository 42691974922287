<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card toolbar-demo">
        <div class="p-grid">
            <div class="p-col-10">
                <h5>{{editMode ? 'Edit Product' : 'Add Single'}}</h5>
            </div>
            <div class="p-col-2">
                <app-switch-input [formControl]="form.controls['status']" [label]="'Active'"
                                  [inline]="true" class="float-right"></app-switch-input>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-col">
                <app-text-input [label]="'Product name'" [formControl]="form.controls['name']"
                                [placeholder]="'Product name'"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label class="full-width">Contract <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="service_id"
                                    [suggestions]="services" (onSelect)="serviceChanged($event)" delay="1000"
                                    (completeMethod)="searchServices($event)" [forceSelection]="true">
                        <ng-template let-service pTemplate="item">
                            <div>{{service.name}}{{service.code ? ', ' + service.code : ''}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.get('service_id').touched && form.get('service_id').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <app-select-input [options]="golfCourses" [formControl]="form.controls['golf_course_id']"
                                  [optionLabel]="'name'" [optionValue]="'id'" [filter]="true"
                                  [label]="'Golf course'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-select-input [options]="teeTimes" [formControl]="form.controls['tee_time_id']"
                                  [optionLabel]="'name'" [optionValue]="'id'" [filter]="true"
                                  [label]="'Tee times'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-select-input [options]="holes" [formControl]="form.controls['hole_id']"
                                  [optionLabel]="'name'" [optionValue]="'id'" [filter]="true"
                                  [label]="'Holes'"></app-select-input>
            </div>
        </div>
        <div class="p-grid">
            <div *ngIf="editMode" class="p-col-12 p-md-col">
                <app-text-input label="Product code" [formControl]="form.get('code')"></app-text-input>
            </div>
            <div *ngIf="editMode" class="p-col-12 p-md-col">
                <app-text-input label="Reference code" [formControl]="form.get('ref_code')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <app-text-input label="TUI code (@ComRes)" [formControl]="form.get('tui_code')"></app-text-input>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Validity from</label>
                    <p-calendar class="full-width" [formControl]="form.get('validity_from')"
                                [showIcon]="true" placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                </div>
            </div>
            <div class="p-col-12 p-md-col">
                <div class="p-field">
                    <label>Validity to</label>
                    <p-calendar class="full-width" [formControl]="form.get('validity_to')"
                                [showIcon]="true" placeholder="dd.mm.yyyy" [readonlyInput]="false"></p-calendar>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-3">
                <app-switch-input [formControl]="form.controls['junior']" [label]="'Junior'"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-switch-input [formControl]="form.controls['buggy']" [label]="'Buggy'"></app-switch-input>
            </div>
            <div class="p-col-12 p-md-3">
                <app-switch-input [formControl]="form.controls['multi_players_only']"
                                  [label]="'Multi players only'"></app-switch-input>
            </div>
            <div class="p-col-12">
                <app-text-area [label]="'Additional information'"
                               [formControl]="form.controls['additional_information']">
                </app-text-area>
            </div>
        </div>
    </div>
    <div class="card toolbar-demo">
        <div class="p-grid p-jc-between">
            <div class="p-col">
                <h5>Contract, Booking & Service Handling</h5>
            </div>
            <div>
                <app-switch-input class="float-right" *ngIf="showServiceInfoButton"
                                  [formControl]="form.controls['use_service_configurations']"
                                  [label]="'Use contract configurations'"
                                  [inline]="true"
                                  (changed)="serviceInfoButtonClick($event)"></app-switch-input>
                <a class="view-company-link"
                   *ngIf="form.get('service_id').value && form.get('use_service_configurations').value == 1"
                   [routerLink]="'/product-setup/contracts/edit/' + form.get('service_id').value.id" target="_blank">View
                    service</a>

            </div>
        </div>
        <div class="p-grid">
            <!--<div class="p-col-12 p-md-2">
                <div class="p-field">
                    <label class="full-width">Invoicing <span class="p-error">*</span></label>
                    <p-autoComplete class="autocomplete" [field]="'name'" formControlName="invoice_handler_id"
                                    [suggestions]="companies" delay="1000"
                                    (completeMethod)="searchCompanies($event)" [forceSelection]="true">
                        <ng-template let-company pTemplate="item">
                            <div>{{company.name}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <small *ngIf="form.get('invoice_handler_id').touched && form.get('invoice_handler_id').errors?.required"
                           class="p-error">required</small>
                </div>
            </div>-->
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="bookingOptions" [label]="'Booking possible for'"
                                  [formControl]="form.controls['booking_possible_for']"
                                  (optionChanged)="bookingPossibleChanged($event.value)"></app-select-input>
            </div>
            <div class="p-col-12 p-md-10 half-rem-padding">
                <app-multi-select [options]="bookings" [formControl]="form.controls['hotels']" [filter]="true"
                                  [label]="'Hotels'" [placeholder]="'Select hotels'"
                                  [optionLabel]="'name'" [optionValue]="'id'"></app-multi-select>
            </div>
            <div class="p-col-12 p-md-2">
                <app-select-input [options]="serviceHandlerTypes"
                                  (optionChanged)="serviceHandlerTypeChanged($event.value)"
                                  [label]="'Service handler type'"
                                  [formControl]="form.controls['service_handler_type_id']"
                                  [filter]="true" [optionLabel]="'name'" [optionValue]="'id'"></app-select-input>
            </div>
            <div class="p-col-12 p-md-2">
                <app-select-input [formControl]="form.controls['service_handler_id']" [options]="serviceHandlers"
                                  [optionValue]="'id'" [optionLabel]="'name'"
                                  [label]="'Service Handler'" [filter]="true"></app-select-input>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button pButton type="submit" class="float-right">{{editMode ? ('saveChanges' | translate) : 'Create'}}</button>
    </div>
</form>

